import { FormControl, FormLabel, Stack } from '@mui/material';
import { ReactNode } from 'react';
import FieldHelp from './toggles/FieldHelp';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


export interface FormletProps
{
	name?: string,
	label?: string,
	help?: string,
	children?: ReactNode,
	show_help_below?: boolean,
}


// Generic wrapper for fields
export const Formlet =
({
	label,
	help,
	children,
	show_help_below,
}
: FormletProps) =>
{
	return (
		<FormControl
			component='fieldset'
			sx={{
				// margin: '4px',
			}}
		>
			
			{
				(label)
				&&
				<FormLabel
					component='legend'
					sx={{
						fontWeight: 600,
						fontSize: '16px',
						color: defaultAppTheme.palette.grey['700'],
						lineHeight: 1,
						padding: '6px 0',
					}}
				>
					{label}
				</FormLabel>
			}
			
			
			{
				(help && !show_help_below)
				&&
				<FieldHelp>
					{help}
				</FieldHelp>
			}
			
			
			<Stack
				sx={{
					padding: '2px 20px',
				}}
			>
				{children}
			</Stack>
			
			
			{
				(help && show_help_below)
				&&
				<FieldHelp>
					{help}
				</FieldHelp>
			}
			
		</FormControl>
	)
}


export default Formlet;
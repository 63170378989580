

export const tagBgColors =
[
	{
		name: 'primary',
		hex: '#1982c4'
	}, {
		name: 'secondary',
		hex: '#ff595e'
	}, {
		name: 'leaf',
		hex: '#8ac926'
	}, {
		name: 'dandelion',
		hex: '#ffca3a'
	}, {
		name: 'eggplant',
		hex: '#6a4c93'
	}, {
		name: 'shoal',
		hex: '#ccf4e3'
	},
];


export const tagTextColors =
[
	{
		name: 'text-primary-dark',
		hex: '#323338'
	}, {
		name: 'text-primary-light',
		hex: '#f8f9fa'
	},
]


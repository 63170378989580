import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';


const Inquiry = () =>
{
	const { control, setValue } = useFormContext()
	const mode = useWatch({ control, name: 'inquiryMode' })
	const inquiry  = useWatch({ control, name: 'inquiry' })
	const todos  = useWatch({ control, name: 'todos' })
	const { fields: todoFields, replace: replaceTodos, append: appendTodo, remove: removeTodo } = useFieldArray({ control, name: 'todos' })
	
	
	const setList = () =>
	{
		replaceTodos(inquiry.split('\n').map(label => ({
			label
		})))
	}
	
	
	const setString = () =>
	{
		let string = ``;
		todos.forEach(todo => {
			string = `${string}${todo.label}\n`
		})
		setValue('inquiry', string)
	}
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'start',
				alignItems: 'start',
				width: '100%',
			}}
		>
			
			<Box
				sx={{
					marginBlock: '1rem',
					fontWeight: 'semibold',
				}}
			>
				Enter instruction(s)
			</Box>
			
			<Controller
				control={control}
				name={'inquiry'}
				defaultValue=''
				render={({ field: { value, onChange } }) => (
					<TextField
						multiline
						rows={4}
						sx={{ width: '100%' }}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				)}
			/>
			
			{/* {
				(mode === 'list')
				&&
				<Box>
					{
						todoFields.map(( field, index ) =>
							<Box key={field.id}>
								<Checkbox disabled />
								<Controller
									control={control}
									name={`todos.${index}.label`}
									render={({ field: { value, onChange }}) =>
										<TextField
										variant='standard'
										value={value}
										onChange={onChange}
										onKeyDown={(e) => {
											const { key } = e;
											
											if (key === 'Enter' && value.length > 0)
											{
												appendTodo({ label: '' })
											}
											if (key === 'Backspace' && value.length === 0 && todos.length > 1)
											{
												removeTodo(index)
											}
										}} />
									}
								/>
							</Box>
						)
					}
				</Box>
			}
				
				<FormGroup>
					<Controller
					control={control}
					name='inquiryMode'
					render={({ field: { value, onChange }}) =>
						<FormControlLabel
							control={<Checkbox checked={value === 'list'}
							onChange={() => {
								if (value === 'string')
								{
									onChange('list');
									setList();
								}
								else
								{
									onChange('string')
									setString();
								}
							}} />}
							label='List'
						/>
					}
				/>
			</FormGroup> */}
		</Box>
	)
}


export default Inquiry;



// 
export const firstUrlPart = () =>
{
	let path = window.location.pathname;
	
	return path.split('/').filter(x => !!x)[0];
}



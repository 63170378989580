import { Box, CircularProgress } from "@mui/material";
import { useDoc } from "ember";
import { useState, useEffect } from "react"
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAuthData } from "stuff";
import { ComponentTreeData } from "models";
import CreateIssueDesktopView from "./CreateIssueDesktopView";
import CreateIssueMobileView from "./CreateIssueMobileView";
import { CreateIssueStepperProps } from "./interfaces";


const CreateIssuePageNavigation = ({onSubmit} : CreateIssueStepperProps) =>
{
	const { claims } = useAuthData();
	
	const { component_id } = useParams();
	
	const { setValue } = useFormContext();
	
	const [width, setPageWidth] = useState<number>(window.innerWidth);
	
	const handlePageWidth = () =>
	{
		setPageWidth(window.innerWidth);
	}
	
	
	useEffect(() => {
		window.addEventListener('resize', handlePageWidth);
		return () => {
			window.removeEventListener('resize', handlePageWidth);
		}
	}, []);
	
	
	const component_collection_path = `organizations/${claims?.organization_id}/components`;
	const component_doc = useDoc(component_collection_path, component_id)
	
	const component = component_doc.data as ComponentTreeData;
	
	if(component_doc.is_loading)
	{
		return <CircularProgress />
	}
	
	if(component !== undefined)
	{
		console.log(component);
		
		setValue('asset_id', component.asset_id);
		setValue('component_ids', [component_id]);
		setValue('component_names', [component.name]);
	}
	
	
	return (
		<Box>
			{
				(width >= 768) ? (
					<CreateIssueDesktopView />
				) : (
					<CreateIssueMobileView onSubmit={onSubmit} />
				)
			}
		</Box>
	)
}


export default CreateIssuePageNavigation;
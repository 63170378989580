import { CircularProgress } from '@mui/material';


const LoadingSpinner = () =>
{
	return <CircularProgress
		size={36}
	/>
}


export default LoadingSpinner;
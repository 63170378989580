import { useParams } from 'react-router';

import CircularProgress from '@mui/material/CircularProgress';

import { useDoc } from 'ember';
import { useAuthData } from 'stuff';
import { Issue } from 'models';
import IssueCollaborationHeader from './IssueCollaborationHeader';
import IssueCollaborationTabs from './IssueCollaborationTabs';
import IssueProvider from './IssueProvider';


const IssueCollaborationPage = () =>
{
	const params = useParams();
	const { issue_id } = params;
	
	const { claims } = useAuthData();
	
	console.log({claims});
	
	// Get the Issue data
	const issues_collection_path = `organizations/${claims?.organization_id}/issues`;
	const issue_doc = useDoc(issues_collection_path, issue_id);
	
	
	if(issue_doc?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const issue = issue_doc?.data as Issue;
	
	console.log({issue});
	
	
	return (
		<IssueProvider issue={issue}>
			<div className='issue_collab_page'>
				
				<IssueCollaborationHeader />
				
				<IssueCollaborationTabs />
				
			</div>
		</IssueProvider>
	)
}


export default IssueCollaborationPage;
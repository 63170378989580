// Contains commonly used imports that are widely used in the app
import React from 'react';

import { useContext } from 'react';

import store from 'store';


// Allows descendents components of App to access up-to-date decoded token data via useAuth
export * from 'app/auth/AuthDataProvider';


// React itself
export { default as React } from 'react';


// React hooks
export {
	useState,
	useHook,
	useEffect,
	useRef,
	useContext,
	useMemo,
	useCallback,
} from 'react';



export { Entity, Thingy, resolveEntity, entities, fuego, useFuego, useFuegoData } from 'store';




export { DateTime, Duration, Interval, Info } from 'luxon';


export { default as cx } from 'classnames';


// let id = cuid()
export { default as cuid } from 'cuid';


// Utils
export * from 'utils';

// Components
export * from 'components';



////export { default as _ } from 'lodash';

////export { cloneDeep } from 'lodash/cloneDeep';



//console.log(store)





// Grants access to our store, dispatch, and app state via hooks
export const useStore         = () => useContext(store);
export const useDispatch      = () => useStore().dispatch;
export const useDispatchWrite = () => useStore().dispatchWrite;
export const useAppState      = () => useStore().state;





// Useful for nav tabs and other internal links
export {
	NavLink,
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';



// Voila - now 'stuff' is a drop-in replacement for 'react'
export default React;
import { useState } from 'react';
import { useUploadFile, useWrite } from 'ember';
import { convertToRaw, EditorState, RawDraftContentState } from 'draft-js';

import { Message, Event } from 'types/misc_interfaces';
import { StorageFile } from 'models';

import { Button, Paper } from '@mui/material';

import { useAuthData } from 'stuff';
import { DateTime } from 'luxon';
import cuid from 'cuid';

import CorIcon from 'components/icons/CorIcon';

import AttachFilesButton from 'components/buttons/AttachFilesButton';

import ChatSimpleEditor from './ChatSimpleEditor';
import { useSession } from 'app/providers/SessionProvider';


// interface Token
// {
// 	organization_id: string;
// 	[key: string]: any;
// }


const ChatComposition =
({
	message_metadata,
} : {
	message_metadata: Object,
}) =>
{
	const { users } = useSession();
	
	const write = useWrite();
	
	const uploadFile = useUploadFile();
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { claims } = useAuthData();
	
	const [ editor_state, setEditorState ] = useState(EditorState.createEmpty());
	
	const [ attached_files, setAttachedFiles ] = useState<File[]>([]);
	
	
	let org = claims?.organization_id;
	
	
	
	// console.log(
	// 	'Editor contents',
	// 	token,
	// 	user,
	// 	editor_state,
	// 	editor_state.getSelection().getAnchorOffset(),
	// 	convertToRaw(editor_state?.getCurrentContent()).blocks.map(x =>
	// 		x.text
	// 	).join('\n\n'),
	// );
	
	
	
	// Will also upload any selected files
	const sendMessage = async (parsed: RawDraftContentState) =>
	{
		console.log(parsed, claims);
		
		
		let plain = parsed.blocks.map(block => block.text).join('\n\n').trim();
		
		
		// Protect against empty messages
		// TODO: disable button and prevent situation
		if((plain.trim().length === 0) && (attached_files.length === 0))
		{
			return;
		}
		
		
		let datetime = DateTime.local().toISO();
		
		
		// We may not send a message - we could just be sending files
		if(plain.trim().length !== 0)
		{
			var message : Message =
			{
				...message_metadata,
				
				_id: cuid(),
				
				sender_id: claims?.user_id,
				sender_name: claims?.name,
				
				text: plain.trim(),
				
				date_sent: datetime,
			}
			
			
			// If there's any rich text, we'll include it in the message
			if(
				(!parsed.blocks.every(block =>
					(block.type === 'unstyled') && (!block.inlineStyleRanges.length)
				))
				||
				(Object.values(parsed.entityMap).length > 0)
			)
			{
				message.rich_text = JSON.stringify(parsed);
			}
			
			
			write(`organizations/${org}/messages`, message);
		}
		
		
		
		let files = [];
		
		
		
		// // Trying to determine image dimensions
		// await Promise.all(
		// 	attached_files.map((attached_file: File) =>
		// 	{
		// 		var reader = new FileReader();
				
		// 		// Read the contents of Image File
		// 		reader.readAsDataURL(attached_file);
		// 		reader.onload = function (e)
		// 		{
		// 			// Initiate the JavaScript Image object
		// 			var image = new Image();
					
		// 			// Set the Base64 string return from FileReader as source
		// 			image.src = e.target.result as string;
					
		// 			// Validate the file dimensions
		// 			image.onload = function ()
		// 			{
		// 				var height = this.height;
		// 				var width = this.width;
						
		// 				alert(`Uploaded image dimensions: ${width} ${height}`);
						
		// 				return true;
		// 			};
		// 		};
				
		// 		// ?
		// 		return true;
		// 	})
		// )
		
		
		
		// Generate StorageFile from HTML5 File and upload to Firebase Storage
		const uploadOneFile = (file_to_upload: File) =>
		{
			let _id = cuid();
			
			let storage_file : StorageFile =
			{
				...message_metadata,
				
				_id: _id,
				name: file_to_upload.name,
				path: `organizations/${claims?.organization_id}/files/${_id}`,
				size: file_to_upload.size,
				type: file_to_upload.type,
				upload_date: datetime,
				last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
			}
			
			if(message)
			{
				storage_file.message_id = message._id;
			}
			
			
			uploadFile(storage_file, file_to_upload, `organizations/${org}/files`);
			
			files.push(storage_file);
		}
		
		
		
		// If any files were selected, upload them to storage, add corresponding
		// documents to Firestore, and also write an event with the same metadata
		if(attached_files.length)
		{
			attached_files?.forEach(file_to_upload => uploadOneFile(file_to_upload));
			
			
			let event_text = (attached_files.length > 1)
				? `Uploaded ${attached_files.length} files`
				: 'Uploaded file';
			
			
			let event : Event =
			{
				...message_metadata,
				
				_id: cuid(),
				name: event_text,
				
				type: 'file',
				subtype: 'upload',
				
				datetime: datetime,
				summary:  event_text,
				
				user_id: claims.user_id,
				sender_name: claims?.name,
				
				file_ids: files.map(x => x._id),
				files: files,
			}
			
			
			if(message)
			{
				event.message_id = message._id;
			}
			
			
			write(`organizations/${org}/events`, event);
			
			console.log(message, event, files);
		}
		
		
		clearAll();
	}
	
	
	
	// Convert FileList to array
	const fileChangeHandler = ( file_list: FileList | null) =>
	{
		// console.log(event);
		
		if(file_list != null)
		{
			const files_array: File[] = Array.from( file_list );
			
			setAttachedFiles( files_array );
			
			console.log(files_array);
		}
	};
	
	
	const clearAll = () =>
	{
		setAttachedFiles( [] );
		
		setEditorState( EditorState.createEmpty() );
	}
	
	
	
	const FileInfo = () =>
	{
		if(attached_files.length === 0)
		{
			return null;
		}
		
		if(attached_files.length === 1)
		{
			return (
				<div
					className='chat_selected_files'
				>
					<CorIcon
						of='file'
					/>
					1 File
				</div>
			)
		}
		
		return (
			<div
				className='chat_selected_files'
			>
				<CorIcon
					of='files'
				/>
				{attached_files.length} Files
			</div>
		)
	}
	
	
	
	return (
		<Paper
			className='chat_composition_zone'
			sx={{
				borderRadius: '0',
				// minHeight: '160px',
			}}
		>
			
			<ChatSimpleEditor
				users={users}
				onSubmit={(data: any) => sendMessage(data)}
				onChange={(editor_state) =>
					setEditorState(editor_state)
				}
				editor_state={editor_state}
				setEditorState={setEditorState}
			/>
			
			<div
				className='chat_composition_buttons'
			>
				<Button
					variant='contained'
					onClick={() => {
						let raw = convertToRaw(editor_state?.getCurrentContent());
						sendMessage(raw);
					}}
					// type='submit'
					className='chat_composition_submit'
					sx={{
						gap: '3px',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<CorIcon
						of='send'
						size='24px'
					/>
					{/* Send */}
				</Button>
				
				<div
					className='chat_composition_attachments'
				>
					<AttachFilesButton
						fileChangeHandler={fileChangeHandler}
					/>
					<FileInfo />
				</div>
			</div>
			
		</Paper>
	);
}


export default ChatComposition;
import { useCollection } from 'ember';
import { where } from 'firebase/firestore';
import { Action, ActionStatus, Certification, CertificationRecordStatus, Issue, IssueStatus, Requisition, RequisitionStatus, WorkOrderNew, WorkOrderStatus } from 'models'
import { ReactNode, createContext, useContext } from 'react'
import { useAuthData } from 'stuff';




export interface CorsAnalyticsData
{
	issues: Issue[],
	actions: Action[],
	work_orders: WorkOrderNew[],
	requisitions: Requisition[],
	certifications: Certification[],
};


const CorsAnalyticsDataContext = createContext<CorsAnalyticsData>(null);


export const useCorsAnalyticsLoader = () =>
{
	return useContext(CorsAnalyticsDataContext)
};



const CorsAnalyticsLoader =
({
	children,
} : {
	children: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	
	const org_path = `organizations/${claims.organization_id}`;
	
	
	// Call all related collections
	const issues_collection = useCollection(
		`${org_path}/issues`,
		where('status', '!=', IssueStatus.CLOSED)
	);
	
	const actions_collection = useCollection(
		`${org_path}/actions`,
		where('status', '!=', ActionStatus.RESOLVED)
	);
	
	const work_orders_collection = useCollection(
		`${org_path}/work_orders`,
		where('status', '!=', WorkOrderStatus.COMPLETED)
	);
	
	const requisitions_collection = useCollection(
		`${org_path}/requisitions`,
		where('status', '!=', RequisitionStatus.COMPLETED)
	);
	
	const certifications_collection = useCollection(
			`${org_path}/certifications`,
			where('updated_record_status', '==', CertificationRecordStatus.ACTIVE)
		);
	
	
	// Load data
	const issues = issues_collection?.data as Issue[] || [];
	const work_orders = work_orders_collection?.data as WorkOrderNew[] || [];
	const actions = actions_collection?.data as Action[] || [];
	const requisitions = requisitions_collection?.data as Requisition[] || [];
	const certifications = certifications_collection?.data as Certification[] || [];
	
	
	
	
	
	const cors_analytics_data =
	{
		issues: issues || [],
		actions: actions || [],
		work_orders: work_orders || [],
		requisitions: requisitions || [],
		certifications: certifications || [],
	}
	
	
	
	return (
		<CorsAnalyticsDataContext.Provider
			value={cors_analytics_data}
		>
			{children}
		</CorsAnalyticsDataContext.Provider>
	)
}

export default CorsAnalyticsLoader
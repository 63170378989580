import React from 'react'

import { useState } from 'react';

import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AssetsNavigationPage from './AssetsNavigationPage';
import PartsPage from './parts/PartsPage';





const tab_titles = ['Assets', 'Parts'];



const getTabNumberFromSearchParam = (tab: string | undefined) =>
{
	switch (tab)
	{
		case 'assets_list':
			return 1;
		case 'parts':
			return 2;
		default:
			return 1;
	}
};



const AssetPageTabs = () => 
{
	const { search } = useLocation();
	
	
	
	const tab = getTabNumberFromSearchParam(
		new URLSearchParams(search).get('tab')
	);
		
	const [selected_tab, setSelectedTab] = useState(`${tab}`);
	
	
	
	
	const handleChange = (event: React.SyntheticEvent, newValue: string) =>
	{
		setSelectedTab(newValue);
		
		const url = new URL(document.location.href)
		
		switch(newValue)
		{
			case '1':
				url.searchParams.set('tab', 'assets_list');
				break;
			case '2':
				url.searchParams.set('tab', 'parts');
				break;
			default:
				url.searchParams.set('tab', 'assets_list');
				break;
		}
		
		window.history.pushState({}, document.title, url.toString());
	};


	const tabs =
	<TabContext value={selected_tab}>
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}
			className='issue_collab_tabs'
		>
			<TabList
				onChange={handleChange}
				className='issue_collab_tabs_list'
			>
				<Tab label={tab_titles[0]} value={'1'} />
				<Tab label={tab_titles[1]} value={'2'} />
			</TabList>
		</Box>
		
		<TabPanel value='1' className='issue_collab_tab_panel'>
			<AssetsNavigationPage />
		</TabPanel>
		
		<TabPanel
			value='2'
			className='issue_collab_tab_panel'
		>
			<PartsPage />
		</TabPanel>
	</TabContext>
	
	
	
	return (
		<>
			{tabs}
		</>
	)
};


export default AssetPageTabs;
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useCollection } from 'ember';
import { CostCenter } from 'models/costs';
import { useAuthData } from 'stuff';
import SearchableCostCentersCardList from './SearchableCostCentersCardList';



const CostCenterList = () =>
{
	const { claims } = useAuthData();
	
	const cost_centers_collection = useCollection(
			`organizations/${claims.organization_id}/cost_centers`
		);
	
	if(cost_centers_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	const cost_centers = cost_centers_collection?.data as CostCenter[];
	
	console.log({cost_centers})
	
	
	return (
		<Stack
			spacing={1}
			width='100%'
		>
			<Typography
				variant='h4'
			>
				Available Cost Centers
			</Typography>
			
			<SearchableCostCentersCardList cost_centers={cost_centers} />
			
		</Stack>
	)
};


export default CostCenterList;
import { useCallback, useMemo, useState } from 'react';

import {
	Rectangle,
	useMap,
	useMapEvent,
} from 'react-leaflet';

import { useEventHandlers, useLeafletContext } from '@react-leaflet/core';
import { determineMinimapZoom } from './minimap_utils';


const BOUNDS_STYLE =
{
	weight: 1
}


// Translucent box in the minimap showing the extent of the main map bounds
const MinimapViewbox = ({ parent_map }) =>
{
	const minimap = useMap();
	const context = useLeafletContext();
	
	
	// Keep track of bounds in state to trigger renders
	const [viewbox_bounds, setViewboxBounds] = useState(parent_map.getBounds());
	
	
	// Clicking a point on the minimap sets the parent map's center
	const onClick = useCallback(
		(e) => {
			parent_map.setView(e.latlng, parent_map.getZoom())
		},
		[parent_map],
	);
	
	// Configure Leaflet to call our handler on click events on the minimap
	useMapEvent('click', onClick);
	
	
	// On move or zoom events from main map, we'll update the viewbox
	const onMainMapChange = useCallback(
		() =>
		{
			setViewboxBounds(parent_map.getBounds());
			
			// console.log('onChange', parent_map.getZoom(), parent_map);
			
			// Update the minimap's view to match the parent map's center and zoom
			minimap.setView(
				parent_map.getCenter(),
				determineMinimapZoom(parent_map)
			);
		},
		[minimap, parent_map]
	);
	
	
	// Listen to events on the main map
	const main_map_handlers = useMemo(
		() => ({
			move: onMainMapChange,
			zoom: onMainMapChange,
		}),
		[onMainMapChange]
	);
	
	// Configure Leaflet to call our handlers when specified events fire on main map
	useEventHandlers(
		{
			instance: parent_map,
			context: context
		},
		main_map_handlers
	);
	
	
	return <Rectangle
		bounds={viewbox_bounds}
		pathOptions={BOUNDS_STYLE}
	/>
}


export default MinimapViewbox;
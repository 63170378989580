import { ComponentRecordType, defaultUnitsForRecordType, unitsEnumForRecordType } from 'models';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import EnumSelectFormlet from '../fields/select/EnumSelectFormlet';


// Choose between Running Hours, Pressure, etc.
export const ComponentRecordTypeFormlet =
({
	name = 'type',
	label = 'Record Type',
} : {
	name?: string,
	label?: string,
}) =>
{
	const { setValue } = useFormContext();
	
	let [ search_params ] = useSearchParams();
	
	
	let url_query_units = search_params.get('units');
	
	
	return (
		<EnumSelectFormlet
			name={name}
			label={label}
			help={'What is being recorded?'}
			url_param_key='type'
			enum_type={ComponentRecordType}
			onChange={(new_value: ComponentRecordType) => {
				
				let units_enum = unitsEnumForRecordType(new_value);
				
				
				let is_query_param_relevant = !!units_enum[url_query_units];
				
				
				// When changing the record type, we also change the units to match
				if(units_enum && url_query_units && is_query_param_relevant)
				{
					// If the URL query includes a units param that matches the newly selected type, use it
					setValue('units', units_enum[url_query_units]);
				}
				else
				{
					setValue('units', defaultUnitsForRecordType(new_value));
				}
			}}
		/>
	);
}


export default ComponentRecordTypeFormlet;
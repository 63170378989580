import { createContext, ReactNode, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { cuid, useAuthData } from 'stuff';
import { DateTime } from 'luxon';

import { excludeUndefinedValues } from 'utils/obj_utils_new';

import {
	ComponentRecord, ComponentRecordStatus, ComponentRecordType, ComponentRecordUnits, defaultUnitsForRecordType, ThresholdActionType, TimeUnit,
} from 'models';

import { useParams, useSearchParams } from 'react-router-dom';
import { useWrite } from 'ember';
import { Alert, Snackbar } from '@mui/material';
import { useComponent } from 'app/providers/ComponentLoader';

// import { triggerName } from './trigger/trigger_helpers';



const DEFAULT_FORM_DATA: ComponentRecord =
{
	_id: cuid(),
	
	title: '',
	description: '',
	
	// type: ComponentRecordType.RUNNING_HOURS,
	// threshold_action_type: ThresholdActionType.SEND_ALERT,
	// units: TimeUnit.HOURS,
	
	should_calculate_average: false,
};



const ComponentRecordFormContext = createContext<Partial<ComponentRecord> | null>(null);



export const useComponentRecordForm = () =>
{
	return useContext(ComponentRecordFormContext);
}



// TODO: Clarify form provider vs a view-only provider?
export const ComponentRecordFormProvider =
({
	component_record,
	children,
} : {
	component_record?: Partial<ComponentRecord>,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const { component } = useComponent();
	
	const write = useWrite();
	
	const [ show_snackbar, setShowSnackbar ] = useState<boolean>(false);
	
	let [ search_params ] = useSearchParams();
	
	
	const { asset_id, system_id, component_id } = useParams();
	
	
	// This will be as enum keys, not enum values (Ex: 'DEGREES_CELSIUS' not '°C')
	let type_query = search_params.get('type');
	let units_query = search_params.get('units');
	let threshold_action_type_query = search_params.get('action');
	
	
	const closeAlert = (event?: React.SyntheticEvent | Event, reason?: string) =>
	{
		if (reason === 'clickaway')
		{
			return;
		}
	
		setShowSnackbar(false);
	};
	
	
	const form = useForm<ComponentRecord>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
			
			_id: cuid(),
			
			// title: component.name,
			
			type: ComponentRecordType[type_query] || ComponentRecordType.RUNNING_HOURS,
			units: (units_query)
				? ComponentRecordUnits[units_query] || TimeUnit.HOURS
				: (type_query)
					? defaultUnitsForRecordType(type_query) || TimeUnit.HOURS
					: TimeUnit.HOURS,
			
			asset_id: asset_id,
			system_id: system_id,
			component_id: component_id,
			
			status: ComponentRecordStatus.NORMAL,
			
			triggers_by_id: {},
			
			threshold_action_type: ThresholdActionType[threshold_action_type_query],
			
			...component_record,
		},
	});
	
	
	console.log(form.getValues());
	
	
	const onSubmit = (data: ComponentRecord) =>
	{
		console.log(data);
		
		
		data = excludeUndefinedValues(data);
		
		
		data.title = data?.title
			|| component?.name + ' - ' + data?.type;
		
		// Only include start reading if a value was provided
		if(!data.initial_reading?.value)
		{
			delete data.initial_reading;
		}
		else
		{
			data.initial_reading.units = data.units;
			data.initial_reading.recorded_by_user_id = claims.user_id;
			data.initial_reading.recorded_by_user_name = claims.name;
			
			// If an initial value was provided without a datetime, use now as the datetime
			if(!data.initial_reading.recorded_at_datetime)
			{
				data.initial_reading.recorded_at_datetime = DateTime.utc().toISO();
			}
			// Convert DateTime to ISO formatted string
			else if(data.initial_reading.recorded_at_datetime instanceof DateTime)
			{
				data.initial_reading.recorded_at_datetime =
					data.initial_reading.recorded_at_datetime?.toUTC().toISO();
			}
			
			// Create an ID for the initial reading
			if(!data.initial_reading._id)
			{
				data.initial_reading._id = cuid();
			}
			
			// TODO: Do server-side?
			// TODO: What if the initial datetime has changed?
			// TODO: Fix editing initial data
			// If an initial reading was provided, use that as the last updated reading
			data.last_updated_reading = (data.last_updated_reading) || data.initial_reading;
			
			// TODO: Potential race condition? May be better to handle incrementing server-side
			data.reading_count = data.reading_count || 0;
		}
		
		
		write(
			`organizations/${claims.organization_id}/component_records`,
			data
		).then(() => {
			setShowSnackbar(true);
		});
	}
	
	
	const onSubmitError = (props: any) =>
	{
		console.log('Error submitting', props);
	}
	
	
	const handleSubmitForm = (e) =>
	{
		e.preventDefault()
		
		form.handleSubmit(
			onSubmit,
			onSubmitError
		)();
	}
	
	
	// TODO: Change alert text if submitting update
	return (
		<FormProvider {...form}>
			<form
				onSubmit={handleSubmitForm}
				id='ComponentRecord'
			>
				{children}
			</form>
			
			<Snackbar
				open={show_snackbar} 
				autoHideDuration={3000} 
				onClose={closeAlert}
				className='alert'
			>
				<Alert
					onClose={closeAlert}
					severity='success'
					sx={{ width: '100%' }}
				>
					Component record created
				</Alert>
			</Snackbar>
		</FormProvider>
	);
};


export default ComponentRecordFormProvider;
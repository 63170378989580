
import { useIssue } from 'app/dashboard/pages/issueCollaboration/IssueProvider';
import Chat from './Chat';


const IssueChat = () =>
{
	const { issue, messages, events, files } = useIssue();
	
	
	return (
		<Chat
			messages={messages}
			events={events}
			files={files}
			message_metadata={{
				issue_id: issue._id,
			}}
		/>
	)
}


export default IssueChat;
import { Divider, Grid, Stack } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';


import WOTAffectedComponentsList from './WOTAffectedComponentsList';
import WOTPartsNeededList from './WOTPartsNeededList';
import WOTemplateTagsList from './WOTemplateTagsList';
import WOTCollaboratorsList from './collaborators/WOTCollaboratorsList';

import FilesTable from '../../issueCollaboration/issueDetails/FilesTable';


import { useWOTCollabData } from '../WOTCollabProvider';
import AttachFilesButton from 'components/buttons/AttachFilesButton';
import ResponsiveWorkOrdersList from 'pages/work_orders/work_orders/ResponsiveWorkOrdersList';
import IssuesListResponsiveContent from '../../issuesMain/IssuesListResponsiveContent';


const WOTDetailsTab = () =>
{
	const { wo_template, files, tags, components, related_work_orders, linked_issues, uploadFiles } = useWOTCollabData();
	
	const { description, status } = wo_template;
	
	
	return (
		<Grid
			container
			width={'100%'}
		>
			<Grid
				item
				className='cards_container'
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					spacing={1}
				>
					
					<InfoHighlight
						severity={'info'}
						message_context='Description'
						message_main={description}
						title={`Status: ${status}`}
					/>
					
					<Grid
						container
						sx={{
							marginTop:'20px',
						}}
						width='100%'
					>
						<WOTAffectedComponentsList
							components={components}
							wo_template = {wo_template}
						/>
						
						<Grid
							item
						>
							<Divider orientation='vertical'/>
						</Grid>
						
						<WOTPartsNeededList wo_template = {wo_template} />
						
						<Grid
							item
						>
							<Divider
								orientation='vertical'
							/>
						</Grid>
						
						<WOTemplateTagsList tags={tags} wo_template={wo_template} />
					</Grid>
					
				</Stack>
			</Grid>
			
			<Grid item width='100%'>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={8} md={8} lg={8}>
						
						<Stack
							direction='column'
							justifyContent='flex-start'
							alignItems='flex-start'
							spacing={1}
							className='cards_container'
						>
							<AttachFilesButton
								fileChangeHandler={uploadFiles}
								button_text='Upload Files'
							/>
							<FilesTable files={files} />
						</Stack>
					</Grid>
					
					<Grid item xs={12} sm={4} md={4} lg={4}>
						<WOTCollaboratorsList
							main_collaborator_title='Work Order Template Creator'
						/>
					</Grid>
					
				</Grid>
				
			</Grid>
			
			<Grid
				item
				className='cards_container'
			>
				<IssuesListResponsiveContent issues={linked_issues} title='Linked Issues' />
			</Grid>
			
			<Grid
				item
				className='cards_container'
			>
				<ResponsiveWorkOrdersList work_orders={related_work_orders}/>
			</Grid>
			
		</Grid>
	)
}


export default WOTDetailsTab;
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MyDashboardTabs from './MyDashboardTabs';
import MyDashboardProvider from './MyDashboardProvider';
import MyDashboardCalendar from './MyDashboardCalendar';



const MyDashboardPage = () =>
{
	return (
		<MyDashboardProvider>
			<Box 
				sx={{
					padding: '20px',
					overflowY: 'auto',
					height: '100%'
				}}
			>
				
				{/* header */}
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					sx={{
						marginBottom: '20px',
					}}
				>
					<Typography
						variant='h1'
					>
						My Page
					</Typography>
				</Stack>
				
				<MyDashboardCalendar />
				
				<MyDashboardTabs />
				
			</Box>
		</MyDashboardProvider>
	)
}

export default MyDashboardPage;
import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme?: any) => createStyles({

	ManageTagContainer: {
		marginLeft: '2rem',
		display: 'flex',
		flexWrap: 'wrap',
		padding: '10px',
	},

	ManageTagFormContainer__mobile: {
		marginTop: '1.5rem',
	},

	ManageTagTitle: {
		marginBottom: '.5rem',
	},

	CreateTagInputFieldContainer__desktop: {
		marginLeft: '1rem',
	},

	CreateTagInputField: {
		display: 'flex',
		alignItems: 'center',
		padding: '2px',
		height: '35px',
	},


	TagColorsContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		margin: '1.5rem 0',
	},

	TagColorsContainer__mobile: {
		display: 'flex',
		flexDirection: 'column',
		margin: '1.5rem 0',
	},

	TagColorsTitle: {
		marginRight: '1rem',
	},

	TagColorsFlex: {
		display: 'flex',
		alignItems: 'center',
	},

	TagColorPaper: {
		width: 40,
		height: 40,
		borderRadius: '5px',
		margin: '0 .5rem',
		cursor: 'pointer',

		'&:first-child': {
			marginLeft: '0.5rem',
		},

		'&:last-child': {
			marginRight: '0',
		},

		'&:hover':{
			boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.3)',
		},
	},

	TagColorPaper__active: {
		width: 45,
		height: 45,
		borderRadius: '5px',
		margin: '0 .5rem',
		cursor: 'pointer',

		'&:first-child': {
			marginLeft: '0.5rem',
		},

		'&:last-child': {
			marginRight: '0',
		},

		boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.4)',
	},

	TagPreviewPaper: {
		padding: '.5rem 1rem ',
		margin: '0 .5rem',
	},

	CreateTagFormBtn: {
		marginTop: '2rem',
		marginBottom: '5rem',
		padding: '.5rem 3rem',
	},

	CreateTagFormBtn__mobile: {
		marginTop: '2rem',
		padding: '.5rem 3rem',
		width: '90%',
		margin: '0 1rem'
	},

	TagListContainer: {
		marginLeft: '5rem',
		padding: '2rem 0',
		maxHeight: '90vh',
		overflow: 'auto',
	},

	TagListContainer__mobile: {
		backgroundColor: 'transparent',
		maxHeight: '90vh',
		overflow: 'auto',
	},

	TagHeaderContainer: {
		padding: '0 2.5rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},

	TagListTitle: {
		marginBottom: '1rem',
	},

	ManageUserInputField: {
		display: 'flex',
		alignItems: 'center',
		width: '20rem',
		height: '35px',
		padding: '2px'
	},

	DividerStyle: {
		margin: '1rem 0',
	},

	TagListItemsContainer: {
		padding: '0 2.5rem',
		display: 'flex',
		flexDirection: 'column',
	},

	SingleTagContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	SingleTagPaper: {
		padding: '.5rem 1rem ',
		height: 'fit-content',
	},

	PaginationStyle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}

}))
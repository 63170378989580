import { Divider, Stack, Typography } from '@mui/material';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import { ComponentRecord, ComponentRecordStatus } from 'models';
import CorIcon from 'components/icons/CorIcon';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ComponentRecordReadingForm from 'app/gizmos/component_record/reading/ReadingForm';
import ComponentRecordCardReadings from './ComponentRecordCardReadings';
import ComponentRecordCardHeader from './ComponentRecordCardHeader';
import ReadingValue from './ReadingValue';
import RecordedAt from './RecordedAt';
import ComponentRecordProvider from 'app/providers/ComponentRecordProvider';
import ComponentReadingProvider from 'app/providers/ComponentReadingProvider';
import { useParams } from 'react-router';


const ComponentRecordCard =
({
	component_record
} : {
	component_record: ComponentRecord,
}) =>
{
	const { work_order_id } = useParams();
	
	return (
		<ComponentRecordProvider
			component_record={component_record}
		>
			<ComponentReadingProvider
				component_reading={component_record.last_updated_reading}
			>
				<Stack
					className='basic_card'
					padding={2}
					spacing={1}
				>
					
					{
						!(work_order_id) && 
							<>
								<ComponentRecordCardHeader />
								
								<Divider />
							</>
					}
					
					<Stack>
						<Stack
							flexDirection='row'
							alignItems='center'
							justifyContent='space-between'
							
						>
							<CorIcon
								of={component_record.type.toLowerCase().trim().replaceAll(' ', '_')}
								size='40px'
								color={
									(component_record.status === ComponentRecordStatus.NORMAL)
										? '#0004'
										: '#f00f'
								}
							/>
							
							{
								(component_record?.last_updated_reading?.recorded_at_datetime)
								&&
								<Stack
									direction='column'
									padding={1}
								>
									
									<RecordedAt
										label='Last Updated Value'
										datetime={component_record?.last_updated_reading?.recorded_at_datetime}
									/>
									
									<ComponentRecordCardReadings
										component_record={component_record}
									/>
								</Stack>
							}
							
							<ReadingValue
								reading={component_record.last_updated_reading}
							/>
							
						</Stack>
						
					</Stack>
					
					
					{
						(!!component_record?.average_daily_value)
						&&
						<Stack
							flexDirection='row'
							alignItems='center'
							justifyContent='space-between'
						>
							<CorIcon of='arrow_right' size='20px'/>
							<Typography
								variant='subtitle1'
								sx={{
									color: defaultAppTheme.palette.grey['700'],
								}}
							>
								Average
							</Typography>
						</Stack>
					}
					
					
					<ButtonToOpenDialog
						button_variant='text'
						dialog_title={`New ${component_record.title} Reading`}
						dialog_dismiss_text='Discard'
						dialog_submit_text='Add Reading'
						dialog_content={
							<ComponentRecordReadingForm
								component_record={component_record}
							/>
						}
						button_content='Add New Reading'
						startIcon={<CorIcon of='plus_basic' size='24'/>}
						handleSubmit={(foo) => {
							console.log(foo);
						}}
						form_id='ComponentRecordReading'
					/>
					
				</Stack>
			</ComponentReadingProvider>
		</ComponentRecordProvider>
	)
};


export default ComponentRecordCard;
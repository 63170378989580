import EnumToggleButtonGroup from 'app/widgets/fields/EnumToggleButtonGroup';
import { getEnumKeyByValue, useURLEnumParam } from 'utils/hooks';


export interface EnumToggleFieldProps
{
	enum_type: any,
	url_param_key?: string,
	onChange?: Function,
	
	// If not provided as a prop, EnumToggleField will use the value in the URL
	value?: string,
}


// Choose between enum values, with the state stored as selected enum key within URL
// If you wish to store selected value in a form, or trigger side effects, use onChange
// TODO: Optional initial value from form?
// Ex: provided enum type Color and url_param_key = 'color'
//     if user clicks Red toggle button (enum key-value pair: Color.RED = 'Red')
//     resulting URL would be: url.com/page-path?color=RED
export const EnumToggleField =
({
	enum_type,
	url_param_key,
	onChange,
	value,
}
: EnumToggleFieldProps) =>
{
	const [ url_param_value, setValue ] = useURLEnumParam(url_param_key, enum_type);
	
	
	const handleChange =
	(
		new_type: string | null,
	) =>
	{
		if(new_type !== null)
		{
			// Enum values may not be URL-safe, so we use the enum key instead
			// Ex: 'Red' => 'RED'
			setValue(getEnumKeyByValue(enum_type, new_type));
			
			onChange?.(new_type);
		}
	};
	
	
	// TODO: Pass in description
	return (
		<EnumToggleButtonGroup
			enum_type={enum_type}
			onChange={handleChange}
			value={value || enum_type[url_param_value]}
		/>
	);
}


export default EnumToggleField;
import { Control, useFieldArray, useWatch } from 'react-hook-form';

import {
	Box,
} from '@mui/material';

import { User } from 'types';

import SelectCollaboratorsFormlet from 'components/selectCollaborators/desktop/SelectCollaboratorsFormlet';
import SelectedCollaboratorsList from 'components/selectCollaborators/desktop/SelectedCollaboratorsList';


interface CollaboratorsProps
{
	control: Control<any, any>,
	isModalOpen?: any,
	showModal?: any,
}


function isUser(user: User | string): user is User
{
	return (user as User)._id !== undefined;
}


const Collaborators =
({
	control,
}
: CollaboratorsProps) =>
{
	const { replace, remove, append } = useFieldArray({
		control,
		name: `collaborators`,
	});
	
	const selected_collaborators = useWatch({
		control,
		name: `collaborators`,
	});
	
	
	const onChange = (newValues: User[]) =>
	{
		replace(
			newValues.map((value) => {
				if (isUser(value)) return value;
			})
		);
	};
	
	
	const selectUser = (user: User) =>
	{
		const collaborator_ids = selected_collaborators.map(x => x._id);
		
		if(collaborator_ids.includes(user._id))
		{
			unSelectUser(user)
		}
		else
		{
			append(user)
		}
	}
	
	
	const unSelectUser = (user: User) =>
	{
		let index: number | undefined;
		
		selected_collaborators.forEach((collaborator, idx) =>
		{
			if (collaborator._id === user._id)
			{
				index = idx;
			}
		});
		
		remove(index);
	};
	
	
	
	return (
		<Box
			sx={{
				marginBlock: '2rem',
				width: `min(358px, 100%)`,
			}}
		>
			
			<Box sx={{ marginBottom: '1rem' }}>
				Add Collaborators
			</Box>
			
			<SelectCollaboratorsFormlet
				selectedCollaborators={selected_collaborators}
				handleSelectedCollaborators={selectUser}
			/>
			
			<SelectedCollaboratorsList
				users={selected_collaborators}
				handleChange={unSelectUser}
			/>
			
		</Box>
	);
};


export default Collaborators;
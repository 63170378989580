import { Month, ScheduleFormData, months } from 'pages/CreateWorkOrder/scheduling_types';
import { Control, Controller } from 'react-hook-form';
import { Box, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { startCase } from 'lodash';


export interface SpecificDaysProps
{
	control: Control<ScheduleFormData>;
}


export const SpecificDays = ({ control }: SpecificDaysProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem',
			}}
		>
			<InputLabel required={true}>Repeats on</InputLabel>
			<Box sx={{ marginLeft: '0.5rem' }}>
				<Controller
					control={control}
					name='yearly.specific_month_and_day.month'
					defaultValue={''}
					render={({ field: { value, onChange } }) => {
						return (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<FormControl>
									<InputLabel id='yearly-specific-days-month-label'>
										Month
									</InputLabel>
									<Select
										labelId='yearly-specific-days-month-label'
										defaultValue={value}
										onChange={onChange}
										autoCapitalize='true'
										label='Month'
									>
										{months.map((month) => {
											return (
												<MenuItem
													value={month}
													sx={{
														textTransform:
															'capitalize',
													}}
													key={month}
												>
													{startCase(
														month.toLowerCase()
													)}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								<Controller
									control={control}
									name='yearly.specific_month_and_day.day'
									defaultValue={null}
									render={({
										field: { value, onChange },
									}) => {
										return (
											<FormControl
												sx={{ marginTop: '1rem' }}
											>
												<InputLabel id='yearly-specific-days-day-label'>
													Day
												</InputLabel>
												<Select
													labelId='yearly-specific-days-day-label'
													label='Day'
													value={value}
													onChange={onChange}
													disabled={!value}
												>
													{/* {getDays(value).map(
														(day) => (
															<MenuItem
																value={day}
															>
																{day}
															</MenuItem>
														)
													)} */}
												</Select>
											</FormControl>
										);
									}}
								/>
							</Box>
						);
					}}
				/>
			</Box>
			<Box sx={{ textAlign: 'end' }}>
				of the year
			</Box>
		</Box>
	);
};



// TODO: 29? Why make that assumption?
// const getDays = (month: Month | '') =>
// 	(month === '')
// 		? []
// 		: [
// 			  ...Array(
// 				(month === 'february')
// 					? 29
// 					: (
// 							[
// 								'january',
// 								'march',
// 								'may',
// 								'july',
// 								'august',
// 								'october',
// 								'december',
// 							] as Month[]
// 						).includes(month)
// 					? 31
// 					: 30
// 			),
// 		].map((_, i) => i + 1);

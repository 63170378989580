import ErrorWrapper from "components/errors/ErrorWrapper";
import AvatarImageFromStorage from "./AvatarImageFromStorage";

import { AvatarImageProps } from "./ImageProps";



const AvatarOrError = (
{
	src,
	alt,
	...rest
}: AvatarImageProps ) =>
{
	if(!src)
	{
		return <div
			className='error'
		>
			Invalid storage path
		</div>
	};
	
	
	return (
		<ErrorWrapper>
			<AvatarImageFromStorage
				src={src}
				alt={alt}
				{...rest}
			/>
		</ErrorWrapper>
	)
}

export default AvatarOrError;
import data from 'conversion/helm/leeway/leeway_forms_dump.json';

import { Button, Stack } from '@mui/material';
import { transformHelmFormsToTemplates } from './leeway_template_conversion_helpers';
import { DateTime, useAuthData, useTitle } from 'stuff';
import { uniqueBy } from 'utils/array_utils';
import { groupBy } from 'lodash';
import { useWriteMultiple } from 'ember';
import { projectedOccurrences } from 'models/recurrence/recurrence_helpers3';
import { ensureLocalDateTime } from 'models/recurrence/recurrence_helpers2';
import { prepRecurrence, RecurrenceScheduleType } from 'models';



const LeeWayTemplatesConversionPage = () =>
{
	const { claims } = useAuthData();
	
	const write = useWriteMultiple();
	
	useTitle('LeeWay Data')
	
	
	const templates = data as any[]
	
	const running_hour_templates = templates?.filter(x => x?.ReadingTypes?.length > 0);
	
	
	const daily_templates     = templates?.filter(x => x?.FrequencyType === 'daily');
	const weekly_templates    = templates?.filter(x => x?.FrequencyType === 'weekly');
	const monthly_templates   = templates?.filter(x => x?.FrequencyType === 'monthly');
	const yearly_templates    = templates?.filter(x => x?.FrequencyType === 'yearly');
	const on_demand_templates = templates?.filter(x => x?.FrequencyType === 'ondemand');
	
	const unscheduled_templates = templates?.filter(x => x?.FrequencyType === null);
	
	
	
	
	console.log('Templates', {
		running_hour_templates,
		daily_templates,
		weekly_templates,
		monthly_templates,
		yearly_templates,
		on_demand_templates,
		unscheduled_templates,
	});
	
	
	
	
	
	let frequency_types = uniqueBy(
		templates,
		x => x?.FrequencyType
	).map(x => x.FrequencyType)
	
	
	
	console.log('frequency_types', {
		frequency_types,
	});
	
	
	
	const all_templates_transformed = transformHelmFormsToTemplates(templates, claims);
	
	let all_pairs = templates.map((x, i) => {
		return [x, all_templates_transformed[i]]
	})
	
	
	let templates_with_readings = all_templates_transformed.filter(x =>
		!!x.sections?.filter(y =>
			!!y.fields.filter(z =>
				z.action_type === 'reading'
			)?.length
		)?.length
	)
	
	
	// let template_with_components = templates.filter(x => x.Component);
	
	
	let templates_with_components = [];
	let converted_templates_with_components = [];
	
	
	templates.forEach((x, i) => {
		if(x.Component)
		{
			templates_with_components.push(x)
			converted_templates_with_components.push(all_templates_transformed[i])
		}
	});
	
	
	let component_records = []
	// let components = []
	
	all_templates_transformed.forEach(x =>
		!!x.sections?.forEach(y =>
			!!y.fields.forEach(z => {
				if(z?.reading?.component_record)
				{
					component_records.push(z?.reading?.component_record)
				}
				
			})?.length
		)?.length
	)
	
	let unique_records = uniqueBy(component_records, x => x._id)
	let grouped_records = groupBy(component_records, x => x._id)
	
	
	let striker_templates = all_templates_transformed.filter(x => x.asset_name === 'Striker')
	
	
	let pairs = striker_templates.map(x => {
		
		if(!x.recurrence.recurrence_schedule)
		{
			console.log(x);
		}
		
		return ([
			x, projectedOccurrences(
				x.recurrence,
				1,
				(x.recurrence?.last_automation_datetime)
					? ensureLocalDateTime(x.recurrence.last_automation_datetime)
					: DateTime.local(),
			)
		])
	})
	
	
	
	console.log('%cAll data transformed', 'font-size: 18px; color: lime', {
		all_templates_transformed,
		striker_templates,
		pairs,
		
		converted_templates_with_components,
		templates_with_components,
		
		templates,
		all_pairs,
		templates_with_readings,
		component_records,
		unique_records,
		grouped_records,
	});
	
	
	
	
	
	const mapDailyTemplates = () =>
	{
		const daily_data_transformed = transformHelmFormsToTemplates(daily_templates, claims);
		
		let pairs = daily_templates.map((x, i) => {
			return [x, daily_data_transformed[i]]
		})
		
		console.log('%cDaily data transformed', 'font-size: 18px; color: lime', {
			daily_data_transformed,
			daily_templates,
			pairs,
		});
	}
	
	
	const mapWeeklyTemplates = () =>
	{
		const weekly_data_transformed = transformHelmFormsToTemplates(weekly_templates, claims);
		
		let pairs = weekly_templates.map((x, i) => {
			return [x, weekly_data_transformed[i]]
		})
		
		console.log('%cWeekly data transformed', 'font-size: 18px; color: lime', {
			weekly_data_transformed,
			weekly_templates,
			pairs,
		});
	}
	
	
	const mapMonthlyTemplates = () =>
	{
		const monthly_data_transformed = transformHelmFormsToTemplates(monthly_templates, claims);
		
		let pairs = monthly_templates.map((x, i) => {
			return [x, monthly_data_transformed[i]]
		})
		
		console.log('%cMonthly data transformed', 'font-size: 18px; color: lime', {
			monthly_data_transformed,
			monthly_templates,
			pairs,
		});
	}
	
	
	const mapYearlyTemplates = () =>
	{
		const yearly_data_transformed = transformHelmFormsToTemplates(yearly_templates, claims);
		
		let pairs = yearly_templates.map((x, i) => {
			return [x, yearly_data_transformed[i]]
		})
		
		console.log('%cYearly data transformed', 'font-size: 18px; color: lime', {
			yearly_data_transformed,
			yearly_templates,
			pairs,
		});
	}
	
	
	
	
	
	const uploadComponentRecords = () =>
	{
		write(
			'organizations/' + claims.organization_id + '/component_records',
			component_records
		);
		
		
		console.log('%cUploadComponentRecords', 'font-size: 18px; color: lime', {
			component_records,
		});
	}
	
	
	const uploadTemplates = () =>
	{
		console.log(striker_templates);
		
		
		let striker_templates_docs = striker_templates.map(x => {
			let z = structuredClone(x);
			
			z.recurrence = prepRecurrence(x.recurrence)
			
			return z;
		})
		
		
		write(
			'organizations/' + claims.organization_id + '/work_order_templates',
			striker_templates_docs
		);
		
		
		// console.log('%cUploadComponentRecords', 'font-size: 18px; color: lime', {
		// 	all_templates_transformed,
		// });
	}
	
	
	
	return (
		<div>
			<h2>
				LeeWay Templates Conversion Page
			</h2>
			
			
			<Stack>
				<Button
					onClick={mapDailyTemplates}
				>
					Map {daily_templates.length} Daily Templates
				</Button>
				
				<Button
					onClick={mapWeeklyTemplates}
				>
					Map {weekly_templates.length} Weekly Templates
				</Button>
				
				<Button
					onClick={mapMonthlyTemplates}
				>
					Map {monthly_templates.length} Monthly Templates
				</Button>
				
				<Button
					onClick={mapYearlyTemplates}
				>
					Map {yearly_templates.length} Yearly Templates
				</Button>
				
				<Button
					onClick={uploadComponentRecords}
				>
					Upload Component Records
				</Button>
				
				<Button
					onClick={uploadTemplates}
				>
					Upload Templates
				</Button>
				
				<Stack>
					{
						striker_templates.map(x =>
							<Stack
								key={x._id}
							>
								{x.title}
							</Stack>
						)
					}
				</Stack>
				
			</Stack>
			
		</div>
	)
};


export default LeeWayTemplatesConversionPage;
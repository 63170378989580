import { List } from '@mui/material';
import { StorageFile } from 'models';
import FileListItem from './FileListItem';


const FileList =
({
	files
} : {
	files : StorageFile[]
}
) =>
{
	if(!files || files.length === 0)
	{
		return null;
	}
	
	
	//console.log(files);
	
	
	// TODO: Why are files sometimes undefined?
	let items = files.filter(x => x).map((file : StorageFile) =>
		<FileListItem
			file={file}
			key={file._id}
		/>
	)
	
	
	return (
		<div
			className='file_list'
		>
			<List>
				{items}
			</List>
		</div>
	)
}


export default FileList;
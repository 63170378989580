import { Typography } from '@mui/material';
import { Stack, Step, StepButton, Stepper } from '@mui/material';
import { useURLStepNum } from 'utils/hooks';


const StepperGizmoHeader =
({
	current_heading,
	headings,
	completed,
}) =>
{
	const { step, setStep } = useURLStepNum();
	
	
	const handleStep = (step: number) => () =>
	{
		setStep(step + 1);
	};
	
	
	return (
		<Stack
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: 48,
				pl: 2,
				bgcolor: 'bg.stepper_header',
				padding: '12px',
				boxShadow: '0 1px 3px -1px #0004',
				zIndex: 1,
			}}
		>
			<Stepper
				nonLinear
				activeStep={step - 1}
				sx={{
					width: 'fit-content',
					
					margin: '0 auto',
					marginTop: '-1px',
					marginBottom: '1px',
					
					'& .MuiStepLabel-label': {
						color: '#0006',
					},
					'& .MuiSvgIcon-root': {
						color: '#0003',
					},
				}}
			>
				{
					headings.map((heading, index) => (
						<Step
							key={heading}
							completed={completed[index]}
							sx={{
								paddingLeft: '16px',
							}}
						>
							<StepButton
								// color='inherit'
								onClick={handleStep(index)}
								sx={{
									padding: '12px',
									whiteSpace: 'nowrap',
									
									// TODO: theme
									transition: '.25s',
									
									'&:hover': {
										background: '#00000008',
									},
								}}
							>
								<Typography
									sx={{
										fontWeight: '500',
									}}
								>
									{heading}
								</Typography>
							</StepButton>
						</Step>
					))
				}
			</Stepper>
		</Stack>
	)
}


export default StepperGizmoHeader;
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { AvatarGroup, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar } from '@mui/material';
import { User } from 'types';
import AvatarOrError from 'components/images/AvatarOrError';
import UserAvatar from 'components/avatars/UserAvatar';



// styles components from MUI
const Accordion = styled((props: AccordionProps) => (
		<MuiAccordion children={''} disableGutters elevation={0} square {...props} />
	))
	(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
		borderBottom: 0,
	},
		'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))
	(({ theme }) => ({
		backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




const CollaboratorsAccordion  = ({
	users,
} : {
	users: User[],
}) =>
{
	const [expanded, setExpanded] = React.useState<string | false>('panel1');

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};
	
	
	
	return (
		<Accordion onChange={handleChange('panel1')} sx={{ width: '100%' }}>
			
			<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
				<AvatarGroup max={4}>
					{
						users.map(user => <UserAvatar key={user._id} user={user} />)
					}
				</AvatarGroup>
			</AccordionSummary>
			
			<AccordionDetails>
				
				<List>
					{
						users.map(user => {
							return (
								<ListItem key={user._id} disablePadding>
									<ListItemButton>
										<ListItemAvatar>
											<UserAvatar user={user}/>
										</ListItemAvatar>
										<ListItemText
											primary={user.display_name}
											secondary={user.email}
										/>
									</ListItemButton>
								</ListItem>
								
							)
						})
					}
				</List>
				
			</AccordionDetails>
		</Accordion>
	)
};


export default CollaboratorsAccordion;
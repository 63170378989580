import { DateTime } from 'luxon';
import { Asset, User } from 'types';



// For records of certificates of individuals and assets
export interface CertificationRecord
{
	_id: string,
	certification_id: string,
	
	obtained_date?: string | DateTime,
	expiry_date?: string | DateTime,
	last_modified_date?: string,
	
	note?: string,
	
	files?: any[],
	
	status: CertificationRecordStatus,
	never_expires?: boolean,
}



// To save actual certificates
export interface Certification
{
	_id: string,
	
	name: string,
	certification_for: CertificationFor,
	
	
	category?: string,
	type?: string,
	lead_time_in_days?: number,
	
	is_required_to_operate: boolean,
	is_physical_copy_required: boolean,
	
	// assigned to
	asset_id?: string,
	asset_name?: string,
	
	user_id?: string,
	user_name?: string,
	
	role_id?: string,
	role_name?: string,
	
	created_at?: string,
	
	updated_record_status?: CertificationRecordStatus,
	updated_record_obtained_date?: string,
	updated_record_expiry_date?: string,
	never_expires?: boolean,
}



export enum CertificationFor
{
	COMPANY_WIDE_ROLE = 'Company Wide Role',
	USER = 'User',
	ASSET = 'Asset',
	COMPANY_WIDE_ASSETS = 'Company Wide Assets'
}


export enum CertificationRecordStatus
{
	ACTIVE = 'active',
	INACTIVE = 'inactive'
}


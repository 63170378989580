import { Stack } from '@mui/material';
import Formlet from '../Formlet';
import VerticalCounter from './VerticalCounter';


export const DurationFormlet =
({
	name = '',
	label = 'Duration',
	help = 'How often should this template generate work orders?',
	units =
	[
		'years',
		'months',
		'weeks',
		'days',
		'hours',
		'minutes',
	]
} : {
	name?: string,
	label?: string,
	help?: string,
	units?: string[],
}) =>
{
	let base_name = name;
	
	
	if(base_name?.length)
	{
		if(!base_name.endsWith('.'))
		{
			base_name = base_name + '.';
		}
	}
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			
			<Stack
				direction='row'
				justifyContent='center'
			>
				{
					units.map(unit =>
						<VerticalCounter
							name={base_name + unit}
							units={unit}
							key={unit}
						/>
					)
				}
			</Stack>
			
		</Formlet>
	);
}


export default DurationFormlet;
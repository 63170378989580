

export const dotJoin = (...strings: string[]) : string =>
{
	return (
		strings.filter(Boolean).join('.')
	)
}



// TODO: Typing?
export const commaAndJoin = (...strings: string[]) : string =>
{
	// if(typeof strings === 'string')
	// {
	// 	return strings;
	// }
	// if(typeof strings === 'string')
	// {
	// 	return first + strings;
	// }
	
	let parts = strings.filter(Boolean);
	
	
	if(parts.length === 0)
	{
		return '';
	}
	else if(parts.length === 1)
	{
		return parts[0];
	}
	else if(parts.length === 2)
	{
		return parts[0] + ' and ' + parts[1];
	}
	else
	{
		return parts.slice(0, parts.length - 1).join(', ') + ' and ' + parts[parts.length - 1];
	}
}






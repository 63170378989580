import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import BasicDialog from 'app/widgets/dialogs/BasicDialog';


interface MaybeDialogI
{
	className?: string,
	
	dialog_title: string,
	dialog_submit_text: string,
	dialog_content?: ReactNode,
	dialogClassName?: string,
	
	handleClose?: Function,
	handleSubmit?: Function,
	
	// Dialog will be shown if this URL search query parameter is set
	open_key?: string,
	
	sx?: Object,
	
	// Content may be provided via dialog_content or as children
	children?: ReactNode,
}


const MaybeDialog =
({
	dialog_title,
	dialog_submit_text,
	dialog_content,
	
	handleClose,
	handleSubmit,
	
	// Dialog will be shown if this URL search query parameter is set
	open_key = 'show_dialog',
	
	sx,
	
	children,
	
	...rest
}
: MaybeDialogI) =>
{
	const [ search_params, setSearchParams ] = useSearchParams();
	
	
	const is_open = !!search_params.get(open_key);
	
	
	// Removes the specified parameter from URL search query parameters
	const removeTargetParam = () =>
	{
		if(search_params.has(open_key))
		{
			search_params.delete(open_key);
			
			setSearchParams(search_params);
		}
	}
	
	
	const onClose = () =>
	{
		removeTargetParam();
		
		handleClose?.();
	}
	
	
	const onSubmit = () =>
	{
		removeTargetParam();
		
		handleSubmit?.();
	}
	
	
	return (
		<BasicDialog
			handleClose={onClose}
			handleSubmit={onSubmit}
			
			open={is_open}
			
			title_text={dialog_title}
			submit_text={dialog_submit_text}
			
			sx={sx}
			
			{...rest}
		>
			{dialog_content}
			{children}
		</BasicDialog>
	)
}


export default MaybeDialog;
import { Box, Typography, Stack } from '@mui/material';

import SelectTagsFormlet from 'components/selectTags/SelectTagsFormlet';
import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Tag } from 'types';
import { useStyles } from '../../styles/theme';
import { AttachmentsFormlet } from '../components/AttachmentsFormlet';


const CreateIssueTags = () =>
{
	const { setValue, formState: {errors}, control } = useFormContext();
	
	const sessionContext = useSessionContext();
	
	const [selectedTags, setSelectedTags] = useState([])
	
	const classes = useStyles();
	
	
	console.log(errors);
	
	
	const handleTagChange = (event: React.ChangeEvent<{}>, newValue: Tag[] | null) =>
	{
		let tag_ids = [];
		
		newValue.forEach((tag) =>
		{
			tag_ids.push(tag._id);
		});
		
		setValue('tag_ids', tag_ids);
		
		setSelectedTags(newValue);
	}
	
	
	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			align-items='flex-start'
			sx={{
				height: '100%',
			}}
		>
			<Box className={classes.BoxCol} sx={{ marginBottom: '50px' }}>
				
				<Box
					sx={{
						alignContent: 'left',
						width: '100%',
					}}
				>
					<Typography variant='h3'>
						Add Tags
					</Typography>
				</Box>
				
				<SelectTagsFormlet 
					label={'Type to search'}
					id='create-issue-tags'
					multiple={true}
					value={selectedTags}
					onChange={handleTagChange}
					tags={sessionContext.all_available_tags}
				/>
				
			</Box>
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					<Typography variant='h3'>
						Attachments
					</Typography>
				</Box>
				
				<AttachmentsFormlet control={control}/>
				
			</Box>
			
		</Stack>
		
	)
}


export default CreateIssueTags;
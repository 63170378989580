import { Controller } from 'react-hook-form';
import { OutlinedInput } from '@mui/material';
import { ShortTextField } from 'models/fields';
import Formlet from 'app/gizmos/fields/Formlet';
import CorIcon from 'components/icons/CorIcon';


export const SearchBarNew =
({
	name,
	label,
	help,
	sx,
	...rest
}
: ShortTextField) =>
{
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<OutlinedInput
						fullWidth
						name={name}
						value={value}
						onChange={(e) =>
							onChange(e.target.value)
						}
						
						endAdornment={<CorIcon of={'search'} size='18px' />}
						
						sx={{
							bgcolor: 'field.default',
							
							'&:hover': {
								bgcolor: 'field.hover',
							},
							
							'&.Mui-focused': {
								bgcolor: 'field.focus',
							},
							...sx,
						}}
						
						{...rest}
					/>
				}
			/>
		</Formlet>
	);
}


export default SearchBarNew;
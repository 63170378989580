import { useEffect, useState } from 'react';
import { Control, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Box, Chip, InputLabel, styled } from '@mui/material';

import CorIcon from 'components/icons/CorIcon';

import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';

import { WorkOrderDescriptionFormData } from 'pages/CreateWorkOrder/work_order_types';
import { useCor } from 'app/providers/CorProvider';


export interface EquipmentsProps
{
	control: Control<any, any>;
}


export const Equipments = ({ control }: EquipmentsProps) =>
{
	const { setValue } = useFormContext();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	const { replace, remove: unSelectComponent } = useFieldArray(
		{
			control,
			name: 'equipment',
		}
	);
	
	
	const { selected_components, setSelectedComponentIds } = useCor();
	
	
	const components = useWatch({
		control,
		name: 'equipment',
	});
	
	
	const handleSelectComponents = (equipment) =>
	{
		console.log('handleSelectComponents', equipment);
		
		setSelectedComponentIds(equipment.map(x => x._id));
		
		// TODO: This causes crash
		setValue(
			'equipment',
			equipment.map(x => ({
				_id: x._id,
				name: x.name,
				asset_id: x?.asset_id,
				asset_name: x?.asset_name,
				system_id: x?.system_id,
				system_name: x?.system_name
			}))
		)
		
		setValue(
			'asset',
			equipment.map(x => ({
				_id: x._id,
				name: x.name
			})[0])
		)
		
		setCloseDialog(!closeDialog);
	};
	
	
	
	
	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		}}>
			<InputLabel
				required={true}
				sx={{
					fontSize: '16px',
					color: 'black',
				}}
			>
				Add Equipment
			</InputLabel>
			
			{components.length > 0 && (
				<Box
					sx={{
						border: `1px solid #d8dbdf`,
						borderRadius: '3px',
						padding: '0.5rem',
						display: 'flex',
						gap: '0.5rem',
						flexWrap: 'wrap',
						marginTop: '1rem',
					}}
				>
					{components.map((component, index) => (
						<StyledChip
							label={component.name}
							deleteIcon={
								<CorIcon of='close' size='15px' color='#fff' />
							}
							onDelete={() => unSelectComponent(index)}
						/>
					))}
				</Box>
			)}
			
			<ButtonToOpenDialog
				button_variant='text'
				dialog_title='Select Equipment'
				button_content='Add Equipment'
				dialog_content={
					<ComponentSelection
						selected_components={components}
						selectEquipment={handleSelectComponents}
					/>
				}
				closeDialog={closeDialog}
				startIcon={<CorIcon of='asset_tree' size='20px'/>}
				sx={{
					width: 'fit-content'
				}}
			/>
		</Box>
	);
};


export const StyledChip = styled(Chip)(() => ({
	backgroundColor: '#C85656',
	color: 'white',
}));


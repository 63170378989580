import Paper from '@mui/material/Paper';
import { CircularProgress, Divider, IconButton, Stack, Typography } from '@mui/material';

import { useStyles } from '../../../styles/ManageTagsTabTheme';
import { Tag } from 'types';
import CorIcon from 'components/icons/CorIcon';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useState } from 'react';
import { useSession } from 'app/providers/SessionProvider';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Role, RoleTag, TagRole } from 'models/users/user_interfaces';
import { useCollection, useDelete, useDoc, useWrite } from 'ember';
import { useAuthData } from 'stuff';
import TagChip from 'app/dashboard/pages/issueCollaboration/issueDetails/TagChip';
import TagRoles from '../TagRoles';
import { where } from 'firebase/firestore';


const TagCard = ({
	tag
} : {
	tag: Tag,
}) =>
{
	const classes = useStyles({});
	
	const write = useWrite();
	const deleteDoc = useDelete();
	
	const { claims } = useAuthData();
	const { roles, all_role_tags } = useSession();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	const [ selectedRoles, setSelectedRoles ] = useState<Role[]>();
	
	
	
	const org_collection_path = `organizations/${claims?.organization_id}`;
	
	// Load tag_roles & role_tags
	const tag_roles_document = useDoc(`${org_collection_path}/tag_roles`, tag._id);
	
	
	if(
		tag_roles_document?.is_loading
	) 
	return <CircularProgress />
	
	
	const tag_roles = tag_roles_document?.data as TagRole;
	
	
	
	// Links tags with roles
	const updateTag = () =>
	{
		let tag_role = {
			_id: tag._id,
		};
		
		selectedRoles.forEach(role => Object.assign(tag_role, { [role._id]: true }));
		
		
		// Adds and removes tags from ROLE TAGS -----------------------------------------------------
		const selected_role_ids = selectedRoles.map(role => role._id);
		const filtered_role_tags_with_selected_ids = all_role_tags.filter(x => selected_role_ids.includes(x._id));
		
		
		const deleted_role_ids = (tag_roles !== undefined) &&
			Object.keys(tag_roles).filter(x => !selected_role_ids.includes(x) && (x!=='_id')) || [];
		
		const filtered_role_tags_with_deleted_role_ids = all_role_tags.filter(x => deleted_role_ids.includes(x._id));
		
		console.log('aayoyooooooooo', {deleted_role_ids})
		
		// Adds tags to roles
		filtered_role_tags_with_selected_ids?.forEach(role_tag =>
		{
			if(!role_tag[tag._id])
			{
				let new_role_tag = {
					[tag._id]: true,
					...role_tag
				}
				
				write(`${org_collection_path}/role_tags`, new_role_tag);
			}
		});
		
		
		if(deleted_role_ids.length > 0)
		{
			filtered_role_tags_with_deleted_role_ids?.forEach(role_tag => {
				
				const new_role_tag = role_tag;
				delete new_role_tag[tag._id]
				
				console.log('wwwwwwwwwwwwwwwwwwwwwwwwww', {role_tag}, {new_role_tag});
				
				write(`${org_collection_path}/role_tags`, new_role_tag);
			})
		}
		
		// ---------------------------------------------------------------------------------------------
		
		
		// Writes new TAG ROLE to db
		write(`${org_collection_path}/tag_roles`, tag_role);
		
		setCloseDialog(!closeDialog);
	};
	
	
	// Deletes tag from DB
	const deleteTag = () =>
	{
		console.log({tag});
		
		deleteDoc(`${org_collection_path}/tags`, tag);
	}
	
	
	// Filteres out the assigned roles from the roles collection
	const filtered_roles = roles.filter(role => !selectedRoles?.map(x => x._id).includes(role._id));
	
	
	
	
	
	
	return (
		<Stack
			width='100%'
			direction='column'
			alignItems='flex-start'
			justifyContent='center'
			spacing={3}
		>
			<Stack
				width='100%'
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ 
					marginTop: '5px',
				}}
			>
				<TagChip tag={tag}/>
				
				<Stack
					direction='row'
					spacing={1}
					alignItems='center'
				>
					<ButtonToOpenDialog
						button_type='icon_button'
						dialog_submit_text='Confirm'
						dialog_title='Assign Roles to Tag'
						dialog_content={
							<>
								<Autocomplete
									multiple
									disablePortal
									options={filtered_roles}
									value={selectedRoles}
									getOptionLabel={(option: Role) => option.name}
									onChange={(event, newValue: Role[]) => setSelectedRoles(newValue)}
									renderTags={(value, getTagProps) =>
										value.map((option: Role, index) =>
										{
											return (
												<Chip
													variant='outlined'
													label={option.name}
													{...getTagProps({ index })}
												/>
											);
										})
									}
									renderInput={(params) => (
										<TextField
											{...params}
											variant='outlined'
											label='Roles'
											helperText='You can assign multiple roles to a tag'
										/>
									)}
									sx={{
										marginTop: '10px',
										width: '50%'
									}}
								/>
							</>
						}
						buttonIcon={<CorIcon of='edit' size='24px' />}
						closeDialog={closeDialog}
						handleSubmit={updateTag}
						dialogClassName='add_tags_dialog'
					/>
					
					<IconButton
						onClick={deleteTag}
					>
						<CorIcon of='delete' size='24px' />
					</IconButton>
				</Stack>
			</Stack>
			
			
			<Stack>
				<TagRoles
					tag_roles={tag_roles}
					setSelectedRoles={setSelectedRoles}
				/>
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
			
		</Stack>
	)
}


export default TagCard;
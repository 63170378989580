import { Stack } from '@mui/material';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import IoTDataGraph from './IoTDataGraph';

import TreeProvider  from './tree/widgets/TreeProvider';
import TreeHierarchy from './tree/widgets/TreeHierarchy';
import { tree_root } from './tree/tree_data';
import Victor from './victor/Victor';
import VictorProvider from './victor/VictorProvider';

// import ZoomExample from './ZoomExample';


const IotAnalyticsDashboardMain = () =>
{
	return (
		<TreeProvider
			data={{
				root: tree_root,
			}}
		>
			<VictorProvider>
				<Stack
					justifyContent='center'
					alignItems='center'
					width='100%'
					sx={{
						padding: '10px',
					}}
				>
					<ParentSize>
						{({ width, height }) =>
							<Victor
								width={width}
								height={300}
							/>
						}
					</ParentSize>
					<ParentSize>
						{({ width, height }) =>
							<TreeHierarchy
								width={width}
								height={750}
							/>
						}
					</ParentSize>
					<ParentSize>
						{({ width, height }) =>
							<IoTDataGraph
								width={width}
								height={500}
							/>
						}
					</ParentSize>
					{/* <ParentSize>
						{({ width, height }) =>
							<ZoomExample width={width} height={800} />
						}
					</ParentSize> */}
				</Stack>
			</VictorProvider>
		</TreeProvider>
	)
}


export default IotAnalyticsDashboardMain;
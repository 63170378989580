import { Stack, Typography } from '@mui/material';
import CreateRequistionStepper from './CreateRequisitionStepper';
import RequisitionDataProvider from '../RequisitionDataProvider';



const CreateRequisitionPage = () =>
{
	return (
		<RequisitionDataProvider>
			<Stack
				flexDirection='column'
				sx={{
					height: '100vh'
				}}
			>
				<Typography
					variant='h2'
					sx={{
						marginLeft: '10px',
						marginTop: '10px',
					}}
				>
					Create a New Requisition
				</Typography>
				
				<CreateRequistionStepper />
			</Stack>
		</RequisitionDataProvider>
	)
}


export default CreateRequisitionPage;
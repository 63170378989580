import { useEffect, useState } from 'react';

import { useAuth } from 'reactfire';
import { User } from 'firebase/auth';
import { useSearchParams } from 'react-router-dom';


// TODO: Should move to ember if useful
// TODO: May not need - review reactfire's useUser
export const useUser = () =>
{
	const auth = useAuth();
	
	const [ current_user, setUser ] = useState<User>();
	
	
	auth.onIdTokenChanged((user : User) =>
	{
		console.log(user);
		
		if(user)
		{
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User
			
			setUser(user);
		}
		else
		{
			// User is signed out
			// ...
		}
	});
	
	return current_user;
}





// Uses URL query params to store/set a number, converting between string and number
export const useURLQueryNum = ( key : string, initial_value: number ) =>
{
	let [ search_params, setSearchParams ] = useSearchParams();
	
	
	// TODO: Prioritize function argument or current URL query?
	let value = Number(search_params.get(key)) || initial_value || 1;
	
	
	const setValue = (new_value: number) =>
	{
		search_params.set(key, new_value.toString());
		
		setSearchParams(search_params);
	}
	
	
	
	return [
		value,
		(new_value: number) => setValue(new_value),
		() => setValue(value + 1),
		() => setValue(value - 1),
	]
}





// Uses URL query params to store/set the current step, converting between string and number
export const useURLStepNum = () =>
{
	let [ search_params, setSearchParams ] = useSearchParams();
	
	
	let key = 'step';
	
	
	let step = Number(search_params.get(key)) || 1;
	
	
	const setStep = (step_num: number) =>
	{
		search_params.set(key, step_num.toString());
		
		setSearchParams(search_params);
	}
	
	
	
	return {
		step,
		setStep: (step: number) => setStep(step),
		nextStep: () => setStep(step + 1),
		prevStep: () => setStep(step - 1),
	}
}


// Uses URL query params to store/set the currently selected tab, defaulting to the first
// TODO: Properly support plain array - currently expects an object mapping URL keys to labels
export const useURLTab =
(
	tab_titles?: { [key: string]: string } | string[],
	default_key?: string
) =>
{
	let [ search_params, setSearchParams ] = useSearchParams();
	
	
	if(default_key === undefined)
	{
		if(tab_titles instanceof Object)
		{
			default_key = Object.keys(tab_titles)[0];
		}
		
		// Note that arrays are not properly handled yet
		// else //if(tab_titles.length > 0)
		// {
		// 	// default_key = tab_titles?.[0];
		// 	default_key = '1';
		// }
	}
	
	return {
		tab: search_params.get('tab') || default_key || tab_titles?.[0] || '1',
		setTab: (tab: number | string) => setSearchParams({ tab: tab.toString() }),
	}
}



// Uses URL query params to store/set a single value
// TODO: Handle removing/unsetting, also add a new hook for handling arrays
export const useURLQueryParam =
(
	url_param_key: string,
	default_value?: string,
) : [
	value: string,
	setValue: Function,
] =>
{
	let [ search_params, setSearchParams ] = useSearchParams();
	
	
	let value = search_params.get(url_param_key);
	
	// if(value)
	// {
	// 	console.log({
	// 		url_param_key,
	// 		value,
	// 	});
	// }
	
	
	return [
		(value === null)
			? default_value
			: value
		,
		( new_value : string ) =>
		{
			// console.log(
			// 	`URL param changed from ${value} to ${new_value}`
			// );
			
			search_params.set(url_param_key, new_value);
			
			setSearchParams(search_params);
		},
	]
}


// Uses URL query params to store/set a multiple values
// TODO: Handle removing/unsetting, also add a new hook for handling arrays
export const useURLQueryParamMulti =
(
	url_param_key: string,
	default_values?: string[],
) : [
	values: string[],
	setValues: Function,
] =>
{
	let [ search_params, setSearchParams ] = useSearchParams();
	
	
	let values = search_params.getAll(url_param_key);
	
	// if(values)
	// {
	// 	console.log({
	// 		url_param_key,
	// 		values,
	// 	});
	// }
	
	
	return [
		(values === null)
			? default_values
			: values
		,
		( new_values : string[] ) =>
		{
			console.log(
				`URL param changed from ${values} to ${new_values}`
			);
			
			if(new_values.length === 0)
			{
				search_params.delete(url_param_key);
			}
			else
			{
				new_values.forEach((x, i) => {
					if(i === 0)
					{
						search_params.set(url_param_key, x);
					}
					else
					{
						search_params.append(url_param_key, x);
					}
				})
			}
			
			setSearchParams(search_params);
		},
	]
}



// If no default value is provided, the first defined enum value will
// be returned as the selected value
export const useURLEnumParam =
(
	url_param_key: string,
	enum_type: any,
	default_value?: string,
) : [
	value: string,
	setValue: Function,
] =>
{
	
	
	
	if((!!enum_type) && (default_value === undefined))
	{
		default_value = Object.keys(enum_type)[0];
	}
	
	
	let [ value, setValue ] = useURLQueryParam(url_param_key, default_value);
	
	
	// console.log({
	// 	enum_type,
	// 	value,
	// 	// actual_value: getEnumKeyByValue(enum_type, value),
	// })
	
	
	return [
		value,
		setValue,
		// actual_value: enum_type[value],
	]
}



// Allows you to go backwards from an enum value to an enum key
// TODO: Generic typing with return type based on input?
export const getEnumKeyByValue = ( enum_type: any, target_value: string ) : string =>
{
	let entries = Object.entries(enum_type);
	
	// TODO: Match either key or value?
	let match = entries.find((entry, i) =>
		(target_value === entry[0]) || (target_value === entry[1])
	)
	
	// console.log({
	// 	entries,
	// 	target_value,
	// 	match,
	// });
	
	// [key, value] => key
	// Ex: RecurrenceSchedule.DAILY
	
	return match?.[0];
}



export const useFocus = (ref, defaultState = false) =>
{
	const [ is_focused, setIsFocused ] = useState(defaultState);
	
	
	useEffect(
		() =>
		{
			const onFocus = () => setIsFocused(true);
			const onBlur = () => setIsFocused(false);
			
			let current = ref.current;
			
			current.addEventListener('focus', onFocus);
			current.addEventListener('blur', onBlur);
			
			return () =>
			{
				current.removeEventListener('focus', onFocus);
				current.removeEventListener('blur', onBlur);
			}
		},
		[ref]
	);
	
	return is_focused;
};



import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import { useCorsAnalyticsState } from './CorsAnalyticsStateProvider';



const CorsAnalyticsSearchParameters = () =>
{
	const { assets } = useCorsAnalyticsState();
	
	return (
		<SearchableSelectFormlet
			name='assets'
			label='Filter by Assets'
			options={assets}
			getOptionLabel={option => option.name}
			multiple={true}
		/>
	)
}


export default CorsAnalyticsSearchParameters;
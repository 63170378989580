import { DateTime } from 'luxon';
import { Button, Stack } from '@mui/material';
import DatePickerWidget from './DatePickerWidget';
import TimePickerWidget from './TimePickerWidget';
import { Controller, useFormContext } from 'react-hook-form';
import Formlet from '../Formlet';
import CorIcon from 'components/icons/CorIcon';


// Automatically reads and write to enclosing form (via FormProvider and the name)
const DatetimeFormlet =
({
	name = 'datetime',
	label = 'Date/Time',
	help = 'Calculate relative dates from this date (may also be used to delay work order generation)',
	onChange,
} : {
	name?: string,
	label?: string,
	help?: string,
	onChange?: Function,
}) =>
{
	const form_context = useFormContext();
	
	const selected_datetime = form_context.watch(name) || DateTime.local();
	
	
	
	const handleChange = (new_datetime: DateTime) =>
	{
		if(new_datetime.isValid)
		{
			console.log({
				new_datetime,
			})
			
			onChange?.(new_datetime);
			
			form_context.setValue(
				name,
				new_datetime,
			);
		}
	}
	
	
	const handleDateChange = (new_datetime: DateTime) =>
	{
		if(new_datetime.isValid)
		{
			handleChange?.(selected_datetime.set({
				year:  new_datetime.year,
				month: new_datetime.month,
				day:   new_datetime.day,
				second: 0,
				millisecond: 0,
			}));
		}
	}
	
	
	const handleTimeChange = (new_datetime: DateTime) =>
	{
		if(new_datetime.isValid)
		{
			handleChange?.(selected_datetime.set({
				hour:   new_datetime.hour,
				minute: new_datetime.minute,
				second: 0,
				millisecond: 0,
			}));
		}
	}
	
	
	const handleDatetimeReset = () =>
	{
		handleChange?.(DateTime.local());
	}
	
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<Stack
						className='datetime_picker'
						direction='row'
						gap={2}
						justifyContent='center'
						sx={{
							marginTop: 1,
						}}
					>
						<DatePickerWidget
							name={name}
							value={selected_datetime}
							onChange={handleDateChange}
						/>
						<TimePickerWidget
							name={name}
							value={selected_datetime}
							onChange={handleTimeChange}
						/>
						<Button
							onClick={handleDatetimeReset}
						>
							<CorIcon
								of='reset_datetime'
								size='26px'
							/>
						</Button>
					</Stack>
				}
			/>
		</Formlet>
	)
}


export default DatetimeFormlet;
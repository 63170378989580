import React from 'react'

import { useState } from 'react';
import { useTitle } from 'stuff';

import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useRequisition } from '../RequisitionDataProvider';
import RequisitionCollabDetails from './requisitionCollabDetails/RequisitionCollabDetails';
import RequisitionChat from './RequisitionChat';
import RequisitionActions from './requisitionActions/RequistionActions';




const tab_titles = ['Details', 'Communication', 'Actions'];



const getTabNumberFromSearchParam = (tab: string | undefined) =>
{
	switch (tab)
	{
		case 'details':
			return 1;
		case 'communication':
			return 2;
		case 'actions':
			return 3;
		default:
			return 1;
	}
};



const RequisitionCollabPageTabs = () => 
{
	const { search } = useLocation();

	const { requisition } = useRequisition();
	

	const tab = getTabNumberFromSearchParam(
		new URLSearchParams(search).get('tab')
	);

	const [selected_tab, setSelectedTab] = useState(`${tab}`);

	useTitle(
		`Requisition #${requisition.requisition_num} - ${requisition.title} - ${
			tab_titles[parseInt(selected_tab) - 1]
		}`
	);



	const handleChange = (event: React.SyntheticEvent, newValue: string) =>
	{
		setSelectedTab(newValue);
		
		const url = new URL(document.location.href)
		
		switch(newValue)
		{
			case '1':
				url.searchParams.set('tab', 'details');
				break;
			case '2':
				url.searchParams.set('tab', 'communication');
				break;
			case '3':
				url.searchParams.set('tab', 'actions');
				break;
			default:
				url.searchParams.set('tab', 'details');
				break;
		}
		
		window.history.pushState({}, document.title, url.toString());
	};


	const tabs =
	<TabContext value={selected_tab}>
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}
			className='issue_collab_tabs'
		>
			<TabList
				onChange={handleChange}
				className='issue_collab_tabs_list'
			>
				<Tab label={tab_titles[0]} value={'1'} />
				<Tab label={tab_titles[1]} value={'2'} />
				<Tab label={tab_titles[2]} value={'3'} />
			</TabList>
		</Box>
		
		<TabPanel
			value='1'
			className='issue_collab_tab_panel'
		>
			<RequisitionCollabDetails />
		</TabPanel>
		
		<TabPanel
			value='2'
			sx={{
				padding: '0',
				// TODO: avoid magic numbers
				height: 'calc(100% - 155px)',
			}}
		>
			<RequisitionChat />
		</TabPanel>
		
		<TabPanel
			value='3'
			className='issue_collab_tab_panel'
		>
			<RequisitionActions />
		</TabPanel>
		
	</TabContext>
	
	
	
	return (
		<>
			{tabs}
		</>
	)
};


export default RequisitionCollabPageTabs;
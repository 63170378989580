import { useState, ReactNode, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { StorageFile } from 'models';
import { formatBytes } from 'utils';
import { useStorage } from 'reactfire';
import { getDownloadURL, ref } from 'firebase/storage';
import ImageOrError from 'components/images/ImageOrError';
import { Typography } from '@mui/material';


const ClickForDownloadDialog =
({
	file,
	children,
} : {
	file : StorageFile,
	children: ReactNode,
}) =>
{
	const [ open, setOpen ] = useState(false);
	const [ download_url, setDownloadUrl ] = useState('');
	const [ thumb_url, setThumbUrl ] = useState('');
	
	const storage = useStorage();
	
	
	useEffect(
		() =>
		{
			getDownloadURL(ref(storage, file.path + '_thumb'))
				.then((url) =>
				{
					setThumbUrl(url);
				})
			
			// Asynchronously sets the download href (pretty much instantly)
			getDownloadURL(ref(storage, file.path))
				.then((url) =>
				{
					setDownloadUrl(url);
				})
				.catch((error) =>
				{
					// Handle any errors
				});
		},
		[file.path, storage]
	)
	
	
	
	
	const handleClickOpen = () =>
	{
		setOpen(true);
	}
	
	const handleClose = () =>
	{
		setOpen(false);
	}
	
	
	const handleDownload = () =>
	{
		setOpen(false);
	}
	
	
	
	if(thumb_url)
	{
		var thumb = <ImageOrError
			path={thumb_url}
			className='thumb'
			title={`Thumbnail: ${file.name}`}
		/>
	}
	
	
	
	return (
		<div>
			
			<div
				onClick={handleClickOpen}
			>
				{children}
			</div>
			
			
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				className='download_dialog'
			>
				
				<DialogTitle id='alert-dialog-title'>
					Download file?
				</DialogTitle>
				
				
				<DialogContent
					sx={{
						padding: '4px 24px',
					}}
					// MUI is annoying
					style={{
						paddingTop: '4px',
					}}
				>
					<DialogContentText
						id='alert-dialog-description'
						sx={{
							padding: '8px',
						}}
					>
						<span>
							{file.name}
						</span>
					</DialogContentText>
					
					{thumb}
					
					<Typography
						className='download_dialog_file_size'
						sx={{
							textAlign: 'center',
							opacity: '.4',
						}}
					>
						{formatBytes(file.size)}
					</Typography>
					
				</DialogContent>
				
				
				<DialogActions>
					<Button onClick={handleClose}>
						Cancel
					</Button>
					<Button
						component='label'
						onClick={handleDownload}
					>
						<a
							href={download_url}
							download
							target='_blank'
							rel='noreferrer'
							className='download_dialog_download_button'
						>
							Download
						</a>
					</Button>
				</DialogActions>
				
				
			</Dialog>
		</div>
	);
}


export default ClickForDownloadDialog;
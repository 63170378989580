import React from 'react'

import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CorIcon from 'components/icons/CorIcon';
import { useNavigate } from 'react-router';
import { defaultAppTheme, responsiveAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RequisitionStatus } from 'models';
import { navigateUp } from 'utils/url_utils';
import { useRequisition } from '../RequisitionDataProvider';




const RequisitionCollabPageHeader = () => 
{
	const navigate = useNavigate();
	
	const { requisition } = useRequisition();
	const { title, status, requisition_num } = requisition;
	
	const small_screen = useMediaQuery(responsiveAppTheme.breakpoints.down('sm'));
	
	
	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			alignItems='flex-start'
			spacing={1}
			sx={{
				padding: '2px',
			}}
			className='issue_collab_header'
		>
			
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				sx={{
					cursor: 'pointer',
				}}
				// onClick={() => navigate(-1)}
				onClick={() => navigateUp(navigate)}
			>
				<Button
					sx={{
						textTransform: 'none',
						padding: '3px 4px',
					}}
				>
					<CorIcon
						of='back'
						size='25px'
						color={defaultAppTheme.palette.primary.main}
					/>
					<Typography variant='caption'>
						Back to list
					</Typography>
				</Button>
			</Stack>
			
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				sx={{
					padding: '2px 16px',
					margin: 0,
					marginTop: 0,
				}}
				style={{
					marginTop: 0,
				}}
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					sx={{
						padding: '8px',
					}}
				>
					<CorIcon
						of={
							(status === RequisitionStatus.STARTED)
								? 'requisition_open'
								: (status === RequisitionStatus.ACCOUNTS_APPROVED)
								? 'requisition_waiting_approval'
								: (status === RequisitionStatus.IN_PROGRESS)
								? 'requisition_in_progress'
								: 'requisition_completed'
							}
						size='24px'
						color={
							(status === RequisitionStatus.STARTED)
								? defaultAppTheme.palette.secondary.main
								: (status === RequisitionStatus.IN_PROGRESS)
								? defaultAppTheme.palette.primary.main
								: ((status === RequisitionStatus.ACCOUNTS_APPROVED))
								? defaultAppTheme.palette.warning.main
								: defaultAppTheme.palette.success.dark
							}
					/>
					
					<Typography
						variant='body1'
						color={
							(status === RequisitionStatus.STARTED)
								? defaultAppTheme.palette.secondary.main
								: (status === RequisitionStatus.IN_PROGRESS)
								? defaultAppTheme.palette.primary.main
								: ((status === RequisitionStatus.ACCOUNTS_APPROVED))
								? defaultAppTheme.palette.warning.main
								: defaultAppTheme.palette.success.dark
							}
					>
						{ status }
					</Typography>
				</Stack>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='flex-start'
					sx={{
						padding: '4px',
					}}
				>
					<Typography
						variant='h1'
						sx={{
							padding: '2px',
						}}
					>
						Requisition #{requisition_num}
					</Typography>
					
					{
						!small_screen &&
							<Typography
								variant='h3'
								fontWeight={300}
								sx={{
									padding: '2px',
								}}
							>
								{title}
							</Typography>
					}
				</Stack>
				
			</Stack>
			
		</Stack>
	)
}


export default RequisitionCollabPageHeader;
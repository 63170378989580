import { ReactNode, createContext, useContext, useState } from 'react';

import { useURLQueryParam } from 'utils/hooks';

import { TreeData, TreeContextData, TreeNode } from '../tree_models';
import { traverseTree } from '../tree_helpers';


// Create a context for the tree hierarchy
const TreeContext = createContext<TreeContextData | null>(null);


export const useTree = () : TreeContextData =>
{
	return useContext(TreeContext);
};


// TreeHierarchyProvider component
export const TreeProvider =
({
	data,
	children,
} : {
	data: TreeData,
	children: ReactNode,
}) =>
{
	const [ selected_id, setSelectedId ] = useURLQueryParam('selected');
	
	
	// TODO: Currently just [root], should hold full array, with root node in separate state
	const [ nodes, setNodes ] = useState<TreeNode[]>(() =>
	{
		// Convert the tree data to have bidirectional links and add optional props
		const convertTreeData = (simple_node: TreeNode, parent?: TreeNode): TreeNode =>
		{
			let _id = (parent?._id)
				? parent?._id + simple_node?.label
				: simple_node?.label;
			
			const interlinked_node: TreeNode =
			{
				parent,
				
				is_ancestor_hovered:    false,
				is_descendant_hovered:  false,
				is_ancestor_selected:   false,
				is_descendant_selected: false,
				
				_id: _id,
				
				...simple_node,
			};
			
			// If this node has children, we'll interlink them too, following the hierarchy down
			if (simple_node.children)
			{
				interlinked_node.children = simple_node.children.map((child) =>
					convertTreeData(child, interlinked_node)
				);
			}
			
			return interlinked_node;
		};
		
		return [convertTreeData(data.root)];
	});
	

	
	
	
	
	let lookup : { [_id: string]: TreeNode } = {};
	
	traverseTree(
		nodes[0],
		(item: TreeNode) =>
		{
			lookup[item._id] = item;
		}
	)
	
	
	console.log({
		lookup,
		nodes,
	});
	
	
	
	
	
	
	
	// Set the hover status of a node and its ancestors/descendants
	const onNodeHover = (node: TreeNode) =>
	{
		node.is_hovered = true;
		
		if (node.parent)
		{
			onDescendentHover(node.parent);
		}
		
		if (node.children)
		{
			node.children.forEach((child) =>
				onAncestorHover(child)
			);
		}
		
		setNodes([...nodes]);
	};
	
	
	const onAncestorHover = (node: TreeNode) =>
	{
		node.is_ancestor_hovered = true;
		
		if (node.children)
		{
			node.children.forEach((child) =>
				onAncestorHover(child)
			);
		}
	};
	
	
	const onDescendentHover = (node: TreeNode) =>
	{
		node.is_descendant_hovered = true;
		
		if (node.parent)
		{
			onDescendentHover(node.parent);
		}
	};
	
	
	
	
	// Set the selection status of a node and its ancestors/descendants
	const onNodeSelect = (node: TreeNode) =>
	{
		node.is_selected = true;
		
		if (node.parent)
		{
			onDescendentSelect(node.parent);
		}
		
		if (node.children)
		{
			node.children.forEach((child) =>
				onAncestorSelect(child)
			);
		}
		
		setSelectedId(node._id);
		
		console.log(node)
		
		setNodes([...nodes]);
	};
	
	
	const onAncestorSelect = (node: TreeNode) =>
	{
		node.is_ancestor_selected = true;
		
		if (node.children)
		{
			node.children.forEach((child) =>
				onAncestorSelect(child)
			);
		}
	};
	
	
	const onDescendentSelect = (node: TreeNode) =>
	{
		node.is_descendant_selected = true;
		
		if (node.parent)
		{
			onDescendentSelect(node.parent);
		}
	};
	
	
	
	// Render the provider with the tree hierarchy context
	return (
		<TreeContext.Provider value={{
			selected_id,
			nodes,
			lookup,
			onNodeHover,
			onNodeSelect,
		}}>
			{children}
		</TreeContext.Provider>
	);
};


export default TreeProvider;
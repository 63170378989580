

// These should generally not be used
// See our useURLQueryParam hook, which uses React Router's useSearchParams



// Returns a POJO mapping URL query parameters to strings or arrays of strings
// if the key is repeated
// Ex: pet=cat&owner=jack => {pet: 'cat', owner: 'jack']}
// Ex: pet=cat&pet=dog => {pet: ['cat', 'dog']}
export const getParams = () =>
{
	// We'll extract the key-value pairs to this POJO
	let params = {};
	
	new URLSearchParams(window.location.search).forEach((val, key) =>
	{
		if(params[key] !== undefined)
		{
			if(!Array.isArray(params[key]))
			{
				params[key] = [params[key]];
			}
			params[key].push(val);
		}
		else
		{
			params[key] = val;
		}
	});
	
	return params;
}



// Returns a string or an array of strings or undefined, depending on how many
// times the provided key appears in the URL
export const readParam = (key) =>
{
	return getParams()[key];
}



// If a key appears multiple times in the query params, this will only return
// the first match
export const readFirstParam = (key) =>
{
	return new URLSearchParams(window.location.search).get(key);
}



// After user clicks up, pass this the navigate function from useNavigate()
export const navigateUp = (navigate) =>
{
	let path = window.location.pathname;
	
	const last_index = path.lastIndexOf('/');
	
	if(last_index >= 0)
	{
		navigate(
			path.slice(
				0,
				last_index,
			)
		)
	}
	else
	{
		navigate('/');
	}
}



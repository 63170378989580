import { useNavigate } from 'react-router';

import { useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Action, ActionStatus } from 'models';
import { Chip, Divider } from '@mui/material';

import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ActionsCollaborationPanel from '../../actions/actionCollaboration/ActionsCollaborationPanel';

import { DateTime } from 'luxon';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { relativeDatetimeText, relativeDaysDiff } from 'stuff';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';

const ActionCard =
({
	action,
} : {
	action: Action,
})  =>
{
	const navigate = useNavigate();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	const { status, created_at, issue_id, inquiry, requisition_id } = action;
	
	
	const created_at_datetime = DateTime.fromISO(created_at)
	const days_diff = relativeDaysDiff(created_at_datetime);
	
	const is_assigned = ((action?.assigned_to?.length > 0
		|| action?.role_ids?.length > 0
	)) ? true : false;
	
	return (
		<>
			<Stack
				direction='column'
				justifyContent='space-between'
				alignItems='flex-start'
				className='related_issue_card'
				onClick={() => {
					if(action?.issue_id !== undefined)
					{
						navigate(`/issues/${issue_id}`)
					}
					else
					{
						navigate(`/requisitions/${requisition_id}`)
					}
				}}
			>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					spacing={1}
					width='100%'
				>
					<Stack
						direction='column'
						justifyContent='space-between'
						alignItems='flex-start'
						spacing={1}
					>
						<Typography
							variant='subtitle1'
							sx={{
								background: defaultAppTheme.palette.warning.dark,
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							Action #{action?.action_num}
						</Typography>
						
						<Typography
							variant='body2'
							sx={{
								marginLeft: '5px'
							}}
						>
							{inquiry}
						</Typography>
					</Stack>
					
					<ButtonToOpenDialog
						button_variant='text'
						dialog_title={`Action #${action.action_num}`}
						dialog_dismiss_text='Exit'
						dialog_content={
							<ActionsCollaborationPanel
								action={action}
								closeDialog={closeDialog}
								setCloseDialog={setCloseDialog}
							/>
						}
						closeDialog={closeDialog}
						buttonIcon={
							<CorIcon
								of='edit'
								size='24px'
								color={defaultAppTheme.palette.primary.main}
							/>
						}
						button_type='icon_button'
					/>
				</Stack>
				
				
				
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					spacing={1}
					width='100%'
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='center'
						sx={{
							padding: '16px',
						}}
					>
						<CorIcon
							of={(status !== ActionStatus.RESOLVED) ? 'open_action' : 'closed_action'}
							size='24px'
							color={
								(action?.status === ActionStatus.OPEN) ?
								defaultAppTheme.palette.secondary.main :
								(action?.status === ActionStatus.IN_PROGRESS) ?
								defaultAppTheme.palette.primary.main :
								(action?.status === ActionStatus.RESOLVED) ?
								defaultAppTheme.palette.success.main :
								defaultAppTheme.palette.grey['800']
							}
						/>
						
						<Typography
							variant='body1'
							color={
								(action?.status === ActionStatus.OPEN) ?
								defaultAppTheme.palette.secondary.main :
								(action?.status === ActionStatus.IN_PROGRESS) ?
								defaultAppTheme.palette.primary.main :
								(action?.status === ActionStatus.RESOLVED) ?
								defaultAppTheme.palette.success.main :
								defaultAppTheme.palette.grey['800']
							}
						>
							{ status.toUpperCase() }
						</Typography>
					</Stack>
					
					<Stack
						direction='column'
						justifyContent='center'
						alignItems={'flex-start'}
						spacing={1}
					>
						<Typography
							sx={{
								background: ((days_diff > -7) && (action?.status !== ActionStatus.RESOLVED))
									? defaultAppTheme.palette.primary.main
									: (days_diff < -7 && days_diff >= -30 && action?.status !== ActionStatus.RESOLVED)
									? defaultAppTheme.palette.warning.main
									: (days_diff < -30 && action?.status !== ActionStatus.RESOLVED)
									? defaultAppTheme.palette.secondary.main
									: defaultAppTheme.palette.grey['500'],
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							{ relativeDatetimeText(created_at_datetime) }
						</Typography>
						
						<ConciseDateAndTime datetime={created_at_datetime} />
					</Stack>
				</Stack>
				
				{
					(is_assigned) ? (
						<Chip color='success' label='assigned'/>
					) : (
						<Chip label='unassigned' />
					)
				}
				
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
		</>
	)
};


export default ActionCard;
import { Issue, WorkOrderNew } from 'models';

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';




function LinearProgressWithLabel(props: LinearProgressProps & { value: number })
{
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">
					{
						`${Math.round(props.value,)}%`
					}
				</Typography>
			</Box>
		</Box>
	);
}







const AssetDashboardWorkOrdersInfo = ({
	work_orders,
} : {
	work_orders: WorkOrderNew[],
}) =>
{
	const [ progress, setProgress ] = useState<number>(100);
	
	
	const closed_work_orders = work_orders.filter(x => x.status === 'Completed');
	
	
	useEffect(() =>
	{
		if(closed_work_orders?.length !== work_orders?.length)
		{
			const work_order_progress = closed_work_orders.length/work_orders.length*100;
			
			setProgress(work_order_progress);
		}
	},
	[work_orders])
	
	
	return (
		<Stack
			className='container'
			width='100%'
			direction='column'
			justifyContent='center'
			alignItems='flex-start'
			spacing={1}
		>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				width='100%'
			>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					spacing={1}
				>
					<Box
						sx={{
							width: 'fit-content',
							borderRadius: '4px',
							background: defaultAppTheme.palette.primary.main,
							padding: '5px 5px 0px 5px'
						}}
					>
						<CorIcon
							of='work_order'
							color='#fff'
							size='24px'
						/>
					</Box>
					<Typography
						variant='h5'
					>
						Work Orders Summary
					</Typography>
				</Stack>
				
				<CorIcon
					of={(progress === 100) ? 'check' : 'warning_triangle'}
					size='30px'
					color={(progress === 100)? defaultAppTheme.palette.success.main : defaultAppTheme.palette.warning.main}
				/>
			</Stack>
			
			<Stack>
				<Typography
					variant='subtitle1'
					sx={{
						color: defaultAppTheme.palette.grey['700']
					}}
				>
					Identified: {work_orders.length}
				</Typography>
				
				<Typography
					variant='subtitle1'
					sx={{
						color: defaultAppTheme.palette.grey['700']
					}}
				>
					Due: {work_orders.length - closed_work_orders.length}
				</Typography>
			</Stack>
			
			<Box width='100%'>
				<LinearProgressWithLabel value={progress} />
			</Box>
		</Stack>
	)
};


export default AssetDashboardWorkOrdersInfo;
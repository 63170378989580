import { Trigger } from 'models/triggers/trigger_models';
import { dotJoin } from 'utils';
import { useFormContext } from 'react-hook-form';
import { IconButton, Stack, Typography } from '@mui/material';

import CorIcon from 'components/icons/CorIcon';
import WorkOrderTemplatePicker from 'app/gizmos/component_record//WorkOrderTemplatePicker';
import ShortTextFormlet from 'app/gizmos/fields/text/ShortTextFormlet';
import ThresholdMinMaxGizmo from './ThresholdMinMaxFormlet';
import ThresholdMaxFormlet from './ThresholdMaxFormlet';

import { ComponentRecordType } from 'models';
import { triggerName } from './trigger_helpers';
import DeleteIconWidget from 'app/gizmos/DeleteIconWidget';


// UI for defining a ComponentRecord Trigger, allowing user to decide
// what should happen when a ComponentReading reaches a threshold
export const TriggerGizmo =
({
	basename,
	trigger,
	index,
} : {
	basename?: string,
	trigger: Trigger,
	index: number,
}) =>
{
	const form_context = useFormContext();
	
	
	if(!trigger)
	{
		return null;
	}
	
	
	let field_name = dotJoin(basename, trigger._id);
	
	
	const triggers_by_id = form_context.watch(basename);
	const type           = form_context.watch('type');
	const units          = form_context.watch('units');
	
	
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifySelf='center'
			alignSelf='center'
		>
			
			<Typography
				sx={{
					fontSize: '32px',
					fontWeight: 600,
					opacity: .1,
					marginRight: 5,
				}}
			>
				{index + 1}
			</Typography>
			
			
			<Stack
				sx={{
					// flexGrow: 1,
					padding: 2,
				}}
			>
				<Stack
					direction='row'
					flexWrap='wrap'
				>
					<Stack>
						<ShortTextFormlet
							name={dotJoin(field_name, 'name')}
							label='Trigger Name'
							placeholder={triggerName(trigger, type, units)}
						/>
						
						<Stack
							sx={{
								// padding: '8px 0',
							}}
						>
							{
								(type !== ComponentRecordType.RUNNING_HOURS)
								?
									<ThresholdMinMaxGizmo
										basename={field_name}
										units={units}
										help={null}
									/>
								:
								<>
									<ThresholdMaxFormlet
										basename={field_name}
										units={units}
										help={null}
									/>
									
									{/* <Stack
										sx={{
											opacity: (threshold?.min || threshold?.max) ? 1 : .4,
										}}
									>
										<ThresholdIncrementGizmo
											units={units}
										/>
									</Stack> */}
									
								</>
							}
						</Stack>
					</Stack>
					
					<WorkOrderTemplatePicker
						name={dotJoin(field_name, 'action.work_order_template_ids')}
					/>
					
				</Stack>
			</Stack>
			
			
			<DeleteIconWidget
				name={basename}
				obj={triggers_by_id}
				item_id={trigger._id}
			/>
			
		</Stack>
	);
}


export default TriggerGizmo;
import { useNavigate } from 'react-router';

import { Box, Typography, Stack, Grid, Chip } from '@mui/material';

import SortableTable from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/SortableTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { WorkOrderNew, WorkOrderStatus } from 'models';
import { relativeDatetimeText, relativeDaysDiff } from 'utils/datetime_utils_new';
import { DateTime } from 'luxon';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';


const WorkOrdersTable =
({
	work_orders,
	title,
}: {
	work_orders: WorkOrderNew[],
	title?: string,
}
) =>
{
	const navigate = useNavigate();
	
	
	return(
		<Box>
			<Typography
				variant='h6'
			>
				{title}
			</Typography>
			
			<SortableTable
				options={work_orders}
				onSelect={(selectedOption) => {
					navigate(`/work-orders/${selectedOption._id}`)
				}}
				rows={[
					{
						title: 'WO #',
						getCell(work_order)
						{
							return (
								<Box sx={{ marginInline: '0.5rem' }}>
									{work_order?.work_order_num || ''}
								</Box>
							);
						},
					},
					{
						title: 'Status',
						getCell(work_order)
						{
							const { status } = work_order;
							
							return (
								<Stack
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
								>
									<CorIcon
										of={(status === WorkOrderStatus.COMPLETED) ? 'closed_work_order' : 'open_work_order'}
										size='24px'
										color={
											(status === WorkOrderStatus.DUE)
												? defaultAppTheme.palette.secondary.main
												: (status === WorkOrderStatus.IN_PROGRESS)
													? defaultAppTheme.palette.primary.main
													: defaultAppTheme.palette.success.dark
											}
									/>
									
									<Typography
										variant='body1'
										color={
											(status === WorkOrderStatus.DUE)
												? defaultAppTheme.palette.secondary.main
												: (status === WorkOrderStatus.IN_PROGRESS)
													? defaultAppTheme.palette.primary.main
													: defaultAppTheme.palette.success.dark
											}
										sx={{
											paddingLeft: '5px',
										}}
									>
										{ status.toUpperCase() }
									</Typography>
								</Stack>
							)
						},
					},
					{
						title: 'Title',
						getCell(work_order)
						{
							return <Typography
								variant='subtitle1'
								sx={{
									inlineSize: '250px',
									overflowWrap: 'break-word',
								}}
							>
								{ work_order?.title }
							</Typography>
						},
					},
					{
						title: 'Assigned',
						getCell(work_order) {
							
							const is_assigned = ((work_order?.collaborator_ids?.length > 0
									|| work_order?.assigned_to_role_ids?.length > 0
								)) ? true : false;
							
							return (
								<>
									{
										(is_assigned) ? (
											<Chip color='success' label='assigned'/>
										) : (
											<Chip label='unassigned' />
										)
									}
								</>
							)
						},
					},
					{
						title: 'Asset',
						getCell(work_order)
						{
							return (
								<Grid
									container
									width='100%'
									sx={{
										marginTop: '5px'
									}}
								>
									<RelatedAssetAndComponents component_ids={work_order.component_ids} division_id={work_order.division_id}/>
								</Grid>
							)
						},
					},
					{
						title: 'Due Date',
						getCell(work_order) {
							
							let scheduled_for_datetime = DateTime.fromISO(work_order?.scheduled_for);
							const days_diff = relativeDaysDiff(scheduled_for_datetime);
							
							return (
								<Stack
									direction='column'
									justifyContent='center'
									alignItems={'flex-start'}
									spacing={1}
								>
									<Typography
										sx={{
											background: ((days_diff < 0) && (work_order.status !== WorkOrderStatus.COMPLETED))
												? defaultAppTheme.palette.secondary.main
												: (days_diff > 0 && days_diff <= 7 && work_order.status !== WorkOrderStatus.COMPLETED)
												? defaultAppTheme.palette.warning.main
												: (days_diff > 7 && days_diff < 30 && work_order.status !== WorkOrderStatus.COMPLETED)
												? defaultAppTheme.palette.primary.main
												: defaultAppTheme.palette.grey['500'],
											padding: '4px',
											borderRadius: '4px',
											color: '#fff',
										}}
									>
										{ (work_order?.scheduled_for) && relativeDatetimeText(scheduled_for_datetime) }
									</Typography>
									<ConciseDateAndTime datetime={DateTime.fromISO(work_order?.scheduled_for)} />
								</Stack>
							)
						}
					},
					
					{
						title: 'Created',
						getCell(work_order) {
							return (
								<ConciseDateAndTime
									datetime={work_order?.created_at}
								/>
							)
						}
					},
				]}
			/>
		</Box>
	)
};


export default WorkOrdersTable;
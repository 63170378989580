import { useNavigate, useParams } from 'react-router';
import { where } from 'firebase/firestore';

import { useAuthData } from 'stuff';
import { useCollection, useDoc } from 'ember';

import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {
	Asset,
	CurrentComponent,
	System,
	ComponentTreeData,
} from 'models';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import SystemsAssetRefCard from 'components/cards/SystemsAssetRefCard';
import { Issue } from 'types';
import ComponentTree from './ComponentTree';
import CorIcon from 'components/icons/CorIcon';

import { useURLQueryParam } from 'utils/hooks';


const ComponentTreePanel =
({
	component,
	setComponent,
	setIsComponentDrawerOpen,
	selectedComponents,
	setSelectedComponents,
	selectedSystem,
	setSelectedSystem,
} : {
	component?: ComponentTreeData,
	setComponent?: Function,
	setIsComponentDrawerOpen?: Function,
	selectedComponents?: ComponentTreeData[],
	setSelectedComponents?: Function,
	selectedSystem?: System,
	setSelectedSystem?: Function,
	
}) =>
{
	let [ selected_component_id, setSelectedComponentId ] = useURLQueryParam('selected', null);
	
	
	const navigate = useNavigate();
	
	const params = useParams();
	const { asset_id, system_id, component_id } = params;
	
	const { claims } = useAuthData();
	
	
	// Get the asset data
	const assets_collection_path = `organizations/${claims?.organization_id}/assets`;
	const asset_doc = useDoc(assets_collection_path, selectedSystem?.asset_id || asset_id);
	
	// Get the system data
	const systems_collection_path = `organizations/${claims?.organization_id}/systems`;
	const system_doc = useDoc(systems_collection_path, selectedSystem?._id || system_id);
	
	// Get components data for selected asset
	const components_collection = useCollection(
		`organizations/${claims?.organization_id}/components`,
		where(
			'system_id',
			'==',
			//selectedSystem?._id ||
			// TODO: Replace with primary group at some point and ensure one is available?
			system_id || selectedSystem?._id || 'no errors please'
		)
	);
	
	// Get Issues data from selected Asset > System
	const issues_collection_path = `organizations/${claims?.organization_id}/issues`;
	const issues_collection = useCollection(issues_collection_path);
	
	
	if (
		components_collection.is_loading ||
		asset_doc.is_loading ||
		system_doc.is_loading ||
		issues_collection.is_loading
	)
	{
		return <CircularProgress />
	};
	
	
	const components = components_collection.data as CurrentComponent[];
	const asset = asset_doc.data as Asset;
	const system = system_doc.data as System;
	const issues = issues_collection.data as Issue[];
	
	
	console.log({
		asset,
		system,
		components,
		issues,
		selected_component_id,
	});
	
	
	// console.log(window.location.pathname);
	
	
	
	// TODO: FIX THIS
	const onBack = () =>
	{
		if(
			window.location.pathname === `/assets/${asset_id}/systems/${system_id}/components`
			|| window.location.pathname === `/assets/${asset_id}/systems/${system_id}/components/${component_id}`
		)
		{
			navigate(`/assets/${asset_id}/systems`)
		}
		else
		{
			setSelectedSystem?.(undefined);
		}
	}
	
	
	
	return (
		<Stack
			className='components_page'
			sx={{
				overflow: 'unset',
			}}
		>
			
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				width='90%'
			>
				<CorIcon
					of='back'
					size='40px'
					color={defaultAppTheme?.palette?.primary.main}
					onClick={() => onBack()}
					cursor='pointer'
				/>
				
				<Typography variant='h3'>
					Components
				</Typography>
				
				<div style={{ width: '25px' }}>
					
				</div>
				
			</Stack>
			
			<SystemsAssetRefCard asset={asset} system={system}/>
			
			<Divider
				sx={{
					width: '100%',
					color: defaultAppTheme.palette.grey['900'],
					marginBottom: '10px',
				}}
			/>
			
			{/* <DivisionTree
				asset_id={asset_id}
			/> */}
			
			<ComponentTree
				issues={issues}
				setComponent={(newly_selected) => {
					setSelectedComponentId(newly_selected._id);
					console.log({ newly_selected });
					setComponent(newly_selected);
				}}
				setIsComponentDrawerOpen={setIsComponentDrawerOpen}
				selectedComponents={selectedComponents}
				setSelectedComponents={setSelectedComponents}
			/>
			
		</Stack>
	)
};


export default ComponentTreePanel;
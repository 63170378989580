import { Stack } from '@mui/system';
import { useRequisition } from '../../RequisitionDataProvider';
import { Chip, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import EditCostCodes from '../../EditCostCodes';




const RequisitionCollabCostCodes = () =>
{
	const form = useFormContext();
	
	const { requisition, cost_codes, updateCostCodes } = useRequisition();
	
	const related_cost_codes = cost_codes.filter(x => requisition?.cost_code_ids?.includes(x._id));
	
	useEffect(() =>
	{
		form.setValue(
			'cost_codes',
			related_cost_codes
		)
	}, [requisition.cost_code_ids])
	
	
	return (
		<Grid
			item
			xs={12}
			sm={3}
		>
			<Stack
				gap={2}
				sx={{ padding: '10px' }}
				>
				<Typography
					variant='h6'
				>
					Cost Codes
				</Typography>
				{
					related_cost_codes.map(cost_code => (
						
						<Chip 
							key={cost_code._id}
							label={cost_code.description}
							variant='filled'
							color= 'warning'
						/>
					))
				}
			</Stack>
			
			<EditCostCodes
				cost_codes={cost_codes}
				handleSubmit={updateCostCodes}
			/>
		</Grid>
	)
};


export default RequisitionCollabCostCodes;
import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';
import SelectTagsFormlet from '../../../../components/selectTags/SelectTagsFormlet';
import { useStyles } from '../../styles/theme';
import { useState } from 'react';
import IssueSelectCollaborators from 'components/selectCollaborators/desktop/IssueSelectCollaborators';
import { AttachmentsFormlet } from './AttachmentsFormlet';
import { Tag } from 'types';


const CreateIssueDesktopColumnRight = () => 
{
	const { getValues, setValue, formState: {errors}, control } = useFormContext();
	
	const sessionContext = useSessionContext();
	
	// const [files, setFiles] = useState([]);
	
	const [selectedTags, setSelectedTags] = useState<Tag[]>();
	
	const classes = useStyles();

	const handleTagChange = (event: React.ChangeEvent<{}>, newValue:null | Tag[]) =>
	{

		let tag_ids = [];
		console.log(newValue);
		

		newValue.forEach((tag) => {
			tag_ids.push(tag._id)
		})
		
		setValue('tag_ids', tag_ids);
		
		setSelectedTags(newValue);
	}
	
	
	return (
		<Box className={classes.DesktopCol}>
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box
					sx={{
						alignContent: 'left',
						width: '100%',
					}}
				>
					<Typography variant='h3' >
						Add Tags
					</Typography>
				</Box>
				
				<SelectTagsFormlet
					label={'Type to search'}
					id='create-issue-tags'
					value={selectedTags}
					onChange={handleTagChange}
					tags={sessionContext.all_available_tags}
					multiple={true}
				/>
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				<IssueSelectCollaborators />
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					<Typography variant='h3'>
						Attachments
					</Typography>
				</Box>
				
				<AttachmentsFormlet control={control}/>
				
			</Box>
			
			
		</Box>
	)
}


export default CreateIssueDesktopColumnRight;
import React, { useEffect } from 'react'
import { useParams } from 'react-router';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ComponentDrawerDetails from './details/ComponentDrawerDetails';
import ComponentRelatedIssuesList from './componentIssues/ComponentRelatedIssuesList';
import { CorComponent } from 'models';
import { useAuthData } from 'stuff';
import { useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';
import { Issue, WorkOrderNew } from 'models';
import ComponentRelatedWorkOrdersList from './componentWorkOrders/ComponentRelatedWorkOrders';


const ComponentDrawerTabs =
({
	component,
} : {
	component: CorComponent,
}) =>
{
	const [value, setValue] = React.useState('1');
	const [activateTab, setActivateTab] = React.useState(false);
	
	
	useEffect(() =>
	{
		setValue('1')
	}, [component]);
	
	
	const { claims } = useAuthData();
	
	const { component_id } = useParams();
	
	const org_path = `organizations/${claims?.organization_id}`;
	// Get Issues that involve any components specified in this Issue
	const related_issues_collection = useCollectionConditionally(
		(component_id !== undefined),
		`${org_path}/issues`,
		where('component_ids', 'array-contains', component_id)
	);
	
	
	const related_work_orders_collection_1 = useCollectionConditionally(
		(component_id !== undefined),
		`${org_path}/work_orders`,
		where('component_ids', 'array-contains', component_id)
	);
	
	// const related_work_orders_collection_2 = useCollectionConditionally(
	// 	(component_id !== undefined),
	// 	`${org_path}/work_orders`,
	// 	where('division_id', '==', component?.maintenance_function_id || '')
	// );
	
	
	if(related_issues_collection.is_loading || related_work_orders_collection_1?.is_loading
		// || related_work_orders_collection_2?.is_loading
		) return <CircularProgress />
	
	
	const related_issues = related_issues_collection.data as Issue[] || [];
	const related_work_orders = related_work_orders_collection_1?.data as WorkOrderNew[] || [];
	
	
	
	setTimeout(
		() => {
			setActivateTab(true)
		},
		100
	)
	
	
	const handleChange = (event: React.SyntheticEvent, newValue: string) =>
	{
		setValue(newValue);
	};
	
	
	
	return (
		<Box
			sx={{
				width: '100%',
				typography: 'body1',
			}}
		>
			<TabContext value={value}>
				
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					
				<TabList onChange={handleChange} className='component_card_tabs'>
					{
						activateTab &&
							<Tab label='Details' value={false || '1'} />
					}
					{
						activateTab &&
							<Tab label= 'Issues' value={false || '2'} />
						
					}
					{
						activateTab &&
							<Tab label= 'Work Orders' value={false || '3' } />
					}
				</TabList>
				</Box>
				
				<TabPanel value='1'>
					<ComponentDrawerDetails
						component={component}
						related_issues={related_issues}
						related_work_orders={related_work_orders}
					/>
				</TabPanel>
				
				<TabPanel value='2'>
					<ComponentRelatedIssuesList related_issues={related_issues} />
				</TabPanel>
				
				<TabPanel value='3'>
					<ComponentRelatedWorkOrdersList related_work_orders={related_work_orders}/>
				</TabPanel>
				
			</TabContext>
		</Box>
	)
};


export default ComponentDrawerTabs;
import { Control, Controller } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { CheckboxListField, FieldOrientation } from 'models/fields';


export interface CheckboxListProps
{
	control: Control<any, any>,
	field: CheckboxListField,
	handleChange: Function,
}


export const CheckboxList =
({
	control,
	field,
	handleChange,
}
: CheckboxListProps) =>
{
	const handleSelect = (option_id: string, is_checked: boolean) =>
	{
		let prev_selected_ids = field.options.filter(option => option.checked).map(option => option._id);
		
		
		if(is_checked)
			return prev_selected_ids.concat(option_id)
				// .flat().filter(Boolean);
		else
			return prev_selected_ids.filter(x => (x !== option_id))
				// .flat().filter(Boolean);
	}
	
	
	
	return (
		<FormControl
			component='fieldset'
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			
			{
				(field.label)
				&&
					<FormLabel
						component='legend'
					>
						{field.label}
					</FormLabel>
			}
			
			
			
			<FormGroup
				aria-label='position'
				row={field.orientation === FieldOrientation.ROW}
			>
				{
					field?.options.map((option, index) =>
						<FormControlLabel
							key={option._id || index}
							label={option.label}
							labelPlacement='top'
							control={
								<Controller
									control={control}
									name={field.name + '.' + option._id}
									render={({
										field: { onChange, onBlur, value, name, ref },
										fieldState, //: { isTouched, isDirty, error },
										formState,
									}) =>
										<Checkbox
											onBlur={onBlur}
											onChange={(e) => handleChange(handleSelect(option._id, e.target.checked))}
											inputRef={ref}
											checked={field.options[Number(option._id) - 1]?.checked}
										/>
									}
								/>
							}
							sx={{
								userSelect: 'none',
								margin: 0,
								// padding: '2px',
							}}
						/>
					)
				}
			</FormGroup>
			
		</FormControl>
	);
}


export default CheckboxList;
import { where } from 'firebase/firestore';
import { Chip, CircularProgress, Grid, Stack } from '@mui/material';

import ComponentChip from '../ComponentChip';
import { useAuthData } from 'stuff';
import { useCollectionConditionally } from 'ember';
import { CurrentComponent } from 'models';
import { useNavigate } from 'react-router';


const RelatedAssetAndComponents =
({
	component_ids,
	division_id,
}: {
	component_ids: string[],
	division_id?: string,
}) =>
{
	const { claims } = useAuthData();
	const navigate = useNavigate();
	
	
	const components_collection_path = `organizations/${claims.organization_id}/components`;
	
	const components_collection_1 = useCollectionConditionally(
		(division_id !== undefined),
		components_collection_path,
		where('maintenance_function_id', '==', division_id)
	)
	
	const components_collection_2 = useCollectionConditionally(
		(component_ids?.length > 0),
		components_collection_path,
		where('_id', 'in', component_ids)
	)
	
	
	if(components_collection_1.is_loading) return <CircularProgress />
	
	
	const affected_components = components_collection_1?.data.concat(components_collection_2?.data || []) as CurrentComponent[] || [];
	const asset_name = affected_components.map(x => x.asset_name)[0];
	
	
	return (
		<>
			{
				(affected_components?.length > 0) && (
					<Stack
						direction='column'
						alignItems='flex-start'
						justifyContent='flex-start'
						spacing={1}
					>
						<Chip label={asset_name} />
						<Grid
							container
							gap={1}
						>
							{
								affected_components?.map((component, index) =>
										<Grid
											key={index}
											item
											onClick={() => navigate(`/assets/${component.asset_id}/systems/${component.system_id}/components/${component._id}`)}
											width='100%'
										>
											<ComponentChip name={component.name} />
										</Grid>
									)
							}
						</Grid>
					</Stack>
				)
			}
		</>
	)
};


export default RelatedAssetAndComponents;
import { Control, Controller } from 'react-hook-form';
import { SelectContainer } from './styles';
import { FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import DatetimeFormlet from 'app/gizmos/fields/datetimes/DatetimeFormlet';
import { ScheduleFormData } from 'pages/CreateWorkOrder/scheduling_types';


interface ScheduleSelectTypeProps
{
	control: Control<ScheduleFormData>;
}


export const ScheduleTypeSelect = ({ control }: ScheduleSelectTypeProps) =>
{
	return (
		<Stack
			gap={2}
		>
			
			<DatetimeFormlet
				name='effective_datetime'
				label='Effective Date and Time'
			/>
			
			<SelectContainer>
				<FormControl fullWidth>
					<InputLabel id='recurrence_schedule-label' required={true}>
						Work Order Schedule
					</InputLabel>
					<Controller
						control={control}
						name='recurrence_schedule'
						defaultValue=''
						render={({ field: { value, onChange } }) => (
							<Select
								required={true}
								labelId='recurrence_schedule-label'
								id='recurrence_schedule-select'
								defaultValue=''
								label='Work Order Schedule'
								value={value}
								onChange={onChange}
							>
								<MenuItem value='daily'>Daily</MenuItem>
								<MenuItem value='weekly'>Weekly</MenuItem>
								<MenuItem value='monthly'>Monthly</MenuItem>
								<MenuItem value='yearly'>Yearly</MenuItem>
							</Select>
						)}
					/>
				</FormControl>
			</SelectContainer>
			
		</Stack>
	);
};


export default ScheduleTypeSelect;
import { CircularProgress, Box, Stack, Typography, Button, Chip, Grid } from '@mui/material';

import { useCollection } from 'ember';
import { relativeDatetimeText, useAuthData } from 'stuff';
import { MultiSelectTable } from 'pages/CreateWorkOrder/GeneralDescription/Issues/MultiSelectTable/MultiSelectTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { WorkOrderNew, WorkOrderStatus } from 'models';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { DateTime } from 'luxon';

// import ComponentChip from 'app/dashboard/pages/issueCollaboration/issueDetails/ComponentChip';



interface SelectWOModalProps
{
	selectedWOs: WorkOrderNew[];
	setSelectedWOs: (wos: WorkOrderNew[]) => void;
	addSelectedWOs: () => void;
}



const SelectableWorkOrdersTable =
({
	selectedWOs,
	setSelectedWOs,
	addSelectedWOs,
}: SelectWOModalProps) =>
{
	const { claims } = useAuthData();
	
	const work_orders_collection_path = `organizations/${claims.organization_id}/work_orders`
	const work_orders_collection = useCollection(work_orders_collection_path);
	
	
	if(work_orders_collection?.is_loading)
	{ 
		return <CircularProgress />
	}
	
	
	const work_orders = work_orders_collection?.data as WorkOrderNew[] || [];
	
	
	return (
		<MultiSelectTable
			options={work_orders}
			onSelect={(selectedOptions) => {
				setSelectedWOs(selectedOptions)
			}}
			rows={[
				{
					title: '',
					getCell: (_, isSelected) => (
						!isSelected ? (
							<CorIcon of='empty_circle' size='20px' />
						) : (
							<CorIcon of='circle_checked' size='20px' color='#74b70a' />
						)
					),
				},
				{
					title: 'WO #',
					getCell(work_order)
					{
						return (
							<Box sx={{ marginInline: '0.5rem' }}>
								{work_order?.work_order_num || ''}
							</Box>
						);
					},
				},
				{
					title: 'Status',
					getCell(work_order)
					{
						const { status } = work_order;
						
						return (
							<Stack
								direction='row'
								justifyContent='flex-start'
								alignItems='center'
							>
								<CorIcon
									of={(status === WorkOrderStatus.COMPLETED) ? 'closed_work_order' : 'open_work_order'}
									size='24px'
									color={
										(status === WorkOrderStatus.DUE)
											? defaultAppTheme.palette.secondary.main
											: (status === WorkOrderStatus.IN_PROGRESS)
												? defaultAppTheme.palette.primary.main
												: defaultAppTheme.palette.success.dark
										}
								/>
								
								<Typography
									variant='caption'
									color={
										(status === WorkOrderStatus.DUE)
											? defaultAppTheme.palette.secondary.main
											: (status === WorkOrderStatus.IN_PROGRESS)
												? defaultAppTheme.palette.primary.main
												: defaultAppTheme.palette.success.dark
										}
									sx={{
										paddingLeft: '5px',
									}}
								>
									{ status.toUpperCase() }
								</Typography>
							</Stack>
						)
					},
				},
				{
					title: 'Title',
					getCell(work_order)
					{
						return <Typography
						variant='subtitle1'
						sx={{
							inlineSize: '250px',
							overflowWrap: 'break-word',
						}}
					>
						{ work_order?.title }
					</Typography>
					},
				},
				{
					title: 'Asset',
					getCell(work_order)
					{
						return (
							<Grid
								container
								width='100%'
								sx={{
									marginTop: '5px'
								}}
							>
								<RelatedAssetAndComponents component_ids={work_order.component_ids} division_id={work_order.division_id}/>
							</Grid>
						)
					},
				},
				{
					title: 'Due Date',
					getCell(work_order) {
						
						let relative_datetime = DateTime.fromISO(work_order?.scheduled_for);
						
						return (
							<Stack
								direction='column'
								justifyContent='center'
								alignItems={'center'}
								spacing={1}
							>
								<Typography
									sx={{
										background: defaultAppTheme.palette.grey['500'],
										padding: '4px',
										borderRadius: '4px',
										color: '#fff',
									}}
								>
									{ (work_order?.scheduled_for) && relativeDatetimeText(relative_datetime) }
								</Typography>
								<Typography>
									{ fullDateAndTimeFromISO(work_order?.scheduled_for) }
									
								</Typography>
							</Stack>
						)
					}
				}
			]}
			tableDescription={
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<Button
						sx={{
							backgroundColor: '#186ab4',
							color: 'white',
							cursor: 'pointer',
							padding: '5px',
							borderRadius: '5px',
							'&:hover': {
								backgroundColor: '#186ab4',
							},
							textTransform: 'none',
						}}
						onClick={() => addSelectedWOs()}
					>
						Confirm
					</Button>
				</Box>
			}
			skipFilterOnSelect={true}
			initialSelectedOptions={selectedWOs}
		/>
	)
};


export default SelectableWorkOrdersTable;
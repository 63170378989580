
import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';
import { Control, useFieldArray, UseFormReturn } from 'react-hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { WOFormData } from '../../WorkOrderProvider';
import FillableField from './FillableField';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';


export interface SectionProps
{	form?: UseFormReturn<WOFormData>;
	control: Control<StepperForm>;
	index: number;
}


export const FillableSection =
({
	form,
	control,
	index: sectionIndex
}
: SectionProps) =>
{
	const { sections } = form.getValues();
	
	const {
		fields: fieldsList,
	} = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields` as `sections.0.fields`,
	});
	
	
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				border: '1px solid #d8dbdf',
				padding: '1rem',
			}}
		>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={2}
			>
				<Typography
					variant='subtitle2'
					sx={{
						padding: '10px',
						background: defaultAppTheme.palette.grey['200'],
						borderRadius: '4px',
					}}
				>
					Section #{sectionIndex + 1}
				</Typography>
				
				<Typography variant='h3'>
						{sections[sectionIndex].name}
				</Typography>
			</Stack>
			
			<InfoHighlight
				severity='info'
				message_main={sections[sectionIndex].description}
				message_context={'Instructions/Details'}
				sx={{
					marginTop: '10px'
				}}
			/>
			
			<Box
				sx={{
					marginLeft: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				{
					fieldsList.map((field: any, fieldIndex) => 
					{
						console.log(field);
						
						if(!!field?.action_type)
						{
							return (
								<FillableField
									key={field?.id}
									control={control}
									fieldIndex={fieldIndex}
									sectionIndex={sectionIndex}
									sections={sections}
								/>
							)
						}
					})
				}
				
			</Box>
		</Box>
	);
};


export default FillableSection;
import React from 'react'


import PartsTable from './parts/PartsTable';



const InventoryPage = () =>
{
	return (
		<div className='inventory_page'>
			<PartsTable />
		</div>
	)
};


export default InventoryPage;
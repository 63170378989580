import { ReactNode, createContext, useContext } from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';

import { DateTime } from 'luxon';
import cuid from 'cuid';

import { where } from 'firebase/firestore';

import { useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally, 
	useUpdateDocProperty, useUploadFile, useWrite } from 'ember';

import { Certification, CertificationFor, CertificationRecord, CertificationRecordStatus, StorageFile} from 'models';
import { User, Asset, CorComponent } from 'models';

import { writeCertificationRecordOnCreation } from './certification_records_helpers';
import { ensureLocalDateTime } from 'models/recurrence/recurrence_helpers2';





export interface CertificationRecordsData
{
	certification: Certification,
	certification_record?: CertificationRecord,
	
	current_certification_records?: CertificationRecord[],
	users: User[],
	assets: Asset[],
	components?: CorComponent[],
	files?: StorageFile[],
	
	uploadFiles?: Function,
	handleSubmit?: any
}



const DEFAULT_FORM_DATA: CertificationRecord =
{
	_id: cuid(),
	
	certification_id: '',
	
	
	obtained_date: DateTime.local(), 
	never_expires: false,
	expiry_date: DateTime.local(),
	last_modified_date: '',
	
	
	note: '',
	
	files: null,
	
	status: CertificationRecordStatus.ACTIVE,
}



const CertificationRecordsDataContext = createContext<CertificationRecordsData>(null);



export const useCertificationRecord = () =>
{
	return useContext(CertificationRecordsDataContext)
};



const CertificationRecordsProvider =
({
	certification,
	certification_record,
	children,
} : {
	certification?: Certification,
	certification_record?: CertificationRecord,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	
	// const current_user = {
	// 	_id: claims.user_id,
	// 	name: claims.name,
	// 	email: claims.email,
	// }
	
	
	const uploadFile = useUploadFile();
	const write = useWrite();
	const navigate = useNavigate();
	const updateDocProperty = useUpdateDocProperty();
	
	
	
	const form = useForm<CertificationRecord>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
			...certification_record,
		},
	});
	
	const form_values = form?.getValues();
	
	//console.log("This is the first cert records form values 1234" + {form_values});
	
	console.log("This to check the note values yay" + form_values?.note)
	
	console.log("This to check the files" + form_values.files?.map(x => x?.name))
	
	
	const { control } = form;
	
	
	
	
	// const [ show_snackbar, setShowSnackbar ] = useState<boolean>(false);
	
	// let [ search_params ] = useSearchParams();
	
	
	// const { asset_id, system_id, component_id } = useParams();
	
	
	// // This will be as enum keys, not enum values (Ex: 'DEGREES_CELSIUS' not '°C')
	// let type_query = search_params.get('type');
	// let units_query = search_params.get('units');
	// let threshold_action_type_query = search_params.get('action');
	
	
	// const files_ref = useWatch({
	// 	control,
	// 	name: 'files'
	// });


	// const notes_ref = useWatch({
	// 	control,
	// 	name: 'note'
	// });
	
	


	// useEffect(() =>
	// {
	// 	const form_values = form?.getValues();;
	// }, [files_ref, form, notes_ref])
	
	
	
	const handleSubmit = async (e) =>
	{
		e.preventDefault();
		
		const form_values = form?.getValues();
		
		//if edits we dont pass cuid
		const new_certification_record: CertificationRecord = (certification_record !== undefined)
			? { ...form_values }
			: { ...form_values, _id: cuid() };
		
		console.log ("Hello certification record form values:" + {form_values})
		
		
		const new_form_values: CertificationRecord =
		{
			_id: new_certification_record?._id,
			certification_id: certification._id,
			
			never_expires: new_certification_record?.never_expires,
			status: new_certification_record?.status,
			obtained_date: ensureLocalDateTime(new_certification_record?.obtained_date)?.toISO() || null,
			
			expiry_date: ensureLocalDateTime(new_certification_record?.expiry_date)?.toISO() || null,
			
			note:  new_certification_record?.note || '',
			
			files: new_certification_record?.files || [] ,
		}
		
		console.log ({new_form_values})
		
		//located in helper func
		writeCertificationRecordOnCreation (
			new_form_values,
			write,
			claims,
			uploadFile,
			navigate,
			certification,
			updateDocProperty
		)
	}
	
	
	
	const org_path = `organizations/${claims.organization_id}`;
	
	
	const users_collection = useCollection(`${org_path}/users`);
	const assets_collection = useCollection(`${org_path}/assets`);
	const current_certification_records_collection = useCollectionConditionally(
		(certification !== undefined),
		`${org_path}/certification_records`,
		where('certification_id', '==', certification?._id));
	
	//check the file logic again as we try to load all attachments with certifications at once
	const files_collection = useCollectionConditionally(
		(certification_record !== undefined),
		`${org_path}/files`,
		where('_id', '==', certification_record?._id)
	);
	
	
	//later add component collection 
	
	const files = files_collection?.data as StorageFile[] || [];
	const users = users_collection?.data as User[] || [];
	const assets = assets_collection?.data as Asset[] || [];
	
	const current_certification_records = current_certification_records_collection?.data as CertificationRecord[] || []
	
	
	
	const certification_record_data: CertificationRecordsData = 
	{
		certification: certification,
		certification_record: certification_record || null,
		
		
		current_certification_records: current_certification_records || null,
		users: users || [],
		assets: assets || [],
		components: [],
		files: files || [],
		
		uploadFiles: uploadFile,
		handleSubmit: handleSubmit
	
	}
	
	
	return (
		<FormProvider {...form}>
			<CertificationRecordsDataContext.Provider
				value = {certification_record_data}
			>
				<form
					onSubmit={handleSubmit}
				>
					{children}
				</form>
			</CertificationRecordsDataContext.Provider>
		</FormProvider>
	)
}


export default CertificationRecordsProvider;



export enum RecurrenceType
{
	INTERVAL = 'Interval',
	SCHEDULE = 'Schedule',
}


export enum OnDemandRecurrenceType
{
	SCHEDULE = 'Schedule',
	TRIGGER = 'Trigger',
}



export enum RecurrenceScheduleType
{
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
	YEARLY = 'Yearly',
	ON_DEMAND = 'On Demand',
}



export enum RecurrenceTriggerType
{
	RUNTIME_HOURS = 'Runtime Hours',
	TEMPERATURE = 'Temperature',
	PRESSURE = 'Pressure',
}



export enum RecurrenceEffectiveType
{
	START_IMMEDIATELY = 'Start Immediately',
	DELAYED_START = 'Delayed Start',
	EFFECTIVE_BETWEEN = 'Effective Between',
}



import { Divider, Stack, Typography } from '@mui/material';
import { useCertification } from '../CertificationDataProvider';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import { useFormContext, useWatch } from 'react-hook-form';
import { CertificationFor } from 'models';



const SelectCetificationForOptions = () =>
{
	const form = useFormContext();
	const { control } = form;
	
	
	const { users, roles, assets } = useCertification();
	
	
	const certification_for = useWatch({
		control,
		name: 'certification_for'
	});
	
	
	
	return(
		<Stack
			gap={1}
		>
			{
				(certification_for === CertificationFor.USER)
				? (
					<SearchableSelectFormlet
						name='user'
						label='Select a user'
						help='Please select the user this certification is for'
						options={users}
						getOptionLabel={option => option.display_name || ''}
						multiple={false}
					/>
				) : (certification_for === CertificationFor.COMPANY_WIDE_ROLE)
				? (
					<SearchableSelectFormlet
						name='role'
						label='Select a role'
						help='Please select the role this certification is for'
						options={roles}
						getOptionLabel={option => option.name || ''}
						multiple={false}
					/>
				) : (certification_for === CertificationFor.COMPANY_WIDE_ASSETS)
				? (
					<InfoHighlight
						severity='info'
						message_main='Note: This certification will be applied to all assets for the company'
						message_context='Company Wide Assets'
					/>
				) : (
					<SearchableSelectFormlet
						name='asset'
						label='Select an asset'
						help='Please select the asset this certification is for'
						options={assets}
						getOptionLabel={option => option.name || ''}
						multiple={false}
					/>
				)
			}
		</Stack>
	)
}


export default SelectCetificationForOptions;
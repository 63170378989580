import { Stack, Typography } from '@mui/material';
import { useSession } from 'app/providers/SessionProvider';
import SearchableRolesCardList from './SearchableRolesCardList';



const RolesList = () =>
{
	const { roles } = useSession();
	
	
	
	return (
		<Stack
			spacing={1}
			width='100%'
		>
			<Typography
				variant='h6'
			>
				Available Roles
			</Typography>
			
			<SearchableRolesCardList roles={roles} />
		</Stack>
	)
};


export default RolesList;



// TODO: Include in form
export enum WorkOrderType
{
	FORM = 'Form',
	INSPECTION = 'Inspection',
	MAINTENANCE = 'Maintenance',
	REGULAR = 'Regular',
}



// TODO: Do WorkOrderTemplates use these too? Separate status? Default for created WorkOrders?
// TODO: Review types? Overlap - ex: active/open? Define meanings?
// TODO: Once a template is done automating, what status does it get?
export enum WorkOrderStatus
{
	DRAFT = 'Draft',
	UNDER_REVIEW = 'Under Review',
	
	ACTIVE = 'Active',
	OPEN = 'Open',
	PAUSED = 'Paused',
	
	APPROVED = 'Approved',
	PROVISIONAL = 'Provisional',
	IN_PROGRESS = 'In Progress',
	RELEASED = 'Released',
	COMPLETED = 'Completed',
	
	DUE = 'Due',
}



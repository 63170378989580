import { useState } from 'stuff';
import { useNavigate } from 'react-router';

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';

import BottomNavDrawer from './BottomNavDrawer';
import BottomBarButton from './BottomBarButton';
import { firstUrlPart } from 'utils/new_url_utils';


// Navigation menu at the bottom of the window for mobile users
// Contains tabs to control what content is displayed in Main
const BottomNavBar = () =>
{
	const navigate = useNavigate();
	
	const [ is_drawer_open, setIsDrawerOpen ] = useState<boolean>(false);
	
	const first_url_part = firstUrlPart();
	
	
	const toggleDrawer = () =>
	{
		setIsDrawerOpen(prevState => !prevState);
		
		console.log({ is_drawer_open });
	}
	
	
	return (
		<>
			
			<Paper
				sx={{
					display: { xs: 'flex', sm: 'none'},
				}}
				className='bottom_nav_container'
			>
				<BottomNavigation
					showLabels={true}
					value={first_url_part}
					className='bottom_navigation_bar'
				>
					
					<BottomBarButton
						label='My Page'
						icon_name='my_page'
						onClick={() => navigate('/my-page')}
						is_selected={(first_url_part === 'my-page') || (first_url_part === 'home') || (!first_url_part)}
					/>
					
					<BottomBarButton
						label='Work Orders'
						icon_name='work_order'
						onClick={() => navigate('/work-orders')}
						is_selected={first_url_part === 'work-orders'}
					/>
					
					
					<BottomBarButton
						icon_name='menu_plus'
						onClick={toggleDrawer}
						is_selected={false}
					/>
					
					
					<BottomBarButton
						label='Issues'
						icon_name='issue'
						onClick={() => navigate('/issues')}
						is_selected={first_url_part === 'issues'}
					/>
					
					<BottomBarButton
						label='Requisitions'
						icon_name='requisition_list'
						onClick={() => navigate('/requisitions')}
						is_selected={first_url_part === 'requisitions'}
					/>
					
				</BottomNavigation>
			</Paper>
			
			<BottomNavDrawer
				isMobileDrawerOpen={is_drawer_open}
				toggleDrawer={toggleDrawer}
			/>
		</>
	);
};


export default BottomNavBar;
import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme?: any) =>
    createStyles({
        CreateIssueBox: {
            width: "100%",
            position: "fixed",
            height: "100%",
            overflow: "scroll",
            zIndex: "11000 !important",
            margin: "auto",
            padding: "1rem",
            bottom: 0,
        },
        CreateIssueModal: {
            backgroundColor: "#fff",
            minHeight: "100vh",
            "&.MuiBox-root": {
                minHeight: "100vh",
            },
        },
        CreateIssueDrawer: {
            zIndex: "11000 !important",
            minHeight: "100vh",
            "&.MuiBox-root": {
                minHeight: "100vh",
            },
        },
        AddEquipmentDrawer: {
            zIndex: "12000 !important",
            minHeight: "85%",
            "&.MuiBox-root": {
                minHeight: "85%",
            },
        },
        CreateIssueDrawerHeaderBox: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#fff",
            height: "50px",
            width: "100%",
            top: 0,
        },
        CreateIssueStepperBox: {
            backgroundColor: "#fff",
            top: "50px",
            height: "100%",
            minHeight: "100%",
            zIndex: "11000 !important",
            width: "100%",
        },
        CreateIssueDetailsBox: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
            width: "100%",
            height: "100%",
            overflow: "scroll",
            margin: "auto",
            paddingBottom: "10rem",
        },
        CreateIssueInputBox: {
            width: "85%",
            maxWidth: "500px",
            margin: "auto",
            marginBottom: "2rem",
        },
        CreateIssueInputRowBox: {
            width: "85%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "row",
        },
        CreateIssueRightJustifyRow: {
            width: "85%",
            maxWidth: "500px",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
        },
        CreateIssueCenterJustifyRow: {
            width: "85%",
            maxWidth: "500px",
            margin: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
        },
        BoxRow: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            height: "100%",
            width: "100%",
        },
        BoxCol: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "top",
            alignContent: "center",
            height: "100%",
            width: "100%",
        },
        DesktopCol: {
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            justifyContent: "space-between",
            margin: "1rem",
            alignSelf: "flex-start",
        },
        DesktopSubmitButton: {
            color: "#fff",
            backgroundColor: "#3F8ED8",
            width: "100px",
            height: "33px",
            alignSelf: "center",
        },
    })
);

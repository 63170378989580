// import { Alert, Collapse, IconButton } from '@mui/material';
import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CorIcon from 'components/icons/CorIcon';

// import IssueCreationDrawer from 'pages/createIssue/createIssueStepper/components/IssueCreationDrawer';
import { ComponentTreeData } from 'models';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';


/*NOTE: Should be used in a FORM component. */
const SelectEquipmentDrawer =
({
	selected_components,
	set_selected_components
} : {
	selected_components?: ComponentTreeData[],
	set_selected_components?: (selected_components: ComponentTreeData[]) => void,
}) => 
{
	const { setValue, getValues } = useFormContext();
	
	const [ openAlert, toggleAlert] = useState(false);
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);

	
	const selected_ids = getValues('component_ids'); 
	
	
	console.log(selected_ids);
	
	
	const selectEquipment = (selected : ComponentTreeData[]) =>
	{
		let selected_component_ids = [];
		let selected_component_names = [];
		
		selected.forEach((component) =>
		{
			selected_component_ids.push(component._id);
			selected_component_names.push(component.name);
		});
		
		setValue('component_ids', selected_component_ids);
		setValue('component_names', selected_component_names);
		
		toggleAlert(true);
		
		set_selected_components(selected);
		setCloseDialog(!closeDialog);
	}
	
	
	return (
		<ButtonToOpenDialog
			button_variant='text'
			dialog_title='Select Equipment'
			button_content='Add Equipment'
			dialog_content={
				<ComponentSelection
					selected_components={selected_components}
					selectEquipment={selectEquipment}
				/>
			}
			closeDialog={closeDialog}
			startIcon={<CorIcon of='asset_tree' size='20px'/>}
		/>
	)
}


export default SelectEquipmentDrawer;
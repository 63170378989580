import { CircularProgress, Grid } from '@mui/material'
import { useURLTab } from 'utils/hooks';

import CertificationCard from './CertificationCard'
import { useCertification } from './CertificationDataProvider';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Certification, CertificationFor, CertificationRecord } from 'models';
import CertificationList from './certificationList/CertificationList';



const tab_titles_by_key =
{
	users: 'Users',
	assets: 'Assets',
}



const CertificationPageTabs = () =>
{
	const { tab, setTab } = useURLTab(tab_titles_by_key);
	
	const { all_certifications } = useCertification();
	
	
	if(!all_certifications)
	{
		return <CircularProgress />
	};
	
	
	const user_certifications: Certification[] = all_certifications?.filter( x =>
		(x?.certification_for === CertificationFor.USER) || (x?.certification_for === CertificationFor.COMPANY_WIDE_ROLE)
	)
	
	const asset_certifications: Certification[] = all_certifications?.filter(x =>
		(x?.certification_for === CertificationFor.ASSET) || (x?.certification_for === CertificationFor.COMPANY_WIDE_ASSETS)
	)
	
	
	console.log({user_certifications})
	console.log({asset_certifications})
	
	
	
	return (
		<TabContext value={tab}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}
				className='issue_collab_tabs'
			>
				<TabList
					onChange={(e, v) => setTab(v)}
					className='issue_collab_tabs_list'
				>
					{
						Object.entries(tab_titles_by_key).map(([key, label]) =>
							<Tab
								label={label}
								value={key}
								key={key}
							/>
						)
					}
				</TabList>
			</Box>
			
			
			<TabPanel
				value='users'
				className='issue_collab_tab_panel'
			>
				<CertificationList certifications={user_certifications} />
			</TabPanel>
			
			
			<TabPanel
				value='assets'
				className='issue_collab_tab_panel'
			>
				<CertificationList certifications={asset_certifications} />
			</TabPanel>
		</TabContext>
	)
};


export default CertificationPageTabs;
import { Control, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField, InputLabel, Box } from '@mui/material';



export interface EffectiveDateProps
{
	control: Control<any, any>,
	// name: Name,
	defaultValue: Date | null,
}



export const EffectiveDate =
({
	control,
	defaultValue,
}: EffectiveDateProps) =>
{
	return (
		<Controller
			control={control}
			name={`effective_date`}
			rules={{
				required: true,
			}}
			defaultValue={defaultValue}
			render={({ field: { value, onChange } }) => {
				return (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem',
						}}
					>
						<InputLabel required={true}>
							Effective Date
						</InputLabel>
						<DatePicker
							value={value}
							onChange={(value) => {
								onChange(new Date(value));
							}}
							renderInput={(params) =>
								<TextField {...params} />
							}
							toolbarPlaceholder='Date'
						/>
					</Box>
				);
			}}
		/>
	);
};


export default EffectiveDate;
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useCollection } from 'ember';
import React from 'react';
import { useAuthData } from 'stuff';
import { Part } from 'types';
import PartsListTable from './PartsListTable';


const PartsPage = () =>
{
	const { claims } = useAuthData();
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	const parts_collection = useCollection(`${org_path}/parts`);
	
	if(parts_collection?.is_loading)
	{
		return <CircularProgress />
	};
	
	
	const parts = parts_collection?.data as Part[];
	
	return (
		<Stack
			gap={2}
			sx={{
				padding: '10px'
			}}
		>
			<Typography
				variant='h1'
			>
				Available Parts List
			</Typography>
			
			<PartsListTable parts={parts} />
		</Stack>
	)
};


export default PartsPage;
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';


export interface ActionTypeProps
{
	
}


const action_types =
[
	'Investigation',
	'Safety Check',
	'Inspection Check',
	'Work Order',
	'Requisition',
	'Inventory Check',
	'Modify Drawings',
	'General',
	'Record Value',
];


const ActionTypeSelect = () =>
{
	return (
		<SearchableSelectFormlet
			name='action_type'
			label='Type of action'
			options={action_types}
		/>
	);
};


export default ActionTypeSelect;
import { Stack } from '@mui/material';

import { useTree } from './TreeProvider';
import { traverseTreeUp } from '../tree_helpers';
import { TreeNode } from '../tree_models';


export const TreeInfo = () =>
{
	const tree_context = useTree();
	
	
	let selected_node = tree_context.lookup[tree_context.selected_id];
	
	
	if(!selected_node)
	{
		return null;
	}
	
	
	let path = [];
	
	traverseTreeUp(selected_node, (node: TreeNode) => {
		path.push(node);
	})
	
	
	return (
		<Stack>
			<Stack
				direction='row'
				sx={{
					bgcolor: '#0002',
					padding: '12px',
					margin: '6px',
					borderRadius: '8px',
				}}
			>
				{
					path.map(x =>
						<Stack
							key={x._id}
							margin='2px 6px'
						>
							{x.label}
						</Stack>
					)
				}
			</Stack>
			{/* {selected_node.label} */}
		</Stack>
	)
}


export default TreeInfo;
import { Routes, Route } from 'react-router';

import LoginPage from '../pages/login/LoginPage';
import ForgotPasswordPage from '../pages/forgot_password/ForgotPasswordPage';
import RegistrationPage from '../pages/registration/RegistrationPage';


const AuthPageRoutes = () =>
{
	return (
		<Routes>
			<Route 
				path='/login'
				element={<LoginPage />}
			/>
			<Route 
				path='/forgot-password'
				element={<ForgotPasswordPage />}
			/>
			<Route 
				path='/register'
				element={<RegistrationPage />}
			/>
			<Route 
				path='*'
				element={<LoginPage />}
			/>
		</Routes>
	)
}


export default AuthPageRoutes;
import { Group } from '@visx/group';
import { HierarchyPointNode } from '@visx/hierarchy/lib/types';
import { pointRadial } from 'd3-shape';

import TreeNodeChildrenCount from './TreeNodeChildrenCount';
import TreeNodeContent from './TreeNodeContent';
import { useTree } from './TreeProvider';
import { calculateBetween } from '../tree_helpers';
import { TreeNode } from '../tree_models';
import useForceUpdate from '../useForceUpdate';


export const TreeNodeBox =
({
	node,
	layout,
	orientation,
} : {
	node: HierarchyPointNode<TreeNode>,
	layout: string,
	orientation: string,
}) =>
{
	const tree_context = useTree();
	
	const forceUpdate = useForceUpdate();
	
	if(!node?.data)
	{
		return null;
	}
	
	
	let is_selected = tree_context.selected_id === node.data._id;
		
	
	let has_children = !!(node.data?.children);
	let has_hidden_children = !!(has_children && !node.children);
	let has_value = !!node.data.value;
	
	
	let num_char = Math.max(
		node.data.label.length,
		(node.data.value?.length + node.data.units?.length + 1) || 0
	);
	
	let width = calculateBetween(60, 150, (x) => 0 + x * 10)(num_char);
	let height = (has_value) ? 36 : 24;
	
	let top: number;
	let left: number;
	
	
	if (layout === 'polar')
	{
		const [radialX, radialY] = pointRadial(node.x, node.y);
		top = radialY;
		left = radialX;
	}
	else if (orientation === 'vertical')
	{
		top  = node.y;
		left = node.x;
	}
	else
	{
		top  = node.x;
		left = node.y;
	}
	
	
	return (
		<Group top={top} left={left} key={node.data._id}>
			
			<rect
				height={height}
				width={width}
				y={-height / 2}
				x={-width / 2}
				rx={(node.data.children) ? 2 : 12}
				
				onClick={() => {
					node.data.is_expanded = !node.data.is_expanded;
					
					console.log(node);
					
					tree_context.onNodeSelect(node.data, true)
					
					forceUpdate();
				}}
				
				stroke={(node.data.children) ? '#03c0dc' : '#26deb0'}
				strokeWidth={2}
				strokeOpacity={(node.data.children) ? 1 : 0.5}
				// strokeDasharray={(node.data.children) ? '0' : '2,2'}
				
				// fill={(node.depth !== 0) ? '#202048' : '#03c0dc'}
				fill={
					(is_selected)
						? '#ff0'
						: (node.depth === 0)
							? '#ddd'
							: (node.children)
								? '#ddd'
								: (node.data.children)
									? '#fff'
									: '#26deb0'
				}
				
				cursor='pointer'
			/>
			
			<TreeNodeContent
				node={node}
				has_value={has_value}
				has_hidden_children={has_hidden_children}
			/>
			
			<TreeNodeChildrenCount
				width={width}
				node={node}
				has_value={has_value}
				has_hidden_children={has_hidden_children}
				forceUpdate={forceUpdate}
			/>
			
		</Group>
	);
}


export default TreeNodeBox;
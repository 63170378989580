import { useState } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import { CurrentComponent } from 'models';
import { useAuthData } from 'stuff';
import { useUpdateDocProperty } from 'ember';
import { WorkOrderTemplate } from 'models';
import RelatedAssetAndComponents from '../../issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';


const WOTAffectedComponentsList =
({
	components,
	wo_template,
}: {
	components: CurrentComponent[],
	wo_template: WorkOrderTemplate,
}) =>
{
	const { claims } = useAuthData();
	const updateDocProperty = useUpdateDocProperty();
	
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	const updateAffectedComponents = (selected_components) =>
	{
		const component_ids = selected_components.map(x => x._id);
		
		const division_id = selected_components.map(x => x?.maintenance_function_id || x?.division_id)[0]
		
		updateDocProperty(
			`organizations/${claims?.organization_id}/work_order_templates/${wo_template._id}`,
			'component_ids',
			component_ids
		);
		
		updateDocProperty(
			`organizations/${claims?.organization_id}/work_order_templates/${wo_template._id}`,
			'division_id',
			division_id
		);
		
		setCloseDialog(!closeDialog);
	}
	
	
	return (
		<Grid
			item
			xs={12}
			sm={4}
		>
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='center'
				sx={{
					marginLeft:'10px',
					width: '100%',
				}}
			>
				<Typography
					variant='h6'
				>
					Affected Components
				</Typography>
				
				<RelatedAssetAndComponents component_ids={wo_template.component_ids} division_id={wo_template.division_id} />
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					sx={{
						width: '100%',
					}}
				>
					<ButtonToOpenDialog
						button_variant='text'
						dialog_title='Select Components'
						button_content='Edit'
						dialog_content={
							<ComponentSelection
								selected_components={components}
								selectEquipment={updateAffectedComponents}
							/>
						}
						sx={{
							textTransform:'none',
							textDecoration: 'underline',
						}}
						closeDialog={closeDialog}
					/>
				</Stack>
				
			</Stack>
		</Grid>
	)
}


export default WOTAffectedComponentsList;
import { createContext, useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { useDtn } from './dtn_api';
// import { useMap } from 'react-leaflet';
// import { Map } from 'leaflet';


interface MapData
{
	selected_vessel?: any,
	selected_marker?: any,
	
	setSelectedMarker: Function;
	setSelectedVessel: Function;
	
	// TODO: Type
	ports?: any[];
	trip_request: any;
	setTripRequest: Function;
	trip_responses: any;
	setTripResponses: Function;
}


const MapDataContext = createContext<MapData>(null);


export const useMapData = () =>
{
	return useContext(MapDataContext);
}





const getAllPorts = async (dtn_access_token, setPorts) =>
{
	try
	{
		const resp = await axios.get('https://routing.api.shipping.dtn.com/v1/ports', {
			headers: {
				//"Content-type": "application/json",
				'Authorization': 'Bearer ' + dtn_access_token,
			},
			data: null,
		})
		
		console.log(resp.data);
		
		setPorts(resp.data);
	}
	catch (err)
	{
		console.error(err);
	}
};



const MapDataProvider = ({ children }) =>
{
	const [ selected_vessel, setSelectedVessel ] = useState(null);
	const [ selected_marker, setSelectedMarker ] = useState(null);
	const [ ports, setPorts ] = useState([]);
	const [ trip_request, setTripRequest ] = useState([
		instructed_speed_trip1,
		instructed_speed_trip2,
		instructed_speed_trip3,
	]);
	const [ trip_responses, setTripResponses ] = useState([]);
	
	
	// DTN Route API Data
	const dtn_access_token = useDtn();
	
	
	
	
	useEffect(() =>
		{
			console.log({ dtn_access_token });
			
			if(dtn_access_token)
			{
				getAllPorts(dtn_access_token, setPorts);
			}
		},
		[dtn_access_token]
	);
	
	
	
	console.log({ ports });
	
	
	
	let map_data =
	{
		selected_vessel,
		selected_marker,
		setSelectedVessel,
		setSelectedMarker,
		ports,
		trip_request,
		setTripRequest,
		trip_responses,
		setTripResponses,
	}
	
	
	console.log(map_data);
	
	
	return (
		<MapDataContext.Provider value={map_data}>
			{children}
		</MapDataContext.Provider>
	);
}


export default MapDataProvider;











const instructed_speed_trip1 = {
	"id": "sp-12",
	"points": [
		{
			"type": "Feature",
			"properties": {
				"name": "St John's, Newfoundland",
				"port": "CASJF-3467"
			},
			"geometry": {
				"type": "Point",
				"coordinates": [
					-52.70753479003906,
					47.55973815917969
				]
			}
		},
		{
			"type": "Feature",
			"properties": {
				"name": "Halifax",
				"port": "CAHAL-6719",
				"ignoreRouteNetwork": false
			},
			"geometry": {
				"type": "Point",
				"coordinates": [
					-63.56512451171875,
					44.631561279296875
				]
			}
		}
	],
	"etd": "2022-04-28T02:34:30.45Z",
	"config": null,
	"restrictions": {
		"northVertex": 80,
		"southVertex": 80,
		"weatherLimits": [
		{
			"name": "HurricaneDistance",
			"minimum": 500,
			"action": {
			"actionType": "Warn",
			"warningSeverity": "Moderate"
			}
		}
		],
		"conditionalAreas": {
		"defaultAreas": [
			"SpeedLimit",
			"EmissionControl"
		],
		"areaOverrides": [
			{
			"enabled": true,
			"areaId": "nogo-4"
			}
		],
		"customAreas": [
			{
			"type": "Feature",
			"properties": {
				"name": "string",
				"condition": {
				"type": "NoGo"
				}
			},
			"geometry": {
				"type": "Polygon",
				"coordinates": [
				[
					[
					112.19238281249999,
					21.207458730482642
					],
					[
					111.302490234375,
					20.2725032501349
					],
					[
					112.08251953125,
					18.323240460443397
					],
					[
					114.3896484375,
					19.321511226817176
					],
					[
					114.840087890625,
					21.34054846908118
					],
					[
					112.19238281249999,
					21.207458730482642
					]
				]
				]
			}
			}
		]
		}
	},
	"vesselParameters": {
		"imo": "8814275",
		"vesselType": "CapesizeBulkCarrier",
		"cargoTypes": [],
		"laden": true,
		"measurements": {
			"lengthOverall": 100,
			"beam": 20,
			"draft": 10,
			"airDraft": 10,
			"grossTonnage": 100000
		},
		"safetyMargins": {
			"port": 0,
			"starboard": 0,
			"underKeel": 0,
			"air": 0,
			"aft": 0,
			"forward": 0
		},
		"fuelCurve": {
			"otherFuelConsumption": 1,
			"values": [
				{
					"speed": 8,
					"fuelUsage": 2.6
				},
				{
					"speed": 12,
					"fuelUsage": 9.2
				}
			]
		},
		"rpmCurve": {
			"values": [
				{
					"speed": 0,
					"rpm": 0
				},
				{
					"speed": 10,
					"rpm": 1000
				}
			]
		},
		"customModel": null
	},
	"costs": {
		"vesselCosts": 25000,
		"fuelCosts": 700,
		"ecaFuelCosts": 800,
		"otherFuelCosts": 700
	},
	"weatherSource": {
		"type": "Forecast",
		"version": "2020-09-20T00:00:00Z"
	},
	"optimizationType": "Time",
	"speed": 10
};



const instructed_speed_trip2 = {
	"id": "sp-12",
	"points": [
		{
			"type": "Feature",
			"properties": {
				"name": "St John's, Newfoundland",
				"port": "CASJF-3467"
			},
			"geometry": {
				"type": "Point",
				"coordinates": [
					-52.70753479003906,
					47.55973815917969
				]
			}
		},
		{
			"type": "Feature",
			"properties": {
				"name": "Halifax",
				"port": "CAHAL-6719",
				"ignoreRouteNetwork": false
			},
			"geometry": {
				"type": "Point",
				"coordinates": [
					-63.56512451171875,
					44.631561279296875
				]
			}
		}
	],
	"etd": "2022-04-28T02:34:30.45Z",
	"config": null,
	"restrictions": {
		"northVertex": 80,
		"southVertex": 80,
		"weatherLimits": [
		{
			"name": "HurricaneDistance",
			"minimum": 500,
			"action": {
			"actionType": "Warn",
			"warningSeverity": "Moderate"
			}
		}
		],
		"conditionalAreas": {
		"defaultAreas": [
			"SpeedLimit",
			"EmissionControl"
		],
		"areaOverrides": [
			{
			"enabled": true,
			"areaId": "nogo-4"
			}
		],
		"customAreas": [
			{
			"type": "Feature",
			"properties": {
				"name": "string",
				"condition": {
				"type": "NoGo"
				}
			},
			"geometry": {
				"type": "Polygon",
				"coordinates": [
				[
					[
					112.19238281249999,
					21.207458730482642
					],
					[
					111.302490234375,
					20.2725032501349
					],
					[
					112.08251953125,
					18.323240460443397
					],
					[
					114.3896484375,
					19.321511226817176
					],
					[
					114.840087890625,
					21.34054846908118
					],
					[
					112.19238281249999,
					21.207458730482642
					]
				]
				]
			}
			}
		]
		}
	},
	"vesselParameters": {
		"imo": "8814275",
		"vesselType": "CapesizeBulkCarrier",
		"cargoTypes": [],
		"laden": true,
		"measurements": {
			"lengthOverall": 100,
			"beam": 20,
			"draft": 10,
			"airDraft": 10,
			"grossTonnage": 100000
		},
		"safetyMargins": {
			"port": 0,
			"starboard": 0,
			"underKeel": 0,
			"air": 0,
			"aft": 0,
			"forward": 0
		},
		"fuelCurve": {
			"otherFuelConsumption": 1,
			"values": [
				{
					"speed": 8,
					"fuelUsage": 2.6
				},
				{
					"speed": 12,
					"fuelUsage": 9.2
				}
			]
		},
		"rpmCurve": {
			"values": [
				{
					"speed": 0,
					"rpm": 0
				},
				{
					"speed": 10,
					"rpm": 1000
				}
			]
		},
		"customModel": null
	},
	"costs": {
		"vesselCosts": 25000,
		"fuelCosts": 700,
		"ecaFuelCosts": 800,
		"otherFuelCosts": 700
	},
	"weatherSource": {
		"type": "Forecast",
		"version": "2020-09-20T00:00:00Z"
	},
	"optimizationType": "Time",
	"speed": 12
};



const instructed_speed_trip3 = {
	"id": "sp-12",
	"points": [
		{
			"type": "Feature",
			"properties": {
				"name": "St John's, Newfoundland",
				"port": "CASJF-3467"
			},
			"geometry": {
				"type": "Point",
				"coordinates": [
					-52.70753479003906,
					47.55973815917969
				]
			}
		},
		{
			"type": "Feature",
			"properties": {
				"name": "Halifax",
				"port": "CAHAL-6719",
				"ignoreRouteNetwork": false
			},
			"geometry": {
				"type": "Point",
				"coordinates": [
					-63.56512451171875,
					44.631561279296875
				]
			}
		}
	],
	"etd": "2022-04-28T02:34:30.45Z",
	"config": null,
	"restrictions": {
		"northVertex": 80,
		"southVertex": 80,
		"weatherLimits": [
		{
			"name": "HurricaneDistance",
			"minimum": 500,
			"action": {
			"actionType": "Warn",
			"warningSeverity": "Moderate"
			}
		}
		],
		"conditionalAreas": {
		"defaultAreas": [
			"SpeedLimit",
			"EmissionControl"
		],
		"areaOverrides": [
			{
			"enabled": true,
			"areaId": "nogo-4"
			}
		],
		"customAreas": [
			{
			"type": "Feature",
			"properties": {
				"name": "string",
				"condition": {
				"type": "NoGo"
				}
			},
			"geometry": {
				"type": "Polygon",
				"coordinates": [
				[
					[
					112.19238281249999,
					21.207458730482642
					],
					[
					111.302490234375,
					20.2725032501349
					],
					[
					112.08251953125,
					18.323240460443397
					],
					[
					114.3896484375,
					19.321511226817176
					],
					[
					114.840087890625,
					21.34054846908118
					],
					[
					112.19238281249999,
					21.207458730482642
					]
				]
				]
			}
			}
		]
		}
	},
	"vesselParameters": {
		"imo": "8814275",
		"vesselType": "CapesizeBulkCarrier",
		"cargoTypes": [],
		"laden": true,
		"measurements": {
			"lengthOverall": 100,
			"beam": 20,
			"draft": 10,
			"airDraft": 10,
			"grossTonnage": 100000
		},
		"safetyMargins": {
			"port": 0,
			"starboard": 0,
			"underKeel": 0,
			"air": 0,
			"aft": 0,
			"forward": 0
		},
		"fuelCurve": {
			"otherFuelConsumption": 1,
			"values": [
				{
					"speed": 8,
					"fuelUsage": 2.6
				},
				{
					"speed": 12,
					"fuelUsage": 9.2
				}
			]
		},
		"rpmCurve": {
			"values": [
				{
					"speed": 0,
					"rpm": 0
				},
				{
					"speed": 10,
					"rpm": 1000
				}
			]
		},
		"customModel": null
	},
	"costs": {
		"vesselCosts": 25000,
		"fuelCosts": 700,
		"ecaFuelCosts": 800,
		"otherFuelCosts": 700
	},
	"weatherSource": {
		"type": "Forecast",
		"version": "2020-09-20T00:00:00Z"
	},
	"optimizationType": "Time",
	"speed": 8
}
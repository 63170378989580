import { useWindowSize } from 'usehooks-ts'

import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';


const CompanyHeader = () =>
{
	const { width } = useWindowSize();
	
	
	let compact = (width < 555);
	
	
	return (
		<Stack
			className='company_header'
			direction='row'
			alignItems='center'
			justifyItems='center'
		>
			<Box
				component='img'
				sx={{
					height: (compact) ? 70 : 140,
					width: (compact) ? 70 : 140,
				}}
				alt='CORSphere logo'
				src='/images/logo_curved_border.png'
			/>
			
			<Stack
				className='company_header_text'
				sx={{
					padding: (compact) ? 1 : 3,
				}}
			>
				<Typography
					variant='h1'
					component='h1'
					sx={{
						fontSize: (compact) ? '18px' : '25px',
						color: 'rgb(94, 94, 94)',
						marginBottom: 1,
					}}
				>
					CORSphere
				</Typography>
				<Typography 
					variant='h3'
					component='h3'
					sx={{
						fontSize: (compact) ? '18px' : '25px',
						color: 'rgb(156, 156, 156)',
					}}
				>
					Industrial interconnectivity
				</Typography>
				<Typography
					variant='h3'
					sx={{
						fontSize: (compact) ? '18px' : '25px',
						color: 'rgb(94, 94, 94)',
					}}
				>
					made simple.
				</Typography>
			</Stack>
			
		</Stack>
	)
}


export default CompanyHeader;
import { useState } from 'react';

import { Box, Typography, Stack, Chip } from '@mui/material';

import { Action, ActionStatus  } from 'models';
import SortableTable from '../issueDetails/issueTable/SortableTable'
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ActionsCollaborationPanel from '../../actions/actionCollaboration/ActionsCollaborationPanel';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';
import { relativeDatetimeText, relativeDaysDiff } from 'utils';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';


const ActionsTable =
({
	actions,
	title,
}: {
	actions: Action[],
	title?: string,
}
) =>
{
	const navigate = useNavigate();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	return(
		<Box>
			<Typography
				variant='h6'
			>
				{title}
			</Typography>
			
			<SortableTable
					options={actions}
					onSelect={(selectedOption: Action) => {
						if(selectedOption?.issue_id !== undefined)
						{
							navigate(`/issues/${selectedOption.issue_id}`)
						}
						else
						{
							navigate(`/requisitions/${selectedOption?.requisition_id}`)
						}
					}}
					rows={[
						{
							title: 'Action #',
							getCell(action) {
								return (
									<Box sx={{ marginInline: '0.5rem' }}>
										{action?.action_num}
									</Box>
								)
							}
						},
						{
							title: 'Status',
							getCell(action) {
								return (
									<Stack
										direction='row'
										justifyContent='flex-start'
										alignItems='center'
									>
										<CorIcon
											of={
												(action?.status === ActionStatus.OPEN) ?
												'open_action' : 'closed_action'
											}
											size='24px'
											color={
												(action?.status === ActionStatus.OPEN) ?
												defaultAppTheme.palette.secondary.main :
												(action?.status === ActionStatus.IN_PROGRESS) ?
												defaultAppTheme.palette.primary.main :
												(action?.status === ActionStatus.RESOLVED) ?
												defaultAppTheme.palette.success.main :
												defaultAppTheme.palette.grey['800']
											}
										/>
										
										<Typography
											variant='body1'
											color={
												(action?.status === ActionStatus.OPEN) ?
												defaultAppTheme.palette.secondary.main :
												(action?.status === ActionStatus.IN_PROGRESS) ?
												defaultAppTheme.palette.primary.main :
												(action?.status === ActionStatus.RESOLVED) ?
												defaultAppTheme.palette.success.main :
												defaultAppTheme.palette.grey['800']
											}
											sx={{
												paddingLeft: '5px',
											}}
										>
											{ action.status }
										</Typography>
									</Stack>
								)
							}
						},
						{
							title: 'Inquiry',
							getCell(action) {
								return (
									<Typography variant='body1'>
										{ action.inquiry }
									</Typography>
								)
							}
						},
						{
							title: 'Assigned',
							getCell(action) {
								
								const is_assigned = ((action?.assigned_to?.length > 0
										|| action?.role_ids?.length > 0
									)) ? true : false;
								
								return (
									<>
										{
											(is_assigned) ? (
												<Chip color='success' label='assigned'/>
											) : (
												<Chip label='unassigned' />
											)
										}
									</>
								)
							},
						},
						{
							title: 'Date Issued',
							getCell(action) {
								
								const created_at_datetime = DateTime.fromISO(action?.created_at)
								const days_diff = relativeDaysDiff(created_at_datetime);
								
								return (
									<Stack
										direction='column'
										justifyContent='center'
										alignItems={'flex-start'}
										spacing={1}
									>
										<Typography
											sx={{
												background: ((days_diff > -7) && (action?.status !== ActionStatus.RESOLVED))
													? defaultAppTheme.palette.primary.main
													: (days_diff < -7 && days_diff >= -30 && action?.status !== ActionStatus.RESOLVED)
													? defaultAppTheme.palette.warning.main
													: (days_diff < -30 && action?.status !== ActionStatus.RESOLVED)
													? defaultAppTheme.palette.secondary.main
													: defaultAppTheme.palette.grey['500'],
												padding: '4px',
												borderRadius: '4px',
												color: '#fff',
											}}
										>
											{ relativeDatetimeText(created_at_datetime) }
										</Typography>
										
										<ConciseDateAndTime datetime={created_at_datetime} />
									</Stack>
								)
							}
						},
						{
							title: 'Edit Action',
							getCell(action) {
								return (
									<ButtonToOpenDialog
										button_variant='text'
										dialog_title={`Action #${action.action_num}`}
										dialog_dismiss_text='Exit'
										dialog_content={
											<ActionsCollaborationPanel
												action={action}
												closeDialog={closeDialog}
												setCloseDialog={setCloseDialog}
											/>
										}
										closeDialog={closeDialog}
										buttonIcon={
											<CorIcon
												of='edit'
												size='24px'
												color={defaultAppTheme.palette.primary.main}
											/>
										}
										button_type='icon_button'
									/>
								)
							}
						},
					]}
				/>

		</Box>
	)
};


export default ActionsTable;
import { Controller, Control } from 'react-hook-form';

import { InputLabel, FormControl, Select, MenuItem } from '@mui/material';

import { WorkOrderDescriptionFormData, WorkOrderType } from 'pages/CreateWorkOrder/work_order_types';


interface WOTypeSelectProps
{
	control: Control<any, any>,
}


export const WOTypeSelect = ({ control }: WOTypeSelectProps) =>
{
	return (
		<FormControl
			sx={{
				width: '250px',
			}}
		>
			<InputLabel
				id='work_order_type-label'
				required={true}
			>
				WO Type
			</InputLabel>
			<Controller
				control={control}
				name='work_order_type'
				defaultValue=''
				render={({ field: { value, onChange } }) => (
					<Select
						required={true}
						labelId='work_order_type-label'
						id='work_order_type-select'
						defaultValue=''
						label='WO Type'
						value={value}
						onChange={onChange}
					>
						{Object.keys(WorkOrderType).map((type) => (
							<MenuItem
								value={type}
								key={type}
							>
								{type}
							</MenuItem>
						))}
					</Select>
				)}
			/>
		</FormControl>
	);
};


export default WOTypeSelect;
import { MobileStepper, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react'
import { useTheme } from '@mui/material/styles';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CreateIssueDetails from './steps/CreateIssueDetails';
import { useStyles } from '../styles/theme';
import CreateIssueCollaborators from './steps/CreateIssueCollaborators';
import CreateIssueTags from './steps/CreateIssueTags';
import { CreateIssueStepperProps } from '../interfaces';
import { useFormContext } from 'react-hook-form';
import { IssueSelectCollaborators } from 'components/selectCollaborators';


const CreateIssueStepper = ({onSubmit} : CreateIssueStepperProps) =>
{
	const [ activeStep, setActiveStep ] = useState(0);
	
	// Adding collaborators validation must be done outside of react hook form validation
	const [ addCollaboratorsValidate, setAddCollaboratorsValidate ] = useState<boolean>(true);
	
	const theme = useTheme();
	
	const classes = useStyles();
	
	const { trigger, getValues } = useFormContext();
	
	
	const steps =
	[
		{
			step: <CreateIssueDetails />,
		},
		{
			step: <IssueSelectCollaborators />,
		},
		{
			step: <CreateIssueTags />,
		},
	]
	
	
	const handleNext = async () =>
	{
		// Need to validate using react hook form trigger
		// If new validations are to be added, add them here
		let isValid = false;
		
		switch (activeStep) {
			case 0:
				const componentValidity = getValues('component_ids');
				isValid = await trigger(['name']);
				
				// isValid = isValid && (componentValidity.length > 0);
				
				break;
			
			case 1:
				const collaborator_ids = getValues('collaborator_ids');
				isValid = Boolean(collaborator_ids.length >= 0);
				
				setAddCollaboratorsValidate(isValid);
				
				break;
			
			case 2:
				isValid = await trigger(['collaborator_ids']);
				
				break;
			
			default:
				break;
		}
		
		
		if(isValid)
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}
	
	
	const handleBack = () =>
	{
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	}
	
	
	return (
		<Box className={classes.CreateIssueStepperBox} >
			
			<Box>
				{steps[activeStep].step}
			</Box>
			
			
			<MobileStepper 
				activeStep={activeStep}
				variant='dots'
				steps={3}
				nextButton={
					<Box
						sx={{
							width: 'fit-content',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<Button
							sx={{marginRight: '1rem'}}
							size='small'
							disabled={activeStep === 2}
							onClick={handleNext}
						>
							Next
							<KeyboardArrowRight />
						</Button>
						<Button
							disabled={activeStep !== 2}
							type='submit'
							size='small'
							form='create-issue-form'
							onClick={onSubmit}
						>
							Finish
						</Button>
					</Box>
				}
				backButton={
					<Button
						size='small'
						onClick={handleBack}
						disabled={activeStep === 0}
					>
						{
							(theme.direction === 'rtl')
							?
								<KeyboardArrowRight />
							:
								<KeyboardArrowLeft />
						}
						Back
					</Button>
				}
			/>
		</Box>
	)
}


export default CreateIssueStepper;
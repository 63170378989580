import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { useVictor } from './VictorProvider';


const VictorSelect = () =>
{
	const { selected_sensor, handleSelectSensor, sensors, sensors_by_id } = useVictor();
	
	
	return (
		<FormControl fullWidth>
			
			<InputLabel id='demo-simple-select-label'>
				Techsol Sensor
			</InputLabel>
			
			<Select
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				value={selected_sensor.signal_position}
				label='Techsol Sensor'
				onChange={(e) => handleSelectSensor(sensors_by_id[e.target.value as string])}
			>
				{
					sensors.map(sensor =>
						<MenuItem
							value={sensor.signal_position}
							key={sensor.signal_position}
						>
							<strong
								style={{
									marginRight: '8px',
								}}
							>
								({sensor.signal_position})
							</strong>
							{sensor.signal_name}
						</MenuItem>
					)
				}
			</Select>
		</FormControl>
	)
}


export default VictorSelect;
import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme?: any) => createStyles({

	MobileHeaderContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '0 auto',
		width: '100%',
	},

	IconSize: {
		width: '40px',
		height: '40px',
	},

	HiddenArrow: {
		opacity: 0,
	}



}))
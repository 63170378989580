import ChatLog from './chat_log/ChatLog';
import InputFrom from './chat_log/input_form/InputForm';

import { IssueLog, ChatUser } from '../interfaces/content_data_interfaces';


export interface ChatProps
{
	issue_tracking: string;
	tracking_number: string;
	issue_log: IssueLog[];
	collaborators: ChatUser[];
	thisUser: ChatUser;
	formSubmit: (form_obj_: IssueLog) => void;
};


const Chat =
({
	issue_log,
	collaborators,
	thisUser,
	formSubmit,
	issue_tracking,
	tracking_number,
}
: ChatProps) =>
{
	return (
		<>
			<ChatLog
				issue_log={ issue_log }
				collaborators={ collaborators }
				issue_tracking={ issue_tracking }
				tracking_number={ tracking_number }
			/>
			
			<InputFrom
				collaborators={ collaborators }
				thisUser={ thisUser }
				formSubmit={ formSubmit }
			/>
		</>
	);
}


export default Chat;
import ShortTextFormlet from 'app/gizmos/fields/text/ShortTextFormlet';


interface TitleFormletProps
{
	name?: string,
	label?: string,
	help?: string,
}


export const TitleFormlet =
({
	name = 'title',
	label = 'Title',
	help,
}
: TitleFormletProps) =>
{
	return (
		<ShortTextFormlet
			name={name}
			label={label}
			help={help}
		/>
	);
};


export default TitleFormlet;
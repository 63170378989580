import { DateTime, HourNumbers, MinuteNumbers } from 'luxon';
import { OnDemandRecurrenceType, Recurrence, RecurrenceEffectiveType, RecurrenceScheduleType, RecurrenceType, TimeOfDay } from 'models';
import { FrequencyType } from './helm_enums';
import { HelmForm } from './helm_interfaces';




export function convertHelmFormToRecurrence(helm_form: HelmForm): Recurrence | undefined
{
	// console.log(
	// 	'%cExtracting recurrence for: %c' + data?.Name,
	// 	'font-size: 16px; color: blue',
	// 	'font-size: 16px; color: cyan',
	// 	data,
	// )
	
	
	const now = DateTime.local();
	
	
	// if(!helm_form?.Frequency)
	// {
	// 	console.log(helm_form);
		
	// 	return undefined;
	// }
	
	
	// AMOS stores frequency info as as JSON string
	const frequency = JSON.parse(helm_form?.Frequency);
	
	
	// console.log(frequency)
	
	
	// if(frequency)
	// {
		
	// }
	
	
	let foo = frequency?.schedules[0]?.fd_a[0];
	
	// console.log({ foo })
	
	// TODO: Verify that this always works as intended
	let effective = (foo)
		? DateTime.fromMillis(foo).toLocal()
		: now;
	
	
	let seconds_into_day = frequency?.schedules[0]?.t || 0;
	
	// TODO: Fallback?
	let time_of_day: TimeOfDay =
	{
		hour: Math.floor(seconds_into_day / 3600) as HourNumbers,
		minute: Math.floor((seconds_into_day % 3600) / 60) as MinuteNumbers,
	};
	
	// console.log('    Frequency', frequency);
	// console.log('    Effective ' + conciseDateAndTime(effective));
	// console.log('    time_of_day ', time_of_day);
	
	
	// const time_of_day = DateTime.fromObject({second: frequency.schedules[0]?.t[0]});
	// console.log('TIME OF DAY', time_of_day);
	
	
	// TODO: Incorporate fd_a_variable (ex: "LastCompleted")
	
	
	if(frequency === null)
	{
		helm_form.FrequencyType = FrequencyType.ON_DEMAND;
		
		// console.log(helm_form)
	}
	
	
	
	switch (helm_form.FrequencyType)
	{
		case 'daily':
			const days_interval = frequency.schedules[0]?.rr_D[0];
			
			// console.log('    DAYS INTERVAL', days_interval);
			
			return {
				recurrence_schedule: RecurrenceScheduleType.DAILY,
				recurrence_type: RecurrenceType.INTERVAL,
				effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
				effective_datetime: effective,
				time_of_day: time_of_day,
				interval: {
					days: days_interval,
				},
				lead_time: {
					weeks: days_interval,
				},
			};
		
		
		case 'weekly':
			
			// If the schedule contains a d property, it's scheduled - if it contains rr_W, it's interval
			const weeks_interval = (frequency.schedules[0]?.rr_W !== undefined)
				? frequency.schedules[0]?.rr_W[0]
				: undefined;
			
			const specific_weekdays = frequency.schedules[0]?.d
			
			
			if(weeks_interval !== undefined)
			{
				return {
					recurrence_schedule: RecurrenceScheduleType.WEEKLY,
					recurrence_type: RecurrenceType.INTERVAL,
					effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
					effective_datetime: effective,
					time_of_day: time_of_day,
					interval: {
						weeks: weeks_interval,
					},
					lead_time: {
						weeks: weeks_interval,
					},
				};
			}
			else
			{
				return {
					recurrence_schedule: RecurrenceScheduleType.WEEKLY,
					recurrence_type: RecurrenceType.SCHEDULE,
					effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
					effective_datetime: effective,
					time_of_day: time_of_day,
					weekly: {
						weekday_numbers: specific_weekdays,
					},
					lead_time: {
						weeks: 1,
					},
				}
			}
		
		
		case 'monthly':
			
			// TODO: Verify
			const months_interval = (frequency.schedules[0]?.rr_M !== undefined)
				? frequency.schedules[0]?.rr_M[0]
				: 1;
			
			// TODO: Verify, any cases with multiples?
			let specific_month_days = frequency.schedules[0]?.D || [];
			
			
			// TODO: Why are there 0s?
			// specific_month_days = specific_month_days?.replaceAll(0, 1);
			// console.log(frequency.schedules);
			// console.log(specific_month_days);
			// specific_month_days = (specific_month_days)
			// 	? specific_month_days?.replaceAll(0, 1)
			// 	: []
			
			
			// TODO: Actual check
			if('interval')
			{
				return {
					recurrence_schedule: RecurrenceScheduleType.MONTHLY,
					recurrence_type: RecurrenceType.INTERVAL,
					effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
					effective_datetime: effective,
					time_of_day: time_of_day,
					interval: {
						months: months_interval,
					},
					lead_time: {
						months: months_interval,
					},
				}
			}
			else
			{
				return {
					recurrence_schedule: RecurrenceScheduleType.MONTHLY,
					recurrence_type: RecurrenceType.SCHEDULE,
					effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
					effective_datetime: effective,
					time_of_day: time_of_day,
					monthly: {
						day_numbers: specific_month_days,
					},
					lead_time: {
						months: 1,
					},
				}
			};
		
		
		case 'yearly':
			
			const years_interval = (frequency.schedules[0]?.rr_Y !== undefined)
				? frequency.schedules[0]?.rr_Y[0]
				: 1;
			
			if((frequency.schedules[0]?.rr_Y === undefined))
			{
				if(frequency.schedules.length === 1)
				{
					console.log(helm_form, frequency.schedules[0])
				}
				else
				{
					console.log(helm_form, frequency.schedules)
				}
			}
			
			const specific_yearly_dates = frequency.schedules.map(schedule => {
				return ({
					month: schedule.M?.[0],
					day: schedule.D?.[0],
				})
			})
			
			// console.log({
			// 	specific_yearly_dates,
			// 	frequency,
			// 	effective,
			// })
			
			// TODO: Actual check - do we have any examples of multiple occurrences per year?
			if('interval')
			{
				return {
					recurrence_schedule: RecurrenceScheduleType.YEARLY,
					recurrence_type: RecurrenceType.INTERVAL,
					effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
					effective_datetime: effective,
					time_of_day: time_of_day,
					interval: {
						years: years_interval,
					},
					lead_time: {
						years: years_interval,
					},
				};
			}
			else
			{
				return {
					recurrence_schedule: RecurrenceScheduleType.YEARLY,
					recurrence_type: RecurrenceType.SCHEDULE,
					effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
					effective_datetime: effective,
					time_of_day: time_of_day,
					yearly: {
						// TODO: Actual yearly dates
						yearly_dates: specific_yearly_dates,
					},
					lead_time: {
						years: 1,
					},
				};
			}
		
		
		// TODO: Verify
		case 'ondemand':
			return {
				recurrence_schedule: RecurrenceScheduleType.ON_DEMAND,
				on_demand_type: OnDemandRecurrenceType.TRIGGER,
				effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
				effective_datetime: effective,
				time_of_day: time_of_day,
				lead_time: {
					years: 1,
				},
			};
			
			
		// case null:
		// 	console.error('Y')
		// 	return {
		// 		recurrence_schedule: RecurrenceScheduleType.ON_DEMAND,
		// 		on_demand_type: OnDemandRecurrenceType.TRIGGER,
		// 		effective_type: RecurrenceEffectiveType.DELAYED_START,
		// 		effective_datetime: effective,
		// 		time_of_day: time_of_day,
		// 	}
		
		
		default:
			console.log('    ERROR')
			return null
	}
};



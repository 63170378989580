


export enum Name
{
	COUNT = 'Count',
	FLUID = 'Fluid',
	FUEL_USED = 'Fuel Used',
	RUNNING_HOURS = 'Running Hours',
	VOLTAGE = 'Voltage',
}


export enum SnippetTag
{
	FUEL_USED = 'FuelUsed',
	RUNNING_HOURS = 'RunningHours',
}


export enum Unit
{
	EMPTY = '#',
	GAL = 'gal',
	HR = 'hr',
	L = 'L',
	V = 'V',
}


export enum ItemType
{
	CALENDAR = 'calendar',
	CHECKBOX = 'checkbox',
	CREW_SELECTION = 'crew-selection',
	CUSTOM_DROPDOWN = 'custom-dropdown',
	CUSTOM_RADIO = 'custom-radio',
	DESCRIPTION = 'description',
	DONE = 'done',
	FAIL_PASS = 'fail-pass',
	FIELD = 'field',
	INSTRUCTION = 'instruction',
	NO_YES_NA = 'no-yes-na',
	NUMERIC = 'numeric',
	PART_USAGE = 'part-usage',
	RADIO = 'radio',
	READING = 'reading',
	REPLACE = 'replace',
	RUNNING_HOUR = 'runninghour',
	SELECTION_DIVISION = 'selection-division',
	SELECTION_POSITION = 'selection-position',
	TIME = 'time',
}


export enum FrequencyType
{
	DAILY = 'daily',
	MONTHLY = 'monthly',
	WEEKLY = 'weekly',
	YEARLY = 'yearly',
	ON_DEMAND = 'ondemand',
}


export enum HelmFormType
{
	FORM = 'Form',
	INSPECTION = 'Inspection',
	MAINTENANCE = 'Maintenance',
	REGULAR = 'Regular',
}


export enum VesselName
{
	ODYSSEY = 'Odyssey',
	RV_NOVUS = 'RV Novus',
	SEEKER = 'Seeker',
	STRIKER = 'Striker',
	VENTURE = 'Venture',
}



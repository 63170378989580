import { AmosAsset, AmosComponentType, AmosMaintFunction } from './amos_component_models'
import { CorComponent } from './component_models'



// TODO: hardcoded asset ID
export const convertAmosMaintFuncToCorComponent = (amos: AmosMaintFunction) : CorComponent =>
{
	// let ancestor = traverseToAncestor(amos);
	
	// console.log(ancestor);
	
	
	return ({
		_id: amos._id + '_f',
		name: amos.display_name,
		type_name: amos.DESCRIPTION,
		erp_ref_id: amos._id,
		asset_id: 'sanderling',
		asset_name: 'MV Oceanex Sanderling',
		
		system_id: amos.system_id,
		system_name: amos.system_name,
		system_code: amos.system_code,
		
		parent_component_id: (amos?.parent_function_id) ? amos?.parent_function_id + '_f' : undefined,
		has_child_components: !!amos.child_function_ids.length,
		
		open_issue_ids: [],
		status: '',
		
		// child_components?: Component[],
		asset_class_code: amos.CODE,
		asset_code: amos.CODE,
		asset_class_description: amos.DESCRIPTION,
		asset_type_id: amos._id,
		asset_type_notes: amos.NOTES,
		
		is_critical: amos.CRITICALITYID,
		
		location_id: amos.LOCATIONID,
		location_name: amos.LOCATIONID,
		
		// serial_number?: any,
		
		notes: amos.NOTES,
		maintenance_function_id: amos._id,
		maintenance_function_code: amos.CODE,
		maintenance_function_description: amos.DESCRIPTION,
		maintenance_function_notes: amos.NOTES,
		
		amos_type: AmosComponentType.FUNCTION,
	})
}





// TODO: hardcoded asset ID
// TODO: Model AmosAsset
export const convertAmosAssetToCorComponent = (amos: AmosAsset) : CorComponent =>
{
	// let ancestor = traverseToAncestor(amos);
	
	
	if(amos._id === '760350200000013')
	{
		console.log({
			amos,
			// ancestor,
		})
	}
	
	
	return ({
		_id: amos._id + '_a',
		display_name: amos.display_name,
		type_name: amos.asset_type_name,
		erp_ref_id: amos._id,
		
		asset_id: 'sanderling',
		asset_name: 'MV Oceanex Sanderling',
		
		system_id:   amos.primary_group_id   || amos.system_id,
		system_name: amos.primary_group_name || amos.system_name,
		system_code: amos.primary_group_code || amos.system_code,
		
		parent_component_id: (amos?.parent_function_id) ? amos?.parent_function_id + '_f' : undefined,
		has_child_components: !!amos.child_asset_ids?.length,
		
		open_issue_ids: [],
		status: '',
		
		// child_components?: Component[],
		asset_class_code: amos.asset_code,
		asset_code: amos.asset_code,
		// asset_class_description: amos.asset_notes,
		asset_type_id: amos._id,
		asset_type_notes: amos.asset_notes,
		
		// is_critical: amos.CRITICALITYID,
		
		location_id: amos.location_id,
		location_name: amos.location_name,
		
		serial_number: amos.asset_serial_number,
		
		notes: amos.asset_notes,
		maintenance_function_id: amos._id,
		maintenance_function_code: amos.asset_code,
		maintenance_function_description: amos.maintenance_function_description,
		maintenance_function_notes: amos.maintenance_function_notes,
		
		amos_type: AmosComponentType.ASSET,
	})
}






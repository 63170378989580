import Grid from '@mui/material/Grid';


import IssuesTable from '../issueCollaboration/issueDetails/issueTable/IssuesTable';
import SearchableIssuesCardList from './SearchableIssuesCardList';
import { useSearchAndSort } from 'components/searchAndSort/SearchAndSortStateProvider';
import IssuesSearch from './IssuesSearch';



const IssuesListGridContent = () =>
{
	const { filtered_issues: issues } = useSearchAndSort();
	
	
	
	return (
		<Grid
			container
			className='cards_container'
			gap={2}
		>
			<IssuesSearch />
			
			<Grid
				item
				display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
				width='100%'
			>
				<IssuesTable issues={issues} title={''} />
			</Grid>
			
			<Grid
				item
				display={{xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
				width='100%'
			>
				<SearchableIssuesCardList
					issues={issues}
				/>
			</Grid>
		</Grid>
	)
};


export default IssuesListGridContent;
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import { useStyles } from '../../../styles/ManageTagsTabTheme';
import { TagInputProps } from 'app/dashboard/pages/settings/interfaces/settings_Interfaces';


const TagInputField = ({ tagTitle, setTagTitle } : TagInputProps) =>
{
	const classes = useStyles({});
	
	
	return (
		<>
			<Paper
				className={classes.CreateTagInputField}
			>
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder='Type to create tag'
					inputProps={{ 'aria-label': 'Create Tag' }}
					value={tagTitle}
					onChange={ (e) => setTagTitle( e.target.value ) }
				/>
				<IconButton sx={{ p: '10px' }} aria-label="search">
					<LocalOfferOutlinedIcon />
				</IconButton>
			</Paper>
		</>
	)
}


export default TagInputField;
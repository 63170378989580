import { CircularProgress, Grid } from '@mui/material'
import { useComponentRecords } from 'app/providers/ComponentRecordsLoader';
import ComponentRecordCard from './ComponentRecordCard';


const ComponentRecordsList = () =>
{
	const { component_records } = useComponentRecords();
	
	
	if(!component_records)
	{
		return <CircularProgress />
	};
	
	
	console.log({
		component_records,
	})
	
	
	return (
		<Grid
			container
			spacing={2}
		>
			{
				component_records?.map(component_record =>
					<Grid
						key={component_record._id}
						item
						xs={12}
						sm={6}
						md={6}
						lg={4}
					>
						<ComponentRecordCard
							component_record={component_record}
						/>
					</Grid>
				)
			}
		</Grid>
	)
};


export default ComponentRecordsList;
import Box from '@mui/material/Box';

import MobileHeader from '../MobileHeader';
import EmailInputField from '../../manage_user/components/EmailInputField';
import UserList from '../../manage_user/components/mobile/UserList';
import { useStyles } from '../../../styles/ManageUserTabTheme';
import InviteUsers from '../../../users/InviteUsers';
import ManageRoles from '../../manage_user/components/roles/ManageRoles';
import { Stack } from '@mui/material';
import ManageUsersTab from '../../manage_user/ManageUsersTab';


const ManageUsersPage = () =>
{
	const classes = useStyles();
	
	
	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{
				padding: '10px',
			}}
		>
			<MobileHeader
				title='Manage Users'
				variantType='h3'
			/>
			
			<ManageUsersTab />
		</Stack>
	)
}


export default ManageUsersPage;
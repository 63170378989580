/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GradientPinkBlue } from '@visx/gradient';
import { animated, useTransition, to } from '@react-spring/web';
import { useCostAnalyticsLoader } from './CostAnalyticsLoader';
import { CostCenter, CostCode, CostRecord } from 'models';
import { useCostAnalyticsState } from './CostAnalyticsStateProvider';


export interface CostCenterUsage
{
	_id: string,
	description: string,
	cost_records: {
		_id: string,
		cost: {
			quantity: string,
			currency: string,
			is_approved: boolean,
		},
	}[],
};


export interface CostCodeUsage
{
	_id: string,
	description: string,
	cost_records: {
		_id: string,
		cost: {
			quantity: string,
			currency: string,
			is_approved: boolean,
		},
	}[],
}



const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export type PieProps = {
	width: number;
	height: number;
	margin?: typeof defaultMargin;
	animate?: boolean;
};

const CostDistributionPieChart = ({
	width,
	height,
	margin = defaultMargin,
	animate = true,
}: PieProps) =>
{
	const { cost_centers, cost_codes } = useCostAnalyticsLoader();
	const { cost_records_state_data: cost_records, processCostCenterFilter, processCostCodeFilter, resetData } = useCostAnalyticsState();
	
	
	const [ cost_centers_usage, setCostCentersUsage ] = useState<CostCenterUsage[]>([]);
	const [ cost_codes_usage, setCostCodesUsage ] = useState<CostCodeUsage[]>([]);
	
	
	const [selectedCostCodeId, setSelectedCostCodeId] = useState<string>(null);
	const [selectedCostCenterId, setSelectedCostCenterId] = useState<string>(null);
	
	
	
	useEffect(() =>
	{
		async function processCostCenters() {
			
			const updatedCostCentersUsage = await Promise.all(
				
				cost_centers?.map(async (cost_center: CostCenter) =>
				{
					const cost_center_with_cost_records: CostCenterUsage =
					{
						_id: cost_center?._id,
						description: cost_center?.description,
						cost_records: [],
					};
					
					
					cost_records?.forEach((cost_record: CostRecord) =>
					{
						// console.log('=============', cost_record)
						if (cost_record?.cost_center_ids?.includes(cost_center._id))
						{
							cost_center_with_cost_records?.cost_records?.push({
								_id: cost_record?._id,
								cost: cost_record?.cost,
							});
						}
					});
					
					// console.log('YOOO', cost_center_with_cost_records);
					
					return cost_center_with_cost_records;
				})
			);
			
			setCostCentersUsage(updatedCostCentersUsage);
		}
		
		processCostCenters();
	}, [cost_centers, cost_records]);
	
	
	
	useEffect(() =>
	{
		async function processCostCodes() {
			
			const updatedCostCodesUsage = await Promise.all(
				
				cost_codes?.map(async (cost_code: CostCode) =>
				{
					const cost_code_with_cost_records: CostCodeUsage =
					{
						_id: cost_code?._id,
						description: cost_code?.description,
						cost_records: [],
					};
					
					cost_records?.forEach((cost_record: CostRecord) =>
					{
						if (cost_record?.cost_code_ids?.includes(cost_code._id))
						{
								cost_code_with_cost_records?.cost_records?.push({
								_id: cost_record?._id,
								cost: cost_record?.cost,
							});
						}
					});
					
					// console.log('HELLOO', cost_code_with_cost_records);
					
					return cost_code_with_cost_records;
				})
			);
			
			setCostCodesUsage(updatedCostCodesUsage);
		}
		
		processCostCodes();
	}, [cost_codes, cost_records]);
	
	
	
	
	
	if (width < 10) return null;
	
	const innerWidth = width - margin.left - margin.right;
	const innerHeight = height - margin.top - margin.bottom;
	const radius = Math.min(innerWidth, innerHeight) / 2;
	const centerY = innerHeight / 2;
	const centerX = innerWidth / 2;
	const donutThickness = 50;
	
	
	
	
	
	// console.log('COST CENTERS', cost_centers_usage, cost_centers)
	// console.log('COST_CODES',cost_codes_usage, cost_codes);
	
	
	// accessor functions
	const getUsage = (d) => d.cost_records?.reduce((accumulator, currentValue) =>
	{
		// console.log('ACCUMULATOR', accumulator)
		
		const usage = accumulator + parseFloat(currentValue?.cost?.quantity);
		// console.log('CURRENT_VALUE', currentValue, usage)
		
		return usage;
	}, 0);
	
	
	// color scales
	const getBrowserColor = scaleOrdinal({
		domain: cost_codes_usage.map(x => x.description),
		range: [
			'rgba(255,255,255,0.7)',
			'rgba(255,255,255,0.6)',
			'rgba(255,255,255,0.5)',
			'rgba(255,255,255,0.4)',
			'rgba(255,255,255,0.3)',
			'rgba(255,255,255,0.2)',
			'rgba(255,255,255,0.1)',
			],
	});
	
	const getLetterFrequencyColor = scaleOrdinal({
	domain: cost_centers_usage.map((l) => l.description),
	range: ['rgba(93,30,91,1)', 'rgba(93,30,91,0.8)', 'rgba(93,30,91,0.6)', 'rgba(93,30,91,0.4)'],
	});
	
	
	
	
	return (
		<svg width={width} height={height}>
			<GradientPinkBlue id="visx-pie-gradient" />
			<rect rx={14} width={width} height={height} fill="url('#visx-pie-gradient')" />
			<Group top={centerY + margin.top} left={centerX + margin.left}>
				<Pie
					data={cost_codes_usage}
					pieValue={getUsage}
					outerRadius={radius}
					innerRadius={radius - donutThickness}
					cornerRadius={3}
					padAngle={0.005}
				>
					{(pie) => (
						<AnimatedPie<CostCodeUsage>
							{...pie}
							animate={animate}
							getKey={(arc) => arc.data.description}
							onClickDatum={({ data: { _id } }) =>
							{
								setSelectedCostCodeId(
									selectedCostCodeId && selectedCostCodeId === _id
									? null
									: _id,
								)
								
								animate &&
									(selectedCostCodeId === _id)
									? resetData()
									: processCostCodeFilter(_id)
							}}
							getColor={(arc) => getBrowserColor(arc.data.description)}
						/>
					)}
				</Pie>
				<Pie
					data={cost_centers_usage}
					pieValue={getUsage}
					pieSortValues={() => -1}
					outerRadius={radius - donutThickness * 1.3}
				>
					{(pie) => (
						<AnimatedPie<CostCenterUsage>
							{...pie}
							animate={animate}
							getKey={({ data: { description } }) => description}
							onClickDatum={({ data: { _id } }) =>
							{
								setSelectedCostCenterId(
									selectedCostCenterId && selectedCostCenterId === _id
									? null
									: _id,
								)
								
								animate &&
									(selectedCostCenterId === _id)
										? resetData()
										: processCostCenterFilter(_id)
							}}
							getColor={({ data: { description } }) => getLetterFrequencyColor(description)}
						/>
					)}
				</Pie>
			</Group>
			{animate && (
				<text
					textAnchor="end"
					x={width - 16}
					y={height - 16}
					fill="white"
					fontSize={11}
					fontWeight={300}
					pointerEvents="none"
				>
					Click segments to update
				</text>
			)}
		</svg>
	);
};



export default CostDistributionPieChart;





// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
	// enter from 360° if end angle is > 180°
	startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
	opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
	startAngle,
	endAngle,
	opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
	animate?: boolean;
	getKey: (d: PieArcDatum<Datum>) => string;
	getColor: (d: PieArcDatum<Datum>) => string;
	onClickDatum: (d: PieArcDatum<Datum>) => void;
	delay?: number;
};

function AnimatedPie<Datum>({
	animate,
	arcs,
	path,
	getKey,
	getColor,
	onClickDatum,
}: AnimatedPieProps<Datum>) {
	const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
	from: animate ? fromLeaveTransition : enterUpdateTransition,
	enter: enterUpdateTransition,
	update: enterUpdateTransition,
	leave: animate ? fromLeaveTransition : enterUpdateTransition,
	keys: getKey,
	});
	return transitions((props, arc, { key }) => {
	const [centroidX, centroidY] = path.centroid(arc);
	const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

	return (
		<g key={key}>
			<animated.path
				// compute interpolated path d attribute from intermediate angle values
				d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
				path({
					...arc,
					startAngle,
					endAngle,
				}),
				)}
				fill={getColor(arc)}
				onClick={() => onClickDatum(arc)}
				onTouchStart={() => onClickDatum(arc)}
			/>
			{
				hasSpaceForLabel && (
					<animated.g style={{ opacity: props.opacity }}>
						<text
							fill="white"
							x={centroidX}
							y={centroidY}
							dy=".33em"
							fontSize={9}
							textAnchor="middle"
							pointerEvents="none"
						>
							{getKey(arc)}
						</text>
					</animated.g>
			)}
		</g>
	);
	});
}

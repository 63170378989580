import { useNavigate } from 'react-router';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Issue, IssueStatus } from 'models';
import { Chip, Divider } from '@mui/material';
import RelatedAssetAndComponents from './issueTable/RelatedAssetAndComponents';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { DateTime } from 'luxon';
import { relativeDatetimeText, relativeDaysDiff } from 'utils';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';



const IssueCard =
({
	issue,
} : {
	issue: Issue,
})  =>
{
	const navigate = useNavigate();
	
	const { status, issue_num, name, _id } = issue;
	
	const created_at_datetime = DateTime.fromISO(issue?.date_opened)
	const days_diff = relativeDaysDiff(created_at_datetime);
	
	const is_assigned = ((issue?.collaborator_ids?.length > 0
		|| issue?.role_ids?.length > 0
	)) ? true : false;
	
	return (
		<>
			<Stack
				direction='column'
				justifyContent='space-between'
				alignItems='flex-start'
				className='related_issue_card'
				onClick={() => navigate(`/issues/${_id}`)}
			>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='space-between'
					width='100%'
				>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='flex-start'
						width='100%'
						spacing={1}
					>
						<Typography
							variant='subtitle1'
							sx={{
								background: defaultAppTheme.palette.secondary.main,
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							Issue #{issue_num}
						</Typography>
						
						<Typography
							variant='h3'
							sx={{
								marginLeft: '5px',
								inlineSize: '190px',
								overflowWrap: 'break-word',
							}}
						>
							{name}
						</Typography>
					</Stack>
					
					<Grid
						container
						width='100%'
						sx={{
							marginTop: '5px'
						}}
					>
						<RelatedAssetAndComponents component_ids={issue.component_ids} />
					</Grid>
				</Stack>
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					width='100%'
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='center'
						sx={{
							padding: '16px',
						}}
					>
						<CorIcon
							of={(status === IssueStatus.OPEN) ? 'open_issue' : 'closed_issue'}
							size='24px'
							color={
								(status === IssueStatus.OPEN)
								? defaultAppTheme.palette.secondary.main
								: (status === IssueStatus.IN_PROGRESS)
								? defaultAppTheme.palette.primary.main
								: defaultAppTheme.palette.success.main
								}
						/>
						
						<Typography
							variant='body1'
							color={
								(status === IssueStatus.OPEN)
								? defaultAppTheme.palette.secondary.main
								: (status === IssueStatus.IN_PROGRESS)
								? defaultAppTheme.palette.primary.main
								: defaultAppTheme.palette.success.main
							}
						>
							{ status.toUpperCase() }
						</Typography>
					</Stack>
					
					<Stack
						direction='column'
						justifyContent='center'
						alignItems={'flex-start'}
						spacing={1}
					>
						<Typography
							sx={{
								background: ((days_diff > -7) && (issue?.status !== IssueStatus.CLOSED))
									? defaultAppTheme.palette.primary.main
									: (days_diff < -7 && days_diff >= -30 && issue?.status !== IssueStatus.CLOSED)
									? defaultAppTheme.palette.warning.main
									: (days_diff < -30 && issue?.status !== IssueStatus.CLOSED)
									? defaultAppTheme.palette.secondary.main
									: defaultAppTheme.palette.grey['500'],
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							{ relativeDatetimeText(created_at_datetime) }
						</Typography>
						
						<ConciseDateAndTime datetime={created_at_datetime} />
					</Stack>
				</Stack>
				
				{
					(is_assigned) ? (
						<Chip color='success' label='assigned'/>
					) : (
						<Chip label='unassigned' />
					)
				}
				
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
		</>
	)
};


export default IssueCard;
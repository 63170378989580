import { Interval } from 'luxon';
import { cx } from 'stuff';
import CalendarDay from './CalendarDay';
import { useCalendar } from './CalendarProvider';
import { daysOfWeek } from './calendar_utils';


const CalendarWeek =
({
	week
} : {
	week: Interval;
}) =>
{
	let days = daysOfWeek(week);
	
	const { show_week_num } = useCalendar();
	
	
	return <>
		<div
			className={cx(
				'calendar3_month',
				{
					odd_month: (week.start.month % 2)
				}
			)}
		>
			{
				week.start.monthShort
			}
		</div>
		
		{days.map(day =>
			<CalendarDay
				day={day}
				key={day.toISO()}
			/>
		)}
		
		
		{
			(show_week_num) &&
				<div
					className='calendar3_week_num'
				>
					{days[4].weekNumber}
				</div>
		}
	</>
}


export default CalendarWeek;
import NestedItem from '../../nested_sidebar/NestedItem';
import SearchUsersInput from './search_user_field/SearchUsersInput';
import CollaboratorsList from './CollaboratorsList';
import { ChatUser } from '../../../interfaces/content_data_interfaces';


export interface EditCollaboratorsProps
{
	_collaborators: ChatUser[];
	_users: ChatUser[];
	_whoHasSelected: (newCollab_: ChatUser) => void;
}


const EditCollaborators =
({
	_users,
	_collaborators,
	_whoHasSelected,
}
: EditCollaboratorsProps) =>
{
	return (
		<>
			<NestedItem
				id_title='EditCollaborators'
				title='Manage Collaborators'
				icon_name='account_group'
				color='jade'
			/>
			
			<div className='edit_collaborators' >
			
				<CollaboratorsList
					_collaborators={ _collaborators }
				/>
				
				<SearchUsersInput
					_placeholder='Search users'
					_users={ _users }
					_whoHasSelected={ _whoHasSelected }
				/>
				
			</div>
		</>
	)
}


export default EditCollaborators;
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';


const InstantNotificationPopup =
({
	open,
	setOpen,
	autoHideDuration,
	severity,
	message,
}: {
	open: boolean,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>,
	autoHideDuration?: number,
	severity: AlertColor,
	message: string,
}) =>
{
	const alert_type_title = severity.charAt(0).toUpperCase() + severity.slice(1);
	
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		
		setOpen(false);
	};
	
	
	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={open}
				autoHideDuration={autoHideDuration || 6000}
				onClose={handleClose}
				sx={{
					marginBottom: '60px'
				}}
			>
				<Alert
					severity={severity}
					onClose={handleClose}
				>
					<AlertTitle>{alert_type_title}</AlertTitle>
					{message}
				</Alert>
			</Snackbar>
		</div>
	)
};


export default InstantNotificationPopup;
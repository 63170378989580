import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CorIcon from 'components/icons/CorIcon';
import { useNavigate } from 'react-router';
import { defaultAppTheme, responsiveAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useIssue } from './IssueProvider';
import { IssueStatus } from 'models';



const IssueCollaborationHeader = () =>
{
	const navigate = useNavigate();
	
	const { issue } = useIssue();
	const { name, issue_num, status } = issue;
	
	const small_screen = useMediaQuery(responsiveAppTheme.breakpoints.down('sm'));
	
	
	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			alignItems='flex-start'
			spacing={1}
			sx={{
				padding: '2px',
			}}
			className='issue_collab_header'
		>
			
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				sx={{
					cursor: 'pointer',
				}}
				onClick={() => navigate(-1)}
			>
				<Button
					sx={{
						textTransform: 'none',
						padding: '3px 4px',
					}}
				>
					<CorIcon
						of='back'
						size='25px'
						color={defaultAppTheme.palette.primary.main}
					/>
					<Typography variant='caption'>
						Back to list
					</Typography>
				</Button>
			</Stack>
			
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				sx={{
					padding: '2px 16px',
					margin: 0,
					marginTop: 0,
				}}
				style={{
					marginTop: 0,
				}}
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					sx={{
						padding: '8px',
					}}
				>
					<CorIcon
						of={(status === IssueStatus.OPEN) ? 'open_issue' : 'closed_issue'}
						size='24px'
						color={
							(status === IssueStatus.OPEN)
								? defaultAppTheme.palette.secondary.main
								: (status === IssueStatus.IN_PROGRESS)
								? defaultAppTheme.palette.primary.main
								: defaultAppTheme.palette.success.main
							}
					/>
					
					<Typography
						variant='body1'
						color={
							(status === IssueStatus.OPEN)
								? defaultAppTheme.palette.secondary.main
								: (status === IssueStatus.IN_PROGRESS)
								? defaultAppTheme.palette.primary.main
								: defaultAppTheme.palette.success.main
						}
					>
						{ status.toUpperCase() }
					</Typography>
				</Stack>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='flex-start'
					sx={{
						padding: '4px',
					}}
				>
					<Typography
						variant='h1'
						sx={{
							padding: '2px',
						}}
					>
						Issue #{issue_num}
					</Typography>
					
					{
						!small_screen &&
							<Typography
								variant='h3'
								fontWeight={300}
								sx={{
									padding: '2px',
								}}
							>
								{name}
							</Typography>
					}
				</Stack>
				
			</Stack>
			
		</Stack>
	)
};


export default IssueCollaborationHeader;
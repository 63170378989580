import { useState, useEffect } from 'react';

import Highlighter from 'react-highlight-words';
import FileButton from 'components/fileButton/FileButton';
import { Stack } from '@mui/material';
import { ChatUser } from '../../../interfaces/content_data_interfaces';


export interface ChatCommentProps
{
	_description: string[];
	_collaborators: ChatUser[];
	_file?: File[] | undefined;
};


const ChatComment =
({
	_description, 
	_collaborators,
	_file,
}
: ChatCommentProps) =>
{
	const [collabs, setCollabs] = useState< string[] >([]);
	
	
	// Initialize the collabs with the current collaborators
	useEffect(() =>
		{
			const collabs_array: string[] = [];
			
			_collaborators.map(( user ) => {
				return collabs_array.push( ( '@' + user.username ).toString() );
			})
			
			setCollabs( collabs_array );
		},
		[ _collaborators ]
	);
	
	
	return (
		<Stack
			className='comment'
		>
			
			<div
				className='comment__ChatBubble'
			>
				{
					_description.map( ( line, id ) =>
						<p 
							key={ id }
						>
							{
								(line === ' ' || line === '')
								?
									<br/>
								:
									<Highlighter
										highlightClassName='username-highlist'
										searchWords={
											(collabs.length !== 0)
												? collabs
												: []
										}
										autoEscape={ false }
										textToHighlight={ line }
									/>
							}
						</p>
					)
				}
			</div>
			
			{
				(_file !== undefined)
				&&
					_file.map((file, key) =>
						<div
							key={ key }
						>
							<FileButton
								_file={ file }
							/>
						</div>
					)
			}
		</Stack>
	)
}


export default ChatComment;
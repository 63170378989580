import {
	Box,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { ActionProps } from '../../types';

export interface PassFailProps extends ActionProps {}

export const PassFail = (props: PassFailProps) => {
	return (
		<>
			<Description {...props} />
			<Options {...props} />
		</>
	);
};


interface DescriptionProps extends ActionProps {}


const Description = ({ control, sectionIndex, fieldIndex }: DescriptionProps) =>
{
	return (
		<Controller
			control={control}
			name={`sections.${sectionIndex}.fields.${fieldIndex}.pass_fail.description`}
			defaultValue=''
			render={({ field: { value, onChange } }) => (
				<TextField
					value={value}
					onChange={onChange}
					label='Work-step Details'
					variant='filled'
				/>
			)}
		/>
	);
};


interface OptionsProps extends ActionProps {}


const values = ['pass', 'fail', 'n/a'] as const;


const labels: Record<typeof values[number], string> =
{
	pass: 'Pass',
	fail: 'Fail',
	'n/a': 'N/A',
};


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	return (
		<Box>
			<Controller
				control={control}
				name={`sections.${sectionIndex}.fields.${fieldIndex}.pass_fail.value`}
				render={({ field: { value, onChange } }) => {
					return (
						<RadioGroup value={value} onChange={onChange}>
							{values.map((value) => {
								return (
									<FormControlLabel
										key={value}
										value={value}
										control={<Radio />}
										label={labels[value]}
									/>
								);
							})}
						</RadioGroup>
					);
				}}
			/>
		</Box>
	);
};


export default PassFail;
import { useEffect } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { Section } from './Section/Section';
import AddCircle from '@mui/icons-material/AddCircleOutlineRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';


export interface SectionsProps
{
	form: UseFormReturn<any>;
	showPreview: () => void;
}


export const CreateWorkOrderTemplateSections = ({ form, showPreview }: SectionsProps) =>
{
	const { control } = form;
	
	
	const { fields: sections, append } = useFieldArray({
		control,
		name: 'sections',
	});
	
	
	// useEffect(() => {
	// 	// to add an empty section at first
	// 	// if (fields.length === 0) {
	// 	// 	append({});
	// 	// }
	// }, [append, fields.length]);
	
	
	
	return (
		<Box
			sx={{
				// marginInline: 'auto',
				maxWidth: '800px',
				paddingBottom: '2rem',
				width: 'calc(100% - 40px)',
				alignSelf: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				{sections.map((field, index) => (
					<Section
						key={field.id}
						control={control}
						index={index}
					/>
				))}
			</Box>
			
			<Box
				sx={{
					marginTop: '2rem',
					backgroundColor: 'white',
					border: '1px solid #d8dbdf',
					display: 'grid',
					gridTemplateColumns: '1fr',
					gap: '1rem',
					color: '#666666',
				}}
			>
				
				<Button
					sx={{
						borderRight: '1px solid #d8dbdf',
						padding: '1rem',
					}}
					onClick={() => append({})}
				>
					<AddCircle htmlColor='#666666' />
					<Box>
						Add Section
					</Box>
				</Button>
				
				{/* <Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '1rem',
						cursor: 'pointer',
					}}
					onClick={showPreview}
				>
					<VisibilityIcon htmlColor='#666666' />
					<Box>Preview</Box>
				</Box> */}
				
			</Box>
		</Box>
	);
};


export default CreateWorkOrderTemplateSections;
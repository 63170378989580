import FormSelect from 'components/formSelect/FormSelect';


const SeveritySelect = () =>
{
	const MenuProps = {
			style: {
				zIndex: 13000
			}
	}
	
	const items = ['High', 'Medium', 'Low'];
	
	
	return (
		<FormSelect
			inputLabel='Severity *'
			id='select-issue-severity'
			formLabel='priority'
			MenuProps={MenuProps}
			selectItems={items}
		/>
	)
};


export default SeveritySelect;
import { DateTime } from 'luxon';
import { useCalendar } from './CalendarProvider';
import TimePickerWidget from 'app/gizmos/fields/datetimes/TimePickerWidget';


const CalendarTime = () =>
{
	const { selected_datetime, onTimeSelected } = useCalendar();
	
	
	return <div
		className='calendar3_time'
	>
		<TimePickerWidget
			value={selected_datetime}
			onChange={(datetime: DateTime) => {
				if(datetime.isValid)
					onTimeSelected(datetime)
			}}
		/>
	</div>
}


export default CalendarTime;
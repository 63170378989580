import { Chip, Grid, Stack, Typography } from '@mui/material';

import { useWorkOrder } from '../../WorkOrderProvider';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import EditCostCodes from 'app/dashboard/pages/requisitions/EditCostCodes';




const WorkOrderCostCodes = () =>
{
	const form = useFormContext();
	const { cost_codes, work_order, updateCostCodes } = useWorkOrder();
	
	const related_cost_codes = cost_codes.filter(x => work_order?.cost_code_ids?.includes(x._id));
	
	
	useEffect(() =>
	{
		form.setValue(
			'cost_codes',
			related_cost_codes
		)
	}, [work_order?.cost_code_ids]);
	
	
	return (
		<Stack>
			<Typography
				variant='h6'
			>
				Cost Codes
			</Typography>
			
			<Grid
				container
				gap={1}
			>
				{
					related_cost_codes.map(cost_code => (
						<Grid
							item
							key={cost_code?._id}
						>
							<Chip
								variant='filled'
								label={cost_code?.description}
								color='warning'
							/>
						</Grid>
					))
				}
			</Grid>
			
			<EditCostCodes
				cost_codes={cost_codes}
				handleSubmit={updateCostCodes}
			/>
		</Stack>
	)
}


export default WorkOrderCostCodes;
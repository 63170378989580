import { CircularProgress, List, ListItem } from '@mui/material'
import { useComponentReadings } from 'app/providers/ComponentReadingsLoader';
import ComponentReadingCard from './ComponentReadingCard';


const ComponentReadingsList = () =>
{
	const { component_readings } = useComponentReadings();
	
	
	if(!component_readings)
	{
		return <CircularProgress />
	};
	
	
	console.log({
		component_readings,
	})
	
	
	return (
		<List
		>
			{
				component_readings?.map(component_reading =>
					<ListItem
						key={component_reading._id}
					>
						<ComponentReadingCard
							component_reading={component_reading}
						/>
					</ListItem>
				)
			}
		</List>
	)
};


export default ComponentReadingsList;
import {
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import Draft, { convertToRaw, EditorState, KeyBindingUtil } from 'draft-js';
import Editor from '@draft-js-plugins/editor';

import createMentionPlugin, {
	defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';


// TODO: Use firebase hooks to resolve downlaod URLs and map them to avatar property
const users2mentions = (users) =>
{
	return users.map(user => ({
		...user,
		name: user.display_name,
		// avatar: ''
	}))
}



const ChatSimpleEditor = ({ users, onSubmit, onChange, editor_state, setEditorState }) =>
{
	//const write = useWrite();
	
	const ref = useRef<Editor>(null);
	
	const [ open, setOpen ] = useState(false);
	
	
	//console.log(users);
	
	
	const [ suggestions, setSuggestions ] = useState(users2mentions(users));
	
	
	const { MentionSuggestions, plugins } = useMemo(() =>
		{
			const mentionPlugin = createMentionPlugin();
			
			// eslint-disable-next-line no-shadow
			const { MentionSuggestions } = mentionPlugin;
			
			// eslint-disable-next-line no-shadow
			const plugins = [mentionPlugin];
			
			return { plugins, MentionSuggestions };
		},
		[]
	);
	
	
	const onOpenChange = useCallback((open: boolean) =>
		{
			setOpen(open);
		},
		[]
	);
	
	
	const onSearchChange = useCallback(({ value }: { value: string }) =>
		{
			setSuggestions(defaultSuggestionsFilter(value, users2mentions(users)));
			
			console.log(value);
		},
		[users]
	);
	
	
	
	return (
		<div
			className={'simple_editor'}
			onClick={() => {
				ref.current!.focus();
			}}
		>
			<Editor
				editorKey={'editor'}
				editorState={editor_state}
				onChange={(editor_state) => {
					setEditorState(editor_state);
					onChange(editor_state);
				}}
				plugins={plugins}
				ref={ref}
				placeholder='Type your message'
				
				keyBindingFn={(e) => {
					if(e.key === 'Delete')
					{
						// name this whatever you want
						return 'delete-me'
					}
					if(KeyBindingUtil.hasCommandModifier(e) && (e.key === 'Enter'))
					{
						// name this whatever you want
						return 'submit'
					}
					
					// This wasn't the delete key, so we return Draft's default command for this key
					return Draft.getDefaultKeyBinding(e)
				}}
				
				handleKeyCommand={(command) => {
					if(command === 'delete-me')
					{
						// Do what you want to here, then tell Draft that we've taken care of this command
						return 'handled'
					}
					if(command === 'submit')
					{
						let raw = convertToRaw(editor_state?.getCurrentContent());
						
						let plain_text = raw.blocks.map(x => x.text).join('\n\n').trim();
						
						console.log(editor_state, raw, plain_text);
						
						onSubmit(raw);
						
						setEditorState(EditorState.createEmpty())
						
						// Do what you want to here, then tell Draft that we've taken care of this command
						return 'handled';
					}
					
					// This wasn't the 'delete-me' command, so we want Draft to handle it instead. 
					// We do this by telling Draft we haven't handled it. 
					return 'not-handled';
				}}
				
			/>
			
			<MentionSuggestions
				open={open}
				onOpenChange={onOpenChange}
				suggestions={suggestions}
				onSearchChange={onSearchChange}
				onAddMention={(value) => {
					// get the mention object selected
					
					console.log(value);
				}}
			/>
			
		</div>
	);
}


export default ChatSimpleEditor;
import { useAuthData } from 'stuff';
import { useCollection } from 'ember';

import { CircularProgress, Box, Stack, Typography, Grid, Button } from '@mui/material';
import { MultiSelectTable } from 'pages/CreateWorkOrder/GeneralDescription/Issues/MultiSelectTable/MultiSelectTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import ComponentChip from 'app/dashboard/pages/issueCollaboration/issueDetails/ComponentChip';
import { Issue } from 'types/issue_level_interfaces';



interface SelectIssuesModalProps
{
	selectedIssues: Issue[],
	setSelectedIssues: (issue: Issue[]) => void,
	addSelectedIssues: () => void,
}



const SelectableIssuesTable =
({
	selectedIssues,
	setSelectedIssues,
	addSelectedIssues,
}: SelectIssuesModalProps) =>
{
	const { claims } = useAuthData();
	
	// Get the Issue data
	const issues_collection_path = `organizations/${claims?.organization_id}/issues`;
	const issue_collection = useCollection(issues_collection_path);
	
	
	if(issue_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const issues = issue_collection?.data as Issue[];
	
	console.log({issues});
	
	
	return (
		<MultiSelectTable
			options={issues}
			onSelect={(selectedOptions) => {
				setSelectedIssues(selectedOptions)
			}}
			rows={[
				{
					title: "",
					getCell: (_, isSelected) => (
						!isSelected ? (
							<CorIcon of='empty_circle' size='20px' />
						) : (
							<CorIcon of='circle_checked' size='20px' color='#74b70a' />
						)
					),
				},
				{
					title: 'Issue #',
					getCell(issue) {
						return (
							<Box sx={{ marginInline: '0.5rem' }}>
								{issue.issue_num}
							</Box>
						);
					},
				},
				{
					title: 'Status',
					getCell(issue) {
						const { status } = issue;
						return (
							<Stack
								direction='row'
								justifyContent='center'
								alignItems='center'
							>
								<CorIcon
									of={(status === 'open') ? 'open_issue' : 'closed_issue'}
									size='20px'
									color={
										(status === 'open')
											? defaultAppTheme.palette.secondary.main
											: defaultAppTheme.palette.success.main
										}
								/>
								
								<Typography
									variant='caption'
									color={
										(status === 'open')
										? defaultAppTheme.palette.secondary.main
										: defaultAppTheme.palette.success.main
									}
									sx={{
										paddingLeft: '5px',
									}}
								>
									{ status.toUpperCase() }
								</Typography>
							</Stack>
						)
					},
				},
				{
					title: 'Title',
					getCell(issue) {
						return <Typography variant='subtitle2'> {issue.name} </Typography>
					},
				},
				{
					title: 'Affected Components',
					getCell(issue) {
						return (
							<Grid
								container
								width='100%'
								sx={{
									marginTop: '5px'
								}}
							>
								{
									issue?.component_names?.map((component_name, index) =>
											<Grid
												key={index}
												item
												sx={{ maxWidth: '100%' }}
											>
												<ComponentChip name={component_name} />
											</Grid>
										)
								}
							</Grid>
						)
					},
				}
			]}
			tableDescription={
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Button
						sx={{
							backgroundColor: "#186ab4",
							color: "white",
							cursor: "pointer",
							padding: "5px",
							borderRadius: "5px",
							"&:hover": {
								backgroundColor: "#186ab4",
							},
							textTransform: 'none',
						}}
						onClick={() => addSelectedIssues()}
					>
						Confirm
					</Button>
				</Box>
			}
			skipFilterOnSelect={true}
			initialSelectedOptions={selectedIssues}
		/>
	)
};


export default SelectableIssuesTable;
import {
	Control,
	Controller,
	FieldPath,
	ControllerProps,
} from 'react-hook-form';

import {
	Box,
	FormLabel,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';

import { ScheduleFormData } from 'pages/CreateWorkOrder/scheduling_types';

import { Container } from './styles';



export interface BasedOnProps<T extends FieldPath<ScheduleFormData>>
{
	control: Control<any, any>,
	name: any,//T,
	values: {
		value: ControllerProps<ScheduleFormData, T>['defaultValue'],
		label: string,
	}[],
	defaultValue: ControllerProps['defaultValue'],
}


export const BasedOn = <T extends FieldPath<ScheduleFormData>>({
	name,
	control,
	values,
	defaultValue,
}: BasedOnProps<T>) =>
{
	return (
		<Box>
			<FormLabel>
				Based On
			</FormLabel>
			
			<Controller
				control={control}
				name={name}
				defaultValue={defaultValue}
				render={({ field }) => (
					<Container>
						<RadioGroup
							value={field.value}
							onChange={field.onChange}
						>
							{values.map(({ value, label }) => {
								return (
									<FormControlLabel
										value={value}
										control={<Radio />}
										label={label}
										key={label}
									/>
								);
							})}
						</RadioGroup>
					</Container>
				)}
			/>
		</Box>
	);
};


export default BasedOn;
import { Stack } from '@mui/material';
import { ComponentReading } from 'models';
import ReadingValue from './ReadingValue';
import RecordedAt from './RecordedAt';


const ComponentReadingCard =
({
	component_reading
} : {
	component_reading: ComponentReading,
}) =>
{
	return (
		<Stack
			className='basic_card'
			direction='row'
			padding={1}
		>
			<ReadingValue
				reading={component_reading}
			/>
			
			<Stack
				direction='column'
				padding={1}
			>
				<Stack
					padding={.5}
				>
					{component_reading.recorded_by_user_name || component_reading.recorded_by_user_id}
				</Stack>
				<RecordedAt
					datetime={component_reading.recorded_at_datetime}
				/>
			</Stack>
			
		</Stack>
	)
};


export default ComponentReadingCard;
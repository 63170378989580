import React from 'react';

import CorIcon from 'components/icons/CorIcon';

import ContentIssuedUser from './content/IssuedUser';
import ContentTags from './content/Tags';
import ContentEditCollaborators from './content/edit_collaborators/EditCollaborators'
import ContentSearchLog from './content/SearchLog';
import ContentDouments from './content/Documents';
import ContentAssetHistory from './content/AssetHistory';

import { ChatUser, IssueLog } from '../interfaces/content_data_interfaces';


export interface SidebarProps
{
	isssueLog: IssueLog[];
	collaborators: ChatUser[];
	users: ChatUser[];
	tagClicked?: (tagName: string) => void;
	whoHasSelected: (newCollab_: ChatUser) => void;
	filteredLog: (value: React.SetStateAction<IssueLog[]>) => void
};


const Sidebar =
({
	isssueLog,
	collaborators,
	users,
	tagClicked,
	whoHasSelected,
	filteredLog,
}
: SidebarProps) =>
{
	return (
		<>
			<>
				<input 
					type= 'checkbox'
					id='check'
				/>
				
				<label
					htmlFor='check'
				>
					<i id='btn'>
						<CorIcon
							of='arrow_left'
							size='1.3rem'
							color='#4C4C4C'
						/>
					</i>
				</label>
			</>
			
			<div className={ 'sidebar' } >
				<ContentIssuedUser
					_issue_Headline={ isssueLog[0] }
				/>
				
				<ContentTags
					_issue_Headline={ isssueLog[0] }
					_tagClicked={ tagClicked }
				/>
				
				<ContentSearchLog
					_isssueLog={ isssueLog }
					_filteredLog={ filteredLog }
				/>
				
				<ContentEditCollaborators
					_users={ users }
					_collaborators={ collaborators }
					_whoHasSelected={ whoHasSelected }
				/>
				
				<ContentDouments
					_isssueLog={ isssueLog }
				/>
				
				<ContentAssetHistory/>
				
			</div>
		</>
	)
}


export default Sidebar;
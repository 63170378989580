import { Controller, useFormContext } from 'react-hook-form';

import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';

import { FormInputType, ResponseField } from 'types/forms';

import { WorkOrderResponseForm } from 'types/forms';


export interface ReplaceProps
{
	field: ResponseField & {
		type: FormInputType.Replace,
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const Replace = ({ field, sectionIndex, fieldIndex }: ReplaceProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	const { equipment } = field.value;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '0.5rem',
				}}
			>
				Equipments:{' '}
				{equipment.map((equipment) => (
					<Box
						sx={{
							display: 'inline-block',
							backgroundColor: '#C85656',
							paddingBlock: '0.25rem',
							paddingInline: '0.5rem',
							borderRadius: '5px',
							color: 'white',
						}}
						key={equipment._id}
					>
						{equipment.label}
					</Box>
				))}
			</Box>
			
			<FormControl>
				<Controller
					control={control}
					name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.value`}
					defaultValue=''
					render={({ field: { value, onChange } }) => (
						<RadioGroup
							value={value}
							onChange={(e) => onChange(e.target.value)}
						>
							<FormControlLabel
								control={<Radio value='replaced' />}
								label='Replaced'
							/>
							<FormControlLabel
								control={<Radio value='ok' />}
								label='Ok'
							/>
						</RadioGroup>
					)}
				/>
			</FormControl>
			
		</Box>
	);
};


export default Replace;
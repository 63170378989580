import { useNavigate } from 'react-router';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Chip, Divider } from '@mui/material';
import { Requisition, RequisitionStatus } from 'models';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import RequisitionCostCentersList from './RequisitionCostCentersList';
import { DateTime } from 'luxon';
import { relativeDatetimeText, relativeDaysDiff } from 'utils';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';



const RequisitionCard =
({
	requisition,
} : {
	requisition: Requisition,
})  =>
{
	const navigate = useNavigate();
	
	const { status, requisition_num, title, _id } = requisition;
	
	const created_at_datetime = DateTime.fromISO(requisition?.created_at)
	const days_diff = relativeDaysDiff(created_at_datetime);
	
	const is_assigned = ((requisition?.collaborator_ids?.length > 0
		|| requisition?.role_ids?.length > 0
		|| requisition?.accounts_to_charge_ids.length > 0
	)) ? true : false;
	
	
	return (
		<>
			<Stack
				direction='column'
				justifyContent='space-between'
				alignItems='flex-start'
				className='related_issue_card'
				onClick={() => navigate(`/requisitions/${_id}`)}
			>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='space-between'
					width='100%'
				>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='flex-start'
						width='100%'
						spacing={1}
					>
						<Typography
							variant='subtitle1'
							sx={{
								background: 'rgb(182, 122, 231)',
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							Requisition #{requisition_num}
						</Typography>
						
						<Typography
							variant='body1'
							sx={{
								marginLeft: '5px',
							}}
						>
							{title}
						</Typography>
					</Stack>
					
					
					<Grid
						container
						width='100%'
						sx={{
							marginTop: '5px',
						}}
					>
						<RequisitionCostCentersList requisition={requisition} />
					</Grid>
				</Stack>
				
				<Stack
					width='100%'
					direction='row'
					alignItems='center'
					justifyContent='space-between'
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='center'
						sx={{
							padding: '16px',
						}}
					>
						<CorIcon
							of={
								(status === RequisitionStatus.STARTED)
								? 'requisition_open'
								: (status === RequisitionStatus.ACCOUNTS_APPROVED)
								? 'requisition_waiting_approval'
								: (status === RequisitionStatus.IN_PROGRESS)
								? 'requisition_in_progress'
								: 'requisition_completed'
							}
							size='24px'
							color={
								(status === RequisitionStatus.STARTED)
									? defaultAppTheme.palette.secondary.main
									: (status === RequisitionStatus.IN_PROGRESS)
									? defaultAppTheme.palette.primary.main
									: ((status === RequisitionStatus.ACCOUNTS_APPROVED))
									? defaultAppTheme.palette.warning.main
									: defaultAppTheme.palette.success.dark
								}
						/>
						
						<Typography
							variant='body1'
							color={
								(status === RequisitionStatus.STARTED)
									? defaultAppTheme.palette.secondary.main
									: (status === RequisitionStatus.IN_PROGRESS)
									? defaultAppTheme.palette.primary.main
									: ((status === RequisitionStatus.ACCOUNTS_APPROVED))
									? defaultAppTheme.palette.warning.main
									: defaultAppTheme.palette.success.dark
								}
						>
							{ status?.toUpperCase() }
						</Typography>
					</Stack>
					
					
					<Stack
						direction='column'
						justifyContent='center'
						alignItems={'flex-start'}
						spacing={1}
					>
						<Typography
							sx={{
								background: ((days_diff > -7) && (requisition?.status !== RequisitionStatus.COMPLETED))
									? defaultAppTheme.palette.primary.main
									: (days_diff < -7 && days_diff >= -30 && requisition?.status !== RequisitionStatus.COMPLETED)
									? defaultAppTheme.palette.warning.main
									: (days_diff < -30 && requisition?.status !== RequisitionStatus.COMPLETED)
									? defaultAppTheme.palette.secondary.main
									: defaultAppTheme.palette.grey['500'],
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							{ relativeDatetimeText(created_at_datetime) }
						</Typography>
						
						<ConciseDateAndTime datetime={created_at_datetime} />
					</Stack>
				</Stack>
				
				{
					(is_assigned) ? (
						<Chip color='success' label='assigned'/>
					) : (
						<Chip label='unassigned' />
					)
				}
				
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
		</>
	)
};


export default RequisitionCard;
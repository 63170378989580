import { useMapData } from '../MapDataProvider';
import houston_rotterdam_trip from './dtn_route_houston-rotterdam_response.json';
import Route from './Route';
import { color_palette } from './route_utils';


const Routes = () =>
{
	const { trip_responses } = useMapData();
	
	console.log(trip_responses);
	
	
	let elems = trip_responses.map((trip, i) =>
		<Route
			trip={trip}
			color={color_palette[i]}
			key={i}
		/>
	)
	
	
	return (
		<>
			
			<Route
				trip={houston_rotterdam_trip}
				color='red'
			/>
			
			{elems}
			
		</>
	)
}


export default Routes;
import { useFormContext } from 'react-hook-form';
import NumberFormlet from '../../fields/text/NumberFormlet';
import DatetimeFormlet from '../../fields/datetimes/DatetimeFormlet';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { dotJoin } from 'utils';


// Choose between Running Hours, Pressure, etc.
export const Reading =
({
	name,
	label,
	value_help = '',
	datetime_help = '',
} : {
	name?: string,
	label?: string,
	value_help?: string,
	datetime_help?: string,
}) =>
{
	const form_context = useFormContext();
	
	let units = form_context.watch('units');
	
	
	return (
		<Stack>
			
			<Typography
				variant='h4'
				sx={{
					paddingBottom: 1,
				}}
			>
				{label}
			</Typography>
			
			<NumberFormlet
				name={dotJoin(name, 'value')}
				label='Value'
				help={value_help}
				units={units}
			/>
			
			<DatetimeFormlet
				name={dotJoin(name, 'recorded_at_datetime')}
				label='Date/Time'
				help={datetime_help}
			/>
			
		</Stack>
	);
}


export default Reading;
import { Stack } from '@mui/system';
import { useRequisition } from '../../RequisitionDataProvider';
import { Chip, Grid, Typography } from '@mui/material';
import EditCostCenters from '../../EditCostCenters';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';




const RequisitionCollabCostCenter = () =>
{
	const form = useFormContext();
	
	const { requisition, cost_centers, updateCostCenters } = useRequisition();
	
	const related_cost_centers = cost_centers.filter(x => requisition.cost_center_ids?.includes(x._id));
	
	useEffect(() =>
	{
		form.setValue(
			'cost_centers',
			related_cost_centers
		)
	}, [requisition.cost_center_ids])
	
	
	return (
		<Grid
			item
			xs={12}
			sm={3}
		>
			<Typography
				variant='h6'
				sx={{ padding: '10px' }}
			>
				Cost Centers
			</Typography>
			{
				related_cost_centers.map(cost_center => (
					<Stack
						key={cost_center._id}
						gap={2}
						sx={{ padding: '10px' }}
					>
						
						<Chip 
							label={cost_center.description}
							variant='outlined'
						/>
						
						{
							cost_center.centers.map((x, index) =>(
								<Chip key={index} label={x.name}/>
							))
						}
					</Stack>
				))
			}
			
			<EditCostCenters
				cost_centers={cost_centers}
				handleSubmit={updateCostCenters}
			/>
		</Grid>
	)
};


export default RequisitionCollabCostCenter;
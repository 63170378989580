import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

import { useDoc } from 'ember';
import { useAuthData } from 'stuff';
import { WorkOrderNew } from 'models';

import WorkOrderProvider from './WorkOrderProvider';
import WorkOrderPageHeader from './WorkOrderPageHeader';
import WorkOrderPageTabs from './WorkOrderPageTabs';


const WorkOrderPage = () =>
{
	const params = useParams();
	const { work_order_id } = params;
	
	const { claims } = useAuthData();
	
	
	//Get the work order data
	const work_orders_collection_path = `organizations/${claims?.organization_id}/work_orders`;
	const work_order_doc = useDoc(work_orders_collection_path, work_order_id);
	
	
	//console.log ("I reached here yay. And id is:", id);
	
	
	if(work_order_doc?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const work_order = work_order_doc?.data as WorkOrderNew;
	
	
	console.log({work_order});
	
	
	return (
		<WorkOrderProvider work_order={work_order}>
			<div className='issue_collab_page'>
				
				<WorkOrderPageHeader />
				
				<WorkOrderPageTabs />
				
			</div>
		</WorkOrderProvider>
	)
}


export default WorkOrderPage;



// TODO: Others?
export enum ActionType
{
	INVESTIGATION = 'Investigation',
	SAFETY_CHECK = 'Safety Check',
	INSPECTION_CHECK = 'Inspection Check',
	WORK_ORDER = 'Work Order',
	REQUISITION = 'Requisition',
	INVENTORY_CHECK = 'Inventory Check',
	RECORD_VALUE = 'Record Value',
	CHARGE_ACCOUNTS = 'Charge Accounts',
	GENERAL = 'General',
	MODIFY_DRAWINGS = 'Modify Drawings',
}


export enum ActionStatus
{
	OPEN = 'Open',
	RESOLVED = 'Resolved',
	CANCELLED = 'Cancelled',
	IN_PROGRESS = 'In Progress',
}



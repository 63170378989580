


// Ex: 30165900.10001 => 30,165,900.10001
// Removed original regex because Safari doesn't support lookbehind
export const numberWithCommas = (x) =>
{
	if(!x) return null;
	
	
	let num_string = x.toString();
	
	let decimal_index = num_string.indexOf('.');
	
	let integral = (decimal_index === -1)
			? num_string
			: num_string.slice(0, decimal_index)
	
	let integral_w_commas = integral.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	
	let fractional = (decimal_index === -1)
			? ''
			: num_string.slice(decimal_index);
	
	return integral_w_commas + fractional;
}


export const round = (n, sig_digits = 0) =>
{
	let x = n;
	
	if(sig_digits)
	{
		x = x * Math.pow(10, sig_digits);
	}
	
	x = Math.round(x);
	
	if(sig_digits)
	{
		x = x * Math.pow(10, -sig_digits);
	}
	
	return x.toString();
}



// Uses CSS-style param order rather than lodash's convention
export const clamp = (min, num, max) =>
{
	return (num <= min)
		? min 
		: (num >= max)
			? max
			: num
}



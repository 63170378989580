import { Box } from '@mui/material';
import { useState, useEffect } from 'react'
import CreateIssueDesktopView from './CreateIssueDesktopView';
import CreateIssueMobileView from './CreateIssueMobileView';
import { CreateIssueStepperProps } from './interfaces';


const CreateIssueResponsiveContent = ({onSubmit} : CreateIssueStepperProps) =>
{
	const [width, setPageWidth] = useState<number>(window.innerWidth);

	
	const handlePageWidth = () =>
	{
		setPageWidth(window.innerWidth);
	}
	
	
	useEffect(() => {
		window.addEventListener('resize', handlePageWidth);
		return () => {
			window.removeEventListener('resize', handlePageWidth);
		}
	}, []);
	
	
	return (
		<Box>
			{
				(width >= 768) ? (
					<CreateIssueDesktopView />
				) : (
					<CreateIssueMobileView onSubmit={onSubmit} />
				)
			}
		</Box>
	)
}


export default CreateIssueResponsiveContent;
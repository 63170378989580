import { ContentState, convertFromHTML, convertFromRaw, Editor, EditorState } from 'draft-js';
import { Message } from 'types/misc_interfaces';
import { timeSinceText } from 'utils';
import UserAvatar from 'components/avatars/UserAvatar';
import { Card } from '@mui/material';
import { User } from 'types';

interface ChatMessageProps
{
	message: Message,
	users?: User[],
}


const ChatMessage =
({
	message,
	users,
}
: ChatMessageProps) =>
{
	// TODO: Remove this hack that hides empty messages
	if(message.text?.length < 1)
	{
		return null;
	}
	
	
	let time = (!!message.date_sent)
		&&
		<time
			className='chat_content_info__date'
		>
			{timeSinceText(message.date_sent)}
		</time>
	
	
	
	
	const html2content = ( html : string ) =>
	{
		//const sampleMarkup = '<b>Bold text</b>, <i>Italic text</i><br/ ><br />Other text<br /><br /><a href="http://myurl.com">Some link</a>'
		
		// 1. Convert the HTML
		const content_html = convertFromHTML(html)
		
		const content_state = ContentState.createFromBlockArray(
			content_html.contentBlocks,
			content_html.entityMap
		)
		
		return content_state;
	}
	
	// 3. Stringify `state` object from a Draft.Model.Encoding.RawDraftContentState object
	//const content = convertToRaw(content_state)
	
	
	let parsed = (message.rich_text)
		&& JSON.parse(message?.rich_text);
	
	let content = (parsed)
		? convertFromRaw(parsed)
		: html2content(message.text.trim());
	
	let editor_state = EditorState.createWithContent(content);
	
	// console.log(content, editor_state, parsed, message)
	
	
	const sender_name = message.user?.display_name
		|| message?.sender_name
		|| '(Unknown)';
	
	const message_sender = users.filter(user => user._id === message.sender_id)[0];
	
	console.log({message_sender});
	
	
	return (
		<div
			className='chat_content_zone'
			title={'Sent: ' + message.date_sent?.replace('T', ' at ')}
		>
			
			<UserAvatar
				user={message_sender}
				title={sender_name}
			/>
			
			<div
				className='chat_content'
			>
				
				<div
					className='chat_content_info'
				>
					<h5>
						{sender_name}
					</h5>
					
					{time}
					
				</div>
				
				<Card
					className='chat_content_message'
				>
					<Editor
						editorState={editor_state}
						readOnly={true}
						onChange={(data) => console.log(data)}
					/>
					
					{/* <p>{message.text}</p> */}
				</Card>
				
			</div>
			
		</div>
	);
}


export default ChatMessage;
import React, { ReactNode, useContext } from 'react';

import { Action, CurrentComponent, Issue, StorageFile, WorkOrderNew } from 'models';
import { Event, Message, Part, Tag, User } from 'types';
import { useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { useSession } from 'app/providers/SessionProvider';




export interface IssueData
{
	issue: Issue;
	components: CurrentComponent[];
	tags: Tag[];
	related_issues: Issue[];
	files: StorageFile[];
	actions: Action[];
	other_users: User[];
	parts: Part[];
	messages: Message[];
	events: Event[];
	work_orders: WorkOrderNew[];
};


const IssueContext = React.createContext<IssueData>({
	issue: null,
	components: [],
	tags: [],
	related_issues: [],
	files: [],
	actions: [],
	other_users: [],
	parts: [],
	messages: [],
	events: [],
	work_orders: [],
});


export function useIssue()
{
	return useContext(IssueContext);
}



const IssueProvider =
({
	issue,
	children,
} : {
	issue: Issue,
	children: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const { users } = useSession();
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	
	// Get Equipment (component) data
	const components_collection = useCollectionConditionally(
		(issue?.component_ids?.length > 0),
		`${org_path}/components`,
		where('_id', 'in', issue?.component_ids)
	);
	
	// Get Issues that involve any components specified in this Issue
	const related_issues_collection = useCollectionConditionally(
		(issue?.component_ids?.length > 0),
		`${org_path}/issues`,
		where('component_ids', 'array-contains-any', issue?.component_ids)
	);
	
	
	// Get Tags data
	const tags_collection = useCollectionConditionally(
		(issue?.tag_ids.length > 0),
		`${org_path}/tags`,
		where('_id', 'in', issue?.tag_ids)
	);
	
	// Get Files data
	const files_collection = useCollection(
		`${org_path}/files`,
		where('issue_id', '==', issue?._id)
	);
	
	const assets_files_collection = useCollectionConditionally(
		(issue?.component_ids?.length > 0),
		`${org_path}/files`,
		where('component_id', 'in', issue?.component_ids)
	);
	
	
	// Get Actions data
	const actions_collection = useCollection(
		`${org_path}/actions`,
		where('issue_id', '==', issue?._id)
	);
	
	
	// Get Linked Work Orders data
	const work_orders_collection = useCollectionConditionally(
		(issue?.work_order_ids?.length > 0),
		`${org_path}/work_orders`,
		where('work_order_id', 'in', issue?.work_order_ids)
	);
	
	
	// Get Parts data from parts needed
	const parts_needed_ids = issue.parts_needed?.map(x => x._id);
	const parts_collection = useCollectionConditionally(
		(issue?.parts_needed?.length > 0),
		`${org_path}/parts`,
		where('_id', 'in', parts_needed_ids)
	);
	
	// Get all Messages
	const messages_collection = useCollection(
		`${org_path}/messages`,
		where('issue_id', '==',  issue?._id)
	);
	
	// Get all Events
	const events_collection = useCollection(
		`${org_path}/events`,
		where('issue_id', '==',  issue?._id)
	);
	
	
	
	
	
	
	// Load data from collections
	const components = components_collection?.data as CurrentComponent[] || [];
	const related_issues = related_issues_collection?.data?.filter((x: Issue) => x._id !== issue._id) as Issue[] || [];
	const tags = tags_collection?.data as Tag[] || [];
	const actions = actions_collection?.data as Action[] || [];
	const other_users = users?.filter(x => x._id !== claims?.user_id) as User[] || [];
	const parts = parts_collection?.data as Part[] || [];
	const messages = messages_collection?.data as Message[] || [];
	const events = events_collection?.data as Event[] || [];
	const work_orders = work_orders_collection?.data as WorkOrderNew[] || [];
	
	
	// Merging issue attachments with Asset files
	const general_attachments = files_collection?.data as StorageFile[] || [];
	const asset_files = assets_files_collection?.data as StorageFile[] || [];
	const files = general_attachments.concat(asset_files);
	
	
	
	
	const issue_data: IssueData =
	{
		issue: issue,
		components: components,
		tags: tags,
		related_issues: related_issues,
		files: files,
		actions: actions,
		other_users: other_users,
		parts: parts,
		messages: messages,
		events: events,
		work_orders: work_orders,
	}
	
	
	return (
		<IssueContext.Provider value={issue_data}>
			{children}
		</IssueContext.Provider>
	)
};


export default IssueProvider;
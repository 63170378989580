import { useCollection } from 'ember';
import { CostCenter, CostCode, CostRecord } from 'models'
import { ReactNode, createContext, useContext, useState } from 'react'
import { useAuthData } from 'stuff';




export interface CostAnalyticsState
{
	cost_records_state_data: CostRecord[],
	processCostCenterFilter?: Function,
	processCostCodeFilter?: Function,
	resetData?: Function,
};


const CostAnalyticsDataContext = createContext<CostAnalyticsState>(null);


export const useCostAnalyticsState = () =>
{
	return useContext(CostAnalyticsDataContext)
};



const CostAnalyticsStateProvider =
({
	cost_records,
	children,
} : {
	cost_records?: CostRecord[],
	children: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	
	const [ cost_records_state_data, setCostRecordsStateData ] = useState<CostRecord[]>(cost_records);
	
	
	const processCostCenterFilter = (cost_center_id: string) =>
	{
		const filtered_cost_records = cost_records.filter(x => x.cost_center_ids.includes(cost_center_id))
		setCostRecordsStateData(filtered_cost_records);
	}
	
	
	const processCostCodeFilter = (cost_code_id: string) =>
	{
		setCostRecordsStateData(cost_records)
		const filtered_cost_records = cost_records.filter(x => x.cost_code_ids.includes(cost_code_id))
		setCostRecordsStateData(filtered_cost_records);
	}
	
	
	const resetData = () =>
	{
		setCostRecordsStateData(cost_records)
	}
	
	
	
	
	const cost_analytics_state =
	{
		cost_records_state_data: cost_records_state_data || [],
		processCostCenterFilter: processCostCenterFilter,
		processCostCodeFilter: processCostCodeFilter,
		resetData: resetData,
	}
	
	
	
	return (
		<CostAnalyticsDataContext.Provider
			value={cost_analytics_state}
		>
			{children}
		</CostAnalyticsDataContext.Provider>
	)
}

export default CostAnalyticsStateProvider
import { Avatar, Stack, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import TotalCount from './TotalCount';
import ProgressBar from './ProgressBar';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ResponsiveWorkOrdersList from 'pages/work_orders/work_orders/ResponsiveWorkOrdersList';
import { useCorsAnalyticsLoader } from './CorsAnalyticsLoader';
import { RequisitionStatus, WorkOrderStatus } from 'models';
import { relativeDaysDiff } from 'utils';
import { DateTime } from 'luxon';
import ResponsiveRequisitionsList from 'app/dashboard/pages/requisitionsAndParts/requisitionsList/ResponsiveRequisitionsList';



const CorsRequisitionsSummary = () =>
{
	const { requisitions } = useCorsAnalyticsLoader();
	
	const due_now = requisitions?.filter(x => x?.status === RequisitionStatus.STARTED);
	const accounts_approved = requisitions?.filter(x => x?.status === RequisitionStatus.ACCOUNTS_APPROVED);
	const in_progress = requisitions?.filter(x => x?.status === RequisitionStatus.IN_PROGRESS);
	
	
	const due_immediately = [...in_progress, ...due_now, ...accounts_approved]?.filter(x => relativeDaysDiff(DateTime.fromISO(x?.created_at)) <= -30)
	
	
	
	return (
		<Stack
			className='container'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='center'
			width='100%'
			gap={1}
			sx={{
				minHeight: '350px !important'
			}}
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-start'
				gap={1}
			>
				<Avatar
					sx={{
						bgcolor: 'rgb(182, 122, 231)'
					}}
				>
					<CorIcon
						size='24px'
						of='requisition_list'
						color='rgb(78, 15, 121)'
					/>
				</Avatar>
				
				<Typography
					variant='h3'
				>
					Requisitions
				</Typography>
			</Stack>
			
			<Stack
				flexDirection='row'
				alignItems='flex-start'
				justifyContent='flex-start'
				gap={2}
			>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{due_now?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Started
					</Typography>
				</Stack>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{in_progress?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						In-progress
					</Typography>
				</Stack>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{accounts_approved.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Accounts approved
					</Typography>
				</Stack>
				
				<Stack
					sx={{
						marginLeft: '40px'
					}}
				>
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Active
					</Typography>
					
					<TotalCount count={due_now.length + in_progress.length + accounts_approved.length} />
				</Stack>
				
			</Stack>
			
			<ProgressBar
				value={Math.round((due_immediately.length/(due_now.length + in_progress.length + accounts_approved.length))*100)}
				due_count={due_immediately.length}
				total_count={due_now.length + in_progress.length + accounts_approved.length}
			/>
			
			
			<Stack>
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.error.light }
					/>
					<ButtonToOpenDialog
						button_content='Started'
						button_variant='text'
						dialog_title='Requisitions Started'
						dialog_content={<ResponsiveRequisitionsList requisitions={due_now} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
				
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.info.main }
					/>
					<ButtonToOpenDialog
						button_content='In-Progress'
						button_variant='text'
						dialog_title='Requisitions In Progress'
						dialog_content={<ResponsiveRequisitionsList requisitions={in_progress} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={defaultAppTheme.palette.warning.main}
					/>
					<ButtonToOpenDialog
						button_content='Accounts Approved'
						button_variant='text'
						dialog_title='Accunts approved Requisitions'
						dialog_content={<ResponsiveRequisitionsList requisitions={accounts_approved} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
			</Stack>
			
		</Stack>
	)
}

export default CorsRequisitionsSummary;
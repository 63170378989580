import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useForm, useWatch } from 'react-hook-form';
import { useCollection, useDoc, useWrite } from 'ember';
import { useAuthData } from 'stuff';

import { where } from 'firebase/firestore';

import { WorkOrderResponse, WorkOrderResponseForm, } from 'types/forms';
import { useBreadcrumbData } from 'app/providers/BreadcrumbDataProvider';
import { WorkOrderTemplate } from 'models';



// TODO: Refactor/remove
export const useWorkOrderTemplate = () =>
{
	const { id } = useParams<'id'>();
	
	const { claims, user } = useAuthData();
	
	const data = useDoc(`organizations/${claims?.organization_id}/work_order_templates`, id);
	
	const work_order_template = data.data as WorkOrderTemplate;
	
	const form = useForm<WorkOrderResponseForm>();
	
	const form_sections = useWatch({
		control: form.control,
		name: 'form_sections'
	});
	
	const write = useWrite();
	
	const work_order_responses_collection = useCollection(
		`organizations/${claims?.organization_id}/work_orders`,
		where('work_order_template_id', '==', id)
	);
	
	const [ is_submitting, setIsSubmitting ] = useState(false);
	
	const navigate = useNavigate();
	
	const breadcrumbs_data = useBreadcrumbData();
	
	
	const work_order_responses = work_order_responses_collection.data as Array<WorkOrderResponse> | undefined;
	
	
	console.log({
		form_sections,
		work_order_template
	});
	
	
	function handleSubmit()
	{
		if (is_submitting) return;
		
		setIsSubmitting(true);
		
		write('work_order_templates', {
			work_order_template_id: work_order_template._id,
			filled_up_by_id: user?.uid,
			fill_up_by_date: new Date().toLocaleDateString(),
			form_sections: form.getValues().form_sections,
		},
		() => {
			setIsSubmitting(false);
			navigate('/work-order-templates');
		});
	};
	
	
	
	// TODO: It feels like there's a better way to handle responses
	useEffect(() =>
	{
		if (work_order_template)
		{
			
			breadcrumbs_data.setDisplayText(work_order_template._id, work_order_template.title)
			
			
			form.setValue('work_order_id', work_order_template._id);
			
			form.setValue('form_sections', work_order_template.sections.map(section => ({
				label: section.label,
				fields: section.fields,
			})));
			
			form.setValue('title', work_order_template.title);
			
			
			if (!work_order_responses)
				return;
			
			
			const work_order_response = work_order_responses.filter(response =>
				response.work_order_id === work_order_template._id
			).at(-1);
			
			
			if (!work_order_response)
				return;
			
			
			console.log({ workOrderResponse: work_order_response });
			
			form.setValue('form_sections', work_order_response.form_sections);
			
			
		}
		
		return () => {};
	},
		[ breadcrumbs_data, work_order_template, work_order_responses, id, form ]
	);
	
	
	return {
		workOrderTemplate: work_order_template,
		form,
		handleSubmit,
		isSubmitting: is_submitting,
	};
};


export default useWorkOrderTemplate;
import Badge from 'components/badges/Badge';


const cn :string = 'issueTitle';


export interface IssueTitleProps
{
	title: string;
	_badges: string[];
	_reportDetails: string[];
	_issue_tracking: string;
	_tracking_number: string;
};


const IssueTitle =
({
	title,
	_badges,
	_reportDetails,
	_issue_tracking,
	_tracking_number
}
: IssueTitleProps) =>
{
	const render_bage = ( badge_: string) =>
	{
		const badge :string = badge_.toLowerCase();
		
		if ( badge === 'active' )
		{
			return (
				<Badge
					title={ badge_ }
					color={ 'jade' }
				/>
			);
		}
		else if ( badge === 'need maintenance' )
		{
			return (
				<Badge
					title={ badge_ }
					color={ 'mustard' }
				/>
			);
		}
	};
	
	
	return (
		<div
			className={ cn }
		>
			<div
				className={ cn + '__tracking' }
			>
				
				<div
					className={ cn + '__tracking--issue' }
				>
					Issue Tracking: #{ _issue_tracking }
				</div>
				
				<Badge
					title={ _tracking_number }
					color={ 'smoke' }
				/>
				
			</div>
			
			<div
				className={ cn + '__headline' }
			>
				{ title }
			</div>
			
			<div
				className={ cn + '__badges' }
			>
				{
					_badges.map(( badge, key ) => (
						<div
							key={ key }
						>
							{render_bage( badge )}
						</div>
					))
				}
			
			</div>
			
			<div
				className={ cn + '__details' }
			>
				Report Details:
				{
					_reportDetails.map( ( value, key ) =>
						<p
							key={ key }
						>
							&bull;{' '}
							{ value }
						</p>
					)
				}
			</div>
		</div>
	);
};


export default IssueTitle;
import Alert, { AlertColor } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const InfoHighlight =
({
	severity,
	message_context,
	message_main,
	title,
	sx,
} : {
	severity: AlertColor,
	message_main: string,
	message_context?: string,
	title?: string,
	sx?: any,
}) =>
{
	const alert_type_title = title || severity.charAt(0).toUpperCase() + severity.slice(1);
	
	
	return (
		<Alert
			severity={severity}
			sx={{
				width: '100%',
				color: '#000',
				boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px;',
				...sx
			}}
		>
			{
				message_context && <AlertTitle>{message_context}</AlertTitle>
			}
			<strong>{message_main}</strong>
		</Alert>
	)
};


export default InfoHighlight;
import { React, useState, useEffect } from 'stuff';

import SettingsTabletDesktop from './settings_tablet_desktop/SettingsTabletDesktop';
import SettingsMobile from './settings_mobile/SettingsMobile';


/*
	Responsive layout of settings page
	Screen layer than 768px width jumps to SettingsTabletDesktop
	Screen layer smaller than 768px width jumps to SettingsMobile
*/
const SettingsResponsivePage = () =>
{
	const [ screenWidth, setScreenWidth ] = useState<number>(window.innerWidth);
	
	
	const handlePageWidth = () =>
	{
		setScreenWidth(window.innerWidth);
	}
	
	
	useEffect(() => {
		window.addEventListener('resize', handlePageWidth);
		return () => {
			window.removeEventListener('resize', handlePageWidth);
		}
	}, []);
	
	
	return (
		<>
			{
				(screenWidth >= 768) ? (
					<SettingsTabletDesktop />
				) : (
					<SettingsMobile />
				)
			}
		</>
	)
}


export default SettingsResponsivePage;
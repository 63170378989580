import { useEffect } from 'react';

import NotificationAPI from 'notificationapi-js-client-sdk';
import { PopupPosition } from 'notificationapi-js-client-sdk/lib/interfaces';
// import Icon from 'components/icons/Icon';
import { useAuthData } from 'stuff';


const Notifications = () =>
{
	// Here's how to check custom claims using this hook:
	// const {
	// 	status,
	// 	data: signInCheckResult
	// } = useSigninCheck({requiredClaims: {admin: true}});
	
	// DOCS: https://github.com/FirebaseExtended/reactfire/blob/main/docs/use.md
	//const { status, data: signInCheckResult } = useSigninCheck();
	
	const claims = useAuthData();
	
	
	useEffect(() =>
	{
		const notificationapi = new NotificationAPI({
			clientId: '7t3r9jom7s5kg07h62dsneljkt',
			userId: claims?.uid,
		});
		
		notificationapi.showInApp({
			root: 'Hello',
			popupPosition: PopupPosition.BottomLeft
		});

	});
	
	
	// TODO: Just show gray bell icon?
	// Div should take up same size regardless to prevent jitter
	// if(status === 'loading')
	// {
	// 	return <span>...</span>;
	// }
	
	
	// if(status !== 'loading')
	// {
	// 	// console.log({signInCheckResult})
	// 	// console.log(signInCheckResult?.user?.uid)
	// }
	
	
	return <div className='notification-container' id="Hello"></div>;
}


export default Notifications;
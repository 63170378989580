import { DateTime } from 'luxon';
import cuid from 'cuid';

import { Event } from 'types';
import { StorageFile } from 'models';



export const writeCertificationRecordOnCreation = (
	values,
	write,
	claims,
	uploadFile,
	navigate,
	certification,
	updateDocProperty
) =>
{
	console.log({values});
	
	const {
		_id,
		status,
		certification_id,
		obtained_date,
		expiry_date,
		note,
		files,
		never_expires,
	} = values;
	
	
	let date = DateTime.utc().toISO();
	
	
	uploadFilesCertificationRecord(
		files,
		claims,
		uploadFile,
		_id,
		certification_id,
		write
	)
	
	
	
	// Handle Requisition Creation
	const new_certification_record_data =
	{
		_id: _id,
		status: status,
		never_expires: never_expires,
		
		certification_id: certification_id,
		obtained_date: obtained_date,
		expiry_date: expiry_date,
		note: note,
	};
	
	console.log({new_certification_record_data});
	
	write(`organizations/${claims.organization_id}/certification_records`, new_certification_record_data);
	
	updateDocProperty(
		`organizations/${claims.organization_id}/certifications/${certification._id}`,
		'updated_record_status',
		status
	)
	updateDocProperty(
		`organizations/${claims.organization_id}/certifications/${certification._id}`,
		'updated_record_obtained_date',
		obtained_date
	)
	updateDocProperty(
		`organizations/${claims.organization_id}/certifications/${certification._id}`,
		'updated_record_expiry_date',
		expiry_date
	)
	// we navigate back to right tab where user started
	//navigate(`/certifications?tab=${certification.certification_for.toLowerCase()}s `);
};



export const uploadFilesCertificationRecord =
(
	file_list,
	claims,
	uploadFile,
	_id,
	certification_id,
	write,
) =>
{
	let datetime = DateTime.utc().toISO();
	
	// Handle File Attachments
	let attached_files = [];
	
	if(file_list != null)
	{
		const files: File[] = Array.from( file_list );
		
		if(files.length)
		{
			files?.forEach(file_to_upload =>
			{
				let file_id = cuid();
				
				
				let file : StorageFile =
				{
					_id: file_id,
					name: file_to_upload.name,
					path: `organizations/${claims?.organization_id}/files/${file_id}`,
					size: file_to_upload.size,
					type: file_to_upload.type,
					upload_date: datetime,
					certification_record_id: _id,
					certification_id: certification_id,
					file_category: 'CertificationRecords',
					last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
				}
				
				
				uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				
				attached_files.push(file);
			})
			
			
			
			let file_upload_event_text = 'Uploaded ' + files.length
				+ ' file' + ((files.length > 1) ? 's' : '');
			
			let file_upload_event : Event =
			{
				_id: cuid(),
				name: file_upload_event_text,
				
				type: 'file',
				subtype: 'upload',
				
				datetime: datetime,
				summary:  file_upload_event_text,
				certification_record_id: _id,
				certification_id: certification_id,
				file_ids: attached_files?.map(x => x._id) || [],
				user_id: claims?.user_id,
			};
			
			write(`organizations/${claims.organization_id}/events`, file_upload_event);
		}
		
		console.log(files);
	}
};





import ComponentRecordsLoader from 'app/providers/ComponentRecordsLoader';
import ComponentRecordsPageContent from './ComponentRecordsPageContent';


const ComponentRecordsPage = () =>
{
	return (
		<ComponentRecordsLoader>
			<ComponentRecordsPageContent />
		</ComponentRecordsLoader>
	)
};


export default ComponentRecordsPage;
import { ReactNode } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material';
import { responsiveAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


//DialogContentText
//aria-describedby='alert-dialog-description'


interface BasicDialogI
{
	title_text: string,
	submit_text?: string,
	dismiss_text?: string,
	
	// Used as the form prop for input, linking submit button to a form outside its hierarchy
	form_id?: string,
	
	open: boolean,
	handleClose: Function,
	handleSubmit?: Function,
	
	className?: string,
	dialogClassName?: string,
	
	sx?: Object,
	
	children?: ReactNode,
}


// TODO: Consider how to handle validation before submission, and disabling submit button
const BasicDialog =
({
	className,
	
	title_text,
	submit_text,
	dismiss_text,
	
	form_id,
	
	open,
	handleClose,
	handleSubmit,
	dialogClassName,
	
	sx,
	
	children,
	
	...rest
}
: BasicDialogI
) =>
{
	const fullScreen = useMediaQuery(responsiveAppTheme.breakpoints.down('sm'));
	
	return (
		<Dialog
			fullScreen={fullScreen}
			fullWidth={true}
			maxWidth={'lg'}
			className={className || 'dialog'}
			PaperProps={{ classes: {root: dialogClassName}}}
			
			aria-labelledby='dialog_title'
			
			open={open}
			onClose={(e) => handleClose()}
			
			{...rest}
			sx={{
				zIndex: '200000', // need this to cover the bottom app bar in mobile view (full-screen modal) -- don't change this for now please!
				...sx,
			}}
		>
			
			<DialogTitle id='dialog_title'>
				<Typography
					// variant='h3'
					sx={{
						fontSize: '24px',
					}}
				>
					{title_text}
				</Typography>
			</DialogTitle>
			
			
			<DialogContent>
				{children}
			</DialogContent>
			
			
			<DialogActions>
				<Button onClick={(e) => handleClose()}>
					{dismiss_text || 'Cancel'}
				</Button>
				
				{
					submit_text &&
						<Button
							variant='contained'
							type='submit'
							form={form_id}
							onClick={(e) => handleSubmit()}
						>
							{submit_text}
						</Button>
				}
			</DialogActions>
			
		</Dialog>
	);
}


export default BasicDialog;
import { useEffect, useRef } from 'react';
import { useAuthData } from 'stuff';
import Main   from './Main';
import NavBar from './nav/NavBar';

import TopAppBar from './nav/TopAppBar';


const Dashboard = () =>
{
	const { is_loading } = useAuthData();
	// const scrollInto = useRef(null)
	// useEffect(() => {
	// 	scrollInto.current.scrollIntoView()
	// })
	
	
	// window.addEventListener("load",function() {
	// 	setTimeout(function(){
	// 		// This hides the address bar:
	// 		window.scrollTo(0, 1);
	// 	}, 0);
	// });
	
	
	if(is_loading) return null;
	
	
	
	return (
		<div 
			className='dashboard_ceto'
		>
			<NavBar />
			
			<Main />
			
			<TopAppBar />
			
			{/* <div ref={scrollInto}></div> */}
		</div>
	)
}


export default Dashboard;
import { useAuthData } from 'app/auth/AuthDataProvider';
import { useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { WorkOrderTemplate } from 'models';
import { createContext, useContext } from 'react';
import { useComponent } from './ComponentLoader';


export interface WorkOrderTemplatesData
{
	work_order_templates: WorkOrderTemplate[],
}


const WorkOrderTemplatesContext = createContext<WorkOrderTemplatesData>({
	work_order_templates: [],
});


export const useWorkOrderTemplates = () =>
{
	return useContext(WorkOrderTemplatesContext);
};


// Loads all the templates associated with the selected component
export const WorkOrderTemplatesLoader = ({ children }) =>
{
	const { component } = useComponent();
	
	const { claims } = useAuthData();
	
	
	let org_path = `organizations/${claims?.organization_id}`;
	
	const work_order_templates_collection = useCollectionConditionally(
		(component?._id !== undefined),
		`${org_path}/work_order_templates`,
		where('component_ids', 'array-contains', component?._id)
	);
	
	const work_order_templates = work_order_templates_collection?.data as WorkOrderTemplate[];
	
	
	return (
		<WorkOrderTemplatesContext.Provider value={{
			work_order_templates,
		}}>
			{children}
		</WorkOrderTemplatesContext.Provider>
	);
}


export default WorkOrderTemplatesLoader;
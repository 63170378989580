


// Create an index array 
// Ex: ['apple', 'orange'] => [0, 1]
export function indexArray(array)
{
	let results = [];
	for(let i = 0; i < array.length; i++)
	{
		results.push(i);
	}
	return results;
}



// Provide an array and a function that will be applied to each item
// 
// Function ordered parameters: value, index, source array, results array
// 
// Ex: [1, 4],   (v, i) => 'Item ' + i + ' x2 = ' + 2 * v
//     =>
//    ['Item 0 x2 = 2', 'Item 1 x2 = 8']
export function doForArray(array, fn)
{
	let results = [];
	
	for(let i in array)
	{
		results[i] = fn(array[i], i, array, results);
	}
	
	return results;
}



// [1, 2, 2, 2] => {1: 1, 2: 3}
export const arrayValueCounter = (array) =>
{
	// Map each unique value to the number of times it appears in the array
	const map = array.reduce(
		(map, key) =>
			map.set(key, (map.get(key) || 0) + 1),
			new Map()
	);
	
	// Spread our map into a 2D array (ex: [["🐩",4],["🐕",1],["🐶",2]])
	const table = [...map];
	
	// Use Object.fromEntries to generate an object from our entry table
	return Object.fromEntries(table);
}



// Returns a reordered array. Helps to update pools after a drag.
export const reorder = (list, start_index, end_index) =>
{
	const result = Array.from(list);
	
	const [removed] = result.splice(start_index, 1);
	
	result.splice(end_index, 0, removed);
	
	return result;
};



// Takes an array of objects and the property by which they should be grouped.
// Produces an object of arrays keyed by the specified property values.
// 
// Provide multiple keys if your data is nested:   groupBy(teams, 'team_leader', 'last_name')
// 
// Ex: [{id: 1, group: 'A'}, {id: 2, group: 'B'}, {id: 3, group: 'A'}],   'group'
//     =>
//     {A: [{id: 1, group: 'A'}, {id: 3, group: 'A'}], B: [{id: 2, group: 'B'}]}
export const groupByProp = (data, ...keys) =>
{
	// Ex: {values: {color: 'red'}}, ['values', 'color'] => 'red'
	const getGroupFromItem = (item, keys) =>
	{
		return (keys.length > 1)
			? getGroupFromItem(item[keys[0]], keys.slice(1))
			: item[keys[0]]
	}
	
	return data.reduce((results, item) =>
		{
			// Get the first instance of the key by which we're grouping
			var group = getGroupFromItem(item, keys);
			
			// Ensure that there's an array to hold our results for this group
			results[group] = results[group] || [];
			
			// Add this item to the appropriate group within results
			results[group].push(item);
			
			// Return the updated results object to be passed into next reduce call
			return results; 
		},
		
		// Initial value of the results object
		{}
	);
};



// Takes an array and a max chunk size
// Subdivides the array into as many subarrays as required
// Returns resulting array of chunk subarrays
// Ex: [1, 2, 3, 4, 5], 2 => [[1, 2], [3, 4], [5]]
export const chunkArray = (array, max_n) =>
{
	if(!Array.isArray(array)) return [];
	
	const chunker = (results, item, i) =>
	{
		// The index of this subarray within the results array
		const chunk_i = Math.floor(i / max_n)
		
		// Start a new chunk subarray if needed
		if(!results[chunk_i])
		{
			results[chunk_i] = [];
		}
		
		// Add item to chunk subarray
		results[chunk_i].push(item);
		
		return results;
	}
	
	return array.reduce(chunker, []);
}



// Returns the number of unique values in the provided iterable
// (ex: Array, String, TypedArray, object)
export function countUnique(iterable)
{
	return new Set(iterable).size;
}


// Returns a set containing unique values from the provided iterable
// (ex: Array, String, TypedArray, object)
export function onlyUnique(iterable)
{
	return new Set(iterable);
}


// Returns an array containing unique values from the provided iterable
// (ex: Array, String, TypedArray, object)
export function onlyUniqueArray(iterable)
{
	return Array.from(new Set(iterable));
}


// Returns an array containing unique values from the provided iterable
// Provide a custom function to customize how uniqueness is determined
// Ex: using default JSON.stringify:
//     [[1,2,3], [4,5,6], [1,2,3]] => [[1,2,3], [4,5,6]])
export function uniqueBy(iterable, fn = JSON.stringify)
{
	let seen = new Set();
	
	return iterable.filter(item => {
		let k = fn(item);
		return seen.has(k) ? false : seen.add(k);
	});
}





// Returns the substring before the last instance of a character
export const substringAfterLast = (string, char = '/') =>
{
	if(!string || (string.lastIndexOf(char) === -1)) return string;
	
	return string.substring(string.lastIndexOf(char) + 1);
}



// Returns the substring before the first instance of a character
export const substringBeforeFirst = (string, char = '/') =>
{
	if(!string || (string.indexOf(char) === -1)) return string;
	
	return string.substring(0, string.indexOf(char));
}



// TODO: Will need to be changed to handle other casing schemes
// Ex: 'snake_case' => 'PascalCase'
export const snake2pascal = (text) =>
{
	const upperFirst = (string) =>
	{
		return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
	}
	
	return text.split('_').map(upperFirst).join('');
}




// TODO: Will need to be changed to handle other casing schemes
// Ex: 'PascalCase' => 'pascal_case'
export const toSnakeCase = (text) =>
{
	// Any capital letter will be replaced with a space and a lowercase letter
	let char_array = Array.from(text).map(char => {
		if(char === char.toUpperCase())
			return ' ' + char.toLowerCase();
		else return char;
	})
	
	// Hack to fix FooXL => foo_xl instead of foo_x_l
	char_array.forEach((x, i, arr) =>
		{
			if(i > 0)
				if(arr[i - 1] === ' x')
					if(arr[i] === ' l')
					{
						arr[i - 1] = '';
						arr[i] = ' xl';
					}
		}
	)
	
	// Join the array, remove surrounding space, and replace spaces with underscores
	return char_array
		.join('')
		.trim()
		.replaceAll(' ', '_');
}



// Ex: 'a tale of TWO CITIES' => 'A Tale of Two Cities'
export const toTitleCase = function(sentence)
{
	if(!sentence)
	{
		console.warn('toTitleCase cannot titlize falsy value:', sentence);
		
		return null;
	}
	if(typeof sentence !== 'string')
	{
		console.log(sentence)
		console.log(typeof sentence)
		
		return null;
	}
	
	
	// Certain minor words should be left lowercase unless they are the first or
	// last words in the string
	const lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 
	'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
	
	// Certain words such as initialisms or acronyms should be left uppercase
	const uppers = [
		'Id', 'Tv', 'Url', 'Sd', 'Xl', 'Sku', 'Upc', 'Pdf', 'Pcb', 'Rv',
		'Gmdss', 'A/c', 'Dc', 'Ppm', 'Ecr', 'Ac', 'F.o', 'Ge', 'T/c', 'Cpp', 'Cfw', 'Fw',
		'Krba', 'V/v', 'Vrlh', 'Hvac', 'RPM',
		'24v', '250a', '350a', '240v', '6v', 'Sh2bj', '440v', '220v',
		'Ll', 'Cv', 'Mbl', 'Dk', 'Sw', 'Cr', 'Lp', 'Cw', 'Sa', 'Ond', 'Omd',
		'Fvc', 'Ps', 'Tk', 'Jw', 'Ex', 'Dshg', 'Dsg', 'Dshf', 'Fg', 'Cfm', 'Mx4',
		'Lo', 'Hfo', 'Er', 'Fwg', 'Ows', 'Fo', 'G.s.', 'Tks', 'Vhf', 'Mf',
		'GPS', 'C/w', 'Sic', 'Db', 'Wb', 'S/g', 'Ais', 'D-Gps', 'Dgps', 
		
		// TODO: Not appropriate for general use
		'Me', 'Do', 
	];
	
	
	// Replace underscores with spaces
	sentence = sentence.replaceAll('_', ' ');
	
	
	let str = sentence.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
	
	
	
	// Reusable indices for our loops
	let i, j;
	
	
	for(i = 0, j = lowers.length; i < j; i++)
		str = str.replace(
			new RegExp('\\s' + lowers[i] + '\\s', 'g'), 
			function(txt) {
				return txt.toLowerCase();
			}
		);
	
	for(i = 0, j = uppers.length; i < j; i++)
		str = str.replace(
			new RegExp('\\b' + uppers[i] + '\\b', 'g'), 
			uppers[i].toUpperCase()
		);
	
	
	// Special handling for IDs
	str = str.replaceAll('Ids', 'IDs');
	
	// Hack to fix millimeters...
	str = str.replaceAll('Mm', 'mm');
	
	str = str.replaceAll('W/controller', 'w/ Controller');
	str = str.replaceAll('W/el.motor', 'w/ El. Motor');
	str = str.replaceAll('El.motor', 'El. Motor');
	str = str.replaceAll('W motor', 'w/ Motor');
	str = str.replaceAll('W Motor', 'w/ Motor');
	str = str.replaceAll('Co²', 'CO²');
	str = str.replaceAll('Co2', 'CO²');
	str = str.replaceAll('El.cabinet', 'El Cabinet');
	str = str.replaceAll('Fuel/oil', 'Fuel/Oil');
	
	
	return str;
}



export const capitalizeFirst = (string) =>
{
	if(typeof string !== 'string') return '';
	
	return string.charAt(0).toUpperCase() + string.slice(1);
}



export const isString = (possible_string) =>
{
	return (typeof possible_string === 'string');
}



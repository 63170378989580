import LongTextFormlet from 'app/gizmos/fields/text/LongTextFormlet';


interface DescriptionFormletProps
{
	name?: string,
	label?: string,
	help?: string,
}


export const DescriptionFormlet =
({
	name = 'description',
	label = 'Description',
	help,
}
: DescriptionFormletProps) =>
{
	return (
		<LongTextFormlet
			name={name}
			label={label}
			help={help}
		/>
	);
};


export default DescriptionFormlet;
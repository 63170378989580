import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Recurrence } from 'models';
import { projectedOccurrences } from 'models/recurrence/recurrence_helpers3';
import ConciseDateAndTime from '../fields/datetimes/ConciseDateAndTime';
import { ensureLocalDateTime } from 'models/recurrence/recurrence_helpers2';


// Disabled for now...
const RecurrenceOccurrences = () =>
{
	const form_context = useFormContext();
	
	const recurrence = form_context.watch('recurrence') as Recurrence;
	
	
	let occurrences = projectedOccurrences(
		recurrence,
		32,
		ensureLocalDateTime(recurrence.effective_datetime)
	);
	
	
	console.log('%cOccurrences', 'color: cyan; font-size:20px;', {
		occurrences,
	})
	
	
	return null;
	
	
	// return (
	// 	<Stack
	// 		sx={{
	// 			bgcolor: '#f002',
	// 			padding: '8px 16px',
	// 			borderRadius: 1,
	// 		}}
	// 	>
	// 		{
	// 			occurrences.map(x =>
	// 				<Stack
	// 					key={x.toISO()}
	// 				>
	// 					<ConciseDateAndTime
	// 						datetime={x}
	// 					/>
	// 				</Stack>
	// 			)
	// 		}
	// 	</Stack>
	// )
}


export default RecurrenceOccurrences;
import { Button, Stack, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import BasicQrLabel from 'components/qrCodeLabel/BasicQrLabel';
import React, { MutableRefObject, ReactNode, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';



export const ComponentToPrint = React.forwardRef(
	(
		props: {
			children: ReactNode,
		},
		ref: MutableRefObject<any>,
	) =>
	{
		return (
			<div
				ref={ref}
				style={{
					overflow: 'hidden',
				}}
			>
				{props.children}
			</div>
		);
	}
);



// Using a class component, everything works without issue
export const QRCodeWidget =
({
	line1,
	line2,
	line3,
	payload,
} : {
	line1?: string,
	line2?: string,
	line3?: string,
	payload?: string,
}) =>
{
	const component_ref = useRef();
	
	const handlePrint = useReactToPrint({
		content: () => component_ref.current,
	})
	
	
	
	return (
		<Stack
			gap={2}
			padding={1}
			alignItems='center'
		>
			
			<Stack
				sx={{
					boxShadow: '0 1px 3px #0007',
					borderRadius: '3px',
					overflow: 'hidden',
				}}
			>
				<ComponentToPrint
					ref={component_ref}
				>
					<BasicQrLabel
						line1a={line1}
						line2={line2}
						line3={line3}
						payload={payload}
					/>
				</ComponentToPrint>
			</Stack>
			
			
			
			<Button
				onClick={handlePrint}
			>
				<CorIcon
					of='print'
					size='36px'
				/>
				<Typography
					sx={{
						// textTransform: 'none',
						marginLeft: '8px',
					}}
				>
					Print Label
				</Typography>
			</Button>
			
		</Stack>
	);
}


export default QRCodeWidget;
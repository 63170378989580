import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { conciseDateAndTime } from 'utils';

// import { ensureDateTime } from 'models/recurrence/recurrence_helpers2';


// Ex: Mon, Mar 13 at 00:27  or  Mon, Mar 13, 2021 at 00:27
export const ConciseDateAndTime = ({ datetime } : { datetime: DateTime | string}) =>
{
	// let dt = ensureDateTime(datetime);
	
	let text = conciseDateAndTime(datetime);
	
	let time = text.slice(-5);
	
	
	return (
		<Typography
			component='span'
		>
			
			<Typography
				component='span'
				sx={{
					fontWeight: 400,
				}}
			>
				{text.slice(0, 3)}
			</Typography>
			
			<Typography
				component='span'
				sx={{
					opacity: .5,
					fontWeight: 400,
				}}
			>
				,
			</Typography>
			
			<Typography
				component='span'
				sx={{
					fontWeight: 600,
				}}
			>
				{text.slice(4, -7)}
			</Typography>
			
			<Typography
				component='span'
				sx={{
					opacity: .5,
					fontWeight: 400,
				}}
			>
				{' at '}
			</Typography>
			
			{
				(time[0] === '0')
					? time.slice(1)
					: time
			}
			
		</Typography>
	)
}


export default ConciseDateAndTime;
import { useSession } from 'app/providers/SessionProvider';
import { useCollection, useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { Action, Issue, Requisition, WorkOrderNew } from 'models';

import React, { ReactNode, useContext } from 'react';
import { useAuthData } from 'stuff';



export interface MyDashboardData
{
	issues: Issue[];
	actions: Action[];
	work_orders: WorkOrderNew[];
	requisitions: Requisition[];
};



const MyDashboardContext = React.createContext<MyDashboardData>({
	issues: [],
	actions: [],
	work_orders: [],
	requisitions: [],
});



export function useMyDashboard()
{
	return useContext(MyDashboardContext);
};



const MyDashboardProvider =
({
	children,
} : {
	children: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const { users } = useSession();
	
	
	const current_user = users.find(x =>
		(x._id === claims.user_id)
	);
	
	const created_by_user = {
		_id: claims?.user_id,
		name: claims?.name,
		email: claims?.email,
	}
	
	const current_user_role_ids = current_user?.roles?.map(x => x._id);
	
	const org_collection_path = `organizations/${claims?.organization_id}`;
	
	
	// Bring all issues data for user
	const issues_created_by_user_collection = useCollection(
			`${org_collection_path}/issues`,
			where('issue_creator_id', '==', claims.user_id
		)
	);
	const issues_where_user_is_collaborator_collection = useCollection(
		`${org_collection_path}/issues`,
		where('collaborator_ids', 'array-contains', claims.user_id)
	);
	
	
	// Bring all actions data for user
	const actions_created_by_user_collection = useCollection(
		`${org_collection_path}/actions`,
		where('created_by_user', '==', created_by_user)
	);
	const actions_assigned_to_user_collection = useCollection(
		`${org_collection_path}/actions`,
		where('assigned_to', 'array-contains', claims.user_id)
	);
	
	
	// Bring all work orders data for user
	const work_orders_where_user_is_collaborator_collection = useCollection(
		`${org_collection_path}/work_orders`,
		where('collaborator_ids', 'array-contains', claims.user_id)
	);
	const work_orders_assigned_to_user_role_collection = useCollectionConditionally(
		(current_user_role_ids?.length > 0),
		`${org_collection_path}/work_orders`,
		where('assigned_to_role_ids', 'array-contains-any', current_user_role_ids)
	);
	
	
	// Bring all requisitions data for user
	const requisitions_created_by_user_collection = useCollection(
		`${org_collection_path}/requisitions`,
		where('created_by_user', '==', created_by_user)
	);
	const requisitions_assigned_to_charged_accounts_collection = useCollection(
		`${org_collection_path}/requisitions`,
		where('accounts_to_charge_ids', 'array-contains', claims.user_id)
	);
	
	
	
	
	// Assert data types
	const issues_created_by_user = issues_created_by_user_collection.data as Issue[] || [];
	const issues_where_user_is_collaborator = issues_where_user_is_collaborator_collection.data as Issue[] || [];
	
	const actions_created_by_user = actions_created_by_user_collection.data as Action[] || [];
	const actions_assigned_to_user = actions_assigned_to_user_collection.data as Action[] || [];
	
	const work_orders_where_user_is_collaborator = work_orders_where_user_is_collaborator_collection?.data as WorkOrderNew[] || [];
	const work_orders_assigned_to_user_role = work_orders_assigned_to_user_role_collection?.data as WorkOrderNew[] || [];
	
	const requisitions_created_by_user = requisitions_created_by_user_collection?.data as Requisition[] || [];
	const requisitions_assigned_to_charged_accounts = requisitions_assigned_to_charged_accounts_collection?.data as Requisition[] || [];
	
	
	// Combine data
	const all_issues = [...issues_created_by_user, ...issues_where_user_is_collaborator] as Issue[];
	const all_actions = [...actions_created_by_user, ...actions_assigned_to_user] as Action[];
	const all_work_orders = [...work_orders_assigned_to_user_role, ...work_orders_where_user_is_collaborator] as WorkOrderNew[];
	const all_requisitions = [ ...requisitions_created_by_user, ...requisitions_assigned_to_charged_accounts ] as Requisition[];
	
	
	// Filter duplicates by _id
	const issues = all_issues.filter((v,i,a)=>a.findIndex(v2=>(v2._id===v._id))===i);
	const actions = all_actions.filter((v,i,a)=>a.findIndex(v2=>(v2._id===v._id))===i);
	const work_orders = all_work_orders.filter((v,i,a)=>a.findIndex(v2=>(v2._id===v._id))===i);
	const requisitions = all_requisitions.filter((v,i,a)=>a.findIndex(v2=>(v2._id===v._id))===i);
	
	
	
	
	
	
	console.log({issues}, {actions}, {requisitions});
	
	
	const my_dashboard_data: MyDashboardData =
	{
		issues: issues || [],
		actions: actions || [],
		work_orders: work_orders || [],
		requisitions: requisitions || [],
	}
	
	
	return (
		<MyDashboardContext.Provider value={my_dashboard_data}>
			{children}
		</MyDashboardContext.Provider>
	)
};


export default MyDashboardProvider;
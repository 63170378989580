import {
	FirebaseAppProvider,
} from 'reactfire';

import { LocalizationProvider } from '@mui/x-date-pickers';
import LuxonAdapter from '@mui/lab/AdapterLuxon';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
// import { responsiveAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import firebase_config from 'ember/firebase_config';

// TODO: Remove all fuego stuff
import { StateProvider } from 'store';

import FirebaseSubProviders from 'app/providers/FirebaseSubProviders';
import AuthDataProvider from 'app/auth/AuthDataProvider';
import BreadcrumbDataProvider from './BreadcrumbDataProvider';
import ToastProvider from './ToastProvider';


const AppProviders = ({ children }) =>
{
	return (
		<ThemeProvider theme={defaultAppTheme}>
			<LocalizationProvider dateAdapter={LuxonAdapter}>
				
				<FirebaseAppProvider firebaseConfig={firebase_config}>
					<FirebaseSubProviders>
						
						<ToastProvider>
							<AuthDataProvider>
								<StateProvider>
									<BreadcrumbDataProvider>
									
										{children}
									
									</BreadcrumbDataProvider>
								</StateProvider>
							</AuthDataProvider>
						</ToastProvider>
						
					</FirebaseSubProviders>
				</FirebaseAppProvider>
				
			</LocalizationProvider>
		</ThemeProvider>
	);
}


export default AppProviders;
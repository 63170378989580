import { SetStateAction } from 'react';
import { useAuthData } from 'stuff';
import { useUpdate } from 'ember';

import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { Position, Role, User } from 'models/users/user_interfaces';

import CorIcon from 'components/icons/CorIcon';
import { FormProvider, useForm } from 'react-hook-form';
import { CostCenter, Periodicity } from 'models';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import EnumToggleFormlet from 'app/gizmos/fields/toggles/EnumToggleFormlet';
import { DateTime } from 'luxon';


const AddUserPropertiesForm =
({
	user,
	roles,
	positions,
	cost_centers,
	closeDialog,
	setCloseDialog,
} : {
	user: User,
	roles: Role[],
	positions: Position[],
	cost_centers: CostCenter[],
	closeDialog?: boolean,
	setCloseDialog?: React.Dispatch<SetStateAction<boolean>>,
}) =>
{
	const { claims } = useAuthData();
	const update = useUpdate();
	
	
	const form = useForm({
		defaultValues: {
			position: user?.position || {
				_id: '',
				name: '',
			},
			roles: user?.roles || [],
			cost_center_ids: user?.cost_center_ids || [],
			cost_centers: cost_centers.filter(x => user?.cost_center_ids?.includes(x._id)) || [],
			budget_limit: {
				quantity: user?.budget_limit?.quantity || '',
				currency: user?.budget_limit?.currency || 'CAD',
			},
			single_purchase_limit: {
				quantity: user?.single_purchase_limit?.quantity || '',
				currency: user?.single_purchase_limit?.currency || 'CAD',
			},
			budget_reset_period: user?.budget_reset_period || Periodicity.YEARLY,
		}
	});
	
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	
	const assignRolesAndPositionToUser = () =>
	{
		const form_values = form.getValues();
		
		console.log(form_values);
		
		const user_data = {
			...user,
			...form_values,
			cost_center_ids: form_values.cost_centers.map(x => x._id),
			budget_spent_current_period: {
				quantity: '0',
				currency: 'CAD',
			},
			budget_period_start_date: DateTime.utc().toISO(),
		};
		
		delete user_data.cost_centers
		
		console.log('NEW USER DATA', user_data)
		
		update(`${org_path}/users`, user_data);
		
		setCloseDialog(!closeDialog);
	};
	
	
	return (
		<FormProvider {...form}>
			
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='flex-start'
				width='100%'
				gap={3}
				sx={{
					marginTop: '10px',
				}}
			>
				<Button
					variant='contained'
					endIcon={<CorIcon of='assign_to_user' size='24px' />}
					onClick={assignRolesAndPositionToUser}
					sx={{
						marginTop: '20px',
						width: '100%'
					}}
					disabled={!roles.length}
				>
					Submit Changes
				</Button>
				
				<SearchableSelectFormlet
					name='position'
					label='Position name within the comapany'
					help='e.g. CEO'
					options={positions}
					getOptionLabel={option => option.name}
				/>
				
				<SearchableSelectFormlet
					name='roles'
					label='Please assign or edit Roles for the user'
					help='Tip: You can assign multiple roles to a user'
					options={roles}
					getOptionLabel={option => option.name}
					multiple={true}
				/>
				
				<SearchableSelectFormlet
					name='cost_centers'
					label='Please assign or edit Cost Centers for the user'
					help='Tip: You can assign multiple Cost Centers to a user'
					options={cost_centers}
					getOptionLabel={option => option.description}
					multiple={true}
				/>
				
				<CostFormlet
					name='single_purchase_limit'
					label='Please set the users Single Purchase Limit'
					help='e.g. 10,000 CAD'
				/>
				
				<CostFormlet
					name='budget_limit'
					label='Please set the users Total Budget for the Period below'
					help='e.g. 120,000 CAD'
				/>
				
				<EnumToggleFormlet
					name='budget_reset_period'
					label='Budget reset Interval/Period'
					help='10,000/year = YEARLY'
					enum_type={Periodicity}
				/>
			</Stack>
			
		</FormProvider>
	)
};


export default AddUserPropertiesForm;
import { Button, Stack } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { DateTime } from 'luxon';
import { Recurrence, RecurrenceScheduleType } from 'models';
import { Controller, useFormContext } from 'react-hook-form';
import TimePickerWidget from '../fields/datetimes/TimePickerWidget';
import Formlet from '../fields/Formlet';


export const TimeOfDayFormlet =
({
	name,
	label,
	help,
} : {
	name: string,
	label?: string,
	help?: string,
	onChange?: Function,
}) =>
{
	const form_context = useFormContext();
	
	const time_of_day = form_context.getValues(name);
	
	
	let recurrence = form_context.watch('recurrence') as Recurrence;
	
	
	// On demand recurrence will have time set when triggered - scheduled dates include their own time
	if(recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
	{
		return null;
	}
	
	
	
	const handleChange = (new_datetime: DateTime) =>
	{
		form_context.setValue(
			name,
			{
				hour:   new_datetime.hour,
				minute: new_datetime.minute,
			},
		);
	}
	
	
	const handleDatetimeReset = () =>
	{
		handleChange?.(DateTime.local());
	}
	
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<Stack
						className='datetime_picker'
						direction='row'
						gap={2}
						justifyContent='center'
						sx={{
							marginTop: 1,
						}}
					>
						<TimePickerWidget
							value={DateTime.local().set(time_of_day)}
							label={null}
							onChange={handleChange}
						/>
						<Button
							onClick={handleDatetimeReset}
						>
							<CorIcon
								of='reset_datetime'
								size='26px'
							/>
						</Button>
					</Stack>
				}
			/>
		</Formlet>
	)
}


export default TimeOfDayFormlet;
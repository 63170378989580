import React, {
	useCallback,
	useMemo,
	useRef,
	useEffect,
	useState,
	forwardRef,
	useImperativeHandle,
	Ref,
} from 'react';

// draft js components and plugins 
import {
	EditorState,
	convertToRaw,
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, MentionData} from '@draft-js-plugins/mention';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';


export interface TextInputProps
{
	textInputStyle: string;
	placeholder: string;
	onChange: ( data: string[] ) => void;
	mentions: MentionData[];
	_ref: Ref<{clearTextField: any}>;
};


const TextInput: React.FC< TextInputProps > = forwardRef(({
	textInputStyle,
	placeholder,
	onChange,
	mentions,
	_ref
}, _ ) =>
{
	const [ suggestions, setSuggestions] = useState(mentions);
	const [ is_open, setIsOpen] = useState<boolean>(false);
	
	const editor_ref = useRef<Editor>(null);
	
	
	useEffect(() =>
		{
			setSuggestions( mentions );
		},
		[mentions]
	)
	
	
	const { MentionSuggestions, plugins } = useMemo(() =>
		{
			const mentionPlugin = createMentionPlugin();
			
			const { MentionSuggestions } = mentionPlugin;
			
			const plugins = [mentionPlugin];
			
			return { plugins, MentionSuggestions };
		},
		[]
	);
	
	
	// Draft-JS editor configuration
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	
	
	useImperativeHandle(
		_ref,
		() => ({
			clearTextField
		}),
	)
	
	
	const clearTextField = () =>
	{
		setEditorState(EditorState.createEmpty());
	}
	
	
	// open the suggestion window 
	const onOpenChange = useCallback((_open: boolean ) =>
		{
			setIsOpen(_open);
		},
		[]
	);
	
	
	// Check editor text for mentions
	const onSearchChange = ({ value }: { value: string }) =>
	{
		setSuggestions(
			defaultSuggestionsFilter(value, mentions)
		);
	};
	
	
	// extract text data from editorState into string array
	const onExtractData = () => 
	{
		const contentState = editorState.getCurrentContent();
		
		const raw = convertToRaw( contentState );
		
		var text_array: string[] = [];
		
		raw.blocks.map( (line: any) =>
		{
			return text_array.push((line.text) as string );
		})
		
		onChange( text_array );
	};
	
	
	// Focus on editor window
	const focusEditor = () =>
	{
		editor_ref.current!.focus();
	}
	
	
	return (
		<div
			className={ textInputStyle ? textInputStyle : 'editor' }
			onClick={ () => focusEditor() }
		>
			
			<Editor
				editorState={ editorState }
				onChange={
					( editorState: any ) => {
						setEditorState( editorState );
						onExtractData();
					}
				}
				plugins={ plugins }
				editorKey={ 'editor' }
				ref={ editor_ref }
				placeholder={ placeholder }
			/>
			
			<MentionSuggestions
				open={ is_open }
				onOpenChange={ onOpenChange }
				suggestions={ suggestions }
				onSearchChange={ onSearchChange }
			/>
			
		</div>
	);
});


export default TextInput;
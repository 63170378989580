import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { ScheduleFormData } from '../../scheduling_types';
import { LeftContainer, RightContainer } from '../common/styles';
import { RepeatTime } from '../common/RepeatTime/RepeatTime';
import { Box, Divider, Stack } from '@mui/material';
import { WhiteContainerWithShadow } from '../common/styles';
import { EffectiveDate } from '../common/EffectiveDate/EffectiveDate';
import { RepeatTypeSelector } from '../common/RepeatTypeSelector/RepeatTypeSelector';
import { IntervalAndSpecificDaysContainer } from './styles';
import { SpecificDays } from './SpecificDays/SpecificDays';
import IntervalField from '../common/IntervalField/IntervalField';
import { RecurrenceType } from 'models';


export interface WeeklyProps
{
	control: Control<any, any>;
	setValue: UseFormSetValue<ScheduleFormData>;
}


export const Yearly = ({ control, setValue }: WeeklyProps) =>
{
	return (
		<Stack
			direction='row'
			flexWrap='wrap'
			justifyContent='center'
			alignItems='center'
			gap={3}
		>
			<LeftContainer>
				<WhiteContainerWithShadow>
					<Controller
						control={control}
						name={'recurrence.recurrence_type'}
						defaultValue={RecurrenceType.SCHEDULE}
						render={({ field: { value, onChange } }) => {
							return (
								<Box>
									<RepeatTypeSelector
										control={control}
										value={value}
										onChange={onChange}
									/>
									<IntervalAndSpecificDaysContainer>
										{RecurrenceType.INTERVAL && (
											<IntervalField
												control={control}
												recurrence_unit='years'
											/>
										)}
										
										{value === RecurrenceType.SCHEDULE && (
											<SpecificDays control={control} />
										)}
									</IntervalAndSpecificDaysContainer>
								</Box>
							);
						}}
					/>
				</WhiteContainerWithShadow>
			</LeftContainer>
			
			<Divider />
			
			<RightContainer>
				<RepeatTime
					control={control}
					setValue={setValue}
					defaultValue={new Date()}
				/>
				<EffectiveDate
					control={control}
					// name='yearly.effective_date'
					defaultValue={new Date()}
				/>
			</RightContainer>
		</Stack>
	);
};


export default Yearly;
import React, { createContext, useReducer } from 'react';

import reducer from 'store/reducers/reducer';
import loggingReducer from 'store/reducers/loggingReducer';

import { State } from './classes';


// Create a store context, containing global data
const store = createContext();

// Allows components under hierarchy to subscribe to context changes
const { Provider } = store;


// Controls whether we use the loggingReducer or the unwrapped version
const ENABLE_LOGGING = true;



// TODO: Remove all fuego stuff

// By wrapping our whole App with this, every component will be able to access
// the global state (via the useAppState() or useGlobals() hooks found in stuff)
const StateProvider = ({ children }) =>
{
	const [state, dispatch] = useReducer
	(
		// Decides whether we should use the logging reducer
		ENABLE_LOGGING ? loggingReducer : reducer,
		new State(),
	);
	
	
	return (
		<Provider
			value={{
				state: state,
				dispatch: dispatch,
				dispatchWrite: (thingy) => dispatch({type: 'WRITE', payload: thingy}),
				dispatchWrites: (thingys) => dispatch({type: 'WRITE', payload: thingys}),
			}}
		>
			{children}
		</Provider>
	);
};


export { store, StateProvider }

import MobileHeader from '../MobileHeader';
import { useStyles } from '../../../styles/ManageUserTabTheme';
import { Stack } from '@mui/material';
import ManageCostsPage from '../../../manage_costs/ManageCostsPage';


const ManageCostCentersPageMobile = () =>
{
	const classes = useStyles();
	
	
	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{
				padding: '10px',
			}}
		>
			<MobileHeader
				title='Manage Users'
				variantType='h3'
			/>
			
			<ManageCostsPage />
		</Stack>
	)
}


export default ManageCostCentersPageMobile;
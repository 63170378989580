import { makeStyles, createStyles } from "@mui/styles"

export const useStyles = makeStyles((theme?: any) => createStyles({
	ManageUserContainer: {
		marginLeft: '2.5rem',
	},

	ManageUserTitle: {
		marginLeft: '1rem',
		marginBottom: '.5rem',
	},

	ManageUserInputIndent: {
		marginLeft: '3rem',
	},

	ManageUserInputContainer: {
		width: '40%',
	},

	ManageUserInputFieldContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},


	ManageUserInputField: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '35px',
		padding: '2px',
	},

	ManageUserInputBtn: {
		marginTop: '1rem',
		padding: '.3rem 2rem',
		width: 'fit-content',
	},

	ManageUserContainer__mobile: {
		padding: '1rem',
	},

	ManageUser_UserListContainer: {
		width: '100%',
		marginTop: '3rem',
	},

	ManageUserInputFieldContainer__mobile: {
		marginTop: '1.5rem',
	},

	TableContainer: {
		marginTop: '2rem',
	},

	TableActionIcon: {
		display: 'flex',
		justifyContent: 'right',
		alignItems: 'center',
	},

	TableTitles: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '.5rem 1.5rem',
	},

	TableItem: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1rem 1.5rem',
	}

}))
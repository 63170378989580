import { useState } from 'react';
import cuid from 'cuid';

import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import SeveritySelect from './SeveritySelect';
import { useStyles } from '../../styles/theme';
import LinkWorkOrder from 'components/linkWorkOrder/LinkWorkOrder';
import ButtonModal from 'components/buttonModal/ButtonModal';
import AddPartsStepper from 'components/addParts/AddPartsStepper';
import AddedPartsList from 'components/addParts/AddedPartsList';
import SelectEquipment from 'components/selectEquipment/SelectEquipment';
import SelectedEquipmentList from 'components/selectEquipment/SelectedEquipmentsList';
import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';

import { ButtonToOpenPartsDialog } from 'pages/CreateWorkOrder/GeneralDescription/Parts/PartsModal/ButtonToOpenPartsDialog';
import { PartNeededItem } from 'components/addParts/interfaces';


const CreateIssueDesktopColumnLeft = () =>
{
	const classes = useStyles();
	
	const { setValue } = useFormContext();
	
	
	const { register, formState: {errors}, control } = useFormContext();
	const {selected_components, set_selected_components, parts_needed, set_parts_needed } = useSessionContext();
	
	
	
	const selectParts = (parts) =>
	{
		const parts_needed_ids = [];
		const parts_with_part_needed_id = parts.map(part => {
			
			const _id = cuid();
			
			parts_needed_ids.push(_id);
			
			return {
				part_needed_id: _id,
				...part
			}
		})
		
		set_parts_needed(parts_with_part_needed_id);
		
		setValue('parts_needed_ids', parts_needed_ids);
	};
	
	
	return (
		<Box className={classes.DesktopCol}>
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					
					<Typography variant='h3'>
						Title
					</Typography>
					
					<Typography variant='h3' color='#ff595e'>
						*
					</Typography>
					
				</Box>
				
				<TextField  
					{...register('name',{ required: 'Please enter an issue title.'} )}
					required={true}
					variant='outlined'
					fullWidth
					error={(errors?.name !== undefined) ? (errors.name?.message !== '') : false}
				/>
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Typography variant='h3'>
					Description
				</Typography>
				
				<TextField 
					{...register('description')} 
					multiline 
					fullWidth
					variant='outlined'
					rows={4}
				/>
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					
					<Typography variant='h3'>
						Primary Affected Equipment
					</Typography>
					
					<Typography variant='h3' color='#ff595e'>
						*
					</Typography>
					
				</Box>
				
				<SelectEquipment 
					selected_components={selected_components}
					set_selected_components={set_selected_components}
				/>
				
				<SelectedEquipmentList />
				
				{/* <IssueCreationDrawer buttonTitle='Add Equipment' /> */}
				
			</Box>
			
			<Box className={classes.CreateIssueInputBox}>
				<SeveritySelect />
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					<Typography variant='h3'>
						Link or Create Work Orders
					</Typography>
				</Box>
				
				<LinkWorkOrder 
					control={control}
				/>
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					<Typography variant='h3'>
						Parts Required
					</Typography>
				</Box>
				
				<AddedPartsList />
				
				{/* <ButtonModal buttonTitle='Add Parts'>
					<AddPartsStepper />
				</ButtonModal> */}
				
				<ButtonToOpenPartsDialog
					parts={parts_needed}
					onSetParts={selectParts}
				/>
				
			</Box>
			
		</Box>
	)
}


export default CreateIssueDesktopColumnLeft;
import { Box, TextField } from '@mui/material';
import { ReadingEquipments } from './Equipments/ReadingEquipments';
import { ActionProps } from '../../types';
import { Cumulative } from './Cumulative/Cumulative';
import { Description } from './Description/Description';
import { ReadingType } from './ReadingType/ReadingType';
import { Controller } from 'react-hook-form';
import ReadingComponentRecords from './Equipments/ReadingComponentRecords';


interface ReadingProps extends ActionProps {}


export const Reading = (props: ReadingProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Description {...props} />
			<ReadingEquipments {...props} />
			{/* <Cumulative {...props} /> */}
			<ReadingComponentRecords {...props} />
			<ReadingType {...props} />
			<ReadingValue {...props} />
		</Box>
	);
};



export const ReadingValue = ({
	control,
	sectionIndex,
	fieldIndex,
} : ReadingProps) =>
{
	return(
			<Controller
			control={control}
			name={
				`sections.${sectionIndex}.fields.${fieldIndex}.reading.value` as const
			}
			render={({ field: { value, onChange } }) => {
				return (
					<TextField
						type='number'
						value={value}
						onChange={onChange}
						label='Enter reading value'
					/>
				);
			}}
		/>
	)
}





export default Reading;
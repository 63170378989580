import { useFormContext, useWatch } from 'react-hook-form';

import { Box, styled, Chip } from '@mui/material';

// import { useStyles } from './theme';
// import { List, ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
// import { PartObjectForm } from './interfaces';
// import { convertToPartObject, removePartFromPartObjects } from './utils';

import CorIcon from 'components/icons/CorIcon';

import { useSession } from 'app/providers/SessionProvider';
import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';

import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';



const StyledChip = styled(Chip)(() => ({
	backgroundColor: '#186ab4',
	color: 'white',
}));



const AddedPartsList = () =>
{
	// const classes = useStyles();
	
	const { getValues, setValue } = useFormContext();
	
	const { parts } = useSession();
	
	const { parts_needed, set_parts_needed} = useSessionContext();
	
	const partObjects = parts_needed;
	
	const parts_needed_ids = getValues('parts_needed_ids');
	
	
	useWatch({ name: 'parts_needed_ids' });
	
	
	const removePart = (partObject: PartWithSelectedCount) =>
	{
		let newPartObjects = [...partObjects];
		
		newPartObjects = newPartObjects.filter((partObj) => partObj._id !== partObject._id);
		
		set_parts_needed(newPartObjects);
		
		const new_parts_needed_ids = parts_needed_ids.filter((id) => id !== partObject._id)
		
		setValue('parts_needed_ids', new_parts_needed_ids);
	}
	
	
	return (
		// <Box className={classes.AddedPartsListBox}>
		// 	<List>
		// 		{
		// 			parts_needed?.map((partObj: PartWithSelectedCount, key: number) =>
		// 				<ListItem
		// 					key={partObj._id + key + 'selected-part'}
		// 					className={classes.ListItemText}
		// 					style={{
		// 						backgroundColor:'#186ab4',
		// 						color: '#fbfbfb',
		// 					}}
		// 					secondaryAction={
		// 						<IconButton
		// 							value={`${partObj._id}`}
		// 							disableRipple
		// 							onClick={() => removePart(partObj)}
		// 						>
		// 							<Icon
		// 								of='close_outlined'
		// 								color='#fbfbfb'
		// 								size='20px'
		// 							/>
		// 						</IconButton>
		// 					}
		// 				>
		// 					<ListItemButton>
		// 						<ListItemText
		// 							id={partObj._id}
		// 							primary={`${partObj.name}`}
		// 							secondary={'Quantity: ' + partObj.selected_count}
		// 							// className={classes.ListItemText}
		// 						/>
		// 					</ListItemButton>
		// 				</ListItem>
		// 			)
		// 		}
		// 	</List>
		// </Box>
		<Box
			sx={{
				border: `1px solid #d8dbdf`,
				borderRadius: '3px',
				padding: '0.5rem',
				display: 'flex',
				gap: '0.5rem',
				flexWrap: 'wrap',
				marginTop: '1rem',
			}}
		>
			{parts_needed.map((part, index) => (
				<StyledChip
					label={`${
						part.selected_count > 1
							? `${part.selected_count}X`
							: ''
					} ${part.name}`}
					deleteIcon={
						<CorIcon of='close' size='15px' color='#fff' />
					}
					onDelete={() => removePart(part)}
				/>
			))}
		</Box>
	)
}


export default AddedPartsList;
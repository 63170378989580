import { PartsTable } from './PartsTable/PartsTable';
import { useEffect, useState } from 'react';
import { PartsCount } from '../PartsCount/PartsCount';
import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import CorIcon from 'components/icons/CorIcon';


export interface ButtonToOpenPartsDialogProps
{
	parts: PartWithSelectedCount[],
	onSetParts: (parts: PartWithSelectedCount[]) => void,
	sx?: any,
	button_content?: string,
}


export const ButtonToOpenPartsDialog =
({
	parts,
	onSetParts: _onSetParts,
	sx,
	button_content,
}: ButtonToOpenPartsDialogProps) =>
{
	const [selectedParts, setSelectedParts] = useState<PartWithSelectedCount[]>(parts);
	
	const [display, setDisplay] = useState<'partsTable' | 'partsCount'>(
		'partsTable'
	);
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	useEffect(() =>
	{
		setSelectedParts(parts);
	}, [parts]);
	
	
	const onSetParts: ButtonToOpenPartsDialogProps['onSetParts'] = (parts) =>
	{
		_onSetParts(parts);
		setCloseDialog(!closeDialog);
		setDisplay('partsTable');
	};
	
	return (
		<ButtonToOpenDialog
			button_variant='text'
			dialog_title='Select Parts and Quantity'
			button_content={button_content || 'Add Parts'}
			dialog_content={
				display === 'partsTable' ? (
					<PartsTable
						selectedParts={parts}
						onSelect={(parts) => {
							setSelectedParts(parts);
						}}
						showCountDisplay={() => setDisplay('partsCount')}
					/>
				) : (
					<PartsCount
						parts={selectedParts}
						setSelectedParts={setSelectedParts}
						onFinalSet={onSetParts}
					/>
				)
			}
			closeDialog={closeDialog}
			startIcon={!button_content && <CorIcon of='inventory' size='20px'/>}
			sx={{
				whiteSpace: 'nowrap',
				...sx,
			}}
		/>
	);
};


export default ButtonToOpenPartsDialog;
import { SetStateAction } from 'react';

import { CircularProgress, Stack, Typography } from '@mui/material';
import { Position, User } from 'models/users/user_interfaces';
import UserAvatar from 'components/avatars/UserAvatar';
import { useAuthData } from 'stuff';
import { useCollection } from 'ember';
import { useSession } from 'app/providers/SessionProvider';
import AddUserPropertiesForm from './AddUserPropertiesForm';
import { CostCenter } from 'models';



const AddUserAdditionalProperties = ({
	user,
	closeDialog,
	setCloseDialog,
} : {
	user: User,
	closeDialog?: boolean,
	setCloseDialog?: React.Dispatch<SetStateAction<boolean>>,
}) =>
{
	const { claims } = useAuthData();
	const { roles } = useSession();
	
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	const positions_collection = useCollection(`${org_path}/positions`);
	const cost_centers_collection = useCollection(`${org_path}/cost_centers`);
	
	
	if(
		positions_collection?.is_loading
		|| cost_centers_collection?.is_loading
	)
	{
		return <CircularProgress />
	} 
	
	
	const positions = positions_collection?.data as Position[];
	const cost_centers = cost_centers_collection?.data as CostCenter[];
	
	
	
	return (
		<Stack>
			
			<Stack
				direction='row'
				spacing={3}
				alignItems='center'
			>
				<UserAvatar user={user}/>
				
				<Stack>
					<Typography variant='subtitle1'>
						{user.display_name}
					</Typography>
					
					<Typography>
						{user.email}
					</Typography>
				</Stack>
			</Stack>
			
			<AddUserPropertiesForm
				user={user}
				roles={roles}
				cost_centers={cost_centers}
				positions={positions}
				closeDialog={closeDialog}
				setCloseDialog={setCloseDialog}
			/>
			
		</Stack>
	)
};


export default AddUserAdditionalProperties;
import { ComponentReading } from 'models';
import { createContext, useContext } from 'react';


const ComponentReadingContext = createContext<Partial<ComponentReading>>({});


export const useComponentReading = () =>
{
	return useContext(ComponentReadingContext);
};


// Makes the provided component reading available to descendants via hook
export const ComponentReadingProvider = ({ component_reading, children }) =>
{
	return (
		<ComponentReadingContext.Provider value={component_reading}>
			{children}
		</ComponentReadingContext.Provider>
	);
}


export default ComponentReadingProvider;
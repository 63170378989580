


// Pass in a data structure to save it to the browser's local storage under specified key
export const saveToLocalStorage = (key, data) =>
{
	try
	{
		const serialized_data = JSON.stringify(data);
		
		localStorage.setItem(key, serialized_data);
		
		//console.log('    Saved to', key, data);
		//console.log('    Serialized', serialized_data);
		
		return serialized_data;
	}
	catch (error)
	{
		console.error(error);
		
		return undefined;
	}
};



// Loads the state and returns the data structure specified by key
export const loadFromLocalStorage = (key) =>
{
	try
	{
		const serialized_data = localStorage.getItem(key);
		
		console.log('Loaded from', key, JSON.parse(serialized_data));
		
		return (serialized_data !== null)
			? JSON.parse(serialized_data)
			: undefined;
	}
	catch (error)
	{
		console.error(error);
		
		return undefined;
	}
};



// Call to delete data from browser's local storage
export const deleteFromLocalStorage = (key) =>
{
	try
	{
		localStorage.removeItem(key);
	}
	catch (error)
	{
		console.error(error);
	}
};



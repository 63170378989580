import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { System } from 'models';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import CorIcon from 'components/icons/CorIcon';
import { useNavigate } from 'react-router';
import { useCor } from 'app/providers/CorProvider';
import { Stack } from '@mui/material';


const SystemsGridRowCell =
({
	cell_data,
	setSelectedSystem,
} : { 
	cell_data: System,
	setSelectedSystem?: Function,
}) =>
{
	const navigate = useNavigate();
	
	const { setSelectedSystemId } = useCor();
	
	
	// console.log(window.location.pathname)
	
	
	// TODO: This sometimes doesn't work
	// TODO: Refactor
	const navigateToComponents = () =>
	{
		console.log('navigateToComponents', {
			cell_data,
			path: window.location.pathname,
		})
		
		// TODO: This is a weird hack
		if((window.location.pathname.split('?')[0] === `/assets/${cell_data.asset_id}`)
		|| (window.location.pathname.split('?')[0] === `/assets/${cell_data.asset_id}/systems`))
		{
			let destination = `/assets/${cell_data.asset_id}/systems/${cell_data._id}/components`;
			
			navigate(destination);
			
			console.log('navigating to ', destination)
		}
		else
		{
			setSelectedSystem(cell_data);
			setSelectedSystemId(cell_data._id);
		}
		
		// setSelectedSystemId(cell_data._id);
	};
	
	
	
	return(
		<Stack
			className='systems_grid_cell'
			onClick={() => navigateToComponents()}
		>
			
			<div className='systems_grid_cell_top_section'>
				<Typography variant='body2'>
					{`[ ${cell_data.maintenance_function_code || cell_data.asset_code} ]`} - {cell_data.name}
				</Typography>
				
				<CorIcon of='arrow_right' size='20px'/>
			</div>
			
			<div className='systems_grid_cell_bottom_section'>
				<Typography variant='caption'>
					Open Work Orders: #
				</Typography>
				
				<Divider
					orientation='vertical'
					sx={{
						height: '12px',
						color: defaultAppTheme.palette.grey['900'],
						margin: '0 10px'
					}}
				/>
				
				<Typography variant='caption' sx={{ color: defaultAppTheme.palette.secondary.main}}>
					Open Issues: #
				</Typography>
			</div>
			
		</Stack>
	)
};


export default SystemsGridRowCell;
import L from 'leaflet';

import { makeColor } from './utils';



export const route_origin_marker = new L.Icon({
	iconUrl: 'images/map/route_origin.svg',
	iconSize: [32, 32],
	popupAnchor: [0, -16],
});


export const route_waypoint_marker = new L.Icon({
	iconUrl: 'images/map/route_waypoint.svg',
	iconSize: [32, 32],
	popupAnchor: [0, -7],
});


export const port_marker = new L.Icon({
	iconUrl: 'images/map/port.svg',
	iconSize: [32, 32],
	popupAnchor: [0, -16],
});



export const skater = new L.Icon({
	iconUrl: 'images/map/skateboarding.svg',
	iconSize: [32, 32],
	popupAnchor: [0, -16],
});



export const vessel = new L.Icon({
	iconUrl: 'images/map/vessel.svg',
	iconSize: [32, 32],
	popupAnchor: [0, -16],
});






export const rotatedVessel = (degrees_rotated: number) =>
{
	return L.divIcon({
		className: 'custom_icon',
		iconUrl: 'images/map/vessel.svg',
		iconSize: [32, 32],
		popupAnchor: [0, -16],
		html: `<img 
			style='transform: rotate(${degrees_rotated}deg);'
			width='32'
			height='32'
			src='images/map/vessel.svg'
		>`
	});
}







export const coloredMarkerIcon = (color: string) =>
{
	return new L.Icon({
		iconUrl: `images/map/marker-icon-2x-${color}.png`,
		shadowUrl: 'images/map/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41]
	});
}




export const coloredMarkerIcon2 = (hex: string) =>
{
	//let hsv = hex2hsv(hex);
	
	let filter = makeColor(hex.replaceAll('#', ''));
	
	
	//hue-rotate(${hsv.h}deg) brightness(100%);
	
	
	return L.divIcon({
		className: 'custom_icon',
		shadowUrl: 'images/map/marker-shadow.png',
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
		html: `<img 
			style='filter: brightness(0) saturate(100%) ${filter.replace('filter: ', '')}'
			width='25'
			height='41'
			src='images/map/marker-icon-2x-black.png'
		>`
	});
}


export const blue_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-blue.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const gold_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-gold.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const red_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-red.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const green_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-green.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const orange_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-orange.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const yellow_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-yellow.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const violet_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-violet.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const grey_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-grey.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const black_marker_icon = new L.Icon({
	iconUrl: 'images/map/marker-icon-2x-black.png',
	shadowUrl: 'images/map/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});



import { Routes, Route, useAuthData } from 'stuff';


import Communication from './pages/communication/Communication';
import SchedulePage from './pages/schedule/SchedulePage';
import FilterableSchedule from './pages/filterable_schedule/FilterableSchedule';

import InventoryPage from './pages/inventory/InventoryPage';
import DocumentPage from './pages/FolderManagement/DocumentPage';
import MapPage from 'pages/map/MapPage';

import SystemsNavigationPage from './pages/systems/SystemsNavigationPage';
import SettingsResponsivePage from './pages/settings/responsive_pages/SettingsResponsivePage';
import UserProfilePage from './pages/settings/responsive_pages/settings_mobile/pages/UserProfilePage';
import ManageUsers from './pages/settings/responsive_pages/settings_mobile/pages/ManageUsersPage';
import ManageTags from './pages/settings/responsive_pages/settings_mobile/pages/ManageTagsPage';
import CreateNewTag from './pages/settings/responsive_pages/manage_tags/components/mobile/CreateNewTag';

import ComponentsPage from './pages/components/ComponentsPage';

import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import IssueCollaborationPage from './pages/issueCollaboration/IssueCollaborationPage';

import DemoPage from 'pages/demo/DemoPage';
import CreateIssuePage from 'pages/createIssue/CreateIssuePageNew';
import WorkOrdersAndTemplatesListPage from 'pages/work_orders/WorkOrdersAndTemplatesListPage';
import IssuesListPage from './pages/issuesMain/IssuesListPage';
import WorkOrderTemplatePage from 'pages/WorkOrderCollaboration/WorkOrderTemplatePage';
import MaybeQrLabelDialog from 'pages/createIssue/MaybeQrLabelDialog';
import MaybeQrScanDialog from 'pages/createIssue/MaybeQrScanDialog';
import ComponentTechnicalDocumentationPage from './pages/components/componentsDrawer/details/componentFiles/ComponentTechnicalDocumentationPage';
import CreateWorkOrderTemplatePage from 'pages/CreateWorkOrder/updated/CreateWorkOrderTemplatePage';

import MyDashboardPage from './pages/myPage/MyDashboardPage'

import WOTCollabPage from './pages/WOTemplateCollaboration/WOTCollabPage';
import AssetsDashboardAndNavigationPage from './pages/assets/AssetsDashboardAndNavigationPage';
import CreateWorkOrderTemplatePage2 from 'pages/CreateWorkOrder/updated/CreateWorkOrderTemplatePage2';
import WorkOrderPage from './pages/workOrders/WorkOrderPage';
import ComponentRecordsPage from './pages/components/componentsDrawer/details/componentRecords/ComponentRecordsPage';


import AnalyticsPageMain from 'pages/analyticsPage/AnalyticsPageMain';

// Requisitions
import CreateRequisitionPage from './pages/requisitions/createRequisitions/CreateRequisitionPage';
import RequisitionCollabPage from './pages/requisitions/requisitionCollab/RequisitionCollabPage';
import RequisitionsAndPartsTabsSection from './pages/requisitionsAndParts/RequisitionsAndPartsTabsSection';
import ManageCostCentersPageMobile from './pages/settings/responsive_pages/settings_mobile/pages/ManageCostCentersPageMobile';

import CertificationPageMain from './pages/certification/CertificationPageMain';
import LeeWayTemplatesConversionPage from 'conversion/helm/LeeWayTemplatesConversionPage';


// TODO: Fix whitespace and formatting
const Main = () =>
{
	const { is_loading, claims } = useAuthData();
	
	
	if (is_loading || !claims) return null;
	
	
	return (
		<main className='main_content'>
			<Routes>
				<Route path='/' element={<MyDashboardPage />} />
				<Route path='/home' element={<MyDashboardPage />} />
				
				<Route path='/folders' element={<DocumentPage />} />
				<Route path='/folders/:folderId' element={<DocumentPage />} />
				
				<Route
					path='/vessels/:vessel_id/create-issue'
					element={<CreateIssuePage />}
				/>
				
				
				<Route path='/my-page' element={<MyDashboardPage />} />
				
				<Route path='/communication' element={<Communication />} />
				<Route path='/schedule' element={<SchedulePage />} />
				<Route path='/inventory' element={<InventoryPage />} />
				<Route path='/demo' element={<DemoPage />} />
				<Route path='/map' element={<MapPage />} />
				
				<Route path='/uploader' element={<FilterableSchedule />} />
				<Route path='/calendar' element={<FilterableSchedule />} />
				
				<Route path='/leeway' element={<LeeWayTemplatesConversionPage />} />
				
				
				
				{/* <Route path='/settings' element={<SettingsPage />} /> */}
				<Route path='/settings' element={<SettingsResponsivePage />} />
				<Route path='/settings/profile' element={<UserProfilePage />} />
				<Route path='/settings/users' element={<ManageUsers />} />
				<Route path='/settings/tags' element={<ManageTags />} />
				<Route path='/settings/cost_centers' element={<ManageCostCentersPageMobile />} />
				<Route
					path='/settings/tags/create'
					element={<CreateNewTag />}
				/>
				
				
				
				<Route path='/requisitions/:requisition_id' element={<RequisitionCollabPage />} />
				<Route path='/requisitions' element={<RequisitionsAndPartsTabsSection />} />
				<Route path='/create-requisition' element={<CreateRequisitionPage />} />
				
				
				
				<Route path='/issues' element={<IssuesListPage />} />
				
				<Route path='/create-issue' element={<CreateIssuePage />} />
				
				<Route
					path='/issues/:issue_id'
					element={<IssueCollaborationPage />}
				/>
				
				<Route path='/issues/create' element={<CreateIssuePage />} />
				
				
				
				<Route path='/assets' element={<AssetsDashboardAndNavigationPage />} />
				
				<Route
					path='/assets/:asset_id'
					element={<SystemsNavigationPage />}
				/>
				<Route
					path='/assets/:asset_id/systems'
					element={<SystemsNavigationPage />}
				/>
				<Route
					path='/assets/:asset_id/systems/:system_id/'
					element={<ComponentsPage />}
				/>
				<Route
					path='/assets/:asset_id/systems/:system_id/components'
					element={<ComponentsPage />}
				/>
				<Route
					path='/assets/:asset_id/systems/:system_id/components/:component_id'
					element={<ComponentsPage />}
				/>
				<Route
					path='/assets/:asset_id/systems/:system_id/components/:component_id/files'
					element={<ComponentTechnicalDocumentationPage />}
				/>
				<Route
					path='/assets/:asset_id/systems/:system_id/components/:component_id/create-issue'
					element={<CreateIssuePage />}
				/>
				<Route
					path='/assets/:asset_id/systems/:system_id/components/:component_id/component-records'
					element={<ComponentRecordsPage />}
				/>
				
				
				<Route
					path='/select-components'
					element={<ComponentSelection />}
				/>
				
				
				
				{/* <Route path='/work-orders' element={<WorkOrdersList />} /> */}
				<Route path='/work-orders' element={<WorkOrdersAndTemplatesListPage />} />
				<Route path='/work-order-templates' element={<WorkOrdersAndTemplatesListPage />} />
				
				<Route
					path='/work-orders/:work_order_id'
					element={<WorkOrderPage />}
				/>
				
				<Route
					path='/work-orders/create-template'
					element={<CreateWorkOrderTemplatePage2 />}
				/>
				
				
				{/* testing new page for work order view */}
				{/* element={<WorkOrderTemplatePage />} */}
				<Route
					path='/work-order-templates/:work_order_template_id'
					element = {<WOTCollabPage />}
				/>
				
				<Route
					path='/test/:id'
					element = {<WorkOrderTemplatePage />}
				/>
				
				
				{/* Deprecated */}
				<Route
					path='/create-work-order'
					element={<CreateWorkOrderTemplatePage />}
				/>
				<Route
					path='/create-work-order-template-old'
					element={<CreateWorkOrderTemplatePage />}
				/>
				<Route
					path='/create-work-order-template'
					element={<CreateWorkOrderTemplatePage2 />}
				/>
				
				
				{/* <Route
					path='/leeway-work-orders/:formId/preview'
					element={<LeewayWorkOrderPreview />}
				/> */}
				
				
				<Route
					path='/select-components'
					element={<ComponentSelection />}
				/>
				<Route path='/map' element={<MapPage />} />
				
				{/* My Page */}
				<Route path='/my-page' element={<MyDashboardPage />} />
				
				
				<Route path='/create-requisition' element={<CreateRequisitionPage />} />
				<Route path='/requisitions/:requisition_id' element={<RequisitionCollabPage />} />
				<Route path='/requisitions' element={<RequisitionsAndPartsTabsSection />} />
				
				
				<Route path='/data-analytics' element={<AnalyticsPageMain />} />
				
				
				
				
				<Route path='/certifications' element={<CertificationPageMain />} />
				
			</Routes>
			
			<MaybeQrLabelDialog />
			<MaybeQrScanDialog />
			
		</main>
	);
};


export default Main;
import { Stack } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { cx } from 'stuff';


interface ExpandIconProps
{
	is_expanded: boolean,
	handleToggle: (c : boolean) => void
}


const ExpandIcon = ({ is_expanded, handleToggle } : ExpandIconProps) =>
{
	return <div 
		className={cx('tree_junction_icon', {is_expanded: is_expanded})}
		onClick={e => handleToggle(!is_expanded)}
	>
		<Stack
			className='tree_leaf'
			sx={{
				height: '100%',
				justifyContent: 'center',
				marginTop:    is_expanded ?  '2px' : '',
				marginBottom: is_expanded ? '-2px' : '',
				marginLeft:   is_expanded ?  '1px' : '',
				marginRight:  is_expanded ? '-1px' : '',
			}}
		>
			<CorIcon
				of={is_expanded ? 'triangle_down' : 'triangle_right'}
				size='22px'
			/>
		</Stack>
	</div>
}


export default ExpandIcon;
import React, { useEffect, useState } from 'react';

import { Action } from 'models';
import ActionCard from './ActionCard';
import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';


const SearchableActionsCardList = ({
	actions,
} : {
	actions: Action[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			{
				actions?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((action: Action) => (
					<ActionCard key={action._id} action={action}/>
				))
			}
			<Pagination
				count={Math.ceil(actions.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default SearchableActionsCardList;

import { Box, Typography, Stack, Chip } from '@mui/material';

import SortableTable from '../../issueCollaboration/issueDetails/issueTable/SortableTable';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Part } from 'types';
import CorIcon from 'components/icons/CorIcon';



const PartsListTable =
({
	parts,
} : {
	parts: Part[],
}) =>
{
	return (
		<Box
			className='files_table_container'
		>
			
			<Typography
				variant='h6'
			>
				Parts
			</Typography>
			
			<SortableTable
				options={parts}
				onSelect={(selectedOption) => console.log('Download')}
				rows={[
					{
						title: 'Part Name',
						getCell(part) {
							return (
								<Typography>
									{part.name}
								</Typography>
							)
						}
					},
					{
						title: 'Available Quantity',
						getCell(part) {
							return (
								<Stack
									direction='row'
									alignItems='center'
									justifyContent='flex-start'
									sx={{ marginInline: '0.5rem' }}
									spacing={1}
								>
									<Typography
										variant='subtitle1'
										sx={{
											color: defaultAppTheme.palette.info.main
										}}
									>
										{part.count}
									</Typography>
									{
										(part?.count < 5) && (
											<Chip label='Low' variant='outlined' color='error'/>
										)
									}
								</Stack>
							);
						},
					},
					{
						title: 'Edit',
						getCell(part) {
							return (
								<></>
							)
						},
					},
				]}
			/>
			
		</Box>
	)
};


export default PartsListTable;
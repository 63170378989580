import { Control, UseFormSetValue } from 'react-hook-form';
import { ScheduleFormData } from '../../scheduling_types';
import { RepeatsEvery } from '../common/RepeatsEvery/RepeatsEvery';
import { LeftContainer, RightContainer } from '../common/styles';
import { BasedOn } from '../common/BasedOn/BasedOn';
import { RepeatTime } from '../common/RepeatTime/RepeatTime';
import { EffectiveDate } from '../common/EffectiveDate/EffectiveDate';
import { Divider, Stack } from '@mui/material';


export interface DailyProps
{
	control: Control<any, any>,
	setValue: UseFormSetValue<ScheduleFormData>,
}


export const Daily = ({ control, setValue }: DailyProps) =>
{
	return (
		<Stack
			direction='row'
			flexWrap='wrap'
			justifyContent='center'
			alignItems='center'
			gap={3}
		>
			<LeftContainer>
				<RepeatsEvery
					control={control}
					name='daily.repeats_every'
					isRequired={true}
					unitLabel='days'
				/>
				<BasedOn
					control={control}
					name='daily.based_on'
					defaultValue={'effective_date'}
					values={[
						{
							value: 'effective_date',
							label: 'Effective Date',
						},
						{
							value: 'last_completed_date',
							label: 'Last Completed Date',
						},
					]}
				/>
			</LeftContainer>
			
			<Divider/>
			
			<RightContainer>
				<RepeatTime
					control={control}
					setValue={setValue}
					defaultValue={new Date()}
				/>
				<EffectiveDate
					control={control}
					defaultValue={new Date()}
				/>
			</RightContainer>
			
		</Stack>
	);
};


export default Daily;
import { Input, styled, Box } from '@mui/material';

export const RepeatsEveryInput = styled(Input)(() => ({
	display: 'none',
}));

export const OutsideContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
}));

export const InsideContainer = styled(Box)(() => ({
	display: 'inline-flex',
	gap: '30px',
	marginInline: 'auto',
	alignItems: 'center',
}));

export const IconContainer = styled(Box)(() => ({
	width: '30px',
	height: ' 30px',
	borderRadius: '50%',
	backgroundColor: 'primary.light',
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
}));

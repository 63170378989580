import React, { useEffect, useState } from 'react';

import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';
import { Requisition } from 'models';
import RequisitionCard from './RequisitionCard';


const SearchableRequisitionsCardList =
({
	requisitions,
} : {
	requisitions: Requisition[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			{
				requisitions
					?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE)
					.map((requisition) => (
						<RequisitionCard
							key={requisition._id}
							requisition={requisition}
						/>
					))
			}
			
			<Pagination
				count={Math.ceil(requisitions.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px',
				}}
			/>
			
		</Stack>
	)
};


export default SearchableRequisitionsCardList;
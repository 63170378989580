import QRCode from 'qrcode.react';


//import helm from '../../app/data/HelmConnect - 2021-10-28.json';


type BasicQrLabelProps =
{
	line1a?: string,
	line1b?: string,
	line2?: string,
	line3?: string,
	payload: Object,
}


const BasicQrLabel =
({
	line1a,
	line1b,
	line2,
	line3,
	payload,
}
: BasicQrLabelProps ) =>
{
	
	
	return(
		<div
			id={`qr_code_label_${line1a}`}
			className='label_qr_code'
		>
			<div className='label_content'>
				
				<div className='label_text_content'>
					<div className='label_first_line'>
						<div className='label_target_family'>
							{line1a}
						</div>
						<div className='label_target_type'>
							{line1b}
						</div>
					</div>
					
					<div className='label_second_line'>
						{line2}
					</div>
					
					<div className='label_third_line'>
						{line3}
					</div>
				</div>
				
				<div className='label_image'>
					<div className='qr_code'>
						<QRCode
							value={JSON.stringify(payload)}
							renderAs={'svg'}
							className='qr_code_img'
						/>
					</div>
				</div>
				
			</div>
		</div>
	)
}


export default BasicQrLabel;
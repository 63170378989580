


// Provide an error object and this will display its message property with a retry button
const ErrorFallback =
({
	error,
	resetErrorBoundary,
} : {
	error: any,
	resetErrorBoundary: any
}) =>
{
	return (
		<div role='alert'>
			
			<p>
				Something went wrong:
			</p>
			<pre>
				{error.message}
			</pre>
			
			<button onClick={resetErrorBoundary}>
				Try again
			</button>
			
		</div>
	)
}


export default ErrorFallback;
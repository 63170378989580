import { Control, Controller } from 'react-hook-form';
import { Info, WeekdayNumbers } from 'luxon';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { RecurrenceFormData } from 'models';
import DaysOfTheWeekField from 'app/gizmos/fields/datetimes/DaysOfTheWeekField';


interface SpecificDaysProps
{
	control: Control<any, any>;
}


export const SpecificDays = ({ control }: SpecificDaysProps) =>
{
	// let weekday_numbers : WeekdayNumbers[] = [1, 2, 3, 4, 5, 6, 7];
	
	// let checkboxes = weekday_numbers.map(n =>
	// 	<WeekdayCheckBox
	// 		control={control}
	// 		weekday_number={n}
	// 		key={n}
	// 	/>
	// )
	
	
	return (
		<Box
			sx={{
				display: 'grid',
				// gridTemplateColumns: 'repeat(7, 1fr)',
				gap: '5px',
				marginTop: '1rem',
				flexWrap: 'nowrap',
			}}
		>
			<DaysOfTheWeekField />
		</Box>
	);
};













// type Name =
// 	`weekly.specific_days.weekly${RecurrenceType.SPECIFIC_DAYS}`;
// `weekly.specific_days.${keyof ScheduleFormData['weekly'][RecurrenceType.SPECIFIC_DAYS]}`;


interface SpecificDaysCheckBoxProps
{
	control: Control<RecurrenceFormData>,
	
	weekday_number: WeekdayNumbers,
}





// export const WeekdayCheckBox =
// ({
// 	control,
// 	weekday_number,
// }: SpecificDaysCheckBoxProps) =>
// {
// 	return (
// 		<FormControlLabel
// 			control={
// 				<Controller
// 					name={'weekly.weekday_numbers'}
// 					control={control}
// 					render={({ field: props }) => (
// 						<Checkbox
// 							{...props}
// 							checked={props.value?.includes(weekday_number)}
// 							onChange={(e) => props.onChange(
// 								(e.target.checked)
// 									? props.value.concat(weekday_number).sort()
// 									: props.value.filter(n => (n !== weekday_number))
// 							)}
// 						/>
// 					)}
// 				/>
// 			}
// 			label={Info.weekdays('short')[weekday_number]}
// 		/>
// 	)
	
	
// 	// return (
// 	// 	<Stack>
// 	// 		<Checkbox
// 	// 			defaultChecked={value}
// 	// 			value={value}
// 	// 			onChange={() => onChange}
// 	// 		/>
// 	// 	</Stack>
// 	// )
	
	
// 	// return (
// 	// 	<SingleContainer>
// 	// 		<Box>
// 	// 			{label}
// 	// 		</Box>
// 	// 		<Controller
// 	// 			control={control}
// 	// 			name={name}
// 	// 			shouldUnregister={false}
// 	// 			defaultValue={false}
// 	// 			render={({ field: { value, onChange } }) => {
// 	// 				return (
// 	// 					<Checkbox
// 	// 						defaultChecked={value}
// 	// 						value={value}
// 	// 						onChange={onChange}
// 	// 					/>
// 	// 				);
// 	// 			}}
// 	// 		/>
// 	// 	</SingleContainer>
// 	// );
// };


export default SpecificDays;
import cx from 'classnames';

// TODO: InlineIcon?
import { Icon as Iconify } from '@iconify/react';

import icon_set from './icon_set';


// Provide onClick if you want this icon to be clickable
// Set stopPropagation property (no value) to prevent click event propagation
// TODO: Test non-square icons and add support for alignment
// TODO: Add proper typing and refactor, clean up icon sets
// https://docs.iconify.design/icon-components/react/dimensions.html
const CorIcon =
({
	// Ex: 'play', 'robot'
	of,
	
	// If not provided, the only class will be 'icon'
	className = null,
	
	// Ex: 24px
	size = null,
	
	// Ex: 'red', '#f83'
	color = null,
	
	// Ex: 'pointer'
	cursor = null,
	
	// Provide a function to customize behavior
	onClick = null,
	
	// Provide true to prevent click event from propagating
	stopPropagation = null,
	
	// Number of 90 degree rotations (ex: 2 or '180deg')
	rotate = 0,
	
	// 5 ways to specify x/y flips
	flip   = false,
	flip_x = false,
	flip_y = false,
	hFlip  = false,
	vFlip  = false,
	
	style = {},
	
	...other
} : {
	style?: {[key: string]: any},
	
	[key: string]: any,
}) =>
{
	// Look up the specified icon, or use the fallback icon
	let icon = icon_set[of] || icon_set['unknown'];
	
	// Inline styling
	style.color = color;
	style.cursor = cursor;
	
	if(size)
	{
		style.fontSize   = size;
		style.width      = size;
		style.height     = size;
		style.lineHeight = '0px';
	}
	
	
	// We wrap the Iconify SVG with an i tag, to which we apply the classes and
	// handler and any other provided properties
	return (
		<i
			className={cx('icon', className)}
			style={style}
			onClick={onClick && ((e) => {
				onClick(e);
				if(stopPropagation)
					e.stopPropagation();
			})}
			{...other}
		>
			<Iconify
				icon={icon}
				rotate={rotate}
				hFlip={hFlip || flip_x || flip}
				vFlip={vFlip || flip_y}
			/>
			
		</i>
	)
}


export default CorIcon;
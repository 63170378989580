import { Control } from 'react-hook-form';

import { RecurrenceType } from 'models';

import { RepeatTypeChip, Container } from './styles';


// type Name = `recurrence.recurrence_type`;
// type Name = `${'weekly' | 'monthly'}.recurrence_type`;


export interface RepeatTypeSelectorProps
{
	// value: ControllerRenderProps<ScheduleFormData>['value'];
	value: any,
	
	onChange: Function;
	
	control: Control<any, any>,
}


export const RepeatTypeSelector =
({
	value,
	onChange,
}: RepeatTypeSelectorProps) =>
{
	return (
		<Container
			sx={{
				gap: 5,
			}}
		>
			<RepeatTypeChip
				onClick={() => onChange(RecurrenceType.SCHEDULE)}
				isSelected={value === RecurrenceType.SCHEDULE}
			>
				Specific Days
			</RepeatTypeChip>
			<RepeatTypeChip
				onClick={() => onChange(RecurrenceType.INTERVAL)}
				isSelected={value === RecurrenceType.INTERVAL}
			>
				Interval
			</RepeatTypeChip>
		</Container>
	);
};


export default RepeatTypeSelector;

import { YearlyDate } from 'models';

import { arrayOf, capitalizeFirst, commaAndJoin, conciseDateAndTime, ordinalNumber } from 'utils';
import { DateTime, Info } from 'luxon';
import { OnDemandRecurrenceType, RecurrenceEffectiveType, RecurrenceScheduleType, RecurrenceType } from './recurrence_enums';
import { Recurrence, Occurrence } from './recurrence_interfaces';
import { projectedOccurrences } from './recurrence_helpers3';







// TODO: Investigate why some DateTimes don't have prototype functions
export const ensureStupidDatetimeIsActuallyDatetime = (x: DateTime) : DateTime =>
{
	if(!x.toLocal)
	{
		console.log('Odd - valid DateTime missing prototype functions', x);
		
		let obj_with_timestamp = x as unknown as { ts: number };
		
		return DateTime.fromMillis(obj_with_timestamp.ts);
	}
	
	return x;
}



// TODO: Move elsewhere
export const ensureLocalDateTime = ( x : DateTime | string | undefined | null ) : DateTime =>
{
	if(DateTime.isDateTime(x))
	{
		if(!x.toLocal)
		{
			return ensureStupidDatetimeIsActuallyDatetime(x).toLocal();
		}
		
		return x.toLocal();
	}
	else if((x === undefined) || (x === null))
	{
		return DateTime.local();
	}
	else
	{
		return DateTime.fromISO(x).toLocal();
	}
}



// TODO: Move elsewhere
export const ensureUtcString = ( x : DateTime | string ) : string =>
{
	if(DateTime.isDateTime(x))
	{
		if(!x.toLocal)
		{
			return ensureStupidDatetimeIsActuallyDatetime(x).toUTC().toISO();
		}
		return x.toUTC().toISO();
	}
	else
	{
		return x;
	}
}



let sortYearlyDates = ( a: YearlyDate, b: YearlyDate ) =>
{
	return (a.month * 100 + a.day) -  (b.month * 100 + b.day)
};



// TODO: Any reason not to call projectedOccurrences directly?
// TODO: Review - does this ever need more than 1?
export const determineNextAutomationDatetime =
(
	recurrence: Recurrence,
	count: number = 1,
	relative_datetime: DateTime = DateTime.local(),
)
: DateTime | undefined =>
{
	// let next_automation_datetimes = determineRecurrenceDatetimes(recurrence, count, relative_datetime);
	
	let occurrences = projectedOccurrences(recurrence, count, relative_datetime);
	
	console.log({
		occurrences,
	})
	
	return occurrences.find((occurrence: Occurrence) =>
		(recurrence.last_automation_datetime !== undefined)
		&& (occurrence.generation_datetime > (recurrence?.last_automation_datetime))
	)?.generation_datetime;
	
	
	// let now = DateTime.utc();
	
	
	// return next_automation_datetimes.find((dt: DateTime) =>
	// 	dt > now
	// )
}



// TODO: Deprecated
// Wraps underlying projection logic, skipping first item if configured to do so
export const determineRecurrenceDatetimes =
(
	recurrence: Recurrence,
	count: number = 8,
	relative_datetime: DateTime = DateTime.local(),
)
: DateTime[] =>
{
	count = (recurrence.skip_first)
		? count + 1
		: count
	
	let next_dts = determineProspectiveDatetimes(recurrence, count, relative_datetime) as DateTime[];
	
	if((recurrence.skip_first))
	{
		next_dts = next_dts.slice(1);
	}
	
	
	let effective_type = recurrence.effective_type;
	let effective_until_datetime = ensureLocalDateTime(recurrence?.effective_until_datetime);
	
	if(effective_type === RecurrenceEffectiveType.EFFECTIVE_BETWEEN)
	{
		next_dts = next_dts.filter(dt =>
			// (dt >= relative_datetime)
			// &&
			(dt.startOf('day') <= effective_until_datetime.startOf('day'))
		);
	}
	
	
	return next_dts;
}



// TODO: Deprecated
// TODO: Cover all cases
export const determineProspectiveDatetimes =
(
	recurrence: Recurrence,
	count: number = 8,
	relative_datetime: DateTime = DateTime.local(),
)
: DateTime[] =>
{
	let schedule = recurrence.recurrence_schedule;
	let on_demand_type = recurrence.on_demand_type;
	
	
	// TODO: When viewing, ensure this is a Datetime
	// When does this template take effect? Sometime in the future?
	let effective = ensureLocalDateTime(recurrence.effective_datetime);
	
	// Take the relative or effective datetime, whichever is further in the future
	// This datetime will be the basis of comparisons
	let basis = (effective < relative_datetime)
		? relative_datetime
		: effective
	
	basis = basis.toLocal();
	
	// if(effective_type === RecurrenceEffectiveType.EFFECTIVE_BETWEEN)
	// {
	// 	basis = (basis > effective_until_datetime)
	// 		? basis
	// 		: effective_until_datetime
	// }
	
	
	// TODO: Should this really be applied to the basis? May interfere with checks later
	// if(recurrence.time_of_day)
	// {
	// 	basis = basis.set({
	// 		hour: recurrence.time_of_day.hour,
	// 		minute: recurrence.time_of_day.minute,
	// 		second: recurrence.time_of_day.second || 0,
	// 		millisecond: recurrence.time_of_day.millisecond || 0,
	// 	})
	// }
	
	
	if(schedule === RecurrenceScheduleType.ON_DEMAND)
	{
		if(on_demand_type === OnDemandRecurrenceType.SCHEDULE)
		{
			if(recurrence.specific_dates)
			{
				return recurrence.specific_dates.map(x =>
					ensureLocalDateTime(x.datetime)
				).sort()
			}
			
			return [];
		}
		else if(on_demand_type === OnDemandRecurrenceType.TRIGGER)
		{
			// TODO: Could tie in to ComponentRecord average and projected trigger datetime
			return [];
		}
	}
	
	
	
	// Check for intervals first since they're simple -   they'll be tangly schedules
	if(recurrence.recurrence_type === RecurrenceType.INTERVAL)
	{
		if(recurrence.interval === undefined)
		{
			return [];
		}
		
		if(schedule === RecurrenceScheduleType.DAILY)
		{
			if(recurrence.interval.days === 0)
			{
				return [];
			}
			return arrayOf(count, (i: number) => basis.plus({
				days: i * (recurrence.interval?.days || 0)
			}))
		}
		if(schedule === RecurrenceScheduleType.WEEKLY)
		{
			if(recurrence.interval.weeks === 0)
			{
				return [];
			}
			return arrayOf(count, (i: number) => basis.plus({
				weeks: i * (recurrence.interval?.weeks || 0)
			}))
		}
		if(schedule === RecurrenceScheduleType.MONTHLY)
		{
			if(recurrence.interval.months === 0)
			{
				return [];
			}
			return arrayOf(count, (i: number) => basis.plus({
				months: i * (recurrence.interval?.months || 0)
			}))
		}
		if(schedule === RecurrenceScheduleType.YEARLY)
		{
			if(recurrence.interval.years === 0)
			{
				return [];
			}
			return arrayOf(count, (i: number) => basis.plus({
				years: i * (recurrence.interval?.years || 0)
			}))
		}
	}
	
	
	
	// TODO: Support multiple times per day?
	if(schedule === RecurrenceScheduleType.DAILY)
	{
		return arrayOf(count, (i: number) => basis.plus({ days: i }))
	}
	
	
	
	if(schedule === RecurrenceScheduleType.WEEKLY)
	{
		let weekdays = recurrence.weekly?.weekday_numbers.filter(Boolean);
		
		if(weekdays === undefined || recurrence.time_of_day === undefined || weekdays.length === 0)
		{
			return [];
		}
		
		
		let index_of_basis_weekday = weekdays.findIndex(x =>
			(x === basis.weekday)
		);
		
		let weekdays_before = weekdays.slice(0, index_of_basis_weekday);
		let weekdays_after  = weekdays.slice(index_of_basis_weekday);
		
		
		let reordered_weekdays = weekdays_after.concat(weekdays_before);
		
		
		let dts = []
		let num_to_go = count - dts.length;
		
		for(let i = 0; i < num_to_go; i++)
		{
			let week_offset = Math.floor((weekdays_before.length + i) / weekdays.length);
			let days_offset = (i) % weekdays.length;
			
			dts.push(
				basis.set({
					weekday: reordered_weekdays[days_offset],
				}).plus({
					weeks: week_offset,
				})
			)
		}
		
		console.log({
			index_of_basis_weekday,
			weekdays,
			weekdays_before,
			weekdays_after,
			reordered_weekdays,
			dts,
		})
		
		return dts;
	}
	
	
	
	if(schedule === RecurrenceScheduleType.MONTHLY)
	{
		if(recurrence.monthly?.day_numbers === undefined)
		{
			return [];
		}
		
		if(recurrence.monthly?.day_numbers?.length > 0)
		{
			let day_nums = recurrence.monthly?.day_numbers;
			
			
			// Finds the first day number that's bigger than/equal to the current day
			// This will be the first day we project
			let index = day_nums.findIndex(day_num => {
				return (
					(day_num >= basis.day)
				)
			});
			
			// If no days are greater, the first projected date will use the earliest day number
			index = (index === -1)
				? 0
				: index;
			
			
			let months_offset = 0;
			
			
			let first_is_today = (day_nums[0] === basis.day);
			
			
			let projected_dts = arrayOf(count, (i: number) =>
			{
				let looping_index = (index + i) % day_nums?.length;
				
				if((looping_index === 0) && (!first_is_today))
				{
					months_offset++;
				}
				
				first_is_today = false;
				
				let new_dt = basis.plus({
					months: months_offset,
				})
				
				let new_day_num = day_nums?.[looping_index];
				let last_day_of_month = new_dt.endOf('month').day;
				
				if(new_day_num > last_day_of_month)
				{
					new_day_num = last_day_of_month;
				}
				
				return new_dt.set({
					day: new_day_num,
				})
			})
			
			return projected_dts;
		}
		return [];
	}
	
	
	
	if(schedule === RecurrenceScheduleType.YEARLY)
	{
		let yearly_dates = structuredClone(recurrence.yearly?.yearly_dates).sort(sortYearlyDates);
		
		
		if((yearly_dates === undefined) || (yearly_dates.length === 0))
		{
			return []
		}
		
		console.log({
			yearly_dates,
		})
		
		let index_of_first_yearly_date_after = yearly_dates?.findIndex((x : YearlyDate) =>
			(x.month > basis.month)
			||
			(
				(x.month === basis.month)
				&&
				(
					(x.day > basis.day)
					||
					(
						(x.day === basis.day)
						&&
						(
							(basis.hour < (recurrence.time_of_day?.hour || 0))
							||
							(
								(basis.hour === (recurrence.time_of_day?.hour || 0))
								&&
								(basis.minute < (recurrence.time_of_day?.minute || 0))
							)
						)
					)
				)
			)
		);
		
		
		let yearly_dates_before = yearly_dates.slice(0, index_of_first_yearly_date_after);
		let yearly_dates_after  = yearly_dates.slice(index_of_first_yearly_date_after);
		let reordered_yearly_dates = yearly_dates_after.concat(yearly_dates_before);
		
		if(index_of_first_yearly_date_after === -1)
		{
			yearly_dates_before = reordered_yearly_dates;
			yearly_dates_after  = [];
			reordered_yearly_dates = yearly_dates_after.concat(yearly_dates_before);
		}
		
		
		console.log({
			index_of_first_yearly_date_after,
			yearly_dates_before,
			yearly_dates_after,
			reordered_yearly_dates,
		})
		
		let dts = []
		let num_to_go = count - dts.length;
		
		for(let i = 0; i < num_to_go; i++)
		{
			let years_offset = Math.floor((yearly_dates_before.length + i) / yearly_dates.length);
			let days_offset = (i) % yearly_dates.length;
			
			let new_dt = basis.set({
				month: reordered_yearly_dates[days_offset].month,
				day: reordered_yearly_dates[days_offset].day,
			}).plus({
				years: years_offset,
			});
			
			// console.log({
			// 	years_offset,
			// 	days_offset,
			// 	iso: new_dt.toISO(),
			// 	new_dt,
			// })
			
			dts.push(new_dt)
		}
		
		return dts;
	}
	
	
	return [];
}
































export const describeRecurrence = ( recurrence: Recurrence, long: boolean = false ) =>
{
	// 'daily, from now on'
	// 'every 3rd day, from now on'
	
	// 'on Apr 24, 2025'
	
	
	// 'every week, starting Jul 31'
	
	// 'on Mondays and Tuesdays, from Apr 1 until Sept 1'
	// 'every 2nd Sunday, starting Mar 23'
	// 'every 2nd Sunday, from Mar 23 until Jan, 2023'
	
	
	// 'every 6th month, from now on, skipping first'
	
	// 'on the 2nd 9th and 20th of each month, from now on'
	
	
	// 'every 4th year, on Jun 10, from now on'
	
	// 'every Jan 1, after Jun 15, 1988'
	// 'every Jan 1, after Jun 15, 1988, retroactively'
	// 'every Jan 1, effective Jun 15, 1988, from now on'
	
	
	let parts = [];
	
	
	
	let schedule = recurrence.recurrence_schedule;
	
	
	let specific_dates = recurrence.specific_dates || [];
	
	let is_on_demand = (schedule === RecurrenceScheduleType.ON_DEMAND)
	let is_on_demand_scheduled = is_on_demand
		&& (recurrence.on_demand_type === OnDemandRecurrenceType.SCHEDULE)
		&& (specific_dates.length > 0)
	
	let is_effective_between = (recurrence.effective_type === RecurrenceEffectiveType.EFFECTIVE_BETWEEN);
	
	
	
	// Ex: 'on scheduled dates: Jan 1, 2025 and Feb 2, 2026', 'when triggered by a component record/reading'
	if(schedule === RecurrenceScheduleType.ON_DEMAND)
	{
		if(recurrence.on_demand_type === OnDemandRecurrenceType.SCHEDULE)
		{
			if(specific_dates.length > 0)
			{
				parts.push(
					'for '
					+ specific_dates.map(x =>
						conciseDateAndTime(x.datetime)
					).join(', ')
					+ ' (and when triggered by a component record)'
				)
			}
			else
			{
				parts.push('when triggered by a component record');
			}
		}
		else
		{
			parts.push('when triggered by a component record');
		}
	}
	
	
	
	// Ex: 'every week', 'every 2 years'
	else if((recurrence.recurrence_type === RecurrenceType.INTERVAL) && recurrence.interval)
	{
		let pieces = [];
		
		
		if(schedule === RecurrenceScheduleType.DAILY)
		{
			if(recurrence.interval.days === 1)
			{
				pieces.push('day')
			}
			else
			{
				pieces.push(recurrence.interval.days + ' days')
			}
		}
		if(schedule === RecurrenceScheduleType.WEEKLY)
		{
			if(recurrence.interval.weeks === 1)
			{
				pieces.push('week')
			}
			else
			{
				pieces.push(recurrence.interval.weeks + ' weeks')
			}
		}
		if(schedule === RecurrenceScheduleType.MONTHLY)
		{
			if(recurrence.interval.months === 1)
			{
				pieces.push('month')
			}
			else
			{
				pieces.push(recurrence.interval.months + ' months')
			}
		}
		if(schedule === RecurrenceScheduleType.YEARLY)
		{
			if(recurrence.interval.years === 1)
			{
				pieces.push('year')
			}
			else
			{
				pieces.push(recurrence.interval.years + ' years')
			}
		}
		
		parts.push('every ' + pieces.join(' '))
	}
	
	
	
	
	else if((recurrence.recurrence_type === RecurrenceType.SCHEDULE))
	{
		// Ex: 'on Jan 20, 2025'
		if(schedule === RecurrenceScheduleType.DAILY)
		{
			parts.push('every day');
		}
		// Ex: 'every Wednesday', 'every Mon, Tue and Sat'
		else if((schedule === RecurrenceScheduleType.WEEKLY) && recurrence.weekly)
		{
			let weekdays = recurrence.weekly.weekday_numbers.filter(Boolean);
			
			if(weekdays.length > 0)
			{
				if(weekdays.length === 1)
				{
					parts.push('every ' + Info.weekdays()[weekdays[0] - 1])
				}
				else
				{
					let all_weekday_names = Info.weekdays('short');
					
					let weekday_words = weekdays.map(x =>
						all_weekday_names[x - 1] as string
					)
					
					parts.push('every ' + commaAndJoin(...weekday_words))
				}
			}
		}
		// Ex: 'every month on the 4th, 8th and 26th'
		else if((schedule === RecurrenceScheduleType.MONTHLY) && recurrence.monthly?.day_numbers)
		{
			let ordinals = recurrence.monthly.day_numbers.map(x => ordinalNumber(x));
			
			if(ordinals.length > 0)
			{
				parts.push('every month on the ' + commaAndJoin(...ordinals))
			}
		}
		// Ex: 'every year on Jan 1st and Feb 15th'
		else if((schedule === RecurrenceScheduleType.YEARLY) && recurrence.yearly?.yearly_dates)
		{
			if(recurrence.yearly.yearly_dates.length > 0)
			{
				let all_month_names = Info.months('short');
				
				let ordinal_dates = structuredClone(recurrence.yearly.yearly_dates)
					.sort(sortYearlyDates)
					.map( ( x : YearlyDate ) =>
						all_month_names[x.month - 1] + ' ' + ordinalNumber(x.day)
					);
				
				parts.push('every year on ' + commaAndJoin(...ordinal_dates))
			}
		}
	}
	
	
	
	
	
	// Time due
	if((!is_on_demand || is_on_demand_scheduled) && recurrence?.time_of_day?.minute)
	{
		let minutes = (recurrence?.time_of_day?.minute < 10)
			? '0' + (recurrence?.time_of_day?.minute)
			: (recurrence?.time_of_day?.minute);
		
		parts.push('due at ' + recurrence?.time_of_day?.hour + ':' + minutes);
	}
	
	
	
	// Effective time window
	let pieces = [];
	
	if(recurrence.effective_until_datetime && (is_effective_between || is_on_demand))
	{
		pieces.push('(from ' + conciseDateAndTime(recurrence.effective_datetime));
		
		let effective_end_local = ensureLocalDateTime(recurrence.effective_until_datetime);
		
		if(effective_end_local.isValid)
		{
			pieces.push('until ' + conciseDateAndTime(effective_end_local) + ')');
		}
	}
	else
	{
		if(recurrence.effective_datetime)
		{
			pieces.push('(starting ' + conciseDateAndTime(recurrence.effective_datetime) + ')');
		}
	}
	
	parts.push(pieces.join(' '));
	
	
	
	
	
	return capitalizeFirst(((long) ? 'Scheduling work orders ' : '') + parts.join(' - '));
}




























import { Group } from '@visx/group';
import { HierarchyPointNode } from '@visx/hierarchy/lib/types';

import { TreeNode } from '../tree_models';


export type TreeNodeContentProps =
{
	node: HierarchyPointNode<TreeNode>,
	has_value: boolean,
	has_hidden_children: boolean,
};


export default function TreeNodeContent({
	node,
	has_value,
	has_hidden_children,
}
: TreeNodeContentProps)
{
	return (
		<Group>
			<text
				dy={(has_value) ? '-.2em' : '.33em'}
				fontSize={(has_value) ? '14' : '16'}
				fontFamily='Arial'
				textAnchor='middle'
				fontWeight='400'
				stroke={(node.children || has_hidden_children) ? '#000' : '#666'}
				style={{
					pointerEvents: 'none',
					userSelect: 'none',
				}}
				// fill={node.depth === 0 ? '#71248e' : node.children ? 'white' : '#26deb0'}
			>
				{node.data.label}
			</text>
			{
				(has_value)
				&&
				<Group>
					<text
						dy='0.95em'
						fontSize={14}
						fontFamily='Arial'
						fontWeight={400}
						textAnchor='middle'
						style={{
							pointerEvents: 'none',
							userSelect: 'none',
						}}
						stroke='#000'
					>
						{node.data.value}
						<tspan
							style={{
								pointerEvents: 'none',
								userSelect: 'none',
								fill:   '#0003',
								stroke: '#0003',
								marginLeft: '6px',
							}}
							dx='3'
						>
							{node.data.units}
						</tspan>
					</text>
				</Group>
			}
		</Group>
	);
}
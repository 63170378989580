import { Typography } from '@mui/material';
import { OnDemandRecurrenceType, RecurrenceScheduleType } from 'models';
import { useFormContext } from 'react-hook-form';
import OnDemandScheduleFormlet from './schedule/OnDemandScheduleFormlet';


export const OnDemandFormlet = () =>
{
	const form_context = useFormContext();
	
	const recurrence_schedule = form_context.watch('recurrence.recurrence_schedule') as RecurrenceScheduleType;
	
	const on_demand_type = form_context.watch('recurrence.on_demand_type') as OnDemandRecurrenceType;
	
	
	if(
		(recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
	)
	{
		return null;
	}
	
	
	if(on_demand_type === OnDemandRecurrenceType.TRIGGER)
	{
		return (
			<Typography
				variant='body1'
				sx={{
					textAlign: 'center',
					marginBottom: 2,
				}}
			>
				After this template has been created, you can configure a component record to trigger a work order when a reading reaches a set threshold.
			</Typography>
		)
	}
	
	
	return (
		<OnDemandScheduleFormlet />
	);
	
}


export default OnDemandFormlet;
import { Button, Stack, Typography } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import InstantNotificationPopup from 'app/dashboard/nav/notifications/InstantNotificationPopup';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import AttachFilesButton from 'components/buttons/AttachFilesButton';
import React, { useState } from 'react'
import { useWorkOrder } from '../../WorkOrderProvider';
import WorkOrderCostCenters from './WorkOrderCostCenters';
import { WorkOrderStatus } from 'models';
import WorkOrderCostCodes from './WorkOrderCostCodes';


const WorkOrderCosts = () =>
{
	const [ fireAlert, setFireAlert ] = useState<boolean>(false);
	
	const { work_order, updateActualCost, uploadFiles } = useWorkOrder();
	
	const { estimated_cost } = work_order;
	
	
	const handleSubmit = () =>
	{
		updateActualCost();
		setFireAlert(true);
	}
	
	
	return (
		<Stack
			direction='column'
			spacing={2}
		>
			<Typography variant='subtitle1'>
				Report Costs & associated Cost Centers with the Work Order
			</Typography>
			
			<InfoHighlight
				severity='warning'
				message_main={`${estimated_cost?.quantity || 'Not Provided'} ${estimated_cost?.currency || ''}`}
				message_context='Estimated Cost'
			/>
			
			<WorkOrderCostCenters />
			
			<WorkOrderCostCodes />
			
			<CostFormlet
				name='actual_cost'
				label='Report Cost'
				help='Tip: You need to add Cost Centers before reporting final costs'
			/>
			
			
			<Stack
				direction='row'
				spacing={1}
				alignItems='center'
				justifyContent='flex-end'
			>
				<AttachFilesButton
					fileChangeHandler={uploadFiles}
					button_text='Upload Receipts'
					button_variant='outlined'
				/>
				
				<Button
					onClick={handleSubmit}
					variant='contained'
					disabled={
						(work_order?.status === WorkOrderStatus.COMPLETED)
						|| !(work_order?.cost_center_ids?.length > 0)
					}
				>
					Report Cost
				</Button>
			</Stack>
			
			<InstantNotificationPopup
				open={fireAlert}
				setOpen={setFireAlert}
				severity={'success'}
				message='Cost updated successfully'
			/>
		</Stack>
	)
};


export default WorkOrderCosts;
import CollectionConnection from './CollectionConnection';


// Reads an existing document
// Calls the provided function afterwards
// If the document isn't found, calls the second provided function
const read = async (collection_name, id, fn, error_fn) =>
{
	// // This allows read() to delegate reading entire collections to the appropriate function
	// // TODO: Consider splitting into read, readOne, readAll
	// if(typeof id === 'function')
	// {
	// 	readCollection(collection_name, id);
		
	// 	return;
	// }
	
	
	// const docRef = doc(db, collection_name, id);
	
	// const docSnap = await getDoc(docRef);
	
	// if(docSnap.exists())
	// {
	// 	console.log('🔥 Read', id, 'from', collection_name, docSnap.data());
		
	// 	if(typeof fn === 'function')
	// 	{
	// 		fn(docSnap.data(), docSnap);
	// 	}
	// }
	// else
	// {
	// 	if(typeof error_fn === 'function')
	// 	{
	// 		error_fn(docSnap);
	// 	}
	// 	else
	// 	{
	// 		console.log('🔥 Document not found!', collection_name, id);
	// 	}
	// }
}



// Writes a new or existing document
// Calls the provided function afterwards
const write = async (collection_name, id, values, fn) =>
{
	// const collection_ref = collection(db, collection_name);
	
	// await setDoc(
	// 	doc(collection_ref, id),
	// 	values
	// );
	
	// console.log('🔥 Wrote', id, 'to', collection_name, values);
	
	// if(typeof fn === 'function')
	// {
	// 	fn();
	// }
}



// Writes a new or existing thingy
const writeThingy = async (thingy, fn) =>
{
	write(
		thingy.collection,
		thingy._id,
		thingy.values,
		fn
	)
}



// Reads a snapshot of a collection.
// Calls the provided function with the resulting list of items.
const readCollection = async (collection_name, fn) =>
{
	// const querySnapshot = await getDocs(collection(db, "cities"));
	
	// let items = snap2array(querySnapshot);
	
	// console.log('🔥 Read', querySnapshot.size, 'items from', collection_name, items);
	
	// if(typeof fn === 'function')
	// {
	// 	fn(items, querySnapshot);
	// }
}






const connections_by_name = {};



const watch = (collection_name, watcher_id, dispatch) =>
{
	let connection = connections_by_name[collection_name] ||
		new CollectionConnection(collection_name, dispatch);
	
	connection.addWatcher(watcher_id);
	
	connections_by_name[collection_name] = connection;
}


// TEST
const stopWatching = (collection_name, watcher_id) =>
{
	let connection = connections_by_name[collection_name];
	
	connection.removeWatcher(watcher_id);
}



const fuego =
{
	read: read,
	write: write,
	writeThingy: writeThingy,
	readCollection: readCollection,
	
	watch: watch,
	stopWatching: stopWatching,
	
	connections_by_name: connections_by_name,
}



export default fuego;
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputType, ResponseField } from 'types/forms';

import RadioMUI from '@mui/material/Radio';
import { Box, FormControlLabel, RadioGroup } from '@mui/material';

import { WorkOrderResponseForm } from 'types/forms';


export interface RadioProps
{
	field: ResponseField & {
		type: FormInputType.Radio,
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const Radio = ({ field, sectionIndex, fieldIndex }: RadioProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	const options = field.value;
	
	
	return (
		<Box>
			<Controller
				control={control}
				name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.selectedValue`}
				defaultValue=''
				render={({ field: { value, onChange } }) => (
					<RadioGroup
						value={value}
						onChange={(e) => onChange(e.target.value)}
					>
						{
							options.map((option, index) =>
								<FormControlLabel
									key={index}
									control={<RadioMUI value={option.label} />}
									label={option.label}
								/>
							)
						}
					</RadioGroup>
				)}
			/>
		</Box>
	);
};


export default Radio;
import { React, useAuthData, useState } from 'stuff';
import { useCollection, useWrite } from 'ember';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CircularProgress, MenuItem, Select, Stack } from '@mui/material';

import { Organization } from 'types';
import InstantNotificationPopup from 'app/dashboard/nav/notifications/InstantNotificationPopup';


const InviteUsers = () =>
{
	const [ emails, setEmails ] = useState<string[]>([]);
	const [ fireAlert, setFireAlert ] = useState<boolean>(false);
	const [ invited_into_organization_id, setInvitedIntoOrganizationId ] = useState<string>();
	
	
	const write = useWrite();
	
	const { claims } = useAuthData();
	
	
	const organizations_collection = useCollection(`organizations`);
	
	if(organizations_collection.is_loading) return <CircularProgress />
	
	
	const organizations = organizations_collection?.data as Organization[];
	
	
	
	const handleOnOrganizationSelect = (e) =>
	{
		console.log(e.target.value)
		setInvitedIntoOrganizationId(e.target.value);
	}
	
	
	
	
	
	const sendInvite = async (event?: React.SyntheticEvent | Event, emails?: Array<string>) =>
	{
		event.preventDefault();
		console.log(emails);
		
		const organization_name = organizations.filter(org => {
			
			if(invited_into_organization_id)
			{
				return org._id === invited_into_organization_id
			}
			else
			{
				return org._id === claims?.organization_id 
			}
		})[0].name;
		
		
		//const invitesRef = collection(db, 'invites');
		
		emails.forEach(async (email) =>
		{
			console.log('EMAIL', email);
			
			const cleaned_email = email.toLowerCase().trim();
			
			write('invites', {
				invited_user_email: cleaned_email,
				invited_by_user_id: claims?.user_id,
				invited_by_user_email: claims?.email,
				invited_by_user_name: claims?.name,
				invited_into_organization_id: invited_into_organization_id || claims?.organization_id,
				invited_into_organization_name: organization_name,
			});
		});
		
		
		setFireAlert(true);
	};
	
	
	
	const addEmailValues = (event, newValue) =>
	{
		console.log('NEW VALUE', newValue);
		
		setEmails(newValue);
	};
	
	
	
	const options: string[] = ['Press Enter after typing in each email'];
	
	
	
	return (
		<Box className='container'>
			<Typography 
				variant='h6'
			>
				Invite Users
			</Typography>
			
			<FormControl
				sx={{
					width: '100%',
				}}
			>
				
				<Stack
					direction='row'
					alignItems='flex-start'
					justifyContent='flex-start'
					width='100%'
					gap={3}
					sx={{
						marginTop: '10px'
					}}
				>
					<Autocomplete
						multiple
						id='emails-filled'
						options={options}
						freeSolo
						onChange={(event, newValue) => addEmailValues(event, newValue)}
						renderTags={(value, getTagProps) =>
							value.map((option, index) =>
							{
								return (
									<Chip
										key={index}
										variant='outlined'
										label={option}
										{...getTagProps({ index })}
									/>
								);
							})
						}
						renderInput={(params) => (
							<TextField
								{...params}
								variant='outlined'
								label='Email'
								placeholder='e.g. john@corsphere.com (press enter to add emails)'
								helperText='Press Enter after typing in each email'
							/>
						)}
						sx={{
							width: '50%'
						}}
					/>
					
					{
						claims?.is_super_admin && (
							<Select
								labelId='organization_select_label'
								defaultValue=''
								value={invited_into_organization_id}
								onChange={handleOnOrganizationSelect}
								label='Organization'
								sx={{ width: '30%' }}
							>
								{
									organizations?.map(organization => <MenuItem
											key={organization._id}
											value={organization._id}
										>
											{ organization.name }
										</MenuItem>
									)
								}
							</Select>
						)
					}
				</Stack>
				<Button
					variant='contained'
					className='email_button'
					endIcon={<SendIcon />}
					onClick={(e) => sendInvite(e, emails)}
					sx={{
						marginTop: '10px',
					}}
					disabled={!emails.length}
				>
					Send
				</Button>
			</FormControl>
			
			<InstantNotificationPopup
				open={fireAlert}
				setOpen={setFireAlert}
				severity={'success'}
				message='We will send you a confirmation when the email is sent successfully.'
			/>
		</Box>
	)
};


export default InviteUsers;
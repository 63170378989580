import { useState, useEffect, useCallback } from 'react';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import axios from 'axios';

import { Button } from '@mui/material';

import { useMapData } from './MapDataProvider';
import TripAnalysisCard from './TripAnalysisCard';
import { color_palette } from './routes/route_utils';


const MapControlPanel = () =>
{
	const { 
		// ports,
		trip_request,
		setTripRequest,
		trip_responses,
		setTripResponses,
	} = useMapData();
	
	//console.log('ALL PORTS', ports);
	
	
	
	// This can also be an async getter function. See notes below on Async Urls.
	
	// 9001 => Shortest Path
	// 9002 => Instructed Speed
	// 9003 => Recommended Speed
	const socketUrl = 'ws://localhost:9002';
	
	
	const [ message_history, setMessageHistory ] = useState([]);
	const [ json_message_history, setJsonMessageHistory ] = useState([]);
	
	const [ trip_ready, setTripReady ] = useState(null);
	
	
	const {
		sendJsonMessage,
		lastMessage,
		lastJsonMessage,
		readyState,
	} =
		useWebSocket(
			socketUrl,
			{
				onOpen: () => console.log('opened'),
				// Will attempt to reconnect on all close events, such as server shutting down
				shouldReconnect: (closeEvent) => true,
			}
		);
	
	useEffect(() =>
	{
		if(trip_ready !== null)
		{
			setTripResponses([
				...trip_responses,
				trip_ready,
			]);
		}
	}, [ setTripResponses, trip_ready, trip_responses ]);
	
	
	
	
	useEffect(
		() =>
		{
			if(lastJsonMessage !== null)
			{
				console.log(lastJsonMessage);
				
				setJsonMessageHistory(prev => prev.concat(lastJsonMessage));
			}
		},
		[lastJsonMessage, setJsonMessageHistory]
	);
	
	
	useEffect(
		() =>
		{
			if(lastMessage !== null)
			{
				setMessageHistory(prev => prev.concat(lastMessage));
			}
		},
		[lastMessage, setMessageHistory]
	);
	
	
	
	const getPathData = async (json_message_history) =>
	{
		console.log('WHAT IS THIS', json_message_history);
		
		try
		{
			const all_res_data = [];
			
			json_message_history.forEach(async (message) =>
			{
				if(message?.downloadUrl)
				{
					const resp = await axios.get(message.downloadUrl)
					
					let data = resp.data;
					
					all_res_data.push(data);
					
					setTripReady(data);
					
					console.log({ data });
				}
				else
				{
					console.log('No Request Message Sent!')
				}
			})
		}
		catch (err)
		{
			console.error(err);
		}
	};
	
	
	useEffect(
		() =>
		{
			if(json_message_history.length === 3)
			{
				getPathData(json_message_history);
			}
			
		},
		[json_message_history]
	);
	
	
	
	const handleClickSendMessage = useCallback(
		() =>
		{
			console.log('Sending message', trip_request);
			
			trip_request.forEach((trip) => {
				sendJsonMessage(trip)
			});
		},
		[ sendJsonMessage, trip_request ]
	);
	
	
	const connection_status =
	{
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	}[readyState];
	
	
	// console.log({message_history, json_message_history});
	
	
	
	//Setting Trip Ports
	const setTripPorts = (event, value) =>
	{
		console.log(value);
		setTripRequest({
			...trip_request,
			[trip_request.points]: [
				...trip_request.points,
				value,
			]
		})
	};
	
	// console.log('YOOO HOW MANY ARE THERE', trip_responses);
	
	
	
	
	return (
		<div>
			<div className='route_optimization_demo'>
				Route Optimization Demo
			</div>
			
			{/* <div>
				
				<div>Set Way Points</div>
				
				<div>
					<Autocomplete
						disablePortal
						size='small'
						id="start_port"
						sx={{ width: 400 }}
						renderInput={(params) => <TextField {...params} label="Start Port" />}
						options={ports}
						getOptionLabel={(option) => `${option.properties.name}, ${option.properties.terminal}`}
						renderOption={(props, option) =>
							{
								return (
									<li {...props} key={option.properties.id}>
										{option.properties.name}, {option.properties.terminal}
									</li>
								)
							}
						}
						onChange={(event, newValue) => setTripPorts(event, newValue)}
					/>
					
					<Autocomplete
						disablePortal
						size='small'
						id="end_port"
						sx={{ width: 400 }}
						renderInput={(params) => <TextField {...params} label="End Port" />}
						options={ports}
						getOptionLabel={(option) => `${option.properties.name}, ${option.properties.terminal}`}
						renderOption={(props, option) =>
							{
								return (
									<li {...props} key={option.properties.id}>
										{option.properties.name}, {option.properties.terminal}
									</li>
								)
							}
						}
						onChange={(event, newValue) => setTripPorts(event, newValue)}
					/>
				</div>
				
				<div>
					
					<TextField
						label='Instructed Speed'
						size='small'
						id='speed'
						type='number'
						onChange={(e) => {
							setTripRequest({
								...trip_request,
								speed: e.target.value,
							})
						}}
					/>
					
				</div>
				
			</div>
			
			
			<div>
				
				<div>Vessel Parameters</div>
				
				<div>
					Vessel Size
				</div>
				
				<div>
					Fuel Curve
				</div>
				
				<div>
					RPM Curve
				</div>
				
			</div> */}
			
			<Button
				onClick={handleClickSendMessage}
				disabled={readyState !== ReadyState.OPEN}
				variant='contained'
				sx={{
					marginBottom: '10px',
				}}
			>
				Run Model (Fuel Optimization)
			</Button>
			
			<div className='trips_card_section'>
				{
					trip_responses.map((trip, i) =>
					{
						return (
							<div key={i}>
								<TripAnalysisCard
									trip={trip}
									color={color_palette[i]}
								/>
							</div>
						)
					})
				}
			</div>
			
		</div>
	)
};


export default MapControlPanel;
import { Stack, Typography } from '@mui/material';
import { useWorkOrderTemplates } from 'app/providers/WorkOrderTemplatesLoader';
import WorkOrderTemplatesFormlet from '../fields/work_orders/WorkOrderTemplatesFormlet';


// Accept currently selected?
export const WorkOrderTemplatePicker =
({
	name,
} : {
	name?: string,
}) =>
{
	const { work_order_templates } = useWorkOrderTemplates();
	
	
	if(!work_order_templates?.length)
	{
		return <Stack>
			<Typography
				sx={{
					textAlign: 'center',
					marginTop: 1,
				}}
			>
				No work order templates associated with this component.
				If you create one, you can configure it to trigger when a threshold is reached.
			</Typography>
		</Stack>
	}
	
	
	return (
		<Stack>
			<WorkOrderTemplatesFormlet
				name={name}
			/>
		</Stack>
	)
}


export default WorkOrderTemplatePicker;
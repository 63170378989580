import { Box, Stack, Typography } from '@mui/material';
import ComponentInfoCard from '../../ComponentInfoCard';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import CorIcon from 'components/icons/CorIcon';
import ComponentRecordForm from 'app/gizmos/component_record/ComponentRecordForm';
import ComponentRecordsList from './ComponentRecordsList';
import { useComponent } from 'app/providers/ComponentLoader';


const ComponentRecordsPageContent = () =>
{
	const { component } = useComponent();
	
	
	return (
		<Box
			sx={{
				width: '100%',
				marginBottom: '30px'
			}}
		>
			<Stack
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				width='90%'
				spacing={2}
				sx={{
					margin: '10px',
				}}
			>
				
				<Typography
					variant='h3'
				>
					Component Records
				</Typography>
				
				<ComponentInfoCard />
				
				<ComponentRecordsList />
				
				<ButtonToOpenDialog
					button_variant='text'
					dialog_title={`New Component Record for ${component?.name}`}
					dialog_submit_text='Create Component Record'
					dialog_dismiss_text='Discard'
					dialog_content={<ComponentRecordForm />}
					button_content='Create Component Record'
					startIcon={<CorIcon of='iot' size='24'/>}
					form_id='ComponentRecord'
				/>
				
			</Stack>
		</Box>
	)
};


export default ComponentRecordsPageContent;
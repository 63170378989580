// import Stack from '@mui/material/Stack';

import { Chip, styled, Typography } from '@mui/material';
// import { PartsManifestItem } from 'models';
import { PartsNeeded } from 'types';



const StyledChip = styled(Chip)(() => ({
	backgroundColor: '#186ab4',
	color: 'white',
	margin: '5px',
	maxWidth: '95%',
	fontWeight: '400',
}));



const PartChip =
({
	part,
} : {
	part: PartsNeeded,
}) =>
{
	
	
	return (
		<StyledChip
			label={
				(part.quantity > 1)
					?
						<Typography>
							<span
								style={{
									color: '#fff8',
									marginRight: 12,
								}}
							>
								{`x${part.quantity}`}
							</span>
							{`${part.name}`}
						</Typography>
					:
						<Typography>
							{`${part.name}`}
						</Typography>
			}
		/>
	)
};


export default PartChip;
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Stack, TextField } from '@mui/material';


// TODO: Crashes if user edits text field with invalid time
const TimePickerWidget =
({
	value,
	label = 'Time',
	onChange,
	name,
} : {
	value: DateTime,
	label?: string,
	onChange: Function,
	name?: string,
}) =>
{
	return <Stack
		className='date_picker'
		sx={{
			maxWidth: '160px',
		}}
	>
		<TimePicker
			// disablePortal
			// modal={false}
			// PopperComponent={Popper}
			label={label}
			value={value}
			ampm={false}
			onChange={(datetime: DateTime, keyboardInputValue?: string) => {
				console.log({
					keyboardInputValue,
					datetime,
				})
				if(datetime.isValid)
					onChange(datetime)
			}}
			renderInput={(params) =>
				<TextField
					name={name}
					error={true}
					onChange={(e) => {
						
						// let parts = e.target.value.split(':');
						
						let hoursRegex = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
						
						console.log({
							e,
							valid: hoursRegex.test(e.target.value),
						})
						
						if(!hoursRegex.test(e.target.value))
						{
							e.stopPropagation();
							e.preventDefault();
						}
					}}
					sx={{
						bgcolor: 'field.default',
						
						'&:hover': {
							bgcolor: 'field.hover',
						},
						
						'& .Mui-focused': {
							bgcolor: 'field.focus',
						},
						
						
						'& .MuiInputLabel-root': {
							bgColor: 'field.default',
							outline: '4px solid field.default',
							padding: '0 2px',
							borderRadius: '2px',
							left: '2px',
						},
						
						'&:hover .MuiInputLabel-root': {
							bgcolor: 'field.hover',
							outline: '4px solid field.hover',
						},
						
						'& .MuiInputLabel-root.Mui-focused': {
							bgcolor: 'field.focus',
							outline: '4px solid field.focus',
						},
					}}
					{...params}
				/>
			}
		/>
	</Stack>
}


export default TimePickerWidget;
import React from 'react';

import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { ComponentTreeData } from 'models';


const SelectedComponentChip = ({
	component,
	selectedComponents,
	setSelectedComponents,
} : {
	component: ComponentTreeData,
	selectedComponents: ComponentTreeData[],
	setSelectedComponents: React.Dispatch<React.SetStateAction<ComponentTreeData[]>>,
}) =>
{
	const removeComponents = () =>
	{
		setSelectedComponents(selectedComponents.filter(x => x._id !== component._id))
	}
	
	
	return (
		<div className='component_chip'>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				width='90%'
			>
				<Stack
					flexDirection={'row'}
					gap={1}
					justifyContent='center'
					alignItems='center'
				>
					<Typography
						variant='body1'
						sx={{
							color: '#fff',
							marginRight: '5px',
						}}
					>
						{component.asset_name}
					</Typography>
					
					<CorIcon
						of='arrow_right'
						size='18px'
						color='#fff'
					/>
					
					<Typography
						variant='body1'
						sx={{
							color: '#fff',
							marginRight: '5px',
						}}
					>
						{ component.name }
					</Typography>
				</Stack>
				
				<CorIcon
					of='close'
					size='18px'
					color='#fff'
					onClick={() => removeComponents()}
				/>
			</Stack>
		</div>
	)
};


export default SelectedComponentChip;
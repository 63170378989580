

// export type RecurrenceType = 'specific_days' | 'interval';
// based_on: 'effective_date' | 'last_completed_date',




export enum TimeUnit
{
	YEARS = 'years',
	QUARTERS = 'quarters',
	MONTHS = 'months',
	weeks = 'weeks',
	DAYS = 'days',
	HOURS = 'hours',
	MINUTES = 'minutes',
	SECONDS = 'seconds',
	MILLISECONDS = 'milliseconds',
}





// interface ScheduleInterval
// {
// 	repeats_every: number,
// 	based_on: 'effective_date' | 'last_completed_date',
// }

// export const months =
// [
// 	'january',
// 	'february',
// 	'march',
// 	'april',
// 	'may',
// 	'june',
// 	'july',
// 	'august',
// 	'september',
// 	'october',
// 	'november',
// 	'december',
// ] as const

// export type Month = typeof months[number];

// export type ScheduleType = 'daily' | 'weekly' | 'monthly' | 'yearly';

// export type TimeUnit =
// 	'years'
// 	| 'quarters'
// 	| 'months'
// 	| 'weeks'
// 	| 'days'
// 	| 'hours'
// 	| 'minutes'
// 	| 'seconds'
// 	| 'milliseconds'

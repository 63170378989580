import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CorIcon from 'components/icons/CorIcon';

import { ComponentTreeData } from 'models';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';


/*NOTE: Should be used in a FORM component. */
const SelectEquipment =
({
	selected_components,
	set_selected_components
} : {
	selected_components?: ComponentTreeData[],
	set_selected_components?: (selected_components: ComponentTreeData[]) => void,
}) => 
{
	const { setValue } = useFormContext();
	const [ openAlert, toggleAlert ] = useState(false);
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	const handleSelectEquipment = (selected : ComponentTreeData[]) =>
	{
		let selected_component_ids = [];
		let selected_component_names = [];
		
		selected.forEach((component) =>
		{
			selected_component_ids.push(component._id);
			selected_component_names.push(component.name);
			
			// TODO: CHANGE THIS ASSET SELECTION HANDLING
			setValue('asset_id', component?.asset_id)
			setValue('asset_name', component?.asset_name)
		});
		
		
		setValue('component_ids', selected_component_ids);
		setValue('component_names', selected_component_names);
		
		set_selected_components(selected);
		
		toggleAlert(true);
		setCloseDialog(!closeDialog);
	}
	
	
	return (
		<ButtonToOpenDialog
			button_variant='text'
			dialog_title='Select Equipment'
			button_content='Add Equipment'
			dialog_content={
				<ComponentSelection
					selected_components={selected_components}
					selectEquipment={handleSelectEquipment}
				/>
			}
			closeDialog={closeDialog}
			startIcon={<CorIcon of='asset_tree' size='20px'/>}
		/>
	)
}


export default SelectEquipment;
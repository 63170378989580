import { relativeDateText } from 'stuff';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const CalendarSpotlightItem = ({ today, day, events_by_date, assets_by_date }) =>
{
	let iso = day.toISO().slice(0, 10);
	
	let events_on_day = events_by_date[iso];
	
	
	console.log('Events on', iso, events_on_day);
	
	
	let elems = events_on_day?.map(event => <div
		className='calendar_spotlight_event'
		key={event._id}
	>
		<div style={{
			background: event.color,
			padding: '2px 5px',
			margin: '1px',
			borderRadius: '6px',
			textAlign: 'center',
		}}>
			{event.color}
		</div>
	</div>)
	
	
	return (
		<Stack>
			<div className='calendar_spotlight_item'>
				<div className='calendar_spotlight_item_date'>
					<div className='calendar_spotlight_item_date1'>
						{day.toFormat('ccc, LLL dd')}
					</div>
					<div className='calendar_spotlight_item_date2'>
						{relativeDateText(day, today)}
					</div>
				</div>
				<div className='calendar_spotlight_events'>
					{elems}
				</div>
			</div>
			
		{
			assets_by_date.length > 0 
			&& (
				<div  
					className = 'schedule_filter_accordion'
				>
					<Accordion>
						
						<AccordionSummary
							expandIcon={ <ExpandMoreIcon /> }
							aria-controls = 'panel1a-content'
							id = 'panel1a-header'
						>

							<Typography>Maintenance History</Typography>
						</AccordionSummary>
						
						<AccordionDetails>
							{
								assets_by_date.map((log, key) => (
									<div
										key = { key }
									>
										<div
											className = 'schedule_filter_accordion_wrapper'
										>
											<div 
												className = 'schedule_filter_accordion_title'
											>
												{log.Name}
											</div>
										</div>
									</div>
								))
							}
						</AccordionDetails>
					</Accordion>
				</div>
			)
		}
		</Stack>
	)
}


export default CalendarSpotlightItem;
import Grid from '@mui/material/Grid';
import { WorkOrderNew } from 'models';
import SearchableWorkOrdersCardList from './SearchableWorkOrdersCardList';
import WorkOrdersTable from './WorkOrdersTable';
import WorkOrdersSearch from './WorkOrdersSearch';
import { useSearchAndSort } from 'components/searchAndSort/SearchAndSortStateProvider';


const WorkOrdersGridContainer = () =>
{
	const { filtered_work_orders: work_orders } = useSearchAndSort();
	
	return (
		<Grid
			container
			className='cards_container'
			gap={2}
		>
			<WorkOrdersSearch />
			
			<Grid
				item
				width={'100%'}
				display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
			>
				<WorkOrdersTable work_orders={work_orders} title={''}/>
			</Grid>
			
			<Grid
				item
				width={'100%'}
				display={{xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
			>
				<SearchableWorkOrdersCardList work_orders={work_orders}/>
			</Grid>
		</Grid>
	)
};


export default WorkOrdersGridContainer;
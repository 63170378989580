import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { AuthProvider, FirestoreProvider, StorageProvider, useFirebaseApp } from 'reactfire';

import { getAppAuth } from 'ember';


type FirebaseComponentsProps =
{
	children: JSX.Element[] | JSX.Element,
};


const FirebaseSubProviders = ( props: FirebaseComponentsProps ) =>
{
	const app = useFirebaseApp();
	
	// Initialize Database and Auth with these new Firebase SDK functions
	const auth     = getAppAuth(app);
	const database = getFirestore(app);
	const storage  = getStorage(app);
	
	
	// Any children will be able to use `useUser`, `useDatabaseObjectData`, etc as normal
	return (
		<AuthProvider sdk={auth}>
			<FirestoreProvider sdk={database}>
				<StorageProvider sdk={storage}>
					{props.children}
				</StorageProvider >
			</FirestoreProvider>
		</AuthProvider>
	);
}


export default FirebaseSubProviders;
import React, { useState, useEffect } from 'react';

import Chat from './chat/Chat';
import Sidebar from './sidebar/Sidebar';

import { IssueLog, ChatUser, trackingDetails as TrackingDetails } from './interfaces/content_data_interfaces';

import fakeData from './fakeData.json';


const Communication: React.FC = () =>
{
	const [ users, setUsers ] = useState<ChatUser[]>([]);
	const [ issueLog, setIssueLog ] = useState<IssueLog[]>([]);
	const [ filteredLog, setFilteredLog ] = useState<IssueLog[]>([]);
	const [ thisUser, setThisUser ] = useState<ChatUser>({} as ChatUser);
	const [ collaborators, setCollaborators ] = useState<ChatUser[]>([]);
	const [ trackingDetails, setTrackingDetails ] = useState<TrackingDetails>({} as TrackingDetails);
	
	
	// initialize the useState parsed from the json file
	useEffect(() =>
	{
		const {
			issue_tracking,
			tracking_number,
			issue_log,
			collaborators,
			users,
			user
		} = fakeData
		
		if (
			issue_tracking !== undefined && 
			tracking_number !== undefined && 
			issue_log !== undefined &&
			collaborators !== undefined &&
			users !== undefined &&
			user !== undefined
		)
		{
			console.log(issue_log);
			
			Array.prototype.push.apply(issue_log,issue_log);
			Array.prototype.push.apply(issue_log,issue_log);
			
			setIssueLog( issue_log );
			
			setCollaborators( collaborators );
			setUsers( users );
			setThisUser( user );
			setTrackingDetails({
				issue_tracking:  issue_tracking,
				tracking_number: tracking_number
			})
			
		}
	}, []);
	
	
	const updateCollaborators = ( newCollab_: ChatUser ) =>
	{
		setCollaborators([ ...collaborators, newCollab_ ]);
		
		setUsers(
			users.filter(( user ) => {
				return user._id !== newCollab_._id;
			})
		);
		
		var temp_log = [ ...issueLog ];
		if ( thisUser !== undefined )
		{
			//temp_log.push( createAddCollaborator( thisUser, newCollab_ ) );
			//setIssueLog( temp_log );
		}
		
	}
	
	
	const filterByTag = ( tagName_: string ) =>
	{
		const temp_isssueLog = [...issueLog];
		const issuetitle = temp_isssueLog.slice(0, 1);
		const issueContents = temp_isssueLog.slice(1, temp_isssueLog.length);
		
		if(tagName_ !== '')
		{
			const newFilter = issueContents.filter(( content: IssueLog ) =>
			{
				if(content.body.purchase_part !== undefined)
				{
					return content.body.purchase_part.title === tagName_;
				}
				else
				{
					return false;
				}
			});
			
			setFilteredLog( [ ...issuetitle, ...newFilter ] );
		}
		else
		{
			setFilteredLog( [ ...issuetitle] );
		}
	}
	
	
	const formSubmit = ( form_obj_: IssueLog ) =>
	{
		var temp_log = [ ...issueLog ];
		
		temp_log.push( form_obj_ );
		
		setIssueLog( temp_log );
	}
	
	
	return (
		<div
			className='communication'
		>
			
			<Sidebar
				isssueLog={ issueLog }
				collaborators={ collaborators }
				users={ users }
				tagClicked={ filterByTag }
				whoHasSelected={ updateCollaborators }
				filteredLog={ setFilteredLog }
			/>
			
			<div
				className='communication__chat'
			>
				<Chat
					collaborators={ collaborators }
					issue_log={ (filteredLog.length > 1) ? filteredLog : issueLog }
					thisUser={ thisUser }
					formSubmit={ formSubmit }
					issue_tracking={ trackingDetails.issue_tracking }
					tracking_number={ trackingDetails.tracking_number }
				/>
			</div>
			
		</div>
	)
}


export default Communication;
import Grid from '@mui/material/Grid';
import { Requisition } from 'models';
import RequisitionsTable from './RequisitionsTable';
import SearchableRequisitionsCardList from './SearchableRequisitionsCardList';


const ResponsiveRequisitionsList = ({
	requisitions,
	title,
} : {
	requisitions: Requisition[],
	title?: string,
}) =>
{
	return (
		<Grid container>
			<Grid
				item
				className='cards_container'
				display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
			>
				<RequisitionsTable requisitions={requisitions} title='Requisitions List' />
			</Grid>
			
			<Grid
				item
				className='cards_container'
				display={{xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
			>
				<SearchableRequisitionsCardList requisitions={requisitions} />
			</Grid>
		</Grid>
	)
};


export default ResponsiveRequisitionsList;
import { Card, Stack, Typography } from '@mui/material';
import { OccurrenceCardData } from './OccurrenceCardList';


const OccurrenceCard =
({
	projected,
	highlight,
} : {
	projected: OccurrenceCardData,
	highlight?: boolean,
}) =>
{
	return (
		<Card
			sx={{
				padding: '10px 18px',
				bgcolor: (highlight) ? '#fff' : '#fbfbfb',
				width: 'fit-content',
				margin: 'auto',
			}}
			key={projected._id}
		>
			<Typography
				variant='h3'
				sx={{
					marginBottom: 1,
				}}
			>
				{projected.title}
			</Typography>
			<Stack
				direction='row'
			>
				<Stack
					direction='row'
					// alignItems='center'
				>
					<Typography
						component='span'
						sx={{
							opacity: .4,
							marginTop: '2px',
							marginLeft: 3,
							marginRight: 1,
						}}
					>
						{'Due: '}
					</Typography>
					<Stack>
						<Typography
							sx={{
								fontSize: '16px',
							}}
						>
							{projected.datetime_elem}
						</Typography>
						<Typography
							sx={{
								opacity: .5,
								fontSize: '12px',
							}}
						>
							{projected.subtitle}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Card>
	)
}


export default OccurrenceCard;
import { NavLink, useState, useAuthData } from 'stuff';

import { useNavigate } from 'react-router';

// import {AppBar, ThemeProvider} from '@mui/material';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';

import { Drawer } from './NavThemes';

import NavItem from './NavItem';
import NavLogo from './NavLogo';
import CorIcon from 'components/icons/CorIcon';
import BottomNavBar from './BottomNavBar';


const SHOW_OLD = false;


// Navigation menu in left pane
// Contains tabs to control what content is displayed in Main
const NavBar = () =>
{
	const navigate = useNavigate();
	
	const { logOut } = useAuthData();
	
	const [ open, setOpen ] = useState(true);
	
	
	const handleDrawerOpen = () =>
	{
		setOpen(true);
	};
	
	const handleDrawerClose = () =>
	{
		setOpen(false);
	};
	

	
	let old_items =
	[
		{ name: 'Test', icon: 'developing' },
		{ name: 'Input', icon: 'manufacturing' },
		{ name: 'Helm', icon: 'database' },
		{ name: 'Assets', icon: 'asset' },
		{ name: 'Issues', icon: 'track' },
	];
	
	let demo_items =
	[
		{ name: 'Messages', icon: 'message' },
		{ name: 'Uploader', icon: 'upload' },
		{ name: 'Assets', icon: 'asset' },
		{ name: 'Issues', icon: 'track' },
		{ name: 'Communication', icon: 'message' },
		{ name: 'Vessels', icon: 'asset' },
		{ name: 'Chats', icon: 'message' },
	];
	
	let items =
	[
		{ name: 'My Page', icon: 'my_page' },
		{ name: 'Issues', icon: 'issue' },
		{ name: 'Work Orders', icon: 'work_order' },
		{ name: 'Requisitions', icon: 'requisition_list' },
		{ name: 'Assets', icon: 'asset_tree' },
		{ name: 'Certifications', icon: 'certificate'},
		{ name: 'Data Analytics', icon: 'data_analytics'},
	];
	
	
	if (SHOW_OLD)
	{
		items = items.concat(old_items).concat(demo_items);
	}
	
	
	
	return (
		<>
			<Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
				<Drawer variant='permanent' open={open} className='nav_bar'>
					{
						(!open)
						?
							<IconButton
								color='inherit'
								aria-label='open drawer'
								onClick={handleDrawerOpen}
								sx={{
									...(open && { display: 'none' }),
								}}
								className='nav_list_header'
							>
								<CorIcon of='menu' size='25px'/>
							</IconButton>
						:
							<ListItem className='nav_top'>
								<IconButton onClick={handleDrawerClose} className='nav_list_header'>
									<CorIcon of='back' size='30px'/>
								</IconButton>
								<NavLogo />
							</ListItem>
					}
					
					<List className='nav_body_top'>
						{
							items.map((item, index) =>
							{
								const { name, icon } = item;
								
								return (!open)
								?
									<Tooltip
										title={name}
										placement='right'
										key={name}
										arrow
										componentsProps={{
											tooltip: {
												sx: {
													bgcolor: '#303030',
													height: '35px',
													display: 'flex',
													textAlign: 'center',
													justifyContent: 'center',
													paddingTop: '9px',
													fontSize: '14px',
													'& .MuiTooltip-arrow': {
														color: '#303030',
													},
												},
											},
										}}
									>
										<ListItem
											button
											className='nav_list'
											component={NavLink}
											to={'/' + name.toLowerCase().replaceAll(' ', '-')}
										>
											<NavItem name={name} icon={icon} />
										</ListItem>
									</Tooltip>
								:
									<ListItem
										button
										key={name}
										className='nav_list'
										component={NavLink}
										to={'/' + name.toLowerCase().replaceAll(' ', '-')}
									>
										<NavItem name={name} icon={icon} />
									</ListItem>
								})
						}
					</List>
					
					
					<List className='nav_body_bottom'>
					{
						(!open)
						?
							<>
								<ListItem sx={{ borderTop: '1px solid #fff', width: '70%' }}>
								</ListItem>
								<Tooltip
									title='Settings'
									placement='right'
									arrow
									componentsProps={{
										tooltip: {
											sx: {
												bgcolor: '#303030',
												width: '80px',
												height: '35px',
												display: 'flex',
												textAlign: 'center',
												justifyContent: 'center',
												paddingTop: '9px',
												fontSize: '14px',
												'& .MuiTooltip-arrow': {
													color: '#303030',
												},
											},
										},
									}}
								>
									<ListItem
										button
										className='nav_list'
										component={NavLink}
										to='/settings'
									>
										<NavItem name='Settings' icon='setting' />
									</ListItem>
								</Tooltip>
								
								<Tooltip
									title='Logout'
									placement='right'
									arrow
									componentsProps={{
										tooltip: {
											sx: {
												bgcolor: '#303030',
												width: '80px',
												height: '35px',
												display: 'flex',
												textAlign: 'center',
												justifyContent: 'center',
												paddingTop: '9px',
												fontSize: '14px',
												'& .MuiTooltip-arrow': {
													color: '#303030',
												},
											},
										},
									}}
								>
									<ListItem button className='nav_list' onClick={() => logOut()}>
									<NavItem name='Logout' icon='logout' />
									</ListItem>
								</Tooltip>
							</>
						:
							<>
								<ListItem sx={{ borderTop: '1px solid #fff', width: '70%' }}>
								</ListItem>
								<ListItem
									button
									className='nav_list'
									component={NavLink}
									to='/settings'
								>
									<NavItem name='Settings' icon='setting' />
								</ListItem>
								
								<ListItem
									button
									className='nav_list'
									onClick={() => {
										logOut();
										navigate('./');
									}}
								>
									<NavItem name='Logout' icon='logout' />
								</ListItem>
							</>
						}
					
					</List>
				</Drawer>
			</Box>
			
			<BottomNavBar />
		</>
	);
};


export default NavBar;
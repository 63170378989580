import { useState } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import { CurrentComponent } from 'models';
import { useAuthData } from 'stuff';
import { useUpdateDocProperty } from 'ember';
import { Issue } from 'models';
import RelatedAssetAndComponents from './issueTable/RelatedAssetAndComponents';


const AffectedComponentsList =
({
	components,
	issue,
}: {
	components: CurrentComponent[],
	issue: Issue,
}) =>
{
	const { claims } = useAuthData();
	const updateDocProperty = useUpdateDocProperty();
	
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	const updateAffectedComponents = (selected_components) =>
	{
		const component_ids = selected_components.map(x => x._id);
		
		const asset_name = selected_components.map(x => x.asset_name)[0];
		const asset_id = selected_components.map(x => x.asset_id)[0];
		
		updateDocProperty(
			`organizations/${claims?.organization_id}/issues/${issue._id}`,
			'component_ids',
			component_ids
		);
		
		// Update asset property
		updateDocProperty(
			`organizations/${claims?.organization_id}/issues/${issue._id}`,
			'asset_name',
			asset_name
		);
		updateDocProperty(
			`organizations/${claims?.organization_id}/issues/${issue._id}`,
			'asset__id',
			asset_id
		);
		
		
		
		setCloseDialog(!closeDialog);
	}
	
	return (
		<Grid
			item
			xs={12}
			sm={4}
		>
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='center'
				sx={{
					marginLeft:'10px',
					width: '100%',
				}}
			>
				<Typography
					variant='h6'
				>
					Affected Components
				</Typography>
				
				<RelatedAssetAndComponents component_ids={issue.component_ids} />
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					sx={{
						width: '100%',
					}}
				>
					<ButtonToOpenDialog
						button_variant='text'
						dialog_title='Select Components'
						button_content='Edit'
						dialog_content={
							<ComponentSelection
								selected_components={components}
								selectEquipment={updateAffectedComponents}
							/>
						}
						sx={{
							textTransform:'none',
							textDecoration: 'underline',
						}}
						closeDialog={closeDialog}
					/>
				</Stack>
				
			</Stack>
		</Grid>
	)
}


export default AffectedComponentsList;
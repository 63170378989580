import { useState } from 'stuff';

import BadgeIcon from './BadgeIcon';

import {
	isRectangle,
	match_badgeBgcolor,
	isFiltering 
} from './__badges';


const cn: string = 'badges';



export interface BadgeProps
{
	title: string;
	status?: string;
	color?: string;
	is_rectangle_shape?: boolean;
	tagClicked?: (tagName: string) => void;
}



// title: string -- the string of content to display
// _status: string -- the string of status accept only 'warning' or 'alert'
// _color: string -- name of the color variable
// _rectangle: boolean 
//      -- true => border-radius $br--small
//      -- false / undefined => border-radius $br--round
// _isFiltering: string => void
//      return the clicked tag name and return empty string on another click 
// Icon will render iff _status is either 'warning' or 'alert' 
// 
// all non states functions included in __bages.tsx

/* --- sample ---

	<Badges
		title = { 'sample title' }
		_color = { 'jade' }
		_rectangle = { true }
	/>

*/
const Badge =
({
	title,
	status,
	color: _color,
	is_rectangle_shape: _rectangleShape,
	tagClicked: _tagClicked,
}
: BadgeProps) =>
{
	const [ filterTag, setFilterTag ] = useState< boolean >( false );
	
	const filterTagHandler = () =>
	{
		if ( status !== undefined &&  _tagClicked !== undefined)
		{
			if ( filterTag === false )
			{
				_tagClicked( title ); 
			}
			else {
				_tagClicked( '' );
			}
			
			setFilterTag( !filterTag );
		};
	};
	
	
	return (
		<div
			className={
				cn + '__wrapper ' 
				+ cn + match_badgeBgcolor( _color, status ) + ' '
				+ cn + isRectangle( _rectangleShape ) + ' '
				+ cn + isFiltering( filterTag )
			}
			onClick={ filterTagHandler }
		>
			{ title }
			
			<div className={ cn + '__icon' }
			>
				{
					<BadgeIcon
						status={status}
					/>
				}
			</div>
			
		</div>
	)
}


export default Badge;
import {
	Box,
	Checkbox as CheckboxMUI,
	FormControlLabel,
	FormGroup,
} from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';
import { FormInputType, ResponseField } from 'types/forms';

import { WorkOrderResponseForm } from 'types/forms';


export interface CheckboxProps
{
	field: ResponseField & {
		type: FormInputType.Checkbox;
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const Checkbox =
({
	field,
	sectionIndex,
	fieldIndex,
}: CheckboxProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	const options = field.value.options;
	
	
	return (
		<Box>
			<FormGroup>
				{
					options.map((option, index) =>
						<Controller
							key={index}
							control={control}
							name={`form_sections.${sectionIndex}.fields.${fieldIndex}.value.options.${index}.isChecked`}
							defaultValue={false}
							render={({ field: { value, onChange } }) => (
								<FormControlLabel
									label={option.label}
									control={<CheckboxMUI checked={value} />}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
					)
				}
			</FormGroup>
		</Box>
	);
};


export default Checkbox;
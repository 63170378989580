import { ActionType, actionTypes } from 'pages/CreateWorkOrder/work_order_types';
import { Controller } from 'react-hook-form';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ActionProps } from '../types';


const actionTypeLabels: Record<ActionType, string> =
{
	checkbox: 'Checkbox',
	date: 'Date',
	multiple_choice: 'Multiple Choice',
	pass_fail: 'Pass/Fail',
	reading: 'Reading',
	replace: 'Replace',
	text: 'Text',
	yes_no: 'Yes/No',
	instruction: 'Instruction',
};


interface ActionTypeSelectProps extends ActionProps {}


export const ActionTypeSelect =
({
	control,
	sectionIndex,
	fieldIndex,
}: ActionTypeSelectProps) =>
{
	return (
		<FormControl fullWidth={false}>
			<InputLabel
				required={true}
				id={`step-form-section-${sectionIndex}-action-type-selector-label`}
			>
				Action Type
			</InputLabel>
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.action_type` as const
				}
				defaultValue=''
				render={({ field: { value, onChange } }) => {
					return (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: '1rem',
							}}
						>
							<Select
								defaultValue={value}
								labelId={`step-form-section-${sectionIndex}-action-type-selector-label`}
								onChange={onChange}
								label='Action Type'
								placeholder='Action Type'
								sx={{ minWidth: '192px' }}
							>
								{actionTypes.map((action_type) => {
									return (
										<MenuItem
											value={action_type}
											key={action_type}
										>
											{actionTypeLabels[action_type]}
										</MenuItem>
									);
								})}
							</Select>
						</Box>
					);
				}}
			/>
		</FormControl>
	);
};


export default ActionTypeSelect;
import { Controller } from 'react-hook-form';

import { OutlinedInput } from '@mui/material';
import { LongTextField } from 'models/fields';
import Formlet from '../Formlet';


// TODO: Number of rows?
export const LongTextFormlet =
({
	name,
	label,
	help,
	sx,
}
: LongTextField) =>
{
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<OutlinedInput
						name={name}
						multiline={true}
						rows={4}
						
						value={value}
						onChange={(e) =>
							onChange(e.target.value)
						}
						
						sx={{
							bgcolor: 'field.default',
							
							'&:hover': {
								bgcolor: 'field.hover',
							},
							
							'&.Mui-focused': {
								bgcolor: 'field.focus',
							},
							...sx
						}}
					/>
				}
			/>
		</Formlet>
	);
}


export default LongTextFormlet;
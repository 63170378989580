import { Doc } from 'ember';
import { DateTime, ToObjectOutput, DurationLikeObject } from 'luxon';
import { isLiteralObject } from './obj_utils';



// Provide an object and a function that will be applied to each item
// Note the parameter order!
//     value, key, index
// 
// Ex: { a: 1, b: 2, c: 3 },   v => 2 * v
//     =>
//     {a: 2, b: 4, c: 6}
// 
// Ex: { a: 1, b: 2, c: 3 },   (v, k) => `${k.toUpperCase()} = ${v}`}
//     =>
//     {a: 'A = 1, b: 'B = 2', c: 'C = 3'}
export function objFromObj(obj: {}, fn: (v: any, k: string, i: number) => {}) : Object
{
	// TODO: How to handle
	if(!obj) return null;
	
	return Object.fromEntries(
		Object.entries(obj).map(
			([key, value], index) => [key, fn(value, key, index)]
		)
	)
}



// Takes array, using each element as a key
// Each key is assigned the provided value in the resulting object
// Ex: ['a', 'b', 'c'], true => {a: true, b: true, c: true}
export const objFromStringArray = ( array: string[], value: any = true ) : Object =>
{
	const obj = Object.assign(
		{},
		...array.map((key) =>
			({ [key]: value })
		)
	);
	
	return obj;
}



// Takes an array and uses a particular property value as the key mapping to the
// items themselves in the resulting dictionary. Any duplicates will be overwritten.
// Ex: [{_id: 'a', name: 'Abe'}, {_id: 'b', name: 'Bill'}] =>
//     {a: {_id: 'a', name: 'Abe'}, b: {_id: 'b', name: 'Bill'}}
export const objFromArray = ( array: any[], key_prop: string = '_id' ) : Object =>
{
	const obj = Object.assign(
		{},
		{}
	);
	
	array.forEach((item) =>
		{
			let key = item?.[key_prop];
			
			obj[key] = item;
		}
	)
	
	return obj;
}



// Takes array of documents, using each element's _id as a key
// Each key is assigned the provided value in the resulting object
// Ex: [{_id: 'a'}, {_id: 'b'}], true => {a: true, b: true}
export const objFromDocArray = ( array: Doc[], value: any = true ) : Object =>
{
	return objFromStringArray(array.map(x => x._id), value);
}



// Checks all key-value pairs in object, returning true once it finds one that's truthy
export const hasAtLeastOneTruthyValue = (obj: Object) =>
{
	return Object.values(obj).some(v => !!v);
}



// Returns a deeply cleaned version of input, removing any undefined key-value pairs,
// as well as any undefined array items. Numbers and strings are returned as is,
// while arrays and objects are handled recursively.
export const excludeUndefinedValues = (input: any) =>
{
	// If given an array, recursively call this function for each array item
	if(input instanceof Array)
	{
		return input.map(x => excludeUndefinedValues(x)).filter(x => (x !== undefined));
	}
	
	// If given an object, recursively call this function for each key-value pair
	if(isLiteralObject(input))
	{
		let new_obj = {};
		
		// Undefined key-value pairs won't be included in output
		Object.keys(input).forEach((key) =>
		{
			let value = input[key];
			
			if(value !== undefined)
			{
				new_obj[key] = excludeUndefinedValues(value);
			}
		});
		
		return new_obj;
	}
	
	// If input isn't an array or object, we'll return it as is
	return input;
};



// Returns a deeply cloned deeply cleaned version of input, removing any undefined key-value pairs,
// as well as any undefined array items. Numbers and strings are returned as is,
// while arrays and objects are handled recursively.
export const cloneExcludeUndefinedValues = (input: any) =>
{
	return structuredClone(excludeUndefinedValues(input));
};



// Zeros all properties in an object, except one
export const zeroAllBut = (obj: Object, except_key: string) =>
{
	return objFromObj(obj, (v, k) =>
		(k === except_key)
			? v
			: 0
	)
}






// interface NumericObject
// {
// 	[key: string]: number,
// }

// export interface NumericObjectDatetime extends NumericObject
// {
// 	year: number,
// 	month: number,
// 	day: number,
// 	hour: number,
// 	minute: number,
// 	second: number,
// 	millisecond: number,
// }



// Combines 2 objects, summing values if keys match
export const addNumericObjects = (
	obj1: Object, //NumericObject,
	obj2: Object, //NumericObject
)
: Object =>
{
	const result = structuredClone(obj1);
	
	//{ ...obj1 };
	// Object.keys(obj1).forEach(key => {
	// 	result[key] = obj1[key];
	// });
	
	// Object.keys(obj1).forEach(key => {
	// 	result[key] = obj1[key];
	// });

	Object.keys(obj2).forEach(key =>
	{
		if (result.hasOwnProperty(key))
		{
			result[key] += obj2[key];
		}
		else
		{
			result[key] = obj2[key];
		}
	});
	
	// for (const key in obj1)
	// {
	// 	result[key] = obj1[key];
	// }
	
	// for (const key in obj2)
	// {
	// 	if (result.hasOwnProperty(key))
	// 	{
	// 		result[key] += obj2[key];
	// 	}
	// 	else
	// 	{
	// 		result[key] = obj2[key];
	// 	}
	// }
	
	console.log({
		obj1,
		obj2,
		result,
	})
	
	return result;
}



export const combineDateTimeAndDuration =
(
	datetime: ToObjectOutput,
	duration_like: DurationLikeObject
)
: DateTime =>
{
	const { year, month, day, hour, minute, second, millisecond } = datetime;
	
	// const { years, months, days, hours, minutes, seconds, milliseconds } = duration_like;
	
	
	const dt = DateTime.fromObject({
		year:        year        ,
		month:       month       ,
		day:         day         ,
		hour:        hour        ,
		minute:      minute      ,
		second:      second      ,
		millisecond: millisecond ,
	}).plus(duration_like)
	
	return dt;
	
	// const dt = {
	// 	year:        year        + ( years || 0 ),
	// 	month:       month       + ( months || 0 ),
	// 	day:         day         + ( days || 0 ),
	// 	hour:        hour        + ( hours || 0 ),
	// 	minute:      minute      + ( minutes || 0 ),
	// 	second:      second      + ( seconds || 0 ),
	// 	millisecond: millisecond + ( milliseconds || 0 ),
	// };
	
	// console.log({
	// 	datetime,
	// 	duration_like,
	// 	dt,
	// })
	
	// return fixHighMonth(
	// 	DateTime.fromObject(dt)
	// );
	
	// return dateTime.plus(duration_like);
}



const fixHighMonth = (dt) =>
{
	let new_dt = dt.set({
		year: dt.year + Math.floor(dt.month / 12),
		month: dt.month - 12 * Math.floor(dt.month / 12) + (dt.month % 12),
	})
	
	console.log(new_dt)
	
	return new_dt;
	
	// If the month is out of range, roll over to the next year
	// return DateTime.fromObject({
	// 	year: year + Math.floor((month - 1) / 12),
	// 	month: ((month - 1) % 12) + 1,
	// 	day,
	// 	hour,
	// 	minute,
	// 	second,
	// 	millisecond
	// });
}



export const fixInvalidDateTime = ( dt: DateTime ) =>
{
	if(dt.isValid)
	{
		return dt;
	}
	
	
	const reason = dt.invalidReason;
	const invalidExplanation = dt.invalidExplanation;
	
	
	console.log({
		dt,
		reason,
		invalidExplanation,
	})
	
	
	
	switch (reason)
	{
		case 'unit out of range':
		{
			let { month, day } = dt;
			
			if (month > 12)
			{
				return fixHighMonth(dt);
			}
			else if (day > dt.daysInMonth)
			{
				let new_dt = dt.set({ day: dt.daysInMonth }).plus({ days: dt.day - dt.daysInMonth })
				
				console.log({
					valid: dt.toISO(),
					dt,
					new_dt,
				})
				
				return fixInvalidDateTime(new_dt);
			}
			else if (day < 1)
			{
				let new_dt = dt.minus({ months: 1 })
				
				// new_dt = new_dt.set({ day: dt.day + new_dt.daysInMonth })
				
				new_dt = new_dt.set({
					day: new_dt.daysInMonth + day
				});
				
				
				return fixInvalidDateTime(new_dt);
			}
			break;
		}
		// Add additional cases for other invalidity reasons if needed
	}
	
	// If the invalidity reason is not covered by any case, return the original invalid DateTime object
	return dt;
};












import { Button, Divider, Grid, Stack } from '@mui/material';


import CorIcon from 'components/icons/CorIcon';
import RequisitionPartsTemplate from './RequistionPartsTemplate';
import RequisitionSectionsTemplate from './RequisitionSectionsTemplate';
import { useFieldArray, useFormContext } from 'react-hook-form';



const DEFAULT_PART =
{
	name: '',
	quantity: null,
	
	unit_price: {
		currency: 'CAD',
		quantity: '',
	},
	
	component_ids: [],
	
	manufacturer_name: '',
	manufacturer_part_number: '',
	website_link: '',
};



const DEFAULT_SECTION =
{
	description: '',
	
	cost: {
		currency: 'CAD',
		quantity: '',
	},
	
};






const RequisitionItemsSection = () =>
{
	const form = useFormContext();
	
	const { control } = form;
	
	const {
		fields: parts,
		append: appendPart,
		remove: removePart
	} = useFieldArray({
		control,
		name: 'items.parts',
	});
	
	const {
		fields: sections,
		append: appendSection,
		remove: removeSection
	} = useFieldArray({
		control,
		name: 'items.sections',
	});
	
	
	console.log({parts}, {sections})
	
	
	
	const addToParts = () =>
	{
		let new_part = {
			...DEFAULT_PART
		};
		
		console.log({new_part});
		appendPart(new_part)
	};
	
	
	
	const addToSections = () =>
	{
		let new_section = {
			...DEFAULT_SECTION
		};
		
		console.log({new_section})
		appendSection(new_section)
	};
	
	
	
	return(
		<Stack
			spacing={2}
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-start'
				gap={2}
			>
				<Button
					variant='outlined'
					startIcon={<CorIcon of='add_inventory' size='24px' />}
					sx={{
						textTransform: 'none',
					}}
					onClick={addToParts}
				>
					Add a Part
				</Button>
				
				<Button
					startIcon={<CorIcon of='add_list' size='24px' />}
					sx={{
						textTransform: 'none',
					}}
					onClick={addToSections}
				>
					Add a General Section
				</Button>
				
			</Stack>
			
			<Grid
				container
				gap={2}
			>
				{
					(parts?.length >= 1)
					&&
					parts.map((part, index) => {
						return(
							<Grid
								key={part.id}
								item
								xs={12}
								md={12}
								lg={12}
							>
								<RequisitionPartsTemplate
									part={part}
									partIndex={index}
									removePart={removePart}
								/>
							</Grid>
						)
					})
					
				}
			</Grid>
			
			<Divider />
			
			<Grid
				container
				gap={2}
			>
				{
					(sections?.length >= 1)
					&&
					sections.map((section, index) => {
						return(
							<Grid
								key={section.id}
								item
								xs={12}
								md={12}
								lg={12}
							>
								<RequisitionSectionsTemplate
									section={section}
									sectionIndex={index}
									removeSection={removeSection}
								/>
							</Grid>
						)
					})
				}
			</Grid>
			
		</Stack>
	)
};


export default RequisitionItemsSection;
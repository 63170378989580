import { useNavigate } from 'react-router-dom';
import { useAuthData, useTitle } from 'stuff';
import { useCollection } from 'ember';

import { Box, Typography, Stack, CircularProgress, Button } from '@mui/material';


import { WorkOrderNew, WorkOrderTemplate } from 'models';


import CorIcon from 'components/icons/CorIcon';
import WorkOrdersAndTemplatesPageTabs from './WorkOrdersAndTemplatesPageTabs';
import { orderBy } from 'firebase/firestore';




// Children will be able to use this hook
// const work_orders = useWorkOrders();
const WorkOrdersAndTemplatesListPage = () =>
{
	const navigate = useNavigate();
	
	const { claims } = useAuthData();
	
	useTitle('Work Orders');
	
	
	const work_order_templates_collection = useCollection(
			`organizations/${claims?.organization_id}/work_order_templates`,
			orderBy('created_at', 'desc')
		);
	
	const work_orders_collection = useCollection(
			`organizations/${claims?.organization_id}/work_orders`,
			orderBy('created_at', 'desc')
		);
	
	
	if(work_order_templates_collection?.is_loading || work_orders_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const work_order_templates = work_order_templates_collection?.data as WorkOrderTemplate[];
	const work_orders = work_orders_collection?.data as WorkOrderNew[];
	
	
	//console.log({work_order_templates})
	
	
	return(
		<Box
			sx={{
				margin: '20px',
				height: '100%'
			}}
		>
			
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{
					marginBottom: '20px',
				}}
			>
				<Typography
					variant='h1'
				>
					Work Orders and Templates
				</Typography>
				
				<Button
					variant='contained'
					onClick={() => navigate('/work-orders/create-template')}
					startIcon={<CorIcon of='work_order' size='25px'/>}
				>
					Create Work Order Template
				</Button>
			</Stack>
			
			<WorkOrdersAndTemplatesPageTabs
				work_orders={work_orders}
				work_order_templates={work_order_templates}
			/>
			
		</Box>
	)
};


export default WorkOrdersAndTemplatesListPage;
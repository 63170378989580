import { useStorage, useStorageDownloadURL } from 'reactfire';
import { ref } from 'firebase/storage';

import Image from 'components/images/Image';
import ImageProps from './ImageProps';
import LoadingSpinner from 'components/loading/LoadingSpinner';


// Ex: path = 'cats/newspaper.jpg'
const ImageFromStorage =
({
	path,
	alt = '',
	...rest
} : ImageProps ) =>
{
	const storage = useStorage();
	
	const storage_ref = ref(storage, path);
	
	
	const { status, data: image_url } = useStorageDownloadURL(storage_ref);
	
	
	if(status === 'loading')
	{
		return <LoadingSpinner />
	}
	
	
	return <Image
		url={image_url}
		alt={alt}
		{...rest}
	/>
}


export default ImageFromStorage;
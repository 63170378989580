import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ResponsiveWorkOrdersList from 'pages/work_orders/work_orders/ResponsiveWorkOrdersList';
import { useRequisition } from '../../RequisitionDataProvider';
import CreateAction from 'app/dashboard/pages/actions/createAction/CreateAction';
import ActionsListResponsiveContent from 'app/dashboard/pages/issueCollaboration/actions/ActionsListResponsiveContent';
import IssuesListResponsiveContent from 'app/dashboard/pages/issuesMain/IssuesListResponsiveContent';
import AdditionalRequisitionControls from './AdditionalRequisitionControls';
import LinkWorkOrder from 'components/linkWorkOrder/LinkWorkOrder';
import { useFormContext } from 'react-hook-form';
import LinkIssues from 'pages/CreateWorkOrder/GeneralDescription/Issues/LinkIssues';



const RequisitionActions = () =>
{
	const form = useFormContext();
	const { control } = form;
	
	
	const {
		requisition,
		actions,
		linked_work_orders,
		linked_issues,
		updateLinkedIssues,
		updateLinkedWorkOrders
	} = useRequisition();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	useEffect(() =>
	{
		form.setValue(
			'issues',
			linked_issues
		)
	}, [linked_issues])
	
	
	return (
		<Stack
			sx={{
				padding: '10px',
				width: '100%',
			}}
			gap={2}
		>
			<Stack
				className='container'
				gap={1}
			>
				<ButtonToOpenDialog
					button_variant='contained'
					dialog_title=''
					button_content='Create Action'
					dialog_content={
						<CreateAction
							action_metadata={{ requisition_id: requisition._id }}
							closeDialog={closeDialog}
							setCloseDialog={setCloseDialog}
						/>
					}
					closeDialog={closeDialog}
					sx={{
						width: 'fit-content'
					}}
				/>
				
				<ActionsListResponsiveContent actions={actions} />
			</Stack>
			
			
			<Stack
				className='container'
				gap={1}
			>
				<ButtonToOpenDialog
					button_variant='contained'
					dialog_title='Please select WorkOrders you want to link to this Requisition'
					button_content='Link Work Orders'
					dialog_content={
						<LinkWorkOrder control={control} />
					}
					handleSubmit={updateLinkedWorkOrders}
					dialog_submit_text='Submit Changes'
					dialog_dismiss_text='Discard'
					sx={{
						width: 'fit-content'
					}}
				/>
				
				<ResponsiveWorkOrdersList work_orders={linked_work_orders} title={'Linked Work Orders'} />
			</Stack>
			
			
			<Stack
				className='container'
				gap={1}
			>
				<ButtonToOpenDialog
					button_variant='contained'
					dialog_title='Please select Issues you want to link to this Requisition'
					button_content='Link Issues'
					dialog_content={
						<LinkIssues control={control} />
					}
					handleSubmit={updateLinkedIssues}
					dialog_submit_text='Submit Changes'
					dialog_dismiss_text='Discard'
					sx={{
						width: 'fit-content'
					}}
				/>
				
				<IssuesListResponsiveContent issues={linked_issues} title='Linked Issues' />
			</Stack>
			
			<AdditionalRequisitionControls requisition={requisition} />
		</Stack>
	)
}


export default RequisitionActions;
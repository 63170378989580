import { useState, useEffect } from 'react';

import { Pagination, Stack } from '@mui/material';

import { useSession } from 'app/providers/SessionProvider';
import SearchBarOld from 'components/searchbar/SearchBarOld';
import { User } from 'types';
import SelectableCollaboratorCard from './SelectableCollaboratorCard';


const SearchableCollaboratorsList =
({
	users_list,
	selectedCollaborators,
	handleSelectedCollaborators,
}: {
	users_list?: User[],
	selectedCollaborators?: User[],
	handleSelectedCollaborators: (value: any) => void,
}) =>
{
	const { users } = useSession();
	
	const CARDS_PER_PAGE = 5;
	const [ page, setPage ] = useState<number>(1);
	
	const [filtered_users, setFilteredUsers] = useState<User[]>(users_list || users);
	
	useEffect(() => {
		setFilteredUsers(users_list || users);
	}, [users_list, users]);
	
	
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			<SearchBarOld
				items={users}
				handleItems={setFilteredUsers}
				fields={['display_name', 'email']}
			/>
			
			{
				filtered_users?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((user: User) => (
					<SelectableCollaboratorCard
						user={user}
						handleChange={handleSelectedCollaborators}
						selectedCollaborators={selectedCollaborators}
					/>
				))
			}
			
			<Pagination
				count={Math.ceil(users.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
		</Stack>
	)
};


export default SearchableCollaboratorsList;
import { React, useAuthData } from 'stuff';
import { useNavigate } from "react-router";
import CorIcon from 'components/icons/CorIcon';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from '../../styles/MobileUserSettingTheme';
import MobileHeader from './MobileHeader';
import { useDoc } from 'ember';
import { User } from 'types';
import UserAvatar from 'components/avatars/UserAvatar';



const SettingsMobile = () =>
{
	const { claims } = useAuthData();
	const navigate = useNavigate();
	const classes = useStyles();
	
	
	const users_collection_path = `organizations/${claims.organization_id}/users`;
	const current_user_doc = useDoc(users_collection_path, claims.user_id);
	
	
	if(current_user_doc.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const current_user = current_user_doc.data as User;
	
	
	const page_items =
	[
		{
			label: 'User Profile',
			icon: 'user_avatar',
			link: '/settings/profile',
		},
		{
			label: 'Manage Users',
			icon: 'user_multiple',
			link: '/settings/users',
		},
		{
			label: 'Manage Tags',
			icon: 'tags_outlined',
			link: '/settings/tags',
		},
		{
			label: 'Cost Center',
			icon: 'cost',
			link: '/settings/cost_centers',
		},
	]
	
	
	
	return (
		<>
			
			<MobileHeader
				title={'User Setting'}
				variantType={'h2'}
			/>
			
			<Box
				className={classes.AvatarTitle}
			>
				<UserAvatar
					user={current_user}
					alt={claims?.name.toUpperCase()}
					src={claims?.user_id}
					cuid={claims?.user_id}
					sx={{
						height: '50px',
						width: '50px',
						border: '1px solid #d8dbdf'
					}}
				/>
				
				<Typography
					variant='h1'
				>
					{ claims?.name }
				</Typography>
			</Box>
			
			<Box>
				{
					page_items.map(( page ) => (
						<>
							<Box
								className={classes.PageLinks}
								onClick={() => navigate(page.link)}
							>
								<CorIcon of={page.icon} size='25px' color='#323338' />
								<Typography
									className={classes.PageLinkText}
									variant='h5'
								>
									{ page.label }
								</Typography>
							</Box>
							
							<Divider/>
						</>
					))
				}
			</Box>
			
		</>
	)
}


export default SettingsMobile;
import { React, useAuthData, useState } from 'stuff';
import cuid from 'cuid';
import { DateTime } from 'luxon';

import { useDoc, useUpdate, useUploadFile } from 'ember';


// import { useSearchParams } from 'react-router-dom';
// import Avatar from '@mui/material/Avatar';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useStyles } from '../../styles/ManageProfileTabTheme';

import { text2hex } from 'utils/color_utils';

import { User } from 'types';
import { StorageFile } from 'models';

import UserProfileAvatarEdit from './components/UserProfileAvatarEdit';
import UserProfileNameEdit from './components/UserProfileNameEdit';
import UserProfileEmailEdit from './components/UserProfileEmailEdit';
import UserProfilePasswordEdit from './components/UserProfilePasswordEdit';
import UserAvatar from 'components/avatars/UserAvatar';


/*
	Render the user information from useAuthData
*/
const ManageProfileTab = () =>
{
	const { claims } = useAuthData();
	const uploadFile = useUploadFile();
	const update = useUpdate();
	
	const classes = useStyles();
	
	
	// TODO: Refactor
	const [ avatar, setAvatar ] = useState<any>();
	
	
	const users_collection_path = `organizations/${claims.organization_id}/users`;
	const current_user_doc = useDoc(users_collection_path, claims.user_id);
	
	
	let datetime = DateTime.local().toISO();
	
	
	console.log({claims});
	
	
	const handleDeleteAvatar = () =>
	{
		setAvatar( claims?.user_id )
	}
	
	
	const handleChangeAvatar = ( event: React.ChangeEvent<HTMLInputElement> ) =>
	{
		// Generate StorageFile from HTML5 File and upload to Firebase Storage
		const uploadOneFile = (file_to_upload: File) =>
		{
			let _id = cuid();
			
			let storage_file : StorageFile =
			{
				_id: _id,
				name: file_to_upload.name,
				path: `organizations/${claims?.organization_id}/files/${_id}`,
				size: file_to_upload.size,
				type: file_to_upload.type,
				upload_date: datetime,
				last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
			}
			
			const updated_user_data =
			{
				...current_user,
				image_path: `organizations/${claims?.organization_id}/files/${_id}`
			}
			
			uploadFile(
				storage_file,
				file_to_upload,
				`organizations/${claims?.organization_id}/files`,
				() => update(`organizations/${claims?.organization_id}/users`, updated_user_data)
			)
		}
		
		uploadOneFile(event.target.files[0]);
	}
	
	
	
	if(current_user_doc.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const current_user = current_user_doc.data as User;
	
	
	console.log({current_user});
	
	
	return (
		<>
			<Box
				className={classes.ManageProfileContainer}
			>
			
				<Typography
					className={classes.GeneralInformationTitle}
					variant='h3'
					color='#323338'
				>
					General Information
				</Typography>
				
				<Paper
					className={classes.GeneralInformationContainer}
					variant='outlined'
				>
					<Box
						className={classes.UserAvatarContainer}
					>
					<UserAvatar
						user={current_user}
						src={claims?.user_id}
						cuid={claims?.user_id}
						sx={{
							bgcolor: text2hex(claims?.user_id),
							height: '50px',
							width: '50px',
							border: '1px solid #d8dbdf'
						}}
					/>
					
						<Box
							className={classes.UserProfileContainer}
						>
							<Typography
								variant='caption'
							>
								User Profile
							</Typography>
							
							<UserProfileAvatarEdit
								setDeleteAvatar={ handleDeleteAvatar }
								setChangeAvatar={ handleChangeAvatar }
								current_user={current_user}
							/>
						</Box>
					</Box>
					
					<Box
						className={classes.UserProfile_UsernameContainer}
					>
						<Typography
							variant='caption'
						>
							Username
						</Typography>
						
						<UserProfileNameEdit current_user={current_user} />
					</Box>
					
					<Box
						className={classes.UserProfile_EmailContainer}
					>
						<Typography
							variant='caption'
						>
							Email
						</Typography>
						
						<UserProfileEmailEdit current_user={current_user} />
					</Box>
				</Paper>
				
				<Box
					className={classes.UserProfile_PasswordContainer}
				>
					<Typography
						className={classes.GeneralInformationTitle}
						color='#323338'
						variant='h3'
					>
						Password
					</Typography>
					
					<UserProfilePasswordEdit/>
				</Box>
			</Box>
		</>
	)
}


export default ManageProfileTab;
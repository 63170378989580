import { Box, Checkbox } from '@mui/material';
import { WorkOrderField } from 'pages/CreateWorkOrder/work_order_types';


export interface CheckboxListProps
{
	field: WorkOrderField;
}


// TODO: Delete - use widget
export const CheckboxListOld = ({ field }: CheckboxListProps) =>
{
	const {
		checkbox: { options },
	} = field;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{options.map((option, key) => (
				<Box sx={{ display: 'flex', alignItems: 'center' }} key={key}>
					<Checkbox disabled={true} />
					<Box>{option.label}</Box>
				</Box>
			))}
		</Box>
	);
};


export default CheckboxListOld;
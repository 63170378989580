import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import LoadingSpinner from 'components/loading/LoadingSpinner';

import AppProviders from 'app/providers/AppProviders';

import AppContent from 'app/auth/routing/AppContent';

import './styles/app.scss';


function App()
{
	return (
		<Suspense
			fallback={<LoadingSpinner />}
		>
			<BrowserRouter>
				
				<AppProviders>
					<AppContent />
				</AppProviders>
				
			</BrowserRouter>
		</Suspense>
	);
}


export default App;
import * as React from 'react';
import CircularProgress, {
	CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';



function CircularProgressWithLabel(
	props: CircularProgressProps & { value: number, count: number },
) {
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress
				sx={{
					color: defaultAppTheme.palette.success.dark
				}}
				variant="determinate"
				{...props}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant='h4'
				>
					{props.count}
				</Typography>
			</Box>
		</Box>
	);
}


const TotalCount = ({
	count,
} : {
	count: number,
}) =>
{
	return <CircularProgressWithLabel value={100} count={count} />;
};


export default TotalCount;
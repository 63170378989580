


export const tree_default_margin =
{
	top:    20,
	left:   50,
	right:  80,
	bottom: 20
};



export const tree_link_color = 'rgb(254,110,158,0.25)';



import { useEffect, useState } from 'react';

// import { PartsNeeded } from 'types';

import { Grid, Stack, Typography } from '@mui/material';
import PartChip from './PartChip';
import { Issue } from 'models';
import ButtonToOpenPartsDialog from 'pages/CreateWorkOrder/GeneralDescription/Parts/PartsModal/ButtonToOpenPartsDialog';
import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';
import { useAuthData } from 'stuff';
import { useUpdateDocProperty } from 'ember';
import { useIssue } from '../IssueProvider';

// import { Part } from 'types';


const PartsNeededList =
({
	issue,
} : {
	issue: Issue,
}) =>
{
	const { claims } = useAuthData();
	const updateDocProperty = useUpdateDocProperty();
	
	const { parts } = useIssue();
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	const [ selected_parts_with_count, setSelectedPartsWithCount ] = useState<PartWithSelectedCount[]>([]);
	
	const generatePartsDataFromPartsNeeded = () =>
	{
		const selected_parts_with_quantity = []
		
		parts.forEach(part =>
		{
			issue.parts_needed?.forEach(part_needed =>
			{
				if(part_needed._id === part._id)
				{
					const part_data = {
						selected_count: part_needed.quantity,
						...part
					}
					
					selected_parts_with_quantity.push(part_data);
				}
			})
		});
		
		setSelectedPartsWithCount(selected_parts_with_quantity);
	};
	
	
	useEffect(() =>
	{
		generatePartsDataFromPartsNeeded()
	}, [parts]);
	
	
	const updatePartsNeeded = (parts: PartWithSelectedCount[]) =>
	{
		const parts_needed = parts.map(part => ({
			_id: part._id,
			name: part.name,
			quantity: part.selected_count
		}))
		
		setSelectedPartsWithCount(parts);
		
		
		console.log({parts_needed});
		
		updateDocProperty(
			`organizations/${claims.organization_id}/issues/${issue._id}`,
			'parts_needed',
			parts_needed
		);
		
		setCloseDialog(!closeDialog);
	}
	
	
	
	return (
		<Grid
			item
			xs={12}
			sm={4}
		>
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='center'
				sx={{
					marginLeft:'10px',
				}}
			>
				<Typography
					variant='h6'
				>
					Parts needed
				</Typography>
				
				<Grid
					container
					width='100%'
					sx={{
						marginTop: '5px',
					}}
				>
					{
						issue?.parts_needed?.map(part =>
							<Grid
								key={part._id}
								item
								sx={{ maxWidth: '100%' }}
							>
								<PartChip part={part} />
							</Grid>
						)
					}
				</Grid>
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					sx={{ width: '100%' }}
				>
					<ButtonToOpenPartsDialog
						parts={selected_parts_with_count}
						onSetParts={updatePartsNeeded}
						button_content='Edit'
						sx={{
							textTransform:'none',
							textDecoration: 'underline',
						}}
					/>
				</Stack>
				
			</Stack>
		</Grid>
	)
}


export default PartsNeededList;
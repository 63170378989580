import { LatLngExpression } from 'leaflet';





export const ST_JOHNS = [47.5615, -52.7126];
export const ROME = [41.902783, 12.496366];
export const REYKJAVIK = [64.12738671814198, -21.895751953125];
export const BREST = [48.38544219115486, -4.482421875000001];
export const KYIV: LatLngExpression = [50.5, 30.5];

export const CITIES =
{
	ROME,
	REYKJAVIK,
	BREST,
	KYIV,
};



export const HOME_LAT = 45.4;
export const HOME_LON = -75.7;


export const HOME: LatLngExpression = [HOME_LAT, HOME_LON];

export const DRAGGABLE_COORDS: LatLngExpression = [HOME_LAT + 0.01, HOME_LON + 0.01];




import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';

import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import CorIcon from 'components/icons/CorIcon';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';

import { WorkOrderStatus, WorkOrderTemplate } from 'models';


const WorkOrderTemplateCard =
({
	work_order_template,
} : {
	work_order_template: WorkOrderTemplate,
})  =>
{
	const navigate = useNavigate();
	
	const { status, work_order_template_num, title, _id, component_ids } = work_order_template;
	
	
	return (
		<>
			<Stack
				direction='column'
				justifyContent='space-between'
				alignItems='flex-start'
				className='related_issue_card'
				onClick={() => navigate(`/work-order-templates/${_id}`)}
			>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='space-between'
					width='100%'
				>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='flex-start'
						width='100%'
						spacing={1}
					>
						<Typography
							variant='subtitle1'
							sx={{
								background: defaultAppTheme.palette.info.dark,
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							WOT #{work_order_template_num}
						</Typography>
						
						<Typography
							variant='body1'
							sx={{
								marginLeft: '5px',
							}}
						>
							{title}
						</Typography>
					</Stack>
					
					
					<Grid
						container
						width='100%'
						sx={{
							marginTop: '5px',
						}}
					>
						<RelatedAssetAndComponents
							component_ids={component_ids}
							division_id={work_order_template.division_id}
						/>
					</Grid>
				</Stack>
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='center'
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='center'
						sx={{
							padding: '16px',
						}}
					>
						<CorIcon
							of={
								(status === WorkOrderStatus.ACTIVE)
									? 'active'
									: 'closed_work_order'
							}
							size='24px'
							color={
								(status === WorkOrderStatus.ACTIVE)
									? defaultAppTheme.palette.success.main
									: defaultAppTheme.palette.primary.main
								}
						/>
						
						<Typography
							variant='body1'
							color={
								(status === WorkOrderStatus.OPEN)
									? defaultAppTheme.palette.secondary.main
									: defaultAppTheme.palette.success.main
							}
						>
							{ status?.toUpperCase() }
						</Typography>
					</Stack>
					
					<ConciseDateAndTime
						datetime={DateTime.fromISO(work_order_template?.created_at)}
					/>
				</Stack>
				
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
		</>
	)
};


export default WorkOrderTemplateCard;
import { ComponentReading, ComponentRecord, WorkOrderStatus, WorkOrderType } from 'models';
import { ScheduleFormData } from 'pages/CreateWorkOrder/scheduling_types';
import { PartsNeeded } from './misc_interfaces';


export enum FormInputType
{
	Instruction = 'instruction',
	Checkbox = 'checkbox',
	Description = 'description',
	CrewSelection = 'crew-selection',
	RunningHour = 'runninghour',
	Radio = 'radio',
	Replace = 'replace',
	Reading = 'reading',
	Done = 'done',
	FailPass = 'fail-pass',
	CustomRadio = 'custom-radio',
	CustomDropdown = 'custom-dropdown',
	Calendar = 'calendar',
	NoYesNa = 'no-yes-na',
	Time = 'time',
	SelectionDivision = 'selection-division',
	Numeric = 'numeric',
	SelectionPosition = 'selection-position',
}


// TODO: Item vs FormItem? Rename
export interface Item
{
	Type: FormInputType,
	
	Description: string,
	ValueRange: string,
	ItemNumber: string,
	
	Required: boolean,
}


export interface FormItem
{
	Name: string,
	
	Items: Item[],
}



export interface Form
{
	VesselName: string,
	VesselId: string,
	Name: string,
	Id: string,
	
	// TODO: ?
	Type: string,
	
	FormItems: FormItem[],
}





// TODO: Move
export interface WorkOrderTemplateOld
{
	_id: string,
	name: string,
	description: string,
	due_date?: string,
	
	
	// TODO: Timestamp?
	datetime_created?: string,
	datetime_changed?: string,
	
	
	type?: WorkOrderType,
	status?: WorkOrderStatus,
	
	
	// TODO: ensure counter wired up?
	work_order_template_num?: string,
	
	// TODO: Units? Datetime estimation was made
	estimated_cost?: string,
	
	
	// TODO: Enum? Meaning?
	priority?: string,
	
	
	
	assigned_to_user_ids?: string[],
	inspected_by_user_ids?: string[],
	inspected_date?: string,
	
	asset_id: string,
	asset_name: string,
	system_id?: string,
	system_name?: string,
	
	
	
	// Should these be applied to WOs generated from this template?
	tag_ids?: string[],
	component_ids?: string[],
	collaborator_ids?: string[],
	linked_issue_ids?: string[],
	parts_needed?: PartsNeeded[],
	
	
	// TODO: Files should not be stored directly in the database
	// TODO: StorageFile
	attachments?: Array<File>,
	
	
	
	// TODO: Recurrence should cover this - remove once replaced
	frequency?: ScheduleFormData,
	
	// TODO: Review
	form_sections: Array<TemplateSection>,
	
	
	created_at?: string,
	updated_at?: string,
	
	work_order_creator: {
		_id: string,
		name: string,
		email: string,
	},
	
	division_id?: string,
	division_code?: string,
}








// TDO: Why wasn't this exported and used elsewhere?
export interface TemplateSection
{
	label: string,
	description?: string,
	fields: Array<TemplateField>,
}


export type TemplateField =
{
	is_required: boolean,
	label: string,
} & ({
	type: FormInputType.Instruction,
} | {
	type: FormInputType.Checkbox,
	value: {
		options: Array<{
			label: string,
		}>,
	},
} | {
	type: FormInputType.Description,
} | {
	type: FormInputType.CrewSelection,
} | {
	type: FormInputType.RunningHour,
} | {
	type: FormInputType.Radio,
	value: Array<{
		label: string,
	}>,
} | {
	type: FormInputType.Replace,
	value: {
		equipment: Array<{
			_id: string,
			label: string,
		}>,
	},
} | {
	type: FormInputType.Done,
	value: string,
} | {
	type: FormInputType.FailPass,
	value: Array<{
		value: string,
		label: string,
	}>,
} | {
	type: FormInputType.CustomRadio,
	value: Array<{
		label: string,
		value: string,
	}>,
} | {
	type: FormInputType.CustomDropdown,
	value: Array<{
		label: string,
		value: string,
	}>,
} | {
	type: FormInputType.Reading,
	value: {
		equipment: Array<{
			_id: string,
			label: string,
		}>,
		component_record: {
			_id: string,
			title: string,
		},
		cumulative?: boolean,
		readingType: 'cumulative' | 'increment',
	},
} | {
	type: FormInputType.Calendar,
} | {
	type: FormInputType.NoYesNa,
	value: Array<{
		value: string,
		label: string,
	}>,
} | {
	type: FormInputType.Time,
} | {
	type: FormInputType.SelectionDivision,
} | {
	type: FormInputType.Numeric,
} | {
	type: FormInputType.SelectionPosition,
})


export type ResponseField =
{
	label: string,
} & ({
	type: FormInputType.Instruction,
} | {
	type: FormInputType.Checkbox,
	value: {
		options: Array<{
			label: string,
			isChecked?: boolean,
		}>,
	},
} | {
	type: FormInputType.Description,
} | {
	type: FormInputType.CrewSelection,
} | {
	type: FormInputType.RunningHour,
} | {
	type: FormInputType.Radio,
	value: Array<{
		label: string,
	}>,
	response?: {
		selectedValue: string,
	},
} | {
	type: FormInputType.Replace,
	value: {
		equipment: Array<{
			_id: string,
			label: string,
		}>,
	},
	response?: {
		value: 'replaced' | 'ok' | '',
	},
} | {
	type: FormInputType.Done,
	value: string,
} | {
	type: FormInputType.FailPass,
	value: Array<{
		value: string,
		label: string,
	}>,
	response?: {
		value: string,
	},
} | {
	type: FormInputType.CustomRadio,
	value: Array<{
		label: string,
		value: string,
	}>,
} | {
	type: FormInputType.CustomDropdown,
	value: Array<{
		label: string,
		value: string,
	}>,
} | {
	type: FormInputType.Reading,
	value: {
		component_record: {
			_id: string,
			title: string,
		},
		cumulative?: boolean,
		readingType: 'cumulative' | 'increment',
	},
	response?: number,
} | {
	type: FormInputType.Calendar,
	response?: {
		date: string | null,
		time: string | null,
	},
} | {
	type: FormInputType.NoYesNa,
	value: Array<{
		value: string,
		label: string,
	}>,
	response?: {
		value: string,
	},
} | {
	type: FormInputType.Time,
	response?: {
		time: string,
	},
} | {
	type: FormInputType.SelectionDivision,
} | {
	type: FormInputType.Numeric,
} | {
	type: FormInputType.SelectionPosition,
})


export interface WorkOrderResponse
{
	_id: string,
	
	work_order_id: string,
	filled_up_by_id: string,
	filled_up_date: string,
	
	form_sections: Array<{
		label: string,
		fields: Array<ResponseField>,
	}>,
}


export interface WorkOrderResponseForm extends WorkOrderResponse
{
	title: WorkOrderTemplateOld['name'],
}




import { Grid, Stack, Typography } from '@mui/material';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import ShortTextFormlet from 'app/gizmos/fields/text/ShortTextFormlet';
import CorIcon from 'components/icons/CorIcon';
import { UseFieldArrayRemove } from 'react-hook-form';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


const RequisitionPartsTemplate =
({
	part,
	partIndex,
	removePart,
} : {
	part: Record<'id', string>,
	partIndex: number,
	removePart: UseFieldArrayRemove,
}) =>
{
	return (
		<Stack
			className='container'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='flex-start'
			spacing={2}
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='space-between'
				width='100%'
			>
				<Typography
					variant='subtitle1'
					sx={{
						padding: '4px 12px',
						borderRadius: '4px',
						bgcolor: `${defaultAppTheme.palette.primary.light}`,
					}}
				>
					Part#{partIndex + 1}
				</Typography>
				
				<CorIcon
					of='close'
					size='24px'
					color={defaultAppTheme.palette.secondary.main}
					cursor='pointer'
					onClick={() => removePart(partIndex)}
				/>
			</Stack>
			
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					md={6}
					lg={6}
				>
					<Stack>
						<ShortTextFormlet
							label='Name of Part'
							help='M12 bolt'
							name={`items.parts[${partIndex}].name`}
						/>
						
						<ShortTextFormlet
							label='Quantity'
							help='e.g. 2'
							name={`items.parts[${partIndex}].quantity`}
						/>
						
						<CostFormlet
							label='Price per Unit'
							help='e.g. 121.99 CAD'
							name={`items.parts[${partIndex}].unit_price`}
						/>
					</Stack>
				</Grid>
				
				<Grid
					item
					xs={12}
					md={6}
					lg={6}
				>
					<Stack>
						<ShortTextFormlet
							label='Manufacturer Name'
							help='e.g. Grainger'
							name={`items.parts[${partIndex}].manufacturer_name`}
						/>
						
						<ShortTextFormlet
							label='Manufacturer Part#'
							help='e.g. 1002414441'
							name={`items.parts[${partIndex}].manufacturer_part_number`}
						/>
						
						<ShortTextFormlet
							label='Website Link'
							help='e.g. https://grainger.com/m12_bolts'
							name={`items.parts[${partIndex}].website_link`}
						/>
					</Stack>
				</Grid>
				
			</Grid>
		</Stack>
	)
};


export default RequisitionPartsTemplate;
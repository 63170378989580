import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStepper } from './useSteppers';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';


export interface StepperProps
{
	handleSubmit: () => void;
	isSubmitting: boolean;
}


export const steps =
[
	'General Description',
	'Work Order Schedule',
	'Form Builder',
];


// TODO: Plural?
export default function Steppers
({
	handleSubmit,
	isSubmitting,
}: StepperProps)
{
	let [ search_params ] = useSearchParams();
	
	let current_step = Number(search_params.get('step')) || 1;
	
	
	const {
		isStepOptional,
		handleBack,
		handleSkip,
		handleNext,
		isStepSkipped,
	} = useStepper(handleSubmit);
	
	
	const width = 'min(100%, 926px)';
	
	
	return (
		<Box>
			<Stepper
				activeStep={current_step}
				sx={{ width, marginInline: 'auto' }}
			>
				
				{steps.map((label, index) => {
					const stepProps: { completed?: boolean } = {};
					const labelProps: {
						optional?: React.ReactNode;
					} = {};
					if (isStepOptional(index)) {
						labelProps.optional = (
							<Typography variant='caption'>Optional</Typography>
						);
					}
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			
			
			<Box
				sx={{
					width: '100%',
					height: '1px',
					backgroundColor: '#d8dbdf',
					marginBlock: '1rem',
				}}
			>
				
			</Box>
			
			
			<Box sx={{ width, marginInline: 'auto' }}>
				<React.Fragment>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							pt: 2,
						}}
					>
						<Button
							color='inherit'
							disabled={current_step === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Back
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />
						{isStepOptional(current_step) && (
							<Button
								color='inherit'
								onClick={handleSkip}
								sx={{ mr: 1 }}
							>
								Skip
							</Button>
						)}
						<LoadingButton
							onClick={handleNext}
							loading={isSubmitting}
						>
							{
								(current_step >= steps.length)
								? 'Finish'
								: 'Next'
							}
						</LoadingButton>
					</Box>
				</React.Fragment>
			</Box>
			
		</Box>
	);
}






export enum CustomFormFieldType
{
	UNDETERMINED = 'Undetermined',
	
	TEXT = 'Text',
	LONG_TEXT = 'Long Text',
	
	COST = 'Cost',
	
	MULTIPLE_CHOICE = 'Multiple Choice',
	CHECKBOX = 'Checkbox',
	
	PASS_FAIL = 'Pass/Fail',
	YES_NO = 'Yes/No',
	
	DATE = 'Date',
	TIME = 'Time',
	DATETIME = 'Date/Time',
	
	READING = 'Reading',
	REPLACE = 'Replace',
}


export enum PassFailEnum
{
	PASS = 'Pass',
	FAIL = 'Fail',
	N_A = 'N/A',
}


export enum YesNoEnum
{
	YES = 'Yes',
	NO = 'No',
}


export enum ReadingType
{
	MAXIMUM = 'Maximum',
	MINIMUM = 'Minimum',
}


export enum FieldOrientation
{
	ROW = 'Row',
	COLUMN = 'Column',
}



import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme?: any) => createStyles({

	AvatarTitle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',

		margin: '1.5rem 0',
	},

	PageLinks: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		margin: '1.5rem 1rem',
	},

	PageLinkText: {
		marginLeft: '1rem',
	}

}))
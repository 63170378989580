import { Asset, CurrentComponent, System } from 'models';
import Typography from "@mui/material/Typography";
import { defaultAppTheme } from "styles/mui_app_theme/defaultAppTheme";
import AvatarOrError from "components/images/AvatarOrError";



const SystemsAssetRefCard = (
{
	asset,
	system,
	component,
}:
{
	asset?: Asset,
	system?: System,
	component?: CurrentComponent
}) =>
{
	return (
		<div
			className='systems_asset_card_container'
		>
			
			<div className='asset_card_top_section'>
				<div className='asset_card_top_section_main'>
					<AvatarOrError
						src={asset?.image_path || '/organizations/corsphere/files/test_vessel_2.png'}
						alt={asset?.name || component?.asset_name}
						cuid={asset?._id || component?.asset_id}
						sx={{ height: '35px', width: '35px'}}
					/>
					<div className='asset_card_heading'>
						<Typography variant='subtitle1'>
							{asset?.name || component?.name}
						</Typography>
						
						
						<Typography
							variant='caption' 
							sx={{ color: defaultAppTheme.palette.primary.main }}
						>
							{
								component ? component.system_name :
								system ? system?.name : asset?.status
							}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	)
}


export default SystemsAssetRefCard;
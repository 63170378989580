import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme?: any) => createStyles({
	ManageProfileContainer: {
		marginLeft: '5rem',
	},

	GeneralInformationContainer: {
		paddingTop: '2rem',
		paddingBottom: '2rem',
		paddingLeft: '3rem',
		paddingRight: '10rem',
	},

	GeneralInformationTitle: {
		marginLeft: '1rem',
		marginBottom: '.5rem',
	},

	UserAvatarContainer: {
		display: 'flex',
	},

	UserAvatarContainer__mobile: {
		margin: '.5rem 0',
	},

	UserProfileBtns: {
		display: 'flex'
	},

	UserProfileContainer: {
		marginLeft: '1rem',
	},

	UserProfile_UploadBtn: {
		padding: '0',
		fontSize: '16px',
		marginRight: '1rem',
	},

	UserProfile_DeleteBtn: {
		padding: '0',
		fontSize: '16px',
		color: '#666666'
	},

	UserProfile_UsernameContainer: {
		marginTop: '2rem',
	},

	UserProfile_UsernameField: {
		display: 'flex',
		alignItems: 'center',
		padding: '0',
		margin: '0',
	},

	UserProfile_Username: {
		width: '20rem',
		color: '#323338',
	},

	UserProfile_Username__mobile: {
		color: '#323338',
	},

	UserProfile_UsernameBtn: {
		fontSize: '16px',
		fontWeight: '500',
		padding: '0',
		margin: '0',
	},

	UserProfile_EmailContainer: {
		marginTop: '2rem',
	},

	UserProfile_PasswordContainer: {
		marginTop: '2rem',
		marginBottom: '.5rem',
	},

	UserProfile_PasswordBtn: {
		marginLeft: '2rem',
		fontSize: '16px',
		borderRadius: '5px',
	},

	UserProfileAvatarContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		marginTop: '1rem'
	},

	UserProfilePageContainer: {
		padding: '1rem',
	},

	UserProfileItemsContainer: {
		marginTop: '1rem',
	},

	UserProfileItems: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',

		padding: '1.5rem 0',
	},

	UserProfileItems__right: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	UserProfileItems__rightIcon: {
		width: '20px',
		height: '20px',
		marginLeft: '.5rem',
	},

	UserProfileItemContainer: {
		padding: '1rem',
	},

	UserProfileItemField: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: '40px',
		padding: '2px',
	},

	UserProfileItemBtn: {
		marginTop: '2rem',
		padding: '.5rem 3rem',
		width: '100%',
	},

}))
import { List, ListItem, ListItemButton, ListItemAvatar, ListItemText, IconButton } from '@mui/material';
import UserAvatar from 'components/avatars/UserAvatar';
import { CollaboratorListProps } from '../interfaces';
import { User } from 'types';
import { useStyles } from '../theme';
import CorIcon from 'components/icons/CorIcon';


const SelectedCollaboratorsList = ({users, handleChange, selectedCollaborators } : CollaboratorListProps) => 
{
	const classes = useStyles();
	
	
	return (
		<List dense>
			{
				users.map((user: User, key: number) =>
					<ListItem
						key={user._id + key + 'selected-users'}
						className={classes.SelectedCollaboratorsListItem}
						secondaryAction={
							<IconButton
								value={`${user._id}`}
								disableRipple
								onClick={() => handleChange(user)}
							>
								<CorIcon of='close_outlined' color='#666666' size='20px' />
							</IconButton>
						}
					>
						<ListItemButton>
							
							<ListItemAvatar>
								<UserAvatar user={user} />
							</ListItemAvatar>
							
							<ListItemText 
								id={user._id} 
								primary={`${user.display_name}`} 
								secondary={`${user.email}`} 
								className={classes.CollaboratorsListItemText}
							/>
							
						</ListItemButton>
						
					</ListItem>
				)
			}
		</List>
	)
}


export default SelectedCollaboratorsList;
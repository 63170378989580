import { DateTime, Duration, Interval } from 'luxon';

import { capitalizeFirst } from 'utils';














export const showDateTime = (datetime = DateTime.now()) =>
{
	const format = " yyyy-MM-dd 'at' HH:mm";
	
	if(!datetime.weekdayShort)
	{
		datetime = DateTime.fromISO(datetime);
	}
	
	return datetime.weekdayShort + datetime.toFormat(format)
}



// Ex: '2022-02-06T22:53:54.616-03:30' => 'Sun, Feb 6, 10:53 PM'
export const fullDateAndTimeFromISO = (iso) =>
{
	return fullDateAndTimeText(DateTime.fromISO(iso));
}



// Ex: 'Sun, Feb 6 at 10:53 PM'
export const wordyPlainDateAndTimeText = (datetime) =>
{
	let str = datetime.toLocaleString({
		weekday: 'short',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hourCycle: 'h23',
	})
	
	let comma_index = str.lastIndexOf(',');
	
	return str.slice(0, comma_index) + ' at' + str.slice(comma_index + 1);
}


// Ex: 'Sun, Feb 6, 10:53 PM'
export const plainDateAndTimeText = (datetime, include_year = false) =>
{
	if(include_year)
	{
		return datetime.toLocaleString({
			weekday: 'short',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hourCycle: 'h23',
		})
	}
	else
	{
		return datetime.toLocaleString({
			weekday: 'short',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hourCycle: 'h23',
		})
	}
}


// Deprecated - use conciseDateAndTime
// Ex: 'Sun, Feb 6, 10:53 PM'
export const fullDateAndTimeText = (datetime) =>
{
	if(!datetime)
	{
		return null;
	}
	
	
	if(!datetime.weekdayShort)
	{
		datetime = DateTime.fromISO(datetime);
	}
	
	
	let string = plainDateAndTimeText(datetime);
	
	let needle = ', ';
	let n = string.lastIndexOf(needle);
	
	
	if(n >= 0)
	{
		return (
			<time>
				{string.substring(0, n)}
				<span className='minor'> at </span>
				{string.slice(n + 2)}
			</time>
		)
	}
	
	return (
		<time>
			string
		</time>
	)
}



// Ex: '2021-11-01T11:32:00.000-04:00' => '13 seconds ago'
export const timeSinceText =
(
	iso_datetime
) =>
{
	let datetime = DateTime.fromISO(iso_datetime);
	
	let interval = Interval.fromDateTimes(datetime, DateTime.now());
	
	let length_in_s = interval.length('seconds');
	
	let relative_datetime_text;
	
	
	// Seconds ago
	if(length_in_s < 10)
	{
		//var relative_datetime_text = Math.floor(length_in_s) + ' seconds ago';
		relative_datetime_text = 'Seconds ago';
	}
	// Less than a minute
	else if(length_in_s < 60)
	{
		relative_datetime_text = 'Less than a minute ago';
	}
	// Less than an hour
	else if(length_in_s < 3600)
	{
		let minutes = Math.floor(length_in_s / 60);
		
		relative_datetime_text = minutes + ' minute' + ((minutes !== 1) ? 's' : '') + ' ago';
	}
	// Less than a day
	else if(length_in_s < 24 * 3600)
	{
		let hours = Math.floor(interval.length('hours'));
		
		relative_datetime_text = hours + ' hour' + ((hours !== 1) ? 's' : '') + ' ago';
	}
	// Full date
	else
	{
		relative_datetime_text = plainDateAndTimeText(datetime);
		
		var already_full_date = true;
	}
	
	
	// Same calendar day
	// '24 minutes ago' => '24 minutes ago (10:17 PM)'
	if(
		(length_in_s < 24 * 3600) &&
		(datetime.toISODate() === DateTime.local().toISODate())
	)
	{
		relative_datetime_text = relative_datetime_text
			+ ' (' + datetime.toLocaleString(DateTime.TIME_SIMPLE) + ')';
	}
	else if(!already_full_date)
	{
		relative_datetime_text = relative_datetime_text
			+ ' (' + plainDateAndTimeText(datetime) + ')';
	}
	
	
	// '24 minutes ago' => '24 minutes ago (2022-02-05T22:17:58.997-03:30)'
	// if(always_include_iso)
	// {
	// 	relative_datetime_text = relative_datetime_text + ' (' + datetime.toISO() + ')';
	// }
	
	
	return relative_datetime_text;
}





// Ex: Today, Last week (1 day ago), Next week (in 11 days),
//     In 4 weeks (in 32 days)
export function relativeDateText
(
	date,
	today = DateTime.local()
)
{
	if(!date)
	{
		return null;
	}
	
	
	if(date.hasSame(today, 'day'))
	{
		return 'Today';
	}
	
	// Ex: -4.589890728967
	let days_from_now = date.diff(today).as('days');
	
	if(days_from_now < 0)
	{
		let days = -Math.floor(days_from_now + 1);
		days_from_now = days + ' day'
			+ (days !== 1 ? 's' : '')
			+ ' ago'
	}
	else
	{
		let days = Math.floor(days_from_now + 1);
		days_from_now = 'In ' + days + ' day'
				+ (days !== 1 ? 's' : '')
	}
	
	days_from_now = capitalizeFirst(days_from_now);
	
	
	// Ex: 2 weeks ago, This week, Next week, In 3 weeks
	let relative_to_today = date.toRelativeCalendar({unit: 'weeks'})
			.replace(' ago', '')
			.replace('in ', '');
	
	return days_from_now + ' (' + relative_to_today + ')';
}













// Provide interval to get a Generator object that produces datetimes within interval
// Generators are functions that can be exited and later re-entered
// Their context (variable bindings) will be saved across re-entrances
function* daysWithinGenerator(interval)
{
	let cursor = interval.start.startOf('day');
	
	while (cursor < interval.end)
	{
		yield cursor;
		
		cursor = cursor.plus({ days: 1 });
	}
}



// Returns array of all ISO dates between start and end ISO dates (inclusive)
// Ex: '2021-03-01', '2021-03-03' => ['2021-03-01', '2021-03-02', '2021-03-03']
export const arrayOfDates = (start_or_interval, end = null) =>
{
	let interval = start_or_interval;
	
	if(end)
	{
		interval = Interval.fromDateTimes(
			DateTime.fromISO(start_or_interval).startOf('day'),
			DateTime.fromISO(end).endOf('day')
		);
	}
	
	if(!Interval.isInterval(interval))
	{
		console.error('Invalid interval', start_or_interval, end, interval);
	}
	
	return Array.from(daysWithinGenerator(interval)).map(x => x.toISODate());
}




// Uses the generator to produce an array of DateTimes for each day
export const arrayOfDaysWithin = (interval) =>
{
	let days = [];
	
	for(let day of daysWithinGenerator(interval))
	{
		days.push(day);
	}
	
	return days;
}






// TODO: DOCUMENT
export function durationText(timeInMs)
{
	let dur = Duration.fromMillis(timeInMs)
			.shiftTo('hours', 'minutes', 'seconds')
	
	let text = '';
	
	
	//console.log(dur.normalize().toObject())
	
	if(dur.hours)   text = text + dur.hours + 'h ';
	if(dur.minutes) text = text + dur.minutes + 'm ';
	if(dur.seconds) text = text + Math.round(dur.seconds) + 's ';
	
	
	return text;
}



// Generates array of DateTimes
// (Mondays in chronological order)
export function generateMondaysForSchedule
(
	base_date    = DateTime.local(),
	weeks_before = 2,
	weeks_after  = 2
)
{
	let base_monday = base_date.startOf('week');
	let mondays = [];
	
	for(let i = -weeks_before; i <= weeks_after; i++)
	{
		mondays.push(base_monday.plus({weeks: i}))
	}
	
	return mondays;
}



// Generates array of DateTimes
// (7 days in chronological order, Mon-Sun)
export function generateWeekOfDaysFromMonday
(
	monday = DateTime.local().startOf('week')
)
{
	let days = [];
	
	for(let i = 0; i <= 6; i++)
	{
		days.push(monday.plus({days: i}))
	}
	
	return days;
}



import { Box, TextField, RadioGroup, Radio, Button, Typography, Stack } from '@mui/material';
import {
	Controller,
	FieldArrayWithId,
	useFieldArray,
	useWatch,
} from 'react-hook-form';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';


export interface MultipleChoiceProps extends ActionProps {}


export const FillableRadioField = (props: MultipleChoiceProps) =>
{
	return (
		<>
			<Description {...props} />
			<Options {...props} />
		</>
	);
};


interface DescriptionProps extends ActionProps {}


const Description = ({ control, sectionIndex, fieldIndex, sections }: DescriptionProps) =>
{
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='flex-start'
			spacing={1}
			className='form_field_description'
		>
			<Typography
				variant='subtitle1'
				sx={{
					lineHeight: 0
				}}
			>
				{sectionIndex+1}.{fieldIndex+1}
			</Typography>
			
			<Typography
				variant='subtitle2'
				className='form_field_description'
			>
				{sections[sectionIndex].fields[fieldIndex].multiple_choice.description}
			</Typography>
		</Stack>
	);
};


interface OptionsProps extends ActionProps {}


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	const { fields: option_fields, update } = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options`,
	});
	
	const options = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options`,
	});
	
	
	
	
	const handleSelectedOptionChange = (index: number) =>
	{
		options.forEach((option, idx) => {
			update(idx, {
				label: option.label,
				checked: index === idx,
			});
		});
	};
	
	
	return (
		<Box>
			<RadioGroup
				onChange={(e) => {
					const index = +e.target.value;
					if (isNaN(index)) return;
					handleSelectedOptionChange(index);
				}}
			>
				{option_fields.map((field, optionIndex) => (
					<Option
						control={control}
						field={field}
						sectionIndex={sectionIndex}
						fieldIndex={fieldIndex}
						optionIndex={optionIndex}
					/>
				))}
			</RadioGroup>
		</Box>
	);
};


interface OptionProps extends Omit<ActionProps, 'fieldId'>
{
	field: FieldArrayWithId<
		StepperForm,
		`sections.${number}.fields.${number}.multiple_choice.options`,
		'id'
	>;
	optionIndex: number;
}


const Option =
({
	control,
	field,
	fieldIndex,
	sectionIndex,
	optionIndex,
	sections,
}: OptionProps) =>
{
	// console.log({
	// 	optionIndex,
	// 	field
	// })
	
	
	return (
		<Box
			key={field.id}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.5rem',
			}}
		>
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options.${optionIndex}.checked` as const
				}
				defaultValue={false}
				render={({ field: { value, onChange } }) => (
					<Radio
						key={field.id}
						value={optionIndex}
						checked={!!value}
						onChange={(e) => {
							const value = !!e.target.value;
							onChange(value);
						}}
					/>
				)}
			/>
			
			<Typography>
				{field.label}
			</Typography>
			
		</Box>
	);
};


export default FillableRadioField;
import { Stack, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { Action, User } from 'models';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import { numberWithCommas } from 'utils';


const ActionCollaborationAccountChargeInfo = ({
	action,
	user,
	remaining_balance,
} : {
	action: Action,
	user: User,
	remaining_balance: number,
}) =>
{
	
	
	return (
		<Stack
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			width='100%'
			gap={1}
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-start'
				gap={1}
			>
				<Typography
					variant='h4'
				>
					Remaining balance:
				</Typography>
				<Typography
					variant='h3'
				>
					${numberWithCommas(remaining_balance?.toString())} {user?.budget_spent_current_period?.currency}
				</Typography>
				<Typography
					variant='h4'
				>
					(Limit: ${numberWithCommas(user?.budget_limit?.quantity)} {user?.budget_limit.currency})
				</Typography>
				<CorIcon
					of='check'
					size='24px'
					color={defaultAppTheme.palette.success.dark}
				/>
			</Stack>
			
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-start'
				gap={1}
			>
				<Typography
					variant='h4'
				>
					Your account will be charged for:
				</Typography>
				<Typography
					variant='h3'
				>
					${numberWithCommas(action?.accounts_charged_amount?.quantity)} {action?.accounts_charged_amount?.currency}
				</Typography>
				<CorIcon
					of='warning_triangle'
					size='24px'
					color={defaultAppTheme.palette.warning.dark}
				/>
			</Stack>
		</Stack>
	)
};


export default ActionCollaborationAccountChargeInfo;
import { useState } from 'react';
import { useTitle } from 'stuff';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import IssueDetailsTab from './issueDetails/IssueDetailsTab';
import IssueChat from 'app/widgets/chat/IssueChat';
import { useLocation } from 'react-router-dom';
import ActionsTab from './actions/ActionsTab';
import { useIssue } from './IssueProvider';



const tab_titles = ['Details', 'Communication', 'Actions'];



const getTabNumberFromSearchParam = (tab: string | undefined) =>
{
	switch (tab)
	{
		case 'details':
			return 1;
		case 'communication':
			return 2;
		case 'actions':
			return 3;
		default:
			return 1;
	}
};



const IssueCollaborationTabs = () =>
{
	const { search } = useLocation();
	
	const { issue } = useIssue();
	
	
	const tab = getTabNumberFromSearchParam(
		new URLSearchParams(search).get('tab')
	);
	
	
	
	const [selected_tab, setSelectedTab] = useState(`${tab}`);
	
	useTitle(
		`Issue #${issue.issue_num} - ${issue.name}`
	);
	
	
	
	const handleChange = (event: React.SyntheticEvent, newValue: string) =>
	{
		setSelectedTab(newValue);
		
		const url = new URL(document.location.href)
		
		switch(newValue)
		{
			case '1':
				url.searchParams.set('tab', 'details');
				break;
			case '2':
				url.searchParams.set('tab', 'communication');
				break;
			case '3':
				url.searchParams.set('tab', 'actions');
				break;
			default:
				url.searchParams.set('tab', 'details');
				break;
		}
		
		window.history.pushState({}, document.title, url.toString());
	};
	
	
	
	const tabs =
		<TabContext value={selected_tab}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}
				className='issue_collab_tabs'
			>
				<TabList
					onChange={handleChange}
					className='issue_collab_tabs_list'
				>
					<Tab label={tab_titles[0]} value={'1'} />
					<Tab label={tab_titles[1]} value={'2'} />
					<Tab label={tab_titles[2]} value={'3'} />
				</TabList>
			</Box>
			
			<TabPanel value='1' className='issue_collab_tab_panel'>
				<IssueDetailsTab />
			</TabPanel>
			
			<TabPanel
				value='2'
				// className='issue_collab_tab_panel'
				sx={{
					padding: '0',
					// TODO: avoid magic numbers
					height: 'calc(100% - 155px)',
				}}
			>
				<IssueChat />
			</TabPanel>
			
			<TabPanel value='3' className='issue_collab_tab_panel'>
				<ActionsTab />
			</TabPanel>
		</TabContext>
	
	
	
	return (
		<>
			{tabs}
		</>
	);
};


export default IssueCollaborationTabs;
import React, { ReactNode, useContext, useEffect } from 'react';

import { CostCenter, CostCode, CostRecord, CurrentComponent, Issue, StorageFile, WorkOrderNew, WorkOrderStatus } from 'models';
import { Part, Tag, User } from 'types';
import { cuid, DateTime, useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally, useDocArrayUnion, useUpdateDocProperty, useUploadFile, useWrite } from 'ember';
import { where } from 'firebase/firestore';
import { useSession } from 'app/providers/SessionProvider';

import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { FormSection } from 'pages/CreateWorkOrder/work_order_types';
import { excludeUndefinedValues } from 'utils/obj_utils_new';
import { Role } from 'models/users/user_interfaces';
import { useBreadcrumbData } from 'app/providers/BreadcrumbDataProvider';




export interface WorkOrderData
{
	work_order: WorkOrderNew,
	components: CurrentComponent[],
	tags: Tag[],
	
	// related_workorder_template: WorkOrderTemplateOld[];
	
	files: StorageFile[],
	other_users: User[],
	parts: Part[],
	issues: Issue[],
	assigned_to_roles: Role[],
	cost_centers: CostCenter[],
	cost_codes: CostCode[],
	
	
	form?: UseFormReturn<WOFormData>,
	submitWorkStepsResponse?: Function,
	updateActualCost?: Function,
	updateWorkOrderStatus?: Function,
	uploadFiles?: Function,
	updateCostCenters?: Function,
	updateCostCodes?: Function,
	updateAssignedToRoles?: Function,
};


export interface WOFormData
{
	title: string;
	sections: FormSection[];
	actual_cost: {
		currency: string,
		quantity: string,
	},
	
	cost_centers: CostCenter[],
	cost_center_ids: string[],
	
	cost_codes: CostCode[],
	cost_code_ids: string[],
	
	roles: Role[],
};


const WOContext = React.createContext<WorkOrderData>({
	work_order: null,
	components: [],
	tags: [],
	files: [],
	other_users: [],
	parts: [],
	issues: [],
	assigned_to_roles: [],
	cost_centers: [],
	cost_codes: [],
});


export function useWorkOrder()
{
	return useContext(WOContext);
}



const WorkOrderProvider =
({
	work_order,
	children,
} : {
	work_order: WorkOrderNew,
	children: ReactNode,
}) =>
{
	console.log('WORK ORDER', {work_order})
	
	const { claims } = useAuthData();
	const { users } = useSession();
	
	const datetime = DateTime.utc().toISO();
	
	const updateDocProperty = useUpdateDocProperty();
	const arrayUnion = useDocArrayUnion();
	const uploadFile = useUploadFile();
	const write = useWrite();
	
	const breadcrumbs_data = useBreadcrumbData();
	
	
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	
	// TODO: Reduce querying
	
	
	// Get Equipment (component) data
	const components_collection = useCollectionConditionally(
		(work_order?.component_ids?.length > 0),
		`${org_path}/components`,
		where('_id', 'in', work_order?.component_ids)
	);
	
	// Get Issues that involve any components specified in this Issue
	const related_issues_collection = useCollectionConditionally(
		(work_order?.issue_ids?.length > 0),
		`${org_path}/issues`,
		where('_id', 'in', work_order?.issue_ids)
	);
	
	
	// Get Tags data
	const tags_collection = useCollectionConditionally(
		(work_order?.tag_ids.length > 0),
		`${org_path}/tags`,
		where('_id', 'in', work_order?.tag_ids)
	);
	
	// Get Files data
	const files_collection = useCollection(
		`${org_path}/files`,
		where('work_order_id', '==', work_order?._id)
	);
	
	// Get Files data from work order templates
	const wot_files_collection = useCollection(
		`${org_path}/files`,
		where('work_order_template_id', '==', work_order?.template_id)
	);
	
	const assets_files_collection = useCollectionConditionally(
		(work_order?.component_ids?.length > 0),
		`${org_path}/files`,
		where('component_id', 'in', work_order?.component_ids)
	);
	
	
	// Get Parts data from parts needed
	const parts_needed_ids = work_order?.parts_needed?.map(x => x._id);
	const parts_collection = useCollectionConditionally(
		(work_order?.parts_needed?.length > 0),
		`${org_path}/parts`,
		where('_id', 'in', parts_needed_ids)
	);
	
	// Get Roles data from parts needed
	const roles_collection = useCollectionConditionally(
		(work_order?.assigned_to_role_ids?.length > 0),
		`${org_path}/roles`,
		where('_id', 'in', work_order?.assigned_to_role_ids)
	);
	
	// Get all cost centers
	const cost_centers_collection = useCollection(`${org_path}/cost_centers`);
	
	// Get all cost codes
	const cost_codes_collection = useCollection(`${org_path}/cost_codes`);
	
	
	
	
	
	
	// Load data from collections
	const components = components_collection?.data as CurrentComponent[] || [];
	const tags = tags_collection?.data as Tag[] || [];
	const other_users = users?.filter(x => x._id !== claims?.user_id) as User[] || [];
	const parts = parts_collection?.data as Part[] || [];
	const related_issues = related_issues_collection?.data as Issue[] || [];
	const assigned_to_roles = roles_collection?.data as Role[] || [];
	
	const cost_centers = cost_centers_collection?.data as CostCenter[] || [];
	const cost_codes = cost_codes_collection?.data as CostCode[] || [];
	
	
	// Merging issue attachments with Asset files
	const general_attachments = files_collection?.data as StorageFile[] || [];
	const asset_files = assets_files_collection?.data as StorageFile[] || [];
	const wot_files = wot_files_collection?.data as StorageFile[] || [];
	const files = general_attachments.concat(asset_files).concat(wot_files);
	
	
	
	const DEFAULT_FORM_DATA: WOFormData =
	{
		title: work_order.title,
		sections: work_order.sections,
		actual_cost: work_order.actual_cost || {
			quantity: '',
			currency: 'CAD',
		},
		
		cost_centers: [],
		cost_center_ids: work_order?.cost_center_ids || [],
		
		cost_codes: [],
		cost_code_ids: work_order?.cost_code_ids || [],
		
		roles: [],
	};
	
	
	
	// TODO: Review use of form
	const form = useForm<WOFormData>({
		defaultValues: {
			...DEFAULT_FORM_DATA
		},
	});
	
	
	
	
	
	
	// TODO: Missing dependency
	useEffect(() =>
	{
		form.setValue(
			'roles',
			assigned_to_roles
		)
	}, [assigned_to_roles])
	
	
	
	useEffect(() =>
	{
		if(work_order)
		{
			breadcrumbs_data.setDisplayText(work_order._id, work_order.title)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [work_order])
	
	
	
	
	
	// breadcrumbs_data.setDisplayText(work_order._id, work_order.title)
	
	
	
	
	
	const submitWorkStepsResponse = () =>
	{
		const { sections } = form.getValues();
		
		const cleaned_sections_data = sections.map(x => excludeUndefinedValues(x))
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'sections',
			cleaned_sections_data
		);
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'updated_at',
			datetime
		);
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'status',
			WorkOrderStatus.IN_PROGRESS
		);
		
		arrayUnion(
			`${org_path}/work_orders/${work_order._id}`,
			'filled_by_user_ids',
			claims?.user_id
		)
		
		console.log('SECTIONS UPDATED', cleaned_sections_data);
	};
	
	
	const updateActualCost = () =>
	{
		const { actual_cost } = form.getValues();
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'actual_cost',
			actual_cost
		);
	};
	
	
	const updateCostCenters = () =>
	{
		const { cost_centers } = form.getValues();
		
		const cost_center_ids = cost_centers.map(x => x._id);
		
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'cost_center_ids',
			cost_center_ids
		);
	};
	
	const updateCostCodes = () =>
	{
		const { cost_codes } = form.getValues();
		
		const cost_code_ids = cost_codes.map(x => x._id);
		
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'cost_code_ids',
			cost_code_ids
		);
	};
	
	
	
	
	const updateWorkOrderStatus = () =>
	{
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'status',
			WorkOrderStatus.COMPLETED
		);
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'updated_at',
			datetime
		);
		
		const cost_record : CostRecord = {
			_id: cuid(),
			cost: {
				...work_order?.actual_cost,
				is_approved: true
			},
			cost_center_ids: work_order?.cost_center_ids,
			cost_code_ids: work_order?.cost_code_ids,
			
			work_order_id: work_order?._id,
			
			issued_by_user: {
				_id: claims.user_id,
				name: claims.name,
				email: claims.email
			},
			created_at: datetime
		};
		
		write(`organizations/${claims?.organization_id}/cost_records`, cost_record);
	};
	
	
	const uploadFiles = (file_list: FileList | null) =>
	{
		if(file_list != null)
		{
			const files: File[] = Array.from( file_list );
			
			if(files.length)
			{
				files?.forEach(file_to_upload =>
				{
					let file_id = cuid();
					
					
					let file : StorageFile =
					{
						_id: file_id,
						name: file_to_upload.name,
						path: `organizations/${claims?.organization_id}/files/${file_id}`,
						size: file_to_upload.size,
						type: file_to_upload.type,
						upload_date: datetime,
						work_order_id: work_order._id,
						file_category: 'RECEIPT',
						last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
					}
					
					
					uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				})
			}
			
			console.log(files);
		}
	};
	
	
	
	const updateAssignedToRoles = () =>
	{
		const { roles } = form.getValues();
		
		console.log({roles});
		
		const assigned_to_role_ids = roles.map(x => x._id);
		
		updateDocProperty(
			`${org_path}/work_orders/${work_order._id}`,
			'assigned_to_role_ids',
			assigned_to_role_ids
		);
		
		console.log('SECTIONS UPDATED', assigned_to_role_ids);
	};
	
	
	
	
	
	const work_order_data: WorkOrderData =
	{
		work_order: work_order,
		components: components,
		tags: tags,
		files: files,
		other_users: other_users,
		parts: parts,
		issues: related_issues,
		assigned_to_roles: assigned_to_roles,
		cost_centers: cost_centers,
		cost_codes: cost_codes,
		
		
		form: form,
		submitWorkStepsResponse: submitWorkStepsResponse,
		updateActualCost: updateActualCost,
		updateWorkOrderStatus: updateWorkOrderStatus,
		uploadFiles: uploadFiles,
		updateCostCenters: updateCostCenters,
		updateCostCodes: updateCostCodes,
		updateAssignedToRoles: updateAssignedToRoles,
	};
	
	
	
	
	return (
		<FormProvider {...form}>
			<WOContext.Provider value={work_order_data}>
				{children}
			</WOContext.Provider>
		</FormProvider>
	)
};


export default WorkOrderProvider;
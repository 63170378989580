import { Button } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
// import MuiIcon from 'components/icons/MuiIcon';


// A MUI button that handles file uploads
const AttachFilesButton =
({
	fileChangeHandler,
	button_text,
	button_variant,
} : {
	fileChangeHandler: any,
	button_text?: string,
	button_variant?: 'text' | 'contained' | 'outlined',
}) =>
{
	return (
		<Button
			variant={ button_variant || 'outlined' }
			component='label'
			sx={{
				gap: '3px',
				justifyContent: 'center',
				alignContent: 'center',
			}}
			// startIcon={
			// 	<MuiIcon
			// 		of='file'
			// 	/>
			// }
		>
			<CorIcon
				of='attachment'
				rotate={1}
				flip_x={true}
				size='24px'
			/>
			{/* Attach */}
			<input
				type='file'
				hidden
				multiple
				onChange={ (e) => {
					fileChangeHandler( e.currentTarget.files )
				}}
			/>
			{ (button_text) && button_text }
		</Button>
	)
}


export default AttachFilesButton;
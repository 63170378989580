import { React, useState } from 'stuff';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CorIcon from 'components/icons/CorIcon';

import ManageProfileTab from '../manage_profile/ManageProfileTab';
import ManageUsersTab from '../manage_user/ManageUsersTab'
import ManageTagsTab from '../manage_tags/ManageTagsTab'
import { useLocation } from 'react-router';
import ManageCostsPage from '../../manage_costs/ManageCostsPage';


/*
	Using Material UI Tab component to render
	Manage Profile
	Mange Users
	Manage Tags
*/
interface TabPanelProps
{
	children?: React.ReactNode;
	index: number;
	value: number;
};



const getTabNumberFromSearchParam = (tab: string | undefined) =>
{
	switch (tab)
	{
		case 'profile':
			return 0;
		case 'users':
			return 1;
		case 'tags':
			return 2;
		case 'costs':
			return 3;
		default:
			return 0;
	}
};






export function TabPanel(props: TabPanelProps)
{
	const { children, value, index, ...other } = props;
	
	return (
		<div
		role='tabpanel'
		hidden={value !== index}
		id={`vertical-tabpanel-${index}`}
		aria-labelledby={`vertical-tab-${index}`}
		{...other}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
}



const SettingsTabletDesktop = () =>
{
	const { search } = useLocation();
	
	const tab = getTabNumberFromSearchParam(
		new URLSearchParams(search).get('tab')
	);
	
	
	const [ tabValue, setTabValue ] = useState(tab);
	
	
	console.log({search});
	
	const handleChange = (event: React.SyntheticEvent, newValue: number) =>
	{
		setTabValue(newValue);
		
		const url = new URL(document.location.href)
		
		switch(newValue)
		{
			case 0:
				url.searchParams.set('tab', 'profile');
				break;
			case 1:
				url.searchParams.set('tab', 'users');
				break;
			case 2:
				url.searchParams.set('tab', 'tags');
				break;
			case 3:
				url.searchParams.set('tab', 'costs')
				break;
			default:
				url.searchParams.set('tab', 'profile');
				break;
		}
		
		window.history.pushState({}, document.title, url.toString());
	};
	
	
	let tab_items = [];
	
	tab_items =
	[
		{ label: 'User Profile',   number: 0,   icon: 'user_avatar', },
		{ label: 'Manage Users',   number: 1,   icon: 'user_multiple' },
		{ label: 'Manage Tags',    number: 2,   icon: 'tags_outlined' },
		{ label: 'Cost Center',    number: 3,   icon: 'cost' },
	]
	
	
	return (
		<>
			<Box
				sx={{
					flexGrow: 1,
					bgcolor: 'bgColor',
					display: 'flex',
					height: 100,
				}}
			>
				<Tabs
					orientation='vertical'
					variant='scrollable'
					value={ tabValue }
					onChange={handleChange}
					aria-label='Vertical tabs'
					sx={{
						borderRight: 1,
						borderColor: 'divider',
					}}
				>
					{
						tab_items.map(( item, idx ) =>
							<Tab
								icon={<CorIcon of={item.icon} size='25px' color='#323338' />}
								iconPosition='start'
								key={idx}
								label={item.label}
								value={item.number}
							/>
						)
					}
				</Tabs>
				
				<TabPanel value={tabValue} index={0}>
					<ManageProfileTab/>
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<ManageUsersTab/>
				</TabPanel>
				<TabPanel value={tabValue} index={2}>
					<ManageTagsTab/>
				</TabPanel>
				<TabPanel value={tabValue} index={3}>
					<ManageCostsPage />
				</TabPanel>
				
			</Box>
		
		</>
	)
}


export default SettingsTabletDesktop
import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button';

import { AssetHistory } from './interface/AssetHistoryInterface';

import Calendar from './calendar/Calendar';

import historyData from '../../../data/history.json';


const FilterableSchedule: React.FC = () =>
{
	const [ assetHistory, setAssetHisotory ] = useState< AssetHistory[] >([]);
	
	const [ vesselNames, setVesselNames ] = useState< string[] >([]);
	
	
	// parse historyDate and create array of vessel names for filterable buttons
	useEffect(() => {
		if (historyData)
		{
			let vesNames: string[] = [];
			
			historyData.forEach((item) => {
				if ( !vesNames.includes( item.VesselName ) && item.VesselName )
				{
					vesNames.push( item.VesselName );
				};
			});
			
			setVesselNames( vesNames );
		};
	}, []);
	
	
	// create AssetHistory array of onclicked vName
	/* return void */
	const whichVessel = ( vName_: string ) =>
	{
		const assetHistories: AssetHistory[] =  JSON.parse(JSON.stringify(historyData));
		const filteredAssets = assetHistories.filter(( asset ) => asset.VesselName === vName_);
		
		setAssetHisotory( filteredAssets );
	};
	
	
	return (
		<div
			className = 'schedule_page'
		>
			<h2>Hello from filterable schedule</h2>
			
			<div
				className = 'schedule_filter_vender_btns'
			>
				{
					vesselNames.map((vName, index) => (
						<span 
							key = { index }
						>
							<Button 
								variant = 'contained'
								onClick = { () => whichVessel( vName ) } 
							>
								{ vName }
							</Button>
						</span>
					))
				}
			</div>
			{
				assetHistory.length > 0 
				&& (
					<Calendar
						asset_histories = { assetHistory }
					/>
				)
			}
		</div>
	)
}


export default FilterableSchedule;
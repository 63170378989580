import { DateTime, Interval } from 'luxon';
import NestedItem from '../nested_sidebar/NestedItem';

import historyData from '../../../../../data/history.json';
import Badge from 'components/badges/Badge';


const AssetHistory = () =>
{
	const dateMonthDay = ( date: string ) =>
	{
		return DateTime.fromISO(date).toLocaleString({ month: 'long', day: 'numeric' });
	}
	
	
	const dateInterval = ( from_date: string, to_date: string ) =>
	{
		return Interval.fromDateTimes(
			DateTime.fromISO(from_date),
			DateTime.fromISO(to_date)
		).length('hour').toString();
	}
	
	
	return (
		<div>
			<NestedItem
				id_title='AssetHistory'
				title='Asset Maintenance History'
				icon_name='history'
				color='blush'
			/>
			
			<div
				className={ 'assetHistory' }
			>
				<div className={ 'assetCard' }>
					{
						historyData.map(( log, key ) =>
						(
							<div
								className='assetCard__wrapper'
								key={ key }
							>
								
								<div
									className='assetCard__orderDate'
								>
									{ dateMonthDay( log.HistoryOrderDate ) }
								</div>
								
								<div>
									<Badge
										title='Issue Tracking: #042'
										color='abyss'
										is_rectangle_shape={ true }
									/>
								</div>
								
								<div>
									{ log.Name }
								</div>
								
								<div
									className='assetCard__subContents'
								>
									Downtime Reported:
									<span>
										{
											log.Complete !== undefined && log.Complete !== null 
											? (
												<Badge
													title={
														dateInterval(log.HistoryOrderDate, log.Complete) 
														+ 'h' }
													color='ash'
												/>
											)
											: null
										}
									</span>
								</div>
								
								<div
									className='assetCard__subContents'
								>
									Status:
									{
										log.Status !== undefined
										? (
											<span>
												<Badge
													title={ log.Status.toLowerCase() }
													color='jade'
												/>
											</span>
										)
										: null
									}
								</div>
								
							</div>
						))
					}
				</div>
			</div>
		</div>
	)
}


export default AssetHistory;
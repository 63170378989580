import { Button, Stack, Typography } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import InstantNotificationPopup from 'app/dashboard/nav/notifications/InstantNotificationPopup';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import AttachFilesButton from 'components/buttons/AttachFilesButton';
import React, { useState } from 'react'
import { useRequisition } from '../../RequisitionDataProvider';
import { numberWithCommas } from 'utils';


const RequisitionCollabFinalCostSection = () =>
{
	const [ fireAlert, setFireAlert ] = useState<boolean>(false);
	
	const { requisition, updateActualCost, uploadFiles } = useRequisition();
	
	const { estimated_cost } = requisition;
	
	
	const handleSubmit = () =>
	{
		updateActualCost();
		setFireAlert(true);
	}
	
	
	return (
		<Stack
			direction='column'
			spacing={2}
		>
			<Typography variant='subtitle1'>
				Report final costs associated with the Requisition
			</Typography>
			
			<InfoHighlight
				severity='warning'
				message_main={`${ numberWithCommas(estimated_cost?.quantity) || 'Not Provided'} ${estimated_cost?.currency || ''}`}
				message_context='Reported Cost'
			/>
			
			<CostFormlet
				name='final_cost'
				label='Report Final Cost'
				help='Please provide the final cost associated with the Requisition before approving charges to accounts'
			/>
			
			<Stack
				direction='row'
				spacing={1}
				alignItems='center'
				justifyContent='flex-end'
			>
				<AttachFilesButton
					fileChangeHandler={uploadFiles}
					button_text='Upload Receipts'
					button_variant='outlined'
				/>
				
				<Button
					onClick={handleSubmit}
					variant='contained'
					disabled={requisition?.final_cost?.is_approved ? true : false}
				>
					Save Changes
				</Button>
			</Stack>
			
			<InstantNotificationPopup
				open={fireAlert}
				setOpen={setFireAlert}
				severity={'success'}
				message='Cost updated successfully'
			/>
		</Stack>
	)
};


export default RequisitionCollabFinalCostSection;
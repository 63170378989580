
// import { Popper } from '@mui/material';

import { InputAdornment } from '@mui/material';
import { Chip } from '@mui/material';
import {
	TextField,
	Autocomplete,}from '@mui/material';
import Formlet from 'app/gizmos/fields/Formlet';
import CorIcon from 'components/icons/CorIcon';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { Controller } from 'react-hook-form';
import { Tag } from 'types';

// import { useStyles } from './theme';


interface SelectTagsProps {
	label: string,
	name?: string,
	tags: Tag[],
	help?: string,
	value?: Tag[],
	onChange?: (event: React.ChangeEvent<{}>, newValue: null | Tag[]) => void,
	id?: string,
	multiple?: boolean,
}


const SelectTagsFormlet =
({
	label,
	value: valueProp,
	onChange: onChangeProp,
	tags,
	help,
	name,
	id,
	multiple,
} : SelectTagsProps) => 
{
	// const classes = useStyles();
	
	
	if(!tags)
	{
		return <LoadingSpinner />
	}
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			{
				(name) ? (
					<Controller
						name={name}
						render={({ field: { value, onChange }}) => (
							<Autocomplete
								id={id}
								options={tags}
								multiple={multiple}
								value={value || []}
								fullWidth
								disablePortal
								forcePopupIcon={false}
								onChange={(e, newValue) => onChange(newValue)}
								style={{backgroundColor: '#fff'}}
								getOptionLabel={(option: Tag) => option.name}
								renderInput={(params) => {
									return <TextField
									{...params}
									variant="outlined"
									placeholder={label}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<InputAdornment position='end'>
												<CorIcon of='tag_plus' size='20px' color='#323338' />
											</InputAdornment>
										)
									}}
									/>
								}}
								renderTags={(tagValue, getTagProps) => {
									return tagValue.map((option, index) => (
										<Chip
											label={option.name}
											{...getTagProps({ index })}
											sx={{
												bgcolor: option.bg_color,
												color: option.color,
												borderRadius: '5px',
												width: '250px',
												justifyContent: 'space-between'
											}}
										/>
									));
								}}
								ChipProps={{ deleteIcon: <CorIcon of='close_outlined' size='20px' color='#f8f9fa' /> }}
								renderOption={(props, option: Tag, selected) => (
									<li 
										{...props}
										style={{
											display: `${selected.selected ? 'none' : 'flex'}`,
											alignItems: 'center',
											backgroundColor: option.bg_color,
											color: option.color,
											borderRadius: '5px',
											padding:'10px',
											width: '250px',
											justifyContent: 'space-between',
											margin: '10px'
										}}
									>{option.name} <CorIcon of='plus_outlined' size='20px' /> </li>
								)}
							/>
						)}
					/>
				) : (
					<Autocomplete
						id={id}
						options={tags}
						multiple={multiple}
						value={valueProp || []}
						fullWidth
						disablePortal
						forcePopupIcon={false}
						onChange={onChangeProp}
						style={{backgroundColor: '#fff'}}
						getOptionLabel={(option: Tag) => option.name}
						renderInput={(params) => {
							return <TextField
							{...params}
							variant="outlined"
							placeholder={label}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<InputAdornment position='end'>
										<CorIcon of='tag_plus' size='20px' color='#323338' />
									</InputAdornment>
								)
							}}
							/>
						}}
						renderTags={(tagValue, getTagProps) => {
							return tagValue.map((option, index) => (
								<Chip
									label={option.name}
									{...getTagProps({ index })}
									sx={{
										bgcolor: option.bg_color,
										color: option.color,
										borderRadius: '5px',
										width: '250px',
										justifyContent: 'space-between'
									}}
								/>
							));
						}}
						ChipProps={{ deleteIcon: <CorIcon of='close_outlined' size='20px' color='#f8f9fa' /> }}
						renderOption={(props, option: Tag, selected) => (
							<li 
								{...props}
								style={{
									display: `${selected.selected ? 'none' : 'flex'}`,
									alignItems: 'center',
									backgroundColor: option.bg_color,
									color: option.color,
									borderRadius: '5px',
									padding:'10px',
									width: '250px',
									justifyContent: 'space-between',
									margin: '10px'
								}}
							>{option.name} <CorIcon of='plus_outlined' size='20px' /> </li>
						)}
						sx={{
							minWidth: '300px'
						}}
					/>
				)
			}
		</Formlet>
	)
}


export default SelectTagsFormlet;
import Scanner from 'app/dashboard/nav/scanner/Scanner';
import MaybeDialog from 'app/widgets/dialogs/MaybeDialog';


interface MaybeQrScanDialogI
{
	data?: Object,
	
	handleSubmit?: Function,
}


const MaybeQrScanDialog =
({
	data,
	
	handleSubmit,
	
	...rest
}
: MaybeQrScanDialogI) =>
{
	
	
	return (
		<MaybeDialog
			open_key='show_qr_scanner'
			
			dialog_title='QR Code Scanner'
			dialog_submit_text='Done'
			
			handleSubmit={() => console.log('Done')}
			
			{...rest}
		>
			
			<Scanner
				handleQrCodeFound={() => console.log('handleQrCodeFound')}
			/>
			
		</MaybeDialog>
	)
}


export default MaybeQrScanDialog;
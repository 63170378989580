import { useEffect, useReducer } from 'react';
import {
	useFirestore,
	collection,
	doc,
	getDoc,
	query,
	where,
	onSnapshot,
} from '../TahrinFirebase';


import { useAuthData } from 'stuff';



// TODO: If displayed to user, enum vales should generally be in Title Case
//       Otherwise, use ALL_CAPS to match enum keys
const ACTIONS =
{
	SELECT_FOLDER: 'select-folder',
	UPDATE_FOLDER: 'update-folder',
	SET_CHILD_FOLDERS: 'set-child-folders',
	SET_CHILD_FILES: 'set-child-files',
};


export const ROOT_FOLDER = { name: 'Root', id: null, path: [] };


function reducer(state, { type, payload })
{
	switch (type)
	{
		case ACTIONS.SELECT_FOLDER:
			return {
				folderId: payload.folderId,
				folder: payload.folder,
				childFiles: [],
				childFolders: [],
			};
		
		case ACTIONS.UPDATE_FOLDER:
			return {
				...state,
				folder: payload.folder,
			};
		
		case ACTIONS.SET_CHILD_FOLDERS:
			return {
				...state,
				childFolders: payload.childFolders,
			};
		
		case ACTIONS.SET_CHILD_FILES:
			return {
				...state,
				childFiles: payload.childFiles,
			};
		
		default:
			return state;
	}
}


// TODO: This should probably be in a file called FolderProvider - refactor
export function useFolder(folderId = null, folder = null)
{
	const { claims } = useAuthData();
	
	const firestore = useFirestore();
	
	
	const [state, dispatch] = useReducer(reducer, {
		folderId,
		folder,
		childFolders: [],
		childFiles: [],
	});
	
	useEffect(() =>
		{
			if (folder == null)
			{
				return dispatch({
					type: ACTIONS.SELECT_FOLDER,
					payload: { folder: ROOT_FOLDER },
				})
			}
			
			dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, folder } });
		},
		[folderId, folder]
	);
	
	
	useEffect(() =>
	{
		// If no folderId then we know its Root_Folder so we pass it
		if (folderId == null) {
			//console.log('Folder null:', folder);
			return dispatch({
				type: ACTIONS.UPDATE_FOLDER,
				payload: { folder: ROOT_FOLDER },
			});
		}
		
		
		// If folderId not null
		getDoc(doc(firestore, 'folders', folderId)).then((docSnap) =>
		{
			if (docSnap.exists())
			{
				//console.log('Folder Data:', docSnap.data());
				const formattedFolderData = {
					id: docSnap.id,
					...docSnap.data(),
				};
				
				dispatch({
					type: ACTIONS.UPDATE_FOLDER,
					payload: { folder: formattedFolderData },
				});
			}
			else
			{
				dispatch({
					type: ACTIONS.UPDATE_FOLDER,
					payload: { folder: ROOT_FOLDER },
				});
			}
		})},
		[ firestore, folderId ]
	);
	
	
	// Looks for child folders of the current location
	useEffect(() =>
		{
			const q =  query(collection(firestore, 'folders'), where('parentId', '==', folderId));
			
			return onSnapshot(q, (querySnapshot) => {
					dispatch({
						type: ACTIONS.SET_CHILD_FOLDERS,
						payload: {
							childFolders: querySnapshot.docs.map((childFolder) => {
								return { id: childFolder.id, ...childFolder.data() };
							}),
						},
					});
				}
			);
		},
		[ firestore, folderId ]
	);
	
	
	// Looks for child files of the current location
	useEffect(() =>
		{
			const q =  query(collection(firestore, 'files'), where('folderId', '==', folderId));
			
			return onSnapshot(q, (querySnapshot) => {
					dispatch({
						type: ACTIONS.SET_CHILD_FILES,
						payload: {
							childFiles: querySnapshot.docs.map((childFile) => {
								return { id: childFile.id, ...childFile.data() };
							}),
						},
					});
				}
			);
		},
		[ firestore, folderId ]
	);
	
	
	return state;
}



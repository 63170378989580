import ErrorWrapper from 'components/errors/ErrorWrapper';
import ImageFromStorage from 'components/images/ImageFromStorage';

import ImageProps from './ImageProps';


function ImageOrError
({
	path,
	alt,
	...rest
} : ImageProps )
{
	if(!path)
	{
		return <div
			className='error'
		>
			Invalid storage path
		</div>
	}
	
	
	return (
		<ErrorWrapper>
			<ImageFromStorage
				path={path}
				alt={alt}
				{...rest}
			/>
		</ErrorWrapper>
	)
}


export default ImageOrError;
import { Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Event } from 'types';


const EventCard = ({
	event,
} : {
	event: Event,
}) =>
{

	const navigate = useNavigate();
	
	const navigateTo = () =>
	{
		if(event.type === 'action')
		{
			if(event?.issue_id === null)
			{
				navigate(`/requisitions/${event.requisition_id}`)
			}
			else
			{
				navigate(`/issues/${event.issue_id}`)
			}
		}
		else if(event.type === 'issue')
		{
			navigate(`/issues/${event.issue_id}`)
		}
		else if(event.type === 'work_order')
		{
			navigate(`/work-orders/${event.work_order_id}`)
		}
		else if(event.type === 'requisition')
		{
			navigate(`/requisitions/${event.requisition_id}`)
		}
	}
	
	
	return (
		<Box
			width='100%'
			sx={{
				padding: '5px',
				cursor: 'pointer',
				':hover': {
					boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
					background: defaultAppTheme.palette.primary.light
				}
			}}
			onClick={navigateTo}
		>
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				spacing={2}
				width='100%'
			>
				<Typography
					sx={{
						background: (event.type === 'action')
							? defaultAppTheme.palette.warning.main
							: (event.type === 'issue')
								? defaultAppTheme.palette.secondary.main
								: (event.type === 'work_order')
									? defaultAppTheme.palette.primary.main
									: 'rgb(182, 122, 231)',
						padding: '4px',
						borderRadius: '4px',
						color: '#fff',
					}}
				>
					{
						(event.type === 'action')
						? 'Action'
						: (event.type === 'issue')
							? 'Issue'
							: (event.type === 'work_order')
								? 'Work Order'
								: 'Requisition'
					}
				</Typography>
				
				<Typography variant='body1'>
					{event?.name}
				</Typography>
			</Stack>
			
			<Divider
				sx={{
					width:'100%',
					marginTop: '5px',
				}}
			/>
		</Box>
	)
};


export default EventCard;
import { Stack } from '@mui/material';
import React from 'react'
import AnalyticsPageTabs from './AnalyticsPageTabs';


const AnalyticsPageMain = () =>
{
	return (
		<Stack>
			<AnalyticsPageTabs />
		</Stack>
	)
}

export default AnalyticsPageMain;
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { User } from 'types';
import { Issue } from 'models';

import UserAvatar from 'components/avatars/UserAvatar';
import CollaboratorsAccordion from './CollaboratorsAccordion';
import { useIssue } from '../../IssueProvider';
import { useSession } from 'app/providers/SessionProvider';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import SearchableCollaboratorsList from 'components/selectCollaborators/desktop/SearchableCollaboratorsList';
import { useEffect, useState } from 'react';
import { useUpdateDocProperty } from 'ember';
import { useAuthData } from 'stuff';

// import Avatar from '@mui/material/Avatar';
// import AvatarOrError from 'components/images/AvatarOrError';
// import { text2hex } from 'utils/color_utils';


const CollaboratorsList =
({
	main_collaborator_title,
} : {
	main_collaborator_title: string,
}) =>
{
	const { issue } = useIssue();
	const { users } = useSession();
	const { claims } = useAuthData();
	
	
	const updateDocProperty = useUpdateDocProperty();
	
	const creator: User = users.filter((user: User) => user._id === issue.issue_creator_id)[0];
	const other_collaborators: User[] = users.filter((user: User) => issue.collaborator_ids.includes(user._id));
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	const [ issueCollaborators, setIssueCollaborators ] = useState<User[]>();
	
	
	useEffect(() =>
	{
		const all_issue_collaborators = other_collaborators.concat(creator);
		
		setIssueCollaborators(all_issue_collaborators);
	}, []);
	
	
	
	
	const updateCollaborators = (user: User) =>
	{
		const collaborator_ids = issueCollaborators.map(x => x._id);
		
		if(collaborator_ids.includes(user._id))
		{
			const filtered_issue_collaborators = issueCollaborators.filter(x => x._id !== user._id);
			
			setIssueCollaborators(filtered_issue_collaborators);
		}
		else
		{
			setIssueCollaborators([
				user,
				...issueCollaborators
			])
		}
	};
	
	
	
	const confirmCollaboratorsUpate = () =>
	{
		const collaborators_excluded_creator = issueCollaborators.filter(x => x._id !== creator._id);
		const new_collaborator_ids = collaborators_excluded_creator.map(y => y._id);
		
		updateDocProperty(
			`organizations/${claims?.organization_id}/issues/${issue._id}`,
			'collaborator_ids',
			new_collaborator_ids
		);
		
		setCloseDialog(!closeDialog);
	}
	
	
	
	return (
		<Stack
			direction='column'
			justifyContent='center'
			alignItems='flex-start'
			width='100%'
			className='collaborators_list_container'
		>
			<Typography
				variant='subtitle1'
			>
				Collaborators
			</Typography>
			
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				width='100%'
			>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{
						margin:'10px',
					}}
				>
					<UserAvatar user={creator} />
					
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						sx={{
							marginLeft: '5px',
						}}
					>
						<Typography
							variant='body1'
						>
							{ main_collaborator_title }
						</Typography>
						<Typography
							variant='caption'
						>
							{ creator?.display_name || issue?.issue_creator_name }
						</Typography>
					</Stack>
				</Stack>
				
				<ButtonToOpenDialog
					button_variant='text'
					dialog_title='Add or remove collaborators'
					button_content='Edit'
					dialog_content={
						<SearchableCollaboratorsList
							selectedCollaborators={issueCollaborators}
							handleSelectedCollaborators={updateCollaborators}
						/>
					}
					sx={{
						textTransform:'none',
						textDecoration: 'underline',
					}}
					closeDialog={closeDialog}
					dialog_submit_text='Confirm'
					handleSubmit={confirmCollaboratorsUpate}
				/>
			</Stack>
			
			<CollaboratorsAccordion
				users={other_collaborators}
			/>
		</Stack>
	)
};


export default CollaboratorsList;
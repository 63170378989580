import { Box, Stack, Typography } from '@mui/material';
// import SearchBar from 'components/searchbar/SearchBar';
import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { User } from 'types';
import { useStyles } from '../theme';
import SelectedCollaboratorsList from './SelectedCollaboratorsList';
import SelectCollaboratorsFormlet from './SelectCollaboratorsFormlet';


const IssueSelectCollaborators = () =>
{
	const { users } = useSessionContext();
	
	const { getValues, setValue } = useFormContext();
	
	const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
	const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
	
	const classes = useStyles();
	
	const selectedCollaboratorsIDs = getValues('collaborator_ids');
	
	// obtain selected users to show in the selected users list
	const selectedCollaborators = users.filter((user) => selectedCollaboratorsIDs.includes(user._id));
	
	useEffect(() => {
		setFilteredUsers(users);
	}, [users])
	
	
	
	
	
	
	const handleSelectedCollaboratorIDs = (user: User) =>
	{
		const { _id } = user;
		
		const selectedCollaboratorsIDs = getValues('collaborator_ids');
		
		const indexOfChosenID = selectedCollaboratorsIDs.indexOf(_id);
		
		if( indexOfChosenID >= 0 )
		{
			// update collaborator IDs in FORM to NOT include selected collaborator
			let newCollaboratorIDs: string[] = selectedCollaboratorsIDs.filter((item: string) => item !== _id);
			setValue('collaborator_ids', newCollaboratorIDs);
			
			// Remove Selected Collaborator to component state
			setSelectedUsers(prevState => prevState.filter((user: User) => user._id !== _id));
		}
		else
		{
			// update collaborator IDs in FORM to include selected collaborator
			let newCollaboratorIDs: string[] = [...selectedCollaboratorsIDs, _id];
			setValue( 'collaborator_ids', newCollaboratorIDs );
			
			const selectedUser: User[] = users.filter(user => user._id === _id);
			
			// Add selected collaborator to component state
			const newSelectedUsers: User[] = [...selectedUsers];
			newSelectedUsers.push(selectedUser[0]);
			setSelectedUsers(newSelectedUsers);
		}
	}
	
	
	
	
	
	
	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			alignItems='flex-start'
			gap={1}
		>
			
			<Box sx={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignContent: 'left', width: '100%', }}>
				<Typography variant='h3' > Add Collaborators to Send Notifications </Typography>
			</Box>
			
			<SelectCollaboratorsFormlet
				selectedCollaborators={selectedCollaborators}
				handleSelectedCollaborators={handleSelectedCollaboratorIDs}
			/>
			
			<Box className={classes.SelectedCollaboratorsListBox}>
				<SelectedCollaboratorsList
					users={selectedCollaborators}
					handleChange={handleSelectedCollaboratorIDs}
				/>
			</Box>
			
		</Stack>
	)
}


export default IssueSelectCollaborators;
import UserCard from 'components/user_cards/UserCard';
import { ChatUser } from '../../../interfaces/content_data_interfaces';


export interface CollaboratorsListProps
{
	_collaborators: ChatUser[];
}


const CollaboratorsList =
({
	_collaborators
}
: CollaboratorsListProps) =>
{
	return (
		<>
			{
				_collaborators.map(( user, key ) =>
					<div
						className='collaboratorsList'
						key={ key }
					>
						<UserCard
							_username={ user.username }
							_userImg={ user.profilePic }
							_userPosition={ user.position }
						/>
					</div>
				)
			}
		</>
	)
}


export default CollaboratorsList;
import { Stack, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { Action, ActionStatus } from 'models';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


const ActionCollaborationHeader = ({
	action,
} : {
	action: Action,
}) =>
{
	return (
		<Stack
			direction='row'
			justifyContent='space-between'
			alignItems='center'
			width='100%'
		>
			<Typography
				variant='h4'
			>
				Please respond to action request by: {action.created_by_user?.name}
			</Typography>
			
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
			>
				<CorIcon
					of={
						(action?.status === ActionStatus.OPEN) ?
						'open_action' : 'closed_action'
					}
					size='20px'
					color={
						(action?.status === ActionStatus.OPEN) ?
						defaultAppTheme.palette.secondary.main :
						(action?.status === ActionStatus.IN_PROGRESS) ?
						defaultAppTheme.palette.primary.main :
						(action?.status === ActionStatus.RESOLVED) ?
						defaultAppTheme.palette.success.main :
						defaultAppTheme.palette.grey['800']
					}
				/>
				
				<Typography
					variant='caption'
					color={
						(action?.status === ActionStatus.OPEN) ?
						defaultAppTheme.palette.secondary.main :
						(action?.status === ActionStatus.IN_PROGRESS) ?
						defaultAppTheme.palette.primary.main :
						(action?.status === ActionStatus.RESOLVED) ?
						defaultAppTheme.palette.success.main :
						defaultAppTheme.palette.grey['800']
					}
					sx={{
						paddingLeft: '5px',
					}}
				>
					{ action.status }
				</Typography>
			</Stack>
		</Stack>
	)
};


export default ActionCollaborationHeader;
import { useAuthData } from 'stuff';

// import ScannerModal from './scanner/ScannerModal';
import { IconButton, CircularProgress, Stack } from '@mui/material';
// import { useLocation } from 'react-router';
// import Icon from 'components/icons/Icon';
import Notifications from './notifications/Notifications';
// import { useWatchTitle } from 'utils/js_hooks';

// import AvatarOrError from 'components/images/AvatarOrError';

import { text2hex } from 'utils/color_utils';
import UserAvatar from 'components/avatars/UserAvatar';
// import QrScannerDialogButton from 'app/widgets/dialogs/QrScannerDialogButton';
// import QrLabelDialogButton from 'app/widgets/dialogs/QrLabelDialogButton';
import { useDoc } from 'ember';
import { User } from 'types';
import QrScanDialogButton from 'app/widgets/dialogs/QrScanDialogButton';
// import { useWatchTitle } from 'utils/js_hooks';
import QrLabelDialogButton from 'app/widgets/dialogs/QrLabelDialogButton';
import HeaderBreadcrumbs from './HeaderBreadcrumbs';
// import { useWindowSize } from 'usehooks-ts';



const TopAppBar = () =>
{
	const { claims } = useAuthData();
	// const [ isScannerModalOpen, setIsScannerModalOpen ] = useState(false);
	
	// const openScannerModal  = () => setIsScannerModalOpen(true);
	// const closeScannerModal = () => setIsScannerModalOpen(false);
	
	// const location = useLocation();
	
	
	// const current_title = useWatchTitle();
	
	// const { width, height } = useWindowSize();
	
	
	const users_collection_path = `organizations/${claims.organization_id}/users`;
	const current_user_doc = useDoc(users_collection_path, claims.user_id);
	
	
	
	// const page_title = document.title;
	
	
	// console.log(location);
	
	// // Ex: '/home/path/' => 'home/path'
	// let slashed_text = (location.pathname[0] === '/')
	// 	? location.pathname.substring(1)
	// 	: location.pathname;
	
	
	// // Split on slashes and remove empty strings
	// // Ex: 'home/path' => ['home', 'path']
	// let parts = slashed_text?.split('/').filter(x => x);
	
	// const page_title = parts[0]?.charAt(0).toUpperCase() + parts[0]?.slice(1) || '';
	
	
	// console.log({
	// 	claims
	// })
	
	// TODO: consult with Sahand on how to change the UI for notification API FE
	
	
	
	if(current_user_doc.is_loading) <CircularProgress />
	
	
	const current_user = current_user_doc.data as User;
	
	
	
	return (
		<Stack
			className='app_bar'
			direction='row'
			alignContent='center'
			alignItems='center'
		>
			
			<HeaderBreadcrumbs />
			
			<Stack
				direction='row'
				alignItems='center'
			>
				
				<QrScanDialogButton />
				
				<QrLabelDialogButton />
				
				<IconButton>
					<Notifications />
				</IconButton>
				
				<UserAvatar
					user={current_user}
					title={`Logged in as ${claims?.name}`}
					src={claims?.user_id}
					cuid={claims?.user_id}
					sx={{
						bgcolor: text2hex(claims?.user_id),
						height: '30px',
						width: '30px',
						marginLeft: '7px',
						border: '1px solid #d8dbdf'
					}}
				/>
				
			</Stack>
			
		</Stack>
	)
};


export default TopAppBar;
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { cuid, DateTime, useAuthData, useState } from 'stuff';

import { useSession } from 'app/providers/SessionProvider';
import { useUploadFile, useWrite } from 'ember';

import { Issue, IssueStatus, StorageFile } from 'models';
import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';
import { Event } from 'types';

import CreateIssueProvider from './CreateIssueProvider';
import CreateIssuePageNavigation from './CreateIssuePageNavigation';
import CreateIssueResponsiveContent from './CreateIssueResponsiveContent';

// import { ref, uploadBytes } from 'firebase/storage';
// import { useStorage } from 'reactfire';


const CreateIssuePageNew = () =>
{
	// Session information
	const { all_available_tags, users, parts } = useSession();
	
	const navigate = useNavigate();
	
	// User information
	const { user, claims } = useAuthData();
	
	const { component_id } = useParams();
	
	const [ partsNeeded, setPartsNeeded ] = useState<PartWithSelectedCount[]>([]);
	const [ files, setFiles ] = useState([]);
	const [ selectedComponents, setSelectedComponents ] = useState([]);
	
	
	const sessionContext =
	{
		all_available_tags: all_available_tags,
		users: users,
		user: user,
		claims: claims,
		parts: parts,
		parts_needed: partsNeeded,
		set_parts_needed: setPartsNeeded,
		files: files,
		set_files: setFiles,
		selected_components: selectedComponents,
		set_selected_components: setSelectedComponents
	}
	
	
	// current date
	let datetime = DateTime.utc().toISO();
	
	const write = useWrite();
	const uploadFile = useUploadFile();
	
	
	const methods = useForm({
		defaultValues: 
		{
			_id: cuid(),
			name: '',
			description: '',
			
			
			status: 'open',
			priority: '',
			
			tag_ids: [],
			
			asset_id: '',
			asset_name: '',
			
			component_ids: [],
			
			parts_needed_ids: [],
			
			
			date_opened: datetime,
			date_closed: '',
			due_date: '',
			
			issue_creator_id: user.uid,
			issue_creator_email: user.email,
			issue_creator_name: user.displayName,
			
			collaborator_ids: [],
			files: [],
			work_order_ids: [],
			activity_log_ids: [],
			
			issue_ids: [],
			events: []
		},
		// shouldUnregister: true,
	});
	
	
	const onSubmit = () =>
	{
		const form_values = methods.getValues();
		
		
		console.log('Submitting form. Values:', form_values);
		
		// refactor if works
		const new_issue_values: Issue =
		{
			_id: form_values._id,
			name: form_values.name,
			description: form_values.description,
			
			
			status: IssueStatus.OPEN,
			priority: form_values.priority,
			
			tag_ids: form_values.tag_ids,
			
			asset_id: form_values.asset_id,
			asset_name: form_values.asset_name, // need this for search functionality
			
			component_ids: form_values.component_ids,
			
			parts_needed: partsNeeded.map(part => ({
				_id: part._id,
				name: part.name,
				quantity: part.selected_count
			})),
			
			
			date_opened: datetime,
			date_closed: '',
			due_date: '',
			
			issue_creator_id: user.uid,
			issue_creator_email: user.email,
			issue_creator_name: user.displayName,
			
			collaborator_ids: form_values.collaborator_ids,
			work_order_ids: form_values.work_order_ids,
			activity_log_ids: [],
			
			issue_ids: [],
			events: []
		}
		
		
		write(`organizations/${claims?.organization_id}/issues`, new_issue_values);
		
		
		console.log('submitted form: ', form_values);
		
		
		
		
		// If any files were selected, upload them to storage, add corresponding
		// documents to Firestore, and also write an event linked to this issue
		
		let attached_files = [];
		
		if(form_values.files.length)
		{
			let folder_id = form_values._id;
			// Create folder here in db
			
			
			form_values.files?.forEach(file_to_upload =>
			{
				let file_id = cuid();
				
				
				let file : StorageFile =
				{
					_id: file_id,
					folder_id: folder_id,
					name: file_to_upload.name,
					path: `organizations/${claims?.organization_id}/files/${file_id}`,
					size: file_to_upload.size,
					type: file_to_upload.type,
					upload_date: datetime,
					issue_id: form_values._id,
					last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
				}
				
				
				uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				
				
				// // Create a reference to 'mountains.jpg'
				// const file_ref = ref(storage, file.path);
				
				// // 'file' comes from the Blob or File API
				// uploadBytes(file_ref, file_to_upload).then((snapshot) => {
				// 	console.log('Uploaded a blob or file!', file, file_to_upload);
				// });
				
				// write(`${org}/files`, file);
				
				
				attached_files.push(file);
			})
			
			
			let event_text = 'Uploaded ' + form_values.files.length
				+ ' file' + ((form_values.files.length > 1) ? 's' : '');
			
			
			let event : Event =
			{
				_id: cuid(),
				name: event_text,
				
				type: 'file',
				subtype: 'upload',
				
				datetime: datetime,
				summary:  event_text,
				issue_id: form_values._id,
				file_ids: attached_files?.map(x => x._id) || [],
				user_id: claims?.user_id,
			};
			
			write(`organizations/${claims.organization_id}/events`, event);
		}
		
		navigate(`/issues/${form_values._id}`);
	}
	
	
	const onErrors = errors => console.error(errors);
	
	
	return (
		<FormProvider
			{...methods}
		>
			<form id='create-issue-form' onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
				<CreateIssueProvider sessionContext={sessionContext}>
					{
						(component_id === undefined)
						?
							<CreateIssueResponsiveContent onSubmit={onSubmit} />
						:
							<CreateIssuePageNavigation onSubmit={onSubmit} />
					}
				</CreateIssueProvider>
			</form>
			
		</FormProvider>
	)
}


export default CreateIssuePageNew;
import { Box } from '@mui/material';
import { styled } from '@mui/styles';


export const HeaderContainer = styled(Box)(() => ({
	paddingInline: '3rem',
	paddingBlock: '1rem',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));


export const HeaderTitle = styled(Box)(() => ({
	fontWeight: 700,
	fontSize: '32px',
}));


export const HeaderSubTitle = styled(Box)(() => ({
	fontSize: '12px',
	fontWeight: 400,
}));


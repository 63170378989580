import { entities } from '.';



// Provide this with a singular type noun, a plural collection name or an Entity object
// Finds the matching entity from our list of entities
// Ex: 'city', 'cities', or Entity<city>
export const resolveEntity = (entity_or_type_or_collection) =>
{
	let match = entities.find(entity => (
		entity_or_type_or_collection === entity        ||
		entity_or_type_or_collection === entity.type   ||
		entity_or_type_or_collection === entity.plural
	))
	
	//console.log('        Resolved', match)
	
	return match || null;
}



// Returns array of lines of text, that should be a TypeScript interface
export const makeTypescript = () =>
{
	let lines = ['declare module namespace\n{\n'];
	
	
	for(let entity of entities)
	{
		lines.push('\texport interface ' + entity.type + '\n\t{');
		
		
		let prop_lines = [];
		
		prop_lines.push('_id: string');
		
		// Ex: defined_type = person rel
		// eslint-disable-next-line no-unused-vars
		for(let [prop_name, prop_def] of Object.entries(entity.prop_defs))
		{
			let type = entity.prop_types[prop_name] || 'string';
			
			let is_required = false;
			
			// May not be a rel
			let rel = entity.rels[prop_name];
			
			
			//console.log('        ', prop_name, prop_type);
			prop_lines.push(
				prop_name
				+ (is_required ? '' : '?')
				+  ': '
				+ (rel ? 'string' : type)
				+ (rel?.is_plural ? '[]' : '')
			);
			
			if(rel)
			{
				prop_lines.push(
					rel.obj_prop_name
					+ (is_required ? '' : '?')
					+  ': '
					+ type
					+ (rel?.is_plural ? '[]' : '')
				);
			}
		}
		
		
		lines.push('\t\t' + prop_lines.join(';\n\t\t') + ';\n\t}\n')
		
	}
	
	
	return lines;
}


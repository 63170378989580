import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { Box, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// import { ScheduleForm } from '../../../types';


export interface RepeatTimeProps
{
	control: Control<any, any>,
	setValue: UseFormSetValue<any>,
	defaultValue: Date | null,
}



export const RepeatTime =
({
	control,
	setValue,
	// name,
	defaultValue,
}: RepeatTimeProps) =>
{
	return (
		<Controller
			control={control}
			name='repeat_time'
			defaultValue={defaultValue}
			render={({ field }) => 
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-end',
						gap: '1rem',
					}}
				>
					<TimePicker
						label='Time'
						onChange={(dateTime) => {
							// TODO: Just use DateTime in form
							// this done due to TimePicker sending DateTime instead of Date
							// which causes type mismatch
							field.onChange(new Date(dateTime));
						}}
						value={field.value}
						renderInput={(params) =>
							<TextField {...params} />
						}
					/>
				</Box>
			}
		/>
	);
};


export default RepeatTime;
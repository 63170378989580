import { Card, InputLabel, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { cuid } from 'stuff';
import { relativeDatetimeText } from 'utils/datetime_utils_new';
import FieldHelp from '../../fields/toggles/FieldHelp';
import ConciseDateAndTime from '../../fields/datetimes/ConciseDateAndTime';



export interface OccurrenceCardListProps
{
	name?: string,
	label?: string,
	description?: string,
	occurrence_card_data?: OccurrenceCardData[],
}



// TODO: Move to interfaces
export interface OccurrenceCardData
{
	_id?: string,
	title?: ReactNode,
	datetime_elem?: ReactNode,
	subtitle?: string,
	datetime?: DateTime,
}



// TODO: Move to helpers
export const makeOccurrenceCardData =
(
	title: string,
	dt: DateTime,
	now: DateTime = DateTime.local(),
) : OccurrenceCardData =>
{
	let _id = cuid();
	
	return ({
		_id: title + _id,
		title: title,
		datetime_elem: <ConciseDateAndTime datetime={dt} />,
		subtitle: relativeDatetimeText(dt, now),
		datetime: dt,
	})
}



export const OccurrenceCardList =
({
	label = 'Date/Times',
	occurrence_card_data = [],
	description,
}
: OccurrenceCardListProps) =>
{
	return (
		<Stack
			sx={{
			}}
		>
			
			<InputLabel>
				{label}
			</InputLabel>
			
			<FieldHelp>
				{description}
			</FieldHelp>
			
			<Stack
				gap={1}
				padding={2}
			>
				{
					occurrence_card_data.map(x =>
						<Card
							sx={{
								padding: 1,
							}}
							key={x._id}
						>
							<Typography
								variant='h3'
							>
								{x.title}
							</Typography>
							<Typography>
								{x.datetime_elem}
							</Typography>
							<Typography>
								{x.subtitle}
							</Typography>
						</Card>
					)
				}
			</Stack>
			
		</Stack>
	);
};


export default OccurrenceCardList;
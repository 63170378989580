import { DateTime, Duration } from 'luxon';
import { ScheduleFormData } from 'pages/CreateWorkOrder/scheduling_types';
import { timestamp2datetime } from 'utils/automation_helpers';
import { wordyPlainDateAndTimeText } from 'utils/datetime_utils_old';
import { RecurrenceFormData } from 'models';




// Ex: ' starting Wed, Nov 2 at 14:47'
export const effectiveDatetimeText = (datetime: DateTime) =>
{
	return (datetime.isValid)
		? ' starting ' + wordyPlainDateAndTimeText(datetime)
		: '...'
}


// Ex: 
export const intervalDuration = (schedule: ScheduleFormData) =>
{
	//schedule.recurrence_type
	
	return Duration.fromObject({
		years: 1,
	})
}






// Ex: 'Repeats every month starting Wed, Nov 2 at 15:42'
export const describeRecurrence = (recurrence: RecurrenceFormData) =>
{
	return '____';
}






// Ex: 'Repeats every month starting Wed, Nov 2 at 15:42'
export const describeSchedule = (schedule: ScheduleFormData) =>
{
	let str = `Repeats `;
	
	
	if(!schedule.recurrence_schedule)
	{
		return null;
	}
	
	
	console.log(schedule)
	
	
	// let datetime = determineEffectiveDatetime(schedule);
	
	
	
	return '...';
	
	
	// let datetime = DateTime.fromObject({
	// 	year:   DateTime.fromJSDate(schedule.recurrence?.effective_date).year,
	// 	month:  DateTime.fromJSDate(schedule.recurrence?.effective_date).month,
	// 	day:    DateTime.fromJSDate(schedule.recurrence?.effective_date).day,
	// 	hour:   DateTime.fromJSDate(schedule.recurrence?.repeat_time).hour,
	// 	minute: DateTime.fromJSDate(schedule.recurrence?.repeat_time).minute,
	// 	second: DateTime.fromJSDate(schedule.recurrence?.repeat_time).second,
	// })
	
	
	
	// TODO: What happened to my new code?
	
	
	
	// if(schedule.recurrence_schedule === 'yearly')
	// {
	// 	if(schedule.recurrence?.recurrence_type === 'interval')
	// 	{
	// 		let repeats_every = schedule.yearly?.interval?.repeats_every;
			
	// 		if(!repeats_every || (repeats_every <= 1))
	// 		{
	// 			str += `every year`;
	// 		}
	// 		else
	// 		{
	// 			str += `every ${repeats_every} years`;
	// 		}
			
			
	// 		str += effectiveDatetimeText(datetime);
	// 	}
	// 	else
	// 	{
	// 		let month_num = months.findIndex(x => x === schedule.yearly?.specific_month_and_day?.month) + 1;
	// 		let day_num = schedule.yearly?.specific_month_and_day?.day;
			
			
	// 		if(!month_num || !day_num)
	// 		{
	// 			str += 'yearly...';
	// 		}
	// 		else
	// 		{
	// 			let now = DateTime.local();
				
	// 			let datetime2 = DateTime.fromObject({
	// 				year: now.get('year'),
	// 				month: month_num,
	// 				day: day_num,
	// 				hour: DateTime.fromJSDate(schedule.recurrence?.repeat_time)?.hour,
	// 				minute: DateTime.fromJSDate(schedule.recurrence?.repeat_time)?.minute,
	// 				second: DateTime.fromJSDate(schedule.recurrence?.repeat_time)?.second,
	// 			})
				
				
	// 			str += `yearly${effectiveDatetimeText(datetime2)}`;
	// 		}
	// 	}
	// }
	// else if(schedule.recurrence_schedule === 'monthly')
	// {
	// 	if(schedule.recurrence?.recurrence_type === 'interval')
	// 	{
	// 		let repeats_every = schedule.monthly?.interval?.repeats_every;
			
	// 		if(!repeats_every || (repeats_every <= 1))
	// 		{
	// 			str += `every month`;
	// 		}
	// 		else
	// 		{
	// 			str += `every ${repeats_every} months`;
	// 		}
			
			
	// 		str += effectiveDatetimeText(datetime);
	// 	}
	// 	else
	// 	{
	// 		let day_num = schedule.monthly?.specific_days;
			
			
	// 		if(day_num > 0)
	// 		{
	// 			let datetime_str = datetime.toLocaleString({
	// 				hour: 'numeric',
	// 				minute: '2-digit',
	// 				hourCycle: 'h23'
	// 			}); //=> '11:32'
				
	// 			str += `every month on the ${day_num}${getNumberSuffix(day_num)} at ${datetime_str}`
	// 		}
	// 		else
	// 		{
	// 			str += `monthly...`
	// 		}
	// 	}
	// }
	// else if(schedule.recurrence_schedule === 'weekly')
	// {
	// 	if(schedule.recurrence?.recurrence_type === 'interval')
	// 	{
	// 		let repeats_every = schedule.weekly?.interval?.repeats_every;
			
	// 		if(!repeats_every || (repeats_every <= 1))
	// 		{
	// 			str += `every week`;
	// 		}
	// 		else
	// 		{
	// 			str += `every ${repeats_every} weeks`;
	// 		}
			
	// 		str += effectiveDatetimeText(datetime);
	// 	}
	// 	else if(schedule.weekly?.specific_days)
	// 	{
	// 		let days = Object.entries(schedule.weekly?.specific_days)
	// 			.filter(([k, v]) => v)
	// 			.map(([k, v]) => toTitleCase(k));
			
	// 		if(days.length === 0)
	// 		{
	// 			str += `weekly...`;
	// 		}
	// 		else if(days.length > 1)
	// 		{
	// 			let short_days = days
	// 				.map(x => x.slice(0, 3));
					
	// 			let days_str = short_days.slice(0,-1).join(', ')
	// 				+ ' and '
	// 				+ short_days.slice(-1)
				
	// 			str += `weekly on ${days_str}`;
	// 		}
	// 		else{
	// 			str += `weekly on ${days.join(', ')}`;
	// 		}
			
	// 		str += ' ' + effectiveDatetimeText(datetime);
	// 	}
	// }
	// else if(schedule.recurrence_schedule === 'daily')
	// {
	// 	if(schedule.daily?.repeats_every > 1)
	// 	{
	// 		str += `every ${schedule.daily?.repeats_every} days`
	// 	}
	// 	else
	// 	{
	// 		str += `daily`
	// 	}
		
	// 	str += ' ' + effectiveDatetimeText(datetime);
	// }
	
	
	
	// console.log(schedule, str);
	
	
	// determineEffectiveDatetime(schedule);
	
	
	// return str;
}





// Either the previous scheduled time or the effective date
export const determineEffectiveDatetime = (schedule: ScheduleFormData) =>
{
	let recurrence_schedule = schedule.recurrence_schedule;
	
	let recurrence_type = schedule.recurrence?.recurrence_type;
	
	
	if(!recurrence_type)
	{
		return;
	}
	
	
	
	console.log(typeof schedule.recurrence.effective_datetime);
	console.log(schedule.recurrence.effective_datetime);
	
	
	let effective   = schedule.recurrence.effective_datetime;
	let repeat_time = schedule.recurrence.time_of_day;
	
	let now = DateTime.local();
	
	
	let obj = (effective instanceof DateTime)
		?
			{
				year:  effective.year  || now.year,
				month: effective.month || now.month,
				day:   effective.day   || now.day,
				
				hour:   repeat_time.hour   || now.hour,
				minute: repeat_time.minute || now.minute,
				second: repeat_time.second || now.second,
			}
		:
			{
				year:  now.year,
				month: now.month,
				day:   now.day,
				
				hour:   repeat_time.hour   || now.hour,
				minute: repeat_time.minute || now.minute,
				second: repeat_time.second || now.second,
			}
		
	
	let effective_datetime = DateTime.fromObject(obj)
	
	
	console.log({
		schedule,
		recurrence_schedule,
		recurrence_type,
		obj,
		effective_datetime,
	})
	
	
	
	return effective_datetime;
}




import { Asset, System, ComponentTreeData } from 'models';
import AssetsNavigationPage from 'app/dashboard/pages/assets/AssetsNavigationPage';
import SystemsNavigationPage from 'app/dashboard/pages/systems/SystemsNavigationPage';
import ComponentTreePanel from 'app/dashboard/pages/components/ComponentTreePanel';


const AssetComponentSelection =
({
	selectedComponents,
	setSelectedComponents,
	selectedSystem,
	setSelectedSystem,
	selectedAsset,
	setSelectedAsset,
} : {
	selectedComponents: ComponentTreeData[],
	setSelectedComponents: Function,
	selectedSystem: System,
	setSelectedSystem: Function,
	selectedAsset: Asset,
	setSelectedAsset: Function,
}) =>
{
	if(selectedSystem)
	{
		return <ComponentTreePanel
			selectedComponents={selectedComponents}
			setSelectedComponents={setSelectedComponents}
			selectedSystem={selectedSystem}
			setSelectedSystem={setSelectedSystem}
		/>
	}
	else if(selectedAsset)
	{
		return <SystemsNavigationPage
			setSelectedSystem={setSelectedSystem}
			selectedAsset={selectedAsset}
			setSelectedAsset={setSelectedAsset}
		/>
	}
	else
	{
		return <AssetsNavigationPage
			setSelectedAsset={setSelectedAsset}
		/>
	}
}


export default AssetComponentSelection;


import { LatLng } from 'leaflet';
import { LayerGroup, LayersControl, Polyline } from 'react-leaflet';

import RoutePoint from './RoutePoint';
import { DtnPoint } from './route_interfaces';
import { dtnCoords2LatLng } from './route_utils';


const KM_TO_NM = 0.539956803;


const Route =
({
	trip,
	color,
} : {
	trip: any,
	color: string,
}) =>
{
	console.log(trip);
	
	
	let origin      = trip.request.points[0];
	let destination = trip.request.points[1];
	
	
	let origin_coords = dtnCoords2LatLng(origin.geometry.coordinates)
	let destination_coords = dtnCoords2LatLng(destination.geometry.coordinates)
	
	
	
	
	let points_source = (trip.paths)
		? trip.paths[0]
		: trip.routes[0];
	
	
	let total_distance = points_source.summaries.totalDistance
		|| points_source.summaries.performance.distance.total;
	
	let elapsed_distance = 0;
	
	
	let waypoints = points_source.points.map(point => ({
		latlng: new LatLng(point.geometry.coordinates[1], point.geometry.coordinates[0]),
		distance: (elapsed_distance += (point.properties.distance * KM_TO_NM | 0)),
		distance_left: (total_distance - elapsed_distance || null),
		point: (point as unknown as DtnPoint),
	}))
	
	
	console.log(waypoints);
	
	
	return (
		<>
		
			<LayersControl.Overlay checked name='Route Line'>
				<LayerGroup>
					{
						waypoints.slice(1, -1).map((waypoint, i) =>
						{
							let prev = waypoints[i];
							
							return (
								<Polyline
									key={i}
									positions={[
										[prev.latlng.lat, prev.latlng.lng],
										[waypoint.latlng.lat, waypoint.latlng.lng],
									]}
									color={color}
								/>
							)
						})
					}
				</LayerGroup>
			</LayersControl.Overlay>
			
		
			<LayersControl.Overlay checked name='Route Waypoints'>
				<LayerGroup>
					{waypoints.map((waypoint, i) =>
						<RoutePoint
							latlng={waypoint.latlng}
							type='waypoint'
							name={
								Math.round(waypoint.distance) + ' NM from ' + origin.properties.name
							}
							subheading={
								Math.round(waypoint.distance_left) + ' NM to ' + destination.properties.name
							}
							waypoint={waypoint}
							key={i}
						/>
					)}
				</LayerGroup>
			</LayersControl.Overlay>
			
			
			<LayersControl.Overlay checked name='Route Points'>
				<LayerGroup>
					<RoutePoint
						latlng={origin_coords}
						type='origin'
						name={origin.properties.name}
						subheading={origin.properties.port}
						zoffset={200}
					/>
					<RoutePoint
						latlng={destination_coords}
						type='destination'
						name={destination.properties.name}
						subheading={destination.properties.port}
						zoffset={200}
					/>
				</LayerGroup>
			</LayersControl.Overlay>
			
		</>
	)
}


export default Route;
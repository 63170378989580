import { Button, Divider, Grid, Stack } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import { useRequisition } from '../../RequisitionDataProvider';
import RequisitionCollabPrimaryUsers from './RequisitionCollabPrimaryUsers';
import RequisitionCollabCostCenter from './RequisitionCollabCostCenter';
import RequisitionCollabTagsList from './RequisitionCollabTagsList';
import RequisitionCollabFinalCostSection from './RequisitionCollabFinalCostSection';
import RequisitionItemsSection from '../../RequisitionItemsSection';
import RequisitionCollabItemsSection from './RequisitionCollabItemsSection';
import FilesTable from 'app/dashboard/pages/issueCollaboration/issueDetails/FilesTable';
import RequisitionCollaboratorsList from './RequisitonCollaboratorsList';
import RequisitionCollabCostCodes from './RequisitionCollabCostCodes';





const RequisitionCollabDetails = () =>
{
	const { requisition, files } = useRequisition();
	
	const { status, status_message } = requisition;
	
	
	return (
		<Grid
			container
			width={'100%'}
		>
			<Grid
				item
				className='cards_container'
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					spacing={1}
					width='100%'
				>
					
					<InfoHighlight
						severity={'info'}
						message_context={`Requisition Status: ${status}`}
						message_main={status_message}
					/>
					
					<Grid
						container
						sx={{
							marginTop:'20px',
						}}
						width='100%'
						justifyContent='space-between'
					>
						<RequisitionCollabPrimaryUsers />
						
						<Grid
							item
						>
							<Divider orientation='vertical'/>
						</Grid>
						
						<RequisitionCollabCostCenter />
						
						<Grid
							item
						>
							<Divider
								orientation='vertical'
							/>
						</Grid>
						
						<RequisitionCollabCostCodes />
						
						
						<Grid
							item
						>
							<Divider
								orientation='vertical'
							/>
						</Grid>
						
						<RequisitionCollabTagsList />
					</Grid>
					
				</Stack>
			</Grid>
			
			<Grid
				item
				className='cards_container'
			>
				<RequisitionCollabFinalCostSection />
			</Grid>
			
			<Grid
				item
				className='cards_container'
			>
				<RequisitionCollabItemsSection />
			</Grid>
			
			
			<Grid item width='100%'>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={8} md={8} lg={8}>
						<FilesTable files={files} />
					</Grid>
					
					<Grid item xs={12} sm={4} md={4} lg={4}>
						<RequisitionCollaboratorsList main_collaborator_title='Completed by User' />
					</Grid>
					
				</Grid>
				
			</Grid>
			
		</Grid>
	)
}


export default RequisitionCollabDetails;
import { Certification } from 'models'
import CertificationTable from './CertificationTable'




const ResponsiveCertificationListContent = ({
	certifications,
} : {
	certifications: Certification[],
}) =>
{
	return (
		<>
			<CertificationTable certifications={certifications}/>
		</>
	)
}

export default ResponsiveCertificationListContent
import { MouseEventHandler } from 'react';
import { useHover } from 'utils';
import { BottomNavigationAction, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';

import { cx } from 'stuff';


export const BottomBarButton =
({
	label,
	icon_name,
	is_selected = false,
	onClick,
} : {
	label?: string,
	icon_name: string,
	is_selected?: boolean,
	onClick: MouseEventHandler<any> | undefined,
}) =>
{
	const [ is_hovered, hover_event_handlers ] = useHover();
	
	
	return (
		<BottomNavigationAction
			label={
				<Typography
					sx={{
						color: '#ccc',
					}}
				>
					{label}
				</Typography>
			}
			icon={<CorIcon
				of={icon_name }
				size={(!label) ? '34px' : '22px'}
				color={(is_hovered) ? '#fff' : '#aaa'}
				style={{
					transition: '.2s',
				}}
			/>}
			className={cx('bottom_navigation_buttons', { selected: is_selected })}
			onClick={onClick}
			sx={{
				height: '100%',
				opacity: (is_hovered) ? '1' : '.8',
				transition: '.2s',
				padding: (label) ? '8px 8px 4px 8px' : '8px',
				color: (is_hovered) ? '#fff' : '#aaa',
				
				'& .MuiBottomNavigationAction-label': {
					fontWeight: 400,
					fontSize: '11x',
					lineHeight: '1',
					color: (is_hovered) ? '#fff' : '#aaa',
					opacity: 1,
				},
				
				'& .icon svg': {
					color: (is_hovered) ? '#fff' : '#aaa',
					transition: '.2s',
				},
				'& p': {
					color: (is_hovered) ? '#fff' : '#aaa',
					transition: '.2s',
				},
				
				'&.selected': {
					'& .icon svg': {
						color: '#ff0',
					},
					'& p': {
						color: '#ff0',
					},
				},
			}}
			{...hover_event_handlers}
		/>
	)
}


export default BottomBarButton;



// Uses binary prefixes, not decimal (1 KB = 1024 B)
export function formatBytes(bytes, decimals = 2)
{
	if (bytes === 0) return '0 Bytes';
	
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	
	return (
		parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
			+ ' ' + sizes[i]
	);
}



// Extracts lowercased file extension from file name or file path
// TODO: filenames without extension return filename - should be null?
export const fileExtension = (filepath) =>
{
	const [extension] = filepath.split('.').reverse();
	
	return extension.toLowerCase();
}



// Saves the provided data to a file and initiates download
export const saveDataToFile = (filename, data_to_write) =>
{
	// Create a file-like object of immutable, raw data
	const blob = new Blob([JSON.stringify(data_to_write)], { type: 'text/json' });
	
	// A temporary link that we'll click and then remove
	const link = document.createElement('a');
	
	// Configure the download details as attributes of the link
	link.download = filename;
	link.href = window.URL.createObjectURL(blob);
	link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');
	
	// A simulated mouse click event
	const event = new MouseEvent('click', {
		view: window,
		bubbles: true,
		cancelable: true,
	});
	
	// Initiate the download by clicking on the link, then remove it
	link.dispatchEvent(event);
	link.remove()
};






export const victor_data =
[
	{ x: new Date(1982, 1, 1), y: 125 },
	{ x: new Date(1987, 1, 1), y: 257 },
	{ x: new Date(1993, 1, 1), y: 345 },
	{ x: new Date(1997, 1, 1), y: 515 },
	{ x: new Date(2001, 1, 1), y: 132 },
	{ x: new Date(2005, 1, 1), y: 305 },
	{ x: new Date(2011, 1, 1), y: 270 },
	{ x: new Date(2015, 1, 1), y: 470 },
	{ x: new Date(2023, 1, 1), y: 400 },
]


export const victor_ticks =
[
	new Date(1980, 1, 1),
	new Date(1985, 1, 1),
	new Date(1990, 1, 1),
	new Date(1995, 1, 1),
	new Date(2000, 1, 1),
	new Date(2005, 1, 1),
	new Date(2010, 1, 1),
	new Date(2015, 1, 1),
	new Date(2020, 1, 1),
	new Date(2025, 1, 1),
]



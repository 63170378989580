import { doForObj } from 'utils';

import Thingy from './Thingy';


// Ex: {cities: [{name: Halifax}]} => {cities: [new Thingy('city', {name: Halifax})]}
export const things2thingys = (things) =>
{
	return doForObj(
		things,
		(things_of_type, type) =>
			things_of_type.map(thing => new Thingy(type, thing))
	)
}


// Ex: 
export const thingys2things = (thingys) =>
{
	return doForObj(
		thingys,
		(thingys_of_type) =>
			thingys_of_type.map(x => x.values)
	);
	
	
	//return doForObj(thingys, (thingys_of_type, type) =>
	//	thingys_of_type.map(thingy => Object.assign({}, thingy.values))
	//)
}



export const generateLoadedState = () =>
{
	let all_ids_map = {};
	
	let loaded_things  = []; //loadFromLocalStorage('things') || [];
	let loaded_thingys = things2thingys(loaded_things);
	
	
	// Walk through the object of arrays, adding each Thingy ID to our map
	doForObj(
		loaded_thingys,
		(thingys_of_type) =>
			thingys_of_type.forEach(thingy =>
				all_ids_map[thingy._id] = thingy
			)
	)
	
	
	// TODO
	return {
		thingys: loaded_thingys || {},
		all: all_ids_map,
	}
}



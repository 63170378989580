import { Stack } from '@mui/material';

import ChatContents from './ChatContents';
import ChatComposition from './composition/ChatComposition';
import { useSession } from 'app/providers/SessionProvider';

import { StorageFile } from 'models';
import { Event, Message } from 'types';


const Chat =
({
	messages,
	events,
	files,
	message_metadata,
} : {
	messages : Message[],
	events : Event[],
	files : StorageFile[],
	// Ex: {issue_id: issue._id}
	message_metadata : Object,
}) =>
{
	const { users } = useSession();
	
	
	return (
		<Stack
			sx={{
				background: '#f3f5f9',
				borderRadius: '0',
				
				// maxHeight: 'calc(100% - 300px)',
				overflowY: 'auto',
				height: '100%',
			}}
			className='chat'
		>
			
			<ChatContents
				messages={messages}
				events={events}
				files={files}
				users={users}
			/>
			
			<ChatComposition
				message_metadata={message_metadata}
			/>
			
		</Stack>
	)
}


export default Chat;
import { Stack } from '@mui/material';

import DeleteIconWidget from 'app/gizmos/DeleteIconWidget';
import DatePickerWidget from 'app/gizmos/fields/datetimes/DatePickerWidget';
import TimePickerWidget from 'app/gizmos/fields/datetimes/TimePickerWidget';
import { DateTime } from 'luxon';
import { SpecificDate } from 'models';
import { Controller } from 'react-hook-form';


export const SpecificDateWidget =
({
	name,
	specific_date,
	specific_dates,
	onChange,
} : {
	name: string,
	specific_date: SpecificDate,
	specific_dates: SpecificDate[],
	onChange: Function,
}) =>
{
	const handleChange = (new_datetime: DateTime) =>
	{
		if(new_datetime.isValid)
		{
			console.log({
				new_datetime,
			})
			
			specific_date.datetime = new_datetime;
			
			onChange?.(specific_date);
		}
	}
	
	
	const handleDateChange = (new_datetime: DateTime) =>
	{
		if(new_datetime.isValid && DateTime.isDateTime(specific_date.datetime))
		{
			handleChange?.(specific_date.datetime.set({
				year:  new_datetime.year,
				month: new_datetime.month,
				day:   new_datetime.day,
				second: 0,
				millisecond: 0,
			}));
		}
	}
	
	
	const handleTimeChange = (new_datetime: DateTime) =>
	{
		if(new_datetime.isValid && DateTime.isDateTime(specific_date.datetime))
		{
			handleChange?.(specific_date.datetime.set({
				hour:   new_datetime.hour,
				minute: new_datetime.minute,
				second: 0,
				millisecond: 0,
			}));
		}
	}
	
	
	// console.log({
	// 	basename: name,
	// 	specific_dates,
	// })
	
	
	return (
		<Stack
			direction='row'
			sx={{
				padding: '2px 20px',
				gap: 1.5,
			}}
		>
			
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<Stack
						className='datetime_picker'
						direction='row'
						gap={2}
						justifyContent='center'
						sx={{
							marginTop: 1,
						}}
					>
						<DatePickerWidget
							name={name}
							value={DateTime.isDateTime(specific_date.datetime)
								? specific_date.datetime
								: DateTime.fromISO(specific_date.datetime)
							}
							onChange={handleDateChange}
						/>
						<TimePickerWidget
							name={name}
							value={DateTime.isDateTime(specific_date.datetime)
								? specific_date.datetime
								: DateTime.fromISO(specific_date.datetime)
							}
							onChange={handleTimeChange}
						/>
					</Stack>
				}
			/>
			
			<DeleteIconWidget
				name={name}
				array={specific_dates}
				item_id={specific_date._id}
			/>
			
		</Stack>
	)
}


export default SpecificDateWidget;
import { Button, Stack } from '@mui/material'
import RequisitionItemsSection from '../../RequisitionItemsSection';
import { useState } from 'react';
import { useRequisition } from '../../RequisitionDataProvider';
import InstantNotificationPopup from 'app/dashboard/nav/notifications/InstantNotificationPopup';


const RequisitionCollabItemsSection = () =>
{
	const [ fireAlert, setFireAlert ] = useState<boolean>(false);
	
	const { updateItemsSection } = useRequisition();
	
	
	const handleSubmit = () =>
	{
		updateItemsSection();
		setFireAlert(true);
	};
	
	return (
		<Stack
			gap={2}
		>
			<RequisitionItemsSection />
			
			<Button
				variant='contained'
				onClick={handleSubmit}
			>
				Save Changes to Items & Costs
			</Button>
			
			<InstantNotificationPopup
				open={fireAlert}
				setOpen={setFireAlert}
				severity={'success'}
				message='Requisition Items & Costs updated successfully'
			/>
		</Stack>
	)
};


export default RequisitionCollabItemsSection;
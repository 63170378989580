import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { cx } from 'stuff';
import { useHover } from 'utils';
// import { useURLQueryParam } from 'utils/hooks';
import CorIcon from 'components/icons/CorIcon';
import { CorComponent } from 'models';
import { Issue } from 'types';
import ExpandIcon from './ExpandIcon';
import { Stack, Typography } from '@mui/material';
import { useCor } from 'app/providers/CorProvider';
import CorCode from 'pages/vessels/CorCode';
import ComponentTreeIssueIndicator from './ComponentTreeIssueIndicator';
// import { CorComponent } from 'models';


// TODO: Ideally one component per file
// This is odd that this component hierarchy alternates - the naming is unclear
const ComponentTreeSubChildNode =
({
	child_depth,
	location,
	component,
	setComponent,
	DEFAULT_EXPANSION_LEVEL,
	is_last,
	has_siblings,
	issues,
	show_trace,
	setIsComponentDrawerOpen,
	selectedComponents = [],
	setSelectedComponents,
	all_ancestor_ids,
}) =>
{
	let children = component.children?.map((child: CorComponent, i: number) =>
	{
		let newLocation = location.slice();
		
		newLocation.push(i);
		
		return <ComponentTreeChildNode
			key={component._id + '__' + child._id}
			location={newLocation}
			component={child}
			setComponent={setComponent}
			depth={child_depth}
			DEFAULT_EXPANSION_LEVEL={DEFAULT_EXPANSION_LEVEL}
			is_last={i === child.children?.length - 1}
			has_siblings={child.children?.length > 1} 
			issues={issues}
			setIsComponentDrawerOpen={setIsComponentDrawerOpen}
			selectedComponents={selectedComponents}
			setSelectedComponents={setSelectedComponents}
			all_ancestor_ids={all_ancestor_ids}
			// show_trace={false}
		/>
	})
	
	
	if(!children) return null;
	
	
	return (
		<ul
			className={cx('tree_node_children', {
				has_lower_siblings: !is_last && has_siblings,
			})}
		>
			{children}
		</ul>
	)
};





const ComponentTreeChildNode =
({
	depth,
	DEFAULT_EXPANSION_LEVEL,
	location,
	component,
	is_last,
	has_siblings,
	issues,
	setComponent,
	setIsComponentDrawerOpen,
	selectedComponents,
	setSelectedComponents,
	all_ancestor_ids = [],
} : {
	depth: number,
	DEFAULT_EXPANSION_LEVEL: number,
	location: number[],
	component: CorComponent,
	is_last: boolean,
	has_siblings: boolean,
	issues: Issue[],
	setComponent?: Function,
	setIsComponentDrawerOpen?: Function,
	selectedComponents?: CorComponent[],
	setSelectedComponents?: Function,
	all_ancestor_ids?: string[],
}) =>
{
	const navigate = useNavigate();
	const { asset_id, system_id, component_id } = useParams();
	
	
	const { selected_component, setSelectedComponentId } = useCor();
	
	
	
	const hasComponents = component.children?.length > 0;
	let child_depth = depth + 1;
	
	// console.log(component)
	
	
	// TODO: Refactor
	const [ isExpanded, setIsExpanded ] = useState(
		all_ancestor_ids?.includes(component._id)
		|| (depth < DEFAULT_EXPANSION_LEVEL)
	);
	
	// TODO: Not recommended
	const [ isSelected, setIsSelected ] = useState<Boolean>(false);
	
	
	
	const is_selected = (!!selected_component && !!component) && (selected_component?._id === component?._id);
	
	
	
	// TODO
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ isFocused, setIsFocused ] = useState(false);
	const [ isHovered, hover_event_handlers ] = useHover();
	
	
	// const [ hasIssues, setHasIssues ] = useState<boolean>(false);
	
	
	// const determineIfComponentHasIssues = () =>
	// {
	// 	issues.forEach(issue =>
	// 	{
	// 		if ((issue.component_ids.includes(component._id)) && (issue.status === IssueStatus.OPEN))
	// 		{
	// 			setHasIssues(true);
	// 		}
	// 	})
	// };
	
	
	// TODO: Move up
	const handleSelectComponent = () =>
	{
		if(
			window.location.pathname === `/assets/${asset_id}/systems/${system_id}/components`
			|| window.location.pathname === `/assets/${asset_id}/systems/${system_id}/components/${component_id}`
		)
		{
			console.log('handleSelectComponent', {
				component,
				selected_component,
			});
			
			setComponent(component);
			setIsComponentDrawerOpen(true);
			
			setIsExpanded(true);
			setIsSelected(true);
			
			navigate(`/assets/${asset_id}/systems/${system_id}/components/${component._id}`);
		}
		else
		{
			setSelectedComponentId(component._id);
			
			
			const selected_component_ids = selectedComponents?.map(x => x._id);
			
			if(selected_component_ids?.includes(component._id))
			{
				setSelectedComponents(selectedComponents.filter(x => x._id !== component._id))
				setIsSelected(false);
			}
			else
			{
				// TODO: Why does this sometimes fail?
				setSelectedComponents?.([
					...selectedComponents,
					component
				]);
				setIsSelected(true);
			}
		}
	};
	
	
	const determineIfComponentIsSelected = () =>
	{
		const selected_component_ids = selectedComponents?.map(x => x._id);
		
		if(selected_component_ids?.includes(component._id))
		{
			setIsSelected(true);
		}
	}
	
	
	useEffect(() =>
	{
		// determineIfComponentHasIssues();
		
		determineIfComponentIsSelected();
	}, []);
	
	
	
	let component_asset_code = component?.asset_code;
	
	if(component_asset_code?.length > 3)
	{
		let remains = component_asset_code.slice(3).match(/.{1,2}/g);
		
		component_asset_code = component_asset_code.slice(0, 3) + '.' + remains.join('.');
	}
	
	
	let component_function_code = component?.maintenance_function_code;
	
	if(component_function_code?.length > 3)
	{
		let remains = component_function_code.slice(3).match(/.{1,2}/g);
		
		component_function_code = component_function_code.slice(0, 3) + '.' + remains.join('.');
	}
	
	
	
	if(!hasComponents)
	{
		return (
			<li
				className={cx(
					'tree_node',
					'tree_twig',
					{
						is_last: is_last,
						has_siblings: has_siblings,
						has_lower_siblings: !is_last && has_siblings,
						is_hovered: isHovered,
						// show_trace: show_trace,
						is_selected: is_selected,
						is_highlighted: (component_id === component._id),
					}
				)}
				title={component._id + ': ' + component?.name}
				{...hover_event_handlers}
			>
				
				<div className='tree_leaf'>
					<CorIcon
						of='circle_filled'
						className='tree_leaf_icon'
						size='18px'
					/>
				</div>
				
				<Stack
					onClick={() => handleSelectComponent()}
					className='tree_label'
					direction='row'
				>
					<Stack
						direction='row'
						alignContent='center'
						alignItems='center'
					>
						<CorCode
							code={component?.asset_code || component?.maintenance_function_code}
							sx={{
								bgcolor: (component.amos_type?.toLowerCase() === 'asset') ? '#ff595e' : '#1982c4',
								color: '#fff',
							}}
						/>
						<Typography
							variant='body1'
							display='inline'
						>
							{component.name}
							{
								(!isExpanded && component?.children?.length)
								?
									<Typography
										sx={{
											opacity: .6,
										}}
									>
										&nbsp;
										{`( ${component?.children?.length} )`}
									</Typography>
								:
									null
							}
						</Typography>
					</Stack>
					
					<ComponentTreeIssueIndicator
						component={component}
					/>
					
				</Stack>
			</li>
		)
	}
	else
	{
		return (
			<li
				className={cx('tree_node', 'tree_branch', {
					is_last: is_last,
					has_siblings: has_siblings,
					has_lower_siblings: !is_last && has_siblings,
					is_hovered: isHovered,
					is_selected: is_selected,
					is_highlighted: (component_id === component._id),
				})}
				title={component._id + ': ' + component?.name}
				{...hover_event_handlers}
			>
				
				<Stack
					className='tree_junction'
					direction='row'
				>
					<ExpandIcon
						is_expanded={isExpanded}
						handleToggle={setIsExpanded}
					/>
					<Stack
						onClick={() => handleSelectComponent()}
						className='tree_label'
						direction='row'
					>
						<Stack
							direction='row'
							alignContent='center'
							alignItems='center'
						>
							<CorCode
								code={component?.asset_code || component?.maintenance_function_code}
								sx={{
									bgcolor: (component?.amos_type?.toLowerCase() === 'asset') ? '#ff595e' : '#1982c4',
									color: '#fff',
								}}
							/>
							
							&nbsp;
							
							<Typography
								variant='subtitle1'
							>
								{component.name}
							</Typography>
							
							{
								(!isExpanded && component?.children?.length)
								&&
									<Typography
										sx={{
											opacity: .4,
											marginLeft: 1,
										}}
									>
										&nbsp;
										<span
											style={{
												opacity: .2,
											}}
										>
											(
										</span>
										
										{`+${component?.children?.length}`}
										
										<span
											style={{
												opacity: .2,
											}}
										>
											)
										</span>
									</Typography>
							}
							
							<ComponentTreeIssueIndicator
								component={component}
							/>
							
						</Stack>
					</Stack>
				</Stack>
				
				{
					isExpanded &&
					<ComponentTreeSubChildNode
						child_depth={child_depth}
						location={location}
						component={component}
						setComponent={setComponent}
						DEFAULT_EXPANSION_LEVEL={DEFAULT_EXPANSION_LEVEL}
						is_last={is_last}
						has_siblings={has_siblings}
						show_trace={false /* TODO */ }
						issues={issues}
						setIsComponentDrawerOpen={setIsComponentDrawerOpen}
						selectedComponents={selectedComponents}
						setSelectedComponents={setSelectedComponents}
						all_ancestor_ids={all_ancestor_ids}
					/>
				}
				
			</li>
		);
	};
}


export default ComponentTreeChildNode;
import { useNavigate } from 'react-router';

import CorIcon from 'components/icons/CorIcon';

import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { defaultAppTheme, responsiveAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import { useWOTCollabData } from './WOTCollabProvider';
import { navigateUp } from 'utils/url_utils';

// import { WorkOrderStatus } from 'models';


const WOTCollabHeader = () => 
{
	const navigate = useNavigate();
	
	const { wo_template } = useWOTCollabData();
	const { title, _id, status, work_order_template_num } = wo_template;
	
	const small_screen = useMediaQuery(responsiveAppTheme.breakpoints.down('sm'));
	
	
	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			alignItems='flex-start'
			spacing={1}
			sx={{
				padding: '2px',
			}}
			className='issue_collab_header'
		>
			
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				sx={{
					cursor: 'pointer',
				}}
				// onClick={() => navigate(-1)}
				onClick={() => navigateUp(navigate)}
			>
				<Button
					sx={{
						textTransform: 'none',
						padding: '3px 4px',
					}}
				>
					<CorIcon
						of='back'
						size='25px'
						color={defaultAppTheme.palette.primary.main}
					/>
					<Typography variant='caption'>
						Back to list
					</Typography>
				</Button>
			</Stack>
			
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				sx={{
					padding: '2px 16px',
					margin: 0,
					marginTop: 0,
				}}
				style={{
					marginTop: 0,
				}}
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					sx={{
						padding: '8px',
					}}
				>
					<CorIcon
						of='active'
						size='24px'
						color={defaultAppTheme.palette.success.dark}
					/>
					
					<Typography
						variant='body1'
						color={defaultAppTheme.palette.success.dark}
					>
						{ status.toUpperCase() }
					</Typography>
				</Stack>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='flex-start'
					sx={{
						padding: '4px',
					}}
				>
					<Typography
						variant='h1'
						sx={{
							padding: '2px',
						}}
					>
						WOT #{work_order_template_num}
					</Typography>
					
					{
						<Typography
							variant='h3'
							fontWeight={300}
							sx={{
								padding: '2px',
							}}
						>
							{title}
						</Typography>
					}
				</Stack>
				
			</Stack>
			
		</Stack>
	)
}


export default WOTCollabHeader;

import L, { LeafletKeyboardEvent, LeafletMouseEvent } from 'leaflet';
import { useState } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { coloredMarkerIcon } from './markers';


export const LocationMarker = () =>
{
	const [position, setPosition] = useState(null);
	
	const map = useMapEvents({
		click(event: LeafletMouseEvent)
		{
			console.log('Click coords:', event.latlng, event);
		},
		keypress(event: LeafletKeyboardEvent)
		{
			let key = event.originalEvent.key;
			
			console.log(key, event, map);
			
			if(key === 'h')
			{
				console.log('h pressed - attempting to go to user\'s location');
				map.locate();
			}
		},
		locationfound(e)
		{
			setPosition(e.latlng)
			
			const circle = L.circle([e.latlng.lat, e.latlng.lng], e.accuracy / 2, {
				weight: 2,
				color: "red",
				fillColor: "red",
				fillOpacity: 0.2,
			});
			
			// Add circle representing location uncertainty directly to map
			// (doesn't appear until after flying)
			map.addLayer(circle);
			
			map.flyTo(e.latlng, map.getZoom());
		},
	})
	
	return (position === null)
		? null
		:
			<Marker
				position={position}
				icon={coloredMarkerIcon('violet')}
			>
				<Popup>You are here</Popup>
			</Marker>
}


export default LocationMarker;
import { React } from 'stuff';


import QRCode from 'qrcode.react';

// import BasicQrLabel from './BasicQrLabel';


//import helm from '../../app/data/HelmConnect - 2021-10-28.json';


type QrCodeProps =
{
	selectedComponent: any,
	currentSystem?: any,
};


const QrCodeLabel = React.forwardRef<HTMLInputElement, QrCodeProps>((props, ref) =>
{
	const { selectedComponent } = props;
	
	
	const {
		_id,
		name,
		asset_id,
		asset_name,
		system_id,
		system_name,
		type_name,
	} = selectedComponent;
	
	const qrCodeData =
	{
		component_id: _id,
		system_id: system_id,
		asset_id: asset_id,
	};
	
	
	
	
	// return (
	// 	<BasicQrLabel
	// 		line1a={asset_name || 'Asset'}
	// 		line1b={system_name || 'System'}
	// 		line2={name || 'Component'}
	// 		line3={type_name || 'Component Type'}
	// 		payload={qrCodeData}
	// 	/>
	// )
	
	
	
	return(
		<div 
			id={`qr_code_label_${_id}`}
			className='label_qr_code'
			ref={ref}
		>
			<div className='label_content'>
				
				<div className='label_text_content'>
					<div className='label_first_line'>
						<div className='label_target_family'>
							{ asset_name || 'Asset' }
						</div>
						<div className='label_target_type'>
							{ system_name || 'System' }
						</div>
					</div>
					
					<div className='label_second_line'>
						{ name || 'Component' }
						{/* <p className='label_item_id'>{pid || 'PID'}</p> */}
					</div>
					
					<div className='label_third_line'>
						{ type_name || 'Component Type' }
					</div>
				</div>
				
				<div className='label_image'>
					<div className='qr_code'>
						<QRCode
							value={JSON.stringify(qrCodeData)}
							renderAs={'svg'}
							className='qr_code_img'
						/>
					</div>
				</div>
				
			</div>
		</div>
	)
});


export default QrCodeLabel;
import { React, useState, useEffect } from 'stuff';

import { Link } from 'react-router-dom';

import { getFunctions, httpsCallable } from 'firebase/functions';

import { useWindowSize } from 'usehooks-ts';


import MuiIcon from 'components/icons/MuiIcon';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import CorIcon from 'components/icons/CorIcon';
import CompanyHeader from '../CompanyHeader';



// Our API
const ember =
{
	// Send user info to backend with token
	// Provide object containing username and password
	register: async (functions, data, setError, setRegistrationSuccess, setLoading) =>
	{
		const verifyAndRegisterUser = httpsCallable(functions, 'verifyAndRegisterUser');
		
		if(data.password === data.confirm_password)
		{
			verifyAndRegisterUser(JSON.stringify(data)).then(async (result: any ) => {
				console.log('CREATE USER OUTPUT', result.data);
				
				
				if(result?.data?.error)
				{
					setError(result?.data?.error);
					setLoading(false);
				}
				else
				{
					console.log('Verify User Output', result.data);
					setRegistrationSuccess(true);
					setLoading(false);
				}
			});
		};
	},
};




const RegistrationPage = () =>
{
	const functions = getFunctions();
	
	const urlParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlParams.entries());
	
	// local state for managing inout fields
	const [ registrationInfo, setRegistrationInfo ] = useState({
		email: '',
		name: '',
		password: '',
		confirm_password: '',
		token: params.token,
		invited_into_organization_id: '',
	});
	
	const [ showPassword, setShowPassword ] = useState(false);
	const [ confirmShowPassword, setConfirmShowPassword ] = useState(false);
	
	const [ error, setError ] = useState(null);
	const [ registrationSuccess, setRegistrationSuccess ] = useState(false);
	
	const [ fireAlert, setFireAlert ] = useState<boolean>(false);
	
	const [ loading, setLoading ] = useState<boolean>(false);
	
	
	
	
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) =>
	{
		if (reason === 'clickaway')
		{
			return;
		}
	
		setFireAlert(false);
	};
	
	
	
	
	
	useEffect(() =>
		{
			function parseJwt(token: string)
			{
				var base64Url = token.split('.')[1];
				var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
				var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				}).join(''));
				
				
				const decoded_token = JSON.parse(jsonPayload);
				console.log('PAYLOAD', decoded_token);
				
				setRegistrationInfo({
					...registrationInfo,
					email: decoded_token.email,
					invited_into_organization_id: decoded_token.organization,
				});
			};
			
			parseJwt(params.token);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[params.token]
	);
	
	const { width, height } = useWindowSize();
	
	
	let compact = (width < 555);
	
	
	let content_height = (height < 555)
		? '100%'
		: (width < 555)
			? 'calc(min(80%, 420px))'
			: 'calc(min(80%, 700px))';
	
	
	
	return (
		<Box className='login_page'>
			<Box
				className='login_page_content'
				sx={{
					// maxWidth: (show_robot) ? '100%' : '60%',
					height: 'fit-content',
					// margin: (compact) ? 1 : 2,
					width: (compact) ? '100%' : 'unset',
					maxWidth: (compact) ? 'unset' : '900px',
					maxHeight: '1300px',
					padding: 2,
					background: '#fafafa',
					borderRadius: 2,
					// marginBottom: 7,
				}}
			>
				
				
				<Stack
					direction='column'
				>
					<CompanyHeader />
				</Stack>
				
				<form
					autoComplete='off'
					onSubmit={(e) => {
						e.preventDefault();
						
						ember.register(functions, registrationInfo, setError, setRegistrationSuccess, setLoading);
						
						setFireAlert(true);
						setLoading(true);
					}}
				>
					<Stack
						direction='column'
						justifyContent='flex-start'
						alignItems='center'
						gap={2}
						sx={{ marginTop: (compact) ? 2 : 4 }}
					>
						<Typography
							variant='h1'
							component='h1'
						>
							Register
						</Typography>
						
						{
							(registrationSuccess) ? (
								<Typography
									variant='h3'
									component='p'
								>
									Registration successful! You can now login to your account.
								</Typography>
							) : (
								<>
									{
										loading ? (
											<CircularProgress />
										) : (
											<>
												{
													(error) ? (
														<Typography 
															variant='subtitle1'
															component='div'
															className='signup-error'
														>
															We are unable to register your user account. Please send all inquiry to dev@corsphere.com.
														</Typography>
													) : (null)
												}
												
												<TextField
													variant='outlined'
													label='Email'
													type='email'
													name='email'
													id='email'
													className='input_field_email'
													autoComplete='off'
													placeholder='Email'
													value={registrationInfo.email}
													InputProps={{
														startAdornment:
														<InputAdornment position='start'>
															<CorIcon of='email' size='24px' />
														</InputAdornment>
													}}
													disabled
												/>
												
												<TextField
													variant='outlined'
													label='Full Name'
													type='text'
													name='name'
													id='name'
													className='input_field'
													autoComplete='off'
													placeholder='e.g. John Doe'
													value={registrationInfo.name}
													InputProps={{
														startAdornment:
														<InputAdornment position='start'>
															<CorIcon of='name' size='24px' />
														</InputAdornment>
													}}
													onChange={(e) => setRegistrationInfo({
														...registrationInfo,
														name: e.target.value,
													})}
												/>
												
												<TextField
													type={!showPassword ? 'password' : 'text'}
													label='Password'
													name='password'
													id='password'
													className='input_field'
													placeholder='Password'
													value={registrationInfo.password}
													onChange={(e) => setRegistrationInfo({
														...registrationInfo,
														password: e.target.value,
													})}
													InputProps={{
														startAdornment:
														<InputAdornment position='start'>
															<CorIcon of='password' size='24px'/>
														</InputAdornment>,
														
														endAdornment: 
														<InputAdornment position='end'>
															<CorIcon 
																of={!showPassword ? 'eye_close' : 'eye_open'}
																size='24px'
																onClick={() => setShowPassword(!showPassword)}
																cursor='pointer'
															/>
														</InputAdornment>
													}}
												/>
												
												<TextField
													type={!confirmShowPassword ? 'password' : 'text'}
													label='Confirm Password'
													name='confirm_password'
													id='confirm_password'
													className='input_field'
													placeholder='Confirm Password'
													value={registrationInfo.confirm_password}
													onChange={(e) => setRegistrationInfo({
														...registrationInfo,
														confirm_password: e.target.value,
													})}
													InputProps={{
														startAdornment:
														<InputAdornment position='start'>
															<CorIcon of='password' size='24px'/>
														</InputAdornment>,
														
														endAdornment: 
														<InputAdornment position='end'>
															<CorIcon 
																of={!confirmShowPassword ? 'eye_close' : 'eye_open'}
																size='24px'
																onClick={() => setConfirmShowPassword(!confirmShowPassword)}
																cursor='pointer'
															/>
														</InputAdornment>
													}}
												/>
												
												<Button
													type='submit'
													variant='contained'
													disabled={
														(registrationInfo.name === '')
														|| (registrationInfo.password === '')
														|| (registrationInfo.confirm_password === '')
														|| (registrationInfo.password !== registrationInfo.confirm_password)
													}
												>
													Register
												</Button>
												
												{
													((registrationInfo.confirm_password !== '') 
														&& !(registrationInfo.password === registrationInfo.confirm_password)) 
														? (
																<div className='signup-error'>
																	Please make sure your password fields match
																</div>
															)
														: (
																null
															)
												}
											</>
										)
									}
								</>
							)
						}
						<Button
							component={Link}
							to='/login'
							variant='text'
							sx={{
								alignSelf: 'flex-end',
								textTransform: 'none',
								textDecoration: 'underline'
							}}
							style={{
								marginTop: (compact) ? 2 : 4,
							}}
						>
							Login
						</Button>
					</Stack>
					
				</form>
				
			</Box>
			
			<Snackbar
				open={fireAlert} 
				autoHideDuration={8000}
				onClose={handleClose}
				className='alert'
			>
				<Alert
					onClose={handleClose} 
					severity='success' 
					sx={{ width: '100%' }}
				>
					Please wait while we try to register your account.
				</Alert>
			</Snackbar>
			
		</Box>
	)
}


export default RegistrationPage;
import React, { useEffect, useState } from 'react';

import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';
import { Role } from 'models/users/user_interfaces';
import { Tag } from 'types';
import TagCard from './TagCard';


const TagsList =
({
	tags,
} : {
	tags: Tag[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	const [ filtered_tags, setFilteredTags ] = useState<Role[]>(tags);
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	useEffect(() =>
	{
		setFilteredTags(tags)
	}, [tags])
	
	
	console.log({filtered_tags});
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			<SearchBarOld
				items={tags}
				handleItems={setFilteredTags}
				fields={['name']}
			/>
			{
				filtered_tags
					?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE)
					.map((tag: Tag) => (
						<TagCard tag={tag} />
					))
			}
			<Pagination
				count={Math.ceil(tags.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default TagsList;
import { useState, useAuthData } from 'stuff';
import { useWindowSize } from 'usehooks-ts'

import { Link, useNavigate } from 'react-router-dom';

import { FirebaseError } from 'firebase/app';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import CorIcon from 'components/icons/CorIcon';



const LoginPageForm = () =>
{
	const { logIn } = useAuthData();
	
	// Credential fields
	const [ login_info, setLoginInfo ] = useState({
		email: '',
		password: '',
	});
	
	const navigate = useNavigate();
	
	const [ showPassword, setShowPassword ] = useState(false);
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ error, setError ] = useState(false);
	
	
	const { width } = useWindowSize();
	
	
	let compact = (width < 580);
	
	
	// console.log({
	// 	width,
	// 	height,
	// })

	
	return (
		<form
			autoComplete='off'
			onSubmit={(e) => {
				const { email, password } = login_info;
				
				logIn(email, password)
					.then(() => navigate('/'))
					.catch((error: FirebaseError) => {
						const error_code = error.code;
						const error_message = error.message;
						console.error('Failed to sign in', error_code, error_message);
						setError(true);
					});
				
				e.preventDefault();
			}}
		>
			<Stack
				direction='column'
				alignItems='center'
				justifyContent='flex-start'
				spacing={(compact) ? 2 : 4}
			>
				
				<Typography
					variant='h1'
					component='h1'
				>
					Log In
				</Typography>
				
				
				{
					(error)
					&&
						<Typography 
							variant='subtitle1'
							component='div'
							className='signup-error'
						>
							Wrong email or password. Please check your credentials!
						</Typography>
				}
				
				
				<TextField
					variant='outlined'
					label='Email'
					type='email'
					name='email'
					id='email'
					className='input_field'
					autoComplete='off'
					placeholder='Email'
					value={login_info.email}
					onChange={(e) => setLoginInfo({
						...login_info,
						email: e.target.value,
					})}
					InputProps={{
						startAdornment:
						<InputAdornment position='start'>
							<CorIcon
								of='email'
								size='24px'
							/>
						</InputAdornment>
					}}
				/>
				
				<TextField
					type={!showPassword ? 'password' : 'text'}
					label='Password'
					name='password'
					id='password'
					className='input_field'
					placeholder='Password'
					value={login_info.password}
					onChange={(e) => setLoginInfo({
						...login_info,
						password: e.target.value,
					})}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<CorIcon
									of='password'
									size='24px'
								/>
							</InputAdornment>
						),
						
						endAdornment: (
							<InputAdornment position='end'>
								<CorIcon 
									of={!showPassword ? 'eye_close' : 'eye_open'}
									size='24px'
									onClick={() => setShowPassword(!showPassword)}
									cursor='pointer'
								/>
							</InputAdornment>
						)
					}}
				/>
				
				<Button
					type='submit'
					variant='contained'
					style={{
						marginTop: (compact) ? '40px' : '50px',
					}}
				>
					Log In
				</Button>
				
				<Button
					component={Link}
					to='/forgot-password'
					variant='text'
					sx={{
						alignSelf: 'flex-end',
						textTransform: 'none',
						textDecoration: 'underline'
					}}
					style={{
						marginTop: (compact) ? 40 : 60,
					}}
				>
					Forgot password
				</Button>
				
			</Stack>
		</form>
	)
}


export default LoginPageForm;
import { Controller } from 'react-hook-form';
import { Button, FormControl, Stack, TextField, Typography } from '@mui/material';

import CorIcon from 'components/icons/CorIcon';



export const VerticalCounter =
({
	name,
	units,
	min = 0,
	max,
	is_required,
} : {
	name: string,
	units: string,
	max?: number,
	min?: number,
	is_required?: boolean,
}) =>
{
	const handleMinus = (value: number, onChange: Function) =>
	{
		if(value > (min ?? 0))
		{
			onChange(value - 1);
		}
	}
	
	
	const handlePlus = (value: number, onChange: Function) =>
	{
		if(typeof max === 'undefined')
		{
			onChange(value + 1);
		}
		else if((value < max))
		{
			onChange(value + 1);
		}
	}
	
	
	return (
		<FormControl>
			<Stack>
				<Controller
					name={name}
					defaultValue={0}
					rules={{
						required: is_required,
						max: max ?? undefined,
						min: min,
					}}
					
					render={({ field: { value, onChange } }) =>
						<Stack
							justifyContent='center'
							alignItems='center'
						>
							
							<Button
								onClick={() => handlePlus(value, onChange)}
								tabIndex={-1}
								sx={{
									marginBottom: '4px',
								}}
							>
								<CorIcon
									of='chevron_up'
								/>
							</Button>
							
							<TextField
								name={name}
								value={value || ''}
								// placeholder={is_focused ? '' : '0'}
								// inputRef={ref}
								sx={{
									padding: 0,
									border: 'none',
									maxWidth: '50px',
									
									// fontWeight: (value === 0) ? 600 : 500,
									
									
									'& .MuiOutlinedInput-input': {
										borderWidth: 0,
										outline: 'none',
										padding: 0,
										textAlign: 'center',
										color: (value === 0) ? '#0003' : '#000',
										background: (value === 0) ? '#fff7' : '#fffc',
									},
									
									'& .MuiOutlinedInput-root:hover': {
										background: '#fffc',
									},
									
									'& .Mui-focused': {
										color: '#000',
										background: '#fff',
										opacity: 1,
									},
									
									'& .Mui-focused .MuiOutlinedInput-input': {
										color: '#000',
										// background: '#fff',
										// opacity: 1,
									},
									
									
									'& fieldset': {
										borderColor: '#0000',
									},
									
									'& .Mui-focused fieldset': {
										borderColor: '#000',
										opacity: 1,
										color: '#000',
									},
									
									
									'& label': {
										background: '#fff',
										opacity: (value === 0) ? 0.3 : 0.9,
									},
									
									'& label.Mui-focused': {
										background: '#fff',
										opacity: 1,
									},
								}}
								onChange={(event) => onChange(Number(event.target.value))}
							/>
							
							<Typography
								textAlign='center'
								lineHeight='1'
								padding={.5}
								sx={{
									opacity: (value === 0) ? 0.2 : 0.8,
									padding: 0,
									paddingTop: '4px',
									userSelect: 'none',
								}}
							>
								{
									(value === 1)
										? `${units.slice(0, -1)}`
										: `${units}`
								}
							</Typography>
							
							<Button
								onClick={() => handleMinus(value, onChange)}
								tabIndex={-1}
								disabled={(value === min)}
								sx={{
									marginTop: '4px',
								}}
							>
								<CorIcon
									of='chevron_down'
								/>
							</Button>
							
						</Stack>
					}
				/>
			</Stack>
		</FormControl>
	);
};


export default VerticalCounter;
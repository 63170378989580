import SearchableIssuesCardList from 'app/dashboard/pages/issuesMain/SearchableIssuesCardList';
import { Issue } from 'models';


const ComponentRelatedIssuesList =
({
	related_issues,
} : {
	related_issues: Issue[],
}) =>
{
	
	return (
		<div>
			<SearchableIssuesCardList issues={related_issues} />
		</div>
	)
};


export default ComponentRelatedIssuesList;
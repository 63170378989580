import { Group } from '@visx/group';


export type TreeNodeChildrenCountProps =
{
	width: number,
	node: any,
	has_value: boolean,
	has_hidden_children: boolean,
	forceUpdate: Function,
};


export default function TreeNodeChildrenCount({
	width,
	node,
	has_value,
	has_hidden_children,
	forceUpdate,
}
: TreeNodeChildrenCountProps)
{
	return (
		<Group>
			{
				(has_hidden_children)
				&&
				<rect
					height={20}
					width={20}
					y={-20 / 2}
					x={width / 2 - 4}
					// fill={(node.depth !== 0) ? '#202048' : '#03c0dc'}
					stroke={(node.data.children) ? '#03c0dc' : '#26deb0'}
					strokeWidth={1}
					strokeDasharray={(node.data.children) ? '0' : '2,2'}
					strokeOpacity={(node.data.children) ? 1 : 0.8}
					rx={10}
					onClick={() => {
						node.data.isExpanded = !node.data.isExpanded;
						console.log(node);
						forceUpdate();
					}}
					fill={'#fff'}
					cursor='pointer'
				/>
			}
			{
				(has_hidden_children)
				&&
				<text
					dx={(width / 2 + 10 - 4) + 'px'}
					dy='.33em'
					fontSize={14}
					fontFamily='Arial'
					textAnchor='middle'
					style={{
						pointerEvents: 'none',
						userSelect: 'none',
						color: '#fff !important',
					}}
					color='#fff !important'
					stroke='black'
					fill='red'
				>
					{node.data.children.length}
				</text>
			}
		</Group>
	);
}
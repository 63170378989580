// import { PartObjectForm } from 'components/addParts/interfaces';
import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';
import React from 'react';
import { useContext } from 'react';
import { Tag, User } from 'types';
import { ComponentTreeData } from 'models';


interface SessionContext
{
	all_available_tags: Tag[],
	users: User[],
	user: User,
	claims: any,
	parts_needed: PartWithSelectedCount[],
	set_parts_needed: (partobj: PartWithSelectedCount[]) => void,
	files: File[],
	set_files: (file:File[]) => void,
	selected_components: ComponentTreeData[],
	set_selected_components: (selectedComp: ComponentTreeData[]) => void
}


const CreateIssueContext = React.createContext<SessionContext>(null);



// TODO: RENAME ALL OF THESE COPIES!
export function useSessionContext()
{
	return useContext(CreateIssueContext);
}



const CreateIssueProvider = ({ sessionContext, children }) =>
{
	return (
		<CreateIssueContext.Provider value={sessionContext}>
			{children}
		</CreateIssueContext.Provider>
	);
}



export default CreateIssueProvider;
import { ReactNode, useState, useEffect } from 'react';
import { Button, IconButton } from '@mui/material';

import BasicDialog from './BasicDialog';


interface ButtonToOpenDialogI
{
	dialog_title: string,
	dialog_content: ReactNode,
	
	button_type?: 'regular' | 'icon_button',
	dialog_submit_text?: string,
	dialog_dismiss_text?: string,
	
	button_variant?: 'text' | 'contained' | 'outlined',
	button_content?: ReactNode,
	disabled?: boolean,
	
	// Used as the form prop for input, linking submit button to a form outside its hierarchy
	form_id?: string,
	handleSubmit?: Function,
	
	dialogClassName?: string,
	sx?: any,
	
	closeDialog?: boolean,
	startIcon?: ReactNode,
	endIcon?: ReactNode,
	buttonIcon?: ReactNode,
}


// TODO: Make use of MaybeDialog to store dialog open status as URL query parameter
// TODO: Consider how to handle validation before submission, and disabling submit button
const ButtonToOpenDialog =
({
	button_type = 'regular',
	button_content,
	button_variant,
	
	dialog_title: title_text,
	dialog_submit_text: submit_text,
	dialog_dismiss_text: dismiss_text,
	
	dialog_content,
	
	form_id,
	handleSubmit,
	dialogClassName,
	sx,
	
	closeDialog = false,
	startIcon,
	endIcon,
	buttonIcon,
	disabled = false,
	
	...rest
}
: ButtonToOpenDialogI
) =>
{
	const [ open, setOpen ] = useState(false);
	
	
	const handleOpen = () =>
	{
		setOpen(true);
	}
	
	
	const onClose = () =>
	{
		setOpen(false);
	}
	
	
	const onSubmit = () =>
	{
		setOpen(false);
		
		handleSubmit?.();
	}
	
	
	// Used to close the modal from an external onSubmit
	// e.g. () => setCloseDialog(!closeDialog)
	useEffect(() =>
	{
		setOpen(false)
	}, [closeDialog])
	
	
	return (
		<>
			
			{
				(button_type === 'regular') ? (
					<Button
						onClick={handleOpen}
						variant={button_variant || 'contained'}
						sx={sx}
						startIcon={startIcon}
						endIcon={endIcon}
						disabled={disabled}
					>
						{button_content}
					</Button>
				) : (
					<IconButton
						onClick={handleOpen}
					>
						{buttonIcon}
					</IconButton>
				)
			}
			
			
			<BasicDialog
				className='dialog'
				dialogClassName={dialogClassName}
				
				handleClose={onClose}
				handleSubmit={onSubmit}
				
				open={open}
				
				form_id={form_id}
				title_text={title_text}
				submit_text={submit_text}
				dismiss_text={dismiss_text}
				
				{...rest}
			>
				{dialog_content}
			</BasicDialog>
			
		</>
	);
}


export default ButtonToOpenDialog;
import { CircularProgress, Stack } from '@mui/material';
import { useDoc } from 'ember';
import { Requisition } from 'models';
import { useParams } from 'react-router';
import { useAuthData } from 'stuff';
import RequisitionDataProvider from '../RequisitionDataProvider';
import RequisitionCollabPageHeader from './RequisitionCollabPageHeader';
import RequisitionCollabPageTabs from './RequistionCollabPageTabs';



const RequisitionCollabPage = () =>
{
	const { claims } = useAuthData();
	const { requisition_id } = useParams();
	
	const requisition_doc = useDoc(`organizations/${claims?.organization_id}/requisitions`, requisition_id);
	
	if(requisition_doc?.is_loading)
	{
		return <CircularProgress />
	};
	
	const requsition = requisition_doc?.data as Requisition;
	console.log({requsition});
	
	
	return (
		<RequisitionDataProvider requisition={requsition}>
			<div className='issue_collab_page'>
				
				<RequisitionCollabPageHeader />
				
				<RequisitionCollabPageTabs />
				
			</div>
		</RequisitionDataProvider>
	)
};

export default RequisitionCollabPage;
import { Divider, Stack, Typography } from '@mui/material';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';

import ShortTextFormlet from 'app/gizmos/fields/text/ShortTextFormlet';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import RequisitionItemsSection from '../../RequisitionItemsSection';
import { useRequisition } from '../../RequisitionDataProvider';



const CreateRequisitionDetailsMain = () =>
{
	const { cost_codes } = useRequisition();
	
	
	return (
		<Stack
			spacing={3}
			sx={{
				padding: '10px'
			}}
		>
			
			<ShortTextFormlet
				name={'title'}
				label={'Ttile'}
			/>
			
			<SearchableSelectFormlet
				name={'cost_codes'}
				label={'Select associated Codes'}
				help={'e.g. Parts, Office Supplies'}
				options={cost_codes}
				getOptionLabel={(option) => option.description || ''}
				multiple={true}
			/>
			
			<Divider />
			
			<Typography
				sx={{
					fontWeight: 600,
					fontSize: '16px',
					color: defaultAppTheme.palette.grey['700'],
					lineHeight: 1,
					padding: '6px 0',
				}}
			>
				Add Items to Requisition
			</Typography>
			
			<RequisitionItemsSection />
		</Stack>
	)
}


export default CreateRequisitionDetailsMain;
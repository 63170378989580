import { LatLng } from 'leaflet';
import { round } from 'lodash';
import { Marker, Popup } from 'react-leaflet';
import { route_origin_marker, route_waypoint_marker } from '../markers/markers';
import { Waypoint } from './route_interfaces';


const RoutePoint =
({
	latlng,
	type,
	name,
	subheading,
	waypoint,
	zoffset = 1,
} : {
	latlng: LatLng,
	type: string,
	name: string,
	subheading: string,
	waypoint?: Waypoint,
	zoffset?: number,
}) =>
{
	let marker = (type === 'waypoint')
		? route_waypoint_marker
		: route_origin_marker;
	
	return (
		<Marker
			key={type}
			position={latlng}
			eventHandlers={{
				click: (e) => {
					console.log(e.target);
					console.log('Clicked marker:', type);
				},
				popupclose: () => {
					console.log('Route marker popup closed');
				},
			}}
			icon={marker}
			zIndexOffset={zoffset}
		>
			<Popup>
				<div>
					<h2>{name}</h2>
					<h3>{subheading}</h3>
					{
						(waypoint)
						&&
						<dl>
							<dt>
								Course
							</dt>
							<dd>
								{round(waypoint.point.properties.course, 1) + '°'}
							</dd>
							
							<dt>
								Elapsed Distance
							</dt>
							<dd>
								{round(waypoint.distance, 1) + ' NM'}
							</dd>
							
							{
								(waypoint.point.properties?.depthInfo?.depthValue)
								&&
								<>
									<dt>
										Depth
									</dt>
									<dd>
										{waypoint.point.properties?.depthInfo?.depthValue + ' m'}
									</dd>
								</>
							}
						</dl>
					}
				</div>
			</Popup>
		</Marker>
	)
}


export default RoutePoint;
import { ActionType, FormSection, StepperForm } from 'pages/CreateWorkOrder/work_order_types';
import {
	Control,
	useWatch,
} from 'react-hook-form';
import { ReactNode } from 'react';
import { Box, Divider } from '@mui/material';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import FillableText from './fillableActionTypes/FillableText';
import FillableRadioField from './fillableActionTypes/FillableRadioField';
import FillableCheckBox from './fillableActionTypes/FillableCheckbox';
import FillablePassFail from './fillableActionTypes/FillablePassFail';
import FillableYesNo from './fillableActionTypes/FillableYesNo';
import FillableDate from './fillableActionTypes/FillableDate';
import FillableReading from './fillableActionTypes/FillableReading';
import FillableReplace from './fillableActionTypes/FillableReplace';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import WorkOrderInstruction from './fillableActionTypes/WorkOrderInstruction';


export interface FieldProps
{
	control: Control<StepperForm>;
	sectionIndex: number;
	fieldIndex: number;
	sections?: FormSection[];
}



export const FillableField =
({
	control,
	sectionIndex,
	fieldIndex,
	sections,
}
: FieldProps) =>
{
	const action_type = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.action_type`,
	});
	
	
	const actionProp: ActionProps =
	{
		control,
		sectionIndex,
		fieldIndex,
		sections,
	};
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Divider
				sx={{
					marginTop: '3rem',
					marginBottom: '2rem',
					background: defaultAppTheme.palette.primary.main
				}}
				/>
			
			{
				(action_type !== '')
				&&
				renderActionType(action_type, actionProp)
			}
			
		</Box>
	);
};



const renderActionType =
(
	action_type: ActionType,
	actionProp: ActionProps
)
: ReactNode =>
{
	switch (action_type)
	{
		case 'text':
			return <FillableText {...actionProp} />;
		
		case 'multiple_choice':
			return <FillableRadioField {...actionProp} />;
		
		case 'checkbox':
			return <FillableCheckBox {...actionProp} />;
		
		case 'pass_fail':
			return <FillablePassFail {...actionProp} />;
		
		case 'yes_no':
			return <FillableYesNo {...actionProp} />;
		
		case 'date':
			return <FillableDate {...actionProp} />;
		
		case 'reading':
			return <FillableReading {...actionProp} />;
		
		case 'replace':
			return <FillableReplace {...actionProp} />;
		
		case 'instruction':
			return <WorkOrderInstruction {...actionProp} />;
		
		default:
			return <FillableText {...actionProp} />;
	}
};


export default FillableField;
export interface AddCollaboratorProps
{
	_username: string
	_position: string
};


const AddCollaborator =
({
	_username,
	_position,
}
: AddCollaboratorProps) =>
{
	return (
		<div
			className='addCollab__container'
		>
			Added
			{' '}{ _username }
			({_position})
			as a collaborator.
		</div>
	)
};


export default AddCollaborator;
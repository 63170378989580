import { Event } from 'types/misc_interfaces';
import { timeSinceText } from 'utils';
import UserAvatar from 'components/avatars/UserAvatar';

import FileList from '../files/FileList';
import { Card } from '@mui/material';
import { User } from 'types';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';



interface ChatEventProps
{
	event: Event,
	users?: User[],
}


const ChatEvent =
({
	event,
	users,
}
: ChatEventProps) =>
{
	// console.log('WHAT IS THIS EVENT', event);
	
	const event_user = users.find(user => user._id === event.user_id);
	
	let time = (!!event.datetime)
		&& timeSinceText(event.datetime)
	
	
	// if(!event.user)
	// {
	// 	console.warn(
	// 		'Cannot look up user (' + event.user_id + ') referenced in event',
	// 		event,
	// 	);
	// }
	
	
	// console.log(event);
	
	
	let sender_name = event_user?.display_name
		|| event?.sender_name
		|| '(Unknown)';
	
	
	let file_info = (event.files) &&
		<Card
			className='chat_content_files'
		>
			<FileList
				files={event.files}
			/>
		</Card>
	
	
	let collaborator_info = (event.collaborators) &&
		<div
			className='chat_content_files'
		>
			{
				event.collaborators.map(user => {
					return (
						<div className='chat_event_collaborators' key={user._id}>
							{user?.display_name} ({user?.email})
						</div>
					)
				})
			}
		</div>
	
	
	
	
	
	return (
		<div
			className='chat_content_zone'
			title={event.datetime?.replace('T', ' at ')}
		>
			
			<UserAvatar
				user={event_user}
				title={sender_name}
			/>
			
			<div
				className='chat_content'
			>
				<div
					className='chat_content_info'
				>
					<h5>{sender_name}</h5>
					<time
						className='chat_content_info__date'
					>
						{time}
					</time>
				</div>
				
				<div
					className='chat_content_summary'
				>
					{/* <p className='chat_event_summary'>{event?.summary}</p> */}
					<InfoHighlight
						severity={
							(event.subtype === 'upload')
							? 'info'
							: (event.subtype === 'update')
								? 'warning'
								: (event.subtype === 'create')
									? 'error'
									: 'success'
						}
						message_main={event.summary}
					/>
				</div>
				
				{file_info}
				
				{collaborator_info}
				
			</div>
			
		</div>
	);
}


export default ChatEvent;
import { makeStyles, createStyles } from "@mui/styles"

export const useStyles = makeStyles((theme?: any) => createStyles({
	CardItem_Box: {
		margin: '0.5rem 0 0.5rem 0',
		display: 'flex',
		flexDirection: 'row',
		width: '90%',
		alignItems: 'center'
	},
	CardHeader: {
		flexDirection: 'row-reverse',
		paddingBottom: '0'
	}

}))
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { Stack } from '@mui/material';

// import { Title } from './Title/Title';
// import { Description } from './Description/Description';
// import { WOTypeSelect } from './WOTypeSelect/WOTypeSelect';
// import { Tags } from './Tags/Tags';
// import Collaborators from './Collaborators/Collaborators';
// import { LinkIssues } from './Issues/LinkIssues';
// import { Attachments } from './Attachments/Attachments';
// import { useIsMobile } from 'utils/hooks/useIsMobile';
// import { Equipments } from './Equipments/Equipments';
// import { Parts } from './Parts/Parts';

import { WorkOrderDescriptionFormData } from '../work_order_types';
import WOTDescriptionForm from 'app/gizmos/WOTDescriptionForm';


export interface WorkOrderDescriptionStepProps
{
	form: UseFormReturn<WorkOrderDescriptionFormData>;
	collaboratorsModal: boolean;
	showCollaboratorsModal: () => void;
}


export const WorkOrderDescriptionStep =
({
	form,
	collaboratorsModal,
	showCollaboratorsModal,
}
: WorkOrderDescriptionStepProps) =>
{
	const form_context = useFormContext();
	
	
	// TODO: Remove
	// const { control } = form;
	
	
	// const isMobile = useIsMobile();
	
	
	return (
		<Stack
			sx={{
				width: 'min(674px, 100%)',
				marginInline: 'auto',
				marginTop: '2rem',
				display: 'flex',
				flexDirection: 'column',
				gap: '2rem',
			}}
		>
			
			
			<WOTDescriptionForm
				control={form_context.control}
			/>
			
			
			
			{/* <Title control={control} />
			<Description control={control} />
			
			
			
			
			<WOTypeSelect control={control} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '3rem',
				}}
			>
				<Tags control={control} />
			</Box>
			<Stack
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'
				gap={2}
				width={'100%'}
			>
				<Equipments control={control} />
				
				<Divider />
				
				<Parts control={control} />
			</Stack>
			
			<Divider />
			
			<Stack
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'
				gap={2}
				width={'100%'}
			>
				<LinkIssues control={control} />
				
				<Divider />
				
				<Attachments control={control} />
			</Stack>
			<Collaborators
				control={control}
				isModalOpen={collaboratorsModal}
				showModal={showCollaboratorsModal}
			/> */}
			
			
		</Stack>
	);
};


export default WorkOrderDescriptionStep;
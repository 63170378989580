
import { Typography } from '@mui/material';
import { ScheduleFormData } from 'pages/CreateWorkOrder/scheduling_types';
import { useWatch } from 'react-hook-form';
import { describeSchedule } from './schedule_helpers';


export const DescribeSchedule =
({
	control,
}) =>
{
	const schedule = useWatch({
		control,
	}) as ScheduleFormData;
	
	
	
	return (
		<Typography
			sx={{
				margin: 1,
				fontSize: '16px',
			}}
		>
			{
				describeSchedule(schedule)
			}
		</Typography>
	);
};


export default DescribeSchedule;
import { Box } from '@mui/material';

import Steppers from './Stepper/Stepper';
import { Schedule } from './Schedule/Schedule';
import { useCreateWorkOrderTemplate } from './useCreateWorkOrderTemplate';
import { SectionsPreview } from './SectionsPreview/SectionsPreview';
import { MobileStepper } from './Stepper/MobileStepper';
import { useIsMobile } from 'utils/hooks/useIsMobile';
import { Head } from './Head/Head';
import WorkOrderDescriptionStep from './GeneralDescription/WorkOrderDescriptionStep';
import CreateWorkOrderTemplateSections from './Sections/CreateWorkOrderTemplateSections';
// import { useWOTData } from './updated/WOTDataProvider';
// import { useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';


const CreateWOTStepper = () =>
{
	let [ search_params ] = useSearchParams();
	
	// const work_order_template_data = useWOTData();
	
	
	
	let current_step = Number(search_params.get('step')) || 1;
	
	
	
	
	
	const {
		schedule_form,
		step_form,
		work_order_description_form,
		
		display,
		
		showSectionsPreview,
		hideSectionsPreview,
		
		collaborators_modal,
		
		showCollaboratorsModal,
		handleBack,
		handleSubmit,
		
		is_submitting,
		
	} = useCreateWorkOrderTemplate();
	
	const isMobile = useIsMobile();
	
	
	const renderSteps = () =>
	{
		if (display !== 'form') return null;
		
		switch (current_step)
		{
			case 1:
				return (
					<WorkOrderDescriptionStep
						form={work_order_description_form}
						collaboratorsModal={collaborators_modal}
						showCollaboratorsModal={showCollaboratorsModal}
					/>
				);
			case 2:
				return <Schedule form={schedule_form} />;
			case 3:
				return (
					<CreateWorkOrderTemplateSections
						form={step_form}
						showPreview={showSectionsPreview}
					/>
				);
		}
	};
	
	
	// const handleSetActiveStep = (x) =>
	// {
	// 	let z = x;
		
	// 	if(typeof x === 'function')
	// 	{
	// 		z = x();
	// 	}
		
	// 	console.log({
	// 		x,
	// 		z,
	// 	})
		
	// 	setSearchParams(z);
	// }
	
	
	return (
		<>
			
			{
				(isMobile) &&
					<Head handleBack={handleBack} />
			}
			
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					height: isMobile ? 'calc(100% - 56px - 33px)' : '100%',
					maxHeight: isMobile ? 'calc(100% - 56px - 33px)' : '100%',
					overflowY: 'scroll',
					display: 'flex',
					flexDirection: 'column',
					marginTop: '20px',
				}}
			>
				
				<Box
					sx={{
						flexGrow: '0',
						height: '100%',
						overflowY: 'scroll',
						paddingInline: '1rem',
					}}
				>
					
					{
						(display === 'form' && !isMobile)
						&&
						<Steppers
							handleSubmit={handleSubmit}
							isSubmitting={is_submitting}
						/>
					}
					
					{renderSteps()}
					
					{
						(display === 'sectionsPreview')
						&&
						<SectionsPreview
							form={step_form}
							hidePreview={hideSectionsPreview}
						/>
					}
					
				</Box>
				
				{
					(display === 'form' && isMobile)
					&&
					<Box
						sx={{
							flexGrow: '0',
							flexShrink: '0',
						}}
					>
						<MobileStepper
							handleSubmit={handleSubmit}
							isSubmitting={is_submitting}
						/>
					</Box>
				}
				
			</Box>
		</>
	);
};


export default CreateWOTStepper;
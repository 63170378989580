import { Controller } from 'react-hook-form';
import { FormControl, OutlinedInput, Stack, Typography } from '@mui/material';
import Formlet from '../../fields/Formlet';
import { useFocus } from 'utils/hooks';
import { useRef } from 'react';
import { dotJoin, numberWithCommas } from 'utils';


export const ThresholdMinMaxFormlet =
({
	basename,
	label = 'Min./Max.',
	help = 'Minimum and maximum thresholds',
	units,
} : {
	basename?: string,
	label?: string,
	help?: string,
	units?: string,
}) =>
{
	const ref1 = useRef();
	const ref2 = useRef();
	
	const is_focused1 = useFocus(ref1);
	const is_focused2 = useFocus(ref2);
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Stack
				direction='row'
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					justifyItems: 'center',
				}}
			>
				<Controller
					name={dotJoin(basename, 'threshold.min')}
					render={({ field: { value, onChange } }) =>
						<FormControl>
							<OutlinedInput
								name={dotJoin(basename, 'threshold.min')}
								value={
									(value)
										? (!is_focused1)
											? numberWithCommas(value)
											: value
										: ''
								}
								onChange={(e) => {
									console.log(value)
									if(e.target.value === '')
									{
										onChange(undefined)
									}
									else
									{
										onChange(e.target.value);
									}
								}}
								
								inputRef={ref1}
								
								sx={{
									width: '130px',
									bgcolor: 'field.default',
									
									'&:hover': {
										bgcolor: 'field.hover',
									},
									
									'&.Mui-focused': {
										bgcolor: 'field.focus',
									}
								}}
							/>
						</FormControl>
					}
				/>
				
				<Typography
					sx={{
						padding: 1,
						color: '#0008',
					}}
				>
					⁠–⁠⁠⁠
				</Typography>
				
				<Controller
					name={dotJoin(basename, 'threshold.max')}
					render={({ field: { value, onChange } }) =>
						<FormControl>
							<OutlinedInput
								name={dotJoin(basename, 'threshold.max')}
								value={
									(value)
										? (!is_focused2)
											? numberWithCommas(value)
											: value
										: ''
								}
								onChange={(e) => {
									console.log(value)
									if(e.target.value === '')
									{
										onChange(undefined)
									}
									else
									{
										onChange(e.target.value);
									}
								}}
								
								inputRef={ref2}
								
								sx={{
									width: '130px',
									bgcolor: 'field.default',
									
									'&:hover': {
										bgcolor: 'field.hover',
									},
									
									'&.Mui-focused': {
										bgcolor: 'field.focus',
									}
								}}
							/>
						</FormControl>
					}
				/>
				
				<Typography
					sx={{
						padding: 1,
						color: '#0008',
					}}
				>
					{units}
				</Typography>
				
			</Stack>
		</Formlet>
	);
}


export default ThresholdMinMaxFormlet;
// import Stack from '@mui/material/Stack';
import { Chip, styled } from '@mui/material';
import { CurrentComponent } from 'models';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


export const StyledChip = styled(Chip)(() => ({
	margin: '5px',
	width: '100%',
	fontWeight: '400',
	maxWidth: '170px',
	cursor: 'pointer',
	'&:hover': {
		background: defaultAppTheme.palette.primary.main
	}
}));


const ComponentChip =
({
	component,
	name,
} : {
	component?: CurrentComponent,
	name?: string,
}) =>
{
	return (
		<StyledChip
			variant='outlined'
			label={component?.name || name}
		/>
	)
};


export default ComponentChip;
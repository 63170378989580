import { Avatar, Stack, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import TotalCount from './TotalCount';
import ProgressBar from './ProgressBar';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { CertificationFor } from 'models';
import { relativeDaysDiff } from 'utils';
import { DateTime } from 'luxon';
import { useCorsAnalyticsState } from './CorsAnalyticsStateProvider';
import CertificationList from 'app/dashboard/pages/certification/certificationList/CertificationList';



const CorsUserCertificationsSummary = () =>
{
	const { filtered_certifications } = useCorsAnalyticsState();
	
	const user_certifications = filtered_certifications.filter(x => (x.certification_for === CertificationFor.USER)
			|| (x.certification_for === CertificationFor.COMPANY_WIDE_ROLE)
		)
	
	
	const expired = user_certifications?.filter(x => !x.never_expires && relativeDaysDiff(DateTime.fromISO(x?.updated_record_expiry_date)) <= 0);
	const expires_soon = user_certifications?.filter(x => !x.never_expires &&
		(relativeDaysDiff(DateTime.fromISO(x?.updated_record_expiry_date)) > 0
		&& relativeDaysDiff(DateTime.fromISO(x?.updated_record_expiry_date)) <= 120)
	);
	const valid = user_certifications?.filter(x => x.never_expires || relativeDaysDiff(DateTime.fromISO(x?.updated_record_expiry_date)) > 120)
	
	
	
	return (
		<Stack
			className='container'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='center'
			width='100%'
			gap={1}
			sx={{
				minHeight: '350px !important'
			}}
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-start'
				gap={1}
			>
				<Avatar
					sx={{
						bgcolor: defaultAppTheme.palette.success.light
					}}
				>
					<CorIcon
						size='24px'
						of='certificate'
						color={defaultAppTheme.palette.success.dark}
					/>
				</Avatar>
				
				<Typography
					variant='h3'
				>
					User/Company Role Certifications
				</Typography>
			</Stack>
			
			<Stack
				flexDirection='row'
				alignItems='flex-start'
				justifyContent='flex-start'
				gap={2}
			>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{expired?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Expired
					</Typography>
				</Stack>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{expires_soon?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Expires-soon
					</Typography>
				</Stack>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{valid?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Valid+
					</Typography>
				</Stack>
				
				<Stack
					sx={{
						marginLeft: '40px'
					}}
				>
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Active
					</Typography>
					
					<TotalCount count={expired?.length + expires_soon?.length + valid?.length} />
				</Stack>
				
			</Stack>
			
			<ProgressBar
				value={
					Math.round((
						(expired?.length + expires_soon?.length)/(expired?.length + expires_soon?.length + valid?.length)
					)*100)
				}
				due_count={expired?.length + expires_soon?.length}
				total_count={expired?.length + expires_soon?.length + valid?.length}
			/>
			
			
			<Stack>
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.error.light }
					/>
					<ButtonToOpenDialog
						button_content='Expired'
						button_variant='text'
						dialog_title='Expired'
						dialog_content={<CertificationList certifications={expired} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
				
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.warning.main }
					/>
					<ButtonToOpenDialog
						button_content='Expires-soon'
						button_variant='text'
						dialog_title='Expiring before 120 days'
						dialog_content={<CertificationList certifications={expires_soon} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
				
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.success.main }
					/>
					<ButtonToOpenDialog
						button_content='Valid+'
						button_variant='text'
						dialog_title='Certifications valid over 120 days'
						dialog_content={<CertificationList certifications={valid} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
			</Stack>
			
		</Stack>
	)
}

export default CorsUserCertificationsSummary;
import { useNavigate } from 'react-router';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useSearchParams } from 'react-router-dom';
import { CorComponent } from 'models';


const ComponentDrawerActionsList =
({
	component
} : {
	component?: CorComponent
}) =>
{
	const navigate = useNavigate();
	
	let [ params, setSearchParams ] = useSearchParams();
	
	
	const actions_list =
	[
		{ id: 1, name: 'Create New Issue', icon: 'create_issue' },
		{ id: 2, name: 'Start a Work Order', icon: 'create_work_order' },
		{ id: 3, name: 'View Technical Documentation', icon: 'technical_data' },
		{ id: 4, name: 'View QR Sticker', icon: 'qr_code' },
		{ id: 5, name: 'Component Records', icon: 'iot' }
	]
	
	
	const dispatchAction = (item_id: number) =>
	{
		if(item_id === 1)
		{
			navigate(`/create-issue?asset=${component?.asset_id}&system=${component?.system_id}&component=${component._id}`)
		}
		else if(item_id === 2)
		{
			navigate('/work-orders/create-template')
		}
		else if(item_id === 3)
		{
			navigate('./files')
		}
		else if(item_id === 4)
		{
			setSearchParams({
				...params,
				show_qr_label: 'true',
			})
		}
		else if(item_id === 5)
		{
			navigate('./component-records');
		}
	}
	
	
	return (
		<Stack
			spacing={2}
			direction='column'
			justifyContent='center'
			alignItems='flex-start'
			sx={{
				width: '100%',
				marginBottom: '30%',
				marginTop: '20px',
			}}
		>
			{
				actions_list.map(item =>
					<Button
						key={item.id}
						fullWidth
						onClick={() => dispatchAction(item.id)}
						sx={{
							textTransform: 'none',
							color: defaultAppTheme.palette.grey['800'],
						}}
					>
						<Stack
							key={item.id}
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							width='100%'
						>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='flex-start'
							>
								<CorIcon of={item.icon} size='20px' />
								<Typography
									variant='subtitle2'
									sx={{
										marginLeft: '10px',
									}}
								>
									{ item.name }
								</Typography>
							</Stack>
							
							<CorIcon of='arrow_right' size='20px' />
						</Stack>
					</Button>
				)
			}
			
		</Stack>
	)
}


export default ComponentDrawerActionsList;
import axios from 'axios';
import { useEffect, useState } from 'react';


export const useDtn  = () =>
{
	//DTN Route API Data
	const [ dtn_access_token, setDtnAccessToken ] = useState(null);
	
	
	// DTN API Initilization
	const dtnApiInit = async () =>
	{
		const authCredentials =
		{
			grant_type: 'client_credentials',
			client_id: 'lycELjtbiITElNnFdREyD5xnWVzhezTY',
			client_secret: '6RlLL5XTq4JgDKFgnB7tw-z6dU-d5xhFrW9xFjHq0LMjcfinj7guMF21ZjzlpOdL',
			audience: 'https://routing.api.shipping.dtn.com',
		};
		
		try
		{
			const resp = await axios.post(
				'https://auth.api.shipping.dtn.com/oauth/token',
				authCredentials
			);
			
			console.log(resp.data);
			
			setDtnAccessToken(resp.data?.access_token);
		}
		catch (err)
		{
			// Handle Error here
			console.error(err);
		}
	}
	
	
	useEffect(
		() =>
		{
			dtnApiInit();
		},
		[]
	);
	
	
	return dtn_access_token;
}




import { RecurrenceScheduleType } from 'models';
import { Controller } from 'react-hook-form';
import EnumToggleField from 'app/gizmos/fields/toggles/EnumToggleField';
import Formlet from '../fields/Formlet';


// Choose between Daily, Weekly, Monthly, Yearly and On Demand
export const RecurrenceScheduleTypeFormlet =
({
	name = 'recurrence.recurrence_schedule',
	label = 'Schedule Type',
} : {
	name?: string,
	label?: string,
}) =>
{
	return (
		<Formlet
			label={label}
			help='What kind of schedule?'
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<EnumToggleField
						url_param_key='schedule'
						enum_type={RecurrenceScheduleType}
						value={value}
						onChange={onChange}
					/>
				}
			/>
		</Formlet>
	);
}


export default RecurrenceScheduleTypeFormlet;
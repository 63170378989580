import { useURLStepNum } from 'utils/hooks';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Stack, Button, useTheme, Stepper, Step } from '@mui/material';

import CorIcon from 'components/icons/CorIcon';


const StepperGizmoFooter =
({
	headings,
}) =>
{
	const theme = useTheme();
	
	const { step, nextStep, prevStep, setStep } = useURLStepNum();
	
	
	let is_last_step = (step === headings.length);
	
	
	return (
		<Stack
			justifyContent='space-between'
			alignItems='center'
			direction='row'
			sx={{
				boxShadow: '0 -1px 3px -1px #0004',
				bgcolor: 'bg.stepper_header',
				zIndex: 1,
			}}
		>
			
			<Button
				size='large'
				onClick={prevStep}
				disabled={step === 1}
			>
				{
					(theme.direction === 'rtl')
						? <KeyboardArrowRight />
						: <KeyboardArrowLeft />
				}
				Back
			</Button>
			
			
			<Stepper
				activeStep={step + 1}
				// alternativeLabel
				sx={{
					alignContent: 'center',
					justifyContent: 'center',
				}}
			>
				{headings.map((heading, i) => (
					<Step
						key={i}
						sx={{
							padding: 0,
						}}
					>
						<Button
							key={heading}
							onClick={() => setStep(i + 1)}
							title={heading || 'Step ' + (i + 1)}
							sx={{
								padding: '10px 12px',
								minWidth: '32px',
								color: (step === (i + 1)) ? 'primary' : '#0003',
							}}
						>
							<CorIcon
								of='circle'
								size={14}
							/>
						</Button>
					</Step>
				))}
			</Stepper>
			
			
			{
				(!is_last_step)
				&&
				<Button
					size='large'
					onClick={nextStep}
					disabled={false}
					type='button'
				>
					Next
					{
						(theme.direction === 'rtl')
							? <KeyboardArrowLeft />
							: <KeyboardArrowRight />
					}
				</Button>
			}
			
			
			{
				(is_last_step)
				&&
				<Button
					size='large'
					onClick={() => {}}
					disabled={false}
					type='submit'
				>
					Finish
					{
						(theme.direction === 'rtl')
							? <KeyboardArrowLeft />
							: <KeyboardArrowRight />
					}
				</Button>
			}
			
		</Stack>
	)
}


export default StepperGizmoFooter;
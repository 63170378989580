import { Box } from '@mui/material';
import { useWrite } from 'ember';
import { useAuthData } from 'stuff';
import MobileHeader from '../../../settings_mobile/MobileHeader';
import GenerateNewTag from '../GenerateNewTag';
import { Tag } from 'types';

const CreateNewTag = () =>
{
	const { claims } = useAuthData();
	const write = useWrite();
	
	const org_collection_path = `organizations/${claims.organization_id}`;
	
	// Update the tags state to add new tag object
	// passed by GenerateNewTag
	const handleSubmit = ( newTag: Tag ) =>
	{
		console.log(newTag);
		write(`${org_collection_path}/tags`, newTag);
		
		write(`${org_collection_path}/tag_roles`, {_id: newTag._id});
	}
	
	return (
		<Box sx={{ margin: '10px' }}>
			<MobileHeader
				title='Create New Tags'
				variantType='h3'
			/>
			<GenerateNewTag
				mobile={true}
				handleSubmit={handleSubmit}
			/>
		</Box>
	)
}


export default CreateNewTag;
import { Stack } from '@mui/material';

import { WOTDataProvider } from './WOTDataProvider';
import CreateWOTStepper from '../CreateWOTStepper';
import { useTitle } from 'utils';


const CreateWorkOrderTemplatePage = () =>
{
	useTitle('Create Work Order Template');
	
	
	return (
		<WOTDataProvider>
			<Stack
				sx={{
					flexGrow: 1,
					// maxWidth: '500px',
				}}
			>
				<CreateWOTStepper />
			</Stack>
		</WOTDataProvider>
	)
};


export default CreateWorkOrderTemplatePage;
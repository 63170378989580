import { Controller, useFormContext } from 'react-hook-form';
import { FormInputType, ResponseField } from 'types/forms';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import LuxonAdapter from '@mui/lab/AdapterLuxon';
import { TimePicker } from '@mui/x-date-pickers';

import { WorkOrderResponseForm } from 'types/forms';


export interface TimeProps
{
	field: ResponseField & {
		type: FormInputType.Time,
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const Time = ({ field, sectionIndex, fieldIndex }: TimeProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Controller
				control={control}
				name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.time`}
				defaultValue=''
				render={({ field: { value, onChange } }) => (
					<LocalizationProvider dateAdapter={LuxonAdapter}>
						<TimePicker
							value={value}
							onChange={(newValue) =>
								onChange(newValue.toString())
							}
							renderInput={(params) => (
								<TextField {...params} fullWidth />
							)}
						/>
					</LocalizationProvider>
				)}
			/>
		</Box>
	);
};


export default Time;
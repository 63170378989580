import { Grid } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { cx, DateTime } from 'stuff';
import { useCalendar } from './CalendarProvider';


// day is a DateTime or null
const CalendarDay =
({
	day,
} : {
	day: DateTime,
}) =>
{
	const { selected_datetime, onDaySelected, events_by_date, setEventsOnDay } = useCalendar();
	
	
	//console.log(day, events_by_date);
	
	
	let iso = day.toISO().slice(0, 10);
	
	let events_on_day = events_by_date ? events_by_date[iso] : [];
	
	let today = DateTime.local();
	
	
	const selectDayAndEvents = () =>
	{
		onDaySelected(day);
		
		console.log({events_on_day});
		
		if(events_on_day?.length > 0)
		{
			setEventsOnDay(events_on_day);
		}
		else
		{
			setEventsOnDay([]);
		}
	}
	
	//if(events_on_day?.length) console.log(events_on_day);
	
	
	let pips = <Grid
		container
		width='100%'
		sx={{
			padding: '2px',
		}}
		justifyContent='center'
	>
		{events_on_day?.map(event =>
			<Grid
				item
				key={event._id}
			>
				<CorIcon
					of='circle_filled'
					color={event.color}
					size='15px'
				/>
			</Grid>
		)}
	</Grid>;
	
	
	let is_today    = (today.ordinal === day?.ordinal) && (today.year === day?.year);
	let is_selected = day && (selected_datetime?.ordinal === day?.ordinal) && (selected_datetime?.year === day?.year);
	
	
	return <div
		className={cx(
			'calendar3_day',
			{
				weekend: day?.weekday > 5,
				today: is_today,
				selected: is_selected,
				odd_month: (day.month % 2)
			}
		)}
		onClick={() => selectDayAndEvents()}
	>
		<p>
			{day?.day}
		</p>
		{pips}
	</div>
}


export default CalendarDay;
import { Box, styled } from "@mui/material";

export const RepeatTypeChip = styled(Box)<{
    isSelected: boolean;
}>(({ isSelected }) => ({
    cursor: "pointer",
    color: isSelected ? "white" : "black",
    backgroundColor: isSelected ? "#3F8ED8" : "white",
    boxShadow: isSelected ? `0px 2px 3px rgba(0, 0, 0, 0.4)` : "",
    paddingInline: isSelected ? "1rem" : "",
    paddingBlock: "0.5rem",
    borderRadius: "1rem",
}));

export const Container = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
}));

import { Controller, useFormContext } from 'react-hook-form';
import { Button, FormControl, Stack, TextField, Typography } from '@mui/material';

// import { useFocus } from 'utils/hooks';
import CorIcon from 'components/icons/CorIcon';
import { useRef } from 'react';


export const HorizontalCounter =
({
	name,
	units,
	is_required,
	label,
	min = 0,
	max,
	displayValue,
	onChange,
} : {
	name: string,
	units?: string,
	is_required?: boolean,
	label?: string,
	min?: number,
	max?: number,
	displayValue?: Function,
	// The counter's own controller can be bypassed by providing a higher-level onChange handler
	onChange?: Function,
}) =>
{
	const { watch } = useFormContext();
	const ref = useRef();
	
	// const is_focused = useFocus(ref);
	
	
	let watched_value = watch(name);
	
	
	const handleMinus = (value: number, onChange: Function) =>
	{
		// if(watched_value)
		
		if(value > (min ?? 0))
		{
			onChange(value - 1);
		}
	}
	
	
	const handlePlus = (value: number, onChange: Function) =>
	{
		if(typeof max === 'undefined')
		{
			onChange(value + 1);
		}
		else if((value < max))
		{
			onChange(value + 1);
		}
	}
	
	
	return (
		<FormControl>
			<Stack>
				<Controller
					name={name}
					defaultValue={min}
					rules={{
						required: is_required,
						max: max ?? undefined,
						min: min,
					}}
					
					render={({ field: { value, onChange: onCounterChange } }) =>
						<Stack
							justifyContent='center'
							alignItems='center'
						>
							<Stack
								justifyContent='center'
								alignItems='center'
								direction='row'
							>
								<Button
									onClick={() =>
										(onChange !== undefined)
											? handleMinus(watched_value, onChange)
											: handleMinus(watched_value, onCounterChange)
									}
									disabled={watched_value === min}
								>
									<CorIcon
										of='minus'
										size={24}
									/>
								</Button>
								
								<Stack>
									
									<TextField
										name={name}
										value={
											watched_value
											// (is_focused)
											// 	? value
											// 	: value //displayValue?.(value)
										}
										onChange={(event) => {
											let counter_value = Number(event.target.value);
											
											if(counter_value)
											{
												if(onChange !== undefined)
												{
													onChange(counter_value);
												}
												else
												{
													onCounterChange(counter_value);
												}
											}
										}}
										
										inputRef={ref}
										inputProps={{
											sx: {
												fontSize: '20px',
											},
										}}
										
										sx={{
											border: 'none',
											maxWidth: '50px',
											
											
											'& .MuiOutlinedInput-input': {
												borderWidth: 0,
												outline: 'none',
												padding: 0,
												textAlign: 'center',
												// color: '#000',
												// background: (value === 0) ? '#fff7' : '#fffc',
											},
											
											'& .MuiOutlinedInput-root:hover': {
												background: '#fffc',
											},
											
											'& .Mui-focused': {
												color: '#000',
												background: '#fff',
												opacity: 1,
											},
											
											'& .Mui-focused .MuiOutlinedInput-input': {
												color: '#000',
												// background: '#fff',
												// opacity: 1,
											},
											
											
											'& fieldset': {
												borderColor: '#0000',
											},
											
											'& .Mui-focused fieldset': {
												borderColor: '#000',
												opacity: 1,
												color: '#000',
											},
											
											
											'& label': {
												background: '#fff',
												opacity: (value === 0) ? 0.3 : 0.9,
											},
											
											'& label.Mui-focused': {
												background: '#fff',
												opacity: 1,
											},
										}}
									/>
									
								
								</Stack>
								
								
								<Button
									onClick={() =>
										(onChange !== undefined)
											? handlePlus(watched_value, onChange)
											: handlePlus(watched_value, onCounterChange)
									}
								>
									<CorIcon
										of='plus'
										size={24}
									/>
								</Button>
							
							</Stack>
							
							<Typography
								sx={{
									textAlign: 'center',
									fontSize: '12px',
								}}
							>
								{(watched_value === 1) ? units.slice(0, -1) : units}
							</Typography>
							
						</Stack>
					}
				/>
			</Stack>
		</FormControl>
	);
};


export default HorizontalCounter;
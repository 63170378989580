import { Stack, Typography } from '@mui/material';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import LongTextFormlet from 'app/gizmos/fields/text/LongTextFormlet';
import CorIcon from 'components/icons/CorIcon';
import { UseFieldArrayRemove } from 'react-hook-form';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


const RequisitionSectionsTemplate =
({
	section,
	sectionIndex,
	removeSection,
} : {
	section: Record<'id', string>,
	sectionIndex: number,
	removeSection: UseFieldArrayRemove,
}) =>
{
	return (
		<Stack
			className='container'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='flex-start'
			width='100%'
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='space-between'
				width='100%'
				gap={2}
			>
				<Typography
					variant='subtitle1'
					sx={{
						padding: '4px 12px',
						borderRadius: '4px',
						bgcolor: `${defaultAppTheme.palette.secondary.light}`,
					}}
				>
					Section#{sectionIndex + 1}
				</Typography>
				
				<CorIcon
					of='close'
					size='24px'
					color={defaultAppTheme.palette.secondary.main}
					cursor='pointer'
					onClick={() => removeSection(sectionIndex)}
				/>
			</Stack>
			
			<LongTextFormlet
				label='Description'
				help='Could include details about Labour Costs, Capital Projects, etc.'
				name={`items.sections[${sectionIndex}].description`}
			/>
			
			<CostFormlet
				label='Any associated costs'
				help='Could include costs for covering Labour, Capital Projects, etc.'
				name={`items.sections[${sectionIndex}].cost`}
			/>
		</Stack>
	)
};


export default RequisitionSectionsTemplate;
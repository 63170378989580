import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import CreateAction from '../../actions/createAction/CreateAction';
import AddtionalIssueControls from './AddtionalIssueControls';
import ActionsListResponsiveContent from './ActionsListResponsiveContent';
import { useIssue } from '../IssueProvider';
import ResponsiveWorkOrdersList from 'pages/work_orders/work_orders/ResponsiveWorkOrdersList';



const ActionsTab = () =>
{
	const { issue, actions, work_orders } = useIssue();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	return (
		<Box
			sx={{
				padding: '10px',
				width: '100%',
			}}
		>
			<Stack
				direction='row'
				width='100%'
				justifyContent='flex-end'
				sx={{
					marginBottom: '20px',
				}}
			>
				<ButtonToOpenDialog
					button_variant='contained'
					dialog_title=''
					button_content='Create Action'
					dialog_content={
						<CreateAction
							action_metadata={{ issue_id: issue._id }}
							closeDialog={closeDialog}
							setCloseDialog={setCloseDialog}
						/>
					}
					closeDialog={closeDialog}
				/>
			</Stack>
			
			<ActionsListResponsiveContent actions={actions} />
			
			<ResponsiveWorkOrdersList work_orders={work_orders} title={'Linked Work Orders'} />
			
			<AddtionalIssueControls issue={issue} />
		</Box>
	)
}


export default ActionsTab;
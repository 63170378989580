import cuid from 'cuid';
import { DateTime, Interval } from 'luxon';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useAuthData } from 'stuff';

import { Action, Issue, Requisition, WorkOrderNew } from 'models';
import { Event } from 'types';
import { groupByProp } from 'utils';
import { makeWeekStack, offsetWeekBy, relatedWeek } from './calendar_utils';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';



const num_weeks_before = 2;
const num_weeks_after  = 2;



interface CalendarData
{
	// Events created from issues, actions, etc.
	
	events: Event[];
	events_by_date: Object;
	
	selected_datetime?: DateTime;
	// selected_time?: DateTime;
	
	focus_day?: DateTime;
	focus_week?: Interval;
	
	weeks: Interval[];
	
	onDaySelected: Function;
	onTimeSelected: Function;
	
	setFocusWeek: Function;
	
	incrementFocusWeek: Function;
	decrementFocusWeek: Function;
	
	onEventUpdate: Function;
	
	show_week_num: boolean;
	
	events_on_day: Event[];
	setEventsOnDay: Function;
}



const CalendarContext = React.createContext<CalendarData>(null);



// Our hook that children will use to access the data we provide
// (Don't confuse with reactfire's hook with the same name)
export function useCalendar()
{
	return useContext(CalendarContext);
}



const CalendarProvider =
({
	issues,
	actions,
	work_orders,
	requisitions,
	children,
} : {
	issues: Issue[],
	actions: Action[],
	work_orders: WorkOrderNew[],
	requisitions: Requisition[],
	children: ReactNode,
}) =>
{
	const { uid } = useAuthData();
	
	const [ events, setEvents ] = useState<Event[]>([]);
	const [ events_on_day, setEventsOnDay ] = useState<Event[]>([]);
	
	
	useEffect(
		() =>
		{
			const issue_events = issues.map((issue: Issue) => {
				return {
					_id: cuid(),
					name: issue.name,
					
					datetime: issue.date_opened,
					date: issue.date_opened.slice(0, 10),
					
					type: 'issue',
					subtype: null,
					
					summary:  issue.issue_num?.toString(),
					
					issue_id: issue._id,
					user_id: uid,
					color: defaultAppTheme.palette.secondary.main,
				}
			});
			
			const action_events = actions.map((action: Action) => {
				return {
					_id: cuid(),
					name: action.inquiry,
					
					datetime: action.created_at,
					date: action.created_at.slice(0, 10),
					
					type: 'action',
					subtype: null,
					
					summary: action?.action_num?.toString() || null,
					
					action_id: action._id,
					issue_id: action?.issue_id || null,
					requisition_id: action?.requisition_id || null,
					user_id: uid,
					color: defaultAppTheme.palette.warning.dark,
				}
			});
			
			const work_order_events = work_orders.map((work_order: WorkOrderNew) => {
				return {
					_id: cuid(),
					name: work_order.title,
					
					datetime: work_order.scheduled_for,
					date: work_order.scheduled_for.slice(0, 10),
					
					type: 'work_order',
					subtype: null,
					
					summary: work_order?.work_order_num?.toString() || null,
					
					work_order_id: work_order._id,
					user_id: uid,
					color: defaultAppTheme.palette.primary.main,
				}
			});
			
			const requisition_events = requisitions.map((requisition: Requisition) => {
				return {
					_id: cuid(),
					name: requisition.title,
					
					datetime: requisition.created_at,
					date: requisition?.created_at?.slice(0, 10),
					
					type: 'requisition',
					subtype: null,
					
					summary: requisition?.requisition_num?.toString() || null,
					
					requisition_id: requisition._id,
					user_id: uid,
					color: 'rgb(98, 30, 224)',
				}
			});
			
			setEvents([
				...action_events,
				...issue_events,
				...work_order_events,
				...requisition_events
			]);
			
		}
		,
		[issues, actions, work_orders, requisitions, uid]
	);
	
	const [ selected_datetime, setSelectedDatetime ] = useState<DateTime>(DateTime.local());
	const [ focus_week, setFocusWeek ] = useState<Interval>(relatedWeek(DateTime.local()));
	
	
	const incrementFocusWeek = () => setFocusWeek(offsetWeekBy(focus_week, 1));
	const decrementFocusWeek = () => setFocusWeek(offsetWeekBy(focus_week, -1));
	
	
	const weeks = makeWeekStack(focus_week, num_weeks_before, num_weeks_after);
	
	
	// issues.forEach(issue => {
	// 	issue.date = issue.datetime.slice(0, 10)
	// })
	
	
	
	const onDaySelected = (day: DateTime) =>
	{
		let new_datetime = DateTime.fromObject(
			Object.assign(selected_datetime.toObject(), {
				year: day.year,
				month: day.month,
				day: day.day,
			})
		)
		
		setSelectedDatetime(new_datetime);
		
		setFocusWeek(relatedWeek(new_datetime))
		
		setEvents(
			Object.assign(events, {
				datetime: new_datetime.toISO(),
			})
		);
	}
	
	
	const onTimeSelected = (time: DateTime) =>
	{
		let new_datetime = DateTime.fromObject(
			Object.assign(selected_datetime.toObject(), {
				hour: time.hour,
				minute: time.minute,
				second: time.second,
				millisecond: time.millisecond,
			})
		)
		
		setSelectedDatetime(new_datetime);
		
		setEvents(
			Object.assign(events, {
				datetime: new_datetime.toISO(),
			})
		);
	}
	
	
	const handleEventUpdate = (events: Event[]) =>
	{
		setEvents(events);
	}
	
	
	
	
	const calendar_data : CalendarData =
	{
		events: events,
		
		selected_datetime,
		
		events_by_date: groupByProp(events, 'date'),
		
		onDaySelected: onDaySelected,
		onTimeSelected: onTimeSelected,
		
		setFocusWeek: setFocusWeek,
		incrementFocusWeek: incrementFocusWeek,
		decrementFocusWeek: decrementFocusWeek,
		
		onEventUpdate: handleEventUpdate,
		
		weeks: weeks,
		
		show_week_num: false,
		
		events_on_day: events_on_day,
		setEventsOnDay: setEventsOnDay,
	}
	
	
	// console.log(calendar_data);
	
	
	return (
		<CalendarContext.Provider value={calendar_data}>
			{children}
		</CalendarContext.Provider>
	);
}



export default CalendarProvider;
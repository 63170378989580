import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

type SelectProps = 
{
	inputLabel: string,
	id: string,
	formLabel: string,
	selectItems: string[],
	MenuProps: any
}

const FormSelect = ({ inputLabel, id, formLabel, selectItems, MenuProps, } : SelectProps) => 
{
	const { register, control, formState: {errors} } = useFormContext();
	

	return (

		<FormControl sx={{ width: '250px' }}>

			<InputLabel id={id}>
				{inputLabel}
			</InputLabel>

			<Controller
				control={control}
				name={formLabel}
				render={({field: {onChange, value}}) => (
					<Select
						id={id}
						{...register(formLabel, {required: 'Severity is required.'})}
						label={formLabel}
						value={value}
						onChange={onChange}
						MenuProps={MenuProps}
						error={(errors?.severity !== undefined) ? (errors.severity?.message !== '') : false}
					>

						{
							selectItems.map((item) => {
								return <MenuItem key={`${item}`} value={`${item}`}>{item}</MenuItem>
							})
						}

					</Select>
				)}
			/>
			

		</FormControl>

		
	)
}

export default FormSelect;
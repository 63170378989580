import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useStyles } from '../../../styles/ManageTagsTabTheme';
import { TagPreviewProps } from 'app/dashboard/pages/settings/interfaces/settings_Interfaces';


const TagPreview = ({ title, bgColor, color } : TagPreviewProps) =>
{
	const classes = useStyles({});

	return (
		<>
			<Box
				className={classes.TagColorsContainer}
			>
				<Typography
					variant='body1'
					color='#323338'
				>
					Preview
				</Typography>
				
				<Paper
					className={classes.TagPreviewPaper}
					sx={{backgroundColor: bgColor, color: color}}
				>
					{ title }
				</Paper>
			</Box>
		</>
	)
}


export default TagPreview;
import { useState } from 'react';

import CertificationDataProvider from './CertificationDataProvider';
import { Box, Stack, Typography } from '@mui/material';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import CreateCertificate from './CreateEditCertification';


import CertificationPageTabs from './CertificationPageTabs';


const CertificationPageMain = () =>
{
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	return (
		<CertificationDataProvider>
				<Box
					sx={{
						padding: '20px',
						overflowY: 'auto',
						height: '100%'
					}}
				>
					
					
					<Stack
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						width='100%'
						spacing={2}
						sx={{
							margin: '10px',
						}}
					>
						
						<Typography
							variant='h1'
						>
							Certifications
						</Typography>
						
						<ButtonToOpenDialog
							button_variant='contained'
							dialog_title=''
							button_content='Create Certification'
							dialog_content={
								<CreateCertificate
									closeDialog={closeDialog}
									setCloseDialog={setCloseDialog}
								/>
							}
							closeDialog={closeDialog}
						/>
						
						<CertificationPageTabs />
						
						
					</Stack>
					
				</Box>
		</CertificationDataProvider>
	)
}


export default CertificationPageMain;
import { ComponentRecord } from 'models';
import ReadingFormGizmo from './ReadingFormGizmo';
import ReadingFormProvider from './ReadingFormProvider';


const ComponentRecordReadingForm =
({
	component_record,
} : {
	component_record?: ComponentRecord,
}) =>
{
	
	return (
		<ReadingFormProvider
			component_record={component_record}
		>
			<ReadingFormGizmo />
		</ReadingFormProvider>
	)
}


export default ComponentRecordReadingForm;
import { Map } from 'leaflet';
import { clamp } from 'utils';



// Classes used by Leaflet to position controls
const POSITION_CLASSES =
{
	bottomleft: 'leaflet-bottom leaflet-left',
	bottomright: 'leaflet-bottom leaflet-right',
	topleft: 'leaflet-top leaflet-left',
	topright: 'leaflet-top leaflet-right',
}



export const minimapClasses = (position: string) =>
{
	return (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
}



// Util for clamping the minimap zoom between limits, relative to the parent zoom
export const determineMinimapZoom = (main_map: Map) =>
{
	return clamp(
		0,
		main_map.getZoom() - 6,
		10
	)
}

import { Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useIsMobile } from 'utils/hooks/useIsMobile';
import StepperGizmo from '../../../app/gizmos/stepper/StepperGizmo';

import WOTStep1 from './WOTStep1';
import WOTStep2 from './WOTStep2';
import WOTStep3 from './WOTStep3';


// Split steps in stepper, using URL param step num to manage stepper progress
const WOTStepper = () =>
{
	let form_values = useWatch();
	
	const is_mobile = useIsMobile();
	
	
	console.log(
		form_values,
	);
	
	console.log(
		form_values?.recurrence,
	);
	
	
	return (
		<Stack
			padding={(is_mobile) ? 1 : 3}
			// gap={2}
			// justifyContent='center'
			// justifyItems='center'
			alignItems='stretch'
		>
			
			<StepperGizmo
				headings={['General Info', 'Schedule', 'Form Builder']}
			>
				<WOTStep1 />
				<WOTStep2 />
				<WOTStep3 />
			</StepperGizmo>
			
		</Stack>
	);
};


export default WOTStepper;
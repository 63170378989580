
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormInputType, WorkOrderResponse, WorkOrderResponseForm } from 'types/forms';
import { Calendar } from './ActionTypes/Calendar/Calendar';
import { Checkbox } from './ActionTypes/Checkbox/Checkbox';
import { PassFail } from './ActionTypes/PassFail/PassFail';
import { Radio } from './ActionTypes/Radio/Radio';
import { Reading } from './ActionTypes/Reading/Reading';
import { Replace } from './ActionTypes/Replace/Replace';
import { Time } from './ActionTypes/Time/Time';
import { YesNoNa } from './ActionTypes/YesNoNa/YesNoNa';
import { FieldContainer, FieldDescription } from './styles';


export interface FieldProps
{
	sectionIndex: number,
	fieldIndex: number,
}



export const Field = ({ sectionIndex, fieldIndex }: FieldProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	const field = useWatch({
		control,
		name: `form_sections.${sectionIndex}.fields.${fieldIndex}`,
	});
	
	
	return (
		<FieldContainer>
			
			<FieldDescription>
				<Controller
					control={control}
					name={`form_sections.${sectionIndex}.fields.${fieldIndex}.label`}
					render={({ field: { value } }) => <div>{value}</div>}
				/>
			</FieldDescription>
			
			<FieldRendererByType
				field={field}
				sectionIndex={sectionIndex}
				fieldIndex={fieldIndex}
			/>
			
		</FieldContainer>
	);
};


export interface FieldRendererByTypeProps
{
	field: WorkOrderResponse['form_sections'][number]['fields'][number];
	fieldIndex: number;
	sectionIndex: number;
}


// TODO: Instruction/Description difference? Where's text field?
const FieldRendererByType = ({ field, ...props }: FieldRendererByTypeProps) =>
{
	switch (field.type)
	{
		case FormInputType.Instruction:
		case FormInputType.Description:
			return <></>;
		
		case FormInputType.NoYesNa:
			return <YesNoNa {...props} field={field} />;
		
		case FormInputType.Calendar:
			return <Calendar {...props} field={field} />;
		
		case FormInputType.Reading:
			return <Reading {...props} field={field} />;
		
		case FormInputType.Checkbox:
			return <Checkbox {...props} field={field} />;
		
		case FormInputType.Replace:
			return <Replace {...props} field={field} />;
		
		case FormInputType.Radio:
			return <Radio {...props} field={field} />;
		
		case FormInputType.FailPass:
			return <PassFail {...props} field={field} />;
		
		case FormInputType.Time:
			return <Time {...props} field={field} />;
		
		default:
			return <></>;
	}
};

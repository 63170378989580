import { CorAsset } from './component_models';



// These interfaces don't quite match. One includes the properties directly from
// SQL tables, and the other already translated the names in the SQL query.



// AMOS represents maintenance functions and assets are separate concepts,
// but we have modelled them as one - components within our system can be
// differentiated by their AmosComponentType.
export enum AmosComponentType
{
	// A role to be filled - divides stuff into conceptual groups using SFI codes
	// forming a tree that may include assets (which may appear in multiple places)
	FUNCTION = 'Function',
	
	// Physical machinery
	ASSET = 'Asset',
}



// How AMOS represents maintenance functions - ie: the roles that machinery fulfills
export interface AmosMaintFunction
{
	_id: string,
	
	MAINTFUNCTIONID?: string,
	PARENTFUNCTIONID?: string,
	CRITICALITYID?: string,
	LOCATIONID?: string,
	CODE?: string,
	DESCRIPTION?: string,
	NOTES?: string,
	CREATEDDATE?: string,
	CREATEDBY?: string,
	ACTIVEDATE?: string,
	ACTIVEBY?: string,
	DEACTIVATEDDATE?: string,
	DEACTIVATEDBY?: string,
	CANCELLEDDATE?: string,
	CANCELLEDBY?: string,
	UDFTEXT01?: string,
	UDFTEXT02?: string,
	UDFTEXT03?: string,
	INTERNALSTATUS?: string,
	BUSINESSFLOWID?: string,
	BUSINESSFLOWSTATUSID?: string,
	INSTID?: string,
	DELETESTATUS?: string,
	USERIDLASTUPDATED?: string,
	UPDATESITE?: string,
	EXPORTMARKER?: string,
	LASTUPDATED?: string,
	BLOBLASTUPDATED?: string,
	UDFMARPOL?: string,
	UDFIMONO?: string,
	
	
	parent?: AmosMaintFunction,
	parent_function_id?: string,
	
	// code_parent?: AmosMaintFunction,
	
	children?: AmosMaintFunction[],
	child_function_ids?: string[],
	
	linked_assets?: CorAsset[],
	linked_asset_ids?: string[],
	
	depth?: number,
	
	display_name?: string,
	
	// TODO: ?
	system_id?: string,
	system_code?: string,
	system_name?: string,
}



// How AMOS represents maintenance functions - ie: the roles that machinery fulfills
// 
export interface AmosAsset
{
	_id: string,
	
	display_name: string,
	asset_type_name: string,
	
	primary_group_id?: string,
	primary_group_name?: string,
	primary_group_code?: string,
	
	// TODO: Remove - only here to support old data dump
	system_id?: string,
	system_name?: string,
	system_code?: string,
	
	
	
	
	// TODO: Review - added to match other Component interface definitions
	name?: string,
	
	asset_id?: string,
	asset_name?: string,
	asset_code?: string,
	asset_notes?: string,
	asset_serial_number?: string,
	
	location_id?: string,
	location_name?: string,
	
	maintenance_function_description?: string,
	maintenance_function_notes?: string,
	
	parent_function_id?: string,
	
	child_asset_ids?: string[],
	
}



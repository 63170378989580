import { useURLStepNum } from 'utils/hooks';
import { Stack, Box } from '@mui/material';

import { useIsMobile } from 'utils/hooks/useIsMobile';
import { arrayOf } from 'utils';

import StepperGizmoHeader from './StepperGizmoHeader';
import StepperGizmoFooter from './StepperGizmoFooter';


const StepperGizmo =
({
	headings = [],
	children,
}) =>
{
	const { step } = useURLStepNum();
	
	const is_mobile = useIsMobile();
	
	
	let num_to_generate = children.length - headings.length;
	
	
	// If there are fewer child components than headings, we'll generate the remaining headings
	if(num_to_generate > 0)
	{
		headings = headings.concat(
			arrayOf(
				num_to_generate,
				(i) => 'Step ' + (headings.length + i + 1))
		)
	}
	
	
	return (
		<Stack
			sx={{
				// maxWidth: 1400,
				flexGrow: 1,
				bgcolor: 'bg.stepper',
				boxShadow: '0 1px 3px #0005',
				borderRadius: '5px',
				overflow: 'hidden',
				justifyContent: 'center',
				maxWidth: 1200,
			}}
		>
			
			<StepperGizmoHeader
				current_heading={headings[step - 1]}
				headings={headings}
				completed={[false, false, false]}
			/>
			
			<Box
				sx={{
					minHeight: 255,
					// height: 'calc(100% - 100px)',
					height:    (is_mobile) ? 'calc(100vh - 250px + 42px)' : 'calc(100% - 100px)',
					maxHeight: (is_mobile) ? 'calc(100vh - 250px + 42px)' : 'calc(100vh - 200px)',
					overflow: 'auto',
					width: '100%',
					p: '4px',
					flexGrow: 1,
				}}
			>
				{children[step - 1]}
			</Box>
			
			<StepperGizmoFooter
				headings={headings}
			/>
			
			
		</Stack>
	)
}


export default StepperGizmo;
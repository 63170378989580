import { Grid, Stack, Typography } from '@mui/material';

import SortableTable from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/SortableTable';
import { UsersTable } from './UsersTable';



const UsersList = () =>
{
	return (
		<Stack
			spacing={2}
		>
			<Typography
				variant='h6'
			>
				Users List
			</Typography>
			
			<UsersTable />
		</Stack>
	)
};


export default UsersList;
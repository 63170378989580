import { Stack } from '@mui/material';
import { useIsMobile } from 'utils/hooks/useIsMobile';
import StepperGizmo from 'app/gizmos/stepper/StepperGizmo';
import CreateRequisitionDetailsMain from './requisitionDetails/CreateRequisitionDetailsMain';
import CreateRequisitionCostAndTeamMain from './costAndTeam/CreateRequisitionCostAndTeamMain';



// Split steps in stepper, using URL param step num to manage stepper progress
const CreateRequistionStepper = () =>
{
	// let form_values = useWatch();
	
	const is_mobile = useIsMobile();
	
	
	// // console.log(
	// // 	form_values,
	// // );
	
	
	return (
		<Stack
			padding={(is_mobile) ? 1 : 3}
			// gap={2}
			// justifyContent='center'
			// justifyItems='center'
			alignItems='stretch'
			height='100%'
		>
			
			<StepperGizmo
				headings={['Requisition Details', 'Cost & Team']}
			>
				<CreateRequisitionDetailsMain />
				
				<CreateRequisitionCostAndTeamMain />
			</StepperGizmo>
			
		</Stack>
	);
};


export default CreateRequistionStepper;
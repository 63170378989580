import React, { useState, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { CurrentComponent, ComponentTreeData } from 'models';
import { Issue } from 'types';
import ComponentTreeRootNode from './ComponentTreeRootNode';
import ComponentTreeChildNode from './ComponentTreeChildNode';
import { objectifyArray } from 'utils/obj_utils';
import { useCor } from 'app/providers/CorProvider';
import { Stack } from '@mui/material';


const ComponentTree =
({
	// components,
	issues,
	setComponent,
	setIsComponentDrawerOpen,
	selectedComponents,
	setSelectedComponents,
}: {
	// components: CurrentComponent[],
	issues: Issue[],
	setComponent?: Function,
	setIsComponentDrawerOpen?: Function,
	selectedComponents?: ComponentTreeData[],
	setSelectedComponents?: Function,
}) =>
{
	const { system_components, selected_component, selected_system } = useCor();
	
	
	
	const [DEFAULT_EXPANSION_LEVEL] = useState(2);
	
	const [ tree_root, setTreeRoot ] = useState<CurrentComponent>();
	
	
	
	
	useEffect(() => {
		setTreeRoot(selected_system)
	}, [selected_system])
	
	
	
	let all_ancestor_ids = [];
	
	
	const addAncestorsToArray = (target_component, array) =>
	{
		if(target_component?._id)
		{
			array.push(target_component._id);
			
			if(target_component.parent)
			{
				addAncestorsToArray(
					target_component.parent,
					all_ancestor_ids,
				);
			}
		}
	}
	
	
	addAncestorsToArray(
		selected_component,
		all_ancestor_ids,
	);
	
	
	console.log({
		selected_component,
		all_ancestor_ids,
	})
	
	
	
	
	
	
	// // ----------------------------------------------------------------------------------------------------------------------------------
	// // TODO: Hacky SHIT!!!!! - needs refactoring
	// /**
	// * Components are mapped to a parent -> child tree structure relationship.
	// * The TOP LEVEL COMPONENT is the parent component
	// **/
	// const mapComponentDataToTree = (components: CurrentComponent[]) =>
	// {
	// 	// For easy lookups so we can stitch together parent/child relationships
	// 	let items_by_id = objectifyArray(components);
		
		
	// 	console.log({
	// 		items_by_id,
	// 	})
		
		
	// 	let roots = [];
		
		
	// 	// Walk through array, looking up parent by ID in map, and pushing the item into its children
	// 	// components?.forEach(component =>
	// 	// {
	// 	// 	let parent_id = component?.parent_component_id;
			
	// 	// 	if(parent_id)
	// 	// 	{
	// 	// 		// let parent = items_by_id[parent_id] || [];
				
	// 	// 		// // console.log({
	// 	// 		// // 	component,
	// 	// 		// // 	parent_id,
	// 	// 		// // 	parent,
	// 	// 		// // })
				
	// 	// 		// parent.children = parent?.children || [];
				
	// 	// 		// parent.children.push(component);
				
	// 	// 		// component.parent = parent;
	// 	// 	}
	// 	// 	else
	// 	// 	{
	// 	// 		roots.push(component);
	// 	// 	}
	// 	// })
		
		
	// 	console.log({
	// 		items_by_id,
	// 		roots,
	// 	});
		
		
	// 	if(roots.length > 0)
	// 	{
	// 		// TODO: Could there be more than one orphan?
	// 		setTreeRoot(roots?.[0]);
	// 	}
		
		
	// 	// const components_data_tree = [];
	// 	// const components_hash_table = Object.create(null);
		
	// 	// // Initialize the child_components empty array for each component
	// 	// components?.forEach(component =>
	// 	// 	components_hash_table[component._id] =
	// 	// 	{
	// 	// 		...component,
	// 	// 		child_components: []
	// 	// 	}
	// 	// );
		
	// 	// components?.forEach(component =>
	// 	// {
	// 	// 	if(component.parent_component_id !== null)
	// 	// 	{
	// 	// 		components_hash_table[component.parent_component_id]
	// 	// 			?.child_components
	// 	// 			?.push(components_hash_table[component._id]);
	// 	// 	}
	// 	// 	else
	// 	// 	{
	// 	// 		components_data_tree.push(components_hash_table[component._id]);
	// 	// 	}
	// 	// });
		
	// 	// console.log(components_hash_table)
	// 	// console.log(components_data_tree)
		
	// 	// setComponentTreeData(components_data_tree[0]);
	// };
	
	
	// useEffect(() => {
	// 	mapComponentDataToTree(system_components);
	// }, [system_components]);
	
	
	
	
	
	
	
	// TODO: REFACTOR
	// const setComponentFromParams = () => {
		
	// 	const component_from_params = components.filter(component => component._id === component_id)
		
	// 	console.log('COMPONENT FROM PARAMS ===========================', component_id, {component_from_params});
		
	// 	if(component_from_params.length === 1) setSelectedComponent(component_from_params[0]);
	// }
	
	// useEffect(() => {
	// 	setComponentFromParams();
	// }, [components, component_id]);
	
	
	
	console.log({
		tree_root,
		system_components,
	});
	
	
	
	// -----------------------------------------------------------------------------------------------------------------------------------
	
	
	
	
	if(!tree_root)
	{
		return null;
	}
	
	
	
	
	let nodes = (tree_root) && tree_root?.children?.map((component, i) => (
		<ComponentTreeChildNode
			key={component._id}
			depth={1}
			DEFAULT_EXPANSION_LEVEL={DEFAULT_EXPANSION_LEVEL}
			location={[i]}
			component={component}
			is_last={i === component.children?.length - 1}
			has_siblings={component.children?.length > 1}
			issues={issues}
			setComponent={setComponent}
			setIsComponentDrawerOpen={setIsComponentDrawerOpen}
			selectedComponents={selectedComponents}
			setSelectedComponents={setSelectedComponents}
			all_ancestor_ids={all_ancestor_ids}
		/>
	));
	
	
	return (
		<Stack
			className='component_tree_container'
			direction='column'
			sx={{
				flexShrink: 1,
			}}
		>
			{
				(tree_root) ? (
					<>
						<ComponentTreeRootNode
							component_tree_data={tree_root}
							setComponent={setComponent}
							issues={issues}
							setIsComponentDrawerOpen={setIsComponentDrawerOpen}
							selectedComponents={selectedComponents}
							setSelectedComponents={setSelectedComponents}
						/>
						<ul
							className={''}
						>
							{nodes}
						</ul>
					</>
				) : (
					<CircularProgress />
				)
			}
		</Stack>
	)
};


export default ComponentTree;
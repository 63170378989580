import { AssetHistory } from '../interface/AssetHistoryInterface';

import { DateTime } from 'luxon';
import { arrayOf } from 'stuff';

import CalendarSpotlightItem from './CalendarSpotlightItem';

// import CreateEventButton from './CreateEventButton';


const CalendarSpotlight = ({ today, selected_day, events_by_date, asset_histories }) => 
{
	// compate and filter relevant_dates's date with asset_histories's HistoryOrderDate
	// then return the asset_histories array of matched date
	/* return AssetHistory[] */
	const compareWithHistory = ( date_: DateTime ) => 
	{
		var relevant_date: string = date_.toISO(); 
		
		const date: DateTime = DateTime.fromISO( relevant_date );
		
		relevant_date = date.toISODate();
		
		
		return asset_histories.filter(( asset ) =>
		{
			const history_ordered_date: DateTime = DateTime.fromISO( asset.HistoryOrderDate );
			const history_date: string = history_ordered_date.toISODate();
			
			return relevant_date === history_date; 
		})
	}

	let num_days_ahead = 7;
	
	
	let relevant_dates = arrayOf(
		num_days_ahead,
		(i) => selected_day.plus({days: i})
	);
	
	
	let elems = relevant_dates.map((date, key) =>
	{
		const matched_assets: AssetHistory[] = compareWithHistory( date );
		
		return (
			<span 
				key = { key }
			>
				<CalendarSpotlightItem
					today={today}
					day={date}
					events_by_date={events_by_date}
					assets_by_date = { matched_assets }
				/>
			</span>
		);
	});
	
	
	return <div className='calendar_spotlight'>
		
		{/* <div className='calendar_spotlight_buttons'>
			
			<CreateEventButton
				color='red'
				selected_day={selected_day}
			/>
			<CreateEventButton
				color='blue'
				selected_day={selected_day}
			/>
			<CreateEventButton
				color='magenta'
				selected_day={selected_day}
			/>
			
		</div> */}
		
		{elems}
		
	</div>
}


export default CalendarSpotlight;
import Badge from 'components/badges/Badge';


const cn : string = 'headline';


export interface HeadlineProps
{
	_username: string;
	_dateTime: string;
	title?: string;
	_status?: string;
};

const Headline =
({
	_username,
	_dateTime,
	title,
	_status,
}
: HeadlineProps) =>
{
	return (
		<div 
			className={ cn } 
		>
			{
				title && 
				(
					<div className={ cn + '--items'}
					>
						<Badge
							title={ title }
							status={ _status }
						/>
					</div>
				)
			}
			
			<div 
				className={ cn + '--username' }
			>
				{ _username }
			</div>
			
			<div
				className={ cn + '--dateTime' }
			>
				{ _dateTime }
			</div>
		</div>
	)
}


export default Headline;
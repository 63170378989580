import React, { useContext, useState, useEffect } from 'react';

import { useNavigate } from 'react-router';
import { useFirebaseApp, useSigninCheck } from 'reactfire';

import {
	updateEmail,
	updatePassword,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	getAuth,
} from 'firebase/auth';

import { clearFirestoreCache } from 'ember';
import { Claims } from 'models';



// We'll be providing this data structure to all children
interface AuthData
{
	is_loading: boolean,
	has_required_claims: boolean,
	
	uid?: any,
	user?: any,
	
	token?: any,
	claims?: Claims,
	
	register?: Function,
	logIn?: Function,
	logOut?: Function,
	
	updateUserEmail?: Function,
	updateUserPassword?: Function,
};



// Once established, this context will only be used within this file
const AuthDataContext = React.createContext<AuthData>({
	is_loading: false,
	has_required_claims: false,
});



// Our hook that children will use to access the data we provide
// (Don't confuse with reactfire's hook with the same name)
export function useAuthData()
{
	return useContext(AuthDataContext);
}



// App will wrap everything is this provider, allowing them to use our hook
export function AuthDataProvider({ children })
{
	const app  = useFirebaseApp();
	const auth = getAuth(app);
	
	const [ user, setUser ] = useState(null);
	const [ token, setToken ] = useState(null);
	const [ claims, setClaims ] = useState(null);
	
	const [ is_loading, setIsLoading ] = useState(false);
	
	const [ has_required_claims, setHasRequiredClaims ] = useState<boolean>(false);
	
	const navigate = useNavigate();
	
	
	
	// console.log('🔒', {
	// 	is_loading,
	// 	user,
	// 	token,
	// 	claims
	// })
	
	
	
	// Set up an observable to check auth status and set states when they're available
	// SignInCheckOptionsClaimsValidator
	// const { status, data: signInCheckResult } = useSigninCheck({
	const signin_check = useSigninCheck({
		// TODO: Verify
		forceRefresh: false,
		
		// Advanced validation logic...
		validateCustomClaims: (claims) =>
		{
			console.log('Validating', claims);
			// setIsChecking(true);
			
			return {
				hasRequiredClaims: !!claims?.organization_id,
				errors: {},
			};
		},
	});
	
	
	
	if(signin_check.data)
	{
		if(has_required_claims !== signin_check.data.hasRequiredClaims)
		{
			setHasRequiredClaims(signin_check.data.hasRequiredClaims)
		}
	}
	
	
	
	async function register(email: string, password: string)
	{
		return createUserWithEmailAndPassword(auth, email, password);
	}
	
	
	async function logIn(email: string, password: string)
	{
		console.log('🔒 Logging in');
		
		return signInWithEmailAndPassword(auth, email, password);
	}
	
	
	function logOut()
	{
		console.log('🔒 Logging out');
		
		navigate('/');
		
		setUser(undefined);
		setToken(null);
		setClaims(null);
		setIsLoading(false);
		
		clearFirestoreCache();
		
		return signOut(auth);
	}
	
	
	function updateUserEmail(email: string)
	{
		if(user) return updateEmail(user, email);
	}
	
	
	function updateUserPassword(password: string)
	{
		if(user) return updatePassword(user, password);
	}
	
	
	
	// Watch for changes to the current user and their token
	useEffect(
		() =>
		{
			// TODO: Unsubscribe from old observable if auth changes
			// Sets up an observable and returns an unsubscribe function for cleanup
			// Callback will be called on sign-in, sign-out, and token refresh events
			const unsubscribe = auth.onIdTokenChanged((user) =>
			{
				if(!user)
				{
					console.log('onIdTokenChanged, but user is undefined');
					
					clearFirestoreCache();
					
					setUser(undefined);
					setToken(null);
					setClaims(null);
					setHasRequiredClaims(false);
					
					return;
				}
				
				
				// console.log('onIdTokenChanged', user, auth);
				
				
				// Returns the current token if it has not expired or if it will not expire in the next five
				// minutes. Otherwise, this will refresh the token and return a new one.
				// TODO: Force refresh?
				user.getIdTokenResult()
					.then((token_result) => {
						// console.log('getIdTokenResult', token_result);
						
						setUser(user);
						setToken(token_result);
						setClaims(token_result.claims);
						setIsLoading(false);
					})
					.catch((error) => {
						console.log(error);
						
						setUser(undefined);
						setToken(null);
						setClaims(null);
						setIsLoading(false);
					});
			});
			
			
			return unsubscribe;
		},
		[auth]
	);
	
	
	
	const auth_data =
	{
		is_loading,
		has_required_claims,
		
		uid: user?.uid,
		user,
		
		token,
		claims,
		
		register,
		logIn,
		logOut,
		updateUserEmail,
		updateUserPassword,
	};
	
	
	return (
		<AuthDataContext.Provider value={auth_data}>
			{children}
		</AuthDataContext.Provider>
	);
}



export default AuthDataProvider;
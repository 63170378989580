import { Stack } from '@mui/material';

import { WOTDataProvider } from './WOTDataProvider';
import { useTitle } from 'utils';
import WOTStepper from './WOTStepper';


const CreateWorkOrderTemplatePage2 = () =>
{
	useTitle('Create Work Order Template');
	
	
	return (
		<WOTDataProvider>
			<Stack
				sx={{
					flexGrow: 1,
				}}
			>
				<WOTStepper />
			</Stack>
		</WOTDataProvider>
	)
};


export default CreateWorkOrderTemplatePage2;
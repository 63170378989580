import { Button, Divider, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { User } from 'types';
import ActionTypeSelect from './ActionType/ActionTypeSelect';
import ActionCollaborators from './Collaborators/ActionCollaborators';
import Inquiry from './Inquiry/Inquiry';
import { ActionType } from 'models';
import { AttachmentsFormlet } from 'pages/createIssue/createIssueStepper/components/AttachmentsFormlet';
import { useAction } from '../ActionDataProvider';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';


export interface CreateActionForm
{
	_id: string,
	inquiryMode?: 'list' | 'string',
	inquiry: string,
	todos: {
		label: string;
	}[],
	action_type: ActionType,
	due_date?: string,
	files: File[],
	collaborators: User[],
}


const CreateActionForm = () =>
{
	const { users, roles, handleSubmit } = useAction();
	const { control } = useFormContext();
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
			width='100%'
			spacing={1}
		>
			<Typography
				variant='subtitle1'
			>
				Create a new Action
			</Typography>
			
			<Stack
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'
				spacing={2}
			>
				<Inquiry />
				
				
				<SearchableSelectFormlet
					name='roles'
					label='Assign to roles'
					options={roles}
					getOptionLabel={option => option.name}
					multiple
				/>
				
				<ActionCollaborators users={users} />
				
				<Divider sx={{ width: '80%' }} />
				
				<ActionTypeSelect />
				
				<Divider sx={{ width: '80%' }} />
				
				<AttachmentsFormlet control={control}/>
			</Stack>
			
			<Stack
				direction='row'
				width='100%'
				justifyContent='center'
				alignItems='center'
			>
					<Button
						variant='contained'
						onClick={() => handleSubmit()}
					>
						Create
					</Button>
			</Stack>
		</Stack>
	)
}


export default CreateActionForm;
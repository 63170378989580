import { Action, Issue, Requisition, WorkOrderNew } from 'models';

import CalendarProvider from './CalendarProvider';
import CalendarWeekStack from './CalendarWeekStack';

import CalendarDate from './CalendarDate';
import CalendarTime from './CalendarTime';

// import CalendarEventForm from './CalendarEventForm';

import Grid from '@mui/material/Grid';
import CalendarEvents from './calendarEvents/CalendarEvents';


const Calendar =
({
	issues,
	actions,
	work_orders,
	requisitions,
} : {
	issues: Issue[],
	actions: Action[],
	work_orders: WorkOrderNew[],
	requisitions: Requisition[],
}) =>
{
	return <CalendarProvider
		issues={issues}
		actions={actions}
		work_orders={work_orders}
		requisitions={requisitions}
	>
		<Grid
			container
			width='100%'
			justifyContent='center'
		>
			
			<Grid
				item
				width='100%'
			>
				<CalendarWeekStack />
			</Grid>
			
			<Grid
				item
			>
				<CalendarEvents />
			</Grid>
		</Grid>
		
		<div
			className='calendar3_pickers'
		>
			<CalendarDate />
			<CalendarTime />
		</div>
		
	</CalendarProvider>
}


export default Calendar;
import Typography from '@mui/material/Typography';

import { Asset } from 'models';
import CorIcon from 'components/icons/CorIcon';
import AvatarOrError from 'components/images/AvatarOrError';
import { CircularProgress, Divider } from '@mui/material';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useNavigate } from 'react-router';
import { useAuthData } from 'stuff';
import { useCollection } from 'ember';
import { where } from 'firebase/firestore';
import { Issue, WorkOrderNew } from 'models';



const AssetCard =
({
	asset,
	setSelectedAsset,
}: {
	asset: Asset,
	setSelectedAsset?: Function,
}) =>
{
	const navigate = useNavigate();
	
	const { claims } = useAuthData();
	
	
	const issues_collection = useCollection(
		`organizations/${claims?.organization_id}/issues`,
		where('asset_id', '==', asset._id)
	);
	
	const work_order_collection = useCollection(
		`organizations/${claims?.organization_id}/work_orders`,
		where('asset_id', '==', asset._id)
	);
	
	
	if(issues_collection.is_loading || work_order_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	const issues = issues_collection.data as Issue[] || [];
	const work_orders = work_order_collection.data as WorkOrderNew[] || [];
	
	
	const navigateToSystems = () =>
	{
		if(window.location.pathname === '/assets')
		{
			navigate(`${asset._id}/systems`)
		}
		else
		{
			setSelectedAsset(asset);
		}
	};
	
	
	return (
		<div
			className='asset_card_container'
			onClick={() => navigateToSystems()}
		>
			
			<div className='asset_card_top_section'>
				<div className='asset_card_top_section_main'>
					<AvatarOrError
						src={asset?.image_path || '/organizations/corsphere/files/test_vessel_2.png'}
						alt={asset?.name}
						cuid={asset?._id}
					/>
					<div className='asset_card_heading'>
						<Typography variant='subtitle1'>
							{asset?.name}
						</Typography>
						<Typography
							variant='caption' 
							sx={{ color: defaultAppTheme.palette.primary.main }}
						>
							{asset?.status}
						</Typography>
					</div>
				</div>
				<CorIcon of='arrow_right' size='20px'/>
			</div>
			
			
			
			<div className='asset_card_bottom_section'>
				<Typography variant='caption'>
					Open Work Orders: {work_orders.length}
				</Typography>
				
				<Divider sx={{ width: '20px', color: defaultAppTheme.palette.grey['900']}}/>
				
				<Typography variant='caption' sx={{ color: defaultAppTheme.palette.secondary.main}}>
					Open Issues: {issues.length}
				</Typography>
			</div>
			
			
			
		</div>
	)
};


export default AssetCard;
import { Box, styled } from '@mui/material';


export const SectionContainer = styled(Box)(() => ({
	backgroundColor: '#fbfbfb',
	width: `min(98%, 600px)`,
	marginInline: 'auto',
	border: `1px solid #d8d8df`,
	borderRadius: '5px',
	padding: '1rem',
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '2rem'
}));


export const SectionHeader = styled(Box)(() => ({
	fontSize: '18px',
	fontWeight: 500,
	paddingBottom: '2rem'
}));


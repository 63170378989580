import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



export default function ProgressBar(
	props: LinearProgressProps & {
		value: number,
		due_count: number
		total_count: number,
	}
)
{
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', width:'100%' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress
					color={
						(props.value >= 80)
						? 'secondary'
						: (props.value > 50 && props.value < 80)
						? 'warning'
						: (props.value > 20 && props.value <= 50)
						? 'primary'
						: 'success'
					}
					variant="determinate" {...props}
				/>
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">
					{
						`${props.due_count}/${props.total_count} requires immediate attention`
					}
				</Typography>
			</Box>
		</Box>
	);
}
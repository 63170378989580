import { Stack } from '@mui/material';
import { CorComponent } from 'models';


export const ComponentTreeIssueIndicator =
({
	component
} : {
	component: CorComponent,
}) =>
{
	if(component.issues.length < 1)
	{
		return null;
	}
	
	
	return (
		<Stack
			sx={{
				bgcolor: '#f004',
				marginLeft: 3,
				padding: '1px 4px',
				borderRadius: '50%',
				fontSize: '13px',
				fontweight: 600,
			}}
			title={
				component.issues.length + ' current issue' +
				((component.issues.length === 1) ? '' : 's')
			}
		>
			{component.issues.length}
		</Stack>
	)
}


export default ComponentTreeIssueIndicator;
import { useState, useEffect, useMemo, useRef } from 'react';


// Provide custom handler (optional), to be called with true/false
// Ex:
// let [is_hovered, handlers] = useHover();
// 
// return <rect
//   fill={(is_hovered) ? '#222' : '#000'}
//   {...handlers}
// />
export const useHover = (fn = null) =>
{
	const [hovered, setHovered] = useState();
	
	const event_handlers = useMemo(() => ({
		onMouseOver() {
			setHovered(true);
			if(fn) fn(true);
		},
		onMouseOut() {
			setHovered(false);
			if(fn) fn(false);
		}
	}), [fn]);
	
	return [hovered, event_handlers];
}





// Provide a string to set the document title
export function useTitle(title)
{
	useEffect(() =>
	{
		if(!title) return;
		
		
		// We'll remember the previous title so we can revert later
		const prev_title = document.title
		
		// Sets the document title
		document.title = title
		
		// Cleanup function to revert the title to whatever it was before
		return () => {
			document.title = prev_title
		}
	})
}



// Call this to watch the document title
export function useWatchTitle()
{
	const [ current_title, setCurrentTitle ] = useState(document.title);
	
	
	// Select the target node
	var target = document.querySelector('title');
	
	// Create an observer instance
	var observer = new MutationObserver(() =>
	{
		setCurrentTitle(target.textContent);
	});
	
	// Configuration of the observer
	var config =
	{
		subtree: true,
		characterData: true,
		childList: true,
	};
	
	// Pass in the target node, as well as the observer options
	observer.observe(target, config);
	
	
	return current_title;
}



// Hook that returns true or false, indicating whether the specified key
// is currently pressed during a render
// Ex: 'h', 'Escape', 'Enter'
export function useKeyPress(target_key)
{
	// State for keeping track of whether key is pressed
	const [is_key_pressed, setKeyPressed] = useState(false);
	
	
	// If pressed key is our target key then set to true
	const downHandler = ({ key }) =>
	{
		if (key === target_key)
		{
			setKeyPressed(true);
		}
	}
	
	// If released key is our target key then set to false
	const upHandler = ({ key }) =>
	{
		if (key === target_key)
		{
			setKeyPressed(false);
		}
	};
	
	
	// Add event listeners
	useEffect(() => {
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);
		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	},
	// Empty array ensures that effect is only run on mount and unmount
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[]);
	
	
	return is_key_pressed;
}





// TODO: Hook for detecting key combos:
// https://codesandbox.io/s/y3qzyr3lrz








// Provide a function and how often you want the function called in ms
// 
// Note: The arguments are dynamic, so this hook will recognize when they change
//       You can temporarily disable the function from being called by changing
//       the provided delay to a falsy value
// 
// Ex:
//     useInterval(() =>
//         setProgress(Math.min(100, progress + .4))
//         ,
//         (status === 'printing') && 25
//     );
// 
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/#just-show-me-the-code
export function useInterval(callback, delay)
{
	const saved_callback = useRef();
	
	// Whenever the callback function changes, we'll update the reference in our
	// saved_callback container
	useEffect(() =>
		{
			saved_callback.current = callback;
		}
		,
		[callback]
	);
	
	// Set up the interval, dynamically updating if the delay changes
	useEffect(() =>
		{
			if(delay)
			{
				// Every tick, we'll call the function referenced in our container
				const tick = () => saved_callback.current();
				
				// Remember the interval ID so we can cancel it when we're done
				let id = setInterval(tick, delay);
				
				// Return a cleanup function that cancels our interval
				return () => clearInterval(id);
			}
		}
		,
		[delay]
	);
}









import { useNavigate } from 'react-router';

import { Box, Typography, Stack } from '@mui/material';

import { StorageFile } from 'models';
import SortableTable from './issueTable/SortableTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import ClickForDownloadDialog from 'app/widgets/dialogs/ClickForDownloadDialog';



const FilesTable =
({
	files,
} : {
	files?: StorageFile[],
}) =>
{
	const navigate = useNavigate();
	
	
	return (
		<Box
			className='files_table_container'
		>
			
			<Typography
				variant='h6'
			>
				Attachments & Files
			</Typography>
			
			<SortableTable
				options={files}
				onSelect={(selectedOption) => console.log('Download')}
				rows={[
					{
						title: 'Download File',
						getCell(file) {
							return (
								<ClickForDownloadDialog
									file={file}
								>
									<Stack
										direction='row'
										justifyContent='center'
										alignItems='center'
										width='100%'
									>
										<CorIcon
											of='file_download'
											size='24px'
											color={defaultAppTheme.palette.primary.main}
										/>
									</Stack>
								</ClickForDownloadDialog>
							)
						}
					},
					{
						title: 'File Name',
						getCell(file) {
							return (
								<Stack
									direction='column'
									alignItems='flex-start'
									justifyContent='center'
									sx={{ marginInline: '0.5rem' }}
								>
									{
										(file?.file_category) && (
											<Typography
												sx={{
													background: defaultAppTheme.palette.grey['500'],
													padding: '4px',
													borderRadius: '4px',
													color: '#fff',
												}}
											>
												{file?.file_category}
											</Typography>
											
										)
									}
									<Typography
										variant='body1'
									>
										{file.name}
									</Typography>
								</Stack>
							);
						},
					},
					{
						title: 'Upload Date',
						getCell(file) {
							return (
								<Typography variant='subtitle2'>
									{ fullDateAndTimeFromISO(file?.upload_date) }
								</Typography>
							)
						},
					},
				]}
			/>
			
		</Box>
	)
};


export default FilesTable;
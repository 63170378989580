// import { Chip, styled } from '@mui/material';
import { useSession } from 'app/providers/SessionProvider';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { Tag } from 'types';
import SelectTagsFormlet from 'components/selectTags/SelectTagsFormlet';
import { WorkOrderDescriptionFormData } from 'pages/CreateWorkOrder/work_order_types';


// function isTag(option: Tag | string): option is Tag
// {
// 	return (option as Tag)._id !== undefined;
// }


interface TagsProps
{
	control: Control<any, any>,
}


export const Tags = ({ control }: TagsProps) =>
{
	const { all_available_tags } = useSession();
	const { replace } = useFieldArray({
		control,
		name: 'tags',
	});
	const tags = useWatch({
		control,
		name: 'tags',
	});
	
	
	const onTagSelect = (event: React.ChangeEvent<{}>, newValues:null | Tag[]) =>
	{
		console.log(newValues);
		
		
		replace(newValues);
	}
	
	return (
		// <Box>
		// 	<Box
		// 		sx={{
		// 			marginBottom: '0.5rem',
		// 		}}
		// 	>{`Add Tags`}</Box>
		// 	<Autocomplete
		// 		multiple={true}
		// 		options={all_available_tags}
		// 		renderInput={(params) => (
		// 			<TextField {...params} placeholder='Type to add tag' />
		// 		)}
		// 		getOptionLabel={(option) =>
		// 			isTag(option) ? option.name ?? '' : option
		// 		}
		// 		loading={true}
		// 		loadingText='Loading available tags'
		// 		value={tags}
		// 		onChange={(_event, newValues) => {
		// 			replace(
		// 				newValues.map((value) => {
		// 					if (isTag(value)) return value;
		// 				})
		// 			);
		// 		}}
		// 		filterOptions={(options) =>
		// 			options.filter((option) => {
		// 				return !tags.find((_tag) => _tag._id === option._id);
		// 			})
		// 		}
		// 		renderTags={(value, getTagProps) =>
		// 			value.map((option, index) => (
		// 				<StyledChip
		// 					variant='filled'
		// 					label={option.name}
		// 					size='small'
		// 					backgroundColor={option.color ?? 'lightgray'}
		// 					{...getTagProps({ index })}
		// 				/>
		// 			))
		// 		}
		// 	/>
		// </Box>
		<SelectTagsFormlet
			label={'Select Tags'}
			id='create-work-order-tags'
			multiple={true}
			value={tags}
			onChange={onTagSelect}
			tags={all_available_tags}
		/>
	);
};


// const StyledChip = styled(Chip)<{ backgroundColor?: string }>(
// 	({ backgroundColor }) => ({
// 		backgroundColor,
// 	})
// );



import React from 'react';
import MaybeDialog from 'app/widgets/dialogs/MaybeDialog';
import QRCodeWidget from './QRCodeWidget';
import { CircularProgress } from '@mui/material';
import { useDoc } from 'ember';
import { CurrentComponent } from 'models';
import { toTitleCase, useAuthData } from 'stuff';


interface MaybeQrDialogI
{
	data?: Object,
	
	handleSubmit?: Function,
}



const MaybeQrLabelDialog =
({
	data,
	
	handleSubmit,
	
	...rest
}
: MaybeQrDialogI) =>
{
	const { claims } = useAuthData();
	
	const location = window.location;
	
	
	let path_parts = window.location.pathname.split('/').filter(Boolean);
	
	
	let components_path_part_index = path_parts.findIndex(x => x === 'components');
	let component_id = path_parts[components_path_part_index + 1];
	
	
	
	// Get component data from params
	const component_doc = useDoc(`organizations/${claims?.organization_id}/components/${component_id}`);
	
	
	if(component_doc?.is_loading)
	{
		return null;
	}
	
	
	
	const component = component_doc?.data as CurrentComponent;
	
	
	
	let address = 'https://app.corsphere.com' + window.location.pathname;
	
	let line1 = component?.name;
	let line2 = component?.system_name;
	let line3 = component?.asset_name;
	
	
	if(!component)
	{
		address = 'https://app.corsphere.com' + window.location.pathname;
		
		let parts = window.location.pathname.split('/').filter(Boolean);
		
		
		line1 = toTitleCase(parts[0]?.replace('-', ' '));
		line2 = parts[1];
		line3 = parts[2];
		
		
		let parts2 = document.title.split(' - ');
		
		line2 = parts2[0];
		line3 = parts2[1];
		
		
		if(!line2 || line2 === 'CORSphere')
		{
			line2 = '\xa0';
		}
		
		if(!line3)
		{
			line3 = window.location.pathname;
		}
		
		if(line1 === 'Home' || !line1)
		{
			line1 = 'CORSphere';
			line2 = '\xa0';
			line3 = 'app.corsphere.com';
			address = 'https://app.corsphere.com';
		}
	}
	
	
	
	return (
		<MaybeDialog
			open_key='show_qr_label'
			
			dialog_title='QR Code Label'
			dialog_submit_text='Done'
			
			handleSubmit={() => console.log('Done')}
			
			sx={{
				'& .MuiPaper-root': {
					// TODO: Not ideal
					width: '600px',
				},
			}}
			
			{...rest}
		>
			<QRCodeWidget
				line1={line1}
				line2={line2}
				line3={line3}
				payload={address}
			/>
			
		</MaybeDialog>
	)
}


export default MaybeQrLabelDialog;
import { useAuthData } from 'stuff';
import { useCollection } from 'ember';
import { CircularProgress } from '@mui/material';

// import { FakeTagData } from '../../manage_tags/components/FakeTagData';

import { Tag } from 'types';

import MobileHeader from '../MobileHeader';
import TagList from '../../manage_tags/components/TagsList';
import { useSession } from 'app/providers/SessionProvider';
import { Box } from '@mui/material';



const ManageTagsPage = () =>
{
	const { all_available_tags } = useSession();
	
	
	return (
		<Box
			sx={{ margin: '10px' }}
		>
			<MobileHeader
				title='Manage Tags'
				variantType='h3'
			/>
			
			<Box className='container'>
				<TagList
					tags={all_available_tags}
				/>
			</Box>
		</Box>
	)
}


export default ManageTagsPage;
import { Stack } from '@mui/material'
import CostCenterForm from './CostCenterForm';
import CostCenterList from './CostCenterList';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useState } from 'react';
import ManageCostCodes from './costCodes/ManageCostCodes';



const ManageCostsPage = () =>
{
	const [ closeDialog, setCloseDialog ] = useState(false);
	
	return (
		<Stack
			className='container'
			sx={{
				width: '100%',
				padding: '10px',
				margin: '10px',
			}}
			spacing={3}
		>
			<ButtonToOpenDialog
				button_variant='outlined'
				button_content='Add a Cost Center'
				dialog_title='Create a New Cost Center'
				dialog_content={
					<CostCenterForm
						closeDialog={closeDialog}
						setCloseDialog={setCloseDialog}
					/>
				}
				closeDialog={closeDialog}
				dialogClassName='add_tags_dialog'
			/>
			
			<CostCenterList />
			
			<ManageCostCodes />
		</Stack>
	)
};


export default ManageCostsPage;
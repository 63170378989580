import { FieldOption } from 'models';
import ToggleButtonGroupNotEmpty from './ToggleButtonGroupNotEmpty';


// Group of toggle buttons allowing the user to select from a set of enum values
export const EnumToggleButtonGroup =
({
	enum_type,
	onChange,
	value,
} : {
	enum_type: any,
	onChange?: Function,
	value?: string,
}) =>
{
	if(!enum_type)
	{
		return null;
	}
	
	
	let options : FieldOption[] =
		Object.entries(enum_type as [string, string])
			.map(([_id, label]) => ({
				_id,
				name: _id,
				label,
				checked: (value === label),
			}))
	
	
	return (
		<ToggleButtonGroupNotEmpty
			options={options}
			onChange={onChange}
			value={value}
		/>
	);
}


export default EnumToggleButtonGroup;
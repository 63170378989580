import { useCor } from 'app/providers/CorProvider';
import { Asset, CostCenter, Issue, WorkOrderNew } from 'models';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form';


export interface SearchAndSortData
{
	assets?: Asset[],
	
	filtered_issues?: Issue[],
	filtered_work_orders?: WorkOrderNew[],
};


export interface SearchAndSortFormData
{
	name?: string,
	title?: string,
	
	assets?: Asset[],
	cost_centers?: CostCenter[],
	
	statuses?: string[],
}


const DEFAULT_FORM_DATA: SearchAndSortFormData =
{
	name: '',
	title: '',
	
	assets: [],
	
	statuses: [],
}






const SearchAndSortDataContext = createContext<SearchAndSortData>(null)


export const useSearchAndSort = () =>
{
	return useContext(SearchAndSortDataContext)
};




const SearchAndSortStateProvider = ({
	issues,
	work_orders,
	children,
} : {
	issues?: Issue[],
	work_orders?: WorkOrderNew[],
	
	children: ReactNode,
}) =>
{
	const { assets } = useCor();
	
	// Issues State
	const [ filtered_issues, setFilteredIssues ] = useState<Issue[]>(issues);
	const [ issues_by_assets, setIssuesByAssets ] = useState<Issue[]>(issues);
	
	// Work Orders State
	const [ filtered_work_orders, setFilteredWorkOrders ] = useState<WorkOrderNew[]>(work_orders);
	const [ work_orders_by_assets, setWorkOrdersByAssets ] = useState<WorkOrderNew[]>(work_orders);
	
	
	
	
	const form = useForm<SearchAndSortFormData>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
		}
	});
	
	const { control } = form;
	
	// Filter by assets
	const selected_assets = useWatch({
		control,
		name: 'assets'
	})
	
	const filterByAssets = () =>
	{
		if(issues)
		{
			const selected_asset_ids = selected_assets.map(x => x._id);
			const filtered_data = issues?.filter(x => selected_asset_ids.includes(x.asset_id))
			
			if(selected_assets.length !> 0 )
			{
				setFilteredIssues(filtered_data);
				setIssuesByAssets(filtered_data);
			}
			else
			{
				setFilteredIssues(issues);
				setIssuesByAssets(issues);
			}
		}
		
		
		if(work_orders)
		{
			const selected_asset_ids = selected_assets.map(x => x._id);
			const filtered_data = work_orders?.filter(x => selected_asset_ids.includes(x.asset_id))
			
			if(selected_assets.length !> 0 )
			{
				setFilteredWorkOrders(filtered_data);
				setWorkOrdersByAssets(filtered_data);
			}
			else
			{
				setFilteredWorkOrders(work_orders);
				setWorkOrdersByAssets(work_orders);
			}
		}
	}
	
	useEffect(() =>
	{
		filterByAssets()
		console.log({selected_assets})
	},
	[selected_assets]);
	
	
	
	
	// Filter by status
	const statuses = useWatch({
		control,
		name: 'statuses'
	})
	
	
	const filterByStatuses = () =>
	{
		if(issues)
		{
			const filtered_data = issues_by_assets?.filter(x => statuses.includes(x.status))
			
			if(statuses.length > 0)
			{
				setFilteredIssues(filtered_data)
			}
			else
			{
				setFilteredIssues(issues_by_assets)
			}
		}
		
		if(work_orders)
		{
			const filtered_data = work_orders_by_assets?.filter(x => statuses.includes(x.status))
			
			if(statuses.length > 0)
			{
				setFilteredWorkOrders(filtered_data)
			}
			else
			{
				setFilteredWorkOrders(work_orders_by_assets)
			}
		}
	}
	
	useEffect(() =>
	{
		filterByStatuses()
		console.log(statuses)
	},
	[statuses])
	
	
	// Search by name or title
	const name = useWatch({
		control,
		name: 'name'
	})
	
	const title = useWatch({
		control,
		name: 'title'
	})
	
	
	const filterByTitle = () =>
	{
		if(issues)
		{
			const filtered_data = issues_by_assets.filter(x => x.name.toLowerCase().includes(name.toLowerCase()));
			
			if(name !== '')
			{
				setFilteredIssues(filtered_data)
			}
			else
			{
				setFilteredIssues(issues)
			}
		}
		
		if(work_orders)
		{
			const filtered_data = work_orders_by_assets.filter(x => x.title.toLowerCase().includes(title.toLowerCase()));
			
			if(title !== '')
			{
				setFilteredWorkOrders(filtered_data)
			}
			else
			{
				setFilteredWorkOrders(work_orders)
			}
		}
	}
	
	
	useEffect(() =>
	{
		filterByTitle()
		console.log(name)
	},
	[name, title])
	
	
	
	
	
	const state_data =
	{
		assets: assets || [],
		
		filtered_issues: filtered_issues || [],
		filtered_work_orders: filtered_work_orders || [],
	}
	
	
	return (
		<FormProvider {...form}>
			<SearchAndSortDataContext.Provider
				value={state_data}
			>
				{children}
			</SearchAndSortDataContext.Provider>
		</FormProvider>
	)
};


export default SearchAndSortStateProvider;
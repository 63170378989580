import { List, ListItem, ListItemButton, ListItemAvatar, ListItemText, Box } from '@mui/material';

import { useStyles } from '../theme';

import UserAvatar from 'components/avatars/UserAvatar';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import CorIcon from 'components/icons/CorIcon';

import { CollaboratorListProps } from '../interfaces';
import { User } from 'types';



const SelectableCollaboratorCard =
({
	user,
	handleChange,
	selectedCollaborators
} : {
	user: User,
	handleChange: (value: User) => void,
	selectedCollaborators?: User[],
}) =>
{
	const classes = useStyles();
	
	
	console.log(selectedCollaborators);
	
	
	const collaborator_ids = selectedCollaborators?.map(x => x._id);
	
	
	return (
		<ListItem
			className={classes.CollaboratorsListItem}
			onClick={() => handleChange(user)}
		>
			<ListItemButton
				sx={{
					margin: 0,
				}}
			>
				
				<ListItemAvatar>
					<UserAvatar user={user} />
				</ListItemAvatar>
				
				<ListItemText 
					id={user._id} 
					primary={`${user.display_name}`} 
					secondary={`${user.email}`} 
					className={classes.CollaboratorsListItemText}
				/>
				
				{
					(collaborator_ids?.includes(user._id)) ? <CorIcon of={'check'} size='18px' color={'#8AC926'} />
					: <CorIcon of={'plus_outlined'} size='18px' color={'#666666'} />
				}
				
			</ListItemButton>
		</ListItem>
	)
}


export default SelectableCollaboratorCard;
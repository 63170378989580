import { makeStyles, createStyles } from "@mui/styles"

export const useStyles = makeStyles((theme?: any) => createStyles({
	SearchBar: {
		borderRadius: '3px',
		border: '1px solid #efefef',
		padding: '5px',
		marginTop: '20px',
		marginBottom: '20px',
		backgroundColor: '#fff',
		width: '100%'
	}
}))
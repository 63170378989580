import { Box, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';


interface ReadingProps extends ActionProps {}


export const FillableReplace = (props: ReadingProps) =>
{
	const { sections, sectionIndex, fieldIndex } = props;
	
	const equipment_ids = sections[sectionIndex].fields[fieldIndex].replace.equipment.map(x => x._id);
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={1}
				className='form_field_description'
			>
				<Typography
					variant='subtitle1'
					sx={{
						lineHeight: 0
					}}
				>
					{sectionIndex+1}.{fieldIndex+1}
				</Typography>
				
				<Typography
					variant='subtitle2'
					className='form_field_description'
				>
					{sections[sectionIndex].fields[fieldIndex].replace.description}
				</Typography>
			</Stack>
			
			<RelatedAssetAndComponents component_ids={equipment_ids} />
			
			<InfoHighlight
				severity='info'
				message_main={'Please select from the following option if the equipment was replaced'}
			/>
			
			<Options {...props} />
		</Box>
	);
};


interface OptionsProps extends ActionProps {}


const values = ['yes', 'no', 'n/a'] as const;

const labels: Record<typeof values[number], string> =
{
	yes: 'Yes',
	no: 'No',
	'n/a': 'N/A',
};


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	return (
		<Box>
			<Controller
				control={control}
				name={`sections.${sectionIndex}.fields.${fieldIndex}.yes_no.value`}
				render={({ field: { value, onChange } }) => {
					return (
						<RadioGroup value={value} onChange={onChange}>
							{values.map((value) => {
								return (
									<FormControlLabel
										key={value}
										value={value}
										control={<Radio />}
										label={labels[value]}
									/>
								);
							})}
						</RadioGroup>
					);
				}}
			/>
		</Box>
	);
};



export default FillableReplace;
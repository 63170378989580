import {
	Box,
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	Typography,
	Stack,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';


export interface YesNoProps extends ActionProps {}


export const FillableYesNo = (props: YesNoProps) =>
{
	return (
		<>
			<Description {...props} />
			<Options {...props} />
		</>
	);
};


interface DescriptionProps extends ActionProps {}


const Description = ({ control, sectionIndex, fieldIndex, sections }: DescriptionProps) =>
{
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='flex-start'
			spacing={1}
			className='form_field_description'
		>
			<Typography
				variant='subtitle1'
				sx={{
					lineHeight: 0
				}}
			>
				{sectionIndex+1}.{fieldIndex+1}
			</Typography>
			
			<Typography
				variant='subtitle2'
				className='form_field_description'
			>
				{sections[sectionIndex].fields[fieldIndex].yes_no.description}
			</Typography>
		</Stack>
	);
};


interface OptionsProps extends ActionProps {}


const values = ['yes', 'no', 'n/a'] as const;

const labels: Record<typeof values[number], string> =
{
	yes: 'Yes',
	no: 'No',
	'n/a': 'N/A',
};


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	return (
		<Box>
			<Controller
				control={control}
				name={`sections.${sectionIndex}.fields.${fieldIndex}.yes_no.value`}
				render={({ field: { value, onChange } }) => {
					return (
						<RadioGroup value={value} onChange={onChange}>
							{values.map((value) => {
								return (
									<FormControlLabel
										key={value}
										value={value}
										control={<Radio />}
										label={labels[value]}
									/>
								);
							})}
						</RadioGroup>
					);
				}}
			/>
		</Box>
	);
};


export default FillableYesNo;
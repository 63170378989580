import React, { useEffect, useState } from 'react';

import { Issue } from 'models';
import IssueCard from '../issueCollaboration/issueDetails/IssueCard';
import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';


const SearchableIssuesCardList =
({
	issues,
} : {
	issues: Issue[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};

	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			{/* <SearchBar
				items={issues}
				handleItems={setFilteredIssues}
				fields={['name']}
			/> */}
			{
				issues?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((issue: Issue) => (
					<IssueCard key={issue._id} issue={issue}/>
				))
			}
			<Pagination
				count={Math.ceil(issues.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default SearchableIssuesCardList;
import { Stack } from '@mui/material';
import DaysOfTheWeekField from 'app/gizmos/fields/datetimes/DaysOfTheWeekField';
import Formlet from 'app/gizmos/fields/Formlet';


export const WeeklyScheduleFormlet = () =>
{
	return (
		<Formlet
			label='Repeats Weekly'
			help='A work order will be created on these days each week'
		>
			<Stack
				sx={{
					alignItems: 'center',
				}}
			>
				<DaysOfTheWeekField
					name='recurrence.weekly.weekday_numbers'
				/>
			</Stack>
		</Formlet>
	)
}


export default WeeklyScheduleFormlet;
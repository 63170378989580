import { FormProvider, useFieldArray } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';

import { HeaderContainer, HeaderTitle } from './styles';

import { useWorkOrderTemplate } from './useWorkOrderTemplate';
import { WorkOrderSection } from './Section/WorkOrderSection';


// TODO: Is this going to be used?
const WorkOrderTemplatePage = () =>
{
	const { form, workOrderTemplate, handleSubmit, isSubmitting } =
		useWorkOrderTemplate();
	
	const { fields } = useFieldArray({
		control: form.control,
		name: `form_sections`,
	});
	
	
	if (!workOrderTemplate)
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '5rem',
				}}
			>
				<CircularProgress />
			</Box>
		);
	
	
	return (
		<FormProvider {...form}>
			
			<Box sx={{ paddingBottom: '4rem' }}>
				<HeaderContainer>
					<HeaderTitle>
						{form.getValues().title}
					</HeaderTitle>
					<LoadingButton
						onClick={handleSubmit}
						loading={isSubmitting}
					>
						Save
					</LoadingButton>
				</HeaderContainer>
			</Box>
			
			{fields.map((field, index) => (
				<WorkOrderSection key={field.id} index={index} />
			))}
			
		</FormProvider>
	);
};


export default WorkOrderTemplatePage;
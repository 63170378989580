import * as React from 'react';
// import { useNavigate } from 'react-router-dom';
import { useCollection } from 'ember';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { CircularProgress, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

// import Checkbox from '@mui/material/Checkbox';

import PartsTableHead from './PartsTableHead';
import PartsTableToolbar from './PartsTableToolbar';
import { Data } from './partsTableInterface';
import { Part } from 'types';
import { useAuthData } from 'stuff';







function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}



type Order = 'asc' | 'desc';



function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key,
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string },
) => number 
{
	return order === 'desc'
	? (a, b) => descendingComparator(a, b, orderBy)
	: (a, b) => -descendingComparator(a, b, orderBy);
}



// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number)
{
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	
	stabilizedThis.sort((a, b) => {
		
	const order = comparator(a[0], b[0]);
	
		if (order !== 0)
		{
			return order;
		}
		
		return a[1] - b[1];
	});
	
	return stabilizedThis.map((el) => el[0]);
};





const PartsTable = () =>
{
	const [ order, setOrder ] = React.useState<Order>('asc');
	
	const [ order_by, setOrderBy ] = React.useState<keyof Data>('asset');
	
	const [ selected, setSelected ] = React.useState<readonly string[]>([]);
	
	const [ page, setPage ] = React.useState(0);
	
	const [ rows_per_page, setRowsPerPage ] = React.useState(5);
	
	
	// const navigate = useNavigate();
	const { claims } = useAuthData();
	
	
	const parts_collection_path = `organizations/${claims?.organization_id}/parts`;
	const parts_collection = useCollection(parts_collection_path);
	
	if( parts_collection.is_loading)
	{
		return <CircularProgress />
	}
	
	const parts = parts_collection.data as Part[];
	
	
	console.log(parts);
	
	
	
	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data,
	) =>
	{
		const isAsc = order_by === property && order === 'asc';
		
		setOrder(isAsc ? 'desc' : 'asc');
		
		setOrderBy(property);
	};
	
	
	
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) =>
	{
		if (event.target.checked)
		{
			const now_selected = parts.map((part) => part._id);
				setSelected(now_selected);
			return;
		};
		
		setSelected([]);
	};
	
	
	
	const handleClick = (event: React.MouseEvent<unknown>, _id: string) =>
	{
		const selectedIndex = selected.indexOf(_id);
		
		let newSelected: readonly string[] = [];
		
		if (selectedIndex === -1)
		{
			newSelected = newSelected.concat(selected, _id);
		} 
		else if (selectedIndex === 0)
		{
			newSelected = newSelected.concat(selected.slice(1));
		}
		else if (selectedIndex === selected.length - 1)
		{
			newSelected = newSelected.concat(selected.slice(0, -1));
		}
		else if (selectedIndex > 0)
		{
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		};
		
		setSelected(newSelected);
	};
	
	
	
	const handleChangePage = (event: unknown, newPage: number) =>
	{
		setPage(newPage);
	};
	
	
	
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
	{
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	
	
	
	const isSelected = (_id: string) => selected.indexOf(_id) !== -1;
	
	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rows_per_page - parts.length) : 0;
	
	
		
	
	return (
		<Box className='parts_table'>
			<Paper sx={{ width: '100%', mb: 2 }}>
				
				<PartsTableToolbar numSelected={selected.length} />
				<TableContainer>
					
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size='medium'
					>
						<PartsTableHead
							numSelected={selected.length}
							order={order}
							orderBy={order_by}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={parts.length}
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
							rows.slice().sort(getComparator(order, orderBy)) */}
							{
								parts
								// stableSort(parts, getComparator(order, order_by))
								.slice(page * rows_per_page, page * rows_per_page + rows_per_page)
								.map((part, index) => {
									
									const selectedRow = JSON.stringify(part._id);
									
									const isItemSelected = isSelected(selectedRow);
									const labelId = `enhanced-table-checkbox-${part._id}`;
									
									return (
										<TableRow
											hover
											// onClick={(event) => handleClick(event, selectedRow)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={part._id}
											// selected={isItemSelected}
											sx={{
												cursor: 'pointer',
											}}
										>
											
											{/* <TableCell padding='checkbox'>
												<Checkbox
													color='primary'
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell> */}
											
											<TableCell
												component='th'
												id={labelId}
												scope='row'
											>
												<Typography variant='body2' color='textSecondary'>
													{part.name}
												</Typography>
											</TableCell>
											
											<TableCell align='left'>
												<Typography variant='body2' color='textSecondary'>
													{part.count}
												</Typography>
											</TableCell>
											
											<TableCell align='left'>
												<Typography variant='body2' color='textSecondary'>
													{part.organizational_part_number}
												</Typography>
											</TableCell>
											
											<TableCell align='left'>
												<Typography variant='body2' color='textSecondary'>
													{part.manufacturer_part_number}
												</Typography>
											</TableCell>
											
											<TableCell align='left'>
												<Typography variant='body2' color='textSecondary'>
													{part.manufacturer_name}
												</Typography>
											</TableCell>
											
										</TableRow>
									);
								})
							}
							
							{
								emptyRows > 0 && (
									<TableRow
										style={{
											height: 53 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)
							}
						</TableBody>
					</Table>
					
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component='div'
					count={parts.length}
					rowsPerPage={rows_per_page}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				
			</Paper>
		</Box>
	);
};


export default PartsTable;
import React from 'react'
// import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { WorkOrderNew, WorkOrderTemplate } from 'models';

import { useURLTab } from 'utils/hooks';
import ResponsiveWorkOrderTemplatesList from './templates/ResponsiveWorkOrderTemplatesList';
import ResponsiveWorkOrdersList from './work_orders/ResponsiveWorkOrdersList';



// const tab_titles = [ 'Work Orders', 'Work Order Templates' ];


const tab_titles_by_key =
{
	work_orders: 'Work Orders',
	work_order_templates: 'Work Order Templates',
}



const WorkOrdersAndTemplatesPageTabs =
({
	work_orders,
	work_order_templates,
} : {
	work_orders: WorkOrderNew[],
	work_order_templates: WorkOrderTemplate[],
}) =>
{
	const { tab, setTab } = useURLTab(tab_titles_by_key);
	
	
	return (
		<TabContext value={tab}>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}
				className='issue_collab_tabs'
			>
				<TabList
					onChange={(e, v) => setTab(v)}
					className='issue_collab_tabs_list'
				>
					{
						Object.entries(tab_titles_by_key).map(([key, label]) =>
							<Tab
								label={label}
								value={key}
								key={key}
							/>
						)
					}
				</TabList>
			</Box>
			
			
			<TabPanel
				value='work_orders'
				className='issue_collab_tab_panel'
			>
				<ResponsiveWorkOrdersList
					work_orders={work_orders}
				/>
			</TabPanel>
			
			
			<TabPanel
				value='work_order_templates'
				className='issue_collab_tab_panel'
			>
				<ResponsiveWorkOrderTemplatesList
					work_order_templates={work_order_templates}
				/>
			</TabPanel>
		</TabContext>
	)
};


export default WorkOrdersAndTemplatesPageTabs;
import { Controller, useFormContext } from 'react-hook-form';

import { Button, Stack } from '@mui/material';

import Formlet from 'app/gizmos/fields/Formlet';
import CorIcon from 'components/icons/CorIcon';
import SpecificDateWidget from './SpecificDateWidget';
import { SpecificDate } from 'models';
import { DateTime } from 'luxon';
import { cuid } from 'stuff';


export const OnDemandScheduleFormlet =
({
	name = 'recurrence.specific_dates',
	label = 'Scheduled Dates',
	help = 'On which dates should work orders be due?',
}) =>
{
	const form_context = useFormContext();
	
	
	let specific_dates = form_context.watch(name) as SpecificDate[] || [];
	
	// let yearly_dates : YearlyDate[] = Object.values(watched_value).filter(x => (x !== undefined)) as YearlyDate[];
	
	
	console.log({
		specific_dates,
		name,
	})
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<Stack
						sx={{
							alignItems: 'center',
						}}
					>
						{
							specific_dates?.map((specific_date, i) =>
								<SpecificDateWidget
									name={name}
									specific_date={specific_date}
									specific_dates={specific_dates}
									key={(DateTime.isDateTime(specific_date?.datetime)
										? specific_date?.datetime?.toISO()
										: specific_date?.datetime
									)}
									onChange={(new_value) => {
										
										specific_dates[i] = new_value;
										
										onChange(specific_dates);
									}}
								/>
							)
						}
					</Stack>
				}
			/>
			
			<Button
				startIcon={<CorIcon of='plus_basic' />}
				onClick={() => {
					let id = cuid();
					
					let now = DateTime.local();
					
					specific_dates.push({
						_id: id,
						datetime: now,
					})
					
					form_context.setValue(name, specific_dates);
				}}
			>
				Add Date
			</Button>
			
		</Formlet>
	)
}


export default OnDemandScheduleFormlet;
import { ExtendedPaletteOptions } from './palette interfaces';



// TODO: Define light/dark variant colors via functions on main
export const palette: ExtendedPaletteOptions =
{
	// Our palette, which now supports the new `lighter`, for example:
	primary: {
		lightest: '#fafcfd',
		lighter: '#60b5ea',
		light: '#c7e0ef',
		lightish: '#7ac1ee',
		
		main: '#1982c4',
		
		dark: '#186ab4',
		// contrastText: '#fff',
	},
	
	secondary: {
		lighter: '#ffbcbe',
		light: '#f4bdbd',
		
		main: '#ff595e',
		
		dark: '#c86c6c',
		// darker: '#870000',
		// contrastText: '#000',
	},
	
	tertiary: {
		lighter: 'rgba(242, 243, 244, 1)',
		light: 'rgb(182, 122, 231)',
		main: 'rgb(98, 30, 224)',
		dark: 'rgb(78, 15, 121)',
		darker: 'rgb(126, 30, 129)',
	},
	
	success:
	{
		light: '#c5eb89',
		main: '#8ac926',
		dark: '#74b70a',
	},
	
	warning:
	{
		light: '#ffe588',
		main: '#ffca3a',
		dark: '#ffbd0a',
	},
	
	
	// Used by `getContrastText()` to maximize the contrast between
	// the background and the text.
	contrastThreshold: 3,
	
	// Used by the functions below to shift a color's luminance by approximately
	// two indexes within its tonal palette.
	// E.g., shift from Red 500 to Red 300 or Red 700.
	tonalOffset: 0.2,
	
	
	// text,
	// error,
	// warning,
	// info,
	// success,
	
	
	
	// TODO: Review use
	bg: {
		default: '#222222',
		field: '#fff',
		
		form: '#f8f8f8',
		
		stepper: '#f3f3f3',
		stepper_header: '#fefefe',
	},
	
	field: {
		container: '#f1f1f1',
		default: '#fafafa',
		hover: '#fdfdfd',
		focus: '#fff',
	},
}


// palette.text


export default palette;
import { Box, TextField, Button, Checkbox } from '@mui/material';
import { Controller, FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { ActionProps } from '../../types';
import AddIcon from '@mui/icons-material/Add';
import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';


export interface CheckBoxProps extends ActionProps {}


export const CheckBox = (props: CheckBoxProps) =>
{
	return (
		<>
			<Description {...props} />
			<Options {...props} />
		</>
	);
};


interface DescriptionProps extends ActionProps {}


const Description = ({ control, sectionIndex, fieldIndex }: DescriptionProps) =>
{
	return (
		<Controller
			control={control}
			name={`sections.${sectionIndex}.fields.${fieldIndex}.checkbox.description`}
			defaultValue=''
			render={({ field: { value, onChange } }) => (
				<TextField
					value={value}
					onChange={onChange}
					label='Work-step Details'
					variant='filled'
				/>
			)}
		/>
	);
};


interface OptionsProps extends ActionProps {}


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	const { fields, append } = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.checkbox.options`,
	});
	
	// useEffect(() => {
	// 	if (fields.length === 0) {
	// 		append({});
	// 	}
	// }, []);
	
	
	return (
		<Box>
			{fields.map((field, optionIndex) => (
				<Option
					control={control}
					field={field}
					sectionIndex={sectionIndex}
					fieldIndex={fieldIndex}
					optionIndex={optionIndex}
					key={optionIndex}
				/>
			))}

			<Button
				startIcon={<AddIcon />}
				fullWidth={false}
				onClick={() => append({})}
				sx={{ marginLeft: '1rem' }}
			>
				Add Option
			</Button>
		</Box>
	);
};


interface OptionProps extends Omit<ActionProps, 'fieldId'> {
	field: FieldArrayWithId<
		StepperForm,
		`sections.${number}.fields.${number}.multiple_choice.options`,
		'id'
	>;
	optionIndex: number;
}


const Option =
({
	control,
	field,
	fieldIndex,
	sectionIndex,
	optionIndex,
}: OptionProps) =>
{
	return (
		<Box
			key={field.id}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.5rem',
			}}
		>
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.checkbox.options.${optionIndex}.checked` as const
				}
				defaultValue={false}
				render={({ field: { value, onChange } }) => (
					<Checkbox
						key={field.id}
						checked={!!value}
						onChange={(e) => {
							const value = e.target.checked;
							onChange(value);
						}}
					/>
				)}
			/>
			
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.checkbox.options.${optionIndex}.label` as const
				}
				defaultValue=''
				render={({ field: { value, onChange } }) => (
					<TextField
						variant='standard'
						placeholder={`Option ${optionIndex + 1}`}
						value={value}
						onChange={onChange}
					/>
				)}
			/>
		</Box>
	);
};


export default Checkbox;
import { React, useState, useRef, cuid } from 'stuff';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useStyles } from '../../../styles/ManageTagsTabTheme';

import {
	TagColorStyles,
	GenerateTagsResponsiveProps,
} from '../../../interfaces/settings_Interfaces';

import TagInputField from './TagInputField';
import TagColorPicker from './TagColorPicker';
import TagPreview from './TagPreview';


/*
	manages newly created tag object such as background color, text color, and title.
	All information will pass back to ManageTagsTab.tsx to update tags array
*/
const GenerateNewTag = ({ mobile, handleSubmit }:GenerateTagsResponsiveProps) =>
{
	const classes = useStyles();
	const tag_color_picker_ref = useRef(null)
	const [ tagTitle, setTagTitle ] = useState<string>('');
	const [ colors, setColors ]	= useState<TagColorStyles>(
		{
			bgColor: { name:'', hex:'' },
			textColor: { name:'text-primary-dark', hex:'#323338' }
		}
	);
	
	
	const handleCreate = ( e:React.FormEvent ) =>
	{
		e.preventDefault();
		
		handleSubmit(
			{
				_id: cuid(),
				name: tagTitle,
				bg_color: colors.bgColor.hex,
				color: colors.textColor.hex
			}
		);
		
		setColors(
			{
				bgColor: { name:'', hex:'' },
				textColor: { name:'text-primary-dark', hex:'#323338' }
			}
		);
		
		setTagTitle('');
		
		tag_color_picker_ref.current?.resetColors();
	}
	
	
	return (
		<>
			<Box
				className={
					(mobile)
					? classes.ManageTagFormContainer__mobile
					: null
				}
				component='form'
			>
				<Typography
					className={classes.ManageTagTitle}
					variant='h3'
					color='#323338'
					component={'span'}
				>
					Generate New Tag
				</Typography>
				
				<Box
					className={
						(mobile)
						? null
						: classes.CreateTagInputFieldContainer__desktop
					}
				>
					<TagInputField
						setTagTitle={setTagTitle}
						tagTitle={tagTitle}
					/>
				</Box>
				
				<TagColorPicker
					mobile={mobile}
					colors={colors}
					setColors={setColors}
					ref={tag_color_picker_ref}
				/>
				
				<TagPreview
					title={(tagTitle === '') ? 'Tag' : tagTitle}
					bgColor={colors.bgColor.hex}
					color={colors.textColor.hex}
				/>
				
				{
					(tagTitle !== '' && colors.bgColor.hex !== '')
					&&
					<Button
						className={
							(mobile)
							? classes.CreateTagFormBtn__mobile
							: classes.CreateTagFormBtn
						}
						variant='contained'
						type='submit'
						onClick={(e) => handleCreate(e)}
					>
						Create
					</Button>
				}

			</Box>
		</>
	)
}


export default GenerateNewTag;
import { Button, Stack, Typography } from '@mui/material';

import { useFormContext, useWatch } from 'react-hook-form';

import ShortTextFormlet from 'app/gizmos/fields/text/ShortTextFormlet';
import DatetimeFormlet from 'app/gizmos/fields/datetimes/DatetimeFormlet';

import { useCertificationRecord } from './CertificationRecordsProvider';

import { AttachmentsFormlet } from 'pages/createIssue/createIssueStepper/components/AttachmentsFormlet';
import { CertificationFor, CertificationRecordStatus } from 'models';
// import EnumSelectFormlet from 'app/gizmos/fields/select/EnumSelectFormlet';
import EnumToggleFormlet from 'app/gizmos/fields/toggles/EnumToggleFormlet';
import CheckboxFormlet from 'app/gizmos/fields/text/CheckboxFormlet';



const CreateEditCertificationRecord =
({
	closeDialog,
	setCloseDialog,
} : {
	closeDialog?: boolean,
	setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) =>

{
	const form = useFormContext();
	const { control } = form;
	
	
	const never_expires = useWatch({
		control,
		name: 'never_expires'
	});
	
	
	
	const {certification_record, handleSubmit, certification } = useCertificationRecord()
	
	console.log('YOOOOOO', certification_record)
	
	
	
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
			width='100%'
			spacing={1}
		>
			<Typography
				variant='subtitle1'
			>
				Create a new Certification Record
			</Typography>
			
			<Stack
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'
				spacing={2}
			>
				
				{
					(certification?.certification_for === CertificationFor.USER)
					? (
						<Typography variant='h1'>
							{certification?.user_name}
						</Typography>
					) : (certification?.certification_for === CertificationFor.COMPANY_WIDE_ROLE)
					? (
						<Typography variant='h1'>
							{certification?.role_name}
						</Typography>
					) : (certification?.certification_for === CertificationFor.ASSET)
					? (
						<Typography variant='h1'>
							{certification?.asset_name}
						</Typography>
					) : (
						<Typography variant='h1'>
							All Vessels
						</Typography>
					)
				}
				
				
				<CheckboxFormlet
					name='never_expires'
					label='Please check if the certificate does not expire'
					option_label='Never Expires'
				/>
				
				<DatetimeFormlet
					name='obtained_date'
					label='Obtained Date and Time'
					help='Input Date and Time when obtained.'
				/>
				
				{
					!never_expires && (
						<DatetimeFormlet
							name='expiry_date'
							label='Expiry Date and Time'
							help='Input Date and Time when it expires.'
						/>
					)
				}
				
				<EnumToggleFormlet
					name='status'
					label='Status of the record'
					enum_type={CertificationRecordStatus}
				/>
				
				<AttachmentsFormlet
					control={control}
					label='Upload Physical copies of the certification.'
					help='You can attach multiple copies.'
				/>
				
				<ShortTextFormlet
					name={'note'}
					label={'Note'}
				/>
				
			</Stack>
			
			
			<Stack
				direction='row'
				width='100%'
				justifyContent='center'
				alignItems='center'
			>
					<Button
						variant='contained'
						onClick={handleSubmit}
						type='submit'
					>
						Create
					</Button>
			</Stack>
			
			
		</Stack>
	)
}


export default CreateEditCertificationRecord
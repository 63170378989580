import { Button, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { useSearchParams } from 'react-router-dom';


// import QrCodeLabel from 'components/qrCodeLabel/QrCodeLabel';


const QrScanDialogButton = () =>
{
	let [ , setSearchParams ] = useSearchParams();
	
	
	return <Button
		onClick={() => {
			setSearchParams({
				show_qr_scanner: 'true',
			})
		}}
		variant={'text'}
		sx={{
			textTransform: 'none',
		}}
	>
		<CorIcon of='qr_code_scanner' size='25px'/>
		<Typography
			sx={{
				marginLeft: '4px',
				color: '#000c',
			}}
		>
			Scan
		</Typography>
	</Button>
}


export default QrScanDialogButton;
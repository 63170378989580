import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const UserIssueStatsChart = (props: { issues, users }) =>
{
	const { issues, users } = props;
	
	const theme = useTheme();
	
	const user_names = users.map(user => user.display_name);
	
	
	const open_issues = issues.filter(issue => issue.status === 'open');
	const resolved_issues = issues.filter(issue => issue.status === 'closed');
	
	
	const open_issues_by_users = users.map(user => {
		
		let issue_involved_in_count = 0;
		
		open_issues.forEach(issue => {
			if(issue.issue_creator_id === user._id || issue.collaborator_ids.includes(user._id))
			{
				issue_involved_in_count++;
			}
		})
		
		return issue_involved_in_count;
	});
	
	const resolved_issues_by_users = users.map(user => {
		
		let issue_involved_in_count = 0;
		
		resolved_issues.forEach(issue => {
			if(issue.issue_creator_id === user._id || issue.collaborator_ids.includes(user._id))
			{
				issue_involved_in_count++;
			}
		})
		
		return issue_involved_in_count;
	});
	
	
	
	const data: any = {
		datasets: [
			{
				backgroundColor: "#055DEA",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: resolved_issues_by_users,
				label: "Resolved Issues",
				maxBarThickness: 10,
			},
			{
				backgroundColor: "#FF5252",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: open_issues_by_users,
				label: "Open Issues",
				maxBarThickness: 10,
			},
		],
		labels: user_names,
	};

	const options: any = {
		animation: false,
		cornerRadius: 20,
		layout: { padding: 0 },
		legend: { display: false },
		maintainAspectRatio: false,
		responsive: true,
		xAxes: [
			{
				ticks: {
					fontColor: theme.palette.text.secondary,
				},
				gridLines: {
					display: false,
					drawBorder: false,
				},
			},
		],
		yAxes: [
			{
				ticks: {
					fontColor: theme.palette.text.secondary,
					beginAtZero: true,
					min: 0,
				},
				gridLines: {
					borderDash: [2],
					borderDashOffset: [2],
					color: theme.palette.divider,
					drawBorder: false,
					zeroLineBorderDash: [2],
					zeroLineBorderDashOffset: [2],
					zeroLineColor: theme.palette.divider,
				},
			},
		],
		tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: "index",
			titleFontColor: theme.palette.text.primary,
		},
	};

	return (
		<Card sx={{ width: "100%", minWidth: '300px', marginBottom: '20px' }} {...props}>
		<CardHeader
			action={
			<Button endIcon={<ArrowDropDownIcon fontSize="small" />} size="small">
				Last 7 days
			</Button>
			}
			title="Team Contribution"
		/>
		<Divider />
		<CardContent>
			<Box
			sx={{
				height: 400,
				position: "relative",
			}}
			>
			<Bar data={data} options={options} />
			</Box>
		</CardContent>
		<Divider />
		<Box
			sx={{
			display: "flex",
			justifyContent: "flex-end",
			p: 2,
			}}
		>
			<Button
			color="primary"
			endIcon={<ArrowRightIcon fontSize="small" />}
			size="small"
			>
			Overview
			</Button>
		</Box>
		</Card>
	);
}

export default UserIssueStatsChart;

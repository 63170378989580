import { Box, Button } from '@mui/material';
import { StepperProps, steps } from './Stepper';
import { useStepper } from './useSteppers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSearchParams } from 'react-router-dom';


export interface MobileStepperProps extends StepperProps {}


export const MobileStepper =
({
	handleSubmit
}
: MobileStepperProps) =>
{
	let [ search_params, setSearchParams ] = useSearchParams();
	
	let current_step = Number(search_params.get('step')) || 1;
	
	
	const { handleBack, handleNext } = useStepper(
		handleSubmit,
	);
	
	
	return (
		<Box
			sx={{
				marginTop: 'auto',
				backgroundColor: '#efefef',
			}}
		>
			<Box
				sx={{
					display: 'flex',
				}}
			>
				
				<Button
					startIcon={<ArrowBackIosIcon />}
					variant='text'
					disabled={current_step <= 1}
					onClick={handleBack}
				>
					Back
				</Button>
				
				<Box
					sx={{
						marginInline: 'auto',
						display: 'flex',
						gap: '1rem',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{steps.map((_, index) => (
						<Box
							sx={{
								height: '10px',
								width: '10px',
								borderRadius: '50%',
								cursor: 'pointer',
								backgroundColor:
									(current_step === index)
										? '#3f8ed8'
										: '#d8dbdf',
							}}
							onClick={() => setSearchParams({ step: String(index) })}
							key={index}
						/>
					))}
				</Box>
				
				<Button
					sx={{
						marginLeft: 'auto',
					}}
					onClick={handleNext}
				>
					{
						(current_step === steps.length)
							? 'Create'
							: 'Next'
					}
				</Button>
				
			</Box>
		</Box>
	);
};


export default MobileStepper;
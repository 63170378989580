import { useFormContext } from 'react-hook-form';
import { Divider, Stack } from '@mui/material';

import { OnDemandRecurrenceType, Recurrence, RecurrenceScheduleType, RecurrenceType } from 'models';

import DurationFormlet from 'app/gizmos/fields/counters/DurationFormlet';
import CheckboxFormlet from 'app/gizmos/fields/text/CheckboxFormlet';

import WeeklyScheduleFormlet from './schedule/WeeklyScheduleFormlet';
import MonthlyScheduleFormlet from './schedule/MonthlyScheduleFormlet';
import YearlyScheduleFormlet from './schedule/YearlyScheduleFormlet';

import ProjectionFormlet from './projection/ProjectionFormlet';

import TimeOfDayFormlet from './TimeOfDayFormlet';
import RecurrenceTypeFormlet from './RecurrenceTypeFormlet';
import RecurrenceScheduleTypeFormlet from './RecurrenceScheduleTypeFormlet';
import OnDemandRecurrenceTypeFormlet from './OnDemandRecurrenceTypeFormlet';
import RecurrenceIntervalFormlet from './RecurrenceIntervalFormlet';
import OnDemandFormlet from './OnDemandFormlet';
import EffectiveTypeFormlet from './EffectiveTypeFormlet';
import EffectiveDatetimeFormlet from './EffectiveDatetimeFormlet';
import RecurrenceDescription from './RecurrenceDescription';
import RecurrenceOccurrences from './RecurrenceOccurrences';



const RecurrenceFormSection = () =>
{
	const form_context = useFormContext();
	
	const recurrence = form_context.watch('recurrence') as Recurrence;
	
	const recurrence_type     = recurrence.recurrence_type as RecurrenceType;
	const recurrence_schedule = recurrence.recurrence_schedule as RecurrenceScheduleType;
	
	
	
	return (
		<Stack
			gap={2}
		>
			
			
			<RecurrenceScheduleTypeFormlet />
			
			{
				(recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
				?
					<OnDemandRecurrenceTypeFormlet />
				:
					<RecurrenceTypeFormlet />
			}
			
			<RecurrenceIntervalFormlet />
			<OnDemandFormlet />
			
			
			{
				(recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
				&&
				(recurrence_type === RecurrenceType.INTERVAL)
				&&
				<CheckboxFormlet
					name='recurrence.skip_first'
					label='Skip First Instance'
					option_label='Skip first instance'
					help={
						(form_context.watch('recurrence.skip_first'))
							? `Won't generate the first instance`
							: 'Will generate the first instance'
					}
				/>
			}
			
			
			{
				(recurrence_type === RecurrenceType.SCHEDULE)
				&&
				(recurrence_schedule === RecurrenceScheduleType.WEEKLY)
				&&
				<WeeklyScheduleFormlet />
			}
			{
				(recurrence_type === RecurrenceType.SCHEDULE)
				&&
				(recurrence_schedule === RecurrenceScheduleType.MONTHLY)
				&&
				<MonthlyScheduleFormlet />
			}
			{
				(recurrence_type === RecurrenceType.SCHEDULE)
				&&
				(recurrence_schedule === RecurrenceScheduleType.YEARLY)
				&&
				<YearlyScheduleFormlet />
			}
			
			
			<Divider />
			
			
			<TimeOfDayFormlet
				name='recurrence.time_of_day'
				label='Time of Day'
				help='Generated work orders will be due at this time'
			/>
			
			{
				(recurrence.recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
				&&
				<Divider />
			}
			
			
			<EffectiveTypeFormlet />
			<EffectiveDatetimeFormlet />
			
			
			<Divider />
			
			
			{
				((recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
				||
				(recurrence.on_demand_type === OnDemandRecurrenceType.SCHEDULE))
				&&
				<DurationFormlet
					name='recurrence.lead_time'
					label='Generation Lead Time'
					help={`Generate a work order this long in advance of its due date`}
				/>
			}
			
			
			{
				((recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
				||
				(recurrence.on_demand_type !== OnDemandRecurrenceType.TRIGGER))
				&&
				<Divider />
			}
			
			
			<RecurrenceDescription />
			<RecurrenceOccurrences />
			
			<ProjectionFormlet />
			
		</Stack>
	)
}


export default RecurrenceFormSection;
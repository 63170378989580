import { Divider, Grid, Stack, Typography } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import AffectedComponentsList from './AffectedComponentsList';
import IssuesTable from './issueTable/IssuesTable';
import PartsNeededList from './PartsNeededList';
import TagsList from './TagsList';
import CollaboratorsList from './collaborators/CollaboratorsList';
import FilesTable from './FilesTable';
import SearchableIssuesCardList from '../../issuesMain/SearchableIssuesCardList';
import { useIssue } from '../IssueProvider';
import IssuesListResponsiveContent from '../../issuesMain/IssuesListResponsiveContent';


const IssueDetailsTab = () =>
{
	const { issue, files, related_issues, tags, components } = useIssue();
	const { description, priority } = issue;
	
	
	return (
		<Grid
			container
			width={'100%'}
		>
			<Grid
				item
				className='cards_container'
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					spacing={1}
				>
					
					<InfoHighlight
						severity={
							(priority === 'Low') ? 'info'
							: (priority === 'Medium') ? 'warning'
							: (priority === 'High') ? 'error'
							: 'success'
						}
						message_context='Description'
						message_main={description}
						title={`Priority: ${priority}`}
					/>
					
					<Grid
						container
						sx={{
							marginTop:'20px',
						}}
						width='100%'
					>
						<AffectedComponentsList
							components={components}
							issue={issue}
						/>
						
						<Grid
							item
						>
							<Divider orientation='vertical'/>
						</Grid>
						
						<PartsNeededList issue={issue} />
						
						<Grid
							item
						>
							<Divider
								orientation='vertical'
							/>
						</Grid>
						
						<TagsList tags={tags} issue={issue} />
					</Grid>
					
				</Stack>
			</Grid>
			
			<IssuesListResponsiveContent issues={related_issues} title='Affected Component Past Issues' />
			
			<Grid item width='100%'>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={8} md={8} lg={8}>
						<FilesTable files={files} />
					</Grid>
					
					<Grid item xs={12} sm={4} md={4} lg={4}>
						<CollaboratorsList
							main_collaborator_title='Issue Creator'
						/>
					</Grid>
					
				</Grid>
				
			</Grid>
			
		</Grid>
	)
}


export default IssueDetailsTab;



// Creates a nested object from an array representing the path of keys
//
// Takes 2 optional arguments:
//     - a value to set at the end of the path
//     - an initial object
// 
// Ex: let house = objectFromPath(['kitchen', 'fridge'], 'empty', {civic: 123})
//     => house = {civic: 123, kitchen: {fridge: 'empty'}}
export const objectFromPath = (path, value = {}, obj = {}) =>
{
	path.reduce((result, key, i, source) =>
		{
			if(i === (source.length - 1))
			{
				return (result[key] = value);
			}
			else
			{
				return (result[key] = {});
			}
		},
		obj
	)
	
	return obj;
}



// Creates object from array, using the specified property as the key
// Defaults to using the CUID of each item as the key.
// Ex:
//     [{_id: 1, name: 'Z Efron'}, {_id: 2, name: 'H Hasselhoff'}]
//     =>
//     {1: {_id: 1, name: 'Z Efron'}, 2: {_id: 2, name: 'H Hasselhoff'}}
export const objectifyArray = (array, key = '_id') =>
{
	if(!array?.length)
	{
		return {};
	}
	
	const reducer = (acc, item, i) =>
	{
		let item_key = (item[key]) ? item[key] : i;
		
		acc[item_key] = item;
		
		return acc;
	}
	
	return array.reduce(reducer, {});
}



// Deprecated in favor of Typescript objFromObj
// Provide an object and a function that will be applied to each item
// Note the parameter order!
//     value, key, index
// 
// Ex: { a: 1, b: 2, c: 3 },   v => 2 * v
//     =>
//     {a: 2, b: 4, c: 6}
export function doForObj(obj, fn)
{
	// TODO: How to handle
	if(!obj) return null;
	
	return Object.fromEntries(
		Object.entries(obj).map(
			([key, value], index) => [key, fn(value, key, index)]
		)
	)
}



// Creates an object similar to the input, but remapped to use a
// different property as a key
// 
// Ex: {1: {id: 1, name: 'Abe'}, 2: {id: 2, name: 'Ben'}},   'name'
//     =>
//     {'Abe': {id: 1, name: 'Abe'}, 'Ben': {id: 2, name: 'Ben'}}
export function remap(obj, new_key)
{
	return objectifyArray(Object.values(obj), new_key);
}



export function isLiteralObject(a)
{
	return (!!a) && (a.constructor === Object);
};



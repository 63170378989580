import { styled, Box } from '@mui/material';

export const LeftContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '1rem',
	paddinRight: '2rem',
}));

export const RightContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-end',
	gap: '1rem',
	// marginLeft: '2rem',
}));

export const WhiteContainerWithShadow = styled(Box)(() => ({
	padding: '1rem',
	backgroundColor: 'white',
	borderRadius: '1rem',
	boxShadow: `0px 2px 3px rgba(0, 0, 0, 0.4)`,
}));


const control_styles =
{
	fontSize: 14,
	padding: 8,
};


type TreeSettingsProps =
{
	layout: string;
	orientation: string;
	linkType: string;
	stepPercent: number;
	setLayout: (layout: string) => void;
	setOrientation: (orientation: string) => void;
	setLinkType: (linkType: string) => void;
	setStepPercent: (percent: number) => void;
};


export default function TreeSettings({
	layout,
	orientation,
	linkType,
	stepPercent,
	setLayout,
	setOrientation,
	setLinkType,
	setStepPercent,
}
: TreeSettingsProps)
{
	return (
		<div style={control_styles}>
			
			<label>layout:</label>&nbsp;
			<select
				onClick={(e) => e.stopPropagation()}
				onChange={(e) => setLayout(e.target.value)}
				value={layout}
			>
				<option value='cartesian'>cartesian</option>
				<option value='polar'>polar</option>
			</select>
			
			<span
				style={{
					padding: '0 8px',
				}}
			>
				&nbsp;&nbsp;
			</span>
			
			<label>orientation:</label>&nbsp;
			<select
				onClick={(e) => e.stopPropagation()}
				onChange={(e) => setOrientation(e.target.value)}
				value={orientation}
				disabled={layout === 'polar'}
			>
				<option value='vertical'>vertical</option>
				<option value='horizontal'>horizontal</option>
			</select>
			
			<span
				style={{
					padding: '0 8px',
				}}
			>
				&nbsp;&nbsp;
			</span>
			
			<label>link:</label>&nbsp;
			<select
				onClick={(e) => e.stopPropagation()}
				onChange={(e) => setLinkType(e.target.value)}
				value={linkType}
			>
				<option value='diagonal'>diagonal</option>
				<option value='step'>step</option>
				<option value='curve'>curve</option>
				<option value='line'>line</option>
			</select>
			
			{linkType === 'step' && layout !== 'polar' && (
				<>
					&nbsp;&nbsp;
					
					<label>step:</label>&nbsp;
					<input
						onClick={(e) => e.stopPropagation()}
						type='range'
						min={0}
						max={1}
						step={0.1}
						onChange={(e) => setStepPercent(Number(e.target.value))}
						value={stepPercent}
						disabled={linkType !== 'step' || layout === 'polar'}
					/>
				</>
			)}
		</div>
	);
}
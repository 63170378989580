import Paper from '@mui/material/Paper';
import { Tag } from 'types';


const TagChip =
({
	tag,
} : {
	tag: Tag,
}) =>
{
	return (
		<Paper
			sx={{
				backgroundColor: tag.bg_color,
				color: tag.color,
				padding: '.5rem 1rem ',
				marginTop: '5px'
			}}
		>
			{ tag.name }
		</Paper>
	)
};


export default TagChip;
import {
	Box,
	InputLabel,
} from '@mui/material';
import { StyledChip } from 'pages/CreateWorkOrder/GeneralDescription/Equipments/Equipments';
import { useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { ActionProps } from '../../../types';
import CorIcon from 'components/icons/CorIcon';
import ComponentSelection from 'components/assetComponentSelection/ComponentSelection';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useCor } from 'app/providers/CorProvider';

interface EquipmentsProps extends ActionProps {}


export const ReplaceEquipments =
({
	control,
	fieldIndex,
	sectionIndex,
}
: EquipmentsProps) =>
{
	const [closeDialog, setCloseDialog] = useState(false);
	
	const equipment = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.replace.equipment`,
	});
	
	const { replace, remove } = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.replace.equipment`,
	});
	
	
	
	const { selected_components, setSelectedComponentIds } = useCor();
	
	
	
	const selectEquipment = (selected_equipment) =>
	{
		replace(selected_equipment?.map(x => ({
			_id: x._id,
			name: x.name
		})));
		
		setSelectedComponentIds(selected_equipment?.map(x => x._id));
		setCloseDialog(!closeDialog)
	}
	
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
				paddingBottom: '3rem',
			}}
		>
			<InputLabel required={true}>{`Select Equipment`}</InputLabel>
			<Box
				sx={{
					width: `min(282px, 100%)`,
					padding: '1rem',
					border: '1px solid #d8dbdf',
					cursor: 'pointer',
					display: 'flex',
					gap: '0.5rem',
					flexWrap: 'wrap',
				}}
			>
				{equipment?.map((eq, index) => (
					<StyledChip
						label={eq.name}
						onDelete={() => {
							remove(index)
							setSelectedComponentIds(
								selected_components
									.map(x => x._id)
									.filter(x => (x !== eq._id))
							)
						}}
						deleteIcon={
							<CorIcon of='close' size='15px' color='#fff' />
						}
						key={eq._id}
					/>
				))}
			</Box>
			<Box>
				<ButtonToOpenDialog
					button_variant='text'
					dialog_title='Choose equipment for taking readings'
					button_content='Select Equipment'
					dialog_content={
						<ComponentSelection
							selected_components={equipment}
							selectEquipment={selectEquipment}
						/>
					}
					sx={{
						textTransform:'none',
						textDecoration: 'underline',
					}}
					closeDialog={closeDialog}
				/>
			</Box>
		</Box>
	);
};


export default ReplaceEquipments;
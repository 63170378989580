import { Control, useFieldArray, useWatch } from 'react-hook-form';


import { Box, InputLabel, Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import { WorkOrderDescriptionFormData } from 'pages/CreateWorkOrder/work_order_types';

// import { ChangeEvent, useRef } from 'react';


export interface AttachmentsProps
{
	control: Control<any, any>,
}


export const Attachments = ({ control }: AttachmentsProps) =>
{
	const { remove, append } = useFieldArray({
		control,
		name: 'files',
	});
	
	const formFiles = useWatch({
		control,
		name: 'files',
	});
	
	
	// Convert FileList to array
	const fileChangeHandler = ( file_list: FileList | null) =>
	{
		// console.log(event);
		
		if(file_list != null)
		{
			const files_array: File[] = Array.from( file_list );
			
			files_array.forEach(file => append(file));
			
			console.log(files_array);
		}
	};
	
	
	
	const onRemove = (index: number) => remove(index);
	
	
	return (
		<Box sx={{ display: 'inline-block' }}>
			
			<InputLabel
				sx={{
					fontSize: '16px',
					color: 'black',
				}}
			>
				Attachments
			</InputLabel>
			
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				{formFiles.map((file, index) => (
					<SelectedFile
						key={index}
						file={file}
						index={index}
						onRemove={onRemove}
					/>
				))}
			</Box>
			
			<Button
				startIcon={<FileUploadIcon />}
				component='label'
				sx={{
					marginLeft: 'auto',
					marginTop: '1rem',
				}}
			>
				Upload File
				<input
					type="file"
					hidden
					multiple
					onChange={(e) => fileChangeHandler(e.currentTarget.files)}
				/>
			</Button>
		</Box>
	);
};


interface SelectedIssueProps
{
	file: File,
	index: number,
	onRemove: (index: number) => void,
}


// TODO: Reference palette colors rather than hardcoding hex values
const SelectedFile = ({ file, onRemove, index }: SelectedIssueProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				gap: '2rem',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '1rem',
				borderRadius: '5px',
				border: '1px solid #d8dbdf',
				backgroundColor: '#d8dbdf',
				color: '#666666',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.4)',
			}}
		>
			
			<Box>
				{file.name}
			</Box>
			
			<CloseIcon
				htmlColor='#666666'
				sx={{ cursor: 'pointer' }}
				onClick={() => onRemove(index)}
			/>
			
		</Box>
	);
};



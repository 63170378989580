import { TreeNode } from './tree_models';



// TODO: Should've just used lodash clamp
export function calculateBetween(
	min: number,
	max: number,
	func: (x: number) => number
)
: (x: any) => number
{
	return (x: any) =>
	{
		const result = func(x);
		
		return Math.min(
			Math.max(result, min),
			max
		);
	};
}



// Steps down through the tree, calling provided function for each node
export const traverseTree = (node: TreeNode, fn: Function) =>
{
	fn(node);
	
	if (node.children)
	{
		node.children.forEach((child) =>
			traverseTree(child, fn)
		);
	}
};


// Steps up through the tree back to the node's root ancestor, calling provided function for each node
export const traverseTreeUp = (node: TreeNode, fn: Function) =>
{
	if (node.parent)
	{
		traverseTreeUp(node.parent, fn);
	}
	
	fn(node);
};



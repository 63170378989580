import { ChatUser } from 'app/dashboard/pages/communication/interfaces/content_data_interfaces';
import UserCard from 'components/user_cards/UserCard';


export interface SearchUsersSuggestionsProps
{
	_filteredData: ChatUser[];
	_cursorNav: number;
	_whoHasSelected: (newCollab_: ChatUser) => void;
	_clearInput: () => void
}



const SearchUsersSuggestions =
({
	_cursorNav,
	_filteredData,
	_whoHasSelected,
	_clearInput,
}
: SearchUsersSuggestionsProps) =>
{
	return (
		<>
			{
				(_filteredData.length !== 0)
				&&
				<div
					className='search__dataResult'
				>
					{
						_filteredData.slice(0, 15).map(( user, key ) =>
							<div 
								className={
									(_cursorNav === key)
									? 'search__dataItem search__dataItem--active'
									: 'search__dataItem'
								}
								key={key}
								onClick={ () => {
									_whoHasSelected( user );
									_clearInput();
								} }
							>
								<div
									className={ 'userCard' }
								>
									<UserCard
										_username={ user.username }
										_userImg={ user.profilePic }
										_userPosition={ user.position }
									/>
								
								</div>
							</div>
						)
					}
				</div>
			}
		</>
	)
}


export default SearchUsersSuggestions
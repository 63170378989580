import { Info } from 'luxon';
import { arrayOf, dotJoin } from 'utils';

import { FormControl, FormGroup, MenuItem, Select, Stack, Typography } from '@mui/material';

import DayOfMonth from '../DayOfMonth';
import { YearlyDate } from 'models';
import DeleteIconWidget from 'app/gizmos/DeleteIconWidget';


export const YearlyDateWidget =
({
	basename,
	yearly_date,
	yearly_dates,
	onChange,
} : {
	basename: string,
	yearly_date: YearlyDate,
	yearly_dates: YearlyDate[],
	onChange: Function,
}) =>
{
	let day_nums   = arrayOf(31, (x: number) => x + 1);
	let month_nums = arrayOf(12, (x: number) => x + 1);
	
	let months = Info.months();
	
	let field_name = dotJoin(basename, yearly_date._id);
	
	
	// console.log({
	// 	basename,
	// 	field_name,
	// 	yearly_date,
	// 	yearly_dates,
	// })
	
	
	return (
		<Stack
			direction='row'
			sx={{
				padding: '2px 20px',
				gap: 1.5,
			}}
		>
			<FormControl
				component='fieldset'
			>
				<FormGroup>
					<Select
						value={yearly_date.month}
						onChange={(e) => {
							onChange({
								...yearly_date,
								month: e.target.value,
							});
							
							e.stopPropagation();
							e.preventDefault();
						}}
						sx={{
							width: '120px',
							bgcolor: 'field.default',
							
							'&:hover': {
								bgcolor: 'field.hover',
							},
							'& .Mui-focused': {
								bgcolor: 'field.focus',
							},
						}}
					>
						{month_nums.map((month_num) => {
							return (
								<MenuItem
									value={month_num}
									key={month_num}
								>
									{months[month_num - 1]}
								</MenuItem>
							);
						})}
					</Select>
				</FormGroup>
			</FormControl>
			<FormControl
				component='fieldset'
			>
				<FormGroup>
					<Select
						value={yearly_date?.day}
						onChange={(e) => {
							onChange({
								...yearly_date,
								day: e.target.value,
							});
							
							e.stopPropagation();
							e.preventDefault();
						}}
						sx={{
							width: '80px',
							bgcolor: 'field.default',
							
							'&:hover': {
								bgcolor: 'field.hover',
							},
							'& .Mui-focused': {
								bgcolor: 'field.focus',
							},
						}}
					>
						{day_nums.map((day_num) => {
							return (
								<MenuItem
									value={day_num}
									key={day_num}
								>
									<DayOfMonth
										day_num={day_num + 1}
									/>
								</MenuItem>
							);
						})}
					</Select>
				</FormGroup>
			</FormControl>
			
			<Typography
				sx={{
					alignSelf: 'center',
				}}
			>
				of each year
			</Typography>
			
			
			<DeleteIconWidget
				name={basename}
				array={yearly_dates}
				item_id={yearly_date._id}
			/>
			
		</Stack>
	)
}


export default YearlyDateWidget;
import { React } from 'stuff';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';


import { EnhancedTableToolbarProps } from './partsTableInterface';



const PartsTableToolbar = (props: EnhancedTableToolbarProps) =>
{
	const { numSelected } = props;

	return (
	<Toolbar
		sx={{
			pl: { sm: 2 },
			pr: { xs: 1, sm: 1 },
			...(numSelected > 0 && {
				bgcolor: (theme) =>
				alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
			}),
		}}
	>
		{numSelected > 0 ? (
			<Typography
				sx={{ flex: '1 1 100%' }}
				color="inherit"
				variant="subtitle1"
				component="div"
			>
				{numSelected} selected
			</Typography>
		) : (
			<Typography
				sx={{ flex: '1 1 100%' }}
				variant="h6"
				id="tableTitle"
				component="div"
			>
				Parts List
			</Typography>
		)}
		{numSelected > 0 ? (
			<Tooltip title="Assign Role">
				<Button
					variant='outlined'
					sx={{
						minWidth: '130px',
					}}
				>
					Select Parts
				</Button>
			</Tooltip>
		) : (
			<Tooltip title="Filter list">
				<IconButton>
					<FilterListIcon />
				</IconButton>
			</Tooltip>
		)}
	</Toolbar>
	);
};


export default PartsTableToolbar;
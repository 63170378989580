import { useAuthData } from 'app/auth/AuthDataProvider';
import { useDoc } from 'ember';
import { CorComponent } from 'models';
import { createContext, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useBreadcrumbData } from './BreadcrumbDataProvider';


export interface SelectedComponentData
{
	component: CorComponent,
}


const ComponentContext = createContext<SelectedComponentData>({
	component: null,
});


export const useComponent = () =>
{
	return useContext(ComponentContext);
};


// Loads the component specified in the URL path/query
export const ComponentLoader = ({ children }) =>
{
	const breadcrumbs_data = useBreadcrumbData();
	
	const { claims } = useAuthData();
	
	let [ search_params ] = useSearchParams();
	
	let location = useLocation();
	
	
	const [ url_base ] = location.pathname.split('?');
	
	const url_parts = url_base.split('/');
	
	let component_id = null;
	
	
	// Basically replicating what React Router does with dynamic routing
	url_parts.forEach((part, i) =>
	{
		if(part === 'components')
		{
			component_id = url_parts[i + 1];
		}
	})
	
	// Query parameters have precedence over base URL
	component_id = search_params.get('component') || component_id;
	
	let org_path = `organizations/${claims?.organization_id}`;
	
	
	const selected_component_doc = useDoc(
		`${org_path}/components/${component_id}`,
	);
	
	
	const component = selected_component_doc.data as CorComponent;
	
	
	useEffect(() =>
		{
			if(component)
			{
				breadcrumbs_data.setSomePairs({
					[component.asset_id]: component.asset_name,
					[component.system_id]: component.system_name,
					[component._id]: component.name,
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[component]
	)
	
	
	return (
		<ComponentContext.Provider value={{
			component,
		}}>
			{children}
		</ComponentContext.Provider>
	);
}


export default ComponentLoader;

import { useIssue } from 'app/dashboard/pages/issueCollaboration/IssueProvider';
import Chat from 'app/widgets/chat/Chat';
import { useRequisition } from '../RequisitionDataProvider';


const RequisitionChat = () =>
{
	const { requisition, messages, events, files } = useRequisition();
	
	
	return (
		<Chat
			messages={messages}
			events={events}
			files={files}
			message_metadata={{ requisition_id: requisition._id }}
		/>
	)
}


export default RequisitionChat;
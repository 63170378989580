
import { React } from 'stuff';

import Calendar from './calendar/Calendar';


const SchedulePage = () =>
{
	return <div
		className='schedule_page'
	>
		<h2>Schedule</h2>
		
		<Calendar />
		
	</div>
}


export default SchedulePage;
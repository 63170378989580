import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { CalendarDateTitleProps } from '../interface/CalendarProps';


const CalendarDateTitle: React.FC< CalendarDateTitleProps > =
({
	date
}) =>
{
	return (
		<Box className = 'normalDayPaper' >
			<Grid item >
				{ date.getDate() }
			</Grid>
		</Box>
	)
}


export default CalendarDateTitle;
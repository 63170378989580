import Button from '@mui/material/Button';

import { useStyles } from '../../../styles/ManageProfileTabTheme';


const UserProfilePasswordEdit = () =>
{

	const classes = useStyles();

	return (
		<>
			<Button
				className={classes.UserProfile_PasswordBtn}
				variant='contained'
				disabled
			>
				Change Password
			</Button>
		</>
	)
}


export default UserProfilePasswordEdit;
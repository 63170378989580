
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useStyles } from '../../../styles/ManageProfileTabTheme'
import { UserProfileAvatarEditProps } from '../../../interfaces/settings_Interfaces';


const UserProfileAvatarEdit =
({
	setDeleteAvatar,
	setChangeAvatar,
	current_user
}: UserProfileAvatarEditProps) =>
{
	const classes = useStyles();
	
	
	return (
		<>
			<Box
				className={classes.UserProfileBtns}
			>
				<Button
					className={classes.UserProfile_UploadBtn}
					component='label'
				>
						Upload
				<input
					hidden
					accept='image/*'
					multiple type='file'
					onChange={(e) => setChangeAvatar(e)}
				/>
				</Button>
				
				<Button
					className={classes.UserProfile_DeleteBtn}
					onClick={ setDeleteAvatar }
				>
					Delete
				</Button>
			</Box>
		</>
	)
}


export default UserProfileAvatarEdit;
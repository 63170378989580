import { useCollection } from 'ember';
import { CostCenter, CostCode, CostRecord } from 'models'
import { ReactNode, createContext, useContext } from 'react'
import { useAuthData } from 'stuff';




export interface CostAnalyticsData
{
	cost_codes: CostCode[],
	cost_centers: CostCenter[],
	cost_records: CostRecord[],
};


const CostAnalyticsDataContext = createContext<CostAnalyticsData>(null);


export const useCostAnalyticsLoader = () =>
{
	return useContext(CostAnalyticsDataContext)
};



const CostAnalyticsLoader =
({
	children,
} : {
	children: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	
	const org_path = `organizations/${claims.organization_id}`;
	
	
	// Call all cost related collections
	const cost_centers_collection = useCollection(`${org_path}/cost_centers`);
	const cost_codes_collection = useCollection(`${org_path}/cost_codes`);
	const cost_records_collection = useCollection(`${org_path}/cost_records`);
	
	
	// Load data
	const cost_centers = cost_centers_collection?.data as CostCenter[] || [];
	const cost_codes = cost_codes_collection?.data as CostCode[] || [];
	const cost_records = cost_records_collection?.data as CostRecord[] || [];
	
	
	
	
	
	const cost_analytics_data =
	{
		cost_centers: cost_centers || [],
		cost_codes: cost_codes || [],
		cost_records: cost_records || [],
	}
	
	
	
	return (
		<CostAnalyticsDataContext.Provider
			value={cost_analytics_data}
		>
			{children}
		</CostAnalyticsDataContext.Provider>
	)
}

export default CostAnalyticsLoader
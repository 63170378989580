import { Issue } from 'types/issue_level_interfaces';
import { Part, Tag, User } from 'types';
import { ComponentReading, ComponentRecord, ComponentTreeData, CorComponent } from 'models';



export interface StepperForm
{
	sections: FormSection[],
}


export interface FormSection
{
	name: string,
	description: string,
	fields: WorkOrderField[],
}


export const actionTypes =
[
	'text',
	'multiple_choice',
	'checkbox',
	'pass_fail',
	'yes_no',
	'date',
	'reading',
	'replace',
	'instruction'
] as const;


export type ActionType = typeof actionTypes[number];

export const passFailValues = ['pass', 'fail', 'n/a'] as const;

export type PassFailType = typeof passFailValues[number];

export const yesNoValues = ['yes', 'no', 'n/a'];

export type YesNoType = typeof yesNoValues[number];




export interface WorkOrderField
{
	action_type?: ActionType | '',
	text?: {
		description: string,
		longText: string,
	},
	multiple_choice?: {
		description: string,
		options: {
			label?: string,
			checked?: boolean,
		}[],
	},
	checkbox?: {
		description: string,
		options: {
			label?: string,
			checked?: boolean,
		}[],
	},
	pass_fail?: {
		description: string,
		value: PassFailType,
	},
	yes_no?: {
		description: string,
		value: YesNoType,
	},
	date?: {
		description: string,
		time: Date | null,
		date: Date | null,
	},
	reading?: {
		description: string,
		equipment: {
			_id: string,
			name: string,
		}[],
		component_record: {
			_id: string,
			title: string,
		},
		cumulative?: boolean,
		readingType: 'cumulative' | 'increment',
		value: number,
	},
	replace?: {
		description: string,
		equipment: {
			_id: string,
			name: string,
		}[],
		value: YesNoType,
	},
	instruction?: {
		description: string,
	}
	
	
	// TODO: User selection? UserStub?
	user?: {
		description: string,
		value: {
			_id: string,
			name: string,
		}[],
	}
	
	
}




// TODO: Enum
// export const work_order_types = [ 'Maintenance', 'Investigation', 'Regular' ] as const;


export enum WorkOrderType
{
	Maintenance = 'Maintenance',
	Investigation = 'Investigation',
	Regular = 'Regular',
}

// type WorkOrderType = typeof work_order_types[number];




export interface PartWithSelectedCount extends Part
{
	selected_count: number,
	part_needed_id?: string,
}


export interface WorkOrderDescriptionFormData
{
	_id: string,
	title: string,
	description: string,
	work_order_type?: WorkOrderType | '',
	tags: Tag[],
	asset: {
		_id: string,
		name: string,
	}
	equipment: {
		_id: string,
		name: string,
		asset_id?: string,
		asset_name?: string,
		system_id?: string,
		system_name?: string,
	}[],
	parts: PartWithSelectedCount[],
	issues: Issue[],
	files: File[],
	collaborators: User[],
	
	test?: any,
}















import { CostCenter, CostCode } from 'models/costs';
import { Issue } from 'models/issues';
import { WorkOrderNew } from 'models/work_orders';
import { Role, Tag, User } from 'types';



export enum RequisitionStatus
{
	STARTED = 'Started',
	IN_PROGRESS = 'In Progress',
	ON_HOLD = 'On Hold',
	ACCOUNTS_APPROVED = 'Accounts Approved',
	COMPLETED = 'Completed',
}


export enum RequisitionType
{
	PARTS = 'PARTS',
	OFFICE_SUPPLIES = 'OFFICE SUPPLIES',
	CAPITAL_COSTS = 'CAPITAL COSTS',
	DRY_DOCK = 'DRY DOCK',
	MISC = 'MISCELLANEOUS',
	CONTRACTUAL_WORK = 'CONTRACTUAL WORK',
	LABOUR = 'LABOUR',
	REFIT = 'REFIT',
}




export interface Requisition
{
	_id: string,
	title: string,
	requisition_num?: number,
	
	created_by_user: {
		_id: string,
		name: string,
		email: string,
	},
	completed_by_user?: {
		_id: string,
		name: string,
		email: string,
	},
	created_at: string, //local utc iso string
	updated_at?: string,
	
	status: RequisitionStatus | '',
	status_message?: ''
	
	estimated_cost?: {
		currency: string,
		quantity: string,
	},
	final_cost?: {
		currency: string,
		quantity: string,
		is_approved: boolean,
	},
	
	cost_center_ids: string[],
	cost_code_ids: string[],
	
	accounts_to_charge_ids: string[],
	
	is_critical: boolean,
	
	items: RequisitionItem | null,
	
	files?: any[],
	
	work_order_ids?: string[],
	issue_ids?: string[],
	tag_ids?: string[],
	collaborator_ids?: string[],
	role_ids?: string[],
	
	
	
	// Additional place holders for data
	charge_accounts: User[],
	issues: Issue[],
	work_orders: WorkOrderNew[],
	tags: Tag[],
	roles: Role[],
	collaborators: User[],
	cost_centers?: CostCenter[],
	cost_codes?: CostCode[],
}


export interface RequisitionItem
{
	parts?: PartTemplate[],
	
	sections?: RequisitionSectionTemplate[],
};


export interface PartTemplate
{
	id: string,
	name: string,
	quantity: number,
	
	unit_price: {
		currency: string,
		quantity: string,
	},
	
	component_ids?: string[],
	
	manufacturer_name?: string,
	manufacturer_part_number?: string,
	website_link?: string,
}


export interface RequisitionSectionTemplate
{
	id: string,
	description: string,
	
	cost: {
		currency: string,
		quantity: string,
	}
}
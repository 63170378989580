import { ReactNode, createContext, useContext, useState } from 'react';


import sensor_map_data from './techsol_data_map';
import { TechhsolSensor, TimeSeriesPoint, TimeSeriesStatistic } from './techsol_models';
import { doForObj } from 'utils';
import { DateTime } from 'luxon';
import { DomainTuple } from 'victory';
import { toInteger } from 'lodash';


export interface VictorContextData
{
	sensors_by_id?: {[sensor_id: string]: TechhsolSensor},
	sensors?: TechhsolSensor[],
	selected_sensor?: TechhsolSensor,
	selected_sensor_stats?: any[],
	
	points?: TimeSeriesPoint[],
	min_points?: TimeSeriesPoint[],
	max_points?: TimeSeriesPoint[],
	
	domain: { x?: DomainTuple; y?: DomainTuple },
	x_domain: DomainTuple,
	y_domain: DomainTuple,
	
	zoom_domain: { x?: DomainTuple; y?: DomainTuple },
	zoom_x_domain: DomainTuple,
	zoom_y_domain: DomainTuple,
	
	setDomain: Function,
	setZoomDomain: Function,
	
	setXDomain: Function,
	setYDomain: Function,
	setZoomXDomain: Function,
	setZoomYDomain: Function,
	
	setSelectedSensor?: Function,
	handleSelectSensor?: Function,
}


// Create a context for the tree hierarchy
const VictorContext = createContext<VictorContextData | null>(null);


export const useVictor = () : VictorContextData =>
{
	return useContext(VictorContext);
};


export const statsArrayFromSensor = (selected_sensor: TechhsolSensor) : TimeSeriesStatistic[] =>
{
	let stats = [];
	
	doForObj(selected_sensor?.daily_stats, (v, k) => {
		
		v.timestamp = DateTime.fromISO(k).toMillis() / 1000;
		
		stats.push(v);
	})
	
	// doForObj(
	// 	selected_sensor?.hourly_stats,
	// 	(v, k) =>
	// 	{
	// 		let dt = k.slice(0, 10) + 'T' + k.slice(11) + ':00:00'
			
	// 		v.timestamp = DateTime.fromISO(dt).toMillis() / 1000;
			
	// 		stats.push(v);
	// 	}
	// )
	
	return stats;
}



// TODO: Time zones?
export const convertTimeSeriesStatisticToPoint = (input: TimeSeriesStatistic) : TimeSeriesPoint =>
{
	return ({
		x: new Date((input.timestamp as number) * 1000),
		y: (input.mean !== undefined)
			? input.mean
			: input.count_true / input.count,
	})
}

export const convertTimeSeriesStatisticToMinPoint = (input: TimeSeriesStatistic) : TimeSeriesPoint =>
{
	return ({
		x: new Date((input.timestamp as number) * 1000),
		y: (input.min !== undefined)
			? input.min
			: toInteger(!!(input.count_true < input.count)),
	})
}

export const convertTimeSeriesStatisticToMaxPoint = (input: TimeSeriesStatistic) : TimeSeriesPoint =>
{
	return ({
		x: new Date((input.timestamp as number) * 1000),
		y: (input.max !== undefined)
			? input.max
			: toInteger(input.count_true > 0),
	})
}




// VictorHierarchyProvider component
export const VictorProvider =
({
	children,
} : {
	children: ReactNode,
}) =>
{
	const [ sensor_map, setSensorMap ] = useState<{[sensor_id: string]: TechhsolSensor} | null>(sensor_map_data);
	const [ sensors, setSensors ] = useState<TechhsolSensor[]>(Object.values(sensor_map));
	
	
	const [ selected_sensor, setSelectedSensor ] = useState<TechhsolSensor | null>(sensors?.[3]);
	const [ selected_sensor_stats, setSelectedSensorStats ] = useState<TimeSeriesStatistic[]>(statsArrayFromSensor(selected_sensor));
	
	
	const pointsFromSelectedSensorStats = (stats: TimeSeriesStatistic[]) =>
	{
		return stats.map(item =>
			convertTimeSeriesStatisticToPoint(item)
		).sort((a: TimeSeriesPoint, b: TimeSeriesPoint) =>
			(a.x.getMilliseconds() - b.x.getMilliseconds()))
	}
	
	const minPointsFromSelectedSensorStats = (stats: TimeSeriesStatistic[]) =>
	{
		return stats.map(item =>
			convertTimeSeriesStatisticToMinPoint(item)
		).sort((a: TimeSeriesPoint, b: TimeSeriesPoint) =>
			(a.x.getMilliseconds() - b.x.getMilliseconds()))
	}
	
	const maxPointsFromSelectedSensorStats = (stats: TimeSeriesStatistic[]) =>
	{
		return stats.map(item =>
			convertTimeSeriesStatisticToMaxPoint(item)
		).sort((a: TimeSeriesPoint, b: TimeSeriesPoint) =>
			(a.x.getMilliseconds() - b.x.getMilliseconds()))
	}
	
	
	const [ points, setPoints ] = useState<TimeSeriesPoint[]>(pointsFromSelectedSensorStats(selected_sensor_stats));
	const [ min_points, setMinPoints ] = useState<TimeSeriesPoint[]>(minPointsFromSelectedSensorStats(selected_sensor_stats));
	const [ max_points, setMaxPoints ] = useState<TimeSeriesPoint[]>(maxPointsFromSelectedSensorStats(selected_sensor_stats));
	
	
	
	// TODO: These are just using points, not min and max points
	
	
	const xDomain = (points: TimeSeriesPoint[], min_points: TimeSeriesPoint[], max_points: TimeSeriesPoint[]) : DomainTuple =>
	{
		let all_points = points.concat(min_points, max_points);
		
		return ([
			all_points.reduce((prev, curr) => (prev.x < curr.x) ? prev : curr).x,
			all_points.reduce((prev, curr) => (prev.x > curr.x) ? prev : curr).x,
		])
	}
	
	const yDomain = (points: TimeSeriesPoint[], min_points: TimeSeriesPoint[], max_points: TimeSeriesPoint[]) : DomainTuple =>
	{
		// let input = points[0];
		
		// y: (input.mean !== undefined)
		// 	? input.mean
		// 	: input.count_true / input.count,
		
		let all_points = points.concat(min_points, max_points);
			
		let min = all_points.reduce((prev, curr) => (prev.y < curr.y) ? prev : curr, all_points[0]).y;
		let max = all_points.reduce((prev, curr) => (prev.y > curr.y) ? prev : curr, all_points[0]).y;
		
		console.log(min, max)
		let padding = (max - min) * .1;
		
		return ([min - padding, max + padding])
	}
	
	
	const [ x_domain, setXDomain ] = useState<DomainTuple>(xDomain(points, min_points, max_points));
	const [ y_domain, setYDomain ] = useState<DomainTuple>(yDomain(points, min_points, max_points));
	
	const [ zoom_x_domain, setZoomXDomain ] = useState<DomainTuple>(x_domain);
	const [ zoom_y_domain, setZoomYDomain ] = useState<DomainTuple>(y_domain);
	
	
	
	
	const handleSelectSensor = (new_sensor: TechhsolSensor) =>
	{
		let stats = statsArrayFromSensor(new_sensor);
		let points = pointsFromSelectedSensorStats(stats);
		let min_points = minPointsFromSelectedSensorStats(stats);
		let max_points = maxPointsFromSelectedSensorStats(stats);
		
		setSelectedSensor(new_sensor);
		setSelectedSensorStats(stats);
		setPoints(points);
		setMinPoints(min_points);
		setMaxPoints(max_points);
		setXDomain(xDomain(points, min_points, max_points));
		
		let new_y_domain = yDomain(points, min_points, max_points);
		
		setYDomain(new_y_domain);
		setZoomYDomain(new_y_domain);
		
		console.log({
			new_sensor,
			stats,
		})
	}
	
	
	
	const setDomain = (domain: { x?: DomainTuple; y?: DomainTuple }) =>
	{
		setXDomain(domain.x);
		setYDomain(domain.y);
	}
	
	
	const setZoomDomain = (domain: { x?: DomainTuple; y?: DomainTuple }) =>
	{
		setZoomXDomain(domain.x);
		setZoomYDomain(domain.y);
	}
	
	
	
	
	
	console.log({
		sensor_map,
		sensors,
		selected_sensor,
		selected_sensor_stats,
		
		points,
		min_points,
		max_points,
		
		x_domain,
		y_domain,
		
		zoom_x_domain,
		zoom_y_domain,
	})
	
	
	// Render the provider with the tree hierarchy context
	return (
		<VictorContext.Provider value={{
			sensors_by_id: sensor_map,
			sensors,
			selected_sensor,
			selected_sensor_stats,
			
			points,
			min_points,
			max_points,
			
			domain: { x: x_domain, y: y_domain },
			x_domain,
			y_domain,
			
			zoom_domain: { x: zoom_x_domain, y: zoom_y_domain },
			zoom_x_domain,
			zoom_y_domain,
			
			setDomain,
			setZoomDomain,
			setXDomain,
			setYDomain,
			setZoomXDomain,
			setZoomYDomain,
			
			setSelectedSensor,
			handleSelectSensor,
		}}>
			{children}
		</VictorContext.Provider>
	);
};


export default VictorProvider;
import cuid from 'cuid';

import { useFormContext } from 'react-hook-form';

import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';
import { ButtonToOpenPartsDialog } from 'pages/CreateWorkOrder/GeneralDescription/Parts/PartsModal/ButtonToOpenPartsDialog';

import { Box, TextField, Typography } from '@mui/material';

import {useStyles} from '../../styles/theme';


import AddedPartsList from 'components/addParts/AddedPartsList';
import SelectedEquipmentList from 'components/selectEquipment/SelectedEquipmentsList';
import SelectEquipmentDrawer from 'components/selectEquipment/SelectEquipmentDrawer';
import LinkWorkOrder from 'components/linkWorkOrder/LinkWorkOrder';

// import IssueCreationDrawer from '../components/IssueCreationDrawer';
// import LinkWorkOrderDrawer from 'components/linkWorkOrder/LinkWorkOrderDrawer';

import SeveritySelect from '../components/SeveritySelect';



const CreateIssueDetails = () =>
{
	const classes = useStyles();
	
	const { setValue } = useFormContext();
	
	const { register, formState: {errors}, control } = useFormContext();
	const {selected_components, set_selected_components, parts_needed, set_parts_needed } = useSessionContext();
	
	
	
	const selectParts = (parts) =>
	{
		const parts_needed_ids = [];
		const parts_with_part_needed_id = parts.map(part => {
			
			const _id = cuid();
			
			parts_needed_ids.push(_id);
			
			return {
				part_needed_id: _id,
				...part
			}
		})
		
		set_parts_needed(parts_with_part_needed_id);
		
		setValue('parts_needed_ids', parts_needed_ids);
	};
	
	
	
	const validatePage = () =>
	{
		return (errors?.name || errors.severity )
	}
	
	
	return (
		<Box className={classes.CreateIssueDetailsBox}>
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					
					<Typography variant='h3'>
						Title
					</Typography>
					
					<Typography variant='h3' color='#ff595e'>
						*
					</Typography>
					
				</Box>
				
				<TextField  
					{...register('name',{ required: 'Please enter an issue title.'} )}
					required={true}
					variant='outlined'
					fullWidth
					error={(errors?.name !== undefined) ? (errors.name?.message !== '') : false}
				/>
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Typography variant='h3'>
					Description
				</Typography>
				
				<TextField 
					{...register('description')}
					multiline 
					fullWidth
					variant='outlined'
					rows={4}
				/>
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					
					<Typography variant='h3'>
						Primary Affected Equipment
					</Typography>
					
					<Typography variant='h3' color='#ff595e'>
						*
					</Typography>
					
				</Box>
				
				<SelectedEquipmentList />
				
				<SelectEquipmentDrawer 
					selected_components={selected_components}
					set_selected_components={set_selected_components}
				/>
			</Box>
			
			<Box className={classes.CreateIssueInputBox}>
				<SeveritySelect />
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					<Typography variant='h3'>
						Link or Create Work Orders
					</Typography>
				</Box>
				
				<LinkWorkOrder control={control} />
				
			</Box>
			
			
			<Box className={classes.CreateIssueInputBox}>
				
				<Box className={classes.CreateIssueInputRowBox}>
					<Typography variant='h3'>
						Parts Required
					</Typography>
				</Box>
				
				<AddedPartsList />
				
				{/* <IssueCreationDrawer
					buttonTitle='Add Parts'
				>
					<AddPartsStepper />
				</IssueCreationDrawer> */}
				<ButtonToOpenPartsDialog
					parts={parts_needed}
					onSetParts={selectParts}
				/>
				
			</Box>
			
			{
				(validatePage()) ? (
					<Box className={classes.CreateIssueCenterJustifyRow}>
						<Typography variant='h4' color='#ff595e'>
							Please complete all mandatory fields.
						</Typography>
					</Box>
				) : (
					<Box className={classes.CreateIssueRightJustifyRow}>
						<Typography variant='h4' color='#ff595e'>
							*
						</Typography>
						<Typography variant='h4'>
							mandatory fields.
						</Typography>
					</Box>
				)
			}
		</Box>
	);
};


export default CreateIssueDetails;

import CorsAnalyticsLoader from './CorsAnalyticsLoader'
import CorsAnalyticsGridArea from './CorsAnalyticsGridArea'



const CorsAnalyticsDashboardMain = () =>
{
	return (
		<CorsAnalyticsLoader>
			<CorsAnalyticsGridArea />
		</CorsAnalyticsLoader>
	)
}


export default CorsAnalyticsDashboardMain;


export enum Periodicity
{
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
	QUARTERLY = 'Quarterly',
	YEARLY = 'Yearly',
}



export interface CostCenter
{
	_id: string,
	description: string,
	centers?: {
		_id: string,
		name: string,
	}[],
	components?: {
		_id: string,
		name: string,
	}[],
	allocated_budget?: {
		quantity: '',
		currency: '',
	},
	periodicity?: Periodicity,
	
	created_at?: string,
	updated_at?: string,
	effective_datetime?: string,
	
	created_by_user?: {
		_id: string,
		name: string,
		email: string,
	},
	
	updated_by_user?: {
		_id: string,
		name: string,
		email: string,
	}
}




export interface CostCode
{
	_id: string,
	description: string,
	cost_center_id?: string,
}


export interface CostRecord
{
	_id: string,
	cost: {
		quantity: string,
		currency: string,
		is_approved: boolean,
	},
	cost_center_ids: string[],
	cost_code_ids: string[],
	
	requisition_id?: string,
	work_order_id?: string,
	issue_id?: string,
	action_id?: string,
	
	issued_by_user: {
		_id: string,
		name: string,
		email: string,
	},
	created_at: string,
}
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DateTime } from 'luxon';
import cuid from 'cuid';


import { useAuthData } from 'stuff';
import {
	useCollection,
	useWrite,
} from 'ember';

import { Certification, CertificationFor, Role } from 'models';
import { User, Asset } from 'models';



// TODO: Why isn't this in models?
// You can let descendants submit via useFormContext - this provider doesn't need it
export interface CertificationData
{
	certification?: Certification,
	
	all_certifications: Certification[],
	
	users: User[],
	assets: Asset[],
	roles: Role[],
	
	uploadFiles?: Function,
	handleSubmit?: any
}



export interface CertificationFormData extends Certification
{
	user: User,
	asset: Asset,
	role: Role,
}


const DEFAULT_FORM_DATA: CertificationFormData =
{
	_id: cuid(),
	certification_for: CertificationFor.USER,
	name: '',
	category: '',
	type: '',
	lead_time_in_days: null,
	is_required_to_operate: null,
	is_physical_copy_required: null,
	
	// assigned to?
	user_id: null,
	user_name: null,
	asset_id: null,
	asset_name: null,
	role_id: null,
	role_name: null,
	
	user: null,
	asset: null,
	role: null,
}


const CertificationDataContext = createContext<CertificationData>(null);


export const useCertification = () =>
{
	return useContext(CertificationDataContext)
};


const CertificationDataProvider =
({
	certification,
	children,
} : {
	certification?: Certification,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const write = useWrite();
	
	
	const datetime = DateTime.utc().toISO();
	
	
	const form = useForm<CertificationFormData>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
			...certification,
		},
	});
	
	const form_values = form.getValues();
	console.log({form_values});
	
	
	
	
	const handleSubmit = async (e) =>
	{
		e.preventDefault();
		
		const form_values = form.getValues();
		
		// If edit rather than creation then we keep same _id, else we cuid() and create new
		const new_certification_data: CertificationFormData = (certification !== undefined)
			? { ...form_values }
			: { ...form_values, _id: cuid() };
		
		console.log ("Hello certification form values:" + {form_values})
		
		let certification_data: Certification = {
			_id: new_certification_data?._id,
			certification_for: new_certification_data?.certification_for,
			name: new_certification_data?.name,
			category: new_certification_data?.category,
			lead_time_in_days: new_certification_data?.lead_time_in_days,
			is_required_to_operate: new_certification_data?.is_required_to_operate,
			is_physical_copy_required: new_certification_data?.is_physical_copy_required,
			created_at: datetime,
		}
		
		if(new_certification_data?.certification_for === CertificationFor.ASSET)
		{
			certification_data = {
				...certification_data,
				asset_name: new_certification_data.asset?.name,
				asset_id: new_certification_data?.asset?._id
			}
		}
		
		if(new_certification_data?.certification_for === CertificationFor.USER)
		{
			certification_data = {
				...certification_data,
				user_name: new_certification_data.user?.display_name,
				user_id: new_certification_data?.user?._id
			}
		}
		
		if(new_certification_data?.certification_for === CertificationFor.COMPANY_WIDE_ROLE)
		{
			certification_data = {
				...certification_data,
				role_name: new_certification_data.role?.name,
				role_id: new_certification_data?.role?._id
			}
		}
		
		
		
		console.log({certification_data});
		
		write(`organizations/${claims.organization_id}/certifications`, certification_data);
	}
	
	
	const org_path = `organizations/${claims.organization_id}`;
	
	const users_collection              = useCollection(`${org_path}/users`);
	const assets_collection             = useCollection(`${org_path}/assets`);
	const roles_collection              = useCollection(`${org_path}/roles`);
	const all_certifications_collection = useCollection(`${org_path}/certifications`);
	

	const users = users_collection?.data as User[] || [];
	const assets = assets_collection?.data as Asset[] || [];
	const roles = roles_collection?.data as Role[] || [];
	const all_certifications = all_certifications_collection?.data as Certification[] || [];
	
	
	
	
	useEffect(() =>
	{
		if(certification?.user_id)
		{
			form.setValue(
				'user',
				users.filter(x => x._id === certification?.user_id)[0]
			)
		}
		
		if(certification?.role_id)
		{
			form.setValue(
				'role',
				roles.filter(x => x._id === certification?.role_id)[0]
			)
		}
		
		if(certification?.asset_id)
		{
			form.setValue(
				'asset',
				assets.filter(x => x._id === certification?.asset_id)[0]
			)
		}
		
	}, [certification, users, assets, roles])
	
	
	
	
	
	
	// Maybe error?
	const certification_data = 
	{
		all_certifications: all_certifications,
		certification: certification,
		
		users: users || [],
		assets: assets || [],
		roles: roles || [],
		
		
		handleSubmit: handleSubmit,
	}
	
	
	return (
		<FormProvider {...form}>
			<CertificationDataContext.Provider
				value = {certification_data}
			>
				<form
					onSubmit={handleSubmit}
				>
					{children}
				</form>
			</CertificationDataContext.Provider>
		</FormProvider>
	)
}


export default CertificationDataProvider;
import { useState } from 'react';

import { Group } from '@visx/group';
import { hierarchy, Tree } from '@visx/hierarchy';
import { LinearGradient } from '@visx/gradient';

import TreeSettings from './TreeSettings';
import { useTree } from './TreeProvider';
import TreeNodeBox from './TreeNodeBox';
import TreeInfo from './TreeInfo';
import { tree_default_margin, tree_link_color } from '../tree_config';

import getLinkComponent from '../getLinkComponent';



export type LinkTypesProps =
{
	width: number;
	height: number;
	margin?: { top: number; right: number; bottom: number; left: number };
};


export default function TreeHierarchy({
	width: total_width,
	height: total_height,
	margin = tree_default_margin,
}
: LinkTypesProps)
{
	const tree_context = useTree();
	
	
	let tree_root = tree_context.nodes[0];
	
	
	
	const [ layout, setLayout ] = useState<string>('cartesian');
	const [ orientation, setOrientation ] = useState<string>('horizontal');
	const [ linkType, setLinkType ] = useState<string>('diagonal');
	const [ stepPercent, setStepPercent ] = useState<number>(0.5);
	
	
	const inner_width  = total_width  - margin.left - margin.right;
	const inner_height = total_height - margin.top  - margin.bottom;
	
	let origin: { x: number; y: number };
	let size_width: number;
	let size_height: number;
	
	
	if (layout === 'polar')
	{
		origin = {
			x: inner_width / 2,
			y: inner_height / 2,
		};
		
		size_width = 2 * Math.PI;
		size_height = Math.min(inner_width, inner_height) / 2;
	}
	else
	{
		origin = { x: 0, y: 0 };
		
		if (orientation === 'vertical')
		{
			size_width  = inner_width;
			size_height = inner_height;
		}
		else
		{
			size_width  = inner_height;
			size_height = inner_width;
		}
	}
	
	
	const LinkComponent = getLinkComponent({ layout, linkType, orientation });
	
	
	return (total_width < 10) ? null : (
		<div>
			
			<TreeSettings
				layout={layout}
				orientation={orientation}
				linkType={linkType}
				stepPercent={stepPercent}
				setLayout={setLayout}
				setOrientation={setOrientation}
				setLinkType={setLinkType}
				setStepPercent={setStepPercent}
			/>
			
			<svg width={total_width} height={total_height}>
				
				<LinearGradient
					id='links-gradient'
					from='#faa'
					to='#faa'
				/>
				
				<rect
					width={total_width}
					height={total_height}
					rx={14}
					fill='#272b4d'
				/>
				
				<Group top={margin.top} left={margin.left}>
					<Tree
						root={hierarchy(tree_root, (d) => (d.is_expanded ? null : d.children))}
						size={[size_width, size_height]}
						// separation={(a, b) => 0.1}
						separation={(a, b) => (a.parent === b.parent) ? 1 : 2}
					>
						{(tree) => (
							<Group top={origin.y} left={origin.x}>
								{tree.links().map((link, i) => (
									<LinkComponent
										key={i}
										data={link}
										percent={stepPercent}
										stroke={tree_link_color}
										strokeWidth='1'
										fill='none'
									/>
								))}
								
								{tree.descendants().map((node) =>
								{
									// console.log(node, node.data);
									
									return (
										<TreeNodeBox
											node={node}
											key={node.data._id}
											layout={layout}
											orientation={orientation}
										/>
									);
								}
								)}
							</Group>
						)}
					</Tree>
				</Group>
			</svg>
			
			<TreeInfo />
			
		</div>
	);
}
import React, { useState } from 'react';

import { Issue } from 'types';
import { ComponentTreeData } from 'models';
import CorIcon from 'components/icons/CorIcon';

import { cx } from 'stuff';
import { useHover } from 'utils';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
// import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


const ComponentTreeRootNode =
({
	component_tree_data,
	setComponent,
	issues,
	setIsComponentDrawerOpen,
	selectedComponents,
	setSelectedComponents,
}: {
	component_tree_data: ComponentTreeData,
	setComponent?: Function,
	issues: Issue[],
	setIsComponentDrawerOpen?: Function,
	selectedComponents?: ComponentTreeData[],
	setSelectedComponents?: Function,
}) =>
{
	// const [ insertChildComponent, handleInsertChildComponent ] = useState(false);
	const [ isSelected, setIsSelected ] = useState<Boolean>(false);
	
	const { asset_id, system_id, component_id } = useParams();
	
	const [ isHovered, hover_event_handlers ] = useHover();
	
	
	const navigate = useNavigate();
	
	
	const selectComponent = () =>
	{
		if(
			window.location.pathname === `/assets/${asset_id}/systems/${system_id}/components`
			|| window.location.pathname === `/assets/${asset_id}/systems/${system_id}/components/${component_id}`
		)
		{
			setComponent(component_tree_data);
			setIsComponentDrawerOpen(true);
			
			navigate(`/assets/${asset_id}/systems/${system_id}/components/${component_tree_data._id}`);
		}
		else
		{
			if(selectedComponents?.includes(component_tree_data))
			{
				setSelectedComponents(selectedComponents.filter(x => x._id !== component_tree_data._id))
			}
			else
			{
				setSelectedComponents([
					...selectedComponents,
					component_tree_data
				]);
			}
		}
	};
	
	
	
	// if(!component_id) {
	// 	setComponent(component_tree_data);
	// 	navigate(`/assets/${asset_id}/systems/${system_id}/components/${component_tree_data._id}`);
	// };
	
	
	
	
	return (
		<ul
			className={cx(
				'ul_main',
				{
					is_hovered: isHovered,
					is_selected: isSelected,
					is_highlighted: (component_id === component_tree_data._id),
				}
			)}
			onClick={() => selectComponent()}
		>
			<span className={'tree_span'}>
				
				<CorIcon
					of='square_rounded'
					className='tree_root_icon'
				/>
				<Typography
					variant='h3'
				>
					{`[ ${component_tree_data?.maintenance_function_code || component_tree_data?.asset_code} ]`} {component_tree_data.name}
				</Typography>
				
			</span>
			
			{
				(component_tree_data?.open_issue_ids?.length >= 1) && (
					<CorIcon
						of='flag_components'
						className='component_tree_flags_icon'
					/>
				)
			}
		</ul>
	)
};


export default ComponentTreeRootNode;
import React, { ReactNode, useContext } from 'react';

import { cleanSections, ComponentRecord, Issue,
	Recurrence, SimpleComponent, StorageFile, WorkOrderNew, WorkOrderTemplate
} from 'models';
import { Part, Tag, User } from 'types';
import { cuid, DateTime, useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally, useUpdateDocProperty, useUploadFile } from 'ember';
import { where } from 'firebase/firestore';
import { useSession } from 'app/providers/SessionProvider';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Role } from 'models/users/user_interfaces';
import { determineNextAutomationDatetime, ensureLocalDateTime, ensureUtcString } from 'models/recurrence/recurrence_helpers2';
import { DEFAULT_RECURRENCE } from 'pages/CreateWorkOrder/updated/WOTDataProvider';
import { merge } from 'lodash';
import { useToast } from 'app/providers/ToastProvider';



export interface WOTCollabData
{
	wo_template: WorkOrderTemplate;
	components: SimpleComponent[];
	tags: Tag[];
	
	files: StorageFile[];
	other_users: User[];
	parts: Part[];
	related_work_orders: WorkOrderNew[];
	linked_issues: Issue[];
	assigned_to_roles: Role[];
	
	component_records: ComponentRecord[];
	
	
	updateRecurrence?: Function;
	updateWorkSteps?: Function;
	updateAssignedToRoles?: Function;
	uploadFiles?: Function;
};


export interface WOTCollabFormData
{
	title: string;
	recurrence: Recurrence;
	sections: any;
	roles: Role[];
	equipment: SimpleComponent[];
};


// const DEFAULT_RECURRENCE_DATA =
// {
// 	interval: {
// 		years: 0,
// 		months: 0,
// 		weeks: 0,
// 		days: 0,
// 		hours: 0,
// 		minutes: 5,
// 	},
	
// 	effective_datetime: DateTime.local(),
	
// 	time_of_day: {
// 		hour: DateTime.local().hour,
// 		minute: DateTime.local().minute,
// 	},
	
// 	// selected_weekday_nums: [2, 4],
	
// 	recurrence_type: RecurrenceType.INTERVAL,
// 	recurrence_schedule: RecurrenceScheduleType.WEEKLY,
	
// 	weekly: {
// 		weekday_numbers: [],
// 	},
	
// 	monthly: {
// 		day_number: 1,
// 		day_numbers: [],
// 	},
	
// 	yearly: {
// 		yearly_date: {
// 			month: 1,
// 			day: 1,
// 		},
// 		yearly_dates: [],
// 	}
// }


const WOTCollabContext = React.createContext<WOTCollabData>({
	wo_template: null,
	components: [],
	tags: [],
	files: [],
	other_users: [],
	parts: [],
	related_work_orders: [],
	linked_issues: [],
	assigned_to_roles: [],
	component_records: [],
});


export function useWOTCollabData()
{
	return useContext(WOTCollabContext);
}



const WOTCollabProvider =
({
	wo_template,
	children,
} : {
	wo_template: WorkOrderTemplate,
	children: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const { users } = useSession();
	
	const toast = useToast();
	const updateDocProperty = useUpdateDocProperty();
	const uploadFile = useUploadFile();
	
	const datetime = DateTime.utc().toISO();
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	
	// Get Equipment (component) data
	const components_collection = useCollectionConditionally(
		(wo_template?.component_ids?.length > 0),
		`${org_path}/components`,
		where('_id', 'in', wo_template?.component_ids)
	);
	
	// Get Issues that involve any components specified in this Issue
	const related_work_orders_collection = useCollection(
		`${org_path}/work_orders`,
		where('template_id', '==', wo_template?._id)
	);
	
	
	// Get Tags data
	const tags_collection = useCollectionConditionally(
		(wo_template?.tag_ids.length > 0),
		`${org_path}/tags`,
		where('_id', 'in', wo_template?.tag_ids)
	);
	
	
	// Get Files data
	const files_collection = useCollection(
		`${org_path}/files`,
		where('work_order_template_id', '==', wo_template?._id)
	);
	
	const assets_files_collection = useCollectionConditionally(
		(wo_template?.component_ids?.length > 0),
		`${org_path}/files`,
		where('component_id', 'in', wo_template?.component_ids)
	);
	
	
	// Get Parts data from parts needed
	const parts_needed_ids = wo_template?.parts_needed?.map(x => x._id);
	const parts_collection = useCollectionConditionally(
		(wo_template?.parts_needed?.length > 0),
		`${org_path}/parts`,
		where('_id', 'in', parts_needed_ids)
	);
	
	// Get Linked Issues data from parts needed
	const issues_collection = useCollectionConditionally(
		(wo_template?.issue_ids?.length > 0),
		`${org_path}/issues`,
		where('_id', 'in', wo_template?.issue_ids)
	);
	
	
	// Get Roles data from parts needed
	const roles_collection = useCollectionConditionally(
		(wo_template?.assigned_to_role_ids?.length > 0),
		`${org_path}/roles`,
		where('_id', 'in', wo_template?.assigned_to_role_ids)
	);
	
	
	// Get Component Records
	const component_records_collection = useCollection(`organizations/${claims?.organization_id}/component_records`);
	
	const component_records = component_records_collection?.data as ComponentRecord[] || [];
	
	
	
	
	
	// Load data from collections
	const components = components_collection?.data as SimpleComponent[] || [];
	const tags = tags_collection?.data as Tag[] || [];
	const other_users = users?.filter(x => x._id !== claims?.user_id) as User[] || [];
	const parts = parts_collection?.data as Part[] || [];
	const related_work_orders = related_work_orders_collection?.data as WorkOrderNew[] || [];
	const linked_issues = issues_collection?.data as Issue[] || [];
	const assigned_to_roles = roles_collection?.data as Role[] || [];
	
	
	
	
	// Merging issue attachments with Asset files
	const general_attachments = files_collection?.data as StorageFile[] || [];
	const asset_files = assets_files_collection?.data as StorageFile[] || [];
	const files = general_attachments.concat(asset_files);
	
	
	console.log({
		wo_template
	})
	
	
	let recurrence = merge(structuredClone(DEFAULT_RECURRENCE), wo_template.recurrence);
	
	
	// Form Data
	const recurrence_data = {
		...recurrence,
		//DateTime.fromISO(wo_template.recurrence?.effective_datetime?.toString()),
		//DateTime.fromISO(wo_template?.recurrence?.next_automation_datetime?.toString()),
	} as Recurrence;
	
	
	
	
	
	recurrence_data.effective_datetime = ensureLocalDateTime(wo_template.recurrence?.effective_datetime);
	recurrence_data.next_automation_datetime = ensureLocalDateTime(wo_template.recurrence?.next_automation_datetime);
	
	
	
	// console.log('RECURRENCE DATA WOT', {recurrence_data});
	
	const DEFAULT_FORM_DATA: WOTCollabFormData =
	{
		title: wo_template.title,
		recurrence: recurrence_data,
		sections: wo_template.sections,
		roles: assigned_to_roles,
		equipment: components,
	};
	
	const form = useForm<WOTCollabFormData>({
		defaultValues: {
			...DEFAULT_FORM_DATA
		},
	});
	
	const { roles } = form.getValues();
	
	const control = form.control;
	
	const { replace } = useFieldArray({
		control,
		name: 'roles',
	});
	
	
	// console.log('SHOW ME THE ROLES', roles);
	
	// TODO: This breaks the page if loaded directly on the schedule tab
	// useEffect(() =>
	// {
	// 	replace(assigned_to_roles)
	// }, [assigned_to_roles])
	
	
	
	
	
	// Handle form submissions
	// TODO: This should rely on helpers
	const updateRecurrence = () =>
	{
		const { recurrence } = form.getValues();
		
		recurrence.weekly.weekday_numbers = recurrence.weekly.weekday_numbers.filter(x => x !== undefined);
		
		
		// TODO: Helper for ensuring all datetime properties are converted
		let new_recurrence_data : Recurrence = {
			...recurrence,
			effective_datetime: (recurrence.effective_datetime as DateTime)?.toISO(),
			next_automation_datetime: determineNextAutomationDatetime(recurrence)?.toISO() || null,
			effective_until_datetime : ensureUtcString(recurrence.effective_until_datetime),
		};
		
		
		new_recurrence_data.specific_dates.forEach(x => {
			x.datetime = ensureLocalDateTime(x.datetime).toUTC().toISO()
		})
		
		
		updateDocProperty(
			`${org_path}/work_order_templates/${wo_template._id}`,
			'recurrence',
			new_recurrence_data
		).then(() => {
			toast.toastSuccess('Updated ' + wo_template.title);
		});
		
		console.log('RECURRENCE UPDATED', {new_recurrence_data});
	};
	
	
	const updateWorkSteps = () =>
	{
		const { sections } = form.getValues();
		
		const cleaned_sections = cleanSections(sections);
		
		updateDocProperty(
			`${org_path}/work_order_templates/${wo_template._id}`,
			'sections',
			cleaned_sections
		);
		
		console.log('SECTIONS UPDATED', cleaned_sections);
	};
	
	
	const updateAssignedToRoles = () =>
	{
		const { roles } = form.getValues();
		
		console.log({roles});
		
		const assigned_to_role_ids = roles.map(x => x._id);
		
		updateDocProperty(
			`${org_path}/work_order_templates/${wo_template._id}`,
			'assigned_to_role_ids',
			assigned_to_role_ids
		);
		
		console.log('SECTIONS UPDATED', assigned_to_role_ids);
	};
	
	
	const uploadFiles = (file_list: FileList | null) =>
	{
		if(file_list != null)
		{
			const files: File[] = Array.from( file_list );
			
			if(files.length)
			{
				files?.forEach(file_to_upload =>
				{
					let file_id = cuid();
					
					
					let file : StorageFile =
					{
						_id: file_id,
						name: file_to_upload.name,
						path: `organizations/${claims?.organization_id}/files/${file_id}`,
						size: file_to_upload.size,
						type: file_to_upload.type,
						upload_date: datetime,
						work_order_template_id: wo_template._id,
						last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
					}
					
					
					uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				})
			}
			
			console.log(files);
		}
	};
	
	
	
	
	const work_order_template_data: WOTCollabData =
	{
		wo_template: wo_template,
		components: components,
		tags: tags,
		files: files,
		other_users: other_users,
		parts: parts,
		related_work_orders: related_work_orders,
		linked_issues: linked_issues,
		assigned_to_roles: assigned_to_roles,
		component_records: component_records,
		
		updateRecurrence: updateRecurrence,
		updateWorkSteps: updateWorkSteps,
		updateAssignedToRoles: updateAssignedToRoles,
		uploadFiles: uploadFiles,
	};
	
	
	
	
	return (
		<FormProvider {...form}>
			<WOTCollabContext.Provider value={work_order_template_data}>
				{children}
			</WOTCollabContext.Provider>
		</FormProvider>
	)
};


export default WOTCollabProvider;
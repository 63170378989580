import { Box } from '@mui/material';
import { DateTime, Info } from 'luxon';
import { cx } from 'stuff';
import { useCalendar } from './CalendarProvider';

import CalendarWeek from './CalendarWeek';


const CalendarWeekStack = () =>
{
	const { selected_datetime: selected_day, focus_week, weeks, show_week_num } = useCalendar();
	
	
	let day_names = Info.weekdays('short');
	
	
	return <Box
		sx={{
			width: 'fit-content',
			display: 'grid',
			gridTemplateColumns: (show_week_num)
				? '1.5fr repeat(7, 1fr) 1.5fr' : '1.5fr repeat(7, 1fr)',
			maxWidth: '100%',
			overflowX: 'scroll',
		}}
	>
		<div
			className='calendar3_year'
		>
			{selected_day?.year || focus_week?.start.year || DateTime.local().year}
		</div>
		
		{day_names.map((name, i) =>
			<div
				className={cx('calendar3_day_of_week', {
					weekend: (i > 4)
				})}
				key={name}
			>
				{name}
			</div>
		)}
		
		
		{
			(show_week_num) &&
				<div
					className='calendar3_week_label'
				>
					Week
				</div>
		}
		
		
		{weeks.map(week =>
			<CalendarWeek
				week={week}
				key={week.start.toISO()}
			/>
		)}
		
	</Box>
}


export default CalendarWeekStack;
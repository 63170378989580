import Avatar from '@mui/material/Avatar';


export interface ChatAvatarProps
{
	_src: string;
	_username: string;
};


const ChatAvatar =
({
	_src,
	_username,
}
: ChatAvatarProps) =>
{
	return (
		<div>
			<Avatar
				alt={ _username }
				src={ _src }
			/>
		</div>
	)
};


export default ChatAvatar;
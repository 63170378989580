import { NavLink } from 'react-router-dom';
import { useAuthData } from 'stuff';

import { Box, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import CorIcon from 'components/icons/CorIcon';

// import { useNavTheme } from './styles/navTheme';


const BottomNavDrawer = (props: { isMobileDrawerOpen: boolean, toggleDrawer: any }) =>
{
	const { isMobileDrawerOpen, toggleDrawer } = props;
	
	// const classes = useNavTheme();
	
	const { logOut } = useAuthData();
	
	
	let mobile_drawer_items =
	[
		{ name: 'Assets', icon: 'asset_tree' },
		{ name: 'Certifications', icon: 'certificate'},
		{ name: 'Data Analytics', icon: 'data_analytics'},
		{ name: 'Create Issue', icon: 'issue' },
		{ name: 'Create Work Order', icon: 'work_order', path: '/work-orders/create-template' },
		{ name: 'Create Requisition', icon: 'create_requisition' },
		{ name: 'Settings', icon: 'setting' },
	];
	
	
	return (
		<Drawer
			anchor='bottom'
			open={isMobileDrawerOpen}
			onClose={toggleDrawer}
			className='mobile_nav_drawer'
		>
			
			<Box className='mobile_nav_drawer_header'>
				
				<Typography
					variant='h3'
				>
					Navigate To
				</Typography>
				
				<IconButton onClick={toggleDrawer}>
					<CorIcon of='close' size='25px'/>
				</IconButton>
				
			</Box>
			
			
			<List>
				{
					mobile_drawer_items.map((item, index) =>
					{
						const navLinkURL = item.path
							||
							'/' + item.name.toLowerCase().split(' ').join('-');
						
						return (
							<ListItem key={index} sx={{ padding: '4px' }}>
								<ListItemButton 
									component={NavLink}
									to={navLinkURL}
									onClick={() => toggleDrawer()}
								>
									<ListItemIcon>
										<CorIcon
											of={item.icon}
											size='25px'
											className='mobile_nav_icons'
										/>
										<ListItemText
											primary={item.name}
										/>
									</ListItemIcon>
								</ListItemButton>
							</ListItem>
						)
					})
				}
				
				<Divider
					orientation='horizontal'
					sx={{
						margin: '0 5px',
					}}
				/>
				
				<ListItem sx={{ padding: '4px' }}>
					<ListItemButton
						component={NavLink}
						to={'./'}
						onClick={() => logOut()}
					>
						<ListItemIcon>
							<CorIcon
								of='logout'
								size='25px'
								className='mobile_nav_icons'
							/>
							<ListItemText
								primary='Logout'
							/>
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
				
			</List>
			
		</Drawer>
	)
}


export default BottomNavDrawer;
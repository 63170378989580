import { Recurrence, RecurrenceEffectiveType, RecurrenceScheduleType } from 'models';
import { Controller, useFormContext } from 'react-hook-form';
import EnumToggleField from 'app/gizmos/fields/toggles/EnumToggleField';
import Formlet from '../fields/Formlet';
import { Stack } from '@mui/material';
import ConciseDateAndTime from '../fields/datetimes/ConciseDateAndTime';


// Choose between Immediately, Delay Until, and Effective Between
export const EffectiveTypeFormlet =
({
	label = 'Effective Type',
	name = 'recurrence.effective_type',
} : {
	label?: string,
	name?: string,
}) =>
{
	let form_context = useFormContext();
	
	// let recurrence = form_context.watch('recurrence') as Recurrence;
	
	
	// if(recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
	// {
	// 	return null;
	// }
	
	
	return (
		<Formlet
			label={label}
			help='Allows you to delay work order generation'
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<EnumToggleField
						url_param_key='effective'
						enum_type={RecurrenceEffectiveType}
						value={value}
						onChange={onChange}
					/>
				}
			/>
			{
				(form_context.watch('recurrence.effective_type') === RecurrenceEffectiveType.START_IMMEDIATELY)
				&&
				<Stack
					sx={{
						textAlign: 'center',
						marginTop: 2,
					}}
				>
					<ConciseDateAndTime
						datetime={form_context.watch('recurrence.effective_datetime')}
					/>
				</Stack>
			}
		</Formlet>
	);
}


export default EffectiveTypeFormlet;
import { Stack } from '@mui/material';

import { Body, IssueLog, ChatUser } from '../../interfaces/content_data_interfaces';

import ContentAvatar from './captions/ChatAvatar';
import ContentHeadline from './captions/Headline';
import ContentComment from './content/Comment';
import ContentAddedCollaborator from './content/AddCollaborator';
import ContentRequestedPO from './content/RequestedPO';
import ContentTitle from './content/IssueTitle';

const cn : string = 'chatLog';


const ChatLogBody =
({
	body,
	collaborators,
} : {
	body: Body,
	collaborators : ChatUser[],
}) =>
{
	if( body.purchase_part !== undefined )
	{
		return (
			<ContentRequestedPO
				_headline={ body.purchase_part.title }
				_status={ body.purchase_part.status }
			/>
		);
	}
	else if ( body.new_collaborator !== undefined )
	{
		return (
			<ContentAddedCollaborator
				_username={ body.new_collaborator?.username }
				_position={
					(body.new_collaborator?.position !== undefined)
						? body.new_collaborator.position
						: ''
				}
			/>
		);
	}
	else if ( body.comment !== undefined )
	{
		return (
			<ContentComment
				_description={ body.comment }
				_collaborators={ collaborators }
				_file={ body.file }
			/>
		);
	}
	else
	{
		// TODO: Components must return something - how to handle an empty comment?
		return (
			<ContentComment
				_description={ ['-------NO DESCRIPTION---------'] }
				_collaborators={ collaborators }
				_file={ body.file }
			/>
		);
	}
};








export interface ChatLogProps
{
	issue_log: IssueLog[];
	collaborators: ChatUser[];
	issue_tracking: string;
	tracking_number: string;
};




const ChatLog =
({
	issue_log,
	collaborators,
	issue_tracking,
	tracking_number,
}
: ChatLogProps) =>
{
	console.log(issue_log);
	
	
	return (
		<div>
			{
				issue_log[0] !== undefined &&
				issue_log[0].body.title !== undefined &&
				issue_log[0].body.status !== undefined &&
				issue_log[0].body.details !== undefined
				? (
					<ContentTitle
						title={ issue_log[0].body.title }
						_badges={ issue_log[0].body.status }
						_reportDetails={ issue_log[0].body.details }
						_issue_tracking={ issue_tracking }
						_tracking_number={ tracking_number }
					/>
				) 
				: null
			}
			
			{
				issue_log.map(( content, key ) => {
					
					return key !== 0 
					? (
						<div 
							className={ cn + '__chatContent' } 
							key={ key }
						>
							
							<ContentAvatar 
								_src={ content.headline.user.profilePic }
								_username={ content.headline.user.username }
							/>
							
							<Stack 
								className={ cn + '__chatContent__chatStack' }
							>
								
								<ContentHeadline
									_username={ content.headline.user.username }
									_dateTime={ content.headline.timestamp }
									title={ 
										content.headline.title !== undefined 
										? content.headline.title
										: ''
									}
								/>
								
								<ChatLogBody
									body={content.body}
									collaborators={collaborators}
								/>
								
							</Stack>
						</div>
					)
					: null
				})
			}
		</div>
	)
}


export default ChatLog;
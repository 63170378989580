import { useEffect, useMemo } from 'react';

import { useSession } from 'app/providers/SessionProvider';
import { Role, TagRole } from 'models/users/user_interfaces';
import { Chip, Grid } from '@mui/material';


const TagRoles =
({
	tag_roles,
	setSelectedRoles,
} : {
	tag_roles: TagRole,
	setSelectedRoles: React.Dispatch<React.SetStateAction<Role[]>>,
}) =>
{
	const { roles } = useSession();
	
	
	const filtered_roles: Role[] = useMemo(
		() =>
		{
			return (tag_roles !== undefined)
				? roles?.filter(role => tag_roles[role._id])
				: []
		},
		[roles, tag_roles]
	)
	
	
	useEffect(() =>
	{
		setSelectedRoles(filtered_roles)
	}, [ filtered_roles, setSelectedRoles, tag_roles ])
	
	
	return (
		<Grid
			container
			gap={1}
		>
			{
				filtered_roles && filtered_roles?.map(role =>
					<Grid
						key={role._id}
						item
					>
						<Chip
							label={role.name}
							sx={{
								marginTop: '5px'
							}}
							variant='outlined'
						/>
					</Grid>
				)
			}
		</Grid>
	)
}


export default TagRoles;
import React, { useEffect, useState } from 'react';

import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';
import EventCard from './EventCard';
import { Event } from 'types';
import { useCalendar } from '../CalendarProvider';


const CalendarEvents = () =>
{
	const { events_on_day } = useCalendar();
	
	
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	const [ filtered_events, setFilteredEvents ] = useState<Event[]>(events_on_day);
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	useEffect(() =>
	{
		setFilteredEvents(events_on_day);
	}, [events_on_day])
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
			width='100%'
			sx={{
				padding: '20px'
			}}
		>
			<SearchBarOld
				items={events_on_day}
				handleItems={setFilteredEvents}
				fields={['name']}
			/>
			{
				filtered_events?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((event: Event) => (
					<EventCard key={event._id} event={event} />
				))
			}
			<Pagination
				count={Math.ceil(events_on_day.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default CalendarEvents;
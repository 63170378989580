


// Utility to convert a Firestore snapshot into a list of items
// Also sets the _id property to be the document ID, if it's not already included
export const snap2array = (snap) =>
{
	let items = [];
	
	snap.forEach((doc) =>
	{
		//console.log('   ', doc.id, " => ", doc.data());
		
		items.push(Object.assign({_id: doc.id}, doc.data()));
	});
	
	return items;
}



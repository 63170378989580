import { Controller } from 'react-hook-form';
import EnumToggleField from 'app/gizmos/fields/toggles/EnumToggleField';
import Formlet from 'app/gizmos/fields/Formlet';


export const EnumToggleFormlet =
({
	name,
	label,
	help,
	enum_type,
} : {
	name: string,
	label: string,
	help?: string,
	enum_type?: any,
}) =>
{
	return (
		<Formlet
			label={label}
			help={help}
		>
			
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<EnumToggleField
						url_param_key={name}
						enum_type={enum_type}
						value={value}
						onChange={onChange}
					/>
				}
			/>
		</Formlet>
	);
}


export default EnumToggleFormlet;
import { useFormContext } from 'react-hook-form';

import { Box, Divider, Stack } from '@mui/material';

import DescriptionField from 'app/gizmos/fields/text/DescriptionFormlet';
import TitleFormlet from 'app/gizmos/fields/text/TitleFormlet';

import { Attachments } from '../GeneralDescription/Attachments/Attachments';
import Collaborators from '../GeneralDescription/Collaborators/Collaborators';
import { Equipments } from '../GeneralDescription/Equipments/Equipments';
import LinkIssues from '../GeneralDescription/Issues/LinkIssues';
import Parts from '../GeneralDescription/Parts/Parts';
import { Tags } from '../GeneralDescription/Tags/Tags';
import WOTypeSelect from '../GeneralDescription/WOTypeSelect/WOTypeSelect';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import AssignRoles from '../GeneralDescription/Roles/AssignRoles';

 
const WOTStep1 = () =>
{
	const form_context = useFormContext();
	
	const control = form_context.control;
	
	
	return (
		<Stack
			padding={3}
			gap={2}
		>
			
			<TitleFormlet />
			
			<DescriptionField />
			
			<CostFormlet
				name='estimated_cost'
				label='Estimated Cost'
				help='Estimate expected cost - actual cost may be provided later'
			/>
			
			
			
			
			<Stack
				padding={3}
				gap={2}
			>
				<WOTypeSelect
					control={control}
				/>
				
				<AssignRoles control={control} />
				
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '3rem',
					}}
				>
					<Tags
						control={control}
					/>
				</Box>
				
				<Stack
					direction='column'
					justifyContent='flex-start'
					alignItems='flex-start'
					gap={2}
					width={'100%'}
				>
					<Equipments
						control={control}
					/>
					
					<Divider />
					
					<Parts
						control={control}
					/>
				</Stack>
				
				<Divider />
				
				<LinkIssues
					control={control}
				/>
				
				<Attachments
					control={control}
				/>
				
				<Collaborators
					control={control}
				/>
				
			</Stack>
			
		</Stack>
	);
};


export default WOTStep1;
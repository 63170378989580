import React, { useState, useEffect } from 'react';

import { useMapData } from './MapDataProvider';
import MuiIcon from 'components/icons/MuiIcon';
import { fullDateAndTimeText } from 'utils/datetime_utils_old';


const TripAnalysisCard =
({
	trip,
	color,
} : {
	trip: any,
	color: string,
}) =>
{
	const { trip_responses } = useMapData();
	
	
	const comparing_trips = trip_responses.filter(x => x.request.speed !== trip.request.speed);
	
	const [ performance_comparisons, setPerformanceComparisons ] = useState<any>({})
	
	
	const comparingFuelUsed = () =>
	{
		const fuel_used_options = [];
		
		comparing_trips.forEach(x => {
			const fuel_used_gap = x.routes[0].summaries.performance.fuelUsed.total.toFixed(2);
			
			fuel_used_options.push(fuel_used_gap);
		});
		
		console.log('FUEL COMPARISONS', fuel_used_options);
		
		if(fuel_used_options[0] <= fuel_used_options[1])
		{
			if(fuel_used_options[0] <= trip.routes[0].summaries.performance.fuelUsed.total.toFixed(2))
			{
				const fuel_used_diff = fuel_used_options[0] - trip.routes[0].summaries.performance.fuelUsed.total.toFixed(2);
				
				setPerformanceComparisons({
					...performance_comparisons,
					fuel_used_diff: fuel_used_diff
				})
			}
			else
			{
				setPerformanceComparisons({
					...performance_comparisons,
					fuel_used_diff: 'best value'
				})
			}
		}
		else
		{
			if(fuel_used_options[1] <= trip.routes[0].summaries.performance.fuelUsed.total.toFixed(2))
			{
				const fuel_used_diff = fuel_used_options[1] - trip.routes[0].summaries.performance.fuelUsed.total.toFixed(2);
				
				setPerformanceComparisons({
					...performance_comparisons,
					fuel_used_diff: fuel_used_diff
				})
			}
			else
			{
				setPerformanceComparisons({
					...performance_comparisons,
					fuel_used_diff: 'best value'
				})
			}
		}
	};
	
	
	
	
	
	useEffect(() =>
	{
		comparingFuelUsed();
	}, [])
	
	
	
	
	console.log({comparing_trips});
	
	
	return (
		<div className='trip_analysis_card'>
			
			<div className='trip_card_sections'>
				<div
					className='trip_card_section_headers'
					style={{background: color}}
				>
					Trip Summary
				</div>
				<div className='trip_summary_section'>
					<div className='trip_data'>
						Estimated Distance (nm)
						<p>{trip.routes[0].summaries.performance.distance.total.toFixed(2)}</p>
					</div>
					<div className='trip_data'>
						Instructed Speed (kn)
						<p>{trip.request.speed}</p>
					</div>
					<div className='trip_data'>
						ETD <p>{fullDateAndTimeText(trip.request.etd)}</p>
						{''} ➡ {''}
						ETA <p>{fullDateAndTimeText(trip.routes[0].eta)}</p>
					</div>
				</div>
			</div>
			
			<div className='trip_card_sections'>
				<div
					className='trip_card_section_headers'
					style={{background: color}}
				>
					Performance Summary
				</div>
				<div className='trip_performance_section'>
					<div className='trip_data'>
						<MuiIcon of='fuel'className='fuel_icon'/>
						Fuel Used (t) -
						<p>{trip.routes[0].summaries.performance.fuelUsed.total.toFixed(2)}</p>
						{/* {
							(performance_comparisons?.fuel_used_diff) ? (
								<div>{performance_comparisons?.fuel_used_diff}</div>
							) : (
								<div>{performance_comparisons?.fuel_used_diff}</div>
							)
						} */}
					</div>
					<div className='trip_data'>
						<MuiIcon of='cost' className='cost_icon'/>
						Fuel Cost -
						<p>{trip.routes[0].summaries.performance.fuelCost.total.toFixed(2)}</p>
					</div>
					<div className='trip_data'>
						<MuiIcon of='co2' className='co2_icon'/>
						Emissions (t) -
						<p>{trip.routes[0].summaries.performance.co2Emissions.total.toFixed(2)}</p>
					</div>
					<div className='trip_data'>
						<MuiIcon of='timer' className='timer_icon'/>
						Time Elapsed (s) -
						<p>{trip.routes[0].summaries.performance.time.total.toFixed(2)}</p>
					</div>
				</div>
			</div>
			
			
			<div className='trip_card_sections'>
				<div
					className='trip_card_section_headers'
					style={{background: color}}
				>
					Weather Warnings
				</div>
				<div className='trip_weather_section'>
					{
						trip.routes[0].summaries.weather.elements.map((element, index) =>
							{
								return (
									<div key={index} className='trip_card_weather'>
										<div className='trip_data'>
											{element.element}
										</div>
										<div className='trip_data'>
											max
											<p>{element.max.toFixed(2)}</p>
										</div>
										<div className='trip_data'>
											avg
											<p>{element.avg.toFixed(2)}</p>
										</div>
									</div>
								)
							})
					}
				</div>
			</div>
			
		</div>
	)
};


export default TripAnalysisCard;
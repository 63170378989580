import { ListItem } from '@mui/material';
import MuiIcon from 'components/icons/MuiIcon';
import ImageOrError from 'components/images/ImageOrError';
import { getDownloadURL, ref } from 'firebase/storage';
import { StorageFile } from 'models';
import ClickForDownloadDialog from 'app/widgets/dialogs/ClickForDownloadDialog';
import { useEffect, useState } from 'react';
import { useStorage } from 'reactfire';


const FileListItem =
({
	file
} : {
	file : StorageFile
}
) =>
{
	const [ thumb_url, setThumbUrl ] = useState('');
	
	const storage = useStorage();
	
	
	
	// TODO: Delay not working as intended
	useEffect(
		() =>
		{
			// var retry_delay = 2000;
			// var max_retries = 1;
			
			
			// const delay = (t) =>
			// {
			// 	return new Promise(function(resolve) {
			// 		setTimeout(resolve, t);
			// 	});
			// }
			
			
			// const retry = async (fn, remaining_retries = max_retries, err = null) =>
			// {
			// 	if(!remaining_retries)
			// 	{
			// 		return Promise.reject(err);
			// 	}
			// 	return fn()?.catch(async err => {
					
			// 		return retry(
			// 			async () => {
			// 				await delay(retry_delay);
			// 				fn();
			// 			},
			// 			(remaining_retries - 1),
			// 			err
			// 		);
			// 	});
			// }
			
			
			const attempt = () =>
			{
				getDownloadURL(ref(storage, file.path + '_thumb'))
					.then((url) =>
					{
						setThumbUrl(url);
					})
			}
			
			
			attempt();
			
			//retry(attempt, max_retries);
		},
		[file.path, storage]
	)
	
	
	
	
	// function rejectDelay(reason)
	// {
	// 	return new Promise(function(resolve, reject)
	// 	{
	// 		setTimeout(reject.bind(null, reason), t);
	// 	});
	// }
	
	
	// const attemptToLoadThumb = () =>
	// {
	// 	// Asynchronously sets the download href (pretty much instantly)
	// 	getDownloadURL(ref(storage, file.path + '_thumb'))
	// 		// .then((url) =>
	// 		// {
	// 		// 	setThumbUrl(url);
	// 		// })
	// 		// .catch((error) =>
	// 		// {
	// 		// 	// Handle any errors
	// 		// 	setTimeout(
	// 		// 		() => {
	// 		// 			// Asynchronously sets the download href (pretty much instantly)
	// 		// 			getDownloadURL(ref(storage, file.path + '_thumb'))
	// 		// 				.then((url) =>
	// 		// 				{
	// 		// 					setThumbUrl(url);
	// 		// 				})
	// 		// 				.catch((error) =>
	// 		// 				{
	// 		// 					// Handle any errors
	// 		// 				});
	// 		// 		},
	// 		// 		5000
	// 		// 	)
	// 		// });
	// }
	
	// function processResult(res)
	// {
	// 	console.log(res);
		
	// 	setThumbUrl(res);
	// }
	
	// function errorHandler(err)
	// {
	// 	console.error(err);
	// }
	
	
	
	// var p = Promise.reject();
	
	// for(var i = 0; i < max; i++)
	// {
	// 	p = p.catch(attemptToLoadThumb).catch(rejectDelay);
	// }
	
	// p = p.then(processResult).catch(errorHandler);
	
	
	
	
	
	if(thumb_url)
	{
		var thumb = <ImageOrError
			path={thumb_url}
			className='thumb'
			title={`Thumbnail: ${file.name}`}
		/>
	}
	
	
	return (
		<ClickForDownloadDialog
			file={file}
			key={file?._id}
		>
			
			<ListItem
				button
				className='file_list_item'
			>
				<MuiIcon
					of='file_attached'
					className='file_attached_icon'
				/>
				
				<div
					className='file_list_item_type'
					key={file._id}
				>
					{file.type.split('/').pop().toUpperCase()}
				</div>
				
				{thumb}
				
				<div
					className='file_list_item_name'
				>
					{file.name}
				</div>
			</ListItem>
			
		</ClickForDownloadDialog>
	)
}


export default FileListItem;
import { Chip, CircularProgress, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useState } from 'react';

import CorIcon from 'components/icons/CorIcon';
import { useDelete, useDoc, useWrite } from 'ember';
import { numberWithCommas, useAuthData } from 'stuff';
import { CostCenter } from 'models/costs';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import CostCenterForm from './CostCenterForm';


const CostCenterCard = ({
	cost_center,
} : {
	cost_center: CostCenter,
}) =>
{
	const { claims } = useAuthData();
	
	const deleteDoc = useDelete();
	const write = useWrite();
	
	const [ closeDialog, setCloseDialog ] = useState(false);
	
	
	const org_collection_path = `organizations/${claims?.organization_id}`;
	
	
	
	
	return (
		<Stack
			width='100%'
			direction='column'
			alignItems='flex-start'
			justifyContent='center'
			spacing={3}
		>
			<Stack
				width='100%'
				direction='row'
				alignItems='center'
				justifyContent='space-between'
			>
				<Typography
					variant='h3'
				>
					{ cost_center.description }
				</Typography>
				
				<Stack
					direction='row'
					spacing={1}
					alignItems='center'
				>
					<ButtonToOpenDialog
						button_type='icon_button'
						dialog_title='Edit your Cost Center'
						dialog_content={
							<CostCenterForm
								closeDialog={closeDialog}
								setCloseDialog={setCloseDialog}
								cost_center={cost_center}
							/>
						}
						buttonIcon={<CorIcon of='edit' size='24px' />}
						closeDialog={closeDialog}
						dialogClassName='add_tags_dialog'
					/>
					
					<IconButton
						onClick={() => console.log('Yo')}
					>
						<CorIcon of='delete' size='24px' />
					</IconButton>
				</Stack>
			</Stack>
			
			<Stack
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='center'
				spacing={2}
			>
				<Grid
					container
					gap={2}
					maxWidth='80%'
				>
					{
						cost_center.centers?.map(center =>
							<Grid key={center._id}>
								<Chip label={center.name} variant='outlined'/>
							</Grid>
						)
					}
				</Grid>
				
				
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='center'
					gap={1}
					sx={{
						padding: '5px',
						borderRadius: '5px',
						background: defaultAppTheme.palette.warning.light,
						width: 'fit-content'
					}}
				>
					<CorIcon
						of='cost_outline'
						size='24px'
					/>
					<Typography
						variant='h5'
						sx={{
							margin: 0,
							padding: 0,
							lineHeight: 0,
						}}
					>
						Budget: {numberWithCommas(cost_center.allocated_budget.quantity)} {cost_center.allocated_budget.currency}
					</Typography>
				</Stack>
				
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='center'
					gap={1}
					sx={{
						padding: '5px',
						borderRadius: '5px',
						background: defaultAppTheme.palette.primary.light,
						width: 'fit-content'
					}}
				>
					<CorIcon
						of='timeline'
						size='24px'
					/>
					<Typography
						variant='h5'
						sx={{
							margin: 0,
							padding: 0,
							lineHeight: 0,
						}}
					>
						Resets: {cost_center.periodicity}
					</Typography>
				</Stack>
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
			
		</Stack>
	)
};


export default CostCenterCard;
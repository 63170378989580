import { useEffect } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import FillableSection from './workOrderFromSection/FillableSection';


export interface SectionsProps
{
	form: UseFormReturn<any>;
}


export const WorkOrderFormSections = ({ form }: SectionsProps) =>
{
	const { control } = form;
	
	
	const { fields } = useFieldArray({
		control,
		name: 'sections',
	});
	
	
	
	return (
		<Box
			sx={{
				// marginInline: 'auto',
				maxWidth: '800px',
				paddingBottom: '2rem',
				width: '100%',
				alignSelf: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				{
					fields.map((field, index) => (
						<FillableSection
							key={field.id}
							control={control}
							index={index}
							form={form}
						/>
					))
				}
			</Box>
		</Box>
	);
};


export default WorkOrderFormSections;
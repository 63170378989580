import { Box, CircularProgress, Stack } from '@mui/material';
import AttachFilesButton from 'components/buttons/AttachFilesButton';
import CorIcon from 'components/icons/CorIcon';
import { useCollection, useDoc, useUploadFile } from 'ember';
import { useNavigate, useParams } from 'react-router';
import { useAuthData } from 'stuff';
import ComponentInfoCard from '../../ComponentInfoCard';
import { CurrentComponent } from 'models'
import cuid from 'cuid';
import { StorageFile } from 'models';
import { DateTime } from 'stuff';
import { where } from 'firebase/firestore';
import FilesTable from 'app/dashboard/pages/issueCollaboration/issueDetails/FilesTable';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


const ComponentTechnicalDocumentationPage = () =>
{
	const { claims } = useAuthData();
	const navigate = useNavigate();
	
	const { asset_id, system_id, component_id } = useParams();
	
	const uploadFile = useUploadFile();
	
	
	const components_collection_path = `organizations/${claims?.organization_id}/components`;
	const component_doc = useDoc(components_collection_path, component_id);
	
	// Files collection filtered with component id
	const files_collection_path = `organizations/${claims?.organization_id}/files`;
	const component_files_collection = useCollection(files_collection_path, where('component_id', '==', component_id));
	
	
	if(
		component_doc.is_loading ||
		component_files_collection.is_loading
	) return <CircularProgress />
	
	
	const component_data = component_doc.data as CurrentComponent;
	const component_files = component_files_collection.data as StorageFile[];
	
	let datetime = DateTime.utc().toISO();
	
	
	
	
	// Convert FileList to array
	const fileChangeHandler = ( file_list: FileList | null) =>
	{
		// console.log(event);
		
		if(file_list != null)
		{
			const files: File[] = Array.from( file_list );
			
			if(files.length)
			{
				files?.forEach(file_to_upload =>
				{
					let file_id = cuid();
					
					
					let file : StorageFile =
					{
						_id: file_id,
						folder_id: component_id,
						name: file_to_upload.name,
						path: `organizations/${claims.organization_id}/files/${file_id}`,
						size: file_to_upload.size,
						type: file_to_upload.type,
						upload_date: datetime,
						asset_id: asset_id,
						system_id: system_id,
						component_id: component_id,
						file_category: 'ASSET',
						last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
					}
					
					
					uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				})
			}
			
			console.log(files);
		}
	};
	
	
	
	return (
		<Stack
			sx={{
				margin: '10px',
				background: '#fff',
				padding: '10px',
				borderRadius: '5px',
			}}
			spacing={2}
		>
			
			<Stack
				justifyContent='flex-end'
				spacing={3}
			>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<CorIcon
						of='back'
						size='40px'
						color={defaultAppTheme.palette.primary.main}
						onClick={() => navigate(-1)}
						cursor='pointer'
					/>
					<ComponentInfoCard component={component_data} />
				</Stack>
				
				<Box>
					<AttachFilesButton
						fileChangeHandler={fileChangeHandler}
						button_text='Upload Files'
						button_variant='contained'
					/>
				</Box>
			</Stack>
			
			<FilesTable files={component_files}/>
			
		</Stack>
	)
};


export default ComponentTechnicalDocumentationPage;
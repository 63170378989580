import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Autocomplete, Checkbox, Chip, ListItem, TextField } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


export interface MultiSelectFieldProps <T,>{

	name?: string,
	label?: string,
	placeholder?: string,
	options: T[],
	getOptionLabel?: (option: T) => string,

}


const MultiSelectField =
<T,>({
	name,
	label,
	placeholder,
	options,
	getOptionLabel = () => '',
	...rest
}
: MultiSelectFieldProps<T>) =>
{
	const form = useForm();
	
	const [ selected_items, setSelectedItems ] = useState([]);
	
	
	const {
		register,
		watch,
	} = form;
	
	
	
	console.log(watch(name));
	
	
	
	// Defaults
	placeholder = placeholder || 'Select ' + name;
	
	
	// Icons
	const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
	const checkedIcon = <CheckBoxIcon fontSize='small' />;
	
	
	
	const onSelectedItemsChange = (event, new_value) =>
	{
		// console.log(event, new_value);
		
		setSelectedItems(new_value);
	}
	
	
	
	return (
		<Autocomplete
			{...register(name)}
			
			disablePortal
			multiple
			disableCloseOnSelect
			
			value={selected_items}
			options={options}
			
			onChange={onSelectedItemsChange}
			getOptionLabel={getOptionLabel}
			
			renderInput={(params) =>
				<TextField
					{...params}
					name={name}
					label={label}
					placeholder={placeholder}
				/>
			}
			
			renderTags={(value, getTagProps) =>
				value.map((option, index: number) =>
					<Chip
						key={option.id}
						variant='filled'
						label={getOptionLabel(option)}
						sx={{
							'&.MuiChip-root': {
								margin: '0 3px',
								// bgcolor: '#0ff',
							},
						}}
						{...getTagProps({
							index,
						})}
					/>
				)
			}
			
			renderOption={(props, option, { selected }) =>
				<ListItem
					{...props}
					key={option.id || option._id}
				>
					<Checkbox
						checked={selected}
						icon={icon}
						checkedIcon={checkedIcon}
						sx={{
							marginRight: '8px',
						}}
					/>
					{getOptionLabel(option)}
				</ListItem >
			}
			
			{...rest}
		/>
	)
}


export default MultiSelectField;
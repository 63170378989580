import { DateTime } from 'luxon';
import cuid from 'cuid';

import { Event } from 'types';
import { Action, ActionStatus, ActionType, RequisitionStatus, StorageFile } from 'models';



export const writeRequisitionDataOnCreation = (
	values,
	write,
	current_user,
	claims,
	uploadFile,
	navigate
) =>
{
	console.log({values});
	
	const {
		_id,
		title,
		estimated_cost,
		final_cost,
		cost_centers,
		cost_codes,
		items,
		files,
		charge_accounts,
		issues,
		work_order_ids,
		tags,
		roles,
		collaborators,
	} = values;
	
	
	let date = DateTime.utc().toISO();
	
	
	uploadFilesRequisition(
		files,
		claims,
		uploadFile,
		values,
		write
	)
	
	const cost_center_ids = cost_centers.map(x => x._id)
	const cost_code_ids = cost_codes.map(x => x._id);
	
	
	createChargeAccountsAction(
		charge_accounts,
		_id,
		current_user,
		write,
		claims,
		estimated_cost,
		cost_center_ids,
		cost_code_ids
	)
	
	
	// Handle Requisition Creation
	const new_requisition_data = {
		_id: _id,
		title: title,
		created_by_user: current_user,
		created_at: date,
		status: RequisitionStatus.STARTED,
		estimated_cost: estimated_cost,
		final_cost: { ...estimated_cost, is_approved: false },
		cost_center_ids: cost_centers?.map(x => x._id),
		cost_code_ids: cost_codes?.map(x => x._id),
		accounts_to_charge_ids: charge_accounts.map(x => x._id),
		is_critical: false,
		items: items,
		work_order_ids: work_order_ids,
		issue_ids: issues?.map(x => x._id),
		tag_ids: tags?.map(x => x._id),
		collaborator_ids: collaborators?.map(x => x._id),
		role_ids: roles?.map(x => x._id),
	};
	
	console.log({new_requisition_data});
	write(`organizations/${claims.organization_id}/requisitions`, new_requisition_data);
	
	navigate(`/requisitions/${_id}`);
};



export const createChargeAccountsAction = (
	charge_accounts,
	requisition_id,
	current_user,
	write,
	claims,
	final_cost,
	cost_center_ids,
	cost_code_ids,
) =>
{
	let date = DateTime.utc().toISO();
	
	if(charge_accounts.length > 0)
	{
		const new_action: Action = {
			_id: cuid(),
			inquiry: 'Need approval to Charge Accounts',
			action_type: ActionType.CHARGE_ACCOUNTS,
			assigned_to: charge_accounts.map(x => x._id),
			created_at: date,
			created_by_user: current_user,
			status: ActionStatus.OPEN,
			
			requisition_id: requisition_id,
			accounts_charged_amount: final_cost,
			cost_center_ids: cost_center_ids,
			cost_code_ids: cost_code_ids,
		};
		
		let new_action_event_text = `${current_user.name} just created a new action item - Need approval to Charge Accounts`
			
		let new_action_event : Event =
		{
			_id: cuid(),
			name: new_action_event_text,
			
			type: 'action',
			subtype: 'create',
			
			datetime: date,
			summary:  new_action_event_text,
			action_id: new_action._id,
			user_id: current_user._id,
			
			requisition_id: requisition_id,
		};
		
		write(`organizations/${claims.organization_id}/events`, new_action_event);
		write(`organizations/${claims.organization_id}/actions`, new_action);
	};
};



export const uploadFilesRequisition = (
	file_list,
	claims,
	uploadFile,
	requisition,
	write,
) =>
{
	let datetime = DateTime.utc().toISO();
	
	// Handle File Attachments
	let attached_files = [];
	
	if(file_list != null)
	{
		const files: File[] = Array.from( file_list );
		
		if(files.length)
		{
			files?.forEach(file_to_upload =>
			{
				let file_id = cuid();
				
				
				let file : StorageFile =
				{
					_id: file_id,
					name: file_to_upload.name,
					path: `organizations/${claims?.organization_id}/files/${file_id}`,
					size: file_to_upload.size,
					type: file_to_upload.type,
					upload_date: datetime,
					requisition_id: requisition._id,
					file_category: 'Receipt',
					last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
				}
				
				
				uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				
				attached_files.push(file);
			})
			
			
			
			
			
		let file_upload_event_text = 'Uploaded ' + files.length
		+ ' file' + ((files.length > 1) ? 's' : '');
		
		let file_upload_event : Event =
		{
			_id: cuid(),
			name: file_upload_event_text,
			
			type: 'file',
			subtype: 'upload',
			
			datetime: datetime,
			summary:  file_upload_event_text,
			requisition_id: requisition._id,
			file_ids: attached_files?.map(x => x._id) || [],
			user_id: claims?.user_id,
		};
		
			write(`organizations/${claims.organization_id}/events`, file_upload_event);
		}
		
		console.log(files);
	}
};



export const calculateTotalEstimatedCost = (parts, sections, form) =>
{
	let parts_cost = 0;
	let sections_cost = 0;
	
	parts?.forEach(part =>
	{
		if((part?.unit_price?.quantity !== '') && (part?.quantity > 0))
		{
			let total_price = Number(part?.unit_price?.quantity)*part?.quantity;
			
			parts_cost+=total_price;
		}
	})
	
	sections?.forEach(section =>
	{
		if((section?.cost?.quantity !== ''))
		{
			let total_price = Number(section?.cost?.quantity)
			
			sections_cost+=total_price;
		}
	});
	
	const total_estimated_cost = ((parts_cost+sections_cost)*1.15).toFixed(2);
	
	
	form.setValue(
		'estimated_cost',
		{
			currency: 'CAD',
			quantity: total_estimated_cost.toString(),
		}
	);
}
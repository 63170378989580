import { useWindowSize } from 'usehooks-ts'

import Box from '@mui/material/Box';

import CompanyHeader from '../CompanyHeader';
import LoginPageForm from './LoginPageForm';
import { Stack } from '@mui/material';
import GetStartedOverlay from './GetStartedOverlay';


const LoginPage = () =>
{
	const { width, height } = useWindowSize();
	
	
	let compact = (width < 555);
	
	
	let content_height = (height < 555)
		? '100%'
		: (width < 555)
			? 'calc(min(80%, 420px))'
			: 'calc(min(80%, 700px))';
	
	
	return (
		<Box
			className='login_page'
			sx={{
				padding: (compact) ? 1 : 2,
			}}
		>
			<Stack
				className='login_page_content'
				spacing={2}
				// margin={(compact) ? 2 : 2}
				sx={{
					// maxWidth: (show_robot) ? '100%' : '60%',
					height: 'fit-content',
					// margin: (compact) ? 1 : 2,
					width: (compact) ? '100%' : 'unset',
					maxWidth: (compact) ? 'unset' : '900px',
					maxHeight: '1300px',
					padding: 2,
					background: '#fafafa',
					borderRadius: 2,
					// marginBottom: 7,
				}}
			>
				
				<CompanyHeader />
				
				<LoginPageForm />
				
			</Stack>
			
			{/* <GetStartedOverlay /> */}
			
		</Box>
	)
}


export default LoginPage;
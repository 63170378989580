import cuid from 'cuid';

import { resolveEntity } from '.';

import Thingo from './Thingo';

const LOG = false;


// Wraps up type handling so we don't have to work directly with POJOs
// The actual data is stored in the values property
// Ex:: new Thingy('city', {name: 'Halifax})
class Thingy
{
	constructor(entity_or_type_or_collection, ...rest)
	{
		let entity = resolveEntity(entity_or_type_or_collection);
		
		
		// Doesn't use a getter - useful for logs
		this.type = entity.type;
		
		
		let values = {};
		
		rest.forEach(props => {
			for(let [prop_name, prop_value] of Object.entries(props))
			{
				values = Object.assign(values, {[prop_name]: prop_value});
			}
		})
		
		
		this._id = values._id || cuid();
		this.entity = entity;
		this.values = values;
		this.refs = {};
		
	}
	
	
	get title()
	{
		if(this.values.name)
		{
			return this.values.name;
		}
		else
		{
			return this.entity.type;// + ' ' + this.values._id;
		}
	}
	
	get plural()
	{
		return this.entity.plural;
	}
	
	get collection()
	{
		return this.entity.plural;
	}
	
	get singular()
	{
		return this.entity.singular;
	}
	
	
	get rels()
	{
		return this.entity.rels;
	}
	
	
	propType(prop_name)
	{
		return this.entity.prop_types[prop_name];
	}
	
	
	
	// Pass in the current state (and optional limits) to deeply resolve referenced data
	toThingo(state, ...rest)
	{
		return new Thingo(this, state, ...rest);
	}
	
	
	
	// Creates a Thingo, which contains the thing's values combined with the Thingy,
	// with all referencces resolved if the foreign Thingys are in the provided state.
	recursivelyResolveAllRefs
	(
		state,
		max_depth = null,
		current_depth = 0,
		resolved_thingys = {},
	)
	{
		if(LOG && (current_depth === 0))
		{
			console.group('Recursively resolving', this.title, '(' + this._id + ')');
		}
		
		
		
		if(resolved_thingys[this._id])
		{
			if(LOG)
			{
				console.log(
					'    '.repeat(current_depth) +
					'%cAlready resolved ' + this.title,
					'color: #666',
				);
			}
			
			return;
		}
		
		
		if(LOG)
		{
			console.log(
				'    '.repeat(current_depth) +
				'%cRecursively resolving refs for %c' + this.title,
				'color: darkred',
				'color: red',
				//this
			);
		}
		
		resolved_thingys[this._id] = this;
		
		this.resolveRefs(state);
		
		
		if((max_depth === null) || (max_depth - current_depth) > 0)
		{
			// For each possible relationship defined in Entity, check if this Thingy
			// has a corresponding relationship
			for(let rel of Object.values(this.rels))
			{
				let ref = this.refs[rel.prop_name];
				
				//console.log(rel, ref, this);
				
				// Ex: Thingy    or    [Thingy, Thingy]
				if(ref)
				{
					if(rel.is_plural)
					{
						ref.forEach(thingy => thingy?.recursivelyResolveAllRefs(
							state,
							max_depth,
							current_depth + 1,
							resolved_thingys,
						));
					}
					else
					{
						ref?.recursivelyResolveAllRefs(
							state,
							max_depth,
							current_depth + 1,
							resolved_thingys,
						);
					}
				}
			}
		}
		
		
		if(LOG && (current_depth === 0))
		{
			console.groupEnd();
		}
	}
	
	
	
	// Pass in a state object mapping collection names to arrays of objects
	// Ex: city_thingy.resolveRef('mayor_id', state) => Person or null
	resolveRefs(state)
	{
		Object.entries(this.entity.rels)
			?.forEach(([prop_name, rel]) =>
			{
			// Get the relationship info from our assoicated Entity
				let foreign = this.rels[prop_name]?.resolve(state, this);
				
				//console.log('         ', prop_name, foreign);
				
				this.refs[prop_name] = foreign;
				
				return foreign;
			})
		
		return this.refs;
	}
	
}


export default Thingy;
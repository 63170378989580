import { Controller, useFormContext } from 'react-hook-form';

import { Button, Stack } from '@mui/material';

import Formlet from 'app/gizmos/fields/Formlet';
import YearlyDateWidget from './YearlyDateWidget';
import cuid from 'cuid';
import CorIcon from 'components/icons/CorIcon';
import { YearlyDate } from 'models';


export const YearlyScheduleFormlet =
({
	name = 'recurrence.yearly.yearly_dates',
	label = 'Repeats Yearly on These Dates',
	help = 'On which dates each year should work orders be due?',
}) =>
{
	const form_context = useFormContext();
	
	
	let watched_value = form_context.watch(name) as YearlyDate[];
	
	let yearly_dates : YearlyDate[] = Object.values(watched_value).filter(x => (x !== undefined)) as YearlyDate[];
	
	console.log({
		watched_value,
		name,
		yearly_dates,
	})
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<Stack
						sx={{
							alignItems: 'center',
						}}
					>
						{
							yearly_dates?.map((yearly_date, i) =>
								<YearlyDateWidget
									basename={name}
									yearly_date={yearly_date}
									yearly_dates={yearly_dates}
									key={yearly_date._id}
									onChange={(new_value) => {
										
										let changed_index = yearly_dates.findIndex(x =>
											x._id === new_value._id
										);
										
										yearly_dates[changed_index] = new_value;
										
										onChange(yearly_dates);
									}}
								/>
							)
						}
					</Stack>
				}
			/>
			
			<Button
				startIcon={<CorIcon of='plus_basic' />}
				onClick={() => {
					let id = cuid();
					
					watched_value.push({
						_id: id,
						month: 1,
						day: 1,
					})
					
					form_context.setValue(
						name,
						watched_value,
					)
				}}
			>
				Add Yearly Date
			</Button>
			
		</Formlet>
	)
}


export default YearlyScheduleFormlet;
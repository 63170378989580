import React, { useState } from 'react';
import { VictoryChart, VictoryZoomContainer, VictoryBrushContainer, VictoryAxis, VictoryArea, VictoryLine, VictoryTooltip,
	// VictoryVoronoiContainer, createContainer,
	VictoryScatter,
	DomainTuple
} from 'victory';
import { victor_ticks } from './victor_data';
import { misc_styles, victor_styles } from './victor_styles';
// import { testVictor } from './techsol_helpers';
// import { createDatasetLookup } from './techsol_temp';
import { useInterval } from 'usehooks-ts';
import { TechhsolSensor } from './techsol_models';
import { useVictor } from './VictorProvider';
import VictorSelect from './VictorSelect';
import { Divider } from '@mui/material';



// TODO: Make work properly
class CustomFlyout extends React.Component
{
	render()
	{
		const { x, y, orientation } = this.props as any;
		
		const newY = orientation === 'bottom' ? y - 35 : y + 35;
		return (
			<g>
			<circle cx={x} cy={newY} r='20' stroke='tomato' fill='none'/>
			<circle cx={x} cy={newY} r='25' stroke='orange' fill='none'/>
			<circle cx={x} cy={newY} r='30' stroke='gold' fill='none'/>
			</g>
		);
	}
}





// TODO: Clean up
// testVictor();

// let sensor_lookup = createDatasetLookup();

let start_date = new Date(2022, 7, 10);
let end_date = new Date(2023, 4, 5);




export const Victor =
({
	width = 550,
	height = 330,
} : {
	width: number,
	height: number,
}) =>
{
	// const [ state, setState ] = useState({
	// 	zoomDomain: null,
	// 	selectedDomain: null,
	// });
	
	// const [ zoomDomain, setZoomDomain ] = useState(null);
	// const [ selectedDomain, setSelectedDomain ] = useState(null);
	
	// const [ sensors, setSensors ] = useState(Object.values(sensor_lookup));
	// const [ valuable_sensors, setValuableSensors ] = useState(sensors.filter(x => x?.values?.length > 0));
	
	// const [ selected_sensor_index, setSelectedSensorIndex ] = useState(0);
	// const [ selected_sensor, setSelectedSensor ] = useState<TechhsolSensor>(valuable_sensors[selected_sensor_index]);
	
	
	const {
		sensors_by_id,
		sensors,
		selected_sensor,
		selected_sensor_stats,
		
		points,
		min_points,
		max_points,
		
		domain,
		x_domain,
		y_domain,
		
		zoom_domain,
		
		setDomain,
		setZoomDomain,
		// setXDomain,
		// setYDomain,
		// setZoomXDomain,
		// setZoomYDomain,
		
		handleSelectSensor,
	} = useVictor();
	
	
	// Rotate through the charts for demo
	// useInterval(
	// 	() =>
	// 	{
	// 		let new_index = selected_sensor_index + 1;
			
	// 		if(new_index >= valuable_sensors.length)
	// 		{
	// 			new_index = 0;
	// 		}
			
	// 		setSelectedSensorIndex(new_index)
	// 		setSelectedSensor(valuable_sensors[new_index])
			
	// 		console.log(new_index)
	// 	},
	// 	5000,
	// )
	
	// let selected_sensor = sensors.find(x => x.values?.length > 0);
	
	// let selected_sensor_values = (selected_sensor?.values?.length > 0)
	// 	? selected_sensor.values
	// 	: [];
	
	
	
	// console.log({sensors})
	// console.log({selected_sensor})
	// console.log({selected_sensor_values})
	
	
	
	
	// TODO: Review, document, and improve - integrate with provider domains
	function handleZoom(domain: { x?: DomainTuple, y?: DomainTuple })
	{
		console.log('Handling zoom');
		
		setZoomDomain(domain);
		
		// handleSelectSensor(selected_sensor);
	}
	
	function handleBrush(domain: { x?: DomainTuple, y?: DomainTuple })
	{
		console.log('Handling brush');
		
		// setZoomDomain(domain);
		setZoomDomain(domain);
		
		// handleSelectSensor(selected_sensor);
	}
	
	
	
	
	
	// const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');
	
	// Changing labels to show concise datetimes
	// let victor_data2 = victor_data.map(datum =>
	// {
	// 	let new_datum = structuredClone(datum);
		
	// 	new_datum.label = datum.y;
		
	// 	// conciseDateAndTime(
	// 	// 	DateTime.fromJSDate(datum.x).toLocal(),
	// 	// 	true,
	// 	// 	false,
	// 	// );
		
	// 	return new_datum;
	// })
	
	
	
	
	
	return (
		<div>
			
			<VictorSelect />
			
			<VictoryChart
				width={width}
				height={height}
				domain={{
					x: x_domain,
					y: y_domain,
				}}
				
				// domainPadding={{
				// 	x: 0,
				// 	y: 10,
				// }}
				scale={{
					x: 'time',
					y: 'linear',
				}}
				containerComponent={
					// <VictoryZoomVoronoiContainer
					// />
					
					<VictoryZoomContainer
						responsive={false}
						zoomDimension='x'
						zoomDomain={zoom_domain}
						onZoomDomainChange={handleZoom}
					/>
					
					// <VictoryVoronoiContainer
					// 	zoomDimension='x'
					// 	zoomDomain={zoomDomain}
					// 	onZoomDomainChange={handleZoom}
					// />
				}
			>
				
				
				
				
				<VictoryArea
					style={misc_styles.inside_range}
					data={max_points}
				/>
				<VictoryArea
					style={victor_styles}
					data={points}
				/>
				<VictoryArea
					style={misc_styles.outside_range}
					data={min_points}
				/>
				
				
				<VictoryScatter
					standalone={false}
					data={min_points}
					style={misc_styles.min_point}
				/>
				<VictoryScatter
					standalone={false}
					data={max_points}
					style={misc_styles.max_point}
				/>
				<VictoryScatter
					standalone={false}
					data={points}
				/>
				
				
				
				
				
				
				{/* <VictoryArea
					style={victor_styles}
					data={points}
					
					// labelComponent={
					// 	<VictoryTooltip
					// 		cornerRadius={({ datum }) => datum.x > 6 ? 0 : 20}
					// 		pointerLength={({ datum }) => datum.y > 0 ? 5 : 20}
					// 		flyoutStyle={{
					// 			stroke: ({ datum }) => datum.x === 10
					// 				? 'tomato'
					// 				: 'black',
					// 			fontSize: '10px',
					// 		}}
					// 		style={{
					// 			fontSize: '10px',
					// 		}}
					// 		flyoutComponent={<CustomFlyout/>}
					// 	/>
					// }
				/> */}
				
				
				{/* Red annotation line
				<VictoryLine
					data={[
						{x: start_date, y: 200},
						{x: end_date, y: 200}
					]}
					// domain={{
					// 	x: [start_date, end_date],
					// 	// y: [-10, 15]
					// }}
					scale={{
						x: 'time',
						y: 'linear',
					}}
					standalone={false}
					style={misc_styles.lineThree}
				/>
				
				{/* Red annotation line */}
				{/* <VictoryLine
					data={[
						{x: start_date, y: 400},
						{x: end_date, y: 400}
					]}
					// domain={{
					// 	x: [start_date, end_date],
					// 	// y: [-10, 15]
					// }}
					scale={{
						x: 'time',
						y: 'linear',
					}}
					standalone={false}
					style={misc_styles.lineFour}
				/> */}
				
				
			
			</VictoryChart>
			
			
			
			
			
			
			<VictoryChart
				width={width}
				height={70}
				
				// domainPadding={{
				// 	x: 0,
				// 	y: 0,
				// }}
				domain={{
					x: x_domain,
					// y: y_domain,
				}}
				
				// domainPadding={{
				// 	x: 0,
				// 	y: 10,
				// }}
				
				scale={{
					x: 'time',
					y: 'linear',
				}}
				padding={{
					top: 20,
					left: 50,
					right: 50,
					bottom: 30,
				}}
				containerComponent={
					<VictoryBrushContainer responsive={false}
						brushDimension='x'
						brushDomain={zoom_domain}
						onBrushDomainChange={handleBrush}
					/>
				}
			>
				<VictoryAxis
					tickValues={victor_ticks}
					tickFormat={(x) => new Date(x).getFullYear()}
					style={{
						tickLabels: {
							fontSize: '10px',
						}
					}}
				/>
				<VictoryArea
					style={victor_styles}
					data={points}
					labelComponent={
						<VictoryTooltip
							cornerRadius={({ datum }) => datum.x > 6 ? 0 : 20}
							pointerLength={({ datum }) => datum.y > 0 ? 5 : 20}
							flyoutStyle={{
								stroke: ({ datum }) => datum.x === 10
								? 'tomato'
								: 'black'
							}}
							flyoutComponent={<CustomFlyout/>}
						/>
					}
				/>
			</VictoryChart>
			
			
			<Divider />
			
			<h3>
				{selected_sensor.signal_name}
			</h3>
			<h4>
				{selected_sensor.values?.length}
			</h4>
			<h5>
				{selected_sensor_stats?.length} Days of Data
			</h5>
			
			<Divider
				sx={{
					marginBottom: 4,
				}}
			/>
			
			
		</div>
	);
}


export default Victor;
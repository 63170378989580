import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OuterContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginInline: 'auto',
	paddingTop: '2rem',
}));

export const InnerContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
}));

export const GridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr',
	paddingTop: '3rem',
	userSelect: 'none',
	'& :first-of-type': {
		// backgroundColor: 'red',
	},
}));

import { Controller } from 'react-hook-form';
import { OutlinedInput, Stack, Typography } from '@mui/material';
import Formlet from '../Formlet';
import { useFocus } from 'utils/hooks';
import { useRef } from 'react';
import { numberWithCommas } from 'utils';

// import { ComponentRecordUnits } from 'models';


// import { NumericFormat, InputAttributes } from 'react-number-format';
// import React from 'react';



// function NumberFormatCustom(props)
// {
// 	const { inputRef, onChange, ...other } = props;
	
// 	return (
// 		<NumericFormat
// 			{...other}
// 			getInputRef={inputRef}
// 			onValueChange={(values) => {
// 				onChange({
// 					target: {
// 						name: props.name,
// 						value: values.value
// 					}
// 				});
// 			}}
// 			thousandSeparator
// 			// isNumericString
// 		/>
// 	);
// }


// interface CustomProps
// {
// 	onChange: (event: { target: { name: string; value: string } }) => void,
// 	name: string,
// }


// const NumberFormatCustom = React.forwardRef<
// 	typeof NumericFormat<InputAttributes>,
// 	CustomProps
// >(function NumberFormatCustom(props, ref)
// {
// 	const { onChange, ...other } = props;
	
// 	return (
// 		<NumericFormat
// 			{...other}
// 			getInputRef={ref}
// 			onValueChange={(values) => {
// 				onChange({
// 				target: {
// 					name: props.name,
// 					value: values.value,
// 				},
// 				});
// 			}}
// 			thousandSeparator
// 			// isNumericString
// 			prefix="$"
// 		/>
// 	);
// });



export const NumberFormlet =
({
	name = 'number',
	label = 'Number',
	units,
	help,
} : {
	name?: string,
	label?: string,
	units?: string,
	help?: string,
}) =>
{
	const ref = useRef();
	
	const is_focused = useFocus(ref);
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Stack
				direction='row'
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					justifyItems: 'center',
				}}
			>
				<Controller
					name={name}
					render={({ field: { value, onChange } }) =>
						
						// <TextField
						// 	label="react-number-format"
						// 	value={value}
						// 	onChange={(e) => onChange(e.target.value)}
						// 	name="numberformat"
						// 	id="formatted-numberformat-input"
						// 	InputProps={{
						// 		inputComponent: NumberFormatCustom as any,
						// 	}}
						// 	variant="standard"
						// />
						// <TextField
						// 	InputProps={{
						// 		inputComponent: MyInputComponent,
						// 		inputProps: {
						// 			component: NumberFormatCustom,
						// 		},
						// 	}}
						// />
						
						<OutlinedInput
							name={name}
							// type='number'
							value={
								(value)
									? (!is_focused)
										? numberWithCommas(value)
										: value
									: ''
							}
							onChange={(e) =>
								// console.log(value)
								onChange(e.target.value)
							}
							
							// inputProps={{ }}
							
							inputRef={ref}
							
							// inputComponent={NumberFormatCustom}
							
							// InputProps={{
							// }}
							// inputProps={{
							// 	format: "##-##-##",
							// 	inputComponent: NumberFormatCustom,
							// 	sx: {
							// 		textAlign: 'end',
							// 		// padding: '12px',
							// 	},
							// }}
							
							sx={{
								width: '170px',
								
								// padding: '8px',
								
								bgcolor: 'field.default',
								
								'&:hover': {
									bgcolor: 'field.hover',
								},
								
								'&.Mui-focused': {
									bgcolor: 'field.focus',
								}
							}}
						/>
					}
				/>
				<Typography
					sx={{
						padding: 1,
						color: '#0008',
					}}
				>
					{units}
				</Typography>
			</Stack>
		</Formlet>
	);
}


export default NumberFormlet;
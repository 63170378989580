import {
	ComponentRecordType,
	PressureUnits,
	TemperatureUnits,
	AreaUnits,
	CountUnits,
	CurrentUnits,
	DimensionUnits,
	FlowRateUnits,
	FluidLevelUnits,
	FrequencyUnits,
	PercentageUnits,
	PowerUnits,
	VoltageUnits,
	VolumeUnits,
	WeightUnits,
	TimeElapsedUnits
} from 'models';

import { Controller, useFormContext } from 'react-hook-form';
import EnumToggleField from 'app/gizmos/fields/toggles/EnumToggleField';
import Formlet from '../fields/Formlet';
import { capitalizeFirst } from 'utils';


// TODO: Move/refactor
export const recordUnitsForRecordType = (record_type: ComponentRecordType | string) =>
{
	
	if(record_type === ComponentRecordType.TEMPERATURE)
	{
		return TemperatureUnits;
	}
	else if(record_type === ComponentRecordType.PRESSURE)
	{
		return PressureUnits;
	}
	else if(record_type === ComponentRecordType.AREA)
	{
		return AreaUnits;
	}
	else if(record_type === ComponentRecordType.VOLUME)
	{
		return VolumeUnits;
	}
	else if(record_type === ComponentRecordType.WEIGHT)
	{
		return WeightUnits;
	}
	else if(record_type === ComponentRecordType.COUNT)
	{
		return CountUnits;
	}
	else if(record_type === ComponentRecordType.CURRENT)
	{
		return CurrentUnits;
	}
	else if(record_type === ComponentRecordType.VOLTAGE)
	{
		return VoltageUnits;
	}
	else if(record_type === ComponentRecordType.POWER)
	{
		return PowerUnits;
	}
	else if(record_type === ComponentRecordType.FLOW_RATE)
	{
		return FlowRateUnits;
	}
	else if(record_type === ComponentRecordType.FLUID_LEVEL)
	{
		return FluidLevelUnits;
	}
	else if(record_type === ComponentRecordType.FREQUENCY)
	{
		return FrequencyUnits;
	}
	else if(record_type === ComponentRecordType.PERCENTAGE)
	{
		return PercentageUnits;
	}
	else if(
		record_type === ComponentRecordType.HEIGHT
		|| record_type === ComponentRecordType.LENGTH
		|| record_type === ComponentRecordType.WIDTH
		|| record_type === ComponentRecordType.DIAMETER
		|| record_type === ComponentRecordType.DEPTH
		)
	{
		return DimensionUnits;
	}
	else if(record_type === ComponentRecordType.TIME_ELAPSED)
	{
		return TimeElapsedUnits;
	}
	
	else return null;
}



// Choose between appropriate units for temperature, pressure, etc.
export const ComponentRecordUnitsFormlet =
({
	name = 'units',
	label = 'Units',
} : {
	name?: string,
	label?: string,
}) =>
{
	const form_context = useFormContext();
	
	
	let type : string = form_context.watch('type');
	
	
	if((!type) || (type === ComponentRecordType.RUNNING_HOURS))
	{
		return null;
	}
	
	
	return (
		<Formlet
			label={label}
			help={capitalizeFirst(`${type ? type?.toLowerCase() + ' ' : ''}readings are expressed in`)}
		>
			
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<EnumToggleField
						url_param_key='units'
						enum_type={recordUnitsForRecordType(type)}
						value={value}
						onChange={onChange}
					/>
				}
			/>
		</Formlet>
	);
}


export default ComponentRecordUnitsFormlet;
import { Button, Stack, Typography } from '@mui/material';
import CreateWorkOrderTemplateSections from 'pages/CreateWorkOrder/Sections/CreateWorkOrderTemplateSections';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useWOTCollabData } from './WOTCollabProvider';


const WOTCollabWorkSteps = () =>
{
	const form_context = useFormContext();
	
	const { updateWorkSteps } = useWOTCollabData();
	
	return (
		<Stack
			padding={4}
			gap={2}
		>
			<Stack
				width='100%'
				direction='row'
				alignItems='center'
				justifyContent='space-between'
			>
				<Typography
					variant='h2'
				>
					Edit Work Steps/Instructions
				</Typography>
				
				<Button
					onClick={() => updateWorkSteps()}
				>
					Save Changes
				</Button>
			</Stack>
			
			<CreateWorkOrderTemplateSections
				form={form_context}
				showPreview={() => console.log('showSectionsPreview')}
			/>
		</Stack>
	)
};


export default WOTCollabWorkSteps;
import { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material'
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useWorkOrder } from '../../WorkOrderProvider';
import { WorkOrderStatus } from 'models';


const AdditionalWorkOrderControls = () =>
{
	const { updateWorkOrderStatus, work_order } = useWorkOrder();
	
	
	
	
	
	return (
		<Box
			sx={{
				background: '#fff',
				marginTop: '20px',
				padding: '10px',
			}}
		>
			
			<Stack>
				
				<ButtonToOpenDialog
					button_variant='contained'
					dialog_title='Please confirm'
					button_content='Mark Work Order as Completed'
					dialog_submit_text='Confirm'
					dialog_content={
						<Typography
							variant='h4'
						>
							PLEASE NOTE: You are about to mark this Work Order as COMPLETED.
						</Typography>
					}
					sx={{
						background: defaultAppTheme.palette.success.dark,
						fontWeight: '700',
						
						'&:hover': {
							background: defaultAppTheme.palette.success.main,
						}
					}}
					handleSubmit={updateWorkOrderStatus}
					disabled={
						(work_order?.actual_cost?.quantity === undefined)
						|| (work_order?.filled_by_user_ids === undefined)
						|| (work_order?.status === WorkOrderStatus.COMPLETED)
					}
				/>
			</Stack>
			
		</Box>
	)
};


export default AdditionalWorkOrderControls;
import {
	Box,
	CircularProgress,
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import { ActionProps } from '../../../types';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import { useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally } from 'ember';
import { ComponentRecord } from 'models';

import { useState, useEffect } from 'react';
import { useWOTData } from 'pages/CreateWorkOrder/updated/WOTDataProvider';
import { useWOTCollabData } from 'app/dashboard/pages/WOTemplateCollaboration/WOTCollabProvider';



interface EquipmentsProps extends ActionProps {}


export const ReadingComponentRecords =
({
	control,
	fieldIndex,
	sectionIndex,
}
: EquipmentsProps) =>
{
	const { claims } = useAuthData();
	
	const [ options, setOptions ] = useState([])
	
	
	const component_record = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.reading.component_record`,
	});
	
	
	const component_records_collection = useCollection(`organizations/${claims?.organization_id}/component_records`);
	const component_records = component_records_collection?.data as ComponentRecord[] || [];
	
	
	
	
	const reading_equipment = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.reading.equipment`,
	});
	
	
	useEffect(() =>
	{
		const reading_equipment_ids = reading_equipment?.map(x => x._id);
		
		const filtered_component_records = component_records?.filter(x => reading_equipment_ids?.includes(x.component_id))
		setOptions(filtered_component_records)
		
	}, [component_records_collection?.is_loading, reading_equipment])
	
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
				paddingBottom: '3rem',
			}}
		>
			<SearchableSelectFormlet
				name={`sections.${sectionIndex}.fields.${fieldIndex}.reading.component_record`}
				label='Select Component Record'
				help='Choose available component record for taking readings - e.g. Running Hours'
				options={options}
				getOptionLabel={option => option?.title}
				default_value={{_id: '', title: ''}}
			/>
		</Box>
	);
};


export default ReadingComponentRecords;
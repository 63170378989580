import { useAuthData } from 'app/auth/AuthDataProvider';
import { useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { ComponentRecord } from 'models';
import { createContext, useContext } from 'react';
import { useComponent } from './ComponentLoader';


export interface ComponentRecordsData
{
	component_records: ComponentRecord[],
}


const ComponentRecordsContext = createContext<ComponentRecordsData>({
	component_records: [],
});


export const useComponentRecords = () =>
{
	return useContext(ComponentRecordsContext);
};


// Loads all the component records associated with the selected component
export const ComponentRecordsLoader = ({ children }) =>
{
	const { component } = useComponent();
	
	const { claims } = useAuthData();
	
	
	let org_path = `organizations/${claims?.organization_id}`;
	
	const component_records_collection = useCollectionConditionally(
		(component?._id !== undefined),
		`${org_path}/component_records`,
		where('component_id', '==', component?._id)
	);
	
	const component_records = component_records_collection?.data as ComponentRecord[];
	
	
	return (
		<ComponentRecordsContext.Provider value={{
			component_records,
		}}>
			{children}
		</ComponentRecordsContext.Provider>
	);
}


export default ComponentRecordsLoader;
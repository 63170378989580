import { useCor } from 'app/providers/CorProvider';
import { Action, Asset, Certification, CertificationFor, CostCenter, Issue, Requisition, WorkOrderNew } from 'models';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form';


export interface CorsAnalyticsData
{
	assets?: Asset[],
	
	filtered_issues?: Issue[],
	filtered_work_orders?: WorkOrderNew[],
	filtered_requisitions?: Requisition[],
	filtered_actions?: Action[],
	filtered_certifications?: Certification[],
};


export interface CorsAnalyticsFormData
{
	assets?: Asset[],
	cost_centers?: CostCenter[],
}


const DEFAULT_FORM_DATA: CorsAnalyticsFormData =
{
	assets: [],
}






const CorsAnalyticsDataContext = createContext<CorsAnalyticsData>(null)


export const useCorsAnalyticsState = () =>
{
	return useContext(CorsAnalyticsDataContext)
};




const CorsAnalyticsStateProvider = ({
	issues,
	work_orders,
	actions,
	requisitions,
	certifications,
	children,
} : {
	issues?: Issue[],
	work_orders?: WorkOrderNew[],
	actions?: Action[],
	requisitions?: Requisition[],
	certifications?: Certification[],
	children: ReactNode,
}) =>
{
	const { assets } = useCor();
	
	// Issues State
	const [ filtered_issues, setFilteredIssues ] = useState<Issue[]>(issues);
	// Issues State
	const [ filtered_actions, setFilteredActions ] = useState<Action[]>(actions);
	// Issues State
	const [ filtered_requisitions, setFilteredRequisitions ] = useState<Requisition[]>(requisitions);
	// Work Orders State
	const [ filtered_work_orders, setFilteredWorkOrders ] = useState<WorkOrderNew[]>(work_orders);
	// Certification State
	const [ filtered_certifications, setFilteredCertifications ] = useState<Certification[]>(certifications);
	
	
	
	
	
	
	const form = useForm<CorsAnalyticsFormData>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
		}
	});
	
	const { control } = form;
	
	// Filter by assets
	const selected_assets = useWatch({
		control,
		name: 'assets'
	})
	
	const filterByAssets = () =>
	{
		if(issues)
		{
			if(selected_assets?.length > 0 )
			{
				const selected_asset_ids = selected_assets.map(x => x._id);
				const filtered_data = issues?.filter(x => selected_asset_ids.includes(x.asset_id));
				
				setFilteredIssues(filtered_data);
			}
			else
			{
				setFilteredIssues(issues);
			}
		}
		
		
		if(work_orders)
		{
			if(selected_assets?.length > 0 )
			{
				const selected_asset_ids = selected_assets.map(x => x._id);
				const filtered_data = work_orders?.filter(x => selected_asset_ids.includes(x.asset_id));
				
				setFilteredWorkOrders(filtered_data);
			}
			else
			{
				setFilteredWorkOrders(work_orders);
			}
		}
		
		if(certifications)
		{
			const all_asset_certifications = certifications?.filter(x => x.certification_for === CertificationFor.COMPANY_WIDE_ASSETS);
			
			if(selected_assets?.length > 0)
			{
				const selected_asset_ids = selected_assets.map(x => x._id);
				const filtered_data = certifications?.filter(x => selected_asset_ids.includes(x.asset_id));
				
				setFilteredCertifications([
					...filtered_data,
					...all_asset_certifications
				])
			}
			else
			{
				setFilteredCertifications(certifications)
			}
		}
	}
	
	useEffect(() =>
	{
		filterByAssets()
		console.log({selected_assets})
	},
	[selected_assets, issues, work_orders, requisitions, actions, certifications]);
	
	
	
	
	
	const state_data =
	{
		assets: assets || [],
		
		filtered_issues: filtered_issues || [],
		filtered_work_orders: filtered_work_orders || [],
		filtered_requisitions: filtered_requisitions || [],
		filtered_actions: filtered_actions || [],
		filtered_certifications: filtered_certifications || [],
	}
	
	
	return (
		<FormProvider {...form}>
			<CorsAnalyticsDataContext.Provider
				value={state_data}
			>
				{children}
			</CorsAnalyticsDataContext.Provider>
		</FormProvider>
	)
};


export default CorsAnalyticsStateProvider;
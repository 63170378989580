import { default as cx } from 'classnames';

import ImageProps from './ImageProps';


// Pass in a Thingy that this image represents
// Ex: thingy={product} url={product.toThingo(state)?.image?.url}
const Image =
({
	size,
	padding = '',
	background = '',
	alt = '',
	title = '',
	url = '',
	className = '',
	...rest
} : ImageProps ) =>
{
	return (
		<div
			className={cx('image', className)}
			style={{
				width: size,
				height: size,
				padding: padding,
				background: background,
				...rest
			}}
		>
			<img
				src={url}
				alt={alt}
				title={title}
			/>
		</div>
	)
}


export default Image;
import { Controller } from 'react-hook-form';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';


interface DateProps extends ActionProps {}


export const FillableDate =
({
	control,
	sectionIndex: parentFieldIndex,
	fieldIndex,
	sections,
}: DateProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={1}
				className='form_field_description'
			>
				<Typography
					variant='subtitle1'
					sx={{
						lineHeight: 0
					}}
				>
					{parentFieldIndex+1}.{fieldIndex+1}
				</Typography>
				
				<Typography
				variant='subtitle2'
				className='form_field_description'
				>
					{sections[parentFieldIndex].fields[fieldIndex].date.description}
				</Typography>
			</Stack>
			<Box
				sx={{
					display: 'flex',
					gap: '1rem',
				}}
			>
				<Controller
					control={control}
					name={`sections.${parentFieldIndex}.fields.${fieldIndex}.date.time`}
					defaultValue={null}
					render={({ field: { value, onChange } }) => {
						return (
							<TimePicker
								label={'Time'}
								value={value}
								onChange={(dateTime) => onChange(dateTime)}
								renderInput={(params) => (
									<TextField {...params} />
								)}
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name={`sections.${parentFieldIndex}.fields.${fieldIndex}.date.date`}
					defaultValue={null}
					render={({ field: { value, onChange } }) => {
						return (
							<DatePicker
								label={'Date'}
								toolbarPlaceholder='Date'
								value={value}
								onChange={(dateTime) => onChange(dateTime)}
								renderInput={(params) => (
									<TextField {...params} />
								)}
							/>
						);
					}}
				/>
			</Box>
		</Box>
	);
};


export default FillableDate;
import { OnDemandRecurrenceType, RecurrenceEffectiveType, RecurrenceScheduleType } from './../recurrence/recurrence_enums';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { Claims } from 'models';
import { Recurrence, RecurrenceType } from 'models/recurrence';
// import { FormSection } from 'types/form_interfaces';
import { cloneExcludeUndefinedValues, excludeUndefinedValues, zeroAllBut } from 'utils/obj_utils_new';
import { WorkOrderStatus } from './work_order_enums';
import { WorkOrderTemplate, WOTFormData } from './work_order_interfaces';
import { ensureUtcString, determineNextAutomationDatetime } from 'models/recurrence/recurrence_helpers2';





// export const prepTemplate = (template: WorkOrderTemplate) =>
// {
// 	if(template.recurrence.effective_datetime)
// }


// Prepares a Recurrence for writing to DB by converting local DateTimes to UTC ISO strings
export const prepRecurrence = (recurrence: Recurrence) =>
{
	if(recurrence.effective_datetime)
	{
		recurrence.effective_datetime = ensureUtcString(recurrence.effective_datetime)
	}
	if(recurrence.last_automation_datetime)
	{
		recurrence.last_automation_datetime = ensureUtcString(recurrence.last_automation_datetime)
	}
	if(recurrence.next_automation_datetime)
	{
		recurrence.next_automation_datetime = ensureUtcString(recurrence.next_automation_datetime)
	}
	if(recurrence.effective_datetime)
	{
		recurrence.effective_datetime = ensureUtcString(recurrence.effective_datetime)
	}
	if(recurrence.effective_until_datetime)
	{
		recurrence.effective_until_datetime = ensureUtcString(recurrence.effective_until_datetime)
	}
	
	return recurrence;
}



// TODO: Move some functionality from cleanSections
export const pareTemplate = (original_template: WorkOrderTemplate) : WorkOrderTemplate =>
{
	let template : WorkOrderTemplate =
	{
		...cloneDeep(original_template)
	};
	
	
	if(template.recurrence)
	{
		if(template.recurrence.recurrence_type !== RecurrenceType.SCHEDULE)
		{
			delete template.recurrence.weekly;
			delete template.recurrence.monthly;
			delete template.recurrence.yearly;
			// delete template.recurrence.recurrence_schedule;
		}
		
		if(template.recurrence.recurrence_type !== RecurrenceType.INTERVAL)
		{
			delete template.recurrence.interval;
		}
	}
	
	
	return template;
}





// TODO: Review and document
// TODO: Add types
// Strip out any values or checked states from custom form sections
export const cleanSections = (sections) =>
{
	// console.log('        %cCleaning sections:', 'color: red', sections);
	
	
	let cleaned_sections = sections.map(section =>
	{
		let clean_section = cloneExcludeUndefinedValues(section);
		
		
		clean_section.fields = clean_section.fields.map(field =>
		{
			// TODO: For some reason these aren't mutually exclusive
			if(field.text)
			{
				delete field.longText;
			}
			if(field.multiple_choice)
			{
				field.multiple_choice.options = field.multiple_choice.options.map(x =>
					({
						label: x.label,
						checked: false,
					})
				)
			}
			if(field.checkbox)
			{
				field.checkbox.options = field.checkbox.options.map(x =>
					({
						label: x.label,
						checked: false,
					})
				)
			}
			if(field.pass_fail)
			{
				delete field.pass_fail.value;
			}
			if(field.yes_no)
			{
				delete field.yes_no.value;
			}
			if(field.date)
			{
				delete field.date.time;
				delete field.date.date;
			}
			if(field.reading)
			{
				delete field.reading.value;
			}
			if(field.replace)
			{
				delete field.replace.value;
			}
			
			return field;
		});
		
		
		
		return clean_section;
	})
	
	
	// console.log('        %cCleaned sections:', 'color: orange',
	// 	cleaned_sections,
	// );
	
	
	return cleaned_sections;
}



// TODO: Clean up and review
export const convertFormDataToWorkOrderTemplate =
(
	form_data: WOTFormData,
	claims: Claims,
)
: WorkOrderTemplate =>
{
	let utc_iso_now = DateTime.utc().toISO();
	
	
	let template =
	{
		...cloneDeep(form_data),
		
		file_ids: [],
		
		status: WorkOrderStatus.ACTIVE,
		
		asset_id: form_data.asset?._id || '',
		asset_name: form_data.asset?.name || '',
		component_ids: form_data.equipment.map(x => x._id),
		
		collaborator_ids: form_data.collaborators.map(x => x._id),
		parts_needed: form_data.parts.map(x => (
			{
				_id: x._id,
				name: x.name,
				quantity: x.selected_count,
			})),
		tag_ids: form_data.tags.map(x => x._id),
		issue_ids: form_data.issues.map(x => x._id),
		
		creator: {
			_id: claims?.user_id,
			name: claims?.name,
			email: claims?.email,
		},
		
		created_at: utc_iso_now,
		start_datetime: utc_iso_now,
		
		work_order_ids: [],
		assigned_to_role_ids: form_data.roles?.map(x => x._id) || [],
	}
	
	
	let recurrence = form_data.recurrence;
	
	
	delete template.now;
	
	
	if(recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
	{
		if(recurrence.on_demand_type === OnDemandRecurrenceType.TRIGGER)
		{
			delete template.recurrence.specific_dates;
		}
		
		delete template.recurrence.interval;
		delete template.recurrence.weekly;
		delete template.recurrence.monthly;
		delete template.recurrence.yearly;
		delete template.recurrence.skip_first;
	}
	else
	{
		delete template.recurrence.on_demand_type;
		delete template.recurrence.specific_dates;
		
		
		if(recurrence.recurrence_type === RecurrenceType.INTERVAL)
		{
			delete template.recurrence.weekly;
			delete template.recurrence.monthly;
			delete template.recurrence.yearly;
			
			if(recurrence.recurrence_schedule === RecurrenceScheduleType.DAILY)
			{
				template.recurrence.interval = zeroAllBut(template.recurrence.interval, 'days');
			}
			if(recurrence.recurrence_schedule === RecurrenceScheduleType.WEEKLY)
			{
				template.recurrence.interval = zeroAllBut(template.recurrence.interval, 'weeks');
			}
			if(recurrence.recurrence_schedule === RecurrenceScheduleType.MONTHLY)
			{
				template.recurrence.interval = zeroAllBut(template.recurrence.interval, 'months');
			}
			if(recurrence.recurrence_schedule === RecurrenceScheduleType.YEARLY)
			{
				template.recurrence.interval = zeroAllBut(template.recurrence.interval, 'years');
			}
		}
		else
		{
			delete template.recurrence.interval;
		}
	}
	
	
	template.recurrence.effective_datetime = (template.recurrence.effective_type === RecurrenceEffectiveType.START_IMMEDIATELY)
		? utc_iso_now
		: ensureUtcString(recurrence.effective_datetime);
	
	template.recurrence.effective_until_datetime = (template.recurrence.effective_type === RecurrenceEffectiveType.EFFECTIVE_BETWEEN)
		? ensureUtcString(recurrence.effective_until_datetime)
		: null;
	
	
	
	
	// TODO: Verify
	// delete template.collaborators;
	// delete template.equipment;
	// delete template.parts;
	// delete template.tags;
	// delete template.issues;
	// delete template.files;
	
	
	// TODO: Don't delete once we're ready to switch
	delete template.custom_form;
	
	
	let next_automation_datetime = determineNextAutomationDatetime(recurrence, 1, DateTime.local());
	
	
	if(next_automation_datetime)
	{
		template.recurrence.next_automation_datetime = ensureUtcString(next_automation_datetime);
	}
	
	// TODO: Verify that this works as intended
	template.sections = cleanSections(template.sections);
	
	
	console.log(template);
	
	
	// if(form_data.recurrence.recurrence_type !== OnDemandRecurrenceType.SCHEDULE)
	// {
	// 	// delete data2.recurrence.daily;
	// 	delete template.recurrence.weekly;
	// 	delete template.recurrence.monthly;
	// 	delete template.recurrence.yearly;
	// 	delete template.recurrence.recurrence_schedule;
	// }
	
	
	// if(form_data.recurrence.recurrence_type !== RecurrenceType.SCHEDULE)
	// {
	// 	// delete data2.recurrence.daily;
	// 	delete template.recurrence.weekly;
	// 	delete template.recurrence.monthly;
	// 	delete template.recurrence.yearly;
	// 	delete template.recurrence.recurrence_schedule;
	// }
	
	// if(form_data.recurrence.recurrence_type !== RecurrenceType.INTERVAL)
	// {
	// 	delete template.recurrence.interval;
	// }
	
	
	// template.recurrence.effective_datetime = (form_data.recurrence.effective_datetime as DateTime)?.toISO();
	
	// if(determineNextAutomationDatetime(form_data.recurrence))
	// {
	// 	template.recurrence.next_automation_datetime =
	// 		determineNextAutomationDatetime(form_data.recurrence)?.toISO();
	// }
	
	
	// if(template.recurrence.weekly)
	// {
	// 	// TODO: This should be done while inputs are making changes
	// 	template.recurrence.weekly.weekday_numbers = template.recurrence.weekly.weekday_numbers.filter(x => x !== undefined);
	// }
	
	
	// // Convert the datetimes in each specific dates to ISO strings after converting to UTC
	// template.recurrence.specific_dates = template.recurrence.specific_dates.map(specific_date => {
		
	// 	if(DateTime.isDateTime(specific_date.datetime))
	// 	{
	// 		specific_date.datetime = specific_date.datetime.toUTC().toISO();
	// 	}
		
	// 	return specific_date;
	// })
	
	
	// console.log(template.sections);
	
	
	return excludeUndefinedValues(template) as WorkOrderTemplate;
}



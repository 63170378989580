import { makeStyles, createStyles } from '@mui/styles'


export const useStyles = makeStyles((theme?: any) => createStyles({
	BoxRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	BoxCol: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginLeft: '20px',
		marginRight: '20px',
	},
	InputBox: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: '100%'
	},
	CollaboratorsListItemText: {
		'& .MuiListItemText-root': {
			minWidth: '150px',
			wordBreak: 'break-all'
		},
		'& .MuiListItemText-secondary': {
			minWidth: '150px',
			wordBreak: 'break-all'
		}
	},
	CollaboratorsListItem: {
		backgroundColor: '#f8f9fa',
		borderBottom: '1px solid #d8dbdf',
		borderRadius: '5px',
		padding: '0px',
	},
	SelectedCollaboratorsListBox: {
		border: '1px solid #d8dbdf',
		borderRadius: '5px',
		width: '100%',
		height: '190px',
		overflow: 'auto'
	},
	SelectedCollaboratorsListItem: {
		borderBottom: '1px solid #d8dbdf',
		borderRadius: '5px'
	},
}))



import { Box, TextField, Button, Checkbox, Typography, Stack } from '@mui/material';
import { Controller, FieldArrayWithId, useFieldArray } from 'react-hook-form';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import AddIcon from '@mui/icons-material/Add';
import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';


export interface CheckBoxProps extends ActionProps {}


export const FillableCheckBox = (props: CheckBoxProps) =>
{
	return (
		<>
			<Description {...props} />
			<Options {...props} />
		</>
	);
};


interface DescriptionProps extends ActionProps {}


const Description = ({ control, sectionIndex, fieldIndex, sections }: DescriptionProps) =>
{
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='flex-start'
			spacing={1}
			className='form_field_description'
		>
			<Typography
				variant='subtitle1'
				sx={{
					lineHeight: 0
				}}
			>
				{sectionIndex+1}.{fieldIndex+1}
			</Typography>
			
			<Typography
				variant='subtitle2'
			>
				{sections[sectionIndex].fields[fieldIndex].checkbox.description}
			</Typography>
		</Stack>
	);
};


interface OptionsProps extends ActionProps {}


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	const { fields } = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.checkbox.options`,
	});
	
	
	
	return (
		<Box>
			{fields.map((field, optionIndex) => (
				<Option
					control={control}
					field={field}
					sectionIndex={sectionIndex}
					fieldIndex={fieldIndex}
					optionIndex={optionIndex}
					key={optionIndex}
				/>
			))}
		</Box>
	);
};


interface OptionProps extends Omit<ActionProps, 'fieldId'> {
	field: FieldArrayWithId<
		StepperForm,
		`sections.${number}.fields.${number}.multiple_choice.options`,
		'id'
	>;
	optionIndex: number;
}


const Option =
({
	control,
	field,
	fieldIndex,
	sectionIndex,
	optionIndex,
}: OptionProps) =>
{
	return (
		<Box
			key={field.id}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.5rem',
			}}
		>
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.checkbox.options.${optionIndex}.checked` as const
				}
				defaultValue={false}
				render={({ field: { value, onChange } }) => (
					<Checkbox
						key={field.id}
						checked={!!value}
						onChange={(e) => {
							const value = e.target.checked;
							onChange(value);
						}}
					/>
				)}
			/>
			
			<Typography>
				{field.label}
			</Typography>
		</Box>
	);
};


export default FillableCheckBox;
import { Stack } from '@mui/material';
import AssetDashboard from './assetDashboard/AssetDashboard';
import AssetPageTabs from './AssetPageTabs';


const AssetsDashboardAndNavigationPage = () =>
{
	return (
		<Stack
			direction='column'
			width='100%'
		>
			<AssetDashboard />
			
			<AssetPageTabs />
		</Stack>
	)
};


export default AssetsDashboardAndNavigationPage;
import { useState } from 'react';

import { Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import SortableTable from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/SortableTable'
import { useSession } from 'app/providers/SessionProvider';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import UserAvatar from 'components/avatars/UserAvatar';
import CorIcon from 'components/icons/CorIcon';
import AddUserAdditionalProperties from './AddUserAdditionalProperties';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { numberWithCommas } from 'utils';


export const UsersTable = () =>
{
	const { users } = useSession();
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	return (
		<SortableTable
			options={users}
			onSelect={(selectedValue) => console.log(selectedValue)}
			rows={[
				{
					title: 'Edit',
					getCell(user) {
						return (
							<ButtonToOpenDialog
								button_type='icon_button'
								dialog_title='Assign Roles/Position'
								dialog_content={
									<AddUserAdditionalProperties
										user={user}
										closeDialog={closeDialog}
										setCloseDialog={setCloseDialog}
									/>
								}
								buttonIcon={<CorIcon of='edit' size='24px' />}
								closeDialog={closeDialog}
								dialogClassName='add_tags_dialog'
							/>
						)
					}
				},
				{
					title: 'Basic Details',
					getCell(user) {
						return (
							<Stack
								direction='row'
								spacing={3}
								alignItems='center'
							>
								<UserAvatar user={user}/>
								
								<Stack>
									<Typography variant='subtitle1'>
										{user.display_name}
									</Typography>
									
									<Typography>
										{user.email}
									</Typography>
								</Stack>
							</Stack>
						)
					}
				},
				{
					title: 'Position & Roles',
					getCell(user) {
						return (
							<Stack
								direction='column'
								alignItems='center'
								justifyContent='center'
								spacing={1}
							>
								<Stack
									flexDirection='row'
									alignItems='center'
									justifyContent='center'
									gap={2}
								>
									<Typography>
										{user?.position?.name}
									</Typography>
									
									{
										user?.budget_limit &&
											<Typography
												variant='subtitle1'
												sx={{
													bgcolor: defaultAppTheme.palette.warning.light,
													borderRadius: '5px',
													color: defaultAppTheme.palette.grey['800'],
													padding: '5px'
												}}
											>
												${numberWithCommas(user?.budget_limit?.quantity)} {user?.budget_limit?.currency}
											</Typography>
									}
								</Stack>
								
								<Divider sx={{ width: '80%'}} />
								
								<Grid
									container
									justifyContent='center'
									gap={1}
								>
									{
										(user.roles) && user?.roles.map(role =>
											<Grid
												key={role._id}
												item
											>
												<Chip label={role.name}/>
											</Grid>
										)
									}
								</Grid>
							</Stack>
						)
					}
				},
			]}
		/>
	)
}

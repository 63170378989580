import { useNavigate } from 'react-router';

import { Box, Typography, Stack, Grid, Chip } from '@mui/material';

import { Issue, IssueStatus } from 'models';
import SortableTable from './SortableTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import RelatedAssetAndComponents from './RelatedAssetAndComponents';
import { ErrorBoundary } from 'react-error-boundary';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { DateTime } from 'luxon';
import { relativeDatetimeText, relativeDaysDiff } from 'utils';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';


const IssuesTable =
({
	issues,
	title,
}: {
	issues: Issue[],
	title?: string,
}
) =>
{
	const navigate = useNavigate();
	
	return(
		<Box>
			<Typography
				variant='h6'
			>
				{title}
			</Typography>
			
			<SortableTable
				options={issues}
				onSelect={(selectedOption) => {
					navigate(`/issues/${selectedOption._id}`)
				}}
				rows={[
					{
						title: 'Issue #',
						getCell(issue) {
							return (
								<Box sx={{ marginInline: '0.5rem' }}>
									{issue.issue_num}
								</Box>
							);
						},
					},
					{
						title: 'Status',
						getCell(issue) {
							const { status } = issue;
							return (
								<Stack
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
								>
									<CorIcon
										of={(status === IssueStatus.OPEN) ? 'open_issue' : 'closed_issue'}
										size='24px'
										color={
											(status === IssueStatus.OPEN)
											? defaultAppTheme.palette.secondary.main
											: (status === IssueStatus.IN_PROGRESS)
											? defaultAppTheme.palette.primary.main
											: defaultAppTheme.palette.success.main
											}
									/>
									
									<Typography
										variant='body1'
										color={
											(status === IssueStatus.OPEN)
											? defaultAppTheme.palette.secondary.main
											: (status === IssueStatus.IN_PROGRESS)
											? defaultAppTheme.palette.primary.main
											: defaultAppTheme.palette.success.main
										}
										sx={{
											paddingLeft: '5px',
										}}
									>
										{ status.toUpperCase() }
									</Typography>
								</Stack>
							)
						},
					},
					{
						title: 'Title',
						getCell(issue) {
							return <Typography
								variant='h3'
								sx={{
									inlineSize: '250px',
									overflowWrap: 'break-word',
								}}
							>
								{ issue.name }
							</Typography>
						},
					},
					{
						title: 'Assigned',
						getCell(issue) {
							
							const is_assigned = ((issue?.collaborator_ids?.length > 0
									|| issue?.role_ids?.length > 0
								)) ? true : false;
							
							return (
								<>
									{
										(is_assigned) ? (
											<Chip color='success' label='assigned'/>
										) : (
											<Chip label='unassigned' />
										)
									}
								</>
							)
						},
					},
					{
						title: 'Affected Components',
						getCell(issue) {
							return (
								<Grid
									container
									width='100%'
									sx={{
										marginTop: '5px'
									}}
								>
									<RelatedAssetAndComponents component_ids={issue?.component_ids} />
								</Grid>
							)
						},
					},
					{
						title: 'Date Opened',
						getCell(issue) {
							
							const created_at_datetime = DateTime.fromISO(issue?.date_opened)
							const days_diff = relativeDaysDiff(created_at_datetime);
							
							return (
								<Stack
									direction='column'
									justifyContent='center'
									alignItems={'flex-start'}
									spacing={1}
								>
									<Typography
										sx={{
											background: ((days_diff > -7) && (issue?.status !== IssueStatus.CLOSED))
												? defaultAppTheme.palette.primary.main
												: (days_diff < -7 && days_diff >= -30 && issue?.status !== IssueStatus.CLOSED)
												? defaultAppTheme.palette.warning.main
												: (days_diff < -30 && issue?.status !== IssueStatus.CLOSED)
												? defaultAppTheme.palette.secondary.main
												: defaultAppTheme.palette.grey['500'],
											padding: '4px',
											borderRadius: '4px',
											color: '#fff',
										}}
									>
										{ relativeDatetimeText(created_at_datetime) }
									</Typography>
									
									<ConciseDateAndTime datetime={created_at_datetime} />
								</Stack>
							)
						}
					}
				]}
			/>
		</Box>
	)
};


export default IssuesTable;
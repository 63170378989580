import { doForObj } from 'utils';


const LOG = false;







// Creates a Thingo, which contains the thing's values combined with the Thingy,
// with all referencces resolved if the foreign Thingys are in the provided state.
// 
// Combines a Thing and a Thingy, while also resolving all references to the specified depth.
// 
// Ex: halifaxObj.name = 'Halifax'   easier than typing halifaxObj.values.name
//     halifaxObj.type = 'city'      same as halifaxThingy.entity.type
//     halifaxObj.mayor.father.favorite_color.hex_code = '#00f'
class Thingo
{
	constructor
	(
		thingy,
		state,
		max_depth = null,
		current_depth = 0,
		resolved_thingys = {},
		resolved_thingos = {},
	)
	{
		if(LOG && (current_depth === 0))
		{
			console.groupCollapsed(
				'Objectifying',
				thingy.title,
				'(' + thingy._id + ')'
			);
		}
		
		
		
		if(!resolved_thingys[thingy._id])
		{
			thingy.recursivelyResolveAllRefs(
				state,
				max_depth,
				current_depth,
				resolved_thingys,
				resolved_thingos,
			);
		}
		
		
		Object.assign(
			this,
			{
				...thingy.values,
				
				entity: thingy.entity,
				type:   thingy.type,
				
				values: thingy.values,
				rels:   thingy.entity.rels,
			}
		)
		
		
		resolved_thingos[thingy._id] = this;
		
		
		
		// Some additional data that will be merged with the Thing and Thingy to form the Thingo
		let results = {};
		
		
		
		
		
		
		const resolveOrMakeThingo = (rel, ref, id) =>
		{
			let obj = resolved_thingos[id];
			
			// If we didn't previous create a Thingo for the referenced Thingy, we'll do that now
			if(!obj)
			{
				obj = ref?.toThingo(
					state,
					max_depth,
					current_depth + 1,
					resolved_thingys,
					resolved_thingos,
				);
			}
			
			if(LOG)
			{
				console.log(
					'    '.repeat(current_depth) +
					'%cResolved thingo for ' + rel.obj_prop_name,
					'color: wheat',
					obj,
				);
			}
			
			return obj;
		}
		
		
		
		
		if(LOG && (current_depth === 0))
		{
			console.group('Objectifying', Object.keys(thingy.refs).length, 'refs');
		}
		
		
		if((max_depth === null) || (max_depth - current_depth) > 0)
		{
			// For each possible relationship defined in Entity, check if this Thingy
			// has a corresponding relationship
			for(let rel of Object.values(thingy.rels))
			{
				let ref = thingy.refs[rel.prop_name];
				
				//console.log(rel, ref);
				
				
				
				
				if(ref)
				{
					// List of IDs
					if(rel.is_plural)
					{
						let list_of_reffed_objs = [];
						
						
						ref.forEach((foreign_thingy) =>
						{
							let obj = resolveOrMakeThingo(rel, foreign_thingy, ref._id);
							
							list_of_reffed_objs.push(obj);
						})
						
						if(LOG)
						{
							console.log(
								'    '.repeat(current_depth) +
								'%cResolved list of thingos for ' + rel.obj_prop_name,
								'color: maroon',
								list_of_reffed_objs,
							);
						}
						
						results[rel.obj_prop_name] = list_of_reffed_objs;
					}
					// Singular reference
					else
					{
						results[rel.obj_prop_name] = resolveOrMakeThingo(rel, ref, ref._id);
					}
				}
			}
		}
		
		
		
		doForObj(results, (v, k) => {
			this[k] = v;
		})
		
		
		if(LOG && (current_depth === 0))
		{
			console.groupEnd();
		}
		
		if(LOG)
		{
			console.log(
				'    '.repeat(current_depth) +
				'%cGenerated new thingo:',
				'color: #aa4',
				this.name || '',
				this,
			);
		}
		
		if(LOG && (current_depth === 0))
		{
			console.groupEnd();
		}
		
		
		return this;
	}
}


export default Thingo;
import { objectifyArray, doForObj } from './obj_utils';
import { doForArray } from './array_utils';


// Provide with an object/array/integer and a function, and this will
// call the function with sensible arguments. Unifies doForArray,
// doForObj, and doX.
export const doFor = (obj_or_array_or_n, fn, ...rest) =>
{
	if(!obj_or_array_or_n)
	{
		return;
	}
	if(Array.isArray(obj_or_array_or_n))
	{
		return doForArray(obj_or_array_or_n, fn, ...rest);
	}
	else if(typeof obj_or_array_or_n === 'object' && obj_or_array_or_n !== null)
	{
		return doForObj(obj_or_array_or_n, fn, ...rest);
	}
	else if(Number.isInteger(obj_or_array_or_n))
	{
		return doX(obj_or_array_or_n, fn, ...rest);
	}
	else
	{
		console.error('doFor what?', obj_or_array_or_n);
	}
}



// Runs a function n times
// TODO: Start from 0 or 1?
export const doX = (n, func, start_at = 0) =>
{
	for(let i = 0; i < n; i++)
	{
		func(i + start_at);
	}
}



// Returns an array containing the results of n function calls or n instances
// of the provided value. Function should take array index, and optionally
// the results array. If no args are provided, returns array of indexes.
// 
// Ex: 3 => [0, 1, 2]
// Ex: 3, 'A' => ['A', 'A', 'A']
// Ex: 3, i => 'Item ' + i   =>   ['Item 0', 'Item 1', 'Item 2']
export const arrayOf = (n, func_or_val) =>
{
	if(n < 0)
	{
		return [];
	}
	
	
	let results = [...new Array(n)];
	
	if(func_or_val === undefined)
	{
		for(let i = 0; i < n; i++)
		{
			results[i] = i;
		}
	}
	else if(func_or_val instanceof Function)
	{
		for(let i = 0; i < n; i++)
		{
			results[i] = func_or_val(i, results);
		}
	}
	else
	{
		for(let i = 0; i < n; i++)
		{
			results[i] = func_or_val;
		}
	}
	
	return results;
}



// Returns an array containing the results of n function calls
export const objectifiedArrayOf = (n, func) =>
{
	return objectifyArray(arrayOf(n, func));
}



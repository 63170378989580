import { Controller, useFormContext } from 'react-hook-form';

import RadioMUI from '@mui/material/Radio';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';

import { FormInputType, ResponseField } from 'types/forms';

import { WorkOrderResponseForm } from 'types/forms';


export interface PassFailProps
{
	field: ResponseField & {
		type: FormInputType.FailPass,
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const PassFail =
({
	field,
	sectionIndex,
	fieldIndex,
}: PassFailProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	
	return (
		<Controller
			control={control}
			name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.value`}
			defaultValue=''
			render={({ field: { value, onChange } }) => (
				<FormControl>
					<RadioGroup
						onChange={(e) => onChange(e.target.value)}
						value={value}
						defaultValue={value}
					>
						{
							field.value.map(({ value, label }) =>
								<FormControlLabel
									key={value}
									value={value}
									control={<RadioMUI />}
									label={label}
								/>
							)
						}
					</RadioGroup>
				</FormControl>
			)}
		/>
	);
};


export default PassFail;
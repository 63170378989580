import { Trigger } from 'models/triggers/trigger_models';
import { ComponentRecordType } from 'models';


export const triggerName = (trigger: Trigger, type: ComponentRecordType, units: any) =>
{
	let min = trigger.threshold?.min;
	let max = trigger.threshold?.max;
	
	let range = (min !== undefined && max !== undefined)
		? 'outside ' + min + ' – ' + max
		: (min !== undefined)
			? '≤ ' + min
			: (max !== undefined)
				? '≥ ' + max
				: null;
	
	let name = (range)
		? type + ' ' + range + ' ' + units
		: ''
	
	return name;
}



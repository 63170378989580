import { Box, TextField } from '@mui/material';
import { WorkOrderField } from 'pages/CreateWorkOrder/work_order_types';


export interface ReadingProps
{
	field: WorkOrderField;
}


export const Reading = ({ field }: ReadingProps) =>
{
	const {
		reading: { component_record },
	} = field;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '0.5rem',
				}}
			>
				Equipments:{' '}
					<Box
						sx={{
							display: 'inline-block',
							backgroundColor: '#C85656',
							paddingBlock: '0.25rem',
							paddingInline: '0.5rem',
							borderRadius: '5px',
							color: 'white',
						}}
					>
						{component_record.title}
					</Box>
			</Box>
			<TextField
				variant='outlined'
				disabled={true}
				placeholder='Maximum Reading'
			/>
			<TextField
				variant='outlined'
				disabled={true}
				placeholder='Minimum Reading'
			/>
		</Box>
	);
};


export default Reading;
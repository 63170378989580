
import { Avatar } from '@mui/material';
import { Box } from '@mui/system';


// Pass User's details in string to render component of users avatar, name and positions
const UserCard =
({
	_username,
	_userPosition,
	_userImg,
} : {
	_userImg :string;
	_username: string;
	_userPosition?: string; 
}) =>
{
	const which_position = () =>
	{
		if( _userPosition !== undefined )
		{
			const position: string = _userPosition.toLowerCase();
			if ( position.includes('technical') )
			{
				return 'position--technician';
			} 
			else if ( position === 'general manager' )
			{
				return 'position--generalManager';
			} 
			else if ( position === 'system engineer' )
			{
				return 'position--systemEnginer';
			} 
			else if ( position === 'junior engineer' )
			{
				return 'position--juniorEnginer';
			} 
		};
	};
	
	
	return (
		<div
			className = { 'userCard' }
		>
			
			<div
				className = { 'userCard__wrapper' }
			>
				
				<Avatar
					className = { 'userCard__avatar' }
					alt = { _username }
					src = { _userImg }
				/>
				
				<Box
					className = { 'userCard__username' }
				>
					{ _username }
				</Box>
				
				<Box
					className = {
						'userCard__position ' 
						+ which_position() 
					}
				>
					{ _userPosition }
				</Box>
			</div>
		</div>
	)
};


export default UserCard;
import { Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router';


export interface HeadProps
{
	handleBack: () => void;
}


export const Head = ({ handleBack }: HeadProps) =>
{
	const navigate = useNavigate();
	
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				paddingLeft: '1rem',
				marginBottom: '10px',
			}}
		>
			
			<ArrowBackIosIcon
				htmlColor='#3f8ed8'
				onClick={() => navigate(-1)}
			/>
			
			<Box
				sx={{
					fontSize: 24,
					fontWeight: 700,
					marginLeft: '10px',
				}}
			>
				Create Work Order Template
			</Box>
			
		</Box>
	);
};


export default Head;
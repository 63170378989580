// import { useRef } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Box, Button } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import Formlet from 'app/gizmos/fields/Formlet';

// import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';


export const AttachmentsFormlet = ({ control, label, help }: any) =>
{
	// const { getValues, setValue } = useFormContext();
	// const { files, set_files } = useSessionContext();
	// const selectedFiles = getValues('files');

	
	const { remove, append } = useFieldArray({
		control,
		name: 'files',
	});
	
	
	const formFiles = useWatch({
		control,
		name: 'files',
	});
	
	
	
	// Convert FileList to array
	const fileChangeHandler = ( file_list: FileList | null) =>
	{
		// console.log(event);
		
		if(file_list != null)
		{
			const files_array: File[] = Array.from( file_list );
			
			files_array.forEach(file => append(file));
			
			console.log(files_array);
		}
	};
	
	
	const onRemove = (index: number) => remove(index);
	
	
	return (
		<Box sx={{
			// display: 'inline-block',
			// width: '100%'
		}}>
			<Formlet
				label={label}
				help={help}
			>
				<Box
					sx={{
						// display: 'flex',
						// flexDirection: 'column',
						// gap: '1rem',
					}}
				>
					<Box
						sx={{
							// border: '1px solid #d8dbdf',
							// borderRadius: '5px',
							// width: '100%',
							// height: '150px',
							// overflow: 'auto'
						}}
					>
						{
							formFiles?.map((file, index) =>
								<SelectedFile
									file={file}
									key={index}
									index={index}
									onRemove={onRemove}
								/>
							)
						}
					</Box>
				
				</Box>
				
				<Button
					startIcon={<FileUploadIcon />}
					component='label'
					sx={{
						marginLeft: 'auto',
						marginTop: '1rem',
					}}
				>
					Upload File
					<input
						type="file"
						hidden
						multiple
						onChange={(e) => fileChangeHandler(e.currentTarget.files)}
					/>
				</Button>
			</Formlet>
		</Box>
	);
};


interface SelectedIssueProps
{
	file: File;
	index: number;
	onRemove: (index: number) => void;
}


const SelectedFile = ({ file, onRemove, index }: SelectedIssueProps) =>
{
	if(!file)
		return null;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				gap: '2rem',
				margin: '20px',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '1rem',
				borderRadius: '5px',
				border: '1px solid #d8dbdf',
				backgroundColor: '#d8dbdf',
				color: '#666666',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.4)',
			}}
		>
			<Box>{file.name}</Box>
			<CloseIcon
				htmlColor="#666666"
				sx={{ cursor: 'pointer' }}
				onClick={() => onRemove(index)}
			/>
		</Box>
	);
};



import { Stack } from '@mui/material';
import ComponentReadingsLoader from 'app/providers/ComponentReadingsLoader';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import CorIcon from 'components/icons/CorIcon';

import { ComponentRecord } from 'models';
import ComponentReadingsList from './ComponentReadingsList';


const ComponentRecordCardReadings =
({
	component_record
} : {
	component_record: ComponentRecord,
}) =>
{
	let text = `${component_record.reading_count} Reading`;
	
	if(component_record.reading_count !== 1)
	{
		text = text + 's';
	}
	
	
	return (
		<Stack
			alignItems='center'
			justifyContent='center'
			alignContent='center'
		>
			<ButtonToOpenDialog
				button_variant='text'
				dialog_title={text + ' for ' + component_record.title}
				dialog_dismiss_text='Done'
				dialog_content={
					<ComponentReadingsLoader>
						<ComponentReadingsList />
					</ComponentReadingsLoader>
				}
				startIcon={<CorIcon of='list' />}
				button_content={text}
				sx={{
					lineHeight: 1,
					// TODO: Hacky
					'& i': {
						height: '20px',
					}
				}}
			/>
			
		</Stack>
	)
};


export default ComponentRecordCardReadings;
import { React, useAuthData, useState } from 'stuff';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import { useStyles } from '../../../styles/ManageProfileTabTheme';
import { User } from 'types';


const UserProfileNameEdit =
({
	current_user,
} : {
	current_user?: User,
}) =>
{
	const { claims } = useAuthData();
	
	const classes = useStyles();
	
	const [ isEdit, setIsEdit ] = useState<boolean>(false);
	const [ username, setUsername ] = useState<string>(claims?.name);
	
	
	return (
		<>
			<Box
				className={classes.UserProfile_UsernameField}
			>
				{
					(isEdit)
					? (
						<Paper
							className={classes.UserProfileItemField}
							variant='outlined'
						>
							<InputBase
								sx={{ ml: 1, flex: 1 }}
								value={username}
								onChange={ (e) => setUsername( e.target.value ) }
								inputProps={{ 'aria-label': 'User username' }}
							/>
						</Paper>
					)
					: (
						<Typography
							className={classes.UserProfile_Username}
							variant='h5'
						>
							{
								current_user?.display_name
							}
						</Typography>
					)

				}
				<Button
					className={classes.UserProfile_UsernameBtn}
					onClick={ () => setIsEdit(!isEdit) }
					disabled
				>
					{
						(isEdit) ? 'Update' : 'Edit'
					}
				</Button>
			</Box>
		</>
	)
}


export default UserProfileNameEdit;
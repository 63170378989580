import { useAuthData } from 'app/auth/AuthDataProvider';
import { useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { ComponentReading } from 'models';
import { createContext, useContext } from 'react';
import { useComponentRecord } from './ComponentRecordProvider';


export interface ComponentReadingsData
{
	component_readings: ComponentReading[],
}


const ComponentReadingsContext = createContext<ComponentReadingsData>({
	component_readings: [],
});


export const useComponentReadings = () =>
{
	return useContext(ComponentReadingsContext);
};


// Loads all the component readings associated with the selected component record
export const ComponentReadingsLoader = ({ children }) =>
{
	const component_record = useComponentRecord();
	
	const { claims } = useAuthData();
	
	
	let org_path = `organizations/${claims?.organization_id}`;
	
	const component_readings_collection = useCollectionConditionally(
		(component_record?._id !== undefined),
		`${org_path}/component_readings`,
		where('component_record_id', '==', component_record?._id)
	);
	
	const component_readings = component_readings_collection?.data as ComponentReading[];
	
	
	return (
		<ComponentReadingsContext.Provider value={{
			component_readings,
		}}>
			{children}
		</ComponentReadingsContext.Provider>
	);
}


export default ComponentReadingsLoader;
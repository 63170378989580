import { Stack } from '@mui/system';
import { Chip } from '@mui/material';
import { Requisition } from 'models';
import { useSession } from 'app/providers/SessionProvider';


const RequisitionCostCentersList =
({
	requisition,
} : {
	requisition: Requisition,
}) =>
{
	const { cost_centers } = useSession();
	
	const related_cost_centers = cost_centers.filter(x =>
		requisition.cost_center_ids.includes(x._id)
	);
	
	
	return (
		<>
			{
				related_cost_centers.map((cost_center, index) => (
					<Stack
						key={cost_center._id}
						gap={2}
						sx={{ padding: '10px' }}
					>
						<Chip
							label={cost_center.description}
							variant='outlined'
						/>
						
						{
							cost_center.centers.map((x, index) =>(
								<Chip
									key={index}
									label={x.name}
								/>
							))
						}
					</Stack>
				))
			}
		</>
	)
};


export default RequisitionCostCentersList;
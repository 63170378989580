import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ReplaceEquipments } from './Equipments/ReplaceEquipments';
import { ActionProps } from '../../types';
import { Description } from './Description/Description';
import { Controller } from 'react-hook-form';


interface ReplaceProps extends ActionProps {}


export const Replace = (props: ReplaceProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Description {...props} />
			<ReplaceEquipments {...props} />
			<Options {...props}/>
		</Box>
	);
};



interface OptionsProps extends ActionProps {}


const values = ['yes', 'no', 'n/a'] as const;

const labels: Record<typeof values[number], string> =
{
	yes: 'Yes',
	no: 'No',
	'n/a': 'N/A',
};


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	return (
		<Box>
			<Controller
				control={control}
				name={`sections.${sectionIndex}.fields.${fieldIndex}.replace.value`}
				render={({ field: { value, onChange } }) => {
					return (
						<RadioGroup value={value} onChange={onChange}>
							{values.map((value) => {
								return (
									<FormControlLabel
										key={value}
										value={value}
										control={<Radio />}
										label={labels[value]}
									/>
								);
							})}
						</RadioGroup>
					);
				}}
			/>
		</Box>
	);
};



export default Replace;
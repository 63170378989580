import React, { useEffect, useState } from 'react';

import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';

import { CostCenter } from 'models/costs';
import CostCenterCard from './CostCenterCard';




const SearchableCostCentersCardList = ({
	cost_centers,
} : {
	cost_centers: CostCenter[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	const [ filtered_cost_centers, setFilteredCostCenters ] = useState<CostCenter[]>(cost_centers);
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	useEffect(() =>
	{
		setFilteredCostCenters(cost_centers)
	}, [cost_centers])
	
	
	console.log({filtered_issues: filtered_cost_centers});
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			<SearchBarOld
				items={cost_centers}
				handleItems={setFilteredCostCenters}
				fields={['name']}
			/>
			{
				filtered_cost_centers?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((cost_center: CostCenter) => (
					<CostCenterCard cost_center={cost_center} key={cost_center._id} />
				))
			}
			<Pagination
				count={Math.ceil(cost_centers.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default SearchableCostCentersCardList;
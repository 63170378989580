import { DateTime, Interval } from 'luxon';
import { arrayOf, doX } from 'utils';


// Returns the week that starts on the provided Monday at 00:00
export const weekOfMondayMidnight = (monday_midnight: DateTime) =>
{
	return Interval.fromDateTimes(monday_midnight, monday_midnight.plus({weeks: 1}));
}


// Ex: -1 => previous week
export const offsetWeekBy = (week: Interval, offset: number) =>
{
	return Interval.fromDateTimes(
		week.start.plus({week: offset}),
		week.end.plus({week: offset})
	);
}


// Returns the Monday that starts the week containing the provided date
export const relatedMondayMidnight = (datetime: DateTime) =>
{
	return DateTime.fromObject({
		weekYear: datetime.year,
		weekNumber: datetime.weekNumber
	});
}


// Returns the ISO week containing provided date
export const relatedWeek = (datetime: DateTime) =>
{
	return weekOfMondayMidnight(relatedMondayMidnight(datetime));
}


// Provide a central week and the number of weeks before and after to get an array of weeks
export const makeWeekStack =
(
	focus_week: Interval,
	num_weeks_before: number,
	num_weeks_after: number
) =>
{
	let weeks: Interval[] = [];
	
	// Ex: 3 => -3, -2, -1
	doX(num_weeks_before, (i: number) => {
		weeks.push(
			offsetWeekBy(focus_week, -num_weeks_before + i)
		)
	});
	
	weeks.push(focus_week);
	
	// Ex: 3 => 1, 2, 3
	doX(num_weeks_after, (i: number) => {
		weeks.push(
			offsetWeekBy(focus_week, i + 1)
		)
	});
	
	return weeks;
}



// Provide interval to get a Generator object that produces datetimes within interval
// Generators are functions that can be exited and later re-entered
// Their context (variable bindings) will be saved across re-entrances
// Ex: const dayMaker = daysWithinGenerator();
export function* daysWithinGenerator(interval: Interval)
{
	let cursor = interval.start.startOf('day');
	
	while (cursor < interval.end)
	{
		yield cursor;
		
		cursor = cursor.plus({ days: 1 });
	}
}


// Ex: Week Interval => 7 DateTimes (Mon-Sun)
export const daysOfWeek = (week: Interval) : DateTime[] =>
{
	return arrayOf(7, (i: number) => week.start.plus({ days: i }));
}



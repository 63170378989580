import { RecurrenceScheduleType, RecurrenceType } from 'models';
import { Controller, useFormContext } from 'react-hook-form';
import { objFromObj } from 'utils';
import HorizontalCounter from '../fields/counters/HorizontalCounter';
import Formlet from '../fields/Formlet';


const schedule_units =
{
	Daily: 'days',
	Weekly: 'weeks',
	Monthly: 'months',
	Yearly: 'years',
}



export const RecurrenceIntervalFormlet = () =>
{
	const form_context = useFormContext();
	
	const recurrence_schedule = form_context.watch('recurrence.recurrence_schedule');
	const recurrence_type = form_context.watch('recurrence.recurrence_type');
	
	
	if(
		(recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
		||
		(recurrence_type !== RecurrenceType.INTERVAL)
	)
	{
		return null;
	}
	
	
	let units = schedule_units[recurrence_schedule] || null;

	
	// TODO: Add toggle for interval 'since last work order issued' versus 'since work order completed'
	return (
		
		<Formlet
			label='Interval'
			help='How often should work orders be generated?'
		>
			<Controller
				name={'recurrence.interval'}
				render={({ field: { value, onChange } }) =>
					<HorizontalCounter
						name={'recurrence.interval.' + units}
						label='Create Work Order Every…'
						units={units}
						min={1}
						onChange={(new_value) => {
							
							let new_obj = objFromObj(value, (v, k) =>
								(k === units)
									? new_value || 0
									: v || 0
							)
							
							console.log({
								value,
								units,
								new_obj,
								new_value,
							})
							
							onChange(new_obj);
						}}
					/>
				}
			/>
		</Formlet>
	)
}


export default RecurrenceIntervalFormlet;
import { DateTime } from 'luxon';
import { TimeUnit } from 'models';
import {
	ComponentRecord,
} from './component_record_models';
import {
	AreaUnits,
	ComponentRecordType,
	CountUnits,
	CurrentUnits,
	DimensionUnits,
	FlowRateUnits,
	FluidLevelUnits,
	FrequencyUnits,
	PercentageUnits,
	PowerUnits,
	PressureUnits,
	TemperatureUnits,
	TimeElapsedUnits,
	VoltageUnits,
	VolumeUnits,
	WeightUnits
} from './component_record_enums';



export const calculateComponentRecordAverage = (component_record: ComponentRecord) =>
{
	let { initial_reading, last_updated_reading } = component_record;
	
	
	// TODO: Ideally this should have been done immediately
	if(typeof initial_reading.recorded_at_datetime === 'string')
	{
		initial_reading.recorded_at_datetime = DateTime.fromISO(initial_reading.recorded_at_datetime);
	}
	
	if(typeof last_updated_reading.recorded_at_datetime === 'string')
	{
		last_updated_reading.recorded_at_datetime = DateTime.fromISO(last_updated_reading.recorded_at_datetime);
	}
	
	
	let elapsed_days = last_updated_reading.recorded_at_datetime
		.diff(initial_reading.recorded_at_datetime)
		.as('days');
	
	
	return (
		(last_updated_reading.value - initial_reading.value)
			/ elapsed_days
	)
}






// Returns the enum of suitable units matching for a type of record (ex: °C for Temperature)
export const unitsEnumForRecordType = (record_type: ComponentRecordType | string) =>
{
	if(record_type === ComponentRecordType.RUNNING_HOURS)
	{
		return TimeUnit;
	}
	else if(record_type === ComponentRecordType.TEMPERATURE)
	{
		return TemperatureUnits;
	}
	else if(record_type === ComponentRecordType.PRESSURE)
	{
		return PressureUnits;
	}
	else if(record_type === ComponentRecordType.AREA)
	{
		return AreaUnits;
	}
	else if(record_type === ComponentRecordType.VOLUME)
	{
		return VolumeUnits;
	}
	else if(record_type === ComponentRecordType.WEIGHT)
	{
		return WeightUnits;
	}
	else if(record_type === ComponentRecordType.COUNT)
	{
		return CountUnits;
	}
	else if(record_type === ComponentRecordType.CURRENT)
	{
		return CurrentUnits;
	}
	else if(record_type === ComponentRecordType.VOLTAGE)
	{
		return VoltageUnits;
	}
	else if(record_type === ComponentRecordType.POWER)
	{
		return PowerUnits;
	}
	else if(record_type === ComponentRecordType.FLOW_RATE)
	{
		return FlowRateUnits;
	}
	else if(record_type === ComponentRecordType.FLUID_LEVEL)
	{
		return FluidLevelUnits;
	}
	else if(record_type === ComponentRecordType.FREQUENCY)
	{
		return FrequencyUnits;
	}
	else if(record_type === ComponentRecordType.PERCENTAGE)
	{
		return PercentageUnits;
	}
	else if(
		record_type === ComponentRecordType.HEIGHT
		|| record_type === ComponentRecordType.LENGTH
		|| record_type === ComponentRecordType.WIDTH
		|| record_type === ComponentRecordType.DIAMETER
		|| record_type === ComponentRecordType.DEPTH
		)
	{
		return DimensionUnits;
	}
	else if(record_type === ComponentRecordType.TIME_ELAPSED)
	{
		return TimeElapsedUnits;
	}
}





export const isComponentRecordType = (test: any): test is ComponentRecordType =>
{
	return (Object.values(ComponentRecordType).indexOf(test) !== -1);
}



// Returns the default unit for a type of record (ex: °C for Temperature)
export const defaultUnitsForRecordType = (record_type: ComponentRecordType | string) =>
{
	let target_enum;
	
	
	if((record_type === ComponentRecordType.RUNNING_HOURS) || (record_type === 'RUNNING_HOURS'))
	{
		return TimeUnit.HOURS;
	}
	else if((record_type === ComponentRecordType.TEMPERATURE) || (record_type === 'TEMPERATURE'))
	{
		target_enum = TemperatureUnits;
	}
	else if((record_type === ComponentRecordType.PRESSURE) || (record_type === 'PRESSURE'))
	{
		target_enum = PressureUnits;
	}
	else if(record_type === ComponentRecordType.AREA || (record_type === 'AREA'))
	{
		target_enum = AreaUnits;
	}
	else if(record_type === ComponentRecordType.VOLUME || (record_type === 'VOLUME'))
	{
		target_enum = VolumeUnits;
	}
	else if(record_type === ComponentRecordType.WEIGHT || (record_type === 'WEIGHT'))
	{
		target_enum = WeightUnits;
	}
	else if(record_type === ComponentRecordType.COUNT || (record_type === 'COUNT'))
	{
		target_enum = CountUnits;
	}
	else if(record_type === ComponentRecordType.CURRENT || (record_type === 'CURRENT'))
	{
		target_enum = CurrentUnits;
	}
	else if(record_type === ComponentRecordType.VOLTAGE || (record_type === 'VOLTAGE'))
	{
		target_enum = VoltageUnits;
	}
	else if(record_type === ComponentRecordType.POWER || (record_type === 'POWER'))
	{
		target_enum = PowerUnits;
	}
	else if(record_type === ComponentRecordType.FLOW_RATE || (record_type === 'FLOW_RATE'))
	{
		target_enum = FlowRateUnits;
	}
	else if(record_type === ComponentRecordType.FLUID_LEVEL || (record_type === 'FLUID_LEVEL'))
	{
		target_enum = FluidLevelUnits;
	}
	else if(record_type === ComponentRecordType.FREQUENCY || (record_type === 'FREQUENCY'))
	{
		target_enum = FrequencyUnits;
	}
	else if(record_type === ComponentRecordType.PERCENTAGE || (record_type === 'PERCENTAGE'))
	{
		target_enum = PercentageUnits;
	}
	else if(
		record_type === ComponentRecordType.HEIGHT || (record_type === 'HEIGHT')
		|| record_type === ComponentRecordType.LENGTH || (record_type === 'LENGTH')
		|| record_type === ComponentRecordType.WIDTH || (record_type === 'WIDTH')
		|| record_type === ComponentRecordType.DIAMETER || (record_type === 'DIAMETER')
		|| record_type === ComponentRecordType.DEPTH || (record_type === 'DEPTH')
		)
	{
		target_enum = DimensionUnits;
	}
	else if(record_type === ComponentRecordType.TIME_ELAPSED || (record_type === 'TIME_ELAPSED'))
	{
		return TimeElapsedUnits;
	}
	
	
	let target_units = Object.values(target_enum)[0];
	
	
	// console.log({
	// 	record_type,
	// 	target_enum,
	// 	target_units,
	// });
	
	
	return target_units;
}



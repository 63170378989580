import { CircularProgress, Grid } from '@mui/material';
import { useCollection } from 'ember';
import { Issue, WorkOrderNew } from 'models';
import { useAuthData } from 'stuff';
import AssetDashboardIssuesInfo from './AssetDashboardIssuesInfo';
import AssetDashboardWorkOrdersInfo from './AssetDashboardWorkOrdersInfo';



const AssetDashboard = () =>
{
	const { claims } = useAuthData();
	
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	const issues_collection = useCollection(`${org_path}/issues`);
	const work_orders_collection = useCollection(`${org_path}/work_orders`);
	
	
	if(
		issues_collection?.is_loading
		|| work_orders_collection?.is_loading
	)
	{
		return <CircularProgress />
	};
	
	const issues = issues_collection.data as Issue[];
	const work_orders = work_orders_collection.data as WorkOrderNew[];
	
	
	return (
		<Grid
			container
			width='93%'
			sx={{
				margin: '10px'
			}}
			gap={3}
			justifyContent='center'
		>
			
			<Grid
				item
				lg={4}
				xs={12}
			>
				<AssetDashboardIssuesInfo issues={issues} />
			</Grid>
			
			<Grid
				item
				lg={4}
				xs={12}
			>
				<AssetDashboardWorkOrdersInfo work_orders={work_orders} />
			</Grid>
			
			
		</Grid>
	)
};


export default AssetDashboard;
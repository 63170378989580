import { useAuthData } from 'app/auth/AuthDataProvider';
import { useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { Asset, ComponentRecord, CorComponent, Issue } from 'models';
import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { objectifyArray } from 'utils';
import { useURLQueryParam, useURLQueryParamMulti } from 'utils/hooks';
import { useTitle } from 'utils/js_hooks';
import { useSession } from './SessionProvider';



// A core set of data that's shared across the app
// For instance, if the URL changes to indicate that a component has been selected,
// this provider will fetch the component data. This shouldn't be overused to avoid
// making lots of components rerender.
export interface CorData
{
	assets: Asset[],
	selected_asset: Asset,
	selected_system: CorComponent,
	selected_component: CorComponent,
	
	// The top-level roots
	primary_components: CorComponent[],
	// Components under the primary groups/systems
	system_components: CorComponent[],
	selected_components: CorComponent[],
	
	component_records: ComponentRecord[],
	
	setSelectedAssetId: Function,
	setSelectedComponentId: Function,
	setSelectedComponentIds: Function,
	setSelectedSystemId: Function,
	
	modal?: string,
	setModal?: Function,
}



const CorContext = createContext<CorData>({
	assets: [],
	selected_asset: null,
	selected_system: null,
	selected_component: null,
	
	primary_components: [],
	system_components: [],
	selected_components: [],
	
	component_records: [],
	
	setSelectedAssetId: () => {},
	setSelectedComponentId: () => {},
	setSelectedComponentIds: () => {},
	setSelectedSystemId: () => {},
	
	modal: null,
	setModal: () => {},
});



export const useCor = () =>
{
	return useContext(CorContext);
};



export const CorProvider = ({ children }) =>
{
	const { user, claims } = useAuthData();
	
	let location = useLocation();
	
	const { issues } = useSession();
	
	
	
	
	let issues_by_component_id : {[ key: string ] : Issue[] } = {}
	let issues_by_status_by_component_id : {[ key: string ] : {[ key: string ] : Issue[] } } = {}
	
	
	issues.forEach(issue => {
		let component_ids = issue.component_ids;
		
		component_ids.forEach(component_id => {
			let issues_by_this_component_id = issues_by_component_id[component_id] || [];
			
			if(!issues_by_status_by_component_id[component_id])
			{
				issues_by_status_by_component_id[component_id] = {};
			}
			
			let issues_by_status = issues_by_status_by_component_id[component_id];
			
			let issues_by_this_status = issues_by_status[issue?.status] || [];
			
			
			if(!issues_by_this_component_id.includes(issue))
			{
				issues_by_this_component_id.push(issue);
			}
			if(!issues_by_this_status.includes(issue))
			{
				issues_by_this_status.push(issue);
			}
			
			
			issues_by_component_id[component_id] = issues_by_this_component_id;
			issues_by_status[issue?.status] = issues_by_this_status;
		})
	})
	
	
	
	
	// console.log({
	// 	issues,
	// 	issues_by_component_id,
	// 	issues_by_status_by_component_id,
	// })
	
	
	
	
	
	
	// const breadcrumbs_data = useBreadcrumbData();
	
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ url_base, url_query ] = location.pathname.split('?');
	
	const url_parts = url_base.split('/');
	
	
	let asset_id = null;
	let system_id = null;
	let component_id = null;
	let component_ids: string[] = [];
	
	
	// Basically replicating what React Router does with dynamic routing
	url_parts.forEach((part, i) =>
	{
		if(part === 'assets')
		{
			asset_id = url_parts[i + 1];
		}
		if(part === 'systems')
		{
			system_id = url_parts[i + 1];
		}
		if(part === 'components')
		{
			component_id = url_parts[i + 1];
		}
	})
	
	
	// Doesn't work unless nested inside a Route with matching dynamic parts
	// const url_params = useParams();
	// const { asset_id, system_id, component_id } = url_params;
	
	
	// Query parameters will override URL path segments, if present
	let [ selected_asset_id, setSelectedAssetId ] = useURLQueryParam(
		'asset',
		asset_id,
	);
	
	let [ selected_system_id, setSelectedSystemId ] = useURLQueryParam(
		'system',
		system_id,
	);
	
	let [ selected_component_id, setSelectedComponentId ] = useURLQueryParam(
		'component',
		component_id,
	);
	
	let [ selected_component_ids, setSelectedComponentIds ] = useURLQueryParamMulti(
		'components',
		component_ids,
	);
	
	
	let [ modal, setModal ] = useURLQueryParam(
		'modal',
		null,
	);
	
	
	
	// TODO: The system_id should probably already contain the _f suffix
	let target_system_id = (selected_system_id?.slice(-2) === '_f')
		? selected_system_id?.slice(0, -2)
		: selected_system_id
	
	
	let org_path = `organizations/${claims?.organization_id}`;
	
	
	const assets_collection = useCollectionConditionally(
		(claims !== undefined),
		`${org_path}/assets`,
		// where('component_ids', 'array-contains', component_id)
	);
	
	const assets = assets_collection.data as Asset[];
	
	
	const primary_components_collection = useCollectionConditionally(
		(claims !== undefined) && (!!selected_asset_id),
		`${org_path}/components`,
		where('asset_id', '==', selected_asset_id),
		// TODO: This asset refers to AMOS's asset, while the previous refers to CORSphere asset/vessel
		where('asset_code', 'in', ['1', '2', '3', '4', '5', '6', '7', '8', '9']),
	);
	
	const system_components_collection = useCollectionConditionally(
		(claims !== undefined) && (!!selected_asset_id),
		`${org_path}/components`,
		where('asset_id', '==', selected_asset_id || 'nope'),
		where('system_id', '==', target_system_id || 'nope'),
	);
	
	const asset_components_collection = useCollectionConditionally(
		(claims !== undefined) && (!!selected_asset_id),
		`${org_path}/components`,
		where('asset_id', '==', selected_asset_id || 'nope'),
	);
	
	
	
	
	// The roots of our tree, corresponding to SFI coded maintenance functions
	const primary_components = primary_components_collection.data as CorComponent[];
	const system_components = system_components_collection.data as CorComponent[];
	const asset_components = asset_components_collection.data as CorComponent[];
	
	
	let components_by_id = objectifyArray(system_components);
	
	// TODO: Shouldn't handle this many items at once
	let asset_components_by_id = objectifyArray(asset_components);
	
	
	
	const selected_asset = assets?.find(x => (x._id === selected_asset_id));
	const selected_system = components_by_id[selected_system_id] || components_by_id[target_system_id];
	const selected_component = system_components?.find(x => (x._id === selected_component_id));
	
	
	// TODO: Do elsewhere
	useTitle(selected_component?.name || selected_system?.name || selected_asset?.name);
	
	
	const all_component_records_collection = useCollectionConditionally(
		true,
		`${org_path}/component_records`,
		// where('maintenance_function_code', '==', selected_component?.maintenance_function_id)
	);
	
	
	const component_records_collection = useCollectionConditionally(
		(selected_component?.maintenance_function_id !== undefined),
		`${org_path}/component_records`,
		where('maintenance_function_code', '==', selected_component?.maintenance_function_id)
	);
	
	
	
	
	// let pairs: any = {};
	
	// // TODO: Do In specialized loader providers
	// if(selected_asset)
	// {
	// 	pairs.asset = selected_asset;
	// }
	// if(selected_system)
	// {
	// 	pairs.system = selected_system;
	// }
	// if(selected_component)
	// {
	// 	pairs.component = selected_component;
	// }
	
	// if(Object.keys(pairs).length)
	// {
	// 	breadcrumbs_data.setPairs(pairs);
	// }
	
	
	const component_records = component_records_collection?.data as ComponentRecord[];
	const all_component_records = all_component_records_collection?.data as ComponentRecord[];
	
	// const selected_component_records = component_records?.filter(x =>
	// 	(x.maintenance_function_id === selected_component.maintenance_function_id)
	// )
	
	// const selected_component_records2 = component_records?.filter(x =>
	// 	(x.parent_component_record_id === selected_component.asset_id)
	// )
	
	// const selected_component_records3 = component_records?.map(x =>
	// 	asset_components_by_id[x.maintenance_function_id + '_f']
	// ).filter(Boolean)
	
	// const selected_component_records4 = component_records?.map(x =>
	// 	asset_components_by_id[x.maintenance_function_id + '_a']
	// ).filter(Boolean)
	
	
	
	
	component_records?.forEach((x: ComponentRecord) =>
	{
		let maintenance_function = asset_components_by_id[x.maintenance_function_id + '_f'];
		
		
		if(maintenance_function)
		{
			maintenance_function.component_records_by_id = maintenance_function.component_records_by_id || {};
			
			if(!maintenance_function.component_records_by_id[x._id])
			{
				maintenance_function.component_records = maintenance_function.component_records || [];
				maintenance_function.component_records.push(x);
				maintenance_function.component_records_by_id[x._id] = x;
				x.component = maintenance_function;
				
				// console.log(x);
			}
		}
	})
	
	
	// console.log(component_records?.filter(x => x._id === selected_component_id))
	
	
	// let all_component_records_by_id = objectifyArray(
	// 	all_component_records,
	// )
	// let component_records_by_component_id = objectifyArray(
	// 	component_records,
	// 	'component_id',
	// )
	
	// console.log({
	// 	component_records_by_component_id,
	// 	all_component_records_by_id,
	// });
	
	
	
	
	
	
	const selected_components = selected_component_ids?.map(x => components_by_id[x]);
	
	
	
	// Walk through array, looking up parent by ID in map, and pushing the item into its children
	system_components?.forEach(x =>
	{
		x.issues = issues_by_component_id[x._id] || [];
		x.issues_by_status = issues_by_status_by_component_id[x._id] || {};
		
		
		let parent_id = x.parent_asset_id || x.parent_component_id;
		
		if(parent_id)
		{
			let parent = components_by_id[parent_id] as CorComponent;
			
			if(parent)
			{
				parent.children_by_id = parent.children_by_id || {};
				
				if(!parent.children_by_id[x._id])
				{
					parent.children = parent.children || [];
					parent.children.push(x);
					parent.children_by_id[x._id] = x;
					x.parent = parent;
				}
				
				// parent.descendant_issues
			}
		}
	})
	
	
	
	// const listAncestors = (component: CorComponent, ancestors: CorComponent[] = []) =>
	// {
	// 	console.log({
	// 		component,
	// 		ancestors,
	// 	})
		
	// 	if(component?.parent)
	// 	{
	// 		return [component].concat(
	// 			listAncestors(component.parent, ancestors)
	// 		);
	// 	}
	// 	else
	// 	{
	// 		return component;
	// 	}
	// }
	
	
	// let ancestors = (selected_component)
	// 	? listAncestors(selected_component)
	// 	: [];
	
	// console.log(ancestors);
	
	
	
	// asset_components?.forEach(x =>
	// {
	// 	let parent_id = x.parent_asset_id || x.parent_component_id;
		
	// 	let parent = components_by_id[parent_id];
		
		
	// 	if(parent)
	// 	{
			
	// 		console.log({
	// 			parent,
	// 		})
			
	// 		parent.children_by_id = parent.children_by_id || {};
			
	// 		if(!parent.children_by_id[x._id])
	// 		{
	// 			parent.children = parent.children || [];
	// 			parent.children.push(x);
	// 			parent.children_by_id[x._id] = x;
	// 			x.parent = parent;
	// 		}
	// 	}
	// })
	
	
	
	// console.log(
	// 	'%cCorProvider',
	// 	'color:blue; background: yellow; border:2px solid black; font-size: 20px; padding: 4px 8px; border-radius: 5px;',
	// 	{
	// 		user,
	// 		claims,
			
	// 		assets,
	// 		primary_components,
	// 		system_components,
	// 		asset_components,
			
	// 		asset_components_by_id,
	// 		components_by_id,
			
	// 		selected_asset_id,
	// 		selected_system_id,
	// 		selected_component_id,
	// 		selected_component_ids,
			
	// 		selected_asset,
	// 		selected_system,
	// 		selected_component,
	// 		selected_components,
			
	// 		component_records,
	// 		all_component_records,
	// 		// selected_component_records,
	// 		// selected_component_records2,
	// 		// selected_component_records3,
	// 		// selected_component_records4,
			
	// 		// location,
	// 		// url_params,
	// 		asset_id,
	// 		system_id,
	// 		component_id,
	// 	}
	// );
	
	
	return (
		<CorContext.Provider value={{
			assets,
			
			selected_asset,
			selected_system: components_by_id[selected_system?._id],
			selected_component,
			selected_components,
			
			primary_components,
			system_components,
			
			component_records,
			
			setSelectedAssetId,
			setSelectedComponentId,
			setSelectedComponentIds,
			setSelectedSystemId,
			
			modal,
			setModal,
		}}>
			{children}
		</CorContext.Provider>
	);
}



export default CorProvider;
import { Stack, Typography } from '@mui/material';

import { ComponentRecordType } from 'models';
import { useComponentRecord } from 'app/providers/ComponentRecordProvider';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import CorIcon from 'components/icons/CorIcon';
import ComponentRecordForm from 'app/gizmos/component_record/ComponentRecordForm';


const ComponentRecordCardHeader = () =>
{
	const component_record = useComponentRecord();
	
	
	let subtitle = (component_record.type === ComponentRecordType.RUNNING_HOURS)
		? component_record?.type
		: `${component_record?.type} (${component_record?.units})`;

	
	return (
		<Stack
			justifyContent='center'
			alignItems='center'
		>
			
			<Typography
				variant='h6'
				sx={{
					padding: 0,
					textAlign: 'center',
					fontSize: '15px',
				}}
			>
				{component_record.title}
			</Typography>
			
			<Typography
				variant='subtitle1'
				sx={{
					padding: 0,
					opacity: .2,
					fontSize: '13px',
				}}
			>
				{subtitle}
			</Typography>
			
			{
				(component_record?.description)
				&&
				<Typography
					sx={{
						opacity: .6,
					}}
				>
					{component_record?.description}
				</Typography>
			}
			
			
			<ButtonToOpenDialog
				button_variant='text'
				dialog_title={`Edit Component Record`}
				dialog_submit_text='Save Changes'
				dialog_dismiss_text='Revert'
				dialog_content={<ComponentRecordForm component_record={component_record}/>}
				button_content='Edit'
				startIcon={<CorIcon of='edit' size='24'/>}
				form_id='ComponentRecord'
			/>
			
		</Stack>
	)
};


export default ComponentRecordCardHeader;
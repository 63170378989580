import { React, useAuthData, useState } from 'stuff';
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import cuid from 'cuid';
import { DateTime } from 'luxon';


import MobileHeader from '../MobileHeader';
import ManageProfileItems from '../../manage_profile/components/mobile/ManageProfileItems';
import UserProfileAvatarEdit from '../../manage_profile/components/UserProfileAvatarEdit';
import EditEmail from '../../manage_profile/components/mobile/EditEmail';
import EditUsername from '../../manage_profile/components/mobile/EditUsername';
import { useStyles } from '../../../styles/ManageProfileTabTheme';
import { User } from 'types';
import { useDoc, useUpdate, useUploadFile } from 'ember';
import UserAvatar from 'components/avatars/UserAvatar';
import { StorageFile } from 'models';




const UserProfilePage = () =>
{
	const { claims } = useAuthData();
	const classes = useStyles();
	const uploadFile = useUploadFile();
	const update = useUpdate();
	
	let datetime = DateTime.local().toISO();
	
	const [searchParams] = useSearchParams();
	const edited_field= searchParams.getAll('edit');
	
	const [ avatar, setAvatar ] = useState('/images/sazied_test_image.jpg'|| claims?.user_id);
	
	
	const users_collection_path = `organizations/${claims.organization_id}/users`;
	const current_user_doc = useDoc(users_collection_path, claims.user_id);
	
	
	if(current_user_doc.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const current_user = current_user_doc.data as User;
	
	
	const handleDeleteAvatar = () =>
	{
		setAvatar( claims?.user_id )
	}
	
	const handleChangeAvatar = ( event: React.ChangeEvent<HTMLInputElement> ) =>
	{
		// Generate StorageFile from HTML5 File and upload to Firebase Storage
		const uploadOneFile = (file_to_upload: File) =>
		{
			let _id = cuid();
			
			let storage_file : StorageFile =
			{
				_id: _id,
				name: file_to_upload.name,
				path: `organizations/${claims?.organization_id}/files/${_id}`,
				size: file_to_upload.size,
				type: file_to_upload.type,
				upload_date: datetime,
				last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
			}
			
			const updated_user_data =
			{
				...current_user,
				image_path: `organizations/${claims?.organization_id}/files/${_id}`
			}
			
			uploadFile(
				storage_file,
				file_to_upload,
				`organizations/${claims?.organization_id}/files`,
				() => update(`organizations/${claims?.organization_id}/users`, updated_user_data)
			)
		}
		
		uploadOneFile(event.target.files[0]);
	}
	
	if(edited_field[0] === 'email')
	{
		return <EditEmail/>
	}
	else if (edited_field[0] === 'username')
	{
		return <EditUsername/>
	};
	
	
	
	
	
	return (
		<>
			<MobileHeader
				title='User Profile'
				variantType='h3'
			/>
			
			<Box
				className={classes.UserProfilePageContainer}
			>
				
				<Typography
					variant='h3'
					color='#323338'
				>
					General Information
				</Typography>
				
				<Box
					className={classes.UserProfileAvatarContainer}
				>
					
					<Typography
						variant='caption'
					>
						USER PROFILE
					</Typography>
					
					<Box
						className={classes.UserAvatarContainer__mobile}
					>
						<UserAvatar
							user={current_user}
							alt={claims?.name.toUpperCase()}
							src={claims?.user_id}
							cuid={claims?.user_id}
							sx={{
								height: '50px',
								width: '50px',
								border: '1px solid #d8dbdf'
							}}
						/>
					</Box>
					
					<Box
						className={classes.UserProfileContainer}
					>
						<UserProfileAvatarEdit
							setDeleteAvatar={ handleDeleteAvatar }
							setChangeAvatar={ handleChangeAvatar }
						/>
					</Box>
				
				</Box>
				
				<ManageProfileItems/>
				
			</Box>
		</>
	)
}


export default UserProfilePage;
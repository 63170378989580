import { useEffect } from 'react';
// import { useStyles } from './theme';

import { useFormContext, useWatch } from 'react-hook-form';
import CorIcon from 'components/icons/CorIcon';
import { Box, styled, Chip } from '@mui/material';
import { useSessionContext } from 'pages/createIssue/CreateIssueProvider';
import { useComponent } from 'app/providers/ComponentLoader';


export const StyledChip = styled(Chip)(() => ({
	backgroundColor: '#C85656',
	color: 'white',
}));



const SelectedEquipmentList = () =>
{
	const { component } = useComponent();
	console.log('COMPONENT USE COMP', component);
	
	
	
	const { getValues, setValue } = useFormContext();
	
	const { selected_components, set_selected_components } = useSessionContext();
	
	const selected_component_ids = getValues('component_ids');
	const selected_component_names = getValues('component_names');
	
	
	useWatch({ name: 'component_ids' });
	
	
	const removePart = (component) =>
	{
		set_selected_components(selected_components.filter(x => x._id !== component._id));
		
		const new_ids = selected_component_ids.filter((id : string) => id !== component._id);
		const new_names = selected_component_names.filter((name : string) => name !== component.name);
		
		setValue('component_ids', new_ids);
		setValue('component_names', new_names);
	};
	
	
	
	useEffect(() =>
	{
		if(component)
		{
			set_selected_components([...selected_components, component]);
			
			setValue('component_ids', [component._id])
		}
	}, [])
	
	
	
	
	return (
		<>
			<Box
				sx={{
					border: `1px solid #d8dbdf`,
					borderRadius: '3px',
					padding: '0.5rem',
					display: 'flex',
					gap: '0.5rem',
					flexWrap: 'wrap',
					marginTop: '1rem',
				}}
			>
				{selected_components?.map((component, index) => (
					<StyledChip
						key={index}
						label={component.name}
						deleteIcon={
							<CorIcon of='close' size='15px' color='#fff' />
						}
						onDelete={() => removePart(component)}
					/>
				))}
			</Box>
		</>
	)
}


export default SelectedEquipmentList;
import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import palette from './palette';


export const defaultAppTheme = createTheme({
	
	palette: palette,
	
	typography:
	{
		fontFamily: [
			'Nunito',
			'sans-serif'
		].join(','),
		h1:
		{
			fontSize: '24px',
			fontWeight: 700,
			color: '#383838'
		},
		h2:
		{
			fontSize: '18px',
			fontWeight: 700,
			color: '#383838',
		},
		h3:
		{
			fontSize: '18px',
			fontWeight: 500,
			color: '#383838',
		},
		h4:
		{
			fontSize: '18px',
			fontWeight: 300,
			color: '#383838',
		},
		h5:
		{
			fontSize: '16px',
			fontWeight: 500,
			color: '#383838',
		},
		h6:
		{
			fontSize: '16px',
			fontWeight: 400,
			color: '#383838',
		},
		subtitle1:
		{
			fontSize: '14px',
			fontWeight: 700,
		},
		subtitle2:
		{
			fontSize: '14px',
			fontWeight: 500,
		},
		body1:
		{
			fontSize: '14px',
			fontWeight: 400,
		},
		body2:
		{
			fontSize: '12px',
			fontWeight: 700,
		},
		caption:
		{
			fontSize: '12px',
			fontWeight: 300,
		}
	}
});


export const responsiveAppTheme = responsiveFontSizes(defaultAppTheme);


import { Stack, Typography } from '@mui/material';

// import CustomFormGizmo from 'app/gizmos/custom_form/CustomFormGizmo';
import { useFormContext } from 'react-hook-form';
import CreateWorkOrderTemplateSections from '../Sections/CreateWorkOrderTemplateSections';

 
const WOTStep3 = () =>
{
	const form_context = useFormContext();
	
	
	return (
		<Stack
			padding={4}
			gap={2}
			// alignItems='stretch'
		>
			
			{/* <CustomFormGizmo /> */}
			
			<Typography
				component='h2'
				fontSize='18px'
			>
				Add Work Steps/Instructions
				
			</Typography>
			
			<CreateWorkOrderTemplateSections
				form={form_context}
				showPreview={() => console.log('showSectionsPreview')}
			/>
			
		</Stack>
	);
};


export default WOTStep3;
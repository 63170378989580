import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { cuid, DateTime, useAuthData } from 'stuff';
import { excludeUndefinedValues } from 'utils/obj_utils_new';

import {
	ComponentRecord,
	ComponentReading,
} from 'models';
import { useWrite } from 'ember';
import { Alert, Snackbar } from '@mui/material';
import { useComponent } from 'app/providers/ComponentLoader';
import { useParams } from 'react-router';



const ReadingFormContext = createContext<ComponentReading | null>(null);



export const useReadingForm = () =>
{
	return useContext(ReadingFormContext);
}



export const ReadingFormProvider =
({
	component_record,
	component_reading,
	children,
} : {
	component_record?: ComponentRecord,
	component_reading?: ComponentReading,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const { component } = useComponent();
	
	const { work_order_id } = useParams();
	
	const write = useWrite();
	
	const [ show_snackbar, setShowSnackbar ] = useState<boolean>(false);
	
	
	const form = useForm<ComponentReading>({
		defaultValues: {
			_id: cuid(),
			
			value: 0,
			units: component_record.units,
			
			component_id: component?._id || component_record?.component_id,
			component_record_id: component_record._id,
			
			recorded_by_user_id: claims.user_id,
			recorded_by_user_name: claims.name,
			recorded_at_datetime: DateTime.local(),
			
			...component_reading,
		},
	});
	
	
	useEffect(() =>
	{
		if(work_order_id)
		{
			form.setValue(
				'work_order_id',
				work_order_id
			)
		}
	}, [form, work_order_id])
	
	
	
	
	const closeAlert = (event?: React.SyntheticEvent | Event, reason?: string) =>
	{
		if (reason === 'clickaway')
		{
			return;
		}
	
		setShowSnackbar(false);
	};
	
	
	
	const onSubmit = (data: any) =>
	{
		console.log(data);
		
		data = excludeUndefinedValues(data);
		
		// Convert DateTime to ISO formatted string
		if(data.recorded_at_datetime instanceof DateTime)
		{
			data.recorded_at_datetime = data.recorded_at_datetime?.toUTC().toISO();
		}
		
		write(
			`organizations/${claims.organization_id}/component_readings`,
			data
		);
	}
	
	
	const onSubmitError = (props: any) =>
	{
		console.log('Error submitting', props);
	}
	
	
	const handleSubmitForm = (e) =>
	{
		e.preventDefault()
		
		form.handleSubmit(
			onSubmit,
			onSubmitError
		)();
	}
	
	
	return (
		<FormProvider {...form}>
			<form
				id='ComponentRecordReading'
				onSubmit={handleSubmitForm}
			>
				{children}
			</form>
			<Snackbar
				open={show_snackbar}
				autoHideDuration={3000}
				onClose={closeAlert}
				className='alert'
			>
				<Alert
					onClose={closeAlert}
					severity='success'
					sx={{ width: '100%' }}
				>
					Component reading added
				</Alert>
			</Snackbar>
		</FormProvider>
	);
};


export default ReadingFormProvider;
import { useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { Recurrence } from 'models';
import { describeRecurrence } from 'models/recurrence/recurrence_helpers2';


const RecurrenceDescription = () =>
{
	const form_context = useFormContext();
	
	const recurrence = form_context.watch('recurrence') as Recurrence;
	
	
	return (
		<Stack
			sx={{
				bgcolor: '#00f2',
				padding: '8px 16px',
				borderRadius: 1,
			}}
		>
			<Typography>
				{describeRecurrence(recurrence)}
			</Typography>
		</Stack>
	)
}


export default RecurrenceDescription;
import { createContext, useContext, useState } from 'react';


export interface BreadcrumbData
{
	pairs: {
		[key: string]: string,
	},
	// Overwrite the whole set of pairs
	setAllPairs: Function,
	// Combine the existing pairs with these new ones
	setSomePairs: Function,
	// Convert an ID to text from display in the header breadcrumbs
	getDisplayText: Function,
	// Set a translation of an ID to the text that should be displayed
	setDisplayText: (key: string, value: string) => void,
}


const BreadcrumbDataContext = createContext<Partial<BreadcrumbData>>({});


export const useBreadcrumbData = () =>
{
	return useContext(BreadcrumbDataContext);
};


// Allows descendants to set and retrieve info about objects references in the URL
// Ex: breadcrumb_data.pairs.component should contain the component data loaded from database
export const BreadcrumbDataProvider = ({ children }) =>
{
	let [ pairs, setPairs ] = useState({});
	
	
	return (
		<BreadcrumbDataContext.Provider value={{
			pairs,
			setAllPairs: setPairs,
			setSomePairs: (new_pairs) => {
				setPairs(Object.assign(
					{...pairs},
					{...new_pairs}
				))
			},
			
			getDisplayText: (key: string) => {
				return pairs[key]
			},
			setDisplayText: (key: string, value: string) => {
				setPairs(Object.assign(
					{...pairs},
					{
						[key]: value,
					}
				))
			},
		}}>
			{children}
		</BreadcrumbDataContext.Provider>
	);
}


export default BreadcrumbDataProvider;
import { CircularProgress, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useSession } from 'app/providers/SessionProvider';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useState } from 'react';

import CorIcon from 'components/icons/CorIcon';
import SelectTagsFormlet from 'components/selectTags/SelectTagsFormlet';
import { useDelete, useDoc, useWrite } from 'ember';
import { Role, RoleTag } from 'models/users/user_interfaces';
import { useAuthData } from 'stuff';
import { Tag } from 'types';
import RoleTags from './RoleTags';


const RoleCard = ({
	role,
} : {
	role: Role,
}) =>
{
	const { claims } = useAuthData();
	const { all_available_tags, all_tag_roles } = useSession();
	
	const deleteDoc = useDelete();
	const write = useWrite();
	
	const org_collection_path = `organizations/${claims?.organization_id}`;
	
	const [selectedTags, setSelectedTags] = useState<Tag[]>();
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	
	// Bring all Role Tags
	const role_tags_collection = useDoc(`${org_collection_path}/role_tags`, role._id);
	
	
	if(role_tags_collection?.is_loading) return <CircularProgress />
	
	const role_tags = role_tags_collection.data as RoleTag[];
	
	console.log({role_tags});
	
	
	
	
	
	const onTagSelect = (event: React.ChangeEvent<{}>, newValue: Tag[] | null) =>
	{
		setSelectedTags(newValue);
	};
	
	
	const updateRoles = () =>
	{
		let role_tag = {
			_id: role._id,
		}
		selectedTags.forEach(tag => Object.assign(role_tag, { [tag._id]: true }));
		
		
		// Adds and removes tags from ROLE TAGS -----------------------------------------------------
		const selected_tag_ids = selectedTags.map(tag => tag._id);
		const filtered_tag_roles_with_selected_ids = all_tag_roles.filter(x => selected_tag_ids.includes(x._id));
		
		
		const deleted_tag_ids = (role_tags !== undefined) &&
			Object.keys(role_tags).filter(x => !selected_tag_ids.includes(x) && (x!=='_id')) || [];
		
		const filtered_tag_roles_with_deleted_role_ids = all_tag_roles.filter(x => deleted_tag_ids.includes(x._id));
		
		
		// console.log('aayoyooooooooo', {deleted_tag_ids})
		
		
		// Adds tags to roles
		filtered_tag_roles_with_selected_ids?.forEach(role_tag =>
		{
			if(!role_tag[role._id])
			{
				let new_tag_role = {
					[role._id]: true,
					...role_tag
				}
				
				write(`${org_collection_path}/tag_roles`, new_tag_role);
			}
		});
		
		
		if(deleted_tag_ids.length > 0)
		{
			filtered_tag_roles_with_deleted_role_ids?.forEach(tag_role => {
				
				const new_tag_role = tag_role;
				delete new_tag_role[role._id]
				
				console.log('wwwwwwwwwwwwwwwwwwwwwwwwww', {tag_role}, {new_tag_role});
				
				write(`${org_collection_path}/tag_roles`, new_tag_role);
			})
		}
		
		// ---------------------------------------------------------------------------------------------
		
		
		
		
		
		console.log({role_tag});
		write(`${org_collection_path}/role_tags`, role_tag);
		
		setCloseDialog(!closeDialog);
	}
	
	
	
	const removeRoleFromDb = (role: Role) =>
	{
		console.log({role});
		
		deleteDoc(`${org_collection_path}/roles`, role);
	};
	

	
	
	
	return (
		<Stack
			width='100%'
			direction='column'
			alignItems='flex-start'
			justifyContent='center'
			spacing={3}
		>
			<Stack
				width='100%'
				direction='row'
				alignItems='center'
				justifyContent='space-between'
			>
				<Typography
					variant='h3'
				>
					{ role.name }
				</Typography>
				
				<Stack
					direction='row'
					spacing={1}
					alignItems='center'
				>
					<ButtonToOpenDialog
						button_type='icon_button'
						dialog_submit_text='Confirm'
						dialog_title='Assign Tags to Roles'
						dialog_content={
							<SelectTagsFormlet
								label={'Type to search'}
								id='create-issue-tags'
								multiple={true}
								value={selectedTags}
								onChange={onTagSelect}
								tags={all_available_tags}
							/>
						}
						buttonIcon={<CorIcon of='edit' size='24px' />}
						closeDialog={closeDialog}
						handleSubmit={updateRoles}
						dialogClassName='add_tags_dialog'
					/>
					
					<IconButton
						onClick={() => removeRoleFromDb(role)}
					>
						<CorIcon of='delete' size='24px' />
					</IconButton>
				</Stack>
			</Stack>
			
			
			<Stack>
				<RoleTags
					role_tags={role_tags}
					setSelectedTags={setSelectedTags}
				/>
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
			
		</Stack>
	)
};


export default RoleCard;
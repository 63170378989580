import { Recurrence } from 'models';



interface ScheduleInterval
{
	repeats_every: number,
	based_on: 'effective_date' | 'last_completed_date',
}



export const months =
[
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
] as const


export type Month = typeof months[number];



export type ScheduleType = 'daily' | 'weekly' | 'monthly' | 'yearly';



export interface ScheduleFormData
{
	recurrence_schedule: ScheduleType | '',
	
	recurrence: Recurrence,
	
	daily?: ScheduleInterval,
	
	weekly?: {
		interval: ScheduleInterval,
		specific_days: {
			sunday?: boolean,
			monday?: boolean,
			tuesday?: boolean,
			wednesday?: boolean,
			thursday?: boolean,
			friday?: boolean,
			saturday?: boolean,
		},
	},
	
	monthly?: {
		interval: ScheduleInterval,
		specific_days: number,
	},
	
	yearly?: {
		interval: ScheduleInterval,
		specific_month_and_day: {
			month: Month | '',
			day: number | null,
		},
	},
}



export type TimeUnit =
	'years'
	| 'quarters'
	| 'months'
	| 'weeks'
	| 'days'
	| 'hours'
	| 'minutes'
	| 'seconds'
	| 'milliseconds'



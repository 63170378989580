import * as React from 'react';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { RecurrenceType } from 'models';
import { FieldOption } from 'models/fields';


// Will not allow the selected item to be unselected - only replaced
export const ToggleButtonGroupNotEmpty =
({
	options,
	onChange,
	value,
} : {
	options: FieldOption[],
	onChange?: Function,
	value?: string,
}) =>
{
	const handleChange =
	(
		event: React.MouseEvent<HTMLElement>,
		new_type: string | null,
	) =>
	{
		if(new_type !== null)
		{
			onChange(new_type as RecurrenceType);
		}
	};
	
	
	let buttons = options.map((option : FieldOption) =>
		<ToggleButton
			value={option.label}
			aria-label={option.label}
			key={option._id}
			sx={{
				// TODO: Magic number
				// minWidth: 110,
				textTransform: 'none',
			}}
		>
			{option.label}
		</ToggleButton>
	)
	
	
	return (
		<Stack
			spacing={4}
			alignItems='center'
		>
			<ToggleButtonGroup
				value={value}
				exclusive
				onChange={handleChange}
				aria-label='recurrence type'
			>
				{buttons}
			</ToggleButtonGroup>
		</Stack>
	);
}


export default ToggleButtonGroupNotEmpty;
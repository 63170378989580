import LoadingSpinner from 'components/loading/LoadingSpinner';
import { ImageProps } from 'components/images';
import { Avatar } from '@mui/material';
import { useDownloadURL } from 'ember';


// Ex: path = 'cats/newspaper.jpg'
const AvatarFromStorage =
({
	path,
	alt = '',
	title = '',
	...rest
}
: ImageProps ) =>
{
	const { data: image_url, is_loading } = useDownloadURL(path);
	
	
	if(is_loading)
	{
		return <LoadingSpinner />
	}
	
	
	return (
		<Avatar
			alt={alt}
			title={title}
			src={image_url}
			className='chat_avatar'
			{...rest}
		/>
	)
}


export default AvatarFromStorage;
import { Avatar, Stack, Typography } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import TotalCount from './TotalCount';
import ProgressBar from './ProgressBar';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useCorsAnalyticsLoader } from './CorsAnalyticsLoader';
import { ActionStatus } from 'models';
import { relativeDaysDiff } from 'utils';
import { DateTime } from 'luxon';
import ActionsListResponsiveContent from 'app/dashboard/pages/issueCollaboration/actions/ActionsListResponsiveContent';



const CorsActionsSummary = () =>
{
	const { actions } = useCorsAnalyticsLoader();
	
	const due_now = actions?.filter(x => x?.status === ActionStatus.OPEN);
	
	const in_progress = actions?.filter(x => x?.status === ActionStatus.IN_PROGRESS);
	
	const due_immediately = [...in_progress, ...due_now]?.filter(x => relativeDaysDiff(DateTime.fromISO(x?.created_at)) <= -30)
	
	
	
	return (
		<Stack
			className='container'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='center'
			width='100%'
			gap={1}
			sx={{
				minHeight: '350px !important'
			}}
		>
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-start'
				gap={1}
			>
				<Avatar
					sx={{
						bgcolor: defaultAppTheme.palette.warning.light
					}}
				>
					<CorIcon
						size='24px'
						of='open_action'
						color={defaultAppTheme.palette.warning.dark}
					/>
				</Avatar>
				
				<Typography
					variant='h3'
				>
					Actions
				</Typography>
			</Stack>
			
			<Stack
				flexDirection='row'
				alignItems='flex-start'
				justifyContent='flex-start'
				gap={2}
			>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{due_now?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Open
					</Typography>
				</Stack>
				
				<Stack>
					<Typography
						variant='h1'
					>
						{in_progress?.length}
					</Typography>
					
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						In-progress
					</Typography>
				</Stack>
				
				<Stack
					sx={{
						marginLeft: '40px'
					}}
				>
					<Typography
						variant='subtitle2'
						sx={{
							color: defaultAppTheme.palette.grey['500']
						}}
					>
						Active
					</Typography>
					
					<TotalCount count={due_now.length + in_progress.length} />
				</Stack>
				
			</Stack>
			
			<ProgressBar
				value={Math.round((due_immediately.length/(due_now.length + in_progress.length)*100))}
				due_count={due_immediately.length}
				total_count={due_now.length + in_progress.length}
			/>
			
			
			<Stack>
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.error.light }
					/>
					<ButtonToOpenDialog
						button_content='Opened'
						button_variant='text'
						dialog_title='Open Actions'
						dialog_content={<ActionsListResponsiveContent actions={due_now} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
				
				<Stack
					flexDirection='row'
					alignItems='center'
					justifyContent='flex-start'
				>
					<CorIcon
						of='triangle_right'
						size='12px'
						color={defaultAppTheme.palette.grey['500']}
					/>
					<CorIcon
						of='circle_filled'
						size='18px'
						color={ defaultAppTheme.palette.info.main }
					/>
					<ButtonToOpenDialog
						button_content='In-Progress'
						button_variant='text'
						dialog_title='Actions In Progress'
						dialog_content={<ActionsListResponsiveContent actions={in_progress} />}
						sx={{
							textTransform: 'none',
							padding: 'none',
							width: 'fit-content',
							height: 'fit-content',
							fontSize: '15px',
							color: defaultAppTheme.palette.grey['700'],
							'&:hover': {
								textDecoration: 'underline'
							},
						}}
					/>
				</Stack>
			</Stack>
			
		</Stack>
	)
}

export default CorsActionsSummary;
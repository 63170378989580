// Find icons:    https://iconify.design/icon-sets

// Ex: You want a factory icon? Here's how to add it:
// BROWSER:
//     Search for "factory"   https://icon-sets.iconify.design/?query=factory
//     Select desired icon    https://icon-sets.iconify.design/bx/bxs-factory/
// COMMAND LINE
//     If the icon set hasn't been installed, install it
//         npm i @iconify-icons/bx
// UPDATE ICON SET
//     Import the icon (you can rename for simplicity)
//         import factory from '@iconify-icons/bx/bxs-factory';
//     Add the icon to the icon_set object
//         const icon_set =
//         {
//             factory,
//             ...,

// Learn:
// https://github.com/iconify/iconify-react
// https://github.com/iconify/iconify/tree/master/packages/react


// MUI ICON SETS -- https://mui.com/components/material-icons

import attachment from '@iconify-icons/mdi/attachment';
import send from '@iconify-icons/mdi/send';
import submit from '@iconify-icons/carbon/task-complete';

import account from '@iconify-icons/mdi/account';
import help from '@iconify-icons/mdi/help';

import inboxOutline from '@iconify-icons/mdi/inbox-outline';
import packageVariantClosed from '@iconify-icons/mdi/package-variant-closed';
import calculator from '@iconify-icons/mdi/calculator';
import scaleBathroom from '@iconify-icons/mdi/scale-bathroom';
import sack from '@iconify-icons/mdi/sack';
import calendarWeek from '@iconify-icons/mdi/calendar-week';
import calendarToday from '@iconify-icons/mdi/calendar-today';

import plus from '@iconify-icons/mdi/plus';
import minus from '@iconify-icons/mdi/minus';

import plusBox from '@iconify-icons/mdi/plus-box';
import tagPlus from '@iconify-icons/mdi/tag-plus-outline';
import CheckboxBlankCircleOutline from '@iconify-icons/mdi/checkbox-blank-circle-outline';
import CheckboxMarkedCircle from '@iconify-icons/mdi/checkbox-marked-circle';
import minusBox from '@iconify-icons/mdi/minus-box';
import calendarClock from '@iconify-icons/mdi/calendar-clock';
import trash from '@iconify-icons/mdi/delete';
import deleteEmpty from '@iconify-icons/mdi/delete-empty';
import chevronLeft from '@iconify-icons/fa/chevron-left';
import chevronRight from '@iconify-icons/fa/chevron-right';
import lightbulbOn from '@iconify-icons/mdi/lightbulb-on';
import weight from '@iconify-icons/mdi/weight';

import eye from '@iconify-icons/mdi/eye';
import qrcode from '@iconify-icons/mdi/qrcode';

// import home from '@iconify-icons/mdi/home';
// import download from '@iconify-icons/mdi/download';
// import menu from '@iconify-icons/mdi/menu';
// import search from '@iconify-icons/mdi/search';
// import settings from '@iconify-icons/mdi/settings';
// import database from '@iconify-icons/mdi/database';
// import publish from '@iconify-icons/mdi/publish';
// import album from '@iconify-icons/mdi/album';

import sigma from '@iconify-icons/mdi/sigma';
import print from '@iconify-icons/mdi/printer';
import factory from '@iconify-icons/mdi/factory';
import library from '@iconify-icons/mdi/library';
import warehouse from '@iconify-icons/mdi/warehouse';
import shuffle from '@iconify-icons/mdi/shuffle';

import down_triangle from '@iconify-icons/mdi/arrow-drop-down';
import arrow_left from '@iconify-icons/mdi/arrow-left';
// import arrow_right from '@iconify-icons/mdi/arrow-right';
import chevron_left from '@iconify-icons/mdi/chevron-left';
import chevron_right from '@iconify-icons/mdi/chevron-right';

import add from '@iconify-icons/mdi/add';
import table from '@iconify-icons/mdi/table';
import download_network from '@iconify-icons/mdi/download-network';

import cancel from '@iconify-icons/mdi/cancel';
import edit from '@iconify-icons/mdi/edit';
import deleteIcon from '@iconify-icons/mdi/delete';

import circle from '@iconify-icons/mdi/circle';
import sync from '@iconify-icons/mdi/sync';

import view from '@iconify-icons/mdi/card-search';
import heat from '@iconify-icons/mdi/radiator';

import play from '@iconify-icons/mdi/play';
import pause from '@iconify-icons/mdi/pause';
import stopIcon from '@iconify-icons/mdi/stop';

import form_select from '@iconify-icons/mdi/form-select';

import fire from '@iconify-icons/mdi/fire';
import wrench from '@iconify-icons/mdi/wrench';
import flag from '@iconify-icons/mdi/flag';
import layers from '@iconify-icons/mdi/layers';

import loginBoxLine from '@iconify-icons/ri/login-box-line';


import robot from '@iconify-icons/mdi/robot';
import test_tube from '@iconify-icons/mdi/test-tube';

import alert_circle from '@iconify-icons/mdi/alert-circle';
import dog_side from '@iconify-icons/mdi/dog-side';
import caret_right from '@iconify-icons/fa/caret-right';
import caret_down from '@iconify-icons/fa/caret-down';
import text_box_plus from '@iconify-icons/mdi/text-box-plus';

import chevron_up from '@iconify-icons/fa/chevron-up';
import chevron_down from '@iconify-icons/fa/chevron-down';

import task_tools from '@iconify-icons/carbon/task-tools';

import account_group from '@iconify-icons/mdi/account-group';
import file_document_multiple from '@iconify-icons/mdi/file-document-multiple';


// import logoutBoxLine from '@iconify-icons/ri/logout-box-line';
// import message from '@iconify-icons/mdi/message';

// import close from '@iconify-icons/mdi/close';
// import check from '@iconify-icons/mdi/check';
// import history from '@iconify-icons/mdi/history';
// import file_document from '@iconify-icons/mdi/file-document';
// import square_rounded from '@iconify-icons/mdi/square-rounded';
// import warning from '@iconify-icons/mdi/warning';

//import tray_arrow_down from '@iconify-icons/mdi/tray-arrow-down';

// import emailIcon from '@iconify-icons/carbon/email';
// import lockTwotone from '@iconify-icons/ant-design/lock-twotone';


import eyeOutlined from '@iconify-icons/ant-design/eye-outlined';
import eyeInvisibleOutlined from '@iconify-icons/ant-design/eye-invisible-outlined';
import closeOutlined from '@iconify-icons/ant-design/close-outlined';
import plusOutlined from '@iconify-icons/ant-design/plus-outlined';
import minusOutlined from '@iconify-icons/ant-design/minus-outlined';

import userAvatar from '@iconify/icons-carbon/user-avatar';
import userMultiple from '@iconify/icons-carbon/user-multiple';
import tagsOutlined from '@iconify/icons-ant-design/tags-outlined';


// import clockOutline from '@iconify-icons/mdi/clock-outline';

import list from '@iconify-icons/mdi/format-list-bulleted-square';
import pressure from '@iconify-icons/mdi/compress';
import timerIcon from '@iconify-icons/mdi/timer';

import temperature from '@iconify-icons/mdi/temperature';
import parent_template from '@iconify-icons/mdi/file-tree';
import high_voltage from '@iconify/icons-mdi/high-voltage';
import voltage from '@iconify/icons-mdi/lightning-bolt';

import deviceReset from '@iconify/icons-material-symbols/device-reset';








// import lineWeightIcon from '@iconify-icons/mdi/format-line-weight';


// Material Design Icons
// import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
// import MenuIcon from '@mui/icons-material/Menu';
// import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
// import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
// import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
// import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
// import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
// import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
// import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
// import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import CenterFocusStrongOutlinedIcon from '@mui/icons-material/CenterFocusStrongOutlined';
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
// import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
// import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
// import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
// import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
// import StyleIcon from '@mui/icons-material/Style';
// import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
// import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';
// import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
// import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
// import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
// import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
// import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
// import DataThresholdingRoundedIcon from '@mui/icons-material/DataThresholdingRounded';
// import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
// import NearbyErrorRoundedIcon from '@mui/icons-material/NearbyErrorRounded';
// import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import GroupsIcon from '@mui/icons-material/Groups';
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';
// import FileOpenIcon from '@mui/icons-material/FileOpen';
// import HistoryIcon from '@mui/icons-material/History';
// import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
// import FilePresentIcon from '@mui/icons-material/FilePresent';
// import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
// import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
// import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import Map from '@mui/icons-material/Map';
// import Co2Icon from '@mui/icons-material/Co2';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import EvStationIcon from '@mui/icons-material/EvStation';
// import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
// import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';












// ----------------------------------------------------------------------------------------------------------
// Production Icons
import homeOutlined from '@iconify/icons-ant-design/home-outlined';
import treeViewAlt from '@iconify/icons-carbon/tree-view-alt';
import licenseMaintenanceDraft from '@iconify/icons-carbon/license-maintenance-draft';
import settingOutlined from '@iconify/icons-ant-design/setting-outlined';
import logoutLine from '@iconify/icons-clarity/logout-line';
import menuAltLeft from '@iconify/icons-bx/menu-alt-left';
import outlineKeyboardArrowLeft from '@iconify/icons-ic/outline-keyboard-arrow-left';
import crossIcon from '@iconify/icons-gridicons/cross';
import appstoreAddOutlined from '@iconify/icons-ant-design/appstore-add-outlined';
import scanLight from '@iconify/icons-ph/scan-light';
import bellOutlined from '@iconify/icons-ant-design/bell-outlined';
import searchOutlined from '@iconify/icons-ant-design/search-outlined';
import filter2Line from '@iconify/icons-clarity/filter-2-line';
import arrowRightAlt2 from '@iconify/icons-dashicons/arrow-right-alt2';
import squareRounded from '@iconify/icons-material-symbols/square-rounded';
import triangleRight from '@iconify/icons-codicon/triangle-right';
import triangleDown from '@iconify/icons-codicon/triangle-down';
import circleSmall24Filled from '@iconify/icons-fluent/circle-small-24-filled';
import textBulletListSquareWarning24Regular from '@iconify/icons-fluent/text-bullet-list-square-warning-24-regular';
import errorCircleSettings20Regular from '@iconify/icons-fluent/error-circle-settings-20-regular';
import settingsServices from '@iconify/icons-carbon/settings-services';
import settingsView from '@iconify/icons-carbon/settings-view';
import settingsCheck from '@iconify/icons-carbon/settings-check';
import editSettings24Regular from '@iconify/icons-fluent/edit-settings-24-regular';
import textBulletListSquareToolbox20Regular from '@iconify/icons-fluent/text-bullet-list-square-toolbox-20-regular';
import textBulletListSquareEdit24Regular from '@iconify/icons-fluent/text-bullet-list-square-edit-24-regular';
import textBulletListSquareShield20Regular from '@iconify/icons-fluent/text-bullet-list-square-shield-20-regular';
import textBulletListSquareClock20Regular from '@iconify/icons-fluent/text-bullet-list-square-clock-20-regular';
import dataTableReference from '@iconify/icons-carbon/data-table-reference';
import qrCode2Rounded from '@iconify/icons-material-symbols/qr-code-2-rounded';
import qrCodeScannerRounded from '@iconify/icons-material-symbols/qr-code-scanner-rounded';
import warehouseIcon from '@iconify/icons-maki/warehouse';
import circleIcon from '@iconify/icons-akar-icons/circle';
import circleCheckFill from '@iconify/icons-akar-icons/circle-check-fill';
import usersOutlineAlerted from '@iconify/icons-clarity/users-outline-alerted';
import lockPasswordFill from '@iconify/icons-ri/lock-password-fill';
import baselineEmail from '@iconify/icons-ic/baseline-email';
import eyeFilled from '@iconify/icons-ant-design/eye-filled';
import eyeInvisibleFilled from '@iconify/icons-ant-design/eye-invisible-filled';
import editName from '@iconify/icons-icon-park-solid/edit-name';
import licenseMaintenance from '@iconify/icons-carbon/license-maintenance';
import warningIcon from '@iconify/icons-healthicons/warning';
import fileDownload from '@iconify/icons-fa-solid/file-download';
import assignUserSolid from '@iconify/icons-clarity/assign-user-solid';
import roleBinding from '@iconify/icons-eos-icons/role-binding';
import roundCheck from '@iconify/icons-ic/round-check';
import warningRounded from '@iconify/icons-material-symbols/warning-rounded';
import layersActive from '@iconify/icons-codicon/layers-active';
import iot20Regular from '@iconify/icons-fluent/iot-20-regular';
import moneyDollarBoxLine from '@iconify/icons-ri/money-dollar-box-line';
import calendarClockOutline from '@iconify/icons-mdi/calendar-clock-outline';
import dollarIcon from '@iconify/icons-mdi/dollar';
import addHomeOutlineRounded from '@iconify/icons-material-symbols/add-home-outline-rounded';
import addNotesOutlineRounded from '@iconify/icons-material-symbols/add-notes-outline-rounded';
import homeDollar from '@iconify/icons-tabler/home-dollar';
import homeExclamation from '@iconify/icons-tabler/home-exclamation';
import homeDown from '@iconify/icons-tabler/home-down';
import homeCheck from '@iconify/icons-tabler/home-check';
import homeHand from '@iconify/icons-tabler/home-hand';
import homeLink from '@iconify/icons-tabler/home-link';
import homeBolt from '@iconify/icons-tabler/home-bolt';
import calendarAccountOutline from '@iconify/icons-mdi/calendar-account-outline';
import certificateOutline from '@iconify/icons-teenyicons/certificate-outline';
import bIDashboard from '@iconify/icons-fluent-mdl2/b-i-dashboard';
import circle32Filled from '@iconify/icons-fluent/circle-32-filled';
import circle20Filled from '@iconify/icons-fluent/circle-20-filled';
import addCircleOutline from '@iconify/icons-material-symbols/add-circle-outline';





import ibmWatsonOrders from '@iconify/icons-carbon/ibm-watson-orders';
import fileIcon from '@iconify/icons-akar-icons/file';
import settingsOutlineBadged from '@iconify/icons-clarity/settings-outline-badged';
import arrowLeftAlt2 from '@iconify/icons-dashicons/arrow-left-alt2';
import plusCircleOutlined from '@iconify/icons-ant-design/plus-circle-outlined';
import treeViewLine from '@iconify/icons-clarity/tree-view-line';





// ----------------------------------------------------------------------------------------------------------



// Our full set of icons. Some are renamed.
const icon_set =
{
	// Production Icons
	// ------------------------------------------------------------------------------------------------------
	home: homeOutlined,
	asset_tree: treeViewAlt,
	issue: settingsServices,
	open_issue: settingsView,
	closed_issue: settingsCheck,
	create_issue: editSettings24Regular,
	work_order: textBulletListSquareToolbox20Regular,
	create_work_order: textBulletListSquareEdit24Regular,
	open_work_order: textBulletListSquareWarning24Regular,
	closed_work_order: textBulletListSquareShield20Regular,
	waiting_work_order: textBulletListSquareClock20Regular,
	setting: settingOutlined,
	logout: logoutLine,
	menu: menuAltLeft,
	// arrow_left: arrowLeftAlt2,
	back: outlineKeyboardArrowLeft,
	// plus: plusCircleOutlined,
	close: crossIcon,
	menu_plus: appstoreAddOutlined,
	scan: scanLight,
	notification: bellOutlined,
	search: searchOutlined,
	filter: filter2Line,
	arrow_right: arrowRightAlt2,
	square_rounded: squareRounded,
	triangle_right: triangleRight,
	triangle_down: triangleDown,
	circle_filled: circle20Filled,
	pending_list: textBulletListSquareWarning24Regular,
	open_issues: errorCircleSettings20Regular,
	technical_data: dataTableReference,
	qr_code: qrCode2Rounded,
	qr_code_scanner: qrCodeScannerRounded,
	// file: fileIcon,
	inventory: warehouseIcon,
	empty_circle: circleIcon,
	circle_checked: circleCheckFill,
	alert_users: usersOutlineAlerted,
	password: lockPasswordFill,
	email: baselineEmail,
	eye_open: eyeFilled,
	eye_close: eyeInvisibleFilled,
	name: editName,
	open_action: licenseMaintenance,
	closed_action: licenseMaintenanceDraft,
	warning: warningIcon,
	file_download: fileDownload,
	assign_to_user: assignUserSolid,
	role: roleBinding,
	check: roundCheck,
	warning_triangle: warningRounded,
	active: layersActive,
	iot: iot20Regular,
	cost_outline: moneyDollarBoxLine,
	timeline: calendarClockOutline,
	cost: dollarIcon,
	add_inventory: addHomeOutlineRounded,
	add_list: addNotesOutlineRounded,
	
	
	requisition_open: homeExclamation,
	requisition_waiting_approval: homeDollar,
	requisition_in_progress: homeDown,
	requisition_completed: homeCheck,
	create_requisition: homeHand,
	requisition_link: homeLink,
	requisition_list: homeBolt,
	my_page: calendarAccountOutline,
	
	certificate: certificateOutline,
	data_analytics: bIDashboard,
	
	
	
	
	pressure,
	running_hours: timerIcon,
	time: timerIcon,
	temperature,
	voltage,
	
	reset_datetime: deviceReset,
	
	parent_template,
	list,
	add: addCircleOutline,








	// -------------------------------------------------------------------------------------------------------









	// -----------------------------------------------------------------------------------------------------
	send,
	submit,
	attachment,

	robot,







	print,
	calculator,

	create: add,
	cancel,
	edit,
	delete: deleteIcon,

	form_select: form_select,

	change: sync,
	view,
	heat,

	play,
	pause,
	stopIcon,

	eye,

	fire,
	wrench,
	flag,
	layers: layers,

	random: shuffle,
	test: test_tube,
	table,

	weight,
	library,
	warehouse,

	qrcode,
	download_network,

	arrow_left,
	down_triangle,
	circle,
	chevron_left,
	chevron_right,

	production: factory,

	total: sigma,

	mass: weight,
	// time: clockOutline,
	scale: scaleBathroom,

	people: account,
	person: account,
	unknown: help,
	
	bin: inboxOutline,
	box: packageVariantClosed,
	batch: packageVariantClosed,
	estimator: calculator,
	sack: sack,
	week: calendarWeek,
	day: calendarToday,
	
	plus_basic: plus,
	minus_basic: minus,
	
	plus: plusBox,
	minus: minusBox,
	
	
	schedule: calendarClock,
	trash: trash,
	open_trash: deleteEmpty,
	left: chevronLeft,
	right: chevronRight,
	product: lightbulbOn,

	login: loginBoxLine,


	// badge icons
	alert: alert_circle,

	account_group,
	files: file_document_multiple,

	//download: tray_arrow_down,

	dog: dog_side,
	caret_right,
	caret_down,
	alert_circle: alert_circle,
	text_box_plus: text_box_plus,
	chevron_up,
	chevron_down,

	task_tools: task_tools,

	show: eyeOutlined,
	do_not_show: eyeInvisibleOutlined,

	tag_plus: tagPlus,
	checkbox_blank_circle: CheckboxBlankCircleOutline,
	checkbox_marked_circle: CheckboxMarkedCircle,
	close_outlined: closeOutlined,
	plus_outlined: plusOutlined,
	minus_outlined: minusOutlined,

	user_avatar: userAvatar,
	user_multiple: userMultiple,
	tags_outlined: tagsOutlined,

}


export default icon_set;
import { Controller } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { ActionProps } from '../../types';


interface TextProps extends ActionProps {}


export const Instruction =
({
	control,
	sectionIndex: parentFieldIndex,
	fieldIndex: optionIndex,
}: TextProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Controller
				control={control}
				name={
					`sections.${parentFieldIndex}.fields.${optionIndex}.instruction.description` as const
				}
				defaultValue=''
				render={({ field: { value, onChange } }) => {
					return (
						<TextField
							value={value}
							onChange={onChange}
							label='Please add the necessary Instructions'
							variant={'filled'}
						/>
					);
				}}
			/>
		</Box>
	);
};


export default Instruction;
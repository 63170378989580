import { RecurrenceType } from 'models';
import { Controller } from 'react-hook-form';
import EnumToggleField from 'app/gizmos/fields/toggles/EnumToggleField';
import Formlet from '../fields/Formlet';


// Choose between Interval and Specific Days
export const RecurrenceTypeFormlet =
({
	label = 'Recurrence Type',
	name = 'recurrence.recurrence_type',
} : {
	label?: string,
	name?: string,
}) =>
{
	return (
		<Formlet
			label={label}
			help='Define how work orders will be automatically generated'
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<EnumToggleField
						url_param_key='recurrence'
						enum_type={RecurrenceType}
						value={value}
						onChange={onChange}
					/>
				}
			/>
		</Formlet>
	);
}


export default RecurrenceTypeFormlet;
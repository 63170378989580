import { Box, TextField, RadioGroup, Radio, Button } from '@mui/material';
import {
	Controller,
	FieldArrayWithId,
	useFieldArray,
	useWatch,
} from 'react-hook-form';
import { ActionProps } from '../../types';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';


export interface MultipleChoiceProps extends ActionProps {}


export const RadioField = (props: MultipleChoiceProps) =>
{
	return (
		<>
			<Description {...props} />
			<Options {...props} />
		</>
	);
};


interface DescriptionProps extends ActionProps {}


const Description = ({ control, sectionIndex, fieldIndex }: DescriptionProps) =>
{
	return (
		<Controller
			control={control}
			name={`sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.description`}
			defaultValue=''
			render={({ field: { value, onChange } }) => (
				<TextField
					value={value}
					onChange={onChange}
					label='Work-step Details'
					variant='filled'
				/>
			)}
		/>
	);
};


interface OptionsProps extends ActionProps {}


const Options = ({ control, sectionIndex, fieldIndex }: OptionsProps) =>
{
	const { fields, update, append } = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options`,
	});
	
	const options = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options`,
	});
	
	
	// useEffect(() =>
	// 	{
	// 		if(fields.length === 0)
	// 		{
	// 			append({});
	// 		}
	// 	},
	// 	[append, fields.length]
	// );
	
	
	const handleSelectedOptionChange = (index: number) =>
	{
		options.forEach((option, idx) => {
			update(idx, {
				label: option.label,
				checked: index === idx,
			});
		});
	};
	
	
	return (
		<Box>
			<RadioGroup
				onChange={(e) => {
					const index = +e.target.value;
					if (isNaN(index)) return;
					handleSelectedOptionChange(index);
				}}
			>
				{fields.map((field, i) => (
					<Option
						key={i}
						control={control}
						field={field}
						sectionIndex={sectionIndex}
						fieldIndex={fieldIndex}
						optionIndex={i}
					/>
				))}
			</RadioGroup>
			
			<Button
				startIcon={<AddIcon />}
				fullWidth={false}
				onClick={() => append({})}
				sx={{ marginLeft: '1rem' }}
			>
				Add Option
			</Button>
		</Box>
	);
};


interface OptionProps extends Omit<ActionProps, 'fieldId'>
{
	field: FieldArrayWithId<
		StepperForm,
		`sections.${number}.fields.${number}.multiple_choice.options`,
		'id'
	>;
	optionIndex: number;
}


const Option =
({
	control,
	field,
	fieldIndex,
	sectionIndex,
	optionIndex,
}: OptionProps) =>
{
	return (
		<Box
			key={field.id}
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.5rem',
			}}
		>
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options.${optionIndex}.checked` as const
				}
				defaultValue={false}
				render={({ field: { value, onChange } }) => (
					<Radio
						key={field.id}
						value={optionIndex}
						checked={!!value}
						onChange={(e) => {
							const value = !!e.target.value;
							onChange(value);
						}}
					/>
				)}
			/>
			
			<Controller
				control={control}
				name={
					`sections.${sectionIndex}.fields.${fieldIndex}.multiple_choice.options.${optionIndex}.label` as const
				}
				defaultValue=''
				render={({ field: { value, onChange } }) => (
					<TextField
						key={field.id}
						variant='standard'
						placeholder={`Option ${optionIndex + 1}`}
						value={value}
						onChange={onChange}
					/>
				)}
			/>
		</Box>
	);
};


export default RadioField;
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

// import Divider from '@mui/material/Divider';

import { useDoc } from 'ember';

import { CorComponent } from 'models';
import { useParams } from 'react-router';
import { useAuthData } from 'stuff';

import ComponentInfoCard from './componentsDrawer/ComponentInfoCard';
import ComponentDrawerTabs from './componentsDrawer/ComponentDrawerTabs';


// TODO: This shouldn't be refetching the same data as the panel next to it
//       selectedComponent isn't being passed in
const ComponentInfoPanel =
({
	selectedComponent,
} : {
	selectedComponent: CorComponent,
}) =>
{
	const { component_id } = useParams();
	const { claims } = useAuthData();
	
	// TODO
	// Get component data from params
	const component_doc = useDoc(`organizations/${claims?.organization_id}/components/${component_id}`);
	
	if(component_doc?.is_loading) return <CircularProgress />
	
	const component_data = component_doc?.data as CorComponent;
	
	
	return (
		<Box
			sx={{
				background: '#fff',
				height: '100%',
				borderRadius: '5px',
			}}
		>
			
			{/* <Divider orientation='horizontal' sx={{ width: '100%', marginBottom: '15px' }} /> */}
			
			<Box className='mobile_nav_drawer_header'>
				<ComponentInfoCard component={selectedComponent || component_data} />
			</Box>
			
			<ComponentDrawerTabs component={selectedComponent || component_data} />
		</Box>
	)
}


export default ComponentInfoPanel;
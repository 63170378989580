import { passFailValues } from 'pages/CreateWorkOrder/work_order_types';
import { Box, Radio } from '@mui/material';


export const PassFail = () =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{passFailValues.map((value, key) => (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
					}}
					key={key}
				>
					<Radio disabled={true} />
					<Box>{value}</Box>
				</Box>
			))}
		</Box>
	);
};


export default PassFail;
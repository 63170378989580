import { Recurrence, RecurrenceEffectiveType, RecurrenceScheduleType, RecurrenceType } from 'models';
import { useFormContext } from 'react-hook-form';
import DatetimeFormlet from '../fields/datetimes/DatetimeFormlet';


// Choose between Immediately, Delay Until, and Effective Between
export const EffectiveDatetimeFormlet =
({
	label = 'Effective Type',
	name = 'recurrence.effective_type',
} : {
	label?: string,
	name?: string,
}) =>
{
	let form_context = useFormContext();
	
	let recurrence = form_context.watch('recurrence') as Recurrence;
	
	
	// if(recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
	// {
	// 	return null;
	// }
	
	
	// let odd_cases =
	// 	(recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
	// 	||
	// 	(
	// 		(recurrence.recurrence_schedule === RecurrenceScheduleType.DAILY)
	// 		&&
	// 		(recurrence.recurrence_type === RecurrenceType.SCHEDULE)
	// 	)
	
	
	// let msg1 = (recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
	// 	? 'Allow External Triggers'
	// 	: 
	
	let start =
		((recurrence.recurrence_schedule === RecurrenceScheduleType.ON_DEMAND)
		||
		(recurrence.effective_type !== RecurrenceEffectiveType.START_IMMEDIATELY))
		&&
		<DatetimeFormlet
			name='recurrence.effective_datetime'
			label={
				(recurrence.recurrence_schedule === RecurrenceScheduleType.DAILY)
				&&
				(recurrence.recurrence_type === RecurrenceType.SCHEDULE)
				? 'Generate At'
				: 'Start Generating From'
			}
			help='When this template becomes effective - can be used to delay/suspend work order generation'
		/>
	
	// TODO: Add ability to disable end date for on demand recurrence?
	let end =
		(recurrence.effective_type === RecurrenceEffectiveType.EFFECTIVE_BETWEEN)
		&&
		<DatetimeFormlet
			name='recurrence.effective_until_datetime'
			label='Generate Until'
			help='Generate work orders until this date/time - can be used to suspend work order generation'
		/>
	
	
	return (
		<>
			{start}
			{end}
		</>
	);
}


export default EffectiveDatetimeFormlet;
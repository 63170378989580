import { ActionType, StepperForm } from 'pages/CreateWorkOrder/work_order_types';
import {
	Control,
	UseFieldArrayMove,
	UseFieldArrayRemove,
	useWatch,
} from 'react-hook-form';
import { Box, Divider } from '@mui/material';
import { ActionTypeSelect } from './ActionTypeSelect/ActionTypeSelect';
import { Text } from './ActionTypes/Text/Text';
import { RadioField } from './ActionTypes/MultipleChoice/RadioField';
import { ActionProps } from './types';
import { CheckBox } from './ActionTypes/CheckBox/CheckBox';
import { PassFail } from './ActionTypes/PassFail/PassFail';
import { YesNo } from './ActionTypes/YesNo/YesNo';
import { ReactNode } from 'react';
import { Date } from './ActionTypes/Date/Date';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Reading } from './ActionTypes/Reading/Reading';
import { Replace } from './ActionTypes/Replace/Replace';
import Instruction from './ActionTypes/Text/Instruction';



export interface FieldProps
{
	control: Control<StepperForm>;
	sectionIndex: number;
	fieldIndex: number;
	remove: UseFieldArrayRemove;
	move: UseFieldArrayMove;
}



export const Field =
({
	control,
	sectionIndex,
	fieldIndex,
	remove,
	move,
}
: FieldProps) =>
{
	const action_type = useWatch({
		control,
		name: `sections.${sectionIndex}.fields.${fieldIndex}.action_type`,
	});
	
	const fields = useWatch({
		control,
		name: `sections.${sectionIndex}.fields`,
	});
	
	const actionProp: ActionProps =
	{
		control,
		sectionIndex,
		fieldIndex,
	};
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Divider
				sx={{
					marginTop: '3rem',
					marginBottom: '2rem',
				}}
			/>
			
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<ActionTypeSelect {...actionProp} />
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '1rem',
					}}
				>
					{fields.length > 1 && fieldIndex < fields.length - 1 && (
						<Box
							sx={{ cursor: 'pointer' }}
							onClick={() => move(fieldIndex, fieldIndex + 1)}
						>
							<ArrowDownwardIcon />
						</Box>
					)}
					{fields.length > 1 && fieldIndex !== 0 && (
						<Box
							sx={{ cursor: 'pointer' }}
							onClick={() => move(fieldIndex, fieldIndex - 1)}
						>
							<ArrowUpwardIcon />
						</Box>
					)}
					{fields.length > 1 && (
						<DeleteOutlinedIcon
							onClick={() => {
								remove(fieldIndex);
							}}
						/>
					)}
				</Box>
			</Box>
			
			{
				(action_type !== '')
				&&
				renderActionType(action_type, actionProp)
			}
			
		</Box>
	);
};



// TODO: Why not use enum instead of strings?
const renderActionType =
(
	action_type: ActionType,
	actionProp: ActionProps
): ReactNode =>
{
	switch (action_type)
	{
		case 'text':
			return <Text {...actionProp} />;
		
		case 'multiple_choice':
			return <RadioField {...actionProp} />;
		
		case 'checkbox':
			return <CheckBox {...actionProp} />;
		
		case 'pass_fail':
			return <PassFail {...actionProp} />;
		
		case 'yes_no':
			return <YesNo {...actionProp} />;
		
		case 'date':
			return <Date {...actionProp} />;
		
		case 'reading':
			return <Reading {...actionProp} />;
		
		case 'replace':
			return <Replace {...actionProp} />;
			
		case 'instruction':
			return <Instruction {...actionProp} />;
		
		default:
			return <Text {...actionProp} />;
	}
};



export default Field;
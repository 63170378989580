import { Stack, Typography } from '@mui/material';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { DateTime } from 'luxon';
import { isString } from 'lodash';


// TODO: Generalize?
const RecordedAt =
({
	datetime,
	label,
} : {
	datetime: DateTime | string,
	label?: string,
}) =>
{
	if(!datetime)
	{
		return null;
	}
	
	
	if(isString(datetime))
	{
		datetime = DateTime.fromISO(datetime).toLocal();
	}
	
	
	return (
		<Stack
			direction='column'
			padding={.5}
			flexWrap='wrap'
			justifyContent='center'
		>
			
			{
				(label)
				&&
				<Typography
					variant='subtitle1'
					sx={{
						color: defaultAppTheme.palette.grey['500'],
						textAlign: 'center',
					}}
				>
					{label}
				</Typography>
			}
			
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				flexWrap='wrap'
			>
				<Typography
					variant='body1'
					sx={{
						fontSize: '12px',
						opacity: .5,
					}}
				>
					Recorded:&nbsp;
				</Typography>
				
				<Typography
					variant='body2'
					textAlign='center'
				>
					{fullDateAndTimeFromISO(
						datetime
					)}
				</Typography>
			</Stack>
			
		</Stack>
	)
};


export default RecordedAt;
import Box from '@mui/material/Box';
import CorIcon from 'components/icons/CorIcon';


// pass the File object and I will render box type file components with icon
const FileButton =
({
	_file
} : {
	_file: File;
}) =>
{
	return (
		<Box
			className = { 'file' }
		>
			<CorIcon
				of = { 'files' }
				className = {''}
				size = { '1.2rem' }
				color = { '#C26F6F' }
			/>
			
			<Box>
				{ _file.name }
			</Box>
			
			<CorIcon
				of = { 'download' }
				className = {''}
				size = { '1.2rem' }
				color = { '#C26F6F' }
			/>
		</Box>
	)
}


export default FileButton;
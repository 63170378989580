import CorIcon from 'components/icons/CorIcon';


export interface NestedItemProps
{
	id_title: string;
	title: string;
	icon_name: string;
	color: string; 
};


const Nested_Item =
({
	id_title,
	title,
	icon_name,
	color,
}
: NestedItemProps) =>
{
	const match_ItemColor = () =>
	{
		if ( color === 'jade' )
		{
			return '#8ac926';
		}
		else if ( color === 'eggplant' )
		{
			return '#6a4c93';
		}
		else if ( color === 'ocean' )
		{
			return '#1982c4';
		}
		else if ( color === 'blush' )
		{
			return '#C26F6F';
		}
	}
	
	
	return (
		<>
			<input 
				type='checkbox'
				id={ 'check' + id_title }
			/>
			
			<label
				htmlFor={ 'check' + id_title }
			>
				<div
					className='nestedItem'
				>
					<span 
						className={ 
							['nestedItem__iconContainer',  
							[' nestedItem--',color].join('')]
							.join(' ') 
						}
					>
						<CorIcon
							of={ icon_name }
							className='nestedItem__icon'
							size='1.2rem'
							color={ match_ItemColor() }
						/>
					</span>
					
					<span
						className='nestedItem__title'
					>
						{ title }
					</span>
					
					<span 
						className={ ['nestedItem__iconContainer', 'iconRight'].join(' ')}
					>
						<CorIcon
							of='chevron_right'
							className='nestedItem__icon'
							size='1.2rem'
							color='#4C4C4C'
						/>
					</span>
				</div>
				
				<i
					id={ 'cancel' + id_title }
				>
					<CorIcon
						of='arrow_right'
						size='1.3rem'
						color='#4C4C4C'
					/>
				</i>
				
			</label>
		</>
	)
}


export default Nested_Item;
import { useNavigate } from 'react-router-dom';
import { useAuthData, useTitle } from 'stuff';
import { useCollection } from 'ember';

import { Box, Typography, Stack, CircularProgress, Button } from '@mui/material';


import CorIcon from 'components/icons/CorIcon';
import { orderBy } from 'firebase/firestore';
import { Requisition } from 'models';
import ResponsiveRequisitionsList from './ResponsiveRequisitionsList';




// Children will be able to use this hook
// const work_orders = useWorkOrders();
const RequisitionsListMain = () =>
{
	const navigate = useNavigate();
	
	const { claims } = useAuthData();
	
	useTitle('Work Orders');
	
	
	const requisitions_collection = useCollection(
			`organizations/${claims?.organization_id}/requisitions`,
			orderBy('created_at', 'desc')
		);
	
	
	if(requisitions_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	const requisitions = requisitions_collection?.data as Requisition[];
	
	
	//console.log({work_order_templates})
	
	
	return(
		<Box
			sx={{
				margin: '20px',
				height: '100%'
			}}
		>
			
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{
					marginBottom: '20px',
				}}
			>
				<Typography
					variant='h1'
				>
					Requisitions
				</Typography>
				
				<Button
					variant='contained'
					onClick={() => navigate('/create-requisition')}
					startIcon={<CorIcon of='create_requisition' size='25px'/>}
				>
					Create Requisition
				</Button>
			</Stack>
			
			<ResponsiveRequisitionsList
				requisitions={requisitions}
				title='Requisitions List'
			/>
			
		</Box>
	)
};


export default RequisitionsListMain;
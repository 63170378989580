import { Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import { ComponentReading } from 'models';


const ReadingValue = ({ reading } : { reading: ComponentReading}) =>
{
	return (
		<Stack
			direction='column'
			sx={{
				alignItems: 'center',
				justifyContent: 'center',
				padding: '6px',
				borderRadius: '4px',
				background: alpha(defaultAppTheme.palette.primary.light, .4),
			}}
		>
			<Typography
				sx={{
					opacity: 1,
					fontWeight: 700,
					fontSize: '20px',
				}}
			>
				{reading?.value}
			</Typography>
			<Typography
				sx={{
					opacity: .5,
				}}
			>
				{reading?.units}
			</Typography>
		</Stack>
	)
};


export default ReadingValue;
import { useDoc } from 'ember';
import { useNavigate, useParams } from 'react-router';
import { useAuthData } from 'stuff';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import CorIcon from 'components/icons/CorIcon';

import CircularProgress from '@mui/material/CircularProgress';
import SystemsGrid from './SystemsGrid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { Asset, System } from 'models';
import SystemsAssetRefCard from '../../../../components/cards/SystemsAssetRefCard';
import { useCor } from 'app/providers/CorProvider';


const SystemsNavigationPage =
({
	setSelectedSystem,
	selectedAsset,
	setSelectedAsset,
} : {
	setSelectedSystem?: Function,
	selectedAsset?: Asset,
	setSelectedAsset?: Function,
}) =>
{
	const navigate = useNavigate();
	
	const { asset_id } = useParams();
	
	const { claims } = useAuthData();
	
	const { primary_components, setSelectedSystemId } = useCor();
	
	
	// Get systems data for selected asset
	// const systems_collection_path = `organizations/${claims?.organization_id}/systems`;
	// const systems_collection = useCollection(systems_collection_path, where('asset_id', '==', selectedAsset?._id || asset_id));
	
	// Get the asset data
	const assets_collection_path = `organizations/${claims?.organization_id}/assets`;
	const asset_doc = useDoc(assets_collection_path, selectedAsset?._id || asset_id)
	
	
	
	// if(systems_collection.is_loading || asset_doc.is_loading)
	if(asset_doc.is_loading)
	{
		return <CircularProgress />
	}
	
	const systems = primary_components as System[];
	const asset = asset_doc.data as Asset;
	
	console.log({
		claims,
		systems,
		asset,
	});
	
	
	
	const onBack = () =>
	{
		if(window.location.pathname === `/assets/${asset_id}/systems`)
		{
			navigate('/assets')
		}
		else
		{
			setSelectedAsset(undefined);
		}
	}
	
	
	
	
	return (
		<div className='systems_page'>
			
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				width='90%'
			>
				<CorIcon
					of='back'
					size='40px'
					onClick={() => onBack()}
					color= {defaultAppTheme?.palette?.primary.main}
					cursor='pointer'
				/>
				
				<Typography variant='h3'>
					Systems
				</Typography>
				
				<div style={{ width: '25px' }}>
					
				</div>
			</Stack>
			
			
			<SystemsAssetRefCard asset={asset} />
			
			<Divider
				sx={{
					width: '80%',
					color: defaultAppTheme.palette.grey['900'],
					marginBottom: '10px'
				}}
			/>
			
			<SystemsGrid
				systems={systems}
				setSelectedSystem={setSelectedSystem}
			/>
		</div>
	)
};

export default SystemsNavigationPage;
import { Controller, useFormContext } from 'react-hook-form';
import { Box, TextField } from '@mui/material';

import { FormInputType, ResponseField } from 'types/forms';

import { WorkOrderResponseForm } from 'types/forms';


export interface ReadingProps
{
	field: ResponseField & {
		type: FormInputType.Reading,
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const Reading = ({ field, sectionIndex, fieldIndex }: ReadingProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	const { component_record, readingType } = field.value;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '0.5rem',
				}}
			>
				Equipments:{' '}
				
						<Box
							sx={{
								display: 'inline-block',
								backgroundColor: '#C85656',
								paddingBlock: '0.25rem',
								paddingInline: '0.5rem',
								borderRadius: '5px',
								color: 'white',
							}}
						>
							{component_record.title}
						</Box>
			</Box>
			{
				(readingType === 'cumulative')
				&&
				<Controller
					control={control}
					name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response`}
					render={({ field: { value, onChange } }) => (
						<TextField
							variant='outlined'
							placeholder='Maximum Reading'
							value={value}
							onChange={(e) => onChange(e.target.value)}
						/>
					)}
				/>
			}
			{
				(readingType === 'increment')
				&&
				<Controller
					control={control}
					name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response`}
					render={({ field: { value, onChange } }) => (
						<TextField
							variant='outlined'
							placeholder='Minimum Reading'
							value={value}
							onChange={(e) => onChange(e.target.value)}
						/>
					)}
				/>
			}
		</Box>
	);
};


export default Reading;
import { Action } from 'models';
import { Grid } from '@mui/material';
import ActionsTable from './ActionsTable';
import SearchableActionsCardList from './SearchableActionsCardList';


const ActionsListResponsiveContent = ({
	actions,
} : {
	actions: Action[],
}) =>
{
	return (
		<Grid container>
			<Grid
				item
				className='cards_container'
				display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
			>
				<ActionsTable actions={actions} title={"My Actions List"}/>
			</Grid>
			
			<Grid
				item
				className='cards_container'
				display={{xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
			>
				<SearchableActionsCardList
					actions={actions}
				/>
			</Grid>
		</Grid>
	)
};


export default ActionsListResponsiveContent;
import { createContext, ReactNode, useContext } from 'react';

import { useCollection } from 'ember';
import { useAuthData } from '../auth/AuthDataProvider';

import { Asset, Part } from 'types/misc_interfaces';
import { CostCenter, Issue } from 'models';
import { Tag } from 'types';
import { WorkOrderOld } from 'models';
import { Role, RoleTag, TagRole, User } from 'models/users/user_interfaces';
// import { User } from 'models/users/user_interfaces';


export interface Session
{
	users: User[];
	all_available_tags: Tag[];
	issues: Issue[];
	assets: Asset[];
	parts: Part[];
	work_orders: WorkOrderOld[];
	roles: Role[];
	all_tag_roles: TagRole[];
	all_role_tags: RoleTag[];
	cost_centers: CostCenter[];
	
	
	current_user?: User;
	current_user_roles?: Role[];
	
	relevant_role_tags?: RoleTag[];
	relevant_tags?: Tag[];
}


const SessionContext = createContext<Session>({
	users: [],
	all_available_tags: [],
	issues: [],
	assets: [],
	parts: [],
	work_orders: [],
	roles: [],
	all_tag_roles: [],
	all_role_tags: [],
	cost_centers: [],
	
	
	current_user: undefined,
	current_user_roles: [],
	relevant_role_tags: [],
	relevant_tags: [],
});


export const useSession = () =>
{
	return useContext(SessionContext);
};


// TODO: Break this up
const SessionProvider = ({ children }: { children: ReactNode }) =>
{
	const { user, claims } = useAuthData();
	
	
	// TODO
	let org = `organizations/${claims.organization_id}/`;
	
	
	const users_collection = useCollection(org + 'users');
	
	const tags_collection = useCollection(org + 'tags');
	
	const issues_collection = useCollection(org + 'issues');
	
	const work_order_collection = useCollection(org + 'Work_orders');
	
	const assets_collection = useCollection(org + 'assets');
	
	const parts_collection = useCollection(org + 'parts');
	
	const roles_collection = useCollection(org + 'roles');
	
	const tag_roles_collection = useCollection(org + 'tag_roles');
	
	const role_tags_collection = useCollection(org + 'role_tags');
	
	const cost_centers_collection = useCollection(org + 'cost_centers');
	
	
	// console.log('parts collection', parts_collection);
	
	// if (!user || !claims.organization_id)
	// {
	// 	return null;
	// }
	
	
	
	
	const users = users_collection.data as User[];
	const all_available_tags = tags_collection.data as Tag[];
	const issues = issues_collection.data as Issue[];
	const assets = assets_collection.data as Asset[];
	const parts = parts_collection.data as Part[];
	const work_orders = work_order_collection.data as WorkOrderOld[];
	const roles = roles_collection.data as Role[];
	const all_tag_roles = tag_roles_collection.data as TagRole[];
	const all_role_tags = role_tags_collection.data as RoleTag[];
	const cost_centers = cost_centers_collection?.data as CostCenter[];
	
	
	
	
	
	const current_user = users?.find(doc => (doc._id === user.uid));
	
	
	// An object mapping Role IDs to true if they're relevant to the User
	const is_role_relevant_by_role_id = {};
	
	current_user?.roles?.forEach(x =>
	{
		is_role_relevant_by_role_id[x._id] = true;
	});
	
	
	// Filter all RoleTags by Role ID to only include the ones relevant to the User
	const relevant_role_tags = all_role_tags?.filter(role_tag =>
		(is_role_relevant_by_role_id[role_tag._id])
	);
	
	
	// An object mapping Tag IDs to true if they're relevant to the User
	let is_relevant_by_tag_id = {};
	
	// Go through all of the User's Roles, adding referenced Tag IDs to our map
	// Any duplicate matches will harmlessly overwrite existing {tag_id: true} pairing
	relevant_role_tags?.forEach(role_tag =>
	{
		let { _id, ...is_tag_linked_to_role_by_tag_id} = role_tag;
		
		Object.keys(is_tag_linked_to_role_by_tag_id).forEach(tag_id =>
			is_relevant_by_tag_id[tag_id] = true
		);
	})
	
	// Extract any tags that are referenced in our map
	const relevant_tags = all_available_tags?.filter(tag =>
		is_relevant_by_tag_id[tag._id]
	);
	
	
	
	let value: Session =
	{
		users: users || [],
		all_available_tags: all_available_tags || [],
		issues: issues || [],
		assets: assets || [],
		parts: parts ?? [],
		work_orders: work_orders,
		roles: roles || [],
		all_tag_roles: all_tag_roles|| [],
		all_role_tags: all_role_tags || [],
		cost_centers: cost_centers || [],
		
		current_user: current_user,
		relevant_role_tags: relevant_role_tags,
		relevant_tags: relevant_tags,
	};
	
	
	
	// console.log('Session data', {
	// 	value,
	// 	user,
	// 	claims,
	// 	// tag_ids_referenced_by_user_roles,
	// 	relevant_tags,
	// });
	
	
	
	return (
		<SessionContext.Provider value={value}>
			{children}
		</SessionContext.Provider>
	);
};


export default SessionProvider;

import CorIcon from 'components/icons/CorIcon';


// check status and return corresponding Icons
const BadgeIcon =
({
	status,
} : {
	status: string | undefined,
}) =>
{
	const mustard: string = '#DC9A1B';
	const apple: string = '#C7372F';
	
	
	if ( status !== undefined && status !== '' )
	{
		const badge_status = status.toLowerCase();
		
		var icon_set: string = '';
		var icon_color: string = '';
		
		if ( badge_status === 'warning' )
		{
			icon_set = 'warning';
			icon_color = mustard;
		}
		else if ( badge_status === 'alert' )
		{
			icon_set = 'alert';
			icon_color = apple;
		}
		
		if ( badge_status !== '' )
		{
			return (
				<CorIcon
					of = { icon_set }
					className = {''}
					size = { '1.2rem' }
					color = { icon_color }
				/>
			)
		}
	};
};


export default BadgeIcon;
import React, {
	useState,
	useEffect,
} from 'react';

import { MentionData } from '@draft-js-plugins/mention';

import TextInput from './text_input/TextInput';
import InputFormButtons from './InputFormButtons';
import { ChatUser, IssueLog } from '../../../interfaces/content_data_interfaces';
import { DateTime } from 'luxon';

const cn : string = 'commentForm';


export interface InputFormProps 
{
	collaborators: ChatUser[];
	thisUser: ChatUser;
	formSubmit: (form_obj_: IssueLog) => void;
};


const InputForm =
({
	collaborators,
	thisUser,
	formSubmit,
}
: InputFormProps) =>
{
	const [ textBody, setTextBody ] = useState<string[]>([]);
	const [ attached_files, setAttachedFiles ] = useState<File[]>([]);
	const [ is_resolved, setIsResolved ] = useState<boolean>(false);
	const [ collabs, setCollabs ] = useState<MentionData[]>([]);
	
	
	let input_text_ref: { clearTextField: () => void; };
	
	
	// Initialized mentions suggestion list
	useEffect(() =>
		{
			const mentions_array: MentionData[] = [];
			
			if ( collaborators !== undefined )
			{
				collaborators.map(( user ) =>
				{
					const mention_obj = {
						name: ( '@' + user.username ).toString(),
						avatar: user.profilePic
					};
					return mentions_array.push( mention_obj )
				});
				
				setCollabs( mentions_array );
			}
		},
		[ collaborators ]
	)
	
	
	// Convert FileList to array
	const fileChangeHandler = ( file_list: FileList | null) =>
	{
		// console.log(event);
		if ( file_list != null)
		{
			const files_array: File[] = Array.from( file_list );
			setAttachedFiles( files_array );
		}
	};
	
	
	// Create comment/message object send back to parent
	const handleSubmit = ( event: React.SyntheticEvent ) =>
	{
		event.preventDefault();
		
		const content =
		{
			text: textBody,
			date_sent: DateTime.local().toISO(),
			
			comment: textBody,
			file: attached_files,
			is_resolved: is_resolved,
		}
		
		
		const userObject = ( user: ChatUser ) =>
		{
			return {
				createdAt: user.createdAt,
				email: user.email,
				profilePic: user.profilePic,
				updatedAt: user.updatedAt,
				username: user.username,
				_id: user._id,
				__v: user.__v,
				position: user.position,
			}
		}
		
		
		const currentUser : ChatUser = userObject( thisUser );
		
		const comment_obj = 
		{
			headline:
			{
				user: currentUser,
				timestamp: 'now'
			},
			body:
			{
				comment: content.comment,
				file: content.file,
				resolved: content.is_resolved
			}
		};
		
		formSubmit( comment_obj );
		
		clearUp();
	}
	
	
	// Clear all the textinput, files, and Resolved btn
	const clearUp = () =>
	{
		setIsResolved( false )
		setTextBody([]);
		setAttachedFiles( [] );
		
		input_text_ref?.clearTextField();
	}
	
	
	return (
		<>
			<div
				className={ cn }
			>
				
				<div
					className={ cn + '__chatBubble' }
				>
					
					<form
						onSubmit={ handleSubmit }
					>
						
						<TextInput
							textInputStyle={ 'editor' }
							placeholder={ 'Leave a comment' }
							onChange={ ( paragraph ) => setTextBody( paragraph ) }
							mentions={ collabs }
							_ref={ c => (input_text_ref as any) = c }
						/>
						
						<InputFormButtons
							attached_files={ attached_files }
							fileChangeHandler={ fileChangeHandler }
							isResolved={ is_resolved }
							setIsResolved={ () => setIsResolved( !is_resolved ) }
						/>
						
					</form>
				</div>
			</div>
		</>
	)
}


export default InputForm;
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useWrite } from 'ember';
import { useState } from 'react';
import { useAuthData } from 'stuff';
import cuid from 'cuid';
import RolesList from './RolesList';
import { Role } from 'models/users/user_interfaces';

const ManageRoles = () =>
{
	const { claims } = useAuthData();
	const write = useWrite();
	
	const [ role_name, setRoleName ] = useState<string>('');
	
	
	const addRoleToDb = () =>
	{
		console.log({role_name});
		
		const role_data: Role = {
			_id: cuid(),
			name: role_name,
		}
		
		write(
			`organizations/${claims?.organization_id}/roles`,
			role_data
		);
		
		write(
			`organizations/${claims?.organization_id}/role_tags`,
			{_id: role_data._id},
			() => setRoleName('')
		);
	};
	
	
	return (
		<Stack
			direction='column'
			alignItems='flex-start'
			justifyContent='flex-start'
			spacing={2}
			width='100%'
			className='container'
		>
			
			<Typography
				variant='h6'
			>
				Add a new Role
			</Typography>
				
			
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={2}
				width='100%'
			>
					<TextField
						label='Role Name'
						placeholder='e.g. Engineering'
						value={role_name}
						onChange={e => setRoleName(e.target.value)}
						sx={{
							width: '50%'
						}}
					/>
					
					<Button
						variant='contained'
						onClick={addRoleToDb}
					>
						Add Role
					</Button>
			</Stack>
			
			<RolesList />
			
		</Stack>
	)
};


export default ManageRoles;
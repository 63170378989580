import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Typography,
	useTheme,
} from "@mui/material";

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';



ChartJS.register(ArcElement, Tooltip, Legend);

const IssueSeverityPieChart = (props: { issues }) =>
{
	const theme = useTheme();
	
	const { issues } = props;
	
	const issues_severity_high = issues.filter(issue => issue.severity === 'high');
	const issues_severity_medium = issues.filter(issue => issue.severity === 'medium');
	const issues_severity_low = issues.filter(issue => issue.severity === 'low');
	
	
	
	const high_percentage = Math.round(issues_severity_high.length / issues.length * 100);
	const medium_percentage = Math.round(issues_severity_medium.length / issues.length * 100);
	const low_percentage = Math.round(issues_severity_low.length / issues.length * 100);
	
	
	
	
	const data: any = {
		datasets: [
		{
			data: [low_percentage, medium_percentage, high_percentage],
			backgroundColor: ["#3470B5", "#F8C20F", "#e53935"],
			borderWidth: 8,
			borderColor: "#FFFFFF",
			hoverBorderColor: "#FFFFFF",
		},
		],
		labels: ["Low", "Medium", "High"],
	};
	
	const options: any = {
			animation: false,
			cutoutPercentage: 80,
			layout: { padding: 0 },
			legend: {
			display: false,
		},
			maintainAspectRatio: false,
			responsive: true,
			tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: "index",
			titleFontColor: theme.palette.text.primary,
		},
	};
	
	const devices = [
		{
			title: "Low",
			value: low_percentage,
			icon: ErrorRoundedIcon,
			color: "#3470B5",
		},
		{
			title: "Medium",
			value: medium_percentage,
			icon: WarningRoundedIcon,
			color: "#F8C20F",
		},
		{
			title: "High",
			value: high_percentage,
			icon: ReportRoundedIcon,
			color: "#e53935",
		},
	];
	
	
	
	return (
		<Card sx={{ width: "100%", minWidth: '300px', marginBottom: '20px' }} {...props}>
			
			<CardHeader title="Issues By Severity" />
			
			<Divider />
			
			<CardContent>
				<Box
					sx={{
						height: 300,
						position: "relative",
					}}
				>
					<Doughnut data={data} options={options} />
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						pt: 2,
					}}
				>
				{devices.map(({ color, icon: Icon, title, value }) => (
					<Box
						key={title}
						sx={{
							p: 1,
							textAlign: "center",
						}}
					>
					<Icon color="action" />
					<Typography color="textPrimary" variant="body1">
						{title}
					</Typography>
					<Typography style={{ color }} variant="h4">
						{value}%
					</Typography>
					</Box>
				))}
				</Box>
			</CardContent>
			
		</Card>
	);
};


export default IssueSeverityPieChart;
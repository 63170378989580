import { Grid } from '@mui/material'
import EnumSelectFormlet from 'app/gizmos/fields/select/EnumSelectFormlet'
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet'
import { useSearchAndSort } from 'components/searchAndSort/SearchAndSortStateProvider'
import SearchBarNew from 'components/searchAndSort/SearchBarNew'
import { IssueStatus } from 'models'
import React from 'react'


const IssuesSearch = () =>
{
	const { assets } = useSearchAndSort();
	
	return (
		<Grid
			item
			width='100%'
		>
			<Grid
				container
				width='100%'
				justifyContent='center'
			>
				<Grid item>
					<SearchBarNew
						name='name'
						label='Search by name'
					/>
				</Grid>
				
				<Grid item>
					<SearchableSelectFormlet
						name='assets'
						label='Filter by assets'
						options={assets}
						getOptionLabel={option => option.name}
						multiple={true}
					/>
				</Grid>
				
				<Grid item>
					<EnumSelectFormlet
						name='statuses'
						label='Filter by status'
						enum_type={IssueStatus}
						multiple={true}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default IssuesSearch
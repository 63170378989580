import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';

import { useDoc } from 'ember';
import { useAuthData, useEffect } from 'stuff';

import WOTCollabProvider from './WOTCollabProvider';

import WorkOrderTemplateCollaborationHeader from './WOTCollabHeader';
import WorkOrderTemplateCollaborationTabs from './WOTCollabTabs';

import { WorkOrderTemplate } from 'models';
import { useBreadcrumbData } from 'app/providers/BreadcrumbDataProvider';

// import WOTDataProvider from 'pages/CreateWorkOrder/updated/WOTDataProvider';


const WOTCollabPage = () =>
{
	const params = useParams();
	const { work_order_template_id } = params;
	
	const { claims } = useAuthData();
	
	const breadcrumbs_data = useBreadcrumbData();
	
	
	// Get the work order data
	const work_order_templates_collection_path = `organizations/${claims?.organization_id}/work_order_templates`;
	const work_order_template_doc = useDoc(work_order_templates_collection_path, work_order_template_id);
	
	
	//console.log ("I reached here yay. And id is:", id);
	
	
	
	useEffect(() =>
	{
		if(!work_order_template_doc?.is_loading)
		{
			let wo_template = work_order_template_doc?.data as WorkOrderTemplate;
			
			breadcrumbs_data.setDisplayText(wo_template._id, wo_template.title)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [work_order_template_doc?.is_loading])
	
	
	
	
	if(work_order_template_doc?.is_loading)
	{
		return <CircularProgress />
	}
	
	const wo_template = work_order_template_doc?.data as WorkOrderTemplate;
	
	
	console.log({wo_template});
	
	
	
	return (
		<WOTCollabProvider wo_template={wo_template}>
			<div className='issue_collab_page'>
				
				<WorkOrderTemplateCollaborationHeader />
				
				<WorkOrderTemplateCollaborationTabs />
				
			</div>
		</WOTCollabProvider>
	)
}


export default WOTCollabPage
import { UseFormReturn, useWatch } from 'react-hook-form';
import { OuterContainer, InnerContainer, GridContainer } from './styles';
import { ScheduleTypeSelect } from './ScheduleTypeSelect/ScheduleTypeSelect';
import { Daily } from './Daily/Daily';
import { ScheduleFormData } from '../scheduling_types';
import { Weekly } from './Weekly/Weekly';
import { Monthly } from './Monthly/Monthly';
import { Yearly } from './Yearly/Yearly';
import DescribeSchedule from './common/RepeatTime/DescribeSchedule';


export interface ScheduleProps
{
	form: UseFormReturn<ScheduleFormData>;
}


export const Schedule = ({ form }: ScheduleProps) =>
{
	const { handleSubmit, control, setValue } = form;
	
	
	const recurrence_schedule = useWatch({
		control,
		name: 'recurrence_schedule',
	});
	
	
	const onSubmit = (data: any) =>
	{
		console.log('onSubmit', data);
	};
	
	
	
	return (
		// <form
		// 	onSubmit={handleSubmit(onSubmit)}
		// 	style={{ height: '100%', width: '100%' }} // handle this later
		// >
			<OuterContainer>
				<InnerContainer>
					<ScheduleTypeSelect control={control} />
					
					<DescribeSchedule
						control={control}
					/>
					
					<GridContainer>
						{recurrence_schedule === 'daily' && (
							<Daily control={control} setValue={setValue} />
						)}
						{recurrence_schedule === 'weekly' && (
							<Weekly control={control} setValue={setValue} />
						)}
						{recurrence_schedule === 'monthly' && (
							<Monthly control={control} setValue={setValue} />
						)}
						{recurrence_schedule === 'yearly' && (
							<Yearly control={control} setValue={setValue} />
						)}
					</GridContainer>
				</InnerContainer>
			</OuterContainer>
		// </form>
	);
};


export default Schedule;
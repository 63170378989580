import { useRef, useEffect } from 'stuff';

import QrScanner from 'qr-scanner';
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router';



QrScanner.WORKER_PATH = 'workers/qr-scanner-worker.min.js';



interface ScannerI
{
	setQrCodeValue?: any,
	setQrCodeDetected?: any,
	qrCodeDetected?: boolean,
	
	handleQrCodeFound?: Function,
}



const Scanner = ( props: ScannerI ) =>
{
	const { setQrCodeValue, setQrCodeDetected, qrCodeDetected } = props;
	
	const webcamRef = useRef(null);
	const scanAreaRef = useRef(null);
	
	const navigate = useNavigate();
	
	
	
	const videoConstraints =
	{
		width: 640,
		height: 360,
		facingMode: { exact: 'environment' }
	};
	
	
	// TODO: Review to ensure reorganization didn't break
	useEffect(() =>
		{
			const onDecodeOld = async (result: string) =>
			{
				try
				{
					console.log('QR code =', result);
					
					if(result)
					{
						const qr_code_data = JSON.parse(result);
						
						setQrCodeValue(qr_code_data);
						setQrCodeDetected(true);
					}
				}
				catch (error)
				{
					console.log(error || 'No QR code found')
				}
			}
			
			
			// Can decode a JSON object payload with path property, or a URL string
			const onDecode = (result: any) =>
			{
				try
				{
					console.log('QR scanner result', result);
					
					let data = JSON.parse(result);
					
					if(data)
					{
						if(data.path)
						{
							let destination = (data.path[0] === '/')
								? data.path
								: data.path.slice(1);
							
							navigate(destination);
							
							props.handleQrCodeFound?.();
						}
						else
						{
							if(typeof data === 'string')
							{
								let destination = data.replace('https://app.corsphere.com', '');
								
								console.log({
									payload: data,
									destination,
								});
								
								navigate(destination);
								
								props.handleQrCodeFound?.();
							}
							else
							{
								console.warn(
									`QR payload doesn't contain path property and isn't URL string`,
									data,
								);
							}
						}
						
						// const qr_code_data = JSON.parse(result);
						
						// setQrCodeValue(qr_code_data);
						// setQrCodeDetected(true);
					}
				}
				catch (error)
				{
					console.log(error || 'No QR code found')
				}
			}
			
			
			
			const init = ()	=>
			{
				try
				{
					const scanner_new : QrScanner = new QrScanner(
						webcamRef?.current?.video,
						onDecode,
						{
							// onDecodeError?: (error: Error | string) => void,
							// calculateScanRegion?: (video: HTMLVideoElement) => QrScanner.ScanRegion,
							// preferredCamera?: QrScanner.FacingMode | QrScanner.DeviceId,
							// maxScansPerSecond?: number,
							// highlightScanRegion?: boolean,
							// highlightCodeOutline?: boolean,
							// overlay?: HTMLDivElement,
							// /** just a temporary flag until we switch entirely to the new api */
							// returnDetailedScanResult?: true,
						},
					);
					
					
					const scanner_old: QrScanner = new QrScanner(webcamRef?.current?.video, onDecode);
					
					
					
					const scanner = scanner_old;
					
					
					scanAreaRef?.current.appendChild(scanner.$canvas);
					
					scanner.$canvas.style.display = 'block';
					
					scanner.start();
					
					// QrScanner.scanImage(qr_code1)
					// .then(result => console.log(result))
					// .catch(error => console.log(error || 'No QR code found.'))
					
					
					return () =>
					{
						scanner.stop();
					}
				}
				catch (err)
				{
					console.error(err)
				}
			}
			
			
			// Return the cleanup function
			return init();
		}
		,
		[ navigate, qrCodeDetected, setQrCodeDetected, setQrCodeValue ]
	);
	
	
	
	return (
		<div className='videoArea'>
			<div className='scanArea' ref={scanAreaRef}></div>
			<Webcam
				className='video'
				audio={false}
				ref={webcamRef}
				width={640}
				height={300}
				videoConstraints={videoConstraints}
			/>
		</div>
	)
};



export default Scanner;
import { DateTime, groupByProp, Interval, useState } from 'stuff';
import { CalendarProps } from '../interface/CalendarProps';

import CalendarSpotlight from './CalendarSpotlight';

// import CalendarMonth from './CalendarMonth';
// import { Button } from '@mui/material';
// import { ButtonGroup } from '@mui/material';

import StatickCalendar from './StatickCalendar';


const Calendar: React.FC< CalendarProps > = ({ asset_histories }) => 
{
	//const { state } = useFuego('events');
	
	let events = []; // state.readAll('events').map(x => x.toThingo(state));
	
	let events_by_date = groupByProp(events, 'date');
	
	let today = DateTime.local();
	
	// TODO: Improve
	
	let month_start = today.startOf('month');
	let month_start_plus1 = month_start.plus({month: 1});
	// let month_start_plus2 = month_start.plus({month: 2});
	// let month_start_plus3 = month_start.plus({month: -1});
	
	// let month1 = Interval.fromDateTimes(month_start, month_start_plus1);
	// let month2 = Interval.fromDateTimes(month_start_plus1, month_start_plus2);
	// let month3 = Interval.fromDateTimes(month_start_plus3, month_start);

	const [ selected_day, setSelectedDay ] = useState(today.startOf('day'));
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [ selected_month, setSelectedMonth ] = useState< Interval >(
		Interval.fromDateTimes(month_start, month_start_plus1)
	);
	const [ month, setMonth ] = useState< number >( 1 );
	
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const switchMonths = ( back_or_forth: string ) =>
	{
		if ( back_or_forth === 'back' )
		{
			if ( month === 1 ){
				setMonth( -1 );
				setSelectedMonth( Interval.fromDateTimes(month_start.plus({month: -1}), month_start) )
			}
			else {
				setMonth( month - 1 );
				setSelectedMonth( Interval.fromDateTimes(month_start.plus({month: month - 1}), month_start.plus({month: month})) );
			};
		}	
		else if ( back_or_forth === 'forth' )
		{
			if ( month === -1 ){
				setMonth( 1 );
				setSelectedMonth( Interval.fromDateTimes( month_start, month_start.plus({month: +1}) ) );
			}
			else
			{
				setMonth( month + 1 );
				setSelectedMonth( Interval.fromDateTimes( month_start.plus({month: month}), month_start.plus({month: month + 1})) );
			}
		};
	};
	
	
	return <div className='schedule_filter'>
		{/* <div className='calendar2_months'>
			<CalendarMonth
				month={selected_month}
				selected_day={selected_day}
				setSelectedDay={setSelectedDay}
				events_by_date={events_by_date}
			/>
		</div>
		<div
			className = 'schedule_filter_month_btns'
		>
			<ButtonGroup
				variant = 'outlined' 
			>
				<Button
					onClick = { () => switchMonths( 'back' ) }
				>
					previous month
				</Button>
				
				<Button
					onClick = { () => switchMonths( 'forth' ) }
				>
					next month
				</Button>
			</ButtonGroup>
		</div> */}
		<div  className = 'schedule_filter_statick_calendar' >
			<StatickCalendar
				selected_Date = { setSelectedDay }
				asset_histories = { asset_histories }
			/>
		</div>
		<div className = 'schedule_filter_spotlight' >
			<CalendarSpotlight
				selected_day={selected_day}
				today={today}
				events_by_date={events_by_date}
				asset_histories = { asset_histories }
			/>	
		</div>
	</div>
}


export default Calendar;
import { ComponentRecord } from 'models';
import { createContext, useContext } from 'react';


const ComponentRecordContext = createContext<Partial<ComponentRecord>>({});


export const useComponentRecord = () =>
{
	return useContext(ComponentRecordContext);
};


// Makes the provided component record available to descendants via hook
export const ComponentRecordProvider = ({ component_record, children }) =>
{
	return (
		<ComponentRecordContext.Provider value={component_record}>
			{children}
		</ComponentRecordContext.Provider>
	);
}


export default ComponentRecordProvider;
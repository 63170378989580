import { Input, Popover, Box } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { useState } from 'react';
import { useStyles } from './theme';


type SearchBarProps =
{
	items: any[],
	handleItems: (newItems: any[]) => void,
	fields: string[],
	popoverContent?: JSX.Element
}


const SearchBarOld = ({ items, handleItems, fields, popoverContent }: SearchBarProps) =>
{
	const [ value, setValue ] = useState<string>('');
	const [ anchorEl, setAnchorEl ] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null);
	
	const classes = useStyles();
	
	
	const handleInput = (event) =>
	{
		setValue(event.target.value);
		
		let newItems = items.filter((item) =>
		{
			let filterItem;
			let fieldBoolean = false;
			
			fields.forEach((field) =>
			{
				if( item.hasOwnProperty(field) )
				{
					filterItem = item[field].toLowerCase();
					fieldBoolean = fieldBoolean || filterItem.includes(event.target.value.toLowerCase());
				}
			})
			
			return fieldBoolean ;
		});
		
		handleItems(newItems)
	}
	
	
	const handleClick =
	(
		event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
	) =>
	{
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () =>
	{
		setAnchorEl(null);
	};
	
	const open = Boolean(anchorEl);
	
	
	return (
		<Box
			sx={{ width: '100%' }}
		>
			<Input 
				fullWidth
				endAdornment={<CorIcon of={'search'} size='18px' />}
				value={value}
				onChange={handleInput}
				className={classes.SearchBar}
				placeholder='Type to search'
				disableUnderline
				onFocus={handleClick}
			/>
			
			{
				popoverContent
				&&
					<Popover
						open={open}
						onClose={handleClose}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
						disableAutoFocus
						disableEnforceFocus
						disableRestoreFocus
						sx={{
							width: '100%',
							height: '400px',
							scroll: 'auto'
						}}
					>
						{popoverContent}
					</Popover>
			}
			
		</Box>
	)
}


export default SearchBarOld;
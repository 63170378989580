import { Button, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { arrayOf, getNumberSuffix } from 'utils';

import HorizontalCounter from 'app/gizmos/fields/counters/HorizontalCounter';
import Formlet from 'app/gizmos/fields/Formlet';


export const MonthlyScheduleWeek =
({
	week_index,
	selected_day_nums,
} : {
	week_index: number,
	selected_day_nums: number[],
}) =>
{
	return (
		
		<Stack
			direction='row'
			// alignSelf='flex-start'
			justifyContent='center'
		>
			{
				arrayOf(7, (i: number) =>
				{
					let day_num = 7 * week_index + i + 1;
					
					if(day_num > 31)
					{
						return null;
					}
					
					return (
						<Controller
							key={i}
							name='recurrence.monthly.day_numbers'
							render={({
								field: { onChange, onBlur, value, name, ref },
							}) =>
								<Button
									variant='text'
									sx={{
										alignSelf: 'flex-end',
										textTransform: 'none',
										padding: '2px',
										minWidth: '2.5rem',
										borderRadius: '1px',
									}}
									onClick={(e) =>
									{
										if(selected_day_nums.includes(day_num))
										{
											onChange(selected_day_nums.filter(x  => x !== day_num))
										}
										else
										{
											onChange(
												selected_day_nums
													.concat(day_num)
													.sort(function(a, b) {
														return a - b;
													})
											);
										}
									}}
								>
									<Typography
										sx={{
											color: (week_index > 3)
												? 'secondary.main'
												: (selected_day_nums.includes(day_num)
													? 'primary'
													: 'primary.lightish'
											),
											bgcolor: (selected_day_nums.includes(day_num)) ? 'field.focus' : '',
											minWidth: '2.5rem',
											fontSize: '20px',
											fontWeight: 600,
											border: (selected_day_nums.includes(day_num)) ? '1px solid blue' : '1px solid #0000',
											borderRadius: '3px',
										}}
									>
										{day_num}
									</Typography>
								</Button>
							}
						/>
					)
				})
			}
		</Stack>
	)
}



// No longer used
export const MonthlyScheduleDaySelector = () =>
{
	return (
		<Stack>
			<HorizontalCounter
				name='recurrence.monthly.day_number'
				displayValue={(value) => value + getNumberSuffix(value)}
				is_required
				min={1}
				max={31}
			/>
			<Typography
				sx={{
					alignSelf: 'center',
					userSelect: 'none',
					padding: '0 56px',
				}}
			>
				of each month
			</Typography>
		</Stack>
	)
}



export const MonthlyScheduleFormlet = () =>
{
	const form_context = useFormContext();
	
	let selected_day_nums = form_context
		.watch('recurrence.monthly.day_numbers')
		.sort(function(a, b) {
			return a - b;
		}) as number[];
	
	
	return (
		<Formlet
			label='Repeats Monthly'
			help={
				`A work order will be created on these dates each month.
				For 29-31, if the month doesn't have that many days, it will trigger on the last day of the month instead (if that day is already selected, it will only trigger once).`
			}
		>
			
			<Stack
				alignItems='center'
			>
				<Stack
					alignItems='flex-start'
				>
					{
						arrayOf(5, (i: number) =>
							<MonthlyScheduleWeek
								week_index={i}
								selected_day_nums={selected_day_nums}
								key={i}
							/>
						)
					}
				</Stack>
			</Stack>
			
		</Formlet>
	)
}


export default MonthlyScheduleFormlet;
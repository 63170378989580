import { ReactNode } from 'react';

import { Box } from '@mui/material';

import { FieldDescription } from '../FieldDescription/FieldDescription';
import { SectionDivider } from '../SectionDivider/SectionDivider';


export const ActionTypeParent =
({
	description,
	children,
}: {
	description: string;
	children: ReactNode;
}) => {
	return (
		<>
			<Box
				sx={{
					marginInline: '1rem',
					marginBlock: '1rem',
					paddingBottom: '3rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				<FieldDescription description={description} />
				<Box sx={{ marginInline: '1rem' }}>
					{children}
				</Box>
			</Box>
			<SectionDivider />
		</>
	);
};


export default ActionTypeParent;
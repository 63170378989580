import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { User } from 'types';
import { Issue } from 'models';

import UserAvatar from 'components/avatars/UserAvatar';
import CollaboratorsAccordion from 'app/dashboard/pages/issueCollaboration/issueDetails/collaborators/CollaboratorsAccordion';

import { useWOTCollabData } from '../../WOTCollabProvider'

import { useSession } from 'app/providers/SessionProvider';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import SearchableCollaboratorsList from 'components/selectCollaborators/desktop/SearchableCollaboratorsList';
import { useEffect, useState } from 'react';
import { useUpdateDocProperty } from 'ember';
import { useAuthData } from 'stuff';
import { useFormContext } from 'react-hook-form';
import { Chip, Grid } from '@mui/material';
import AssignRoles from 'pages/CreateWorkOrder/GeneralDescription/Roles/AssignRoles';

// import Avatar from '@mui/material/Avatar';
// import AvatarOrError from 'components/images/AvatarOrError';
// import { text2hex } from 'utils/color_utils';


const WOTCollaboratorsList =
({
	main_collaborator_title,
} : {
	main_collaborator_title: string,
}) =>
{
	const form_context = useFormContext();
	const control = form_context.control;
	
	
	const { wo_template: work_order_template, assigned_to_roles, updateAssignedToRoles } =  useWOTCollabData();
	const { users } = useSession();
	const { claims } = useAuthData();
	
	
	const updateDocProperty = useUpdateDocProperty();
	
	const creator: User = users.filter((user: User) => user._id === work_order_template.creator._id)[0];
	const other_collaborators: User[] = users.filter((user: User) => work_order_template.collaborator_ids?.includes(user._id));
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	const [ work_order_template_collaborators, setWorkOrderTemplateCollaborators ] = useState<User[]>();
	
	
	useEffect(() =>
	{
		const all_work_order_template_collaborators = other_collaborators.concat(creator);
		
		setWorkOrderTemplateCollaborators(all_work_order_template_collaborators);
	}, []);
	
	
	
	
	const updateCollaborators = (user: User) =>
	{
		const collaborator_ids = work_order_template_collaborators.map(x => x._id);
		
		if(collaborator_ids?.includes(user._id))
		{
			const filtered_work_order_template_collaborators = work_order_template_collaborators.filter(x => x._id !== user._id);
			
			setWorkOrderTemplateCollaborators(filtered_work_order_template_collaborators);
		}
		else
		{
			setWorkOrderTemplateCollaborators([
				user,
				...work_order_template_collaborators
			])
		}
	};
	
	
	
	const confirmCollaboratorsUpdate = () =>
	{
		const collaborators_excluded_creator = work_order_template_collaborators.filter(x => x._id !== creator._id);
		const new_collaborator_ids = collaborators_excluded_creator.map(y => y._id);
		
		updateDocProperty(
			`organizations/${claims?.organization_id}/work_order_templates/${work_order_template._id}`,
			'collaborator_ids',
			new_collaborator_ids
		);
		
		setCloseDialog(!closeDialog);
	}
	
	
	
	return (
		<Stack
			direction='column'
			justifyContent='center'
			alignItems='flex-start'
			width='100%'
			className='collaborators_list_container'
			spacing={2}
		>
			<Stack
				width='100%'
			>
				<Typography
					variant='subtitle1'
				>
					Assigned to roles
				</Typography>
				
				<Grid container spacing={1}>
					{
						assigned_to_roles.map(role => {
							return(
								<Grid
									key={role._id}
									item
								>
									<Chip label={role.name}/>
								</Grid>
							)
						})
					}
				</Grid>
				
				<Stack
					width='100%'
					direction='row'
					justifyContent='flex-end'
				>
					<ButtonToOpenDialog
						button_variant='text'
						dialog_title='Add or remove roles'
						button_content='Edit Roles'
						dialog_content={
							<AssignRoles control={control} />
						}
						sx={{
							textTransform:'none',
							textDecoration: 'underline',
							width: 'fit-content'
						}}
						closeDialog={closeDialog}
						dialog_submit_text='Confirm'
						handleSubmit={updateAssignedToRoles}
						dialogClassName='add_tags_dialog'
					/>
				</Stack>
			</Stack>
			
			
			<Typography
				variant='subtitle1'
			>
				Collaborators
			</Typography>
			
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				width='100%'
			>
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{
						margin:'10px',
					}}
				>
					<UserAvatar user={creator} />
					
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						sx={{
							marginLeft: '5px',
						}}
					>
						<Typography
							variant='body1'
						>
							{ main_collaborator_title }
						</Typography>
						<Typography
							variant='caption'
						>
							{ creator?.display_name }
						</Typography>
					</Stack>
				</Stack>
				
				<ButtonToOpenDialog
					button_variant='text'
					dialog_title='Add or remove collaborators'
					button_content='Edit Collaborators'
					dialog_content={
						<SearchableCollaboratorsList
							selectedCollaborators={work_order_template_collaborators}
							handleSelectedCollaborators={updateCollaborators}
						/>
					}
					sx={{
						textTransform:'none',
						textDecoration: 'underline',
					}}
					closeDialog={closeDialog}
					dialog_submit_text='Confirm'
					handleSubmit={confirmCollaboratorsUpdate}
				/>
			</Stack>
			
			<CollaboratorsAccordion
				users={other_collaborators}
			/>
		</Stack>
	)
};


export default WOTCollaboratorsList;
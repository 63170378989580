import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';

import { HOME } from './data/locations';

// Leaflet CSS wasn't included properly - possibly due to Webpack issues - here's a hack
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import LocationMarker from './markers/LocationMarker';
import Minimap from './minimap/Minimap';
import MapDataProvider from './MapDataProvider';

import Routes from './routes/Routes';
import MapControlPanel from './MapControlPanel';

// import DraggableMarker from './markers/DraggableMarker';
// import Ports from './Ports';
// import Paths from './Paths';
// import { coloredMarkerIcon, skater } from './markers/markers';
// import WebSocketDemo from './WebSocketDemo';




let DefaultIcon = L.icon({
	iconUrl: icon,
	shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;




let INITIAL_ZOOM = 4;



// TODO: Review LeafletEventHandlerFnMap

const MapPage = () =>
{
	return (
		<MapDataProvider>
			<div className='map_page'>
				
				<div className='map_control_panel_section'>
					<MapControlPanel />
				</div>
				
				<MapContainer
					center={HOME}
					zoom={INITIAL_ZOOM}
				>
					
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					/>
					
					
					{/* <Marker
						position={HOME}
						icon={coloredMarkerIcon('red')}
					>
						<Popup>
							Home
						</Popup>
					</Marker> */}
					
					
					{/* <DraggableMarker /> */}
					
					<LocationMarker />
					
					<LayersControl position='bottomright'>
						{/* <Ports /> */}
						<Routes />
					</LayersControl>
					
					
					<Minimap
						position='topright'
					/>
					
				</MapContainer>
				
			</div>
		</MapDataProvider>
	)
}


export default MapPage;
import { createContext, ReactNode, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { cuid, useAuthData } from 'stuff';

import { useCollection, useUploadFile, useWrite } from 'ember';
import { DateTime } from 'luxon';

import {
	ComponentRecord,
	convertFormDataToWorkOrderTemplate,
	CustomFormFieldType, OnDemandRecurrenceType, RecurrenceScheduleType, RecurrenceType, StorageFile,
	WorkOrderStatus,
	WorkOrderTemplateForm, WorkOrderTemplate, WOTFormData, RecurrenceEffectiveType, Recurrence
} from 'models';
import { useSearchParams } from 'react-router-dom';
import { useToast } from 'app/providers/ToastProvider';
import { projectedOccurrences } from 'models/recurrence/recurrence_helpers3';




export const DEFAULT_RECURRENCE: Recurrence =
{
	interval: {
		years:    1,
		months:   1,
		weeks:    1,
		days:     1,
		hours:    6,
		minutes: 60,
	},
	
	skip_first: false,
	
	lead_time: {
		years:   0,
		months:  1,
		weeks:   0,
		days:    0,
		hours:   0,
		minutes: 0,
	},
	
	effective_datetime: DateTime.local().startOf('day'),
	// TODO: Default?
	effective_until_datetime: DateTime.local().plus({ months: 1 }),
	
	time_of_day: {
		hour:   12, //DateTime.local().hour,
		minute:  0, //DateTime.local().minute,
	},
	
	recurrence_schedule: RecurrenceScheduleType.MONTHLY,
	recurrence_type: RecurrenceType.SCHEDULE,
	on_demand_type: OnDemandRecurrenceType.SCHEDULE,
	effective_type: RecurrenceEffectiveType.START_IMMEDIATELY,
	
	weekly: {
		weekday_numbers: [],
	},
	
	monthly: {
		day_numbers: [29], //[DateTime.local().day],
	},
	
	yearly: {
		yearly_dates: [
			{
				_id:   cuid(),
				month: DateTime.local().month,
				day:   DateTime.local().day,
			},
		],
	},
	
	specific_dates: [],
}



export const DEFAULT_WOT_FORM_DATA: WOTFormData =
{
	_id: cuid(),
	
	title: 'Electrical Inspection',
	description: '',
	
	status: WorkOrderStatus.ACTIVE,
	
	estimated_cost: {
		quantity: '',
		currency: 'CAD',
	},
	
	asset: null,
	equipment: [],
	files: [],
	issues: [],
	parts: [],
	tags: [],
	collaborators: [],
	roles: [],
	component_records: [],
	
	work_order_type: '',
	
	
	recurrence: DEFAULT_RECURRENCE,
	
	
	now: DateTime.local(),
	
	
	// TODO: Handle reordering sections and fields
	custom_form: {
		
		fields: {
			field1: {
				_id: 'field1',
				name: 'custom_form.fields.field1',
				type: CustomFormFieldType.TEXT,
				label: '1.1',
				help: 'Example short text field',
				value: 'Foo',
			},
			field2: {
				_id: 'field2',
				name: 'custom_form.fields.field2',
				type: CustomFormFieldType.LONG_TEXT,
				label: '1.2',
				help: 'Example long text field',
				value: 'Bar',
			},
			field3: {
				_id: 'field3',
				name: 'custom_form.fields.field3',
				type: CustomFormFieldType.COST,
				label: '1.3',
				help: 'Example cost field',
				value: {
					quantity: 12,
					currency: 'EUR',
				},
			},
			field4: {
				_id: 'field4',
				name: 'custom_form.fields.field4',
				type: CustomFormFieldType.TEXT,
				label: '2.1',
				help: 'Example short text field',
				value: 'More text',
			},
		},
		
		sections: [
			{
				_id: 'c1',
				name: 'Section 1',
				field_ids: [
					'field1',
					'field2',
					'field3',
				],
			},
			{
				_id: 'c2',
				name: 'Section 2',
				field_ids: [
					'field4',
				],
			},
		],
	},
	
	sections: [],
};



const WOTDataContext = createContext<WorkOrderTemplateForm>(null);



export const useWOTData = () =>
{
	return useContext(WOTDataContext);
}



// If editing, provide a work_order_template to fill the initial values of the form
export const WOTDataProvider =
({
	work_order_template,
	children,
} : {
	work_order_template?: WorkOrderTemplate,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	
	const uploadFile = useUploadFile();
	const write = useWrite();
	const navigate = useNavigate();
	const toast = useToast();
	
	const [ is_submitting, setIsSubmitting ] = useState(false);
	
	
	let [ search_params ] = useSearchParams();
	
	
	
	// This will be as enum keys, not enum values (Ex: 'DEGREES_CELSIUS' not '°C')
	let schedule_query   = search_params.get('schedule');
	let recurrence_query = search_params.get('recurrence');
	let on_demand_query  = search_params.get('on_demand');
	let effective_query  = search_params.get('effective');
	
	
	let form_values : WOTFormData = {
		...DEFAULT_WOT_FORM_DATA,
		_id: cuid(),
		...work_order_template,
	}
	
	// let yearly_date_id = cuid();
	
	// form_values.recurrence.yearly.yearly_dates.push(
	// 	{
	// 		_id: yearly_date_id,
	// 		month: 1,
	// 		day: 1,
	// 	}
	// )
	
	
	// Grab values from URL query params to override default initial values
	if(recurrence_query)
	{
		form_values.recurrence.recurrence_type = RecurrenceType[recurrence_query];
	}
	if(schedule_query)
	{
		form_values.recurrence.recurrence_schedule = RecurrenceScheduleType[schedule_query];
	}
	if(on_demand_query)
	{
		form_values.recurrence.on_demand_type = OnDemandRecurrenceType[on_demand_query];
	}
	if(effective_query)
	{
		form_values.recurrence.effective_type = RecurrenceEffectiveType[effective_query];
	}
	
	
	const form = useForm<WOTFormData>({
		defaultValues: form_values
	});
	
	
	
	// console.log({claims});
	
	
	
	const onSubmit = (data: any) =>
	{
		let doc_data = convertFormDataToWorkOrderTemplate(data, claims);
		
		
		console.log(
			'handleSubmitForm',
			data,
			doc_data
		);
		
		
		// write(
		// 	`organizations/${claims.organization_id}/work_order_templates`,
		// 	doc_data
		// );
	}
	
	
	const onSubmitError = (props: any) =>
	{
		console.log('Error submitting', props);
	}
	
	
	const handleSubmitForm = (e) =>
	{
		// console.log(
		// 	'handleSubmitForm',
		// 	data,
		// 	doc_data
		// );
		
		// write(
		// 	`organizations/${claims.organization_id}/work_order_templates`,
		// 	doc_data
		// );
		
		e.preventDefault()
		form.handleSubmit(onSubmit, onSubmitError)();
	}
	
	
	
	
	
	async function handleSubmit(e)
	{
		const { files, _id } = form.getValues();
		
		const org = `organizations/${claims?.organization_id}`
		
		
		e.preventDefault()
		
		
		if (is_submitting)
			return;
		else
			setIsSubmitting(true);
		
		
		let data = form.getValues();
		
		let datetime = DateTime.utc().toISO();
		
		
		let doc_data = convertFormDataToWorkOrderTemplate(data, claims);
		
		
		let occurrences = projectedOccurrences(
			doc_data.recurrence,
			1,
			DateTime.local()
		)
		
		if(occurrences.length > 0)
		{
			console.log({
				occurrences,
			})
			
			
			
			// TODO: Verify
			if(doc_data.recurrence.last_automation_datetime)
			{
				occurrences.find(dt =>
					dt.generation_datetime > doc_data.recurrence.last_automation_datetime
				)
			}
			else
			{
				doc_data.recurrence.next_automation_datetime = occurrences[0].generation_datetime;
			}
			
			
			// doc_data.recurrence.next_automation_datetime = occurrences[0].generation_datetime;
			
			// if(doc_data.recurrence.next_automation_datetime === doc_data.recurrence.last_automation_datetime)
			// {
			// 	doc_data.recurrence.next_automation_datetime = occurrences[1].generation_datetime;
			// }
		}
		
		
		console.log(
			'data',
			doc_data,
		);
		
		
		// return;
		
		
		try {
			
			let files_to_link = [];
			
			
			if(files.length)
			{
				files?.forEach(file_to_upload =>
				{
					let file_id = cuid();
					
					let file : StorageFile =
					{
						_id: file_id,
						name: file_to_upload.name,
						path: `organizations/${claims?.organization_id}/files/${file_id}`,
						size: file_to_upload.size,
						type: file_to_upload.type,
						upload_date: datetime,
						work_order_template_id: _id,
						last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
					}
					
					
					uploadFile(file, file_to_upload, `${org}/files`);
					
					files_to_link.push(file);
				})
			}
			
			
			doc_data.file_ids = files_to_link.map(x => x._id);
			
			
			write(
				`${org}/work_order_templates`,
				doc_data,
				(result) => {
					setIsSubmitting(false);
					navigate('/work-orders?tab=work_order_templates');
					toast.toastSuccess('Created work order template: ' + doc_data.title)
				}
			);
			
		}
		catch (error)
		{
			console.log('error', error);
		}
	}
	
	
	
	const component_records_collection = useCollection(`organizations/${claims?.organization_id}/component_records`);
	
	const component_records = component_records_collection?.data as ComponentRecord[] || [];
	
	
	
	return (
		<FormProvider {...form}>
			<WOTDataContext.Provider
				value={{
					form,
					handleSubmitForm,
					component_records
				}}
			>
				<form
					onSubmit={handleSubmit}
					style={{
						// height: '100%',
						// width: '100%',
					}}
				>
					{children}
				</form>
			</WOTDataContext.Provider>
		</FormProvider>
	);
};



export default WOTDataProvider;
import { Control, useFieldArray, useWatch } from 'react-hook-form';

import { Box, Chip, InputLabel, styled } from '@mui/material';

import CorIcon from 'components/icons/CorIcon';
import cuid from 'cuid';

import { WorkOrderDescriptionFormData, PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';

import { ButtonToOpenPartsDialog } from './PartsModal/ButtonToOpenPartsDialog';


export interface PartsProps
{
	control: Control<any, any>,
}


export const Parts = ({ control }: PartsProps) =>
{
	const parts = useWatch({
		control,
		name: `parts`,
	});
	
	const { replace, remove } = useFieldArray({
		control,
		name: 'parts',
	});
	
	
	const setParts = (parts: PartWithSelectedCount[]) =>
	{
		const parts_needed = parts.map(part => ({
			part_needed_id: cuid(),
			...part
		}));
		
		replace(parts_needed);
	};
	
	const onRemove = (index: number) => remove(index);
	
	
	return (
		<Box
			sx={{
				display: 'inline-flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				width: '100%',
			}}
		>
			<InputLabel
				required={true}
				sx={{
					fontSize: '16px',
					color: 'black',
				}}
			>
				Add Parts
			</InputLabel>
			
			{parts.length > 0 && (
				<Box
					sx={{
						border: `1px solid #d8dbdf`,
						borderRadius: '3px',
						padding: '0.5rem',
						display: 'flex',
						gap: '0.5rem',
						flexWrap: 'wrap',
						marginTop: '1rem',
					}}
				>
					{parts.map((part, index) => (
						<StyledChip
							label={`${
								part.selected_count > 1
									? `${part.selected_count}X`
									: ''
							} ${part.name}`}
							deleteIcon={
								<CorIcon of='close' size='15px' color='#fff' />
							}
							onDelete={() => onRemove(index)}
							key={part._id}
						/>
					))}
				</Box>
			)}
			
			<ButtonToOpenPartsDialog
				parts={parts}
				onSetParts={setParts}
			/>
		</Box>
	);
};


const StyledChip = styled(Chip)(() => ({
	backgroundColor: '#186ab4',
	color: 'white',
}));


export default Parts;
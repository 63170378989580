import { useAuthData } from 'stuff';
import { useCollection, useWrite } from 'ember';

import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

// import { TagPreviewProps } from '../../interfaces/settings_Interfaces';
// import { FakeTagData } from './components/FakeTagData';

import { useStyles } from '../../styles/ManageTagsTabTheme';

import TagsList from './components/TagsList';
import GenerateNewTag from './components/GenerateNewTag';

import { Tag } from 'types';
import { useSession } from 'app/providers/SessionProvider';


/*
	Renders GenerateNewTag and TagList component
	and update tags object array when user created new tag
*/
const ManageTagsTab = () =>
{
	const classes = useStyles();
	
	const { all_available_tags } = useSession();
	
	const { claims } = useAuthData();
	const write = useWrite();
	
	const org_collection_path = `organizations/${claims.organization_id}`;
	
	
	
	// Update the tags state to add new tag object
	// passed by GenerateNewTag
	const handleSubmit = ( newTag: Tag ) =>
	{
		console.log(newTag);
		write(`${org_collection_path}/tags`, newTag);
		
		write(`${org_collection_path}/tag_roles`, {_id: newTag._id});
	}
	
	
	// TODO: Why wrapped in fragment?
	return (
		<>
			<Box
				className={classes.ManageTagContainer}
			>
				<GenerateNewTag
					handleSubmit={handleSubmit}
					mobile={false}
				/>
				
				<Box className='container' sx={{ marginLeft: '20px' }}>
					<TagsList
						tags={all_available_tags}
					/>
				</Box>
			</Box>
		</>
	)
}


export default ManageTagsTab;
const cn: string = 'commentForm';


export interface InputFormButtonsProps
{
	fileChangeHandler: (event: FileList | null) => void;
	attached_files: File[];
	isResolved: boolean;
	setIsResolved: () => void;
};


const InputFormButtons =
({
	fileChangeHandler,
	attached_files,
	isResolved,
	setIsResolved,
}
: InputFormButtonsProps) =>
{
	return (
		<div>
			
			<label
				className = { cn + '__file--btn' }
			>
				<input
					onChange = { (e) => fileChangeHandler( e.currentTarget.files )}
					type = 'file'
					multiple
					hidden
				/>
				{
					(attached_files == null || attached_files.length === 0)
						? 'Choose file'
						: 'Change file'
				}
			</label>
		
			<span>
				{
					(attached_files == null)
						? 'No file choosen'
						: (attached_files.length === 1)
							? attached_files[0].name
							: (attached_files.length).toString() + ' files selected'
				}
			</span>
			
			<div
					className = { cn + '__btn-Container' }
				>
					<button
						className = {
							isResolved ? cn + '__resolved-btn--active'
							: cn + '__resolved-btn'
						}
						onClick = { setIsResolved }
						type = 'button'
					>
						Mark as Resolved
					</button>
					<button
						className = { cn + '__submit-btn' }
						type = 'submit'
					>
						Submit
					</button>
				</div>
		</div>
	)
}


export default InputFormButtons;
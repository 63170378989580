import { Breadcrumbs, useMediaQuery } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { cx, toTitleCase } from 'stuff';
import { useBreadcrumbData } from 'app/providers/BreadcrumbDataProvider';
import { useIsMobile } from 'utils/hooks/useIsMobile';


interface Breadcrumb
{
	text: string,
	path: string,
}


const HeaderBreadcrumbs = () =>
{
	const location = useLocation();
	const breadcrumbs_data = useBreadcrumbData();
	const is_mobile = useIsMobile();
	
	const extra_small_screen = useMediaQuery('(max-width:425px)');
	
	
	// Split on slashes and remove empty strings
	// Ex: 'home/path' => ['home', 'path']
	let parts = location.pathname.split('/').filter(Boolean);
	
	// Convert the parts into something presentable
	let display_parts = [];
	
	// Ex: ['home', 'path'] => returns ['home', 'home/path']
	//     display_parts = ['Home', 'Path']
	let part_paths = parts.reduce((paths, part, i) =>
	{
		paths.push(
			parts.slice(0, i).join('/') + '/' + part
		);
		
		// TODO: Should we title case the ID?
		display_parts.push(
			breadcrumbs_data.getDisplayText(part)
			|| toTitleCase(part.replaceAll('-', ' '))
		)
		
		return paths;
	}, [])
	
	
	// console.log({
	// 	parts,
	// 	breadcrumbs_data,
	// 	part_paths,
	// 	location,
	// });
	
	
	// The number of parts shown will vary by device/width
	let max_showable = (is_mobile) ? 3 : 5;
	let crumbs : Breadcrumb[] = [];
	let offset = Math.max(0, display_parts.length - max_showable);
	
	
	while(offset < display_parts.length)
	{
		crumbs.push({
			text: display_parts[offset],
			path: part_paths[offset],
		})
		
		offset += 1;
	}
	
	
	return (
		<Breadcrumbs
			className='header_breadcrumbs'
			aria-label='breadcrumb'
			separator={<NavigateNextIcon fontSize='small' />}
			maxItems={(is_mobile) ? 3 : 4}
			itemsAfterCollapse={(is_mobile) ? 2 : 3}
			sx={{
				justifyContent: 'center',
				transition: '.2s',
				maxHeight: '100%',
				overflow: 'hidden',
				
				
			}}
		>
			
			<NavLink
				color='inherit'
				to='/home'
				key='home'
			>
				<HomeIcon
					// fontSize='24px'
					className='icon'
					sx={{
						fontSize: '24px',
						margin: 0,
					}}
				/>
			</NavLink>
			
			{
				!(extra_small_screen) && crumbs.map((crumb, i) =>
					<NavLink
						color='inherit'
						to={crumb.path}
						className={cx({is_last: i === (crumbs.length - 1)})}
						key={i}
					>
						{crumb.text}
					</NavLink>
				)
			}
		</Breadcrumbs>
	)
}


export default HeaderBreadcrumbs;
import { useAuthData} from 'stuff';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import MobileHeader from '../../../settings_mobile/MobileHeader';
import { useStyles } from '../../../../styles/ManageProfileTabTheme';


const EditUsername = () =>
{
	const { claims } = useAuthData();
	
	const classes = useStyles();
	
	
	return (
		<>
			
			<MobileHeader
				title='General Information'
				variantType='h3'
			/>
			
			<Box
				className={classes.UserProfileItemContainer}
			>
				<Typography
					variant='h5'
				>
					Username
				</Typography>
				
				<Paper
					className={classes.UserProfileItemField}
					variant='outlined'
				>
					<InputBase
						sx={{ ml: 1, flex: 1 }}
						defaultValue={claims?.name}
						inputProps={{ 'aria-label': 'Type new username' }}
					/>
				</Paper>
				
				<Button
					className={classes.UserProfileItemBtn}
					variant='contained'
				>
					Update
				</Button>
			</Box>
			
		</>
	)
}


export default EditUsername;
import { Button, Chip, CircularProgress, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { useCollection, useDelete, useWrite } from 'ember';
import { useState } from 'react';
import { useAuthData } from 'stuff';
import cuid from 'cuid';
import { Position } from 'models/users/user_interfaces';

const ManagePositions = () =>
{
	const { claims } = useAuthData();
	const write = useWrite();
	const deleteDoc = useDelete();
	
	const [ position_name, setPositionName ] = useState<string>('');
	
	const positions_collection_path = `organizations/${claims?.organization_id}/positions`;
	const positions_collection = useCollection(positions_collection_path);
	
	
	const addRoleToDb = () =>
	{
		console.log({position_name});
		
		const position_data: Position = {
			_id: cuid(),
			name: position_name,
		}
		
		write(
			positions_collection_path,
			position_data,
			() => setPositionName('')
		);
	};
	
	
	const deletePositionFromDb = (position: Position) =>
	{
		console.log({position});
		
		deleteDoc(positions_collection_path, position);
	}
	
	
	
	
	if(positions_collection?.is_loading) return <CircularProgress />
	
	const postions = positions_collection?.data as Position[];
	
	
	
	return (
		<Stack
			direction='column'
			alignItems='flex-start'
			justifyContent='flex-start'
			spacing={2}
			width='100%'
			className='container'
		>
			
			<Typography
				variant='h6'
			>
				Add a new Position
			</Typography>
				
			
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={2}
				width='100%'
			>
					<TextField
						label='Position Name'
						placeholder='e.g. CEO'
						value={position_name}
						onChange={e => setPositionName(e.target.value)}
						sx={{
							width: '50%'
						}}
					/>
					
					<Button
						variant='contained'
						onClick={addRoleToDb}
					>
						Add Position
					</Button>
			</Stack>
			
			<Stack>
				<Typography
					variant='h6'
				>
					Available Positions
				</Typography>
				
				<Divider sx={{ width: '100%' }} />
				
				<Grid
					container
					sx={{ maxWidth: '900px', padding: '10px' }}
					gap={2}
				>
					{
						postions && postions.map(position =>
								<Grid
									key={position._id}
									item
								>
									<Chip
										label={position.name}
										onDelete={() => deletePositionFromDb(position)}
									/>
								</Grid>
							)
					}
				</Grid>
			</Stack>
			
		</Stack>
	)
};


export default ManagePositions;



// Ex: cklmrhsgg00133b632chj0ise => -1624845568
export function hashCode(str)
{
	var hash = 0;
	
	for (let i = 0; i < str.length; i++)
	{
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	
	return hash;
}



// Ex: cklmrhsgg00133b632chj0ise => #26d300
export function text2hex(text)
{
	let hash = hashCode(text);
	
	var c = (hash & 0x00ffffff)
		.toString(16)
		.toLowerCase();
	
	return '#' + '00000'.substring(0, 6 - c.length) + c;
}



// TODO: Find hue based on rgb value

// Converts a number (0-255) into a hex string (00-ff)
export const component2hex = (c) =>
{
	let int_c = Math.round(c);
	
	let hex = int_c.toString(16);
	
	return hex.length === 1 ? '0' + hex : hex;
}



// Accepts 3 arguments or a single array
export const rgb2hex = (r_or_rgb, g, b) =>
{
	//console.log(r_or_rgb, g, b)
	
	if(Array.isArray(r_or_rgb))
	{
		b = r_or_rgb[2];
		g = r_or_rgb[1];
		r_or_rgb = r_or_rgb[0];
	}
	
	let hex = '#'
		+ component2hex(r_or_rgb)
		+ component2hex(g)
		+ component2hex(b);
		
	//console.log(hex);
	
	return hex;
}


// export const hex2rgb = (hex) =>
// {
// 	// Hex -> RGB
// 	let hex_string = hex.substring(1);

// 	hex_string = parseInt(hex_string, 16);

// 	let r = (hex_string >> 16) & 255;
// 	let g = (hex_string >> 8) & 255;
// 	let b = hex_string & 255;

// 	return [r, g, b];
// }



// hue in range [0, 360]
// saturation and value are in range [0,1]
// return [r,g,b] each in range [0,255]
// See: https://en.wikipedia.org/wiki/HSL_and_HSV#From_HSV
export function hsv2rgb(hue, saturation, value)
{
	let chroma = value * saturation;
	let hue1 = hue / 60;
	let x = chroma * (1 - Math.abs((hue1 % 2) - 1));
	let r1, g1, b1;

	if (hue1 >= 0 && hue1 <= 1)
	{
		([r1, g1, b1] = [chroma, x, 0]);
	}
	else if (hue1 >= 1 && hue1 <= 2)
	{
		([r1, g1, b1] = [x, chroma, 0]);
	}
	else if (hue1 >= 2 && hue1 <= 3)
	{
		([r1, g1, b1] = [0, chroma, x]);
	}
	else if (hue1 >= 3 && hue1 <= 4)
	{
		([r1, g1, b1] = [0, x, chroma]);
	}
	else if (hue1 >= 4 && hue1 <= 5)
	{
		([r1, g1, b1] = [x, 0, chroma]);
	}
	else if (hue1 >= 5 && hue1 <= 6)
	{
		([r1, g1, b1] = [chroma, 0, x]);
	}

	let m = value - chroma;
	let [r, g, b] = [r1 + m, g1 + m, b1 + m];

	// Change r,g,b values from [0,1] to [0,255]
	return [255 * r, 255 * g, 255 * b];
}


// Ex: (359, .9, 1) => [] base 255
export const hsv2rgbString = (h, s, v) =>
{
	let rgb, i, data = [];
	if (s === 0)
	{
		rgb = [v,v,v];
	} 
	else 
	{
		h = h / 60;
		i = Math.floor(h);
		data = [v*(1-s), v*(1-s*(h-i)), v*(1-s*(1-(h-i)))];
		switch(i) 
		{
			case 0:
				rgb = [v, data[2], data[0]];
			break;
			case 1:
				rgb = [data[1], v, data[0]];
			break;
			case 2:
				rgb = [data[0], v, data[2]];
			break;
			case 3:
				rgb = [data[0], data[1], v];
			break;
			case 4:
				rgb = [data[2], data[0], v];
			break;
			default:
				rgb = [v, data[0], data[1]];
			break;
		}
	}
	return '#' + rgb.map(function(x){ 
	  return ("0" + Math.round(x*255).toString(16)).slice(-2);
	}).join('');
}


export const hex2rgba = (hex) =>
{
	var c;
	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
		c = hex.substring(1).split('');
		if(c.length === 3){
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x'+c.join('');
		return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
	}
	throw new Error('Bad Hex');
}

export function rgb2hsv( rgb )
{
	
	let r = rgb[0];
	let g = rgb[1];
	let b = rgb[2];
	
	
	let h;
	let s;
	let v;
	
	
	
	var maxColor = Math.max(r, g, b);
	var minColor = Math.min(r, g, b);
	var delta = maxColor - minColor;
	
	// Calculate hue
	// To simplify the formula, we use 0-6 range.
	if(delta === 0) {
		h = 0;
	}
	else if(r === maxColor) {
		h = (6 + (g - b) / delta) % 6;
	}
	else if(g === maxColor) {
		h = 2 + (b - r) / delta;
	}
	else if(b === maxColor) {
		h = 4 + (r - g) / delta;
	}
	else {
		h = 0;
	}
	// Then adjust the range to be 0-1
	h = h/6;
	
	// Calculate saturation
	if(maxColor !== 0) {
		s = delta / maxColor;
	}
	else {
		s = 0;
	}
	
	// Calculate value
	v = maxColor / 255;
	
	return [ h*360 ,  s,  v ];
};
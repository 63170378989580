import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useUploadFile, useWrite } from 'ember';
import { useAuthData } from 'stuff';

import cuid from 'cuid';
import { DateTime } from 'luxon';

import { FormInputType, TemplateField, WorkOrderTemplateOld } from 'types/forms';
import { ScheduleFormData } from './scheduling_types';
import { ActionType, WorkOrderField, WorkOrderDescriptionFormData, FormSection, StepperForm } from './work_order_types';
import { StorageFile, WorkOrderStatus } from 'models';

import { useStepper } from './Stepper/useSteppers';

// import { PartsNeeded } from 'types';



export type Step = 1 | 2 | 3;
export type Display = 'sectionsPreview' | 'form';


export const actionTypeToInputType: Record<ActionType, FormInputType> =
{
	checkbox: FormInputType['Checkbox'],
	date: FormInputType['Calendar'],
	multiple_choice: FormInputType['Radio'],
	pass_fail: FormInputType['FailPass'],
	reading: FormInputType['Reading'],
	replace: FormInputType['Replace'],
	text: FormInputType['Instruction'],
	yes_no: FormInputType['NoYesNa'],
	instruction: FormInputType['Instruction']
};


const work_order_description_form_default_values: WorkOrderDescriptionFormData =
{
	_id: cuid(),
	description: '',
	title: '',
	asset: null,
	equipment: [],
	files: [],
	issues: [],
	parts: [],
	tags: [],
	collaborators: [],
	work_order_type: '',
};


const stepFromDefaultValues: StepperForm =
{
	sections: []
};



export const useCreateWorkOrderTemplate = () =>
{
	const uploadFile = useUploadFile();
	
	// const [ active_step, setActiveStep ] = useState<Step>(1);
	
	const schedule_form = useForm<ScheduleFormData>();
	
	const step_form = useForm<StepperForm>({
		defaultValues: stepFromDefaultValues
	});
	
	const work_order_description_form = useForm<WorkOrderDescriptionFormData>({
		defaultValues: work_order_description_form_default_values,
	});
	
	const { handleBack: stepperHandleBack } = useStepper(handleSubmit);
	
	const [ display, setDisplay ] = useState<Display>('form');
	const showSectionsPreview = () => setDisplay('sectionsPreview');
	const hideSectionsPreview = () => setDisplay('form');
	
	const [ collaborators_modal, setCollaboratorsModal ] = useState(false);
	const showCollaboratorsModal = () => setCollaboratorsModal(true);
	const hideCollaboratorsModal = () => setCollaboratorsModal(false);
	
	const [ is_submitting, setIsSubmitting ] = useState(false);
	
	
	const { user, claims } = useAuthData();
	
	const write = useWrite();
	
	const navigate = useNavigate();
	
	
	
	
	
	
	// const sections = useWatch({
	// 	control: step_form.control,
	// 	name: 'sections',
	// });
	
	
	let datetime = DateTime.local().toISO();
	
	
	// console.log({ sections });
	
	
	async function handleSubmit()
	{
		const { files } = work_order_description_form.getValues();
		
		const org = `organizations/${claims?.organization_id}`
		
		
		if (is_submitting)
			return;
		
		
		setIsSubmitting(true);
		
		
		console.log('data', convertFormDataToWorkOrderTemplate());
		
		
		try {
			write(`${org}/work_order_templates`, convertFormDataToWorkOrderTemplate(), (data) => {
				setIsSubmitting(false);
				navigate('/work-orders?tab=work_order_templates');
			});
			
			
			if(files.length)
			{
				files?.forEach(file_to_upload =>
				{
					let _id = cuid();
					
					let file : StorageFile =
					{
						_id: _id,
						name: file_to_upload.name,
						path: `organizations/${claims?.organization_id}/files/${_id}`,
						size: file_to_upload.size,
						type: file_to_upload.type,
						upload_date: datetime,
						work_order_template_id: _id,
						last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
					}
					
					
					uploadFile(file, file_to_upload, `${org}/files`);
				})
			}
			
		}
		catch (error)
		{
			console.log('error', error);
		}
	}
	
	

	
	// TODO: Review
	function convertFormDataToWorkOrderTemplate():
		Omit<WorkOrderTemplateOld, 'form_sections'>
		& Omit<{
			form_sections: Array<Omit<WorkOrderTemplateOld['form_sections'][number], '_id' | 'fields'> & {
				fields: Array<Omit<WorkOrderTemplateOld['form_sections'][number]['fields'][number], '_id'>>;
			}>;
		}, '_id'>
	{
		const {
			_id, description, issues,
			title, collaborators, parts,
			// work_order_type,
			tags
		} = work_order_description_form.getValues();
		
		
		return {
			_id: _id,
			name: title,
			description: description,
			status: WorkOrderStatus.OPEN,
			
			
			priority: null,
			
			// TODO: ???
			// work_order_type: work_order_type,
			
			asset_id: work_order_description_form.getValues()?.equipment.map(eq => eq.asset_id)[0],
			asset_name: work_order_description_form.getValues()?.equipment.map(eq => eq.asset_name)[0],
			
			system_id: work_order_description_form.getValues()?.equipment.map(eq => eq.system_id)[0],
			system_name: work_order_description_form.getValues()?.equipment.map(eq => eq.system_name)[0],
			
			
			
			linked_issue_ids: issues.map(issue => issue._id),
			parts_needed: parts.map(part => ({
				_id: part._id,
				name: part.name,
				quantity: part.selected_count,
			})),
			component_ids: work_order_description_form.getValues().equipment.map(eq => eq._id),
			// division_id: work_order_description_form.getValues()?.equipment?.map(eq => eq?.division_id || eq?.maintenance_function_id)[0] || '',
			
			collaborator_ids: collaborators.map(collab => collab._id),
			tag_ids: tags.map(tag => tag._id),
			
			frequency: schedule_form.getValues(),
			form_sections: step_form.getValues().sections.map(convertSectionToWorkOrderTemplateSection),
			
			created_at: datetime,
			updated_at: '',
			work_order_creator: {
				_id: user?.uid,
				name: user?.displayName,
				email: user?.email
			}
		};
	}
	
	
	
	function convertSectionToWorkOrderTemplateSection(section: FormSection): WorkOrderTemplateOld['form_sections'][number]
	{
		return {
			label: section.name,
			description: section.description,
			fields: section.fields.map(convertFieldToTemplateField)
		};
	}
	
	
	
	function convertFieldToTemplateField(field: WorkOrderField): TemplateField
	{
		const defaultObj: TemplateField =
		{
			is_required: false,
			label: '',
			type: FormInputType.Description,
		};
		
		
		switch (field.action_type)
		{
			case 'checkbox':
				return !field.checkbox ? defaultObj : {
					is_required: true,
					type: FormInputType.Checkbox,
					label: field.checkbox.description,
					value: {
						options: field.checkbox.options.map(option => ({
							label: option.label,
						}))
					}
				};
			
			case 'date':
				return !field.date ? defaultObj : {
					is_required: false,
					label: field.date.description,
					type: FormInputType.Calendar,
				};
			
			case 'pass_fail':
				return !field.pass_fail ? defaultObj : {
					is_required: false,
					type: FormInputType.FailPass,
					label: field.pass_fail.description,
					value: [{
						label: 'Pass',
						value: 'pass',
					}, {
						label: 'Fail',
						value: 'fail'
					}]
				};
			
			case 'reading':
				return !field.reading ? defaultObj : {
					is_required: false,
					label: field.reading.description,
					type: FormInputType.Reading,
					value: {
						equipment: field.reading.equipment.map(eq => ({ _id: eq._id, label: eq.name })),
						component_record: {
							_id: field.reading.component_record._id,
							title: field.reading.component_record.title,
						},
						cumulative: (field.reading.readingType === 'cumulative') ? true : false,
						readingType: field.reading.readingType
					}
				};
			
			case 'replace':
				return !field.replace ? defaultObj : {
					is_required: false,
					label: field.replace.description,
					type: FormInputType.Replace,
					value: {
						equipment: field.replace.equipment.map(eq => ({ _id: eq._id, label: eq.name }))
					}
				};
			
			case 'text':
				return !field.text ? defaultObj : {
					is_required: false,
					label: field.text.description,
					type: FormInputType.Instruction
				};
			
			case 'yes_no':
				return !field.yes_no ? defaultObj : {
					is_required: false,
					label: field.yes_no.description,
					type: FormInputType.NoYesNa,
					value: [{
						label: 'No',
						value: 'no'
					}, {
						label: 'Yes',
						value: 'yes'
					}, {
						label: 'N/A',
						value: 'n/a'
					}]
				};
			
			case 'multiple_choice':
				return !field.multiple_choice ? defaultObj : {
					is_required: false,
					label: field.multiple_choice.description ?? '',
					type: FormInputType.Radio,
					value: field.multiple_choice.options.map(option => ({
						label: option.label,
					}))
				};
		}
	};
	
	
	
	const handleBack = () =>
	{
		if (collaborators_modal)
		{
			hideCollaboratorsModal();
			return;
		}
		if (display === 'form')
		{
			stepperHandleBack();
			return;
		}
		
		hideSectionsPreview();
	};
	
	
	
	return {
		// active_step,
		// setActiveStep,
		schedule_form,
		step_form,
		work_order_description_form,
		display,
		showSectionsPreview,
		hideSectionsPreview,
		collaborators_modal,
		showCollaboratorsModal,
		handleBack,
		handleSubmit,
		is_submitting,
	};
};



export default useCreateWorkOrderTemplate;
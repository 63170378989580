import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import CostCenterFormProvider, { useCreateCostCenter } from './CostCenterFormProvider';
import ShortTextFormlet from 'app/gizmos/fields/text/ShortTextFormlet';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import { useAuthData } from 'stuff';
import { useCollection } from 'ember';
import { Asset } from 'models';
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';
import EnumToggleFormlet from 'app/gizmos/fields/toggles/EnumToggleFormlet';
import { CostCenter, Periodicity } from 'models/costs';



const CostCenterForm = ({
	cost_center,
	closeDialog,
	setCloseDialog,
} : {
	cost_center?: CostCenter,
	closeDialog?: boolean,
	setCloseDialog?: any,
}) =>
{
	const { claims } = useAuthData();
	
	
	const org_path = `organizations/${claims.organization_id}`;
	const assets_collection = useCollection(`${org_path}/assets`);
	
	if(assets_collection?.is_loading) return <CircularProgress />
	
	
	const centers = assets_collection?.data as Asset[];
	
	return (
		<CostCenterFormProvider
			cost_center={cost_center}
			closeDialog={closeDialog}
			setCloseDialog={setCloseDialog}
		>
			<Stack
				direction='column'
				alignItems='center'
				justifyContent='center'
				spacing={2}
				width='100%'
			>
				
				<Typography
					variant='h6'
				>
					Please fill the fields.
				</Typography>
					
				
				<Stack
					direction='column'
					alignItems='flex-start'
					justifyContent='center'
					spacing={3}
				>
						<ShortTextFormlet
							name={'description'}
							label={'Description'}
							help={'e.g. Main Office Cost Center'}
						/>
						
						
						<SearchableSelectFormlet
							name={'centers'}
							label={'Select associated centers'}
							help={'e.g. Main Office'}
							options={centers}
							getOptionLabel={(option) => option?.name || ''}
							multiple={true}
						/>
						
						<CostFormlet
							name='allocated_budget'
							label='Allocate budget'
							help='10,000 CAD'
						/>
						
						<EnumToggleFormlet
							name='periodicity'
							label='Budget reset interval'
							help='10,000/year'
							enum_type={Periodicity}
						/>
						
						
						<Stack
							width='100%'
							justifyContent='center'
							alignItems='center'
						>
							<Button
								variant='contained'
								type='submit'
							>
								Create Cost Center
							</Button>
						</Stack>
				</Stack>
			</Stack>
			
		</CostCenterFormProvider>
	)
};


export default CostCenterForm;
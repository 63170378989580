
import { WorkOrderNew } from 'models';
import SearchableWorkOrdersCardList from 'pages/work_orders/work_orders/SearchableWorkOrdersCardList';


const ComponentRelatedWorkOrdersList =
({
	related_work_orders,
} : {
	related_work_orders: WorkOrderNew[],
}) =>
{
	
	return (
		<div>
			<SearchableWorkOrdersCardList work_orders={related_work_orders}/>
		</div>
	)
};


export default ComponentRelatedWorkOrdersList;
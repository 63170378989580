import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import ComponentDrawerActionsList from './ComponentDrawerActionsList';
import { CorComponent, ComponentRecord, Issue, WorkOrderNew } from 'models';
import { useAuthData } from 'stuff';
import { useCollectionConditionally } from 'ember';
import { where } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';


const ComponentDrawerDetails =
({
	component,
	related_issues,
	related_work_orders,
} : {
	component: CorComponent,
	related_issues: Issue[],
	related_work_orders: WorkOrderNew[],
}) =>
{
	const { claims } = useAuthData();
	
	
	// console.log(component)
	
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	const component_records_collection = useCollectionConditionally(
		(component?.maintenance_function_id !== undefined),
		`${org_path}/component_records`,
		where('maintenance_function_id', '==', component?.maintenance_function_id)
	);
	
	
	if(component_records_collection?.is_loading)
	{
		return <CircularProgress />
	};
	
	
	const component_records = component_records_collection?.data as ComponentRecord[];
	
	// console.log('COMPONENT_RECORD', component_records)
	
	
	
	
	return (
		<div>
			<InfoHighlight
				title='System Information'
				severity='info'
				message_context='Status: Operational'
				message_main={(component_records?.length > 0) ? `Runtime Hours: ${component_records[0].current_value} hours` : ''}
			/>
			
			<Stack
				spacing={2}
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{
					padding: '10px 0'
				}}
			>
				
				<Stack
					spacing={1}
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					sx={{
						color: defaultAppTheme.palette.secondary.dark
					}}
				>
					<CorIcon of='open_issue' size='20px'/>
					
					<Typography variant='body1'>
						Issues: {related_issues.length}
					</Typography>
				</Stack>
				
				<Stack
					spacing={1}
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					sx={{
						color: defaultAppTheme.palette.primary.dark
					}}
				>
					<CorIcon of='open_work_order' size='20px'/>
					
					<Typography variant='body1'>
						Work Orders: {related_work_orders.length}
					</Typography>
				</Stack>
				
			</Stack>
			
			
			
			<Divider orientation='horizontal' flexItem/>
			
			
			<ComponentDrawerActionsList component={component} />
			
		</div>
	)
};


export default ComponentDrawerDetails;
import { Controller } from 'react-hook-form';

import Formlet from '../Formlet';
import { Autocomplete, TextField } from '@mui/material';
import { getEnumKeyByValue, useURLEnumParam } from 'utils';


// Choose between Running Hours, Pressure, etc.
export const EnumSelectFormlet =
({
	name = 'type',
	label = 'Record Type',
	help,
	className,
	sx,
	enum_type,
	url_param_key,
	onChange,
	value,
	multiple = false,
} : {
	name?: string,
	label?: string,
	help?: string,
	enum_type: any,
	className?: string,
	sx?: any,
	url_param_key?: string,
	onChange?: Function,
	multiple?: boolean,
	
	// If not provided as a prop, EnumToggleField will use the value in the URL
	value?: string,
}) =>
{
	const [ url_param_value, setValue ] = useURLEnumParam(url_param_key, enum_type);
	
	
	const handleChange =
	(
		new_type: string | null,
	) =>
	{
		if(new_type !== null)
		{
			// Enum values may not be URL-safe, so we use the enum key instead
			// Ex: 'Red' => 'RED'
			setValue(getEnumKeyByValue(enum_type, new_type));
			
			onChange?.(new_type);
		}
	};
	
	
	if(!enum_type)
	{
		return null;
	}
	
	
	let options : string[] =
		Object.entries(enum_type as [string, string])
			.map(([_id, label]) => (label));
	
	
	console.log('OPTIONS', options);
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
				{
					console.log({value});
					
					return (
						<Autocomplete
							options={options}
							getOptionLabel={option => option}
							value={value || []}
							multiple={multiple}
							disablePortal
							onChange={(e, newValue) => {
								console.log(newValue)
								handleChange(newValue)
								onChange(newValue)
							}}
							renderInput={(params) => <TextField {...params} label={label}/>}
							sx={{
								minWidth: '250px',
								...sx,
							}}
							className={className}
						/>
					)
				}}
			/>
		</Formlet>
	);
}


export default EnumSelectFormlet;
import { Controller } from 'react-hook-form';
import { FormGroup, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import Formlet from '../Formlet';
import EnumToggleField from '../toggles/EnumToggleField';
import { useRef } from 'react';
import { numberWithCommas } from 'utils';
import { useFocus } from 'utils/hooks';
import { Currency } from 'models';


// The only child should be a standard currency string like CAD or USD
const CurrencyAdornment = ({ children: currency }) =>
{
	let symbol = (currency === 'EUR')
		? '€'
		: (currency === 'USD')
			? '$'
			: '$'
	
	
	return (
		<InputAdornment position='start'>
			<Stack
				justifyContent='center'
				alignContent='center'
				justifyItems='center'
				alignItems='center'
				sx={{
					marginLeft: (currency === 'USD') ? '-6px' : 0,
				}}
			>
				<Typography
					sx={{
						fontSize: (currency === 'USD') ? 14 : 16,
					}}
				>
					{symbol}
				</Typography>
				{
					(currency === 'USD')
					&&
					<Typography
						fontSize={10}
					>
						{currency}
					</Typography>
				}
			</Stack>
		</InputAdornment>
	)
}



// TODO: focusing on the input changes form label color as intended, bu enum toggle doesn't
// TODO: Other fields don't change label color
export const CostFormlet =
({
	name = 'cost',
	label = 'Cost',
	help,
} : {
	name?: string,
	label?: string,
	help?: string,
}) =>
{
	const ref = useRef();
	
	const is_focused = useFocus(ref);
	
	
	// const is_focused = useFocus
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<FormGroup
						row
						sx={{
							alignItems: 'center',
							gap: 2,
						}}
					>
						<OutlinedInput
							name={name}
							value={
								(value?.quantity)
									? (!is_focused)
										? numberWithCommas(value?.quantity)
										: value?.quantity
									: ''
							}
							onChange={(e) =>
								onChange({
									quantity: e.target.value || '',
									currency: value.currency || 'CAD',
								})
							}
							
							inputRef={ref}
							
							startAdornment={<CurrencyAdornment>{value?.currency}</CurrencyAdornment>}
							
							sx={{
								width: '170px',
								
								// padding: '8px',
								
								bgcolor: 'field.default',
								
								'&:hover': {
									bgcolor: 'field.hover',
								},
								
								'&.Mui-focused': {
									bgcolor: 'field.focus',
								}
							}}
							
							inputProps={{
								sx: {
									textAlign: 'end',
									// padding: '12px',
								}
							}}
						/>
						
						
						<EnumToggleField
							enum_type={Currency}
							url_param_key='currency'
							value={value?.currency}
							onChange={(v) => onChange({
								quantity: value.quantity,
								currency: v,
							})}
						/>
						
					</FormGroup>
				}
			/>
		</Formlet>
	)
}


export default CostFormlet;
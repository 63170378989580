import { ReactNode, createContext, useContext, useState } from 'react';

import cuid from 'cuid';
import { Action, ActionType, CostCenter, CostCode, Issue, Requisition, RequisitionStatus, StorageFile, WorkOrderNew } from 'models';
import { useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally, useUpdateDocProperty, useUploadFile, useWrite } from 'ember';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Event, Message, Role, Tag } from 'types';
import { User } from 'models/users/user_interfaces';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { where } from 'firebase/firestore';
import { calculateTotalEstimatedCost, createChargeAccountsAction, uploadFilesRequisition, writeRequisitionDataOnCreation } from './requisition_helpers2';
// import { writeRequisitionDataOnCreation } from './requisition_helpers';



export interface RequisitionData
{
	requisition: Requisition,
	cost_centers: CostCenter[],
	cost_codes: CostCode[],
	roles: Role[],
	users: User[],
	issues: Issue[],
	work_orders: WorkOrderNew[],
	tags: Tag[],
	messages?: Message[],
	events?: Event[],
	files?: StorageFile[],
	actions?: Action[],
	linked_issues?: Issue[],
	linked_work_orders?: WorkOrderNew[],
	
	
	uploadFiles?: Function,
	updateActualCost?: Function,
	updateItemsSection?: Function,
	updateCostCenters?: Function,
	updateChargeAccounts?: Function,
	updateCostCodes?: Function,
	updateLinkedIssues?: Function,
	updateLinkedWorkOrders?: Function,
}




const DEFAULT_FORM_DATA : Requisition =
{
	_id: cuid(),
	title: '',
	requisition_num: null,
	
	created_by_user: null,
	
	created_at: '',
	
	status: '',
	status_message: '',
	
	estimated_cost: {
		currency: '',
		quantity: '',
	},
	
	final_cost: {
		currency: 'CAD',
		quantity: '',
		is_approved: false,
	},
	
	cost_center_ids: [],
	cost_code_ids: [],
	
	accounts_to_charge_ids: [],
	
	is_critical: null,
	
	items: {
		parts: [],
		sections: [],
	},
	
	files: null,
	
	work_order_ids: [],
	issue_ids: [],
	tag_ids: [],
	collaborator_ids: [],
	role_ids: [],
	
	
	charge_accounts: [],
	issues: [],
	work_orders: [],
	tags: [],
	roles: [],
	collaborators: [],
	cost_centers: [],
	cost_codes: [],
}



const RequisitionDataContext = createContext<RequisitionData>(null);


export const useRequisition = () =>
{
	return useContext(RequisitionDataContext)
};



const RequisitionDataProvider =
({
	requisition,
	children,
} : {
	requisition?: Requisition,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	
	const current_user = {
		_id: claims.user_id,
		name: claims.name,
		email: claims.email,
	}
	
	
	const uploadFile = useUploadFile();
	const write = useWrite();
	const navigate = useNavigate();
	const updateDocProperty = useUpdateDocProperty();
	
	const [ is_submitting, setIsSubmitting ] = useState(false);
	
	const datetime = DateTime.utc().toISO();
	
	
	const form = useForm<Requisition>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
			...requisition,
		},
	});
	
	const form_values = form.getValues();
	console.log({form_values});
	
	
	const { control } = form;
	
	const parts = useWatch({
		control,
		name: 'items.parts'
	});
	
	const sections = useWatch({
		control,
		name: 'items.sections'
	});
	
	console.log(parts, sections)
	
	
	
	useEffect(() =>
	{
		calculateTotalEstimatedCost(parts, sections, form);
	}, [parts, sections])
	
	
	
	
	
	
	const handleSubmit = async (e) =>
	{
		e.preventDefault();
		
		const form_values = form.getValues();
		
		const values = {
			...form_values,
			_id: cuid(),
		}
		
		// TODO: Commit the file this function is in
		console.error('TODO: Commit the file this function is in')
		// writeRequisitionDataOnCreation(values, write, current_user, claims, uploadFile, navigate);
		writeRequisitionDataOnCreation(values, write, current_user, claims, uploadFile, navigate);
	}
	
	
	const org_path = `organizations/${claims.organization_id}`;
	
	
	const cost_centers_collection_path = useCollection(`${org_path}/cost_centers`);
	const cost_codes_collection = useCollection(`${org_path}/cost_codes`);
	const users_collection = useCollection(`${org_path}/users`);
	const roles_collection = useCollection(`${org_path}/roles`);
	const issues_collection = useCollection(`${org_path}/issues`);
	const work_orders_collection = useCollection(`${org_path}/work_orders`);
	const tags_collection = useCollection(`${org_path}/tags`);
	
	
	// Conditionally call these collections on the Requisition Collaboration Phase
	const messages_collection = useCollectionConditionally(
		(requisition !== undefined),
		`${org_path}/messages`,
		where('requisition_id', '==', requisition?._id)
	);
	const events_collection = useCollectionConditionally(
		(requisition !== undefined),
		`${org_path}/events`,
		where('requisition_id', '==', requisition?._id)
	);
	const files_collection = useCollectionConditionally(
		(requisition !== undefined),
		`${org_path}/files`,
		where('requisition_id', '==', requisition?._id)
	);
	const actions_collection = useCollectionConditionally(
		(requisition !== undefined),
		`${org_path}/actions`,
		where('requisition_id', '==', requisition?._id)
	);
	const linked_issues_collection = useCollectionConditionally(
		((requisition !== undefined) && (requisition?.issue_ids?.length > 0)),
		`${org_path}/issues`,
		where('_id', 'in', requisition?.issue_ids)
	);
	const linked_work_orders_collection = useCollectionConditionally(
		((requisition?.work_order_ids?.length > 0) && (requisition !== undefined)),
		`${org_path}/work_orders`,
		where('_id', 'in', requisition?.work_order_ids)
	);
	
	
	
	
	
	
	
	const cost_codes = cost_codes_collection?.data as CostCode[] || [];
	const cost_centers = cost_centers_collection_path?.data as CostCenter[] || [];
	const users = users_collection?.data as User[] || [];
	const roles = roles_collection?.data as Role[] || [];
	const issues = issues_collection?.data as Issue[] || [];
	const work_orders = work_orders_collection?.data as WorkOrderNew[] || [];
	const tags = tags_collection?.data as Tag[] || []; 
	const messages = messages_collection?.data as Message[] || [];
	const events = events_collection?.data as Event[] || [];
	const files = files_collection?.data as StorageFile[] || [];
	const actions = actions_collection?.data as Action[] || [];
	const linked_issues = linked_issues_collection?.data as Issue[] || [];
	const linked_work_orders = linked_work_orders_collection?.data as WorkOrderNew[] || [];
	
	
	
	const updateActualCost = () =>
	{
		const { final_cost } = form.getValues();
		
		const final_cost_data = {
			...final_cost,
			is_approved: false,
		}
		
		const charge_account_action = actions.filter(x => x.action_type === ActionType.CHARGE_ACCOUNTS)[0];
		
		console.log('FINAL COST', {final_cost});
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'final_cost',
			final_cost_data
		);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'estimated_cost',
			final_cost
		);
		
		// update the action charge amount value for this requisiton
		updateDocProperty(
			`${org_path}/actions/${charge_account_action._id}`,
			'accounts_charged_amount',
			final_cost_data
		);
		
		form.setValue(
			'estimated_cost',
			final_cost
		)
		
		updateWorkOrderStatusToInProgress();
	};
	
	
	const updateWorkOrderStatusToInProgress = () =>
	{
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'status',
			RequisitionStatus.IN_PROGRESS
		);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'updated_at',
			datetime
		);
	};
	
	
	const uploadFiles = (file_list: FileList | null) =>
	{
		uploadFilesRequisition(
			file_list,
			claims,
			uploadFile,
			requisition,
			write
		)
		
		updateWorkOrderStatusToInProgress();
	};
	
	
	const updateItemsSection = () =>
	{
		const form_values = form.getValues();
		
		const { items, estimated_cost, final_cost } = form_values;
		
		const charge_account_action = actions.filter(x => x.action_type === ActionType.CHARGE_ACCOUNTS)[0];
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'items',
			items
		);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'estimated_cost',
			estimated_cost
		);
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'final_cost',
			{
				quantity: estimated_cost?.quantity,
				currency: estimated_cost?.currency,
				is_approved: false,
			}
		);
		
		updateDocProperty(
			`${org_path}/actions/${charge_account_action._id}`,
			'accounts_charged_amount',
			{
				quantity: estimated_cost?.quantity,
				currency: estimated_cost?.currency,
				is_approved: false,
			}
		);
		
		form.setValue(
			'final_cost',
			{
				quantity: estimated_cost?.quantity,
				currency: estimated_cost?.currency,
				is_approved: false,
			}
		)
		
		updateWorkOrderStatusToInProgress();
	};
	
	
	
	const updateCostCenters = () =>
	{
		const { cost_centers } = form.getValues();
		
		console.log(cost_centers);
		
		const cost_center_ids = cost_centers.map(x => x._id);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'cost_center_ids',
			cost_center_ids
		)
		
		updateWorkOrderStatusToInProgress();
	};
	
	
	const updateCostCodes = () =>
	{
		const { cost_codes } = form.getValues();
		
		
		const cost_code_ids = cost_codes.map(x => x._id);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'cost_code_ids',
			cost_code_ids
		)
		
		updateWorkOrderStatusToInProgress();
	};
	
	
	
	const updateChargeAccounts = () =>
	{
		const { charge_accounts, _id, final_cost, cost_center_ids, cost_code_ids } = form.getValues();
		
		const accounts_to_charge_ids = charge_accounts.map(x => x._id);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'accounts_to_charge_ids',
			accounts_to_charge_ids
		);
		
		
		createChargeAccountsAction(
			charge_accounts,
			_id,
			current_user,
			write,
			claims,
			final_cost,
			cost_center_ids,
			cost_code_ids,
		);
		
		updateWorkOrderStatusToInProgress();
	};
	
	
	const updateLinkedIssues = () =>
	{
		const { issues } = form.getValues();
		
		const issue_ids = issues.map(x => x._id);
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'issue_ids',
			issue_ids
		);
	};
	
	
	const updateLinkedWorkOrders = () =>
	{
		const { work_order_ids } = form.getValues();
		
		updateDocProperty(
			`${org_path}/requisitions/${requisition._id}`,
			'work_order_ids',
			work_order_ids
		);
	};
	
	
	
	
	
	
	
	const requisition_data =
	{
		requisition: requisition,
		cost_centers: cost_centers || [],
		cost_codes: cost_codes || [],
		users: users || [],
		roles: roles || [],
		issues: issues || [],
		work_orders: work_orders || [],
		tags: tags || [],
		messages: messages || [],
		events: events || [],
		files: files || [],
		actions: actions || [],
		linked_issues: linked_issues || [],
		linked_work_orders: linked_work_orders || [],
		
		
		uploadFiles: uploadFiles,
		updateActualCost: updateActualCost,
		updateItemsSection: updateItemsSection,
		updateCostCenters: updateCostCenters,
		updateChargeAccounts: updateChargeAccounts,
		updateCostCodes: updateCostCodes,
		updateLinkedIssues: updateLinkedIssues,
		updateLinkedWorkOrders: updateLinkedWorkOrders,
	}
	
	
	
	
	
	
	
	
	return (
		<FormProvider {...form}>
			<RequisitionDataContext.Provider
				value={requisition_data}
			>
				<form
					onSubmit={handleSubmit}
				>
					{children}
				</form>
			</RequisitionDataContext.Provider>
		</FormProvider>
	)
}


export default RequisitionDataProvider;
import { WorkOrderField, StepperForm } from '../work_order_types';
import { Box, Button } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ReactNode, useEffect, useRef } from 'react';
import { SectionDivider } from './common/SectionDivider/SectionDivider';
import { ActionTypeParent } from './common/ActionTypeParent/ActionTypeParent';
import { MultipleChoice } from './ActionTypes/MultipleChoice/MultipleChoice';
import { CheckboxListOld } from './ActionTypes/CheckBox/CheckboxListOld';
import { PassFail } from './ActionTypes/PassFail/PassFail';
import { Date } from './ActionTypes/Date/Date';
import { useIsMobile } from 'utils/hooks/useIsMobile';
import { Reading } from './ActionTypes/Reading/Reading';
import { Replace } from './ActionTypes/Replace/Replace';


export interface SectionsPreviewProps
{
	form: UseFormReturn<StepperForm>;
	hidePreview?: () => void;
}


export const SectionsPreview =
({
	form,
	hidePreview,
}: SectionsPreviewProps) =>
{
	const { sections } = form.getValues();
	
	const { ref: topRef } = useScrollToElementOnRender();
	
	const isMobile = useIsMobile();
	
	
	return (
		<Box
			sx={{
				marginInline: 'auto',
				maxWidth: '700px',
				marginBlock: '3rem',
				position: 'relative',
				fontSize: '14px',
				paddingBottom: '20rem',
			}}
		>
			<div ref={topRef} />
			{!isMobile && (
				<Box sx={{ position: 'absolute', left: '0px', top: '0px' }}>
					<Button
						startIcon={<ArrowBackIcon />}
						onClick={hidePreview}
						variant='text'
					>
						Back to edit
					</Button>
				</Box>
			)}
			<Box
				sx={{
					marginLeft: 'auto',
					maxWidth: '600px',
					paddingTop: '2rem',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '0.5rem',
						color: '#3F8ED8',
						marginInline: 'auto',
						marginBottom: '1rem',
					}}
				>
					<VisibilityIcon htmlColor='#3F8ED8' />
					<Box>Preview</Box>
				</Box>

				<Box
					sx={{
						border: '1px solid #d8dbdf',
						borderRadius: '5px',
						paddingBlock: '2rem',
						paddingInline: '3rem',
						backgroundColor: 'white',
					}}
				>
					<Box
						sx={{
							paddingBottom: '2rem',
						}}
					>
						<Box
							sx={{
								fontWeight: '500',
								fontSize: '18px',
								paddingBottom: '0.5rem',
							}}
						>{`Breakdown Information Sections`}</Box>
						<Box sx={{ marginLeft: '3rem' }}>
							{`In this section all the fields related to breakdown are grouped together. Before starting the process, refer to manual #43 attached.`}
						</Box>
					</Box>
					<SectionDivider />
					{sections.map((section, j) => (
						<Box
							key={j}
						>
							{section.fields.map((field: WorkOrderField, i: number) =>
								renderActionType(field, i)
							)}
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};


const renderActionType = (field: WorkOrderField, index: number): ReactNode =>
{
	const action_type = field.action_type;
	
	
	switch (action_type)
	{
		case '':
			return null;
		
		case 'text':
			return (
				<ActionTypeParent
					description={field.text.description}
					key={index}
				>
					<></>
				</ActionTypeParent>
			);
		
		case 'multiple_choice':
			return (
				<ActionTypeParent
					description={field.multiple_choice.description}
					key={index}
				>
					<MultipleChoice field={field} />
				</ActionTypeParent>
			);
		
		case 'checkbox':
			return (
				<ActionTypeParent
					description={field.checkbox.description}
					key={index}
				>
					<CheckboxListOld field={field} />
				</ActionTypeParent>
			);
		
		case 'pass_fail':
			return (
				<ActionTypeParent
					description={field.pass_fail.description}
					key={index}
				>
					<PassFail />
				</ActionTypeParent>
			);
		
		case 'yes_no':
			return (
				<ActionTypeParent
					description={field.yes_no.description}
					key={index}
				>
					<PassFail />
				</ActionTypeParent>
			);
		
		case 'date':
			return (
				<ActionTypeParent
					description={field.date.description}
					key={index}
				>
					<Date />
				</ActionTypeParent>
			);
		
		case 'reading':
			return (
				<ActionTypeParent
					description={field.reading.description}
					key={index}
				>
					<Reading field={field} />
				</ActionTypeParent>
			);
		
		case 'replace':
			return (
				<ActionTypeParent
					description={field.replace.description}
					key={index}
				>
					<Replace field={field} />
				</ActionTypeParent>
			);
		
		default:
			return <div>Default</div>;
	}
};


const useScrollToElementOnRender = () =>
{
	const ref = useRef<HTMLDivElement>(null);
	
	useEffect(() =>
		{
			ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
		},
		[ref]
	);
	
	
	return { ref };
};


import { Stack } from '@mui/material';
import { useTitle } from 'utils';

import DemoStepper from './DemoStepper';


const DemoPage = () =>
{
	useTitle('Demo');
	
	
	return (
		<Stack
			sx={{
				bgcolor: 'yellow',
				flexGrow: 1,
			}}
		>
			<DemoStepper />
		</Stack>
	)
}


export default DemoPage;
import { Controller } from 'react-hook-form';

import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';

import { ActionProps } from '../../../types';


export interface ReadingTypeProps extends ActionProps {}


export const ReadingType =
({
	control,
	fieldIndex,
	sectionIndex,
}: ReadingTypeProps) =>
{
	return (
		<FormControl>
			<Controller
				control={control}
				name={`sections.${sectionIndex}.fields.${fieldIndex}.reading.readingType`}
				defaultValue='cumulative'
				render={({ field: { value, onChange } }) => (
					<RadioGroup
						onChange={(event) => {
							onChange(event.target.value);
						}}
						value={value}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							<FormControlLabel
								value='cumulative'
								control={<Radio />}
								label={'Take Cumulative Reading'}
							/>
							<FormControlLabel
								value='increment'
								control={<Radio />}
								label={'Take Increment Reading'}
							/>
						</Box>
					</RadioGroup>
				)}
			/>
		</FormControl>
	);
};


export default ReadingType;
import { useFormContext } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import TriggerGizmo from './TriggerGizmo';
import { Trigger } from 'models/triggers/trigger_models';
import CorIcon from 'components/icons/CorIcon';
import cuid from 'cuid';
import FieldHelp from '../../fields/toggles/FieldHelp';
import WorkOrderTemplatesLoader from 'app/providers/WorkOrderTemplatesLoader';


// import { Doc } from 'ember';


// export const sortById = (a: Doc, b: Doc) =>
// {
// 	return (
// 		(a._id < b._id)
// 			? -1
// 			: (a._id > b._id)
// 				? 1
// 				: 0
// 	)
// }


// This is a bit sloppy
export const TriggersGizmo =
({
	name = 'triggers_by_id',
	label = 'Triggers',
} : {
	name?: string,
	label?: string,
}) =>
{
	const form_context = useFormContext();
	
	
	let value = form_context.watch(name);
	
	
	console.log(
		form_context.getValues()
	)
	
	
	return (
		<WorkOrderTemplatesLoader>
			<Stack>
				
				<Typography
					variant='h4'
				>
					{label}
				</Typography>
				
				
				<FieldHelp>
					When a reading reaches a threshold, an action may be triggered
				</FieldHelp>
				
				
				<Stack
					sx={{
						padding: 2,
					}}
				>
					<Stack>
						{
							Object.entries(value)?.sort()?.map(([trigger_id, trigger], i) =>
								<TriggerGizmo
									basename={name}
									trigger={trigger as Trigger}
									key={trigger_id}
									index={i}
								/>
							)
						}
					</Stack>
					
					<Button
						startIcon={<CorIcon of='plus_basic' />}
						onClick={() => {
							let id = cuid();
							
							form_context.setValue(name, Object.assign({...value}, {
								[id]: {
									_id: id,
									name: '',
									threshold: {
										// max: 200,
									},
									action: {
										
									}
								}
							}))
						}}
					>
						Add Trigger
					</Button>
					
				</Stack>
				
			</Stack>
		</WorkOrderTemplatesLoader>
	);
}


export default TriggersGizmo;
import { useState } from 'react';


export interface ToggleProperties
{
	isOpen: boolean,
	open: () => void,
	close: () => void,
	toggle: () => void,
}


export const useToggle = (defaultValue?: boolean): ToggleProperties =>
{
	const [isOpen, setIsOpen] = useState(!!defaultValue);
	
	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);
	const toggle = () => setIsOpen((state) => !state);
	
	
	return {
		isOpen,
		open,
		close,
		toggle
	}
}


import { Stack } from '@mui/material';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { CostCenter } from 'models';


const EditCostCenters =
({
	cost_centers,
	handleSubmit,
} : {
	cost_centers: CostCenter[],
	handleSubmit: Function,
}) =>
{
	
	
	return (
		<Stack
			flexDirection='row'
			alignItems='center'
			justifyContent='flex-end'
		>
			<ButtonToOpenDialog
				button_variant='text'
				button_content='Edit'
				dialog_title={`Add or Remove Cost Centers`}
				dialog_dismiss_text='Discard'
				dialog_submit_text='Submit Changes'
				dialog_content={
					<SearchableSelectFormlet
						name='cost_centers'
						label='Select associated Cost Centers'
						help='e.g. Main Office Cost Center'
						options={cost_centers}
						getOptionLabel={option => option.description}
						multiple={true}
					/>
				}
				sx={{
					textTransform:'none',
					textDecoration: 'underline',
				}}
				handleSubmit={handleSubmit}
				dialogClassName='add_tags_dialog'
			/>
		</Stack>
	)
};


export default EditCostCenters;
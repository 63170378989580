import { Button, Stack, Typography } from '@mui/material';
import InstantNotificationPopup from 'app/dashboard/nav/notifications/InstantNotificationPopup';
import { useSession } from 'app/providers/SessionProvider';
import { WorkOrderStatus } from 'models';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CollaboratorsAccordion from '../../issueCollaboration/issueDetails/collaborators/CollaboratorsAccordion';
import { useWorkOrder } from '../WorkOrderProvider';
import WorkOrderFormSections from './WorkOrderFormSections';


const WorkOrderSteps = () =>
{
	const form_context = useFormContext();
	
	const [ fireAlert, setFireAlert ] = useState<boolean>(false);
	
	const { work_order, submitWorkStepsResponse } = useWorkOrder();
	const { users } = useSession();
	
	const filled_by_users = users.filter(x => work_order?.filled_by_user_ids?.includes(x._id));
	
	const handleSubmit = () =>
	{
		submitWorkStepsResponse();
		setFireAlert(true);
	}
	
	
	
	return (
		<Stack
			padding={1}
			gap={1}
		>
			<Typography
				variant='h2'
			>
				Please fill the Work Steps/Instructions
			</Typography>
			
			<WorkOrderFormSections form={form_context} />
			
			
			<Stack
				direction='column'
				sx={{
					maxWidth: '800px',
					width: '100%',
					alignSelf: 'center',
					padding: '20px',
					background: '#fff',
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;'
				}}
				spacing={1}
			>
				<Typography variant='h6'>
					Filled by users:
				</Typography>
				<CollaboratorsAccordion users={filled_by_users}/>
				
				<Button
					disabled={work_order.status === WorkOrderStatus.COMPLETED}
					variant='contained'
					onClick={handleSubmit}
				>
					Submit Response
				</Button>
			</Stack>
			
			<InstantNotificationPopup
				open={fireAlert}
				setOpen={setFireAlert}
				severity={'success'}
				message='Your work steps response was saved successfully'
			/>
		</Stack>
	)
};


export default WorkOrderSteps;
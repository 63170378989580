

import Drawer from '@mui/material/Drawer';
import { Box, CircularProgress, IconButton } from '@mui/material';

import { CorComponent } from 'models';
import CorIcon from 'components/icons/CorIcon';
import ComponentInfoCard from './ComponentInfoCard';
import ComponentDrawerTabs from './ComponentDrawerTabs';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useParams } from 'react-router';
import { useAuthData, useEffect } from 'stuff';
import { useDoc } from 'ember';


const ComponentInfoDrawer =
({
	isComponentDrawerOpen,
	toggleDrawer,
	selectedComponent,
	setIsComponentDrawerOpen
} : {
	isComponentDrawerOpen: boolean,
	toggleDrawer: any,
	selectedComponent?: CorComponent,
	setIsComponentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}) =>
{
	const { component_id } = useParams();
	
	const { claims } = useAuthData();
	
	
	useEffect(() =>
	{
		if(component_id)
		{
			toggleDrawer();
		}
	}, [ component_id ]);
	
	
	// Get component data from params
	const component_doc = useDoc(`organizations/${claims?.organization_id}/components/${component_id}`);
	
	
	if(component_doc?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const component_data = component_doc?.data as CorComponent;
	
	
	return (
		<Drawer
			anchor='bottom'
			open={isComponentDrawerOpen}
			onClose={toggleDrawer}
			className='component_drawer'
			sx={{
				[defaultAppTheme.breakpoints.up('md')]: {
					display: 'none'
				},
			}}
		>
			<Box className='mobile_nav_drawer_header'>
				<ComponentInfoCard component={component_data} />
				
				<IconButton onClick={toggleDrawer}>
					<CorIcon of='close' size='20px'/>
				</IconButton>
			</Box>
			
			<ComponentDrawerTabs component={component_data} />
		</Drawer>
	)
}


export default ComponentInfoDrawer;
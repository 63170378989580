import { Stack } from '@mui/material';
import { useRequisition } from './RequisitionDataProvider';
import SelectCollaboratorsFormlet from 'components/selectCollaborators/desktop/SelectCollaboratorsFormlet';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import SelectedCollaboratorsList from 'components/selectCollaborators/desktop/SelectedCollaboratorsList';
import { useEffect, useState } from 'react';
import { User } from 'models/users/user_interfaces';



const RequisitionChargeToAccount = () =>
{
	const [ filteredUsers, setFilteredUsers ] = useState<User[]>([]);
	
	const { users } = useRequisition();
	
	const form = useFormContext();
	const { control } = form;
	
	const cost_centers = useWatch({
		control,
		name: 'cost_centers'
	});
	
	
	const estimated_cost = useWatch({
		control,
		name: 'estimated_cost'
	});
	
	
	useEffect(() =>
	{
		const requisition_cost_center_ids = cost_centers.map(x => x._id);
		
		const users_with_approval_ability = [] 
		
		
		users.forEach(user => {
			user?.cost_center_ids?.forEach(x => {
				
				const canApprove = Number(user?.single_purchase_limit?.quantity) >= Number(estimated_cost?.quantity);
				
				if(requisition_cost_center_ids.includes(x) && canApprove)
				{
					users_with_approval_ability.push(user)
				}
				
			})
		});
		
		console.log({users_with_approval_ability});
		
		setFilteredUsers(users_with_approval_ability)
	}, [cost_centers, estimated_cost]);
	
	
	const charge_accounts = useWatch({
		control,
		name: 'charge_accounts'
	});
	
	const { remove, append } = useFieldArray({
		control,
		name: 'charge_accounts'
	});
	
	console.log('CHARGE ACCOUNTS', charge_accounts);
	
	
	const selectUser = (selected_account: User) =>
	{
		console.log(selected_account);
		const user_ids = charge_accounts?.map(x => x._id);
		
		if(user_ids.includes(selected_account._id))
		{
			unSelectUser(selected_account)
		}
		else
		{
			append(selected_account)
		}
	}
	
	
	const unSelectUser = (selected_account: User) =>
	{
		let index: number | undefined;
		
		charge_accounts.forEach((account, idx) =>
		{
			if (account._id === selected_account._id)
			{
				index = idx;
			}
		});
		
		remove(index);
	};
	
	
	
	return (
		<Stack>
			<SelectCollaboratorsFormlet
				handleSelectedCollaborators={selectUser}
				label='Please select the account to charge'
				help='Tip: You can only add accounts that can approve this requisition under the selected Cost Center'
				button_content='Select Account'
				selectedCollaborators={charge_accounts}
				users_list={filteredUsers}
			/>
			
			<SelectedCollaboratorsList
				users={charge_accounts}
				handleChange={unSelectUser}
			/>
		</Stack>
	)
};


export default RequisitionChargeToAccount;
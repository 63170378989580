import { useNavigate } from 'react-router';

import { Box, Typography, Stack, Chip } from '@mui/material';

import SortableTable from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/SortableTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Requisition, RequisitionStatus } from 'models';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import RequisitionCostCentersList from './RequisitionCostCentersList';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';
import { DateTime } from 'luxon';
import { relativeDatetimeText, relativeDaysDiff } from 'utils';


const RequisitionsTable =
({
	requisitions,
	title,
}: {
	requisitions: Requisition[],
	title?: string,
}
) =>
{
	const navigate = useNavigate();
	
	
	
	return(
		<Box>
			<Typography
				variant='h6'
			>
				{title}
			</Typography>
			
			<SortableTable
				options={requisitions}
				onSelect={(selectedOption) => {
					navigate(`/requisitions/${selectedOption._id}`)
				}}
				rows={[
					{
						title: 'Requisition #',
						getCell(requisition)
						{
							return (
								<Box sx={{ marginInline: '0.5rem' }}>
									{requisition?.requisition_num || ''}
								</Box>
							);
						},
					},
					{
						title: 'Status',
						getCell(requisition)
						{
							const { status } = requisition;
							
							return (
								<Stack
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
								>
									<CorIcon
										of={
											(status === RequisitionStatus.STARTED)
											? 'requisition_open'
											: (status === RequisitionStatus.ACCOUNTS_APPROVED)
											? 'requisition_waiting_approval'
											: (status === RequisitionStatus.IN_PROGRESS)
											? 'requisition_in_progress'
											: 'requisition_completed'
										}
										size='24px'
										color={
											(status === RequisitionStatus.STARTED)
												? defaultAppTheme.palette.secondary.main
												: (status === RequisitionStatus.IN_PROGRESS)
												? defaultAppTheme.palette.primary.main
												: ((status === RequisitionStatus.ACCOUNTS_APPROVED))
												? defaultAppTheme.palette.warning.main
												: defaultAppTheme.palette.success.dark
										}
									/>
									
									<Typography
										variant='body1'
										color={
											(status === RequisitionStatus.STARTED)
												? defaultAppTheme.palette.secondary.main
												: (status === RequisitionStatus.IN_PROGRESS)
												? defaultAppTheme.palette.primary.main
												: ((status === RequisitionStatus.ACCOUNTS_APPROVED))
												? defaultAppTheme.palette.warning.main
												: defaultAppTheme.palette.success.dark
											}
										sx={{
											paddingLeft: '5px',
										}}
									>
										{ status.toUpperCase() }
									</Typography>
								</Stack>
							)
						},
					},
					{
						title: 'Title',
						getCell(requisition)
						{
							return <Typography
							variant='subtitle1'
							sx={{
								inlineSize: '250px',
								overflowWrap: 'break-word',
							}}
						>
							{ requisition?.title }
						</Typography>
						},
					},
					{
						title: 'Assigned',
						getCell(requisition) {
							
							const is_assigned = ((requisition?.collaborator_ids?.length > 0
									|| requisition?.role_ids?.length > 0
									|| requisition?.accounts_to_charge_ids.length > 0
								)) ? true : false;
							
							return (
								<>
									{
										(is_assigned) ? (
											<Chip color='success' label='assigned'/>
										) : (
											<Chip label='unassigned' />
										)
									}
								</>
							)
						},
					},
					{
						title: 'Cost Centers',
						getCell(requisition)
						{
							return (
								<Stack
									flexDirection='row'
									alignItems='center'
									justifyContent='flex-start'
								>
									<RequisitionCostCentersList requisition={requisition} />
								</Stack>
							)
						},
					},
					{
						title: 'Start Date',
						getCell(requisition) {
							
							const created_at_datetime = DateTime.fromISO(requisition?.created_at)
							const days_diff = relativeDaysDiff(created_at_datetime);
							
							return (
								<Stack
									direction='column'
									justifyContent='center'
									alignItems={'flex-start'}
									spacing={1}
								>
									<Typography
										sx={{
											background: ((days_diff > -7) && (requisition?.status !== RequisitionStatus.COMPLETED))
												? defaultAppTheme.palette.primary.main
												: (days_diff < -7 && days_diff >= -30 && requisition?.status !== RequisitionStatus.COMPLETED)
												? defaultAppTheme.palette.warning.main
												: (days_diff < -30 && requisition?.status !== RequisitionStatus.COMPLETED)
												? defaultAppTheme.palette.secondary.main
												: defaultAppTheme.palette.grey['500'],
											padding: '4px',
											borderRadius: '4px',
											color: '#fff',
										}}
									>
										{ relativeDatetimeText(created_at_datetime) }
									</Typography>
									
									<ConciseDateAndTime datetime={created_at_datetime} />
								</Stack>
							)
						}
					}
				]}
			/>
		</Box>
	)
};


export default RequisitionsTable;
import { Divider } from '@mui/material';


export const SectionDivider = () =>
(
	<Divider
		color='#3f8ed8'
		sx={{
			marginBlock: '1rem',
		}}
	/>
);



export default SectionDivider;
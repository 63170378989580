import { useState } from 'react';

import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import { SortableTableProps } from './table_interfaces';
import { OptionBase } from 'models';
import GeneralTable from './GeneralTable';



const OPTIONS_PER_PAGE = 10;



const getFilteredOptions = <T extends OptionBase>(
	options: T[],
	searchText: string
	): T[] => {
	if (!searchText) return options;
		
	return options.filter((option) =>
		JSON.stringify(option).toLowerCase().includes(searchText)
	);
};



const SortableTable = <T extends OptionBase>({
	options,
	rows,
	onSelect,
}: SortableTableProps<T>) =>
{
	const [searchText, setSearchText] = useState('');
	
	const filteredOptions = getFilteredOptions(options, searchText);
	
	const [page, setPage] = useState(1);
	const startIndex = (page - 1) * OPTIONS_PER_PAGE;
	
	
	
	return(
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: '1rem',
			}}
		>
		<GeneralTable
			options={filteredOptions.slice(
				startIndex,
				startIndex + OPTIONS_PER_PAGE
			)}
			onOptionClick={onSelect}
			rows={rows}
		/>
		<Pagination
			page={page}
			count={Math.ceil(filteredOptions.length / OPTIONS_PER_PAGE)}
			onChange={(_e, page) => {
				setPage(page);
			}}
		/>
	</Box>
	)
};


export default SortableTable;
import { useEffect } from 'react';

import { useSession } from 'app/providers/SessionProvider';
import { RoleTag } from 'models/users/user_interfaces';
import { Tag } from 'types';
import TagChip from 'app/dashboard/pages/issueCollaboration/issueDetails/TagChip';
import { Grid } from '@mui/material';


const RoleTags = ({
	role_tags,
	setSelectedTags,
} : {
	role_tags: RoleTag[],
	setSelectedTags: React.Dispatch<React.SetStateAction<Tag[]>>,
}) =>
{
	const { all_available_tags } = useSession();
	
	console.log({role_tags});
	
	const filtered_tags: Tag[] = all_available_tags?.filter(tag => {
		if(role_tags !== undefined) return role_tags[tag._id]
	});
	
	console.log({filtered_tags});
	
	
	useEffect(() =>
	{
		setSelectedTags(filtered_tags)
	}, [role_tags])
	
	return (
		<Grid
			container
			gap={2}
			maxWidth='80%'
		>
			{
				filtered_tags && filtered_tags?.map(tag =>
					<Grid key={tag._id}>
						<TagChip tag={tag}/>
					</Grid>
				)
			}
		</Grid>
	)
}


export default RoleTags;
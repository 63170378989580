import { React, NavLink } from '../../../stuff';


// Top-left corner of 2x2 Dashboard grid, containing PolyUnity logo as SVG
// Click to go to the Home page
const HeaderLogo = () =>
{
	return (
		<NavLink
			to='/'
			className={'nav_logo'}
		>
			<img
				src={'/images/logo_original.png'}
				alt='CORSphere Logo'
				className={'nav_logo'}
			/>
		</NavLink>
	)
}


export default HeaderLogo;
import React, { useEffect, useState } from 'react';

import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';
import { Role } from 'models/users/user_interfaces';
import RoleCard from './RoleCard';


const SearchableRolesCardList = ({
	roles,
} : {
	roles: Role[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);
	
	const [ filtered_roles, setFilteredRoles ] = useState<Role[]>(roles);
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	useEffect(() =>
	{
		setFilteredRoles(roles)
	}, [roles])
	
	
	console.log({filtered_issues: filtered_roles});
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			<SearchBarOld
				items={roles}
				handleItems={setFilteredRoles}
				fields={['name']}
			/>
			{
				filtered_roles?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((role: Role) => (
					<RoleCard key={role._id} role={role}/>
				))
			}
			<Pagination
				count={Math.ceil(roles.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default SearchableRolesCardList;
import { Button, Chip, CircularProgress, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { useCollection, useDelete, useWrite } from 'ember';
import { useState } from 'react';
import { useAuthData } from 'stuff';
import cuid from 'cuid';
import { CostCode } from 'models/costs';


const ManageCostCodes = () =>
{
	const { claims } = useAuthData();
	const write = useWrite();
	const deleteDoc = useDelete();
	
	const [ cost_code_description, setCostCodeDescription ] = useState<string>('');
	
	const cost_codes_collection_path = `organizations/${claims?.organization_id}/cost_codes`;
	const cost_codes_collection = useCollection(cost_codes_collection_path);
	
	
	const addRoleToDb = () =>
	{
		console.log({cost_code_description});
		
		const cost_code_data: CostCode = {
			_id: cuid(),
			description: cost_code_description,
		}
		
		write(
			cost_codes_collection_path,
			cost_code_data,
			() => setCostCodeDescription('')
		);
	};
	
	
	const deleteCostCodeFromDb = (cost_code: CostCode) =>
	{
		console.log({cost_code});
		
		deleteDoc(cost_codes_collection_path, cost_code);
	}
	
	
	
	
	if(cost_codes_collection?.is_loading) return <CircularProgress />
	
	const cost_codes = cost_codes_collection?.data as CostCode[];
	
	
	
	return (
		<Stack
			direction='column'
			alignItems='flex-start'
			justifyContent='flex-start'
			spacing={2}
			width='100%'
			className='container'
		>
			
			<Typography
				variant='h4'
			>
				Add a new Cost Code
			</Typography>
				
			
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={2}
				width='100%'
			>
					<TextField
						label='Cost Code Description'
						placeholder='e.g. Cleaning'
						value={cost_code_description}
						onChange={e => setCostCodeDescription(e.target.value)}
						sx={{
							width: '50%'
						}}
					/>
					
					<Button
						variant='contained'
						onClick={addRoleToDb}
					>
						Add Cost Code
					</Button>
			</Stack>
			
			<Stack>
				<Typography
					variant='h3'
				>
					Available Cost Codes
				</Typography>
				
				<Divider sx={{ width: '100%' }} />
				
				<Grid
					container
					sx={{ maxWidth: '900px', padding: '10px' }}
					gap={2}
				>
					{
						cost_codes && cost_codes.map(cost_code =>
								<Grid
									key={cost_code._id}
									item
								>
									<Chip
										label={cost_code.description}
										onDelete={() => deleteCostCodeFromDb(cost_code)}
									/>
								</Grid>
							)
					}
				</Grid>
			</Stack>
			
		</Stack>
	)
};


export default ManageCostCodes;
import { ensureLocalDateTime } from 'models/recurrence/recurrence_helpers2';
import { DateTime, DurationLikeObject, ToObjectOutput } from 'luxon'
import { OnDemandRecurrenceType, RecurrenceEffectiveType, RecurrenceScheduleType, RecurrenceType } from './recurrence_enums';
import { Occurrence, Recurrence } from './recurrence_interfaces'
import { arrayOf, multiplyDuration, zeroAllBut, fixInvalidDateTime, combineDateTimeAndDuration,  } from 'utils';
import { Dictionary, groupBy } from 'lodash';




// TODO: Move
const scheduleToUnits : { [ RecurrenceScheduleType: string ] : string } =
{
	Daily   : 'days',
	Weekly  : 'weeks',
	Monthly : 'months',
	Yearly  : 'years',
}



// Make an array of datetimes starting from a given datetime
// Count includes the initial datetime
export const numDatetimesBefore = (
	datetimes: DateTime[] | ToObjectOutput[],
	basis: DateTime,
) =>
{
	return datetimes.filter(dt =>
		dt < basis
	).length
}









// Make an array of datetimes starting from a given datetime
// Count includes the initial datetime
export const seriesFromDatetime = (
	datetime: DateTime,
	count: number,
	step_duration: DurationLikeObject
) =>
{
	return arrayOf(
		count,
		(i: number) =>
		{
			return (
				datetime.plus(
					multiplyDuration(step_duration, i)
				)
			)
		}
	)
}



// Make an array of datetimes starting from an array of datetimes
export const seriesFromDatetimes = (
	datetimes: DateTime[] | ToObjectOutput[],
	count: number,
	step_duration: DurationLikeObject,
	
	// Use to give the index a starting value
	// Use if some items in array are before basis and should only be included on subsequent loops
	skip?: number,
	prevent_duplicates = true,
) =>
{
	let results : DateTime[] = [];
	
	// An index to step through the input datetimes repeatedly
	let i = skip || 0;
	
	// How many times have we looped around the input
	let loops = 0;
	
	// The duration offset from the original set of datetimes
	let offset : DurationLikeObject = {};
	
	
	let iterations = 0;
	
	
	
	// Keep adding datetimes until we have the number we want
	while((results.length < count && iterations < 30))
	{
		// All datetimes are offset the same amount, which increases by the step duration each loop
		if(i >= datetimes.length)
		{
			loops++;
			offset = multiplyDuration(step_duration, loops).toObject();
			i = 0;
		}
		
		// let original_dt = datetimes[i];
		
		// if(original_dt instanceof DateTime)
		// {
		// 	original_dt = (original_dt as DateTime).toObject();
		// }
		
		let dt = fixInvalidDateTime(
			combineDateTimeAndDuration(datetimes[i], offset),
		);
		
		if(!dt)
		{
			break;
		}
		
		// console.log(' - ' + dt.toISO(), {
		// 	dt,
		// })
		
		if(prevent_duplicates)
		{
			if(!results.find(x =>
				x.toISO() === dt.toISO()
			))
			{
				results.push(dt);
			}
		}
		else
		{
			results.push(dt);
		}
		
		i++;
		iterations++;
	}
	
	// console.log({
	// 	datetimes,
	// 	results,
	// 	count,
	// 	i,
	// 	loops,
	// 	offset,
	// })
	
	
	return results;
}




export const groupedOccurrences =
(
	recurrence: Recurrence,
	count: number = 8,
	basis: DateTime = DateTime.local(),
)
: Dictionary< Occurrence[] >=>
{
	let occurrences = projectedOccurrences(recurrence, count, basis);
	
	// TODO: Why is this sometimes invalid?
	occurrences = occurrences.filter(x => x.generation_datetime.isValid);
	
	let grouped_occurrences = groupBy(occurrences, occurrence => occurrence.generation_datetime);
	
	return grouped_occurrences;
}



export const projectedOccurrences =
(
	recurrence: Recurrence,
	count: number = 8,
	basis: DateTime = DateTime.local(),
)
: Occurrence[] =>
{
	let dates : DateTime[]= [];
	
	let now = DateTime.local();
	
	let schedule = recurrence.recurrence_schedule;
	
	
	let start : DateTime | null = ensureLocalDateTime(recurrence.effective_datetime);
	let end   : DateTime | null = ensureLocalDateTime(recurrence.effective_until_datetime);
	
	let start_ms = start.toMillis();
	let end_ms   = end.toMillis();
	
	if(recurrence.effective_type !== RecurrenceEffectiveType.EFFECTIVE_BETWEEN)
	{
		if(recurrence.recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
		{
			end = null;
		}
	}
	
	
	if(recurrence.effective_type === RecurrenceEffectiveType.START_IMMEDIATELY)
	{
		if(basis > now)
		{
			basis = now;
		}
	}
	
	
	// TODO: Is this necessary?
	if(recurrence.time_of_day)
	{
		basis = basis.set(recurrence.time_of_day);
	}
	
	
	
	
	
	let should_skip_first = (recurrence.recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
		&& (recurrence.recurrence_type === RecurrenceType.INTERVAL)
		&& recurrence.skip_first
	
	
	
	// Allows us to check if a datetime is within the effective window
	const withinEffectiveWindow = ( dt: DateTime ) : boolean =>
	{
		if(start)
		{
			if(dt.toMillis() < start_ms)
			{
				// console.log(dt.toISO() + ' is before ' + start.toISO())
				
				return false;
			}
		}
		
		if(end)
		{
			if(dt.toMillis() > end_ms)
			{
				// console.log(dt.toISO() + ' is after ' + end.toISO())
				
				return false;
			}
		}
		
		return true;
	}
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	if(schedule === RecurrenceScheduleType.ON_DEMAND)
	{
		if(recurrence.on_demand_type === OnDemandRecurrenceType.SCHEDULE)
		{
			let specific_dates = recurrence.specific_dates || [];
			
			if(specific_dates.length > 0)
			{
				dates = specific_dates.map(x => ensureLocalDateTime(x.datetime))
			}
		}
	}
	
	
	
	
	
	
	
	
	// Ex: 'every week', 'every 2 years'
	else if((recurrence.recurrence_type === RecurrenceType.INTERVAL) && recurrence.interval)
	{
		let interval = recurrence.interval;
		
		// Only project occurrences if the interval has a non-zero key-value pair
		if(Object.values(interval).find(x => !!x))
		{
			// If we're skipping the first, we'll generate 1 extra datetime to ensure we fulfill the count at the end
			dates = dates.concat(
				seriesFromDatetime(
					basis,
					count + (should_skip_first ? 1 : 0),
					zeroAllBut(
						interval,
						scheduleToUnits[recurrence?.recurrence_schedule || RecurrenceScheduleType.DAILY]
					) as DurationLikeObject,
				)
			)
		}
	}
	
	
	
	
	
	
	else if((recurrence.recurrence_type === RecurrenceType.SCHEDULE))
	{
		if(schedule === RecurrenceScheduleType.DAILY)
		{
			dates = dates.concat(
				seriesFromDatetime(
					basis,
					count,
					{ days: 1 },
				)
			)
		}
		else if((schedule === RecurrenceScheduleType.WEEKLY) && recurrence.weekly)
		{
			let weekdays = recurrence.weekly.weekday_numbers.filter(Boolean);
			
			if(weekdays.length > 0)
			{
				// Find the relevant weekdays during the same calendar week as the basis
				let datetimes_of_basis_week = weekdays.map(weekday =>
					basis.set({
						weekday: weekday,
					})
				)
				
				// Check how many are before basis - we'll skip these during first generation loop
				dates = dates.concat(
					seriesFromDatetimes(
						datetimes_of_basis_week,
						count,
						{ weeks: 1 },
						numDatetimesBefore(datetimes_of_basis_week, basis),
					)
				)
			}
		}
		else if((schedule === RecurrenceScheduleType.MONTHLY) && recurrence.monthly)
		{
			let day_numbers = recurrence?.monthly?.day_numbers?.filter(Boolean) || [];
			
			if(day_numbers.length > 0)
			{
				// Find the relevant dates during the same calendar month as the basis
				let datetimes_of_basis_month = day_numbers.map(day_number =>
					basis.set({
						day: day_number,
					})
				)
				
				// Check how many are before basis - we'll skip these during first generation loop
				dates = dates.concat(
					seriesFromDatetimes(
						datetimes_of_basis_month,
						count,
						{ months: 1 },
						numDatetimesBefore(datetimes_of_basis_month, basis),
					)
				)
			}
		}
		else if((schedule === RecurrenceScheduleType.YEARLY) && recurrence.yearly)
		{
			let yearly_dates = recurrence?.yearly?.yearly_dates?.filter(Boolean) || [];
			
			if(yearly_dates.length > 0)
			{
				// Find the relevant dates during the same calendar month as the basis
				let datetimes_of_basis_year = yearly_dates.map(yearly_date =>
				// 	basis.set({
				// 		month: yearly_date.month,
				// 		day:   yearly_date.day,
				// 	})
				// )
				{
					// This should get around the automatic adjustments Luxon does for dates like Feb 29
					let z = basis.toObject();
					
					// z.month = yearly_date.month;
					// z.day   = yearly_date.day;
					
					return z;
				})
				
				// Check how many are before basis - we'll skip these during first generation loop
				dates = dates.concat(
					seriesFromDatetimes(
						datetimes_of_basis_year,
						count,
						{ years: 1 },
						numDatetimesBefore(datetimes_of_basis_year, basis),
					)
				)
			}
		}
		
	}
	
	
	
	
	
	// TODO: What if it's already been skipped?
	if(should_skip_first)
	{
		dates = dates.slice(1);
	}
	
	
	let valid_datetimes = dates.filter(x => withinEffectiveWindow(x));
	
	
	return valid_datetimes.map(dt => {
		
		let generation_dt = dt.minus(recurrence.lead_time);
		
		if(generation_dt < now)
		{
			generation_dt = now;
		}
		
		return ({
			generation_datetime: generation_dt,
			scheduled_for: dt,
		})
	})
	
}



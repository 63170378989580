import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { getTreeNodeFontSize } from './tree_utils';


const getOpacityByCodeLength = (n: number) =>
{
	let min = .65;
	let max = .85;
	
	if (n >= 7)
	{
		return min;
	}
	else if (n === 1)
	{
		return max;
	}
	else
	{
		return min + (max - min) * (n - 1) / (7 - 1);
	}
}



const CorCode =
({
	code,
	sx,
	depth,
} : {
	code: string,
	sx?: any,
	depth?: number,
}) =>
{
	let display : ReactNode = code;
	
	// let display: ReactNode = code;
	
	
	let remains = (code.length > 3) ? code.slice(3).match(/.{1,2}/g) : [];
	
	display =
		<Typography
			component='span'
			sx={{
				opacity: getOpacityByCodeLength(code.length),
				padding: '1px 4px',
				bgcolor: '#fffd',
				borderRadius: (code.length < 4) ? '6px' : (code.length < 7) ? '5px' : '4px',
				fontWeight: (code.length < 4) ? 700 : (code.length < 7) ? 500 : 400,
				fontSize: '12px',
				marginRight: '10px',
				// fontFamily: 'Courier New, monospace',
				...sx,
			}}
		>
			<Typography
				component='span'
				sx={{
					fontWeight: 'bold',
					fontSize: '12px',
				}}
			>
				{code.slice(0, 3)}
			</Typography>
			{(remains.length > 0) && '.'}
			{remains.join('.')}
		</Typography>
	
	
	return (
		<Stack
		>
			{display}
		</Stack>
	)
}


export default CorCode;
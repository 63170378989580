import { useNavigate } from 'react-router';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { Chip, Divider } from '@mui/material';
import { WorkOrderNew, WorkOrderStatus } from 'models';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';
import { DateTime } from 'luxon';
import { relativeDatetimeText, relativeDaysDiff } from 'utils';



const WorkOrderCard =
({
	work_order,
} : {
	work_order: WorkOrderNew,
})  =>
{
	const navigate = useNavigate();
	
	const { status, work_order_num, title, _id, component_ids, scheduled_for } = work_order;
	
	
	const days_diff = relativeDaysDiff(DateTime.fromISO(scheduled_for));
	
	const is_assigned = ((work_order?.collaborator_ids?.length > 0
		|| work_order?.assigned_to_role_ids?.length > 0
	)) ? true : false;
	
	return (
		<>
			<Stack
				direction='column'
				justifyContent='space-between'
				alignItems='flex-start'
				className='related_issue_card'
				onClick={() => navigate(`/work-orders/${_id}`)}
			>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='space-between'
					width='100%'
				>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='flex-start'
						width='100%'
						spacing={1}
					>
						<Typography
							variant='subtitle1'
							sx={{
								background: defaultAppTheme.palette.primary.main,
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							WO #{work_order_num}
						</Typography>
						
						<Typography
							variant='h4'
							sx={{
								marginLeft: '5px',
							}}
						>
							{title}
						</Typography>
					</Stack>
					
					
					<Grid
						container
						width='100%'
						sx={{
							marginTop: '5px',
						}}
					>
						<RelatedAssetAndComponents
							component_ids={component_ids}
							division_id={work_order.division_id}
						/>
					</Grid>
				</Stack>
				
				<Stack
					width='100%'
					direction='row'
					alignItems='center'
					justifyContent='space-between'
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='center'
						sx={{
							padding: '16px',
						}}
					>
						<CorIcon
							of={
								(status === WorkOrderStatus.COMPLETED)
									? 'closed_work_order'
									: 'open_work_order'
							}
							size='24px'
							color={
								(status === WorkOrderStatus.DUE)
									? defaultAppTheme.palette.secondary.main
									: (status === WorkOrderStatus.IN_PROGRESS)
										? defaultAppTheme.palette.primary.main
										: defaultAppTheme.palette.success.dark
								}
						/>
						
						<Typography
							variant='body1'
							color={
								(status === WorkOrderStatus.DUE)
									? defaultAppTheme.palette.secondary.main
									: (status === WorkOrderStatus.IN_PROGRESS)
										? defaultAppTheme.palette.primary.main
										: defaultAppTheme.palette.success.dark
								}
						>
							{ status?.toUpperCase() }
						</Typography>
					</Stack>
					
					<Stack>
						<Typography
							sx={{
								background: ((days_diff < 0) && (work_order.status !== WorkOrderStatus.COMPLETED))
									? defaultAppTheme.palette.secondary.main
									: (days_diff > 0 && days_diff <= 7 && work_order.status !== WorkOrderStatus.COMPLETED)
									? defaultAppTheme.palette.warning.main
									: (days_diff > 7 && days_diff < 30 && work_order.status !== WorkOrderStatus.COMPLETED)
									? defaultAppTheme.palette.primary.main
									: defaultAppTheme.palette.grey['500'],
								padding: '4px',
								borderRadius: '4px',
								color: '#fff',
							}}
						>
							{ (work_order?.scheduled_for) && relativeDatetimeText(DateTime.fromISO(scheduled_for)) }
						</Typography>
						
						<ConciseDateAndTime datetime={DateTime.fromISO(scheduled_for)} />
					</Stack>
				</Stack>
				
				{
					(is_assigned) ? (
						<Chip color='success' label='assigned'/>
					) : (
						<Chip label='unassigned' />
					)
				}
				
			</Stack>
			
			<Divider sx={{ width: '100%' }} />
		</>
	)
};


export default WorkOrderCard;
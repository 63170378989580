import { Controller } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { ActionProps } from '../../types';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';


interface DateProps extends ActionProps {}


export const Date =
({
	control,
	sectionIndex: parentFieldIndex,
	fieldIndex,
}: DateProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Controller
				control={control}
				name={
					`sections.${parentFieldIndex}.fields.${fieldIndex}.date.description` as const
				}
				defaultValue=''
				render={({ field: { value, onChange } }) => {
					return (
						<TextField
							value={value}
							onChange={onChange}
							label='Work-step Details'
							variant={'filled'}
						/>
					);
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					gap: '1rem',
				}}
			>
				<Controller
					control={control}
					name={`sections.${parentFieldIndex}.fields.${fieldIndex}.date.time`}
					defaultValue={null}
					render={({ field: { value, onChange } }) => {
						return (
							<TimePicker
								label={'Time'}
								value={value}
								onChange={(dateTime) => onChange(dateTime)}
								renderInput={(params) => (
									<TextField {...params} />
								)}
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name={`sections.${parentFieldIndex}.fields.${fieldIndex}.date.date`}
					defaultValue={null}
					render={({ field: { value, onChange } }) => {
						return (
							<DatePicker
								label={'Date'}
								toolbarPlaceholder='Date'
								value={value}
								onChange={(dateTime) => onChange(dateTime)}
								renderInput={(params) => (
									<TextField {...params} />
								)}
							/>
						);
					}}
				/>
			</Box>
		</Box>
	);
};


export default Date;
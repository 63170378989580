
import { Button, Grid, Stack, Typography } from '@mui/material';

import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import { useCertification } from './CertificationDataProvider';
import { CertificationFor } from 'models';
import SelectCertificationForOptions from './certificationRecords/SelectCertificationForOptions';
import EnumToggleFormlet from 'app/gizmos/fields/toggles/EnumToggleFormlet';
import SelectAndTextFormlet from 'app/gizmos/fields/select/SelectAndTextFormlet';



const CreateEditCertification =
({
	closeDialog,
	setCloseDialog,
} : {
	closeDialog?: boolean,
	setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) =>
{
	const {all_certifications, handleSubmit } = useCertification();
	
	const form_options = [
		'Yes',
		'No'
	]
	
	const lead_time_in_days = [30, 60, 90];
	
	
	const available_names = all_certifications?.map(x => x.name);
	const available_categories = all_certifications?.map(x => x.category);
	
	
	return (
		<Stack
			direction='column'
			alignItems='flex-start'
			justifyContent='flex-start'
			width='100%'
			spacing={1}
		>
			<Typography
				variant='h1'
			>
				Create a New Certificate
			</Typography>
			
			<Grid container width='100%'>
				<Grid
					item
					xs={12}
					sm={12}
					md={5}
					lg={5}
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						spacing={2}
						width='100%'
					>
						<EnumToggleFormlet
							label='Create certificate for'
							help='Where does this certificate apply?'
							name='certification_for'
							enum_type={CertificationFor}
						/>
						
						<SelectCertificationForOptions />
						
						<SelectAndTextFormlet
							name={'name'}
							label={'Certification Name'}
							help={'Select or Input New Certification Name'}
							options={available_names}
							getOptionLabel={(option) => option}
							multiple={false}
							freeSolo={true}
						/>
						
						
					</Stack>
				</Grid>
				
				<Grid
					item
					xs={12}
					sm={12}
					md={5}
					lg={5}
				>
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						spacing={2}
						width='100%'
					>
						<SelectAndTextFormlet
							name={'category'}
							label={'Certification Category'}
							help={'Select which Category it belongs to'}
							options={available_categories}
							getOptionLabel={(option) => option}
							freeSolo={true}
							multiple={false}
						/>
						
						<SelectAndTextFormlet
							name={'lead_time_in_days'}
							label={'Lead Time in Days'}
							help={'Lead time in Days'}
							options={lead_time_in_days}
							getOptionLabel={(option) => option || ''}
							multiple={false}
							freeSolo={true}
						/>
						
						<SearchableSelectFormlet
							name={'is_required_to_operate'}
							label={'Is Required To Operate'}
							help={'Is this certification needed to sail?'}
							options={form_options}
							getOptionLabel={(option) => option || ''}
							multiple={false}
							
						/>
						
						<SearchableSelectFormlet
							name={'is_physical_copy_required'}
							label={'Physical copy required?'}
							help={'Does a paper copy need to be onboard'}
							options={form_options}
							getOptionLabel={(option) => option || ''}
							multiple={false}
							
						/>
					</Stack>
				</Grid>
			</Grid>
			
			
			<Stack
				direction='row'
				width='100%'
				justifyContent='center'
				alignItems='center'
			>
				<Button
					variant='contained'
					onClick={handleSubmit}
					type='submit'
					sx={{
						marginTop: '10px'
					}}
				>
					Create
				</Button>
			</Stack>
			
		</Stack>
	)
}


export default CreateEditCertification;
import { Info, WeekdayNumbers } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { FieldOrientation } from 'models/fields';
import CheckboxList from 'app/widgets/fields/CheckboxList';



// Takes selected weekday numbers and generates 7 FieldOption objects for checkboxes
// Ex: [2] => [{_id: '1', label: 'Mon', checked: false}, {_id: '2', label: 'Tues', checked: true}, ...]
export const makeDaysOfTheWeekCheckboxOptions = ( selected_weekday_nums: WeekdayNumbers[] ) =>
{
	let weekdays = Info.weekdays('short');
	
	let options = weekdays.map((weekday, i) => ({
		_id: (i + 1).toString(),
		label: weekday,
		checked: selected_weekday_nums.includes((i + 1) as WeekdayNumbers),
	}))
	
	return options;
}



interface DaysOfTheWeekFieldProps
{
	name?: string,
	selected_weekday_nums?: WeekdayNumbers[],
}



export const DaysOfTheWeekField =
({
	name = 'selected_weekday_nums',
}
: DaysOfTheWeekFieldProps) =>
{
	const form_context = useFormContext();
	
	const control = form_context.control;
	
	const value = form_context.watch(name);
	
	
	const handleChange = ( selected_ids: string[] ) =>
	{
		let new_value = selected_ids
			.map((x: string) => Number(x))
			.filter(Boolean)
			.sort()
		
		// console.log(
		// 	selected_ids,
		// 	selected_ids.map(x => Number(x)),
		// 	new_value,
		// );
		
		// console.log('IS THIS THE CHECKBOX FIELD', {new_value});
		
		form_context.setValue(
			name,
			new_value,
		);
	}
	
	
	return (
		<CheckboxList
			control={control}
			field={{
				name: name,
				orientation: FieldOrientation.ROW,
				options: makeDaysOfTheWeekCheckboxOptions(value),
			}}
			handleChange={handleChange}
		/>
	);
};


export default DaysOfTheWeekField;
import { useState } from 'react';

import { IssueLog } from '../../interfaces/content_data_interfaces';

import NestedItem from '../nested_sidebar/NestedItem';
import SearchOrClearIcon from './edit_collaborators/search_user_field/SearchOrClearIcon';


export interface SearchLogProps
{
	_isssueLog: IssueLog[];
	_filteredLog: (value: React.SetStateAction<IssueLog[]>) => void
}


const SearchLog =
({
	_isssueLog,
	_filteredLog,
}
: SearchLogProps) =>
{
	const [ wordEntered, setWordEntered ] = useState< string >('');
	
	
	// listen to the input and filter the users suggestions
	// void: updated setFilteredData
	const handleFilter = (event_: React.ChangeEvent<HTMLInputElement> | undefined) =>
	{
		if(event_ !== undefined)
		{
			const searchWord = event_.target.value;
			
			setWordEntered(searchWord);
			
			const issuetitle = _isssueLog.slice(0, 1);
			const issueContents = _isssueLog.slice(1, _isssueLog.length);
			
			
			if (searchWord === '')
			{
				_filteredLog([...issuetitle]);
			} 
			else
			{
				const matches = issueContents.filter(( value: IssueLog ) =>
				{
					if(value.body.comment !== undefined)
					{
						const comments = value.body.comment.map((sentence) => sentence.toLowerCase());
						const paragraph =  comments.join(' ');
						
						return paragraph.includes(searchWord.toLowerCase())
					}
					else
					{
						return false;
					}
				});
				
				_filteredLog([ ...issuetitle, ...matches ]);
			}
		}
	};
	
	
	
	const clearInput = () =>
	{
		setWordEntered('');
		_filteredLog([..._isssueLog.slice(0, 1)]);
	};
	
	
	return (
		<>
			<NestedItem
				id_title='SearchLog'
				title='Search Log'
				icon_name='search'
				color='ocean'
			/>
			
			<div
				className='search_log'
			>
				<div
					className='search'
				>
					
					<div
						className='search__inputs'
					>
						
						<input
							type='text'
							placeholder='Type to search log'
							value={ wordEntered }
							onChange={ handleFilter }
						/>
						
						<SearchOrClearIcon
							clearInput={ clearInput }
							word_entered={ wordEntered }
						/>
						
					</div>
				</div>
			</div> 
		</>
	)
}


export default SearchLog;
import { FormHelperText } from '@mui/material';
import { ReactNode } from 'react';


export interface FieldHelpProps
{
	children?: ReactNode,
}


export const FieldHelp =
({
	children,
	...rest
}
: FieldHelpProps) =>
{
	if(!children)
	{
		return null;
	}
	
	
	return (
		<FormHelperText
			sx={{
				padding: '6px 10px',
				margin: 0,
				fontStyle: 'italic',
				fontSize: '13px',
				// opacity: .3,
			}}
			{...rest}
		>
			{children}
		</FormHelperText>
	);
}


export default FieldHelp;
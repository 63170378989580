import CircularProgress from '@mui/material/CircularProgress';

import { useCollection } from 'ember';
import { Asset } from 'models';

import SearchableAssetList from './SearchableAssetList';
import { Box } from '@mui/material';
import { useAuthData } from 'stuff';
import { useCor } from 'app/providers/CorProvider';


const AssetsNavigationPage =
({
	setSelectedAsset,
} : {
	setSelectedAsset?: Function,
}) =>
{
	const { claims } = useAuthData();
	
	const { setSelectedAssetId } = useCor();
	
	
	const assets_collection = useCollection(`organizations/${claims?.organization_id}/assets`);
	
	if(assets_collection.is_loading)
	{
		return <CircularProgress />
	}
	
	const assets = assets_collection.data.reverse() as Asset[];
	
	
	console.log(assets);
	
	
	return (
		<div
			className='assets_page_container'
		>
			<Box
				sx={{
					margin: '10px',
					background: '#fff',
					borderRadius: '5px',
					marginBottom: '20px'
				}}
			>
				<SearchableAssetList
					assets={assets}
					setSelectedAsset={(asset) => {
						setSelectedAsset(asset);
						setSelectedAssetId(asset._id);
					}}
				/>
			</Box>
		</div>
	)
}


export default AssetsNavigationPage;
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { User } from 'types';

import UserAvatar from 'components/avatars/UserAvatar';
import CollaboratorsAccordion from 'app/dashboard/pages/issueCollaboration/issueDetails/collaborators/CollaboratorsAccordion';


import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import SearchableCollaboratorsList from 'components/selectCollaborators/desktop/SearchableCollaboratorsList';
import { useEffect, useState } from 'react';
import { useUpdateDocProperty } from 'ember';
import { useAuthData } from 'stuff';
import { Chip, Grid } from '@mui/material';
import { useRequisition } from '../../RequisitionDataProvider';
import { Role } from 'models/users/user_interfaces';

// import Avatar from '@mui/material/Avatar';
// import AvatarOrError from 'components/images/AvatarOrError';
// import { text2hex } from 'utils/color_utils';


const RequisitionCollaboratorsList =
({
	main_collaborator_title,
} : {
	main_collaborator_title: string,
}) =>
{
	const { requisition, users, roles } =  useRequisition();
	const { claims } = useAuthData();
	
	// console.log('YOOOOOO', assigned_to_roles);
	
	
	const updateDocProperty = useUpdateDocProperty();
	
	const collaborators: User[] = users.filter((user: User) => requisition?.collaborator_ids?.includes(user._id));
	const assigned_to_roles: Role[] = roles.filter((role: Role) => requisition?.role_ids?.includes(role._id));
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	const [ requistion_collaborators, setRequisitionCollaborators ] = useState<User[]>(collaborators);
	const [ closed_by_user, setClosedByUser ] = useState<User>();
	
	
	useEffect(() =>
	{
		if(requisition?.completed_by_user)
		{
			const completed_by_user = users.filter(x => x._id === completed_by_user._id);
			
			setClosedByUser(completed_by_user);
		}
	},
	[requisition.completed_by_user]);
	
	
	
	
	
	const updateCollaborators = (user: User) =>
	{
		const collaborator_ids = requistion_collaborators.map(x => x._id);
		
		if(collaborator_ids?.includes(user._id))
		{
			const filtered_work_order_collaborators = requistion_collaborators.filter(x => x._id !== user._id);
			
			setRequisitionCollaborators(filtered_work_order_collaborators);
		}
		else
		{
			setRequisitionCollaborators([
				user,
				...requistion_collaborators
			])
		}
	};
	
	
	
	const confirmCollaboratorsUpdate = () =>
	{
		const new_collaborator_ids = requistion_collaborators.map(y => y._id);
		
		updateDocProperty(
			`organizations/${claims?.organization_id}/requisitions/${requisition._id}`,
			'collaborator_ids',
			new_collaborator_ids
		);
		
		setCloseDialog(!closeDialog);
	}
	
	
	
	return (
		<Stack
			direction='column'
			justifyContent='center'
			alignItems='flex-start'
			width='100%'
			className='collaborators_list_container'
			spacing={2}
		>
						<Stack
				width='100%'
			>
				<Typography
					variant='subtitle1'
				>
					Assigned to roles
				</Typography>
				
				<Grid container spacing={1}>
					{
						assigned_to_roles.map(role => {
							return(
								<Grid
									key={role._id}
									item
								>
									<Chip label={role.name}/>
								</Grid>
							)
						})
					}
				</Grid>
			</Stack>
			
			
			<Typography
				variant='subtitle1'
			>
				Collaborators
			</Typography>
			
			<Stack
				direction='row'
				alignItems='flex-end'
				justifyContent='space-between'
				width='100%'
			>
				{
					(requisition?.completed_by_user) &&
						<Stack
							direction='row'
							justifyContent='center'
							alignItems='center'
							sx={{
								margin:'10px',
							}}
						>
							<UserAvatar user={closed_by_user} />
							
							<Stack
								direction='column'
								justifyContent='center'
								alignItems='flex-start'
								sx={{
									marginLeft: '5px',
								}}
							>
								<Typography
									variant='body1'
								>
									{ main_collaborator_title }
								</Typography>
								<Typography
									variant='caption'
								>
									{ closed_by_user?.display_name }
								</Typography>
							</Stack>
						</Stack>
				}
				
				<ButtonToOpenDialog
					button_variant='text'
					dialog_title='Add or remove collaborators'
					button_content='Edit'
					dialog_content={
						<SearchableCollaboratorsList
							selectedCollaborators={requistion_collaborators}
							handleSelectedCollaborators={updateCollaborators}
						/>
					}
					sx={{
						textTransform:'none',
						textDecoration: 'underline',
					}}
					closeDialog={closeDialog}
					dialog_submit_text='Confirm'
					handleSubmit={confirmCollaboratorsUpdate}
				/>
			</Stack>
			
			<CollaboratorsAccordion
				users={collaborators}
			/>
		</Stack>
	)
};


export default RequisitionCollaboratorsList;
import React, { useState } from 'react';
import SearchOrClearIcon from './SearchOrClearIcon';
import SearchUsersSuggestions from './SearchUsersSuggestions';

import { ChatUser } from '../../../../interfaces/content_data_interfaces';


export interface SearchUsersInputProps
{
	_placeholder: string;
	_users: ChatUser[];
	_whoHasSelected: (newCollab_: ChatUser) => void;
}


const SearchUsersInput =
({
	_placeholder,
	_users,
	_whoHasSelected,
}
: SearchUsersInputProps) =>
{
	const [ filteredData, setFilteredData ] = useState< Array<ChatUser> >([]);
	const [ wordEntered, setWordEntered ] = useState< string >('');
	const [ cursorNav, setCursorNav ] = useState< number >(-1);
	
	
	// listen to the key pressed ( arrow down up ) to select user
	// void: updated setCursorNav
	const handleKeyDown = ( event_: React.KeyboardEvent<HTMLInputElement> | undefined ) => 
	{
		if( event_ !== undefined )
		{
			if ( event_.keyCode === 38 && cursorNav > 0 )
			{
				setCursorNav( cursorNav - 1 );
			}
			else if ( event_.keyCode === 40 && cursorNav < filteredData.length - 1 )
			{
				setCursorNav( cursorNav + 1 );
			}
			else if ( event_.keyCode === 13 )
			{
				_whoHasSelected(filteredData[cursorNav]);
				clearInput();  
			};
		}
	};
	
	
	// listen to the input and filter the users suggestions
	// void: updated setFilteredData
	const handleFilter = (event_: React.ChangeEvent<HTMLInputElement> | undefined) => 
	{
		if(  event_ !== undefined)
		{
			const search_term = event_.target.value;
			
			setWordEntered(search_term);
			
			let lowercase_search_term = search_term.toLowerCase();
			
			const newFilter = _users.filter(( value: ChatUser ) =>
				value.username.toLowerCase().includes(lowercase_search_term)
			);
		
			if (search_term === '') 
			{
				setFilteredData([]);
			} 
			else 
			{
				setFilteredData(newFilter);
			}
		}
	};
	
	
	const clearInput = () =>
	{
		setFilteredData([]);
		setWordEntered('');
		setCursorNav( -1 );
	};
	
	
	return (
		<div
			className='search'
		>
			
			<div
				className='search__inputs'
			>
				
				<input
					type='text'
					placeholder={ _placeholder }
					value={ wordEntered }
					onChange={ handleFilter }
					onKeyDown={ handleKeyDown }
				/>
				
				<SearchOrClearIcon
					clearInput={ clearInput }
					word_entered={ wordEntered }
				/>
			
			</div>
			
			<SearchUsersSuggestions
				_cursorNav={ cursorNav }
				_filteredData={ filteredData }
				_whoHasSelected={ _whoHasSelected }
				_clearInput={ clearInput }
			/>
			
		</div>
	)
};


export default SearchUsersInput;

import ActionsDataProvider from '../ActionDataProvider';
import CreateActionForm from './CreateActionForm';



const CreateAction = ({
	action_metadata,
	closeDialog,
	setCloseDialog,
} : {
	action_metadata: Object,
	closeDialog?: boolean
	setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) =>
{
	return (
		<ActionsDataProvider
			action_metadata={action_metadata}
			closeDialog={closeDialog}
			setCloseDialog={setCloseDialog}
		>
			<CreateActionForm />
		</ActionsDataProvider>
			
	)
}


export default CreateAction;
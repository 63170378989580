import { Control } from 'react-hook-form';
import { Box, Divider, Stack } from '@mui/material';
import TitleFormlet from 'app/gizmos/fields/text/TitleFormlet';
import { AttachmentsFormlet } from 'pages/createIssue/createIssueStepper/components/AttachmentsFormlet';
import Collaborators from 'pages/CreateWorkOrder/GeneralDescription/Collaborators/Collaborators';
import { Equipments } from 'pages/CreateWorkOrder/GeneralDescription/Equipments/Equipments';
import LinkIssues from 'pages/CreateWorkOrder/GeneralDescription/Issues/LinkIssues';
import Parts from 'pages/CreateWorkOrder/GeneralDescription/Parts/Parts';
import { Tags } from 'pages/CreateWorkOrder/GeneralDescription/Tags/Tags';
import WOTypeSelect from 'pages/CreateWorkOrder/GeneralDescription/WOTypeSelect/WOTypeSelect';
import useCreateWorkOrderTemplate from 'pages/CreateWorkOrder/useCreateWorkOrderTemplate';
import DescriptionField from 'app/gizmos/fields/text/DescriptionFormlet';


interface WOTDescriptionFormProps
{
	control: Control<any, any>,
}


export const WOTDescriptionForm =
({
	control,
}
: WOTDescriptionFormProps) =>
{
	// const wot_data = useWOTData();
	
	
	// TODO: Clean up
	const {
		collaborators_modal,
		showCollaboratorsModal,
	} = useCreateWorkOrderTemplate();
	
	
	
	return (
		<Stack
			gap={4}
		>
			
			<TitleFormlet />
			
			<DescriptionField />
			
			
			<WOTypeSelect control={control} />
			
			
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '3rem',
				}}
			>
				<Tags control={control} />
			</Box>
			
			
			<Stack
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'
				gap={2}
				width={'100%'}
			>
				<Equipments control={control} />
				
				<Divider />
				
				<Parts control={control} />
			</Stack>
			
			
			<Divider />
			
			
			<LinkIssues control={control} />
			
			<AttachmentsFormlet control={control} />
			
			
			<Collaborators
				control={control}
				isModalOpen={collaborators_modal}
				showModal={showCollaboratorsModal}
			/>
			
		</Stack>
	);
};


export default WOTDescriptionForm;
import { Control, Controller } from 'react-hook-form';
import { Box, InputLabel } from '@mui/material';

import {
	OutsideContainer,
	InsideContainer,
	IconContainer,
} from './styles';

import { getNumberSuffix } from 'utils';


// type Name =
// 	| `${
// 		  | 'daily'
// 		  | 'weekly.interval'
// 		  | 'monthly.interval'
// 		  | 'yearly.interval'}.repeatsEvery`
// 	| `monthly.specificDaysRepeatsOn`;


export interface RepeatsEveryProps
{
	control: Control<any, any>;
	name: any, //Name;
	unitLabel: string;
	isRequired: boolean;
	label?: string;
	max?: number;
	min?: number;
}


export const RepeatsEvery =
({
	control,
	name,
	unitLabel,
	isRequired,
	label,
	max,
	min = 1,
}: RepeatsEveryProps) =>
{
	return (
		<OutsideContainer>
			<InputLabel required={isRequired}>
				{label ?? `Repeats Every`}
			</InputLabel>
			<Controller
				control={control}
				name={name}
				defaultValue={1}
				render={({ field: { value, onChange } }) => {
					return (
						<InsideContainer>
							<MinusIcon
								handleClick={() =>
									value > (min ?? 0) && onChange(value - 1)
								}
							/>
							<Box>
								{
									(name === 'monthly.specific_days')
										? value + getNumberSuffix(value)
										: (value === 1)
											? `${value} ${unitLabel.slice(0, -1)}`
											: `${value} ${unitLabel}`
										}
							</Box>
							<PlusIcon
								handleClick={() => {
									return (typeof max === 'undefined')
										? onChange(value + 1)
										: (value < max)
											? onChange(value + 1)
											: null;
								}}
							/>
						</InsideContainer>
					);
				}}
				rules={{
					required: isRequired,
					max: max ?? undefined,
					min: min ?? 1,
				}}
			/>
		</OutsideContainer>
	);
};


interface IconProps
{
	handleClick: () => void;
}


const PlusIcon = ({ handleClick }: IconProps) =>
{
	return (
		<IconContainer onClick={handleClick}>
			<svg
				width='25'
				height='25'
				viewBox='0 0 25 25'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M11.7676 3.71094H13.2324C13.3626 3.71094 13.4277 3.77604 13.4277 3.90625V21.0938C13.4277 21.224 13.3626 21.2891 13.2324 21.2891H11.7676C11.6374 21.2891 11.5723 21.224 11.5723 21.0938V3.90625C11.5723 3.77604 11.6374 3.71094 11.7676 3.71094Z'
					fill='#666666'
				/>
				<path
					d='M4.29688 11.5723H20.7031C20.8333 11.5723 20.8984 11.6374 20.8984 11.7676V13.2324C20.8984 13.3626 20.8333 13.4277 20.7031 13.4277H4.29688C4.16667 13.4277 4.10156 13.3626 4.10156 13.2324V11.7676C4.10156 11.6374 4.16667 11.5723 4.29688 11.5723Z'
					fill='#666666'
				/>
			</svg>
		</IconContainer>
	);
};


const MinusIcon = ({ handleClick }: IconProps) =>
{
	return (
		<IconContainer onClick={handleClick}>
			<svg
				width='17'
				height='3'
				viewBox='0 0 17 3'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M0.296875 0.572266H16.7031C16.8333 0.572266 16.8984 0.63737 16.8984 0.767578V2.23242C16.8984 2.36263 16.8333 2.42773 16.7031 2.42773H0.296875C0.166667 2.42773 0.101562 2.36263 0.101562 2.23242V0.767578C0.101562 0.63737 0.166667 0.572266 0.296875 0.572266Z'
					fill='#666666'
				/>
			</svg>
		</IconContainer>
	);
};


export default RepeatsEvery;
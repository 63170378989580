



// Parameters of specific sensor readings we want to extract form the raw data
// Selected from the pointlist mapping spreadsheet
export const selected_sensors =
[
    {
        "signal_name": "Fuel Centrifuge #2 - Feed Pump - Running",
        "signal_position": "AM02.BA1.DI-06",
        "signal_type": "BOOL",
        "modbus_address": 8282,
        "modbus_position": 6,
        "item_id": 60
    },
    {
        "signal_name": "GEN1 - Battery voltage",
        "signal_position": "GE1.AI-01",
        "signal_type": "INTEGER",
        "modbus_address": 8482,
        "modbus_position": 1,
        "item_id": 260
    },
    {
        "signal_name": "GEN1 - Motor speed",
        "signal_position": "GE1.AI-14",
        "signal_type": "INTEGER",
        "modbus_address": 8493,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 271
    },
    {
        "signal_name": "GEN2 - Battery voltage",
        "signal_position": "GE2.AI-01",
        "signal_type": "INTEGER",
        "modbus_address": 8498,
        "modbus_position": 1,
        "item_id": 276
    },
    {
        "signal_name": "GEN2 - Motor speed",
        "signal_position": "GE2.AI-14",
        "signal_type": "INTEGER",
        "modbus_address": 8509,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 287
    },
    {
        "signal_name": "MP1 - Diesel - Inlet pressure",
        "signal_position": "MP1.AI-01",
        "signal_type": "INTEGER",
        "modbus_address": 8518,
        "modbus_position": 1,
        "item_id": 296
    },
    {
        "signal_name": "MP1 - Diesel - Temp. entrance",
        "signal_position": "MP1.AI-02",
        "signal_type": "INTEGER",
        "modbus_address": 8519,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 297
    },
    {
        "signal_name": "MP1 - Lubrication oil - Differential pressure",
        "signal_position": "MP1.AI-05",
        "signal_type": "INTEGER",
        "modbus_address": 8522,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 300
    },
    {
        "signal_name": "MP2 - Diesel - Inlet pressure",
        "signal_position": "MP2.AI-01",
        "signal_type": "INTEGER",
        "modbus_address": 8545,
        "modbus_position": 1,
        "item_id": 323
    },
    {
        "signal_name": "MP2 - Diesel - Temp. entrance",
        "signal_position": "MP2.AI-02",
        "signal_type": "INTEGER",
        "modbus_address": 8546,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 324
    },
    {
        "signal_name": "MP2 - Lubricating oil - Differential pressure",
        "signal_position": "MP2.AI-05",
        "signal_type": "INTEGER",
        "modbus_address": 8549,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 327
    },
    {
        "signal_name": "MP3 - Diesel - Inlet pressure",
        "signal_position": "MP3.AI-01",
        "signal_type": "INTEGER",
        "modbus_address": 8572,
        "modbus_position": 1,
        "item_id": 350
    },
    {
        "signal_name": "MP3 - Diesel - Temp. entrance",
        "signal_position": "MP3.AI-02",
        "signal_type": "INTEGER",
        "modbus_address": 8573,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 351
    },
    {
        "signal_name": "MP3 - Lubrication oil - Differential pressure",
        "signal_position": "MP3.AI-05",
        "signal_type": "INTEGER",
        "modbus_address": 8576,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 354
    },
    {
        "signal_name": "MP4 - Diesel - Inlet pressure",
        "signal_position": "MP4.AI-01",
        "signal_type": "INTEGER",
        "modbus_address": 8599,
        "modbus_position": 1,
        "item_id": 377
    },
    {
        "signal_name": "MP4 - Diesel - Temp. entrance",
        "signal_position": "MP4.AI-02",
        "signal_type": "INTEGER",
        "modbus_address": 8600,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 378
    },
    {
        "signal_name": "MP4 - Lubricating oil - Differential pressure",
        "signal_position": "MP4.AI-05",
        "signal_type": "INTEGER",
        "modbus_address": 8603,
        "modbus_position": 1,
        "multiplication_factor": 100,
        "item_id": 381
    },
]



import { LatLng } from 'leaflet'
import { DtnCoordinates } from './route_interfaces'



export const dtnCoords2LatLng = (coords: DtnCoordinates | number[]) : LatLng =>
{
	return new LatLng(
		coords[1],
		coords[0],
	)
}



export const color_palette =
[
	'#296A9F',
	'#299F4A',
	'#D35B50',
	'#7f0000',
	'#ffa500',
	'#4b0082',
	'#ffff00',
	'#2f4f4f',
	'#006400',
	'#bdb76b',
	'#c71585',
	'#00ff00',
	'#00fa9a',
	'#00ffff',
	'#0000ff',
	'#ff00ff',
	'#ffb6c1',
]



import { useWorkOrderTemplates } from 'app/providers/WorkOrderTemplatesLoader';

import MultiSelectField from 'components/forms/MultiSelectField';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { Controller } from 'react-hook-form';
import Formlet, { FormletProps } from '../Formlet';


const WorkOrderTemplatesFormlet =
({
	name = 'work_order_templates',
	label = 'Work Order Templates',
	help = 'When threshold is reached, these work order templates will be triggered',
	...rest
}
: FormletProps) =>
{
	const { work_order_templates } = useWorkOrderTemplates();
	
	
	console.log({
		work_order_templates,
	});
	
	
	const options = work_order_templates?.map(template =>
		({
			id: template._id,
			label: template.title,
		})
	)
	
	
	console.log({
		options,
	});
	
	
	if(!options)
	{
		return <LoadingSpinner />
	}
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<MultiSelectField
						name={name}
						getOptionLabel={(option) => `${option.label}`}
						options={options}
						// onChange={onChange}
						{...rest}
					/>
				}
			/>
		</Formlet>
	)
}


export default WorkOrderTemplatesFormlet;
import { useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useAuthData, useTitle } from 'stuff';
import { useCollection } from 'ember';
import { Issue } from 'models';
import CorIcon from 'components/icons/CorIcon';
import IssuesListResponsiveContent from './IssuesListResponsiveContent';
import { orderBy } from 'firebase/firestore';





const IssuesListPage = () =>
{
	const navigate = useNavigate();
	
	const { claims } = useAuthData();
	
	useTitle('Issues')
	
	
	// Get the Issue data
	const issues_collection_path = `organizations/${claims?.organization_id}/issues`;
	const issue_collection = useCollection(issues_collection_path, orderBy('date_opened', 'desc'));
	
	
	if(issue_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const issues = issue_collection?.data as Issue[];
	
	console.log({issues});
	
	
	
	return (
		<Box
			sx={{
				padding: '20px',
				overflowY: 'auto',
				height: '100%'
			}}
		>
			<Stack
				direction='row'
				justifyContent='space-between'
				alignItems='center'
				sx={{
					marginBottom: '20px',
				}}
			>
				<Typography
					variant='h1'
				>
					Issues List
				</Typography>
				
				<Button
					variant='contained'
					onClick={() => navigate('/create-issue')}
					startIcon={<CorIcon of='issue' size='25px'/>}
				>
					Create Issue
				</Button>
			</Stack>
			
			<IssuesListResponsiveContent issues={issues} />
		</Box>
	)
}

export default IssuesListPage;
import { ImageProps } from 'components/images';
import { Avatar, Tooltip } from '@mui/material';
import AvatarFromStorage from './AvatarFromStorage';
import { text2hex } from 'utils/color_utils';



// Unifies Avatar handling - from storage or direct URL
const UserAvatar =
({
	user,
	alt = '',
	src,
	cuid,
	title = null,
	sx,
	...rest
}
: ImageProps ) =>
{
	title = title || user?.email || user?.display_name || 'user';
	
	// console.log(user)
	
	
	if(user?.image_path)
	{
		return (
			<AvatarFromStorage
				alt={user?.display_name.toUpperCase()}
				title={title}
				path={user.image_path}
				className='chat_avatar'
				{...rest}
			/>
		)
	}
	else
	{
		return (
			<Tooltip
				title={title}
				key={user?._id}
				placement='top-end'
			>
				<Avatar
					alt={user?.display_name.toUpperCase() || alt}
					src={user?._id || src}
					className='chat_avatar'
					sx={{
						bgcolor: ((user) ? text2hex(user?._id || user?.email || '') : text2hex(cuid || '')),
						cursor: 'pointer',
						...sx
					}}
					{...rest}
				/>
			</Tooltip>
		)
	}
}


export default UserAvatar;
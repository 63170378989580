import { Box } from '@mui/material';


export interface FieldDescriptionProps
{
	description: string;
}

export const FieldDescription = ({ description }: FieldDescriptionProps) =>
{
	if (!description) return null;
	
	return (
		<Box
			sx={{
				padding: '1rem',
				backgroundColor: '#efefef',
				borderRadius: '5px',
			}}
		>
			{description}
		</Box>
	);
};


export default FieldDescription;
import { DateTime } from 'stuff';
//import cloneDeep from 'lodash/cloneDeep';

import { resolveEntity } from '.';

import { generateLoadedState } from './store_utils';


const LOAD_THINGYS = true;
const LOG = false;


class State
{
	constructor()
	{
		// Load initial data set from browser's local storage
		const { all, thingys } = LOAD_THINGYS
			? generateLoadedState()
			: {};
		
		
		// Object mapping directly to every Thingy
		this.all = all || {};
		
		// Object of arrays
		this.thingys = thingys || {};
		
		
		// This may need to be updateed when the token refreshes
		//this.user = loadFromLocalStorage('user');
		
		
		this.last_change = DateTime.local();
		this.last_change_str = this.last_change.toISO();
	}
	
	
	
	// Adds the provided Thingy to the state's all map, as well as the appropriate thingy array.
	// If the Thingy already exists, we'll replace it in place. Only call from within reducer.
	write = (thingy) =>
	{
		if(LOG) console.log('    %cWriting ' + thingy.title, 'color: yellow', thingy);
		
		
		// Ensure that we have an array for the specified collection name
		if(!this.thingys[thingy.entity.plural])
		{
			this.thingys[thingy.entity.plural] = [];
		}
		
		let possible_preexisting = this.all[thingy._id];
		
		
		// If the thingy already exists, replace it (maintaining array order)
		if(possible_preexisting)
		{
			this.thingys[thingy.entity.plural] = this.thingys[thingy.entity.plural]
				.map(x => x._id !== thingy._id ? x : thingy);
		}
		else
		{
			this.thingys[thingy.entity.plural].push(thingy);
		}
		
		// Write to our map of all Thingys
		this.all[thingy._id] = thingy;
		
		// Record the current time
		this.last_change2 = DateTime.local().toISO();
		this.ms_since_change = DateTime.local().ts - this.last_change.ts;
		
		this.last_write_id = thingy._id;
		
		// TODO
		//console.log(cloneDeep(this))
	}
	
	
	readOne = (_id) =>
	{
		return this.all[_id];
	}
	
	readAll = (type_or_collection_name) =>
	{
		return this.thingys[resolveEntity(type_or_collection_name).plural] || [];
	}
	
	
}


export default State;
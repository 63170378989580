import { Box, Chip, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import { useCollection, useDoc } from 'ember';
import { useAuthData } from 'stuff';
import { ComponentReading, ComponentRecord } from 'models';
import ComponentRecordCard from 'app/dashboard/pages/components/componentsDrawer/details/componentRecords/ComponentRecordCard';
import { useParams } from 'react-router';
import { where } from 'firebase/firestore';


interface ReadingProps extends ActionProps {}


export const FillableReading = (props: ReadingProps) =>
{
	const { work_order_id } = useParams();
	
	const { claims } = useAuthData();
	const { sections, sectionIndex, fieldIndex } = props;
	
	const component_record_id = sections[sectionIndex].fields[fieldIndex].reading.component_record._id;
	const equipment_ids = sections[sectionIndex].fields[fieldIndex].reading.equipment.map(x => x._id);
	
	
	const org_path = `organizations/${claims?.organization_id}`
	
	const component_record_doc = useDoc(
			`${org_path}/component_records`,
			component_record_id
		);
	
	const component_reading_collection = useCollection(
		`${org_path}/component_readings`,
		where('component_record_id', '==', component_record_id)
	);
	
	
	
	
	if(component_record_doc?.is_loading || component_reading_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const component_record = component_record_doc?.data as ComponentRecord;
	const component_readings = component_reading_collection?.data as ComponentReading[];
	
	
	console.log({component_record});
	
	
	const filtered_component_readings = component_readings?.filter(x => x?.work_order_id === work_order_id) || [];
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={1}
				className='form_field_description'
			>
				<Typography
					variant='subtitle1'
					sx={{
						lineHeight: 0
					}}
				>
					{sectionIndex+1}.{fieldIndex+1}
				</Typography>
				
				<Typography
					variant='subtitle2'
					className='form_field_description'
				>
					{sections[sectionIndex].fields[fieldIndex].reading.description}
				</Typography>
			</Stack>
			
			
			<Stack>
				<RelatedAssetAndComponents component_ids={equipment_ids} />
			</Stack>
			
			
			
			<InfoHighlight
				severity='info'
				message_main={`
					Take ${sections[sectionIndex].fields[fieldIndex].reading.readingType} reading
				`}
				message_context={
					(sections[sectionIndex].fields[fieldIndex].reading.cumulative) && 'Cumulative'
				}
			/>
			
			<ComponentRecordCard component_record={component_record} />
			
			
			<Typography
				variant='h3'
			>
				Recorded Value(s):
			</Typography>
			<Grid
				container
				gap={2}
			>
				{
					(filtered_component_readings?.length > 0) &&
						filtered_component_readings.map(x => (
							<Grid
								key={x._id}
								item
							>
								<Chip
									variant='outlined'
									label={`${x.value} ${x.units}`}
									color='info'
								/>
							</Grid>
						))
				}
			</Grid>
			
		</Box>
	);
};





export default FillableReading;
import { React } from 'stuff';

import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { visuallyHidden } from '@mui/utils';

// import Checkbox from '@mui/material/Checkbox';

import { Data, HeadCell, EnhancedTableProps } from './partsTableInterface';





const headCells: readonly HeadCell[] =
[
	{
		id: 'part_name',
		numeric: false,
		disablePadding: true,
		label: 'Name',
	},
	{
		id: 'quantity',
		numeric: true,
		disablePadding: false,
		label: 'Quantity',
	},
	{
		id: 'part_number',
		numeric: false,
		disablePadding: false,
		label: 'Part#',
	},
	{
		id: 'manufacturer',
		numeric: false,
		disablePadding: false,
		label: 'Manufacturer',
	},
];





const PartsTableHead = (props: EnhancedTableProps) =>
{
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort
	} = props;
		
		const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};
		
		
	return (
		<TableHead className='table_header'>
			<TableRow>
				{/* <TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell> */}
					{
						headCells.map((headCell) => (
							<TableCell
								key={headCell.id}
								align={headCell.numeric ? 'left' : 'left'}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
								className='header_cell'
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
									) : null}
								</TableSortLabel>
							</TableCell>
						))
					}
			</TableRow>
		</TableHead>
	);
};



export default PartsTableHead;
import React, { useEffect, useState } from 'react';
// import { useLongPress } from 'use-long-press';

import Grid from '@mui/material/Grid';

import { ComponentTreeData } from 'models';

import ComponentInfoDrawer from './componentsDrawer/ComponentInfoDrawer';
import ComponentTreePanel from './ComponentTreePanel';
import ComponentInfoPanel from './ComponentInfoPanel';
import { useURLQueryParam } from 'utils/hooks';


const ComponentsPage = () =>
{
	let [ selected_component_id, setSelectedComponentId ] = useURLQueryParam('selected', null);
	
	
	// Component Info drawer handling
	const [ isComponentDrawerOpen, setIsComponentDrawerOpen ] = useState<boolean>(false);
	const [ component, setComponent ] = useState<ComponentTreeData>();
	
	
	const toggleDrawer = (selected_component) =>
	{
		// TODO: What does this mean?
		// console.log('STOP IT', selected_component);
		
		setIsComponentDrawerOpen(prevState => !prevState);
		setComponent(selected_component);
	}
	
	
	
	useEffect(() => {
		
	}, [ selected_component_id ])
	
	// use-long-press library implementation / use-case
	// const onLongPress = useLongPress((e, component) =>
	// {
	// 	e.stopPropagation();
	// 	console.log('HOLD EVENT', e);
		
	// 	let selected_component= component?.context;
	// 	toggleDrawer(selected_component);
	// });
	
	
	console.log({
		selected_component_id,
		component,
	})
	
	
	
	return (
		<Grid
			container
			direction='column'
			width='100%'
			height='100%'
			
			display='grid'
			gridTemplateColumns={{
				xs: '1fr',
				sm: '1fr',
				md: '1fr 400px',
				lg: '1fr 400px',
			}}
			
		>
			
			<Grid
				item
				xs={12} sm={12} md={6} lg={6}
				maxHeight='100%'
				sx={{
					overflowX: 'auto',
				}}
			>
				<ComponentTreePanel
					component={component}
					setComponent={setComponent}
					setIsComponentDrawerOpen={setIsComponentDrawerOpen}
				/>
			</Grid>
			
			{/* <Grid item>
				<Divider orientation='vertical' />
			</Grid> */}
			
			<Grid
				item
				md={6}
				lg={6}
				display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
				sx={{
					width: '400px',
				}}
			>
				<ComponentInfoPanel
					selectedComponent={component}
				/>
			</Grid>
			
			<Grid item display={{ md: 'none', lg: 'none' }}>
				<ComponentInfoDrawer
					isComponentDrawerOpen={isComponentDrawerOpen}
					setIsComponentDrawerOpen={setIsComponentDrawerOpen}
					toggleDrawer={toggleDrawer}
					selectedComponent={component}
				/>
			</Grid>
		</Grid>
	)
};


export default ComponentsPage;
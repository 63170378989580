import {
	useState,
	DateTime, Interval, Info,
	doForObj, arrayOf, groupByProp, cx,
	arrayOfDaysWithin, relativeDateText
} from 'stuff';



// Seven divs containing day names
// Ex: Mon Tue Wed Thu Fri Sat Sun
const DaysOfWeek = () =>
{
	let day_names = Info.weekdays('short');
	
	
	return <div className='days_of_week'>
		{day_names.map(name =>
			<div
				className='day_of_week'
				key={name}
			>
				{name}
			</div>
		)}
	</div>
}



// day is a DateTime or null
const CalendarDay = ({ day, selected_day, setSelectedDay, events_by_date }) =>
{
	console.log(day, events_by_date);
	
	
	if(day)
	{
		let iso = day.toISO().slice(0, 10);
		
		let events_on_day = events_by_date ? events_by_date[iso] : [];
		
		let today = DateTime.local();
		
		
		if(events_on_day?.length) console.log(events_on_day);
		
		
		let pips = <div className='calendar_pips'>
			{events_on_day?.map(event =>
				<div
					style={{background: event.color}}
					className='calendar_pip'
					key={event._id}
				>
					{}
				</div>
			)}
		</div>
		
		
		let is_today    = (today.ordinal === day?.ordinal) && (today.year === day?.year);
		let is_selected = day && (selected_day?.ordinal === day?.ordinal) && (selected_day?.year === day?.year);
		
		
		return <div
			className={cx(
				'calendar2_day',
				{
					is_weekend: day?.weekday > 5,
					is_today: is_today,
					is_selected: is_selected,
				}
			)}
			onClick={() => {
				if(day) setSelectedDay(day)
			}}
		>
			{day?.day}
			{pips}
		</div>
	}
	else
	{
		return <div
			className={cx(
				'calendar2_day',
				'is_null',
				{
					is_weekend: day?.weekday > 5,
				}
			)}
			onClick={() => {
				if(day) setSelectedDay(day)
			}}
		>
			{day?.day}
		</div>
	}
}



// Array of DateTimes
const CalendarWeek = ({ days_in_week, selected_day, setSelectedDay, events_by_date }) =>
{
	//console.log(week_num, days_in_week);
	
	let elems = days_in_week.map(day => <CalendarDay
		day={day}
		key={day.toISO()}
		selected_day={selected_day}
		setSelectedDay={setSelectedDay}
		events_by_date={events_by_date}
	/>);
	
	let is_beginning_of_month = days_in_week[0].day < 15;
	
	while(elems.length < 7)
	{
		if(is_beginning_of_month)
		{
			elems.unshift(<CalendarDay
				day={null}
				key={7 - elems.length}
				events_by_date={events_by_date}
			/>)
		}
		else
		{
			elems.push(<CalendarDay
				day={null}
				key={7 - elems.length}
				events_by_date={events_by_date}
			/>)
		}
	}
	
	return <div className='calendar2_week'>
		{elems}
	</div>
}




// month is an Interval
const CalendarMonth = ({ month, selected_day, setSelectedDay, events_by_date }) =>
{
	// Ex: [ DateTime1, ..., DateTime31 ]
	let days = arrayOfDaysWithin(month);
	
	let first_day = days[0];
	
	
	
	
	let days_by_week_num = groupByProp(days, 'weekNumber');
	
	
	console.log(month);
	console.log(days);
	console.log(first_day);
	
	let elems = [];
	
	
	
	
	doForObj(days_by_week_num,
		(days_in_week, week_num) =>
		{
			elems.push(
				<CalendarWeek
					week_num={week_num}
					days_in_week={days_in_week}
					selected_day={selected_day}
					setSelectedDay={setSelectedDay}
					events_by_date={events_by_date}
				/>
			)
		}
	)
	
	
	
	
	return <div className='calendar2_month'>
		
		<div className='calendar2_month_name'>
			{month.s.monthLong}
		</div>
		
		<DaysOfWeek />
		
		{elems}
		
	</div>
}




const CalendarSpotlightItem = ({ today, day, events_by_date }) =>
{
	let iso = day.toISO().slice(0, 10);
	
	let events_on_day = events_by_date[iso];
	
	
	console.log('Events on', iso, events_on_day);
	
	
	let elems = events_on_day?.map(event => <div
		className='calendar_spotlight_event'
		key={event._id}
	>
		<div style={{
			background: event.color,
			padding: '2px 5px',
			margin: '1px',
			borderRadius: '6px',
			textAlign: 'center',
		}}>
			{event.color}
		</div>
	</div>)
	
	
	return <div className='calendar_spotlight_item'>
		<div className='calendar_spotlight_item_date'>
			<div className='calendar_spotlight_item_date1'>
				{day.toFormat('ccc, LLL dd')}
			</div>
			<div className='calendar_spotlight_item_date2'>
				{relativeDateText(day, today)}
			</div>
		</div>
		<div className='calendar_spotlight_events'>
			{elems}
		</div>
	</div>
}




const CreateEventButton = ({ color = 'green', selected_day }) =>
{
	
	return  <button
		// onClick={() => {dispatchWrite(new Thingy('event', {
		// 	color: color,
		// 	date: selected_day.toISO().slice(0, 10),
		// 	datetime_created: DateTime.local().toISO(),
		// }))}}
		style={{background: color, color: 'white'}}
	>
		Create {color} event
	</button>
}





const CalendarSpotlight = ({ today, selected_day, events_by_date }) =>
{
	let num_days_ahead = 7;
	
	
	let relevant_dates = arrayOf(
		num_days_ahead,
		(i) => selected_day.plus({days: i})
	);
	
	
	let elems = relevant_dates.map(date =>
		<CalendarSpotlightItem
			selected_day={selected_day}
			today={today}
			day={date}
			events_by_date={events_by_date}
		/>
	);
	
	
	return <div className='calendar_spotlight'>
		
		<div className='calendar_spotlight_buttons'>
			
			<CreateEventButton
				color='red'
				selected_day={selected_day}
			/>
			<CreateEventButton
				color='blue'
				selected_day={selected_day}
			/>
			<CreateEventButton
				color='magenta'
				selected_day={selected_day}
			/>
			
		</div>
		
		{elems}
		
	</div>
}



// Ex: events = {'2021-11-21': [event1, event2]}
const Calendar = () =>
{
	let events = [];// state.readAll('events').map(x => x.toThingo(state));
	
	let events_by_date = groupByProp(events, 'date');
	
	console.log(events_by_date);
	
	
	
	let today = DateTime.local();
	
	// TODO: Improve
	const [ selected_day, setSelectedDay ] = useState(today.startOf('day'));
	
	let month_start = today.startOf('month');
	let month_start_plus1 = month_start.plus({month: 1});
	let month_start_plus2 = month_start.plus({month: 2});
	
	let month1 = Interval.fromDateTimes(month_start, month_start_plus1);
	let month2 = Interval.fromDateTimes(month_start_plus1, month_start_plus2);

	console.log("Date has selected!! ", selected_day );
	
	
	return <div className='calendar2'>
		<div className='calendar2_months'>
			<CalendarMonth
				month={month1}
				selected_day={selected_day}
				setSelectedDay={setSelectedDay}
				events_by_date={events_by_date}
			/>
			<CalendarMonth
				month={month2}
				selected_day={selected_day}
				setSelectedDay={setSelectedDay}
				events_by_date={events_by_date}
			/>
		</div>
		<CalendarSpotlight
			selected_day={selected_day}
			today={today}
			events_by_date={events_by_date}
		/>
	</div>
}


export default Calendar;
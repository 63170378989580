import { Box, TextField } from '@mui/material';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';


// TODO: Don't overlap our component names with JavaScript Date
export const Date = () =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem',
			}}
		>
			<TimePicker
				label={'Time'}
				value={null}
				onChange={() => {}}
				disabled={true}
				renderInput={(params) => <TextField {...params} />}
			/>
			<DatePicker
				label={'Date'}
				toolbarPlaceholder="Date"
				value={null}
				onChange={() => {}}
				disabled={true}
				renderInput={(params) => <TextField {...params} />}
			/>
		</Box>
	);
};


export default Date;
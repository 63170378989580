import { Controller, useFormContext } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { SingleCheckboxField } from 'models/fields';
import Formlet from '../Formlet';


// A checkbox field gizmo allowing the user to set the property specified
// by name to true/false
export const CheckboxFormlet =
({
	name,
	label,
	help,
	option_label,
}
: SingleCheckboxField) =>
{
	const form_context = useFormContext();
	
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({
					field: { onChange, onBlur, value, name, ref },
				}) =>
					<Button
						sx={{
							textTransform: 'none',
							padding: 0,
							width: 'fit-content',
							color: '#000a',
						}}
					>
						<FormControlLabel
							key={name}
							label={option_label}
							labelPlacement='end'
							sx={{
								margin: 0,
								paddingRight: 2,
							}}
							control={
								<Checkbox
									onBlur={onBlur}
									onChange={(e) => {
										console.log(name + ' going from ' + value + ' to ' + e.target.checked);
										onChange(e.target.checked)}
									}
									inputRef={ref}
									checked={form_context.watch(name)}
								/>
							}
						/>
					</Button>
				}
			/>
		</Formlet>
	)
}


export default CheckboxFormlet;
import { useRef, useEffect } from 'react';

import { Message, Event } from 'types/misc_interfaces';
import { StorageFile } from 'models';
import { User } from 'types';

import ChatEvent from './ChatEvent';
import ChatMessage from './ChatMessage';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';


interface ChatContentsProps
{
	messages?: Message[],
	events?: Event[],
	files?: StorageFile[],
	users?: User[],
}


const ChatContents =
({
	messages,
	events,
	files,
	users,
}
: ChatContentsProps) =>
{
	// We'll automatically scroll to the bottom when a new message arrives
	const ref_to_end = useRef(null)
	
	// const scrollToBottom = () =>
	// {
	// 	ref_to_end.current.scrollIntoView({ behavior: 'smooth' })
	// }
	
	// useEffect(scrollToBottom, [messages]);
	
	/* whenever the list changes we need to scroll our
	last list item into view */
	useEffect(() => {
	if (ref_to_end.current) {
		ref_to_end.current.scrollIntoView({ behaviour: "smooth" });
	}
	}, [messages, events]);
	
	
	
	console.log({
		messages,
		events,
		
	})
	
	
	// Object mapping dates to arrays of content that occurred at that time
	const date_to_content = {};
	
	
	messages?.forEach(message => {
		let arr = date_to_content[message?.date_sent] || [];
		
		date_to_content[message?.date_sent] = [
			...arr,
			message
		];
	})
	
	events?.forEach(event => {
		let arr = date_to_content[event?.datetime] || [];
		
		date_to_content[event?.datetime] = [
			...arr,
			event
		];
	})
	
	
	
	let merged: any[] =
	[
		...events,
		...messages,
	]
	
	let sorted = merged.sort((a, b) =>
		DateTime.fromISO(a.date_sent || a.datetime).toMillis()
		- DateTime.fromISO(b.date_sent || b.datetime).toMillis()
	)
	
	
	const isEvent = (content: any) : content is Event =>
	{
		return 'datetime' in content;
	}
	
	const isMessage = (content: any) : content is Message =>
	{
		return 'date_sent' in content;
	}
	
	
	
	const content2card = (content : Message | Event) =>
	{
		if(isMessage(content))
		{
			return <ChatMessage
				message={content as Message}
				key={content._id}
				users={users}
			/>
		}
		if(isEvent(content))
		{
			return <ChatEvent
				event={content as Event}
				key={content._id}
				users={users}
			/>
		}
	}
	
	
	let cards = [];
	
	
	sorted.forEach((item =>
		cards.push(
			content2card(
				item
			)
		)
	));
	
	
	
	return (
		<Stack
			className='chat_contents_zone'
		>
			<div
				className='chat_contents'
			>
				{cards}
			</div>
			<div
				ref={ref_to_end}
				style={{
					marginTop: '10px',
					marginBottom: '10px',
				}}
			/>
		</Stack>
	);
}


export default ChatContents;
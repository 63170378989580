import { Stack } from '@mui/material'
import ResponsiveCertificationListContent from './ResponsiveCertificationListContent'
import { Certification } from 'models'





const CertificationList = ({
	certifications,
} : {
	certifications: Certification[],
}) =>
{
	return (
		<Stack
			className='container'
		>
			<ResponsiveCertificationListContent certifications={certifications} />
		</Stack>
	)
}

export default CertificationList
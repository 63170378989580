import { Box, Typography, Stack, Button } from '@mui/material'
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useUpdateDocProperty } from 'ember';
import { ActionStatus, Issue, IssueStatus } from 'models'
import React, { useEffect, useState } from 'react'
import { useAuthData } from 'stuff';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useIssue } from '../IssueProvider';


const AddtionalIssueControls = ({
	issue,
} : {
	issue: Issue,
}) =>
{
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	const [ isActionOpen, setIsActionOpen ] = useState<boolean>(false);
	
	const { actions } = useIssue();
	
	const { claims } = useAuthData();
	
	const updateDocProperty = useUpdateDocProperty();
	
	
	const updateIssueStatus = () =>
	{
		updateDocProperty(
			`organizations/${claims?.organization_id}/issues/${issue._id}`,
			'status',
			IssueStatus.CLOSED
		)
		
		setCloseDialog(!closeDialog);
	};
	
	
	
	useEffect(() =>
	{
		actions.forEach(x => {
			if(x.status !== ActionStatus.RESOLVED)
			{
				setIsActionOpen(true)
			}
		})
	}, [actions])
	
	
	
	
	
	return (
		<Box
			sx={{
				background: '#fff',
				marginTop: '20px',
				padding: '10px',
			}}
		>
			
			<Stack>
				
				<ButtonToOpenDialog
					button_variant='contained'
					dialog_title='Please confirm'
					button_content='Mark Issue as Resolved'
					dialog_submit_text='Confirm'
					dialog_content={
						<Typography
							variant='h4'
						>
							PLEASE NOTE: You are about to close the issue.
						</Typography>
					}
					sx={{
						background: defaultAppTheme.palette.success.dark,
						fontWeight: '700',
						
						'&:hover': {
							background: defaultAppTheme.palette.success.main,
						}
					}}
					handleSubmit={updateIssueStatus}
					closeDialog={closeDialog}
					disabled={isActionOpen ? true : false}
				/>
			</Stack>
			
		</Box>
	)
};


export default AddtionalIssueControls;
import { convertHelmFormToRecurrence } from 'conversion/helm/helm_helpers';
import { HelmForm, HelmFormItem, HelmFormItemGroup } from 'conversion/helm/helm_interfaces';
import { DateTime } from 'luxon';
import {
	WorkOrderStatus,
	WorkOrderTemplate,
	WorkOrderType,
	cleanSections,
	pareTemplate,
	RecurrenceEffectiveType,
	RecurrenceScheduleType,
	// convertFormDataToWorkOrderTemplate
} from 'models';
import { determineNextAutomationDatetime } from 'models/recurrence/recurrence_helpers2';
import { projectedOccurrences } from 'models/recurrence/recurrence_helpers3';
import { excludeUndefinedValues } from 'stuff';





export const transformHelmFormsToTemplates = (helm_forms: HelmForm[], claims) : WorkOrderTemplate[] =>
{
	const now = DateTime.local();
	const now_utc_iso = now.toUTC().toISO();
	
	
	const transformed_data = helm_forms.map((helm_form: HelmForm) =>
	{
		const recurrence = convertHelmFormToRecurrence(helm_form);
		
		
		if(recurrence)
		{
			// TODO: Review
			recurrence.next_automation_datetime = projectedOccurrences(recurrence, 2, now)[0]?.generation_datetime;
			
			// TODO: Review
			if(
				(recurrence.recurrence_schedule !== RecurrenceScheduleType.ON_DEMAND)
				&& (recurrence.effective_type === RecurrenceEffectiveType.START_IMMEDIATELY)
			)
			{
				recurrence.next_automation_datetime = now;
			}
		}
		
		
		
		// if (!recurrence)
		// {
		// 	return undefined;
		// }
		
		
		
		
		
		const form_data: WorkOrderTemplate =
		{
			_id: helm_form.Id,
			title: helm_form.Name,
			description: '',
			status: WorkOrderStatus.ACTIVE,
			
			// TODO: Verify approach - don't like using as unknown
			work_order_type:
				(WorkOrderType[helm_form.Type])
					? WorkOrderType[helm_form.Type]
					: (helm_form.Type as unknown as WorkOrderType),
			
			
			division_id: '',
			work_order_template_num: null,
			
			asset_id: helm_form.VesselId,
			asset_name: helm_form.VesselName,
			
			component_ids: (helm_form?.Component)
				? [helm_form?.Component?.Id]
				: [],
			
			
			assigned_to_role_ids: [],
			
			updated_at: '',
			created_at: now_utc_iso, // ??
			creator: {
				// ??
				_id: 'clavaiyzi000101s6e71bho26',
				name: 'Tahrin Maruf',
				email: 'tahrin.maruf@corsphere.com',
			},
			
			
			
			recurrence,
			
			
			// SCHEDULE
			// recurrence: {
			// 	// recurrence_schedule: RecurrenceScheduleType.MONTHLY,
				
			// 	// weekly: {
			// 	// 	weekday_numbers: [],
			// 	// },
				
			// 	// monthly: {
			// 	// 	day_number: 1,
			// 	// 	day_numbers: [],
			// 	// },
				
			// 	// yearly: {
			// 	// 	yearly_dates: [
			// 	// 		{
			// 	// 			month: 1,
			// 	// 			day: 1,
			// 	// 		}
			// 	// 	],
			// 	// },
			// 	...recurrence
			// },
			
			
			// FORM SECTIONS
			sections: helm_form?.FormItems.map(convertFormItemToSection),
			
			
			work_order_ids: [],
			file_ids: [],
			collaborator_ids: [],
			parts_needed: [],
			tag_ids: [],
			issue_ids: [],
		};
		
		
		let template = pareTemplate(form_data);
		
		
		if(template.recurrence)
		{
			template.recurrence.effective_datetime = (form_data?.recurrence?.effective_datetime as DateTime)?.toISO();
			
			if(determineNextAutomationDatetime(form_data.recurrence))
			{
				template.recurrence.next_automation_datetime =
					determineNextAutomationDatetime(form_data.recurrence)?.toISO();
			}
			
			
			if(template.recurrence.weekly)
			{
				template.recurrence.weekly.weekday_numbers = template.recurrence.weekly.weekday_numbers.filter(x => x !== undefined);
			}
		}
		
		
		template.sections = cleanSections(template.sections);
		
		
		// console.log('    Template sections:', template.sections);
		
		
		return excludeUndefinedValues(template) as WorkOrderTemplate;
	});
	
	
	return transformed_data;
}





// TODO: Return type? TemplateSection is incompatible because of fields structure
function convertFormItemToSection(item_group: HelmFormItemGroup)
{
	// const field_item_types = item.Items.map(x => x.Type);
	
	
	// console.log('    Field item types:', field_item_types);
	
	
	let result =
	{
		name: item_group.Name,
		
		fields: convertHelmFormToFields(item_group),
	};
	
	
	// result.component_id = item_group.
	
	return result;
}




export const convertHelmFormToFields = (item_group : HelmFormItemGroup) =>
{
	return (
		item_group.Items.map(( item : HelmFormItem, i : number ) => {
			
			const str1 = item?.Component?.Id;
			const str2 = item?.Description.toLowerCase().replaceAll(' ', '-');
			const str3 = item?.ReadingType?.Name.toLowerCase().replaceAll(' ', '-');
			const str4 = item?.ReadingType?.Id;
			
			
			const component_record_id = `${str1}--${str2}--${str3}--${str4}`;
			
			
			
			switch (item.Type) {
				case 'checkbox':
					return {
						action_type: 'checkbox',
						checkbox: {
							description: item.Description,
							options:
								item.ValueRange?.split(',').map((value) => ({
									label: value,
									checked: false,
								})) || [],
						},
					};
					
					
				case 'custom-dropdown':
					return {
						action_type: 'checkbox',
						checkbox: {
							description: item.Description,
							options:
								item.ValueRange?.split(',').map((value) => ({
									label: value,
									checked: false,
								})) || [],
						},
					};
					
					
				case 'done':
					return {
						action_type: 'checkbox',
						checkbox: {
							description: item.Description,
							options: [
								{
									label: 'Done',
									checked: false,
								},
							],
						},
					};
					
					
				case 'instruction':
					return {
						action_type: 'instruction',
						instruction: {
							description: item.Description,
						},
					};
					
					
				case 'description':
					return {
						action_type: 'text',
						text: {
							description: item.Description,
							longText: '',
						},
					};
					
					
				case 'radio':
					return {
						action_type: 'multiple_choice',
						multiple_choice: {
							description: item.Description,
							options:
								item.ValueRange?.split(',').map((value) => ({
									label: value,
									checked: false,
								})) || [],
						},
					};
					
					
				// case 'crew-selection':
				// 	return {
				// 		action_type: 'user',
				// 		user: {
				// 			description: item.Description,
				// 		}
				// 	};
				case 'replace':
					const values =
						item.ValueRange?.split(',').map((value) => ({
							label: value,
							checked: false,
						})) || [];
					return {
						action_type: 'replace',
						replace: {
							description: item.Description,
							equipment: [
								{_id: item?.Component?.Id, name: item?.Component?.Description}
							],
							value: values,
						},
					};
					
					
				case 'runninghour':
					
					// const str1 = item?.Component?.Id;
					// const str2 = item?.Description.toLowerCase().replaceAll(' ', '-');
					
					// const str3 = item?.ReadingType?.Id;
					
					// const component_record_id = `${str1}--running-hours--` + str3 // str2 + '--' + item?.ValueRange // + i;
					
					// console.log('    COMPONENT RECORD ID:   ', component_record_id, item);
					
					
					// TODO: Handle non-existing component record?
					return {
						action_type: 'reading',
						reading: {
							description: item.Description,
							equipment: [
								{
									_id: item?.Component?.Id,
									name: item?.Component?.Description,
								},
							],
							component_record: {
								_id: component_record_id,
								title: item?.Description,
								type: 'Running Hours',
								units: 'hours',
								status: 'Normal',
								should_calculate_average: true,
								asset_id: item?.Component?.VesselId,
								component_id: item?.Component?.Id,
								triggers_by_id: {},
								description: '',
							},
							readingType: 'cumulative',
							range:
								(!item.ValueRange)
								||
								(item.ValueRange === '')
								||
								(item.ValueRange === ',')
									? {}
									: {
										min:
											(item.ValueRange.split(',')[0])
												? parseInt(item.ValueRange.split(',')[0])
												: undefined,
										max:
											(item.ValueRange.split(',')[1])
												? parseInt(item.ValueRange.split(',')[1])
												: undefined,
									},
						},
					};
					
				
				// TODO: Currently no examples
				case 'part-usage':
					return {
						action_type: 'text',
						text: {
							description: item.Description,
							longText: 'Please add the parts used on the Work Order Details Page (parts used section)',
						},
					};
					
					
				case 'reading':
					
					// const reading_str1 = item?.Component?.Id;
					// const reading_str2 = item?.Description.toLowerCase().replaceAll(' ', '-');
					// const reading_str3 = item?.ReadingType?.Name.toLowerCase().replaceAll(' ', '-');
					// const reading_str4 = item?.ReadingType?.Id;
					
					//item?.ReadingType?.Name.toLowerCase().replaceAll(' ', '-');
					
					if(!str2.length)
					{
						console.log('!!!', item)
					}
					
					// console.log(str1, str2, str3)
					
					// const reading_component_record_id = `${str1}--${str2}--${str3}--${str4}`;
					
					// console.log('    ' + component_record_id, item);
					
					return {
						action_type: 'reading',
						reading: {
							description: item.Description,
							equipment: [
								{
									_id: item?.Component?.Id,
									name: item?.Component?.Description
								},
							],
							component_record: {
								_id: component_record_id,
								title: item?.ReadingType?.Name,
								description: item?.Description,
								type: item?.ReadingType?.Name,
								units: item?.ReadingType?.Unit,
								status: 'Normal',
								should_calculate_average: false,
								asset_id: item?.Component?.VesselId,
								component_id: item?.Component?.Id,
								triggers_by_id: {},
							},
							readingType: 'cumulative',
							range:
								!item.ValueRange
								||
								(item.ValueRange === '')
								||
								(item.ValueRange === ',')
									? {}
									: {
										min:
											(item.ValueRange.split(',')[0])
												? parseInt(item.ValueRange.split(',')[0])
												: undefined,
										max:
											(item.ValueRange.split(',')[1])
												? parseInt(item.ValueRange.split(',')[1])
												: undefined,
									},
						},
					};
					
					
				case 'calendar':
					return {
						action_type: 'date',
						date: {
							description: item.Description,
							time: null,
							date: null,
						},
					};
					
					
				case 'field':
					return {
						action_type: 'text',
						text: {
							description: item.Description,
							longText: item.ValueRange || '',
						},
					};
					
					
				case 'fail-pass':
					return {
						action_type: 'pass_fail',
						pass_fail: {
							description: item.Description,
						}
					};
					
					
				case 'no-yes-na':
					return {
						action_type: 'yes_no',
						yes_no: {
							description: item.Description,
						}
					};
					
					
				default:
					return {
						action_type: 'text',
						text: {
							description: item.Description,
							longText: '',
						},
					};
			}
		})
	)
}




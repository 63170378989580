import { Stack } from '@mui/material';

import ComponentRecordFormProvider from './ComponentRecordFormProvider';

import { Divider } from '@mui/material';

import TitleFormlet from '../fields/text/TitleFormlet';
import DescriptionField from '../fields/text/DescriptionFormlet';
import ComponentRecordUnitsFormlet from './ComponentRecordUnitsFormlet';
import Reading from './reading/Reading';
import { ComponentRecord } from 'models';
import TriggersGizmo from './triggers/TriggersGizmo';
import ComponentRecordTypeFormlet from './ComponentRecordTypeFormlet';
import CheckboxFormlet from '../fields/text/CheckboxFormlet';


const ComponentRecordForm =
({
	component_record,
} : {
	component_record?: Partial<ComponentRecord>,
}) =>
{
	
	return (
		<ComponentRecordFormProvider
			component_record={component_record}
		>
			<Stack
				sx={{
					bgcolor: 'bg.form',
					padding: 2,
					borderRadius: 1,
				}}
			>
				<Stack
					gap={2}
				>
					
					<TitleFormlet />
					<DescriptionField />
					
					<Divider />
					
					<ComponentRecordTypeFormlet />
					<ComponentRecordUnitsFormlet />
					
					<Divider />
					
					<Reading
						name='initial_reading'
						label='Initial Reading'
					/>
					
					<Divider />
					
					<TriggersGizmo />
					
					<Divider />
					
					<CheckboxFormlet
						name='should_calculate_average'
						label='Calculate Average Daily Value?'
						help='When an updated reading is received, should an average daily value be calculated? This can be used for projecting when thresholds will be reached.'
						option_label='Calculate average'
					/>
					
				</Stack>
			</Stack>
			
		</ComponentRecordFormProvider>
	)
}


export default ComponentRecordForm;
import { makeStyles, createStyles } from "@mui/styles"

export const useStyles = makeStyles((theme?: any) => createStyles({
	Card_Main: {
		width: '50%',
		margin: 'auto'
	},
	ColBox: {
		flexDirection: 'column', 
		marginBottom: '1rem'
	},
	RowBox: {
		flexDirection: 'row', 
		marginBottom: '1rem',
		justifyContent: 'space-between'
	},
	CardItem_Box: {
		margin: '0.5rem 0 0.5rem 0',
		display: 'flex',
		flexDirection: 'row',
		width: '90%',
		alignItems: 'center'
	},
	Title: {
		padding: '0.5rem',
		margin:' 0 0.5rem 0 0',
		borderRadius: '10px',
		width: 'fit-content',
		minWidth: '9rem',
		background:'#426A8E',
		color: '#fff'
	},
	Chip: {
		margin: '0 0.2rem 0 0.2rem'
	},
	Card_Dates: {
		marginLeft: '0.2rem',
		marginRight: '0.5rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	Card_Dates_Highlighted: {
		color: '#ffffff',
		background: '#676879',
		padding: '0 0.5rem 0 0.5rem',
		borderRadius: '2px',
		height: 'fit-content',
	}

}))
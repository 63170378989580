import React from 'react'

import { Button, Link, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CorIcon from 'components/icons/CorIcon';
import { useNavigate } from 'react-router';
import { defaultAppTheme, responsiveAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useWorkOrder } from './WorkOrderProvider';
import { WorkOrderStatus } from 'models';
import { navigateUp } from 'utils/url_utils';
import { NavLink } from 'react-router-dom';




const WorkOrderPageHeader = () => 
{
	const navigate = useNavigate();
	
	const { work_order } = useWorkOrder();
	const { title, status, work_order_num } = work_order;
	
	const small_screen = useMediaQuery(responsiveAppTheme.breakpoints.down('sm'));
	
	
	console.log({
		work_order
	});
	
	
	return (
		<Stack
			direction='column'
			justifyContent='flex-start'
			alignItems='flex-start'
			spacing={1}
			sx={{
				padding: '2px',
			}}
			className='issue_collab_header'
		>
			
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				sx={{
					cursor: 'pointer',
				}}
				// onClick={() => navigate(-1)}
				>
				<Button
					sx={{
						textTransform: 'none',
						padding: '3px 4px',
					}}
					onClick={() => navigateUp(navigate)}
				>
					<CorIcon
						of='back'
						size='25px'
						color={defaultAppTheme.palette.primary.main}
					/>
					<Typography variant='caption'>
						Back to list
					</Typography>
				</Button>
				
				<NavLink
					to={'/work-order-templates/' + work_order.template_id}
					style={{
						textDecoration: 'none',
						marginLeft: '64px',
					}}
				>
					<Button
						sx={{
							textTransform: 'none',
							padding: '3px 4px',
						}}
						onClick={() => navigate('/work-order-templates/' + work_order.template_id)}
					>
						<CorIcon
							of='parent_template'
							size='25px'
							color={defaultAppTheme.palette.primary.main}
						/>
						<Typography
							variant='caption'
							sx={{
								// textDecoration: 'none !important',
							}}
						>
							Parent Template
						</Typography>
					</Button>
				</NavLink>
				
			</Stack>
			
			<Stack
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				sx={{
					padding: '2px 16px',
					margin: 0,
					marginTop: 0,
				}}
				style={{
					marginTop: 0,
				}}
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					sx={{
						padding: '8px',
					}}
				>
					<CorIcon
						of={(status === WorkOrderStatus.COMPLETED) ? 'closed_work_order' : 'open_work_order'}
						size='24px'
						color={
							(status === WorkOrderStatus.DUE)
								? defaultAppTheme.palette.secondary.main
								: (status === WorkOrderStatus.IN_PROGRESS)
									? defaultAppTheme.palette.primary.main
									: defaultAppTheme.palette.success.dark
							}
					/>
					
					<Typography
						variant='body1'
						color={
							(status === WorkOrderStatus.DUE)
								? defaultAppTheme.palette.secondary.main
								: (status === WorkOrderStatus.IN_PROGRESS)
									? defaultAppTheme.palette.primary.main
									: defaultAppTheme.palette.success.dark
							}
					>
						{ status.toUpperCase() }
					</Typography>
				</Stack>
				
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='flex-start'
					sx={{
						padding: '4px',
					}}
				>
					<Typography
						variant='h1'
						sx={{
							padding: '2px',
						}}
					>
						WO #{work_order_num}
					</Typography>
					
					{
						!small_screen &&
							<Typography
								variant='h3'
								fontWeight={300}
								sx={{
									padding: '2px',
								}}
							>
								{title}
							</Typography>
					}
				</Stack>
				
			</Stack>
			
		</Stack>
	)
}


export default WorkOrderPageHeader;



export const victor_styles =
{
	data: {
		fill: '#0000',
		stroke: '#0005',
	},
	fontSize: '10px',
}



const BLUE_COLOR = '#00a3de';
const RED_COLOR = '#7c270b';


export const misc_styles =
{
	parent: {
		background: '#ccdee8',
		boxSizing: 'border-box',
		display: 'inline',
		padding: 0,
		fontFamily: `'Fira Sans', sans-serif`,
	},
	title: {
		textAnchor: 'start',
		verticalAnchor: 'end',
		fill: '#000000',
		fontFamily: 'inherit',
		fontSize: '18px',
		fontWeight: 'bold'
	},
	labelNumber: {
		textAnchor: 'middle',
		fill: '#ffffff',
		fontFamily: 'inherit',
		fontSize: '10px'
	},
	
	// INDEPENDENT AXIS
	axisYears: {
		axis: { stroke: 'black', strokeWidth: 1},
		ticks: {
			size: ({ tick }) => {
				const tickSize =
					(tick.getFullYear() % 5 === 0) ? 10 : 5;
				return tickSize;
			},
			stroke: 'black',
			strokeWidth: 1
		},
		tickLabels: {
			fill: 'black',
			fontFamily: 'inherit',
			fontSize: 16
		}
	},
	
	
	// DATA SET ONE
	axisOne: {
		grid: {
			stroke: ({ tick }) =>
				tick === -10 ? 'transparent' : '#ffffff',
			strokeWidth: 2
		},
		axis: { stroke: BLUE_COLOR, strokeWidth: 0 },
		ticks: { strokeWidth: 0 },
		tickLabels: {
			fill: BLUE_COLOR,
			fontFamily: 'inherit',
			fontSize: 16
		}
	},
	
	labelOne: {
		fill: BLUE_COLOR,
		fontFamily: 'inherit',
		fontSize: 12,
		fontStyle: 'italic'
	},
	
	lineOne: {
		data: { stroke: BLUE_COLOR, strokeWidth: 4.5 }
	},
	
	axisOneCustomLabel: {
		fill: BLUE_COLOR,
		fontFamily: 'inherit',
		fontWeight: 300,
		fontSize: 21
	},
	
	// DATA SET TWO
	axisTwo: {
		axis: { stroke: RED_COLOR, strokeWidth: 0 },
		tickLabels: {
			fill: RED_COLOR,
			fontFamily: 'inherit',
			fontSize: 16
		}
	},
	
	labelTwo: {
		textAnchor: 'end',
		fill: RED_COLOR,
		fontFamily: 'inherit',
		fontSize: 12,
		fontStyle: 'italic'
	},
	
	lineTwo: {
		data: { stroke: RED_COLOR, strokeWidth: 4.5 }
	},
	
	// HORIZONTAL LINE
	lineThree: {
		data: { stroke: '#e95f4644', strokeWidth: 1 }
	},
	
	// HORIZONTAL LINE
	lineFour: {
		data: { stroke: '#e95f4644', strokeWidth: 1 }
	},
	
	
	
	
	// Min/max points
	min_point: {
		// data: { stroke: '#e95f4644', strokeWidth: 1 }
		data: { fill: '#00f2' }
	},
	max_point: {
		// data: { stroke: '#e95f4644', strokeWidth: 1 }
		data: { fill: '#f002' }
	},
	
	
	inside_range: {
		data: {
			fill: '#0001',
			stroke: '#f002',
		},
	},
	outside_range: {
		data: {
			fill: '#efefef',
			stroke: '#00f2',
		},
	},
}



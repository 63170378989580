
import { Certification, CertificationFor, CertificationRecordStatus } from "models"
import { DateTime, relativeDatetimeText, relativeDaysDiff, useState } from "stuff";
import { Chip, Typography } from "@mui/material";
import SortableTable from "../../issueCollaboration/issueDetails/issueTable/SortableTable";


import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import CreateEditCertification from '../CreateEditCertification';
import CorIcon from 'components/icons/CorIcon';
import CertificationDataProvider from '../CertificationDataProvider';
import CertificationRecordsProvider from '../certificationRecords/CertificationRecordsProvider';
import CertificationRecordsTable from '../certificationRecords/CertificationRecordsTable';
import CreateEditCertificationRecord from '../certificationRecords/CreateEditCertificationRecord';



const CertificationTable = ({
	certifications,
} : {
	certifications: Certification[],
}) =>
{
	const [ closeDialog2, setCloseDialog2 ] = useState<boolean>(false);
	
	
	return (
		<SortableTable
			options={certifications}
			onSelect={(selectedOption) => {
				//navigate(`/work-orders/${selectedOption._id}`)
			}}
			rows={[
				{
					title: 'Name',
					getCell(certification)
					{
						return (
							<Typography variant='h5'>
								{certification.name}
							</Typography>
						);
					},
				},
				{
					title: 'Category',
					getCell(certification)
					{
						return (
							<Chip
								label={certification?.category}
								color='info'
								variant='outlined'
							/>
						)
					}
				},
				{
					title: 'Assigned to',
					getCell(certification)
					{
						return (
							<>
								{
									(certification.certification_for === CertificationFor.USER)
									? (
										<Typography variant='subtitle1'>
											{certification?.user_name}
										</Typography>
									) : (certification.certification_for === CertificationFor.COMPANY_WIDE_ROLE)
									? (
										<Typography variant='subtitle1'>
											{certification?.role_name}
										</Typography>
									) : (certification.certification_for === CertificationFor.ASSET)
									? (
										<Typography variant='subtitle1'>
											{certification?.asset_name}
										</Typography>
									) : (
										<Typography variant='subtitle1'>
											All Vessels
										</Typography>
									)
								}
							</>
						);
					},
				},
				{
					title: 'Status',
					getCell(certification) {
						
						return (
							<>
								{
									(certification?.updated_record_status === CertificationRecordStatus.ACTIVE)
										? <Chip label={certification?.updated_record_status} color='success'/>
										: <Chip label={certification?.updated_record_status} />
								}
							</>
							
						)
					}
				},
				{
					title: 'Expiry Date',
					getCell(certification) {
						
						const datetime = DateTime.fromISO(certification?.updated_record_expiry_date)
						const days_diff = relativeDaysDiff(datetime);
						
						
						return (
							<>
								{
									(datetime.isValid) ? (
										<>
											<Typography
												sx={{
													background: (days_diff >= 60)
														? defaultAppTheme.palette.success.main
														: (days_diff <= 7 && days_diff > -150)
														? defaultAppTheme.palette.secondary.main
														: (days_diff > 7 && days_diff <= 30)
														? defaultAppTheme.palette.warning.main
														: (days_diff > 30 && days_diff < 60)
														? defaultAppTheme.palette.primary.main
														: defaultAppTheme.palette.grey['500'],
													padding: '4px',
													borderRadius: '4px',
													color: '#fff',
												}}
											>
												{relativeDatetimeText(datetime)}
											</Typography>
											
											<ConciseDateAndTime datetime={datetime} />
										</>
										) : (
										<Typography> -- </Typography>
									)
								}
							</>
						)
					}
				},
				{
					title: 'View Records',
					getCell(certification) {
						return (
							<ButtonToOpenDialog
								button_variant='text'
								button_content='View'
								dialog_title={`Available Records for ${certification.user_name}`}
								dialog_content={
									<CertificationRecordsProvider
										certification={certification}
									>
										<CertificationRecordsTable />
									</CertificationRecordsProvider>
								}
								closeDialog={closeDialog2}
								sx={{
									textTransform:'none',
									textDecoration: 'underline',
								}}
							/>
						)
					}
				},
				{
					title: 'Add Record',
					getCell(certification) {
						return (
							<ButtonToOpenDialog
									button_type='icon_button'
									dialog_title={`Create a new Record`}
									dialog_content={
										<CertificationRecordsProvider
											certification={certification}
										>
											<CreateEditCertificationRecord
												closeDialog={closeDialog2}  
												setCloseDialog={setCloseDialog2}
											/>
										</CertificationRecordsProvider>
									}
									closeDialog={closeDialog2}
									sx={{
										textTransform:'none',
										textDecoration: 'underline',
									}}
									buttonIcon={<CorIcon of='add' size='28px'/>}
								/>
						)
					}
				},
				{
					title: 'Edit',
					getCell(certification) {
						return (
							<ButtonToOpenDialog
								button_type='icon_button'
								dialog_title={`Edit Certification`}
								dialog_content={
									<CertificationDataProvider
										certification={certification}
									>
											<CreateEditCertification
												setCloseDialog={setCloseDialog2}
											/>
									</CertificationDataProvider>
								}
								closeDialog={closeDialog2}
								sx={{
									textTransform:'none',
									textDecoration: 'underline',
								}}
								buttonIcon={<CorIcon of='edit' size='24px'/>}
							/>
						)
					}
				},
			]}
		/>
	)
}


export default CertificationTable;
import { useState } from 'react';

import { Tag } from 'types';
import TagChip from 'app/dashboard/pages/issueCollaboration/issueDetails/TagChip';

import { Grid, Stack, Typography } from '@mui/material';

import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import SelectTagsFormlet from 'components/selectTags/SelectTagsFormlet';
import { useUpdateDocProperty } from 'ember';
import { useAuthData } from 'stuff';
import { useRequisition } from '../../RequisitionDataProvider';


const RequisitionCollabTagsList = () =>
{
	const { claims } = useAuthData();
	const updateDocProperty = useUpdateDocProperty();
	
	
	const { requisition, tags } = useRequisition();
	const selected_tags = tags?.filter(x => requisition?.tag_ids?.includes(x._id));
	
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	const [selectedTags, setSelectedTags] = useState(selected_tags);
	
	
	
	const onTagSelect = (event: React.ChangeEvent<{}>, newValue: Tag[] | null) =>
	{
		setSelectedTags(newValue);
	};
	
	const updateTags = () =>
	{
		let tag_ids = selectedTags.map(tag => tag._id);
		
		updateDocProperty(`organizations/${claims.organization_id}/requisitions/${requisition._id}`, 'tag_ids', tag_ids);
		
		setCloseDialog(!closeDialog);
	}
	
	
	return (
		<Grid
			item
			xs={12}
			sm={3}
		>
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='center'
				sx={{
					marginLeft:'10px',
				}}
			>
				<Typography
					variant='h6'
				>
					Tags
				</Typography>
				
				<Grid
					container
					width='100%'
					sx={{
						marginTop: '5px',
					}}
					gap={1}
				>
					{
						selected_tags?.map(tag =>
							<Grid
								key={tag._id}
								item
								xs={12}
								sm={12}
								md={12}
								lg={6}
							>
								<TagChip tag={tag} />
							</Grid>
						)
					}
				</Grid>
				
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					sx={{
						width: '100%',
					}}
				>
					<ButtonToOpenDialog
						button_variant='text'
						dialog_title='Select Tags'
						button_content='Edit'
						dialog_submit_text='Confirm'
						dialog_content={
							<SelectTagsFormlet
								label={'Type to search'}
								id='create-issue-tags'
								multiple={true}
								value={selectedTags}
								onChange={onTagSelect}
								tags={tags}
							/>
						}
						sx={{
							textTransform:'none',
							textDecoration: 'underline',
						}}
						closeDialog={closeDialog}
						handleSubmit={updateTags}
						dialogClassName='add_tags_dialog'
					/>
				</Stack>
				
			</Stack>
		</Grid>
	)
};


export default RequisitionCollabTagsList;
import { Divider, Stack } from '@mui/material';

import RequisitionTotalEstimatedCost from './RequisitionTotalEstimatedCost';
import { useRequisition } from '../../RequisitionDataProvider';
import SearchableSelectFormlet from 'app/gizmos/fields/select/SearchableSelectFormlet';
import { useFormContext } from 'react-hook-form';
import LinkIssues from 'pages/CreateWorkOrder/GeneralDescription/Issues/LinkIssues';
import LinkWorkOrder from 'components/linkWorkOrder/LinkWorkOrder';
import SelectTagsFormlet from 'components/selectTags/SelectTagsFormlet';
import { AttachmentsFormlet } from 'pages/createIssue/createIssueStepper/components/AttachmentsFormlet';
import RequisitionChargeToAccount from '../../RequisitionChargeToAccount';
import CreateRequisitionCollaborators from './CreateRequisitionCollaborators';



const CreateRequisitionCostAndTeamMain = () =>
{
	const form = useFormContext();
	const { control } = form;
	
	const { cost_centers, roles, tags } = useRequisition();
	
	
	return (
		<Stack
			gap={3}
			sx={{
				padding: '5px'
			}}
		>
			<RequisitionTotalEstimatedCost />
			
			<AttachmentsFormlet
				control={control}
				label='Please upload any quotes or files'
				help='You can upload files related to projects, purchase, etc.'
			/>
			
			<SearchableSelectFormlet
				name='cost_centers'
				label='Select associated Cost Centers'
				help='e.g. Main Office Cost Center'
				options={cost_centers}
				getOptionLabel={option => option.description}
				multiple={true}
			/>
			
			<RequisitionChargeToAccount />
			
			<Divider sx={{padding: '20px'}} />
			
			<SearchableSelectFormlet
				name='roles'
				label='Select responsible Roles'
				help='e.g. Inventory Manager, Chief Engineer'
				options={roles}
				getOptionLabel={option => option.name}
				multiple={true}
			/>
			
			<CreateRequisitionCollaborators />
			
			<SelectTagsFormlet
				tags={tags}
				name='tags'
				label='Please select any needed Tags'
				multiple={true}
			/>
			
			<LinkIssues control={control} />
			
			<LinkWorkOrder control={control} />
		</Stack>
	)
};


export default CreateRequisitionCostAndTeamMain;
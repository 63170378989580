import { useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import { Box, InputLabel, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CorIcon from 'components/icons/CorIcon';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import SelectableWorkOrdersTable from './SelectableWorkOrdersTable';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { WorkOrderNew } from 'models';


interface LinkWorkOrderProps
{
	control: any,
};


const LinkWorkOrder = ({ control }: LinkWorkOrderProps) =>
{
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	const { replace } = useFieldArray({
		control,
		name: `work_order_ids`,
	});
	
	const formSelectedWOs = useWatch({
		control,
		name: 'work_order_ids',
	});
	
	
	
	const [selectedWOs, setSelectedWOs] = useState<WorkOrderNew[]>([]);
	
	
	
	const addSelectedWOs = () =>
	{
		let wo_ids = [];
		
		selectedWOs.forEach((wo: WorkOrderNew) => {
			wo_ids.push(wo._id);
		});
		
		setCloseDialog(!closeDialog);
		replace(wo_ids);
	};
	
	
	const onWORemove = (woId: string) => {
		const newWOs = selectedWOs.filter(
			(wo) => wo._id !== woId
		);
		setSelectedWOs(newWOs);
		
		let wo_ids = [];
		
		newWOs.forEach((wo: WorkOrderNew) => {
			wo_ids.push(wo._id);
		})
		replace(wo_ids);
	};
	
	
	useEffect(() => {
		if (selectedWOs.length > 0) return;
		replace(formSelectedWOs);
	}, []);
	
	
	return (
		<Stack
			gap={1}
		>
			<InputLabel
				sx={{
					fontSize: '16px',
					fontWeight: '600',
					color: defaultAppTheme.palette.grey['700'],
				}}
			>
				Link any associated Work Orders
			</InputLabel>
			
			<ButtonToOpenDialog
				button_variant='outlined'
				dialog_title='Select Work Orders'
				button_content='Add Work Orders'
				dialog_dismiss_text='Cancel'
				dialog_content={
					<SelectableWorkOrdersTable
						selectedWOs={selectedWOs}
						setSelectedWOs={setSelectedWOs}
						addSelectedWOs={addSelectedWOs}
					/>
				}
				closeDialog={closeDialog}
				startIcon={<CorIcon of='work_order' size='24px'/>}
			/>
			
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					border: '1px solid #d8dbdf',
					borderRadius: '5px',
					width: '100%',
					height: '150px',
					overflow: 'auto',
				}}
			>
				{selectedWOs.map((wo) => (
					<SelectedWO
						wo={wo}
						onRemove={onWORemove}
						key={wo._id}
					/>
				))}
			</Box>
		</Stack>
	);
};



interface SelectedWOProps
{
	wo: WorkOrderNew;
	onRemove: (woId: string) => void;
}



const SelectedWO = ({ wo, onRemove }: SelectedWOProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				gap: '2rem',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '1rem',
				margin: '5px 10px 5px 10px',
				borderRadius: '5px',
				border: '1px solid #d8dbdf',
				backgroundColor: '#d8dbdf',
				color: '#666666',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.4)',
			}}
		>
			<Box>
				<Box sx={{ fontWeight: 'semibold' }}>
					Work Order Template #{wo.work_order_num}
				</Box>
				<Box>{wo.title}</Box>
			</Box>
			<CloseIcon
				htmlColor='#666666'
				sx={{ cursor: 'pointer' }}
				onClick={() => onRemove(wo._id)}
			/>
		</Box>
	);
};



export default LinkWorkOrder;
import { Controller, useFormContext } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import LuxonAdapter from '@mui/lab/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';

import { FormInputType, ResponseField, WorkOrderResponseForm } from 'types/forms';



export interface CalendarProps
{
	field: ResponseField & {
		type: FormInputType.Calendar,
	},
	sectionIndex: number,
	fieldIndex: number,
}


export const Calendar =
({
	field,
	sectionIndex,
	fieldIndex,
}: CalendarProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Controller
				control={control}
				name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.date`}
				defaultValue={field.response?.date ?? ''}
				render={({ field: { value, onChange } }) => (
					<LocalizationProvider dateAdapter={LuxonAdapter}>
						<DatePicker
							value={value}
							onChange={(newValue) =>
								onChange(newValue.toString())
							}
							renderInput={(params) => (
								<TextField {...params} fullWidth />
							)}
						/>
					</LocalizationProvider>
				)}
			/>
			<Controller
				control={control}
				name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.time`}
				defaultValue={field.response?.time ?? ''}
				render={({ field: { value, onChange } }) => (
					<LocalizationProvider dateAdapter={LuxonAdapter}>
						<TimePicker
							value={value}
							onChange={(newValue) =>
								onChange(newValue.toString())
							}
							renderInput={(params) => (
								<TextField {...params} fullWidth />
							)}
						/>
					</LocalizationProvider>
				)}
			/>
		</Box>
	);
};


export default Calendar;
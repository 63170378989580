import { React } from 'stuff';

import CorIcon from 'components/icons/CorIcon';

import { Box } from '@mui/system';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { navItem } from './NavBarInterface';


// Individual NavBar tab (left pane), controlling Main content
// Automatically has active class applied if the link matches current URL
const NavItem = React.forwardRef<HTMLInputElement, navItem>((props, ref) =>
{
	const {name, icon } = props;
	
	return (
		<>
			<ListItemIcon>
				<Box
					flexDirection='column'
					className='nav_item'
				>
					<CorIcon of={icon} size='25px'/>
				</Box>
			</ListItemIcon>
			
			<ListItemText primary={name} />
		</>
	)
});


export default NavItem;
import { useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Stack } from '@mui/material';

import { Recurrence } from 'models';
import { groupedOccurrences } from 'models/recurrence/recurrence_helpers3';
import { makeOccurrenceCardData } from './OccurrenceCardList';
import Formlet from '../../fields/Formlet';
import OccurrenceCard from './OccurrenceCard';
import ProjectionNote from './ProjectionNote';


// An output-only look into the future, projecting when events will occur
export const ProjectionFormlet = () =>
{
	const form_context = useFormContext()
	
	// Trying to keep a stable value for comparisons
	// const now = form_context.watch('now') || DateTime.local() as DateTime;
	const now = DateTime.local();
	const title = form_context.watch('title');
	const recurrence = form_context.watch('recurrence') as Recurrence;
	
	
	let grouped_occurrences = groupedOccurrences(recurrence, 10, now);
	
	// TODO: Why is this sometimes invalid?
	// occurrences = occurrences.filter(x => x.generation_datetime.isValid);
	
	// let grouped_occurrences = groupBy(occurrences, occurrence => occurrence.generation_datetime);
	
	
	
	
	let elems = [];
	
	
	Object.keys(grouped_occurrences)
		.forEach(iso_date => {
			
			let some_occurrences = grouped_occurrences[iso_date]
			
			let dt = DateTime.fromISO(iso_date).toLocal();
			
			if(dt.toMillis() <= now.toMillis())
			{
				elems.push(<ProjectionNote
					key='immediately'
				/>)
			}
			else
			{
				elems.push(<ProjectionNote
					dt={DateTime.fromISO(iso_date).toLocal()}
					key={iso_date}
				/>)
			}
			
			
			for(let i = 0; i < some_occurrences.length; i++)
			{
				let occurrence = some_occurrences[i];
				
				
				let card_data =
					makeOccurrenceCardData(
						title + ' ' + occurrence?.scheduled_for.toISO().slice(0, 10),
						occurrence.scheduled_for,
						now
					)
				
				elems.push(
					<OccurrenceCard
						projected={card_data}
						key={card_data._id + 'card'}
					/>
				)
			}
		})
	
	
	console.log({
		grouped_occurrences,
	});
	
	
	return (
		<Formlet
			label='Projections'
			help='Projecting when work orders will be generated'
		>
			{/* <DatetimeList */}
			<Stack
				gap={1}
				padding={2}
			>
				{elems}
			</Stack>
		</Formlet>
	)
}


export default ProjectionFormlet;
import Grid from '@mui/material/Grid';
import { WorkOrderTemplate } from 'models';
import SearchableWorkOrderTemplatesCardList from './SearchableWorkOrderTemplatesCardList';
import WorkOrderTemplatesTable from './WorkOrderTemplatesTable';


const ResponsiveWorkOrderTemplatesList = ({
	work_order_templates,
} : {
	work_order_templates: WorkOrderTemplate[],
}) =>
{
	return (
		<Grid container>
			<Grid
				item
				className='cards_container'
				display={{xs: 'none', sm: 'none', md: 'block', lg: 'block'}}
			>
				<WorkOrderTemplatesTable work_order_templates={work_order_templates}/>
			</Grid>
			
			<Grid
				item
				className='cards_container'
				display={{xs: 'block', sm: 'block', md: 'none', lg: 'none'}}
			>
				<SearchableWorkOrderTemplatesCardList work_order_templates={work_order_templates}/>
			</Grid>
		</Grid>
	)
};


export default ResponsiveWorkOrderTemplatesList;
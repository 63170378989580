import React, { useState } from 'react';

import { Action, ActionStatus, ActionType, CostRecord, RequisitionStatus } from 'models';
import { Box, TextField, Stack, Typography, Divider, Button, CircularProgress } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useDoc, useUpdate, useUpdateDocProperty, useWrite } from 'ember';
import CorIcon from 'components/icons/CorIcon';
import { useAuthData, DateTime, numberWithCommas } from 'stuff';
import cuid from 'cuid';
import { Event } from 'types';
import InstantNotificationPopup from 'app/dashboard/nav/notifications/InstantNotificationPopup';
import { User } from 'models/users/user_interfaces';
import ActionCollaborationHeader from './ActionCollaborationHeader';
import ActionCollaborationAccountChargeInfo from './ActionCollaborationAccountChargeInfo';
import ActionCollaborationControls from './ActionCollaborationControls';


const ActionsCollaborationPanel = ({
	action,
	closeDialog,
	setCloseDialog,
} : {
	action: Action,
	closeDialog?: boolean
	setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) =>
{
	const { claims } = useAuthData();
	
	const [ response, setResponse ] = useState<string>(action?.response);
	const [fireAlert, setFireAlert ] = useState<boolean>(false);
	
	const update = useUpdate();
	const write = useWrite();
	const updateDocProperty = useUpdateDocProperty();
	
	const org_path = `organizations/${claims?.organization_id}`;
	const user_doc = useDoc(`${org_path}/users`, claims?.user_id);
	
	
	if(user_doc?.is_loading)
	{
		return <CircularProgress />
	}
	
	const user = user_doc?.data as User;
	console.log({user});
	
	const remaining_balance = Number(user?.budget_limit?.quantity) - Number(user?.budget_spent_current_period?.quantity);
	
	
	const updated_at_date = fullDateAndTimeFromISO(action?.updated_at).props.children[0];
	const updated_at_time = fullDateAndTimeFromISO(action?.updated_at).props.children[2];
	
	const updated_at_datetime = `${updated_at_date} at ${updated_at_time}`;
	
	
	
	const current_user = {
		_id: claims.user_id,
		name: claims.name,
		email: claims.email
	}
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	// Handle Action Changes -------------------------------------------------------------------------------------------
	
	
	const handleActionUpdate = () =>
	{
		const datetime = DateTime.utc().toISO();
		
		console.log(response);
		
		const updated_action: Action = {
			...action,
			status: ActionStatus.IN_PROGRESS,
			response: response,
			updated_at: datetime,
			completed_by_user: current_user,
		};
		
		
		let new_action_event_text = `${current_user.name} just responded to - ${action.inquiry}`
			
		let new_action_event : Event =
		{
			_id: cuid(),
			name: new_action_event_text,
			
			type: 'action',
			subtype: 'update',
			
			datetime: datetime,
			summary:  new_action_event_text,
			issue_id: action?.issue_id || null,
			requisition_id: action?.requisition_id || null,
			action_id: action._id,
			user_id: claims?.user_id,
		};
		
		write(`organizations/${claims.organization_id}/events`, new_action_event);
		
		
		update(`organizations/${claims.organization_id}/actions`, updated_action);
		
		setFireAlert(true);
	}
	
	
	const handleActionStatusUpdate = () =>
	{
		const datetime = DateTime.utc().toISO();
		
		console.log(response);
		
		const updated_action: Action = {
			...action,
			status: ActionStatus.RESOLVED,
			updated_at: datetime,
		};
		
		if(updated_action?.action_type === ActionType.CHARGE_ACCOUNTS)
		{
			updateDocProperty(
				`organizations/${claims?.organization_id}/requisitions/${action.requisition_id}`,
				'status',
				RequisitionStatus.ACCOUNTS_APPROVED
			);
			
			updateDocProperty(
				`organizations/${claims?.organization_id}/requisitions/${action.requisition_id}`,
				'final_cost',
				{
					...action.accounts_charged_amount,
					is_approved: true,
				}
			);
			
			const cost_record : CostRecord = {
				_id: cuid(),
				cost: {
					...action.accounts_charged_amount,
					is_approved: true
				},
				cost_center_ids: action?.cost_center_ids,
				cost_code_ids: action?.cost_code_ids,
				
				requisition_id: action?.requisition_id,
				action_id: action?._id,
				
				issued_by_user: current_user,
				created_at: datetime
			};
			
			write(`organizations/${claims?.organization_id}/cost_records`, cost_record);
			
			
			const user_budget_spent_amount = (Number(user?.budget_spent_current_period?.quantity || '0')
												+ Number(action?.accounts_charged_amount.quantity))
												.toFixed(2).toString();
			
			updateDocProperty(
				`organizations/${claims?.organization_id}/users/${user._id}`,
				'budget_spent_current_period',
				{
					quantity: user_budget_spent_amount,
					currency: 'CAD',
				}
			)
			
			
		};
		
		
		let new_action_event_text = `${current_user.name} marked ${action.inquiry} as - RESOLVED`
			
		let new_action_event : Event =
		{
			_id: cuid(),
			name: new_action_event_text,
			
			type: 'action',
			subtype: 'resolved',
			
			datetime: datetime,
			summary:  new_action_event_text,
			issue_id: action?.issue_id || null,
			requisition_id: action?.requisition_id || null,
			action_id: action._id,
			user_id: claims?.user_id,
		};
		
		write(`organizations/${claims.organization_id}/events`, new_action_event);
		
		
		update(`organizations/${claims.organization_id}/actions`, updated_action);
		setFireAlert(true);
	};
	
	
	const handleActionStatusCancelled = () =>
	{
		const datetime = DateTime.utc().toISO();
		
		console.log(response);
		
		const updated_action: Action = {
			...action,
			status: ActionStatus.CANCELLED,
			updated_at: datetime,
		};
		
		update(`organizations/${claims.organization_id}/actions`, updated_action);
		setFireAlert(true);
	}
	
	// --------------------------------------------------------------------------------------------------------------------------------------
	
	
	
	
	
	
	
	
	
	
	
	
	return (
		<Box>
			
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='flex-start'
				spacing={2}
				sx={{
					marginBottom: '10px',
				}}
			>
				<ActionCollaborationHeader action={action} />
				
				
				
				
				<InfoHighlight
					severity='info'
					message_context={`Request Type: ${action.action_type}`}
					message_main={`Last updated: ${(action.updated_at) ? updated_at_datetime : ''}`}
				/>
				
				{
					(action.action_type === ActionType.CHARGE_ACCOUNTS
						&& action.assigned_to.includes(claims?.user_id)
					) && (
						<ActionCollaborationAccountChargeInfo
							action={action}
							user={user}
							remaining_balance={remaining_balance}
						/>
					)
				}
			</Stack>
			
			<Divider />
			
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='flex-start'
				spacing={2}
				sx={{
					marginTop: '20px',
					width: '100%',
				}}
			>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='flex-start'
					spacing={1}
					sx={{
						width: '80%',
					}}
				>
					<Box
						sx={{
							background: defaultAppTheme.palette.secondary.light,
							borderRadius: '4px',
							padding: '5px',
						}}
					>
						Action Inquiry:
					</Box>
					
					<Typography
						variant='h3'
					>
						{action.inquiry}
					</Typography>
				</Stack>
				
				<TextField
					multiline
					rows={4}
					sx={{ width: '100%' }}
					value={response}
					onChange={(e) => setResponse(e.target.value)}
					label='Response'
					placeholder='Please type your response here...'
				/>
				
				{
					(action?.assigned_to?.includes(claims?.user_id)
						|| action?.created_by_user?._id === claims?.user_id
					) && (
						<ActionCollaborationControls
							action={action}
							remaining_balance={remaining_balance}
							handleActionUpdate={handleActionUpdate}
							handleActionStatusUpdate={handleActionStatusUpdate}
							handleActionStatusCancelled={handleActionStatusCancelled}
						/>
					)
				}
			</Stack>
			
			
			<InstantNotificationPopup
				open={fireAlert}
				setOpen={setFireAlert}
				severity={'success'}
				message='Changes submitted successfully'
			/>
		</Box>
	)
};


export default ActionsCollaborationPanel;
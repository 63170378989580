import Badge from 'components/badges/Badge';
import Divider from '@mui/material/Divider';

import { IssueLog } from '../../interfaces/content_data_interfaces';

const cn: string = 'tags'


export interface ChatTagsProps
{
	_issue_Headline: IssueLog;
	_tagClicked?: (tagName: string) => void;
};


const ChatTags =
({
	_issue_Headline,
	_tagClicked
}
: ChatTagsProps) =>
{
	return (
		<div
			className={ cn+ '__wrapper' }
		>
			{
				(_issue_Headline !== undefined)
				&&
				<>
					<span
						className={ cn+ '__title' }
					>
						Main Tags
					</span>
					
					<Divider />
					
					<div>
					{
						_issue_Headline.body.main_system_tags?.map((tag, key) =>
							<div
								className={ cn+ '__tagesList' }
								key={ key }
							>
								<Badge
									title={ tag.title }
									status={ tag.status }
									tagClicked={ _tagClicked }
								/>
							</div>
						)
					}
					</div>
					
					<span
						className={ cn+ '__title' }
					>
						Other Tags
					</span>
					
					<Divider />
					
					<div>
					{
						_issue_Headline.body.other_parts_tags?.map((tag, key) =>
						(
							<div
								className={ cn+ '__tagesList' }
								key={ key }
							>
								<Badge
									title={ tag.title }
									status={ tag.status }
									tagClicked={ _tagClicked }
								/>
							</div>
						))
					}
					</div>
				</>
			}
		</div>
	)
}


export default ChatTags;
import Badge from 'components/badges/Badge';

const cn: string = 'requestingPO';


export interface RequestedPOProps
{
	_headline: string
	_status: string
};


const RequestedPO =
({
	_headline,
	_status,
}
: RequestedPOProps) =>
{
	return (
		<div
			className={ cn + '__tagContainer' }
		>
			
			<div
				className={ cn + '__tagWrapper' }
			>
				
				<div>
					Requested Purchase of Part:
				</div>
				
				<Badge
					title={ _headline }
					status={ _status }
				/>
			</div>
			
			<button
				className={ 'btn--green' }
			>
				Approve
			</button>
		</div>
	)
}


export default RequestedPO;
import NestedItem from '../nested_sidebar/NestedItem';
import { IssueLog } from '../../interfaces/content_data_interfaces';
import FileButton from 'components/fileButton/FileButton';


export interface ChatDocumentsProps
{
	_isssueLog: IssueLog[];
}


const ChatDocuments =
({
	_isssueLog,
}
: ChatDocumentsProps) =>
{
	return (
		<div>
			<NestedItem
				id_title='Documents'
				title='Documents'
				icon_name='files'
				color='eggplant'
			/>
			
			<div
				className='documents'
			>
				<div
					className='documents__fileList'
				>
					{
						_isssueLog.map((content) =>
						{
							if(content.body.file !== undefined)
							{
								return content.body.file.map((file, key) =>
									<div
										key={ key }
									>
										<FileButton
											_file={ file }
										/>
									</div>
								)
							}
							
							return null;
						})
					}
				</div>
			</div>
			
		</div>
	)
}


export default ChatDocuments;
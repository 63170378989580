

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebase_config =
{
	apiKey: "AIzaSyAJYpk0IfeSQqnMoWNIHPKoVt6v8uI0WQw",
	authDomain: "triton-47198.firebaseapp.com",
	projectId: "triton-47198",
	storageBucket: "triton-47198.appspot.com",
	messagingSenderId: "671605107995",
	appId: "1:671605107995:web:66cd9a8bbe8a4e77ee37be",
	measurementId: "G-ZWG354LV5B"
};


export default firebase_config;
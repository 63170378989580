import { Box, Button, Grid, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';


export interface PartsCountProps
{
	parts: PartWithSelectedCount[],
	setSelectedParts: (parts: PartWithSelectedCount[]) => void,
	onFinalSet: (parts: PartWithSelectedCount[]) => void,
}


export const PartsCount =
({
	parts,
	setSelectedParts,
	onFinalSet,
}: PartsCountProps) =>
{
	const handleDelete = (partId: string) =>
	{
		setSelectedParts(parts.filter((part) => part._id !== partId));
	};
	
	
	const handleIncrement = (partId: string) =>
	{
		setSelectedParts(
			parts.map((part) => {
				if (part._id !== partId) return part;
				return {
					...part,
					selected_count:
						part.selected_count >= part.count
							? part.count
							: part.selected_count + 1,
				};
			})
		);
	};
	
	
	const handleDecrement = (partId: string) =>
	{
		setSelectedParts(
			parts.map((part) => {
				if (part._id !== partId) return part;
				return {
					...part,
					selected_count:
						part.selected_count <= 0
							? part.count
							: part.selected_count - 1,
				};
			})
		);
	};
	
	
	return (
		<Box
			sx={{
				padding: '1rem',
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
				fontSize: '14px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={{
						fontWeight: '400',
						fontSize: '14px',
					}}
				>
					Please set quantities
				</Box>
				<Button
					sx={{
						backgroundColor: '#186ab4',
						color: 'white',
						cursor: 'pointer',
						padding: '5px',
						borderRadius: '5px',
						'&:hover': {
							backgroundColor: '#186ab4',
						},
					}}
					onClick={() =>
						onFinalSet(
							parts.filter((part) => part.selected_count > 0)
						)
					}
				>
					Confirm
				</Button>
			</Box>
			
			<Grid
				container
				columnGap={5}
				rowGap={2}
				gridTemplateColumns={'repeat(auto-fit, minmax(0, 1fr))'}
			>
				{parts.map((part) => (
					<Grid
						item
						sx={{
							border: '1px solid #d8dbdf',
							position: 'relative',
							padding: '0.5rem',
							display: 'flex',
							flexDirection: 'column',
							borderRadius: '5px',
						}}
						key={part._id}
					>
						<Box sx={{ color: '#323338' }}>{part.name}</Box>
						<Box sx={{ fontSize: '14px', color: '#666666' }}>
							Parts# {part.erp_ref_id}
						</Box>
						<Box sx={{ fontSize: '14px', color: '#666666' }}>
							Manufacturer {part.manufacturer_name}
						</Box>
						<Box sx={{ marginBlock: '0.5rem', marginLeft: 'auto' }}>
							Available{' '}
							<Box
								sx={{
									display: 'inline-block',
									color: '#3F8ED8',
								}}
							>
								{part.count}
							</Box>
						</Box>
						<Box
							sx={{
								marginTop: '1rem',
								fontSize: '12px',
								fontWeight: '300',
							}}
						>
							Quality needed
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '2rem',
							}}
						>
							<Box
								sx={{
									backgroundColor: '#c7e0ef',
									color: 'black',
									fontSize: '24px',
									borderRadius: '50%',
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									width: '30px',
									height: '30px',
								}}
								onClick={() => handleDecrement(part._id)}
							>
								-
							</Box>
							<Box sx={{ fontSize: '18px' }}>
								{part.selected_count}
							</Box>
							<Box
								sx={{
									backgroundColor: '#c7e0ef',
									color: 'black',
									fontSize: '24px',
									borderRadius: '50%',
									cursor: 'pointer',
									width: '30px',
									height: '30px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								onClick={() => handleIncrement(part._id)}
							>
								+
							</Box>
						</Box>
						<Box
							sx={{
								position: 'absolute',
								right: '0.25rem',
								bottom: '0.25rem',
								cursor: 'pointer',
							}}
							onClick={() => handleDelete(part._id)}
						>
							<StyledDeleteIcon />
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};


const StyledDeleteIcon = styled(DeleteIcon)(() => ({
	color: 'red',
}));


export default PartsCount;
import CostFormlet from 'app/gizmos/fields/costing/CostFormlet';




const RequisitionTotalEstimatedCost = () =>
{
	return (
		<CostFormlet
			name='estimated_cost'
			label='Total Estimated Cost'
			help='Tthe estimated value was calculated with an additional 15% tax, if incorrect, please change.'
		/>
	)
};


export default RequisitionTotalEstimatedCost;
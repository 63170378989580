import { CustomForm } from './../fields/field_interfaces';
import { Recurrence } from 'models/recurrence';
import { FormSection, PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';
import { UseFormReturn } from 'react-hook-form';
import { Tag, Issue, PartsNeeded, User } from 'types';
import { WorkOrderStatus, WorkOrderType } from './work_order_enums';
import { Role } from 'models/users/user_interfaces';
import { ComponentRecord } from 'models/component_records';
import { DateTime } from 'luxon';








export enum Currency
{
	CAD = 'CAD',
	USD = 'USD',
	EUR = 'EUR',
}


// TODO: Move/delete
// TODO: Handle estimate and actual one level higher rather than grouping together?
// Used for expressing specific costs or cost ranges
export interface Costing
{
	estimate?: Cost | CostRange,
	actual?: Cost,
}



// TODO: Allow strings? Always convert to numbers? Only convert before writing to DB?
export interface Cost
{
	// Ex: 12.34
	quantity?: number | string,
	
	// Ex: CAD, USD, EUR
	currency?: string,
}



// Used for expressing ranges
export interface CostRange
{
	lower_end: number,
	upper_end: number,
	
	// Ex: CAD, USD, EUR
	currency: string,
}






// The data structure used by the page/steppers to interact with the underlying form
export interface WorkOrderTemplateForm
{
	form: UseFormReturn<WOTFormData> | null,
	handleSubmitForm: Function,
	component_records?: ComponentRecord[],
}




// The data used by the form itself
export interface WOTFormData
{
	_id: string,
	title: string,
	description: string,
	
	status?: WorkOrderStatus,
	
	estimated_cost?: Cost | CostRange,
	
	work_order_type?: WorkOrderType | '',
	tags: Tag[],
	equipment: {
		_id: string,
		name: string,
	}[],
	asset: {
		_id: string,
		name: string,
	}
	parts: PartWithSelectedCount[],
	issues: Issue[],
	collaborators: User[],
	files: File[],
	roles: Role[],
	component_records?: ComponentRecord[],
	
	recurrence: Recurrence,
	custom_form: CustomForm,
	sections?: FormSection[],
	
	now: DateTime,
}



// The template written to the database, specifying how to generate work orders
export interface WorkOrderTemplate
{
	_id: string,
	title: string,
	description: string,
	
	division_id?: string,
	
	status: WorkOrderStatus,
	
	work_order_type?: WorkOrderType | '',
	work_order_template_num?: number,
	
	file_ids?: string[],
	
	// TODO: specific to WOTs
	collaborator_ids?: string[],
	component_ids?: string[],
	parts_needed?: PartsNeeded[],
	
	// TODO: specific to WOTs
	tag_ids?: string[],
	issue_ids?: string[],
	
	asset_id?: string,
	asset_name?: string,
	
	assigned_to_role_ids?: string[],
	
	
	created_at: string,
	updated_at?: string,
	creator: {
		_id: string,
		name: string,
		email: string,
	},
	
	recurrence: Recurrence,
	
	work_order_ids: string[],
	sections?: any,
	
	estimated_cost?: {
		currency: string,
		quantity: string,
	}
}




// The work order document
export interface WorkOrderNew
{
	_id: string,
	title: string,
	description: string,
	
	template_id?: string,
	
	division_id?: string,
	
	status: WorkOrderStatus,
	
	work_order_type?: WorkOrderType | '',
	work_order_num: number,
	
	file_ids: string[],
	
	collaborator_ids: string[],
	component_ids: string[],
	parts_needed: PartsNeeded[],
	tag_ids: string[],
	issue_ids: string[],
	
	asset_id?: string,
	asset_name?: string,
	
	assigned_to_role_ids?: string[],
	cost_center_ids?: string[],
	cost_code_ids?: string[],
	
	
	created_at: string,
	updated_at?: string,
	
	// When the work order is intended to be started/completed
	scheduled_for?: string,
	
	sections?: any,
	
	estimated_cost?: {
		currency: string,
		quantity: string,
	},
	
	actual_cost?: {
		currency: string,
		quantity: string,
	},
	
	filled_by_user_ids?: string[],
	
	completed_by_user: {
		_id: string,
		name: string,
		email: string,
	}
}















export interface WorkOrderField2
{
	
}








// export interface WorkOrder
// {
// 	_id: string,
// 	name: string,
// 	description?: string,
// 	details?: string[],
	
// 	work_order_num?: number,
	
	
// 	// Datetimes
// 	submitted_at: string,
// 	start_at?: string,
// 	end_at?: string,
	
	
// 	// Base 10?
// 	severity?: string,
// 	status?: string,
// 	tags?: string[],
	
// 	asset_id?: string,
// 	asset_name?: string,
	
// 	asset_ids?: string[],
// 	asset_names?: string[],
	
// 	system_ids?: string[],
// 	system_names?: string[],
	
// 	date_opened: string,
// 	date_closed?: string,
// 	due_date?: string,
	
// 	work_order_creator_id?: string,
// 	work_order_creator_email?: string,
// 	work_order_creator_name?: string,
	
// 	collaborator_ids?: string[],
// 	document_ids?: string[],
// 	open_ids?: string[],
// 	open_requisition_ids?: string[],
	
// 	// Base 10?
// 	priority?: number,
	
// 	form_ids?: string[],
// 	issue_ids?: string[],
	
// 	// TODO: Names?
// 	component_ids?: string[],
// 	component_names?: string[],
	
// 	assigned_user_ids?: string[],
// 	requisition_ids?: string[],
// 	message_ids?: string[],
// 	activity_log_ids?: string[],
// }



export interface WorkOrderOld
{
	_id: string,
	name: string,
	work_type?: string,
	
	asset_id: string,
	asset_name?: string,
	component_ids?: string[],
	
	
	form_ids?: string[],
	
	metadata_by_form_id?:
	{
		[form_id: string]: {
			name: string,
		},
	},
	
	created_by: string,
	created_at: string,
	
	collaborator_ids?: string[],
	
	frequency?: string,
	tag_ids?: string[],
}



// Links a set of related work orders
export interface WorkOrderSet
{
	_id: string,
	name: string,
	
	work_order_ids: string[],
}




import { CertificationFor, CertificationRecord, CertificationRecordStatus } from "models"
import { DateTime, duration2text, relativeDatetimeText, relativeDaysDiff, useState } from "stuff";
import { Chip, Typography } from "@mui/material";
import SortableTable from "../../issueCollaboration/issueDetails/issueTable/SortableTable";


import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import ShowCertificationRecordFiles from "./ShowCertificationRecordFiles";
import CreateEditCertificationRecord from "./CreateEditCertificationRecord";
import CertificationRecordsProvider, { useCertificationRecord } from "./CertificationRecordsProvider";
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';



const CertificationRecordsTable = () =>
{
	const [ closeDialog2, setCloseDialog2 ] = useState<boolean>(false);
	
	const { current_certification_records: certification_records, certification } = useCertificationRecord();
	
	
	return (
		<SortableTable
			options={certification_records}
			onSelect={(selectedOption) => {
				//navigate(`/work-orders/${selectedOption._id}`)
			}}
			rows={[
				{
					title: 'Status',
					getCell(certification_records) {
						
						return (
							<>
								{
									(certification_records?.status === CertificationRecordStatus.ACTIVE)
										? <Chip label={certification_records?.status} color='success'/>
										: <Chip label={certification_records?.status} />
								}
							</>
							
						)
					}
				},
				{
					title: 'Obtained Date',
					getCell(certification_record) {
						
						const datetime = DateTime.fromISO(certification_record?.obtained_date as string)
						console.log(datetime)
						
						return (
							<>
								{
									(datetime.isValid) ? <ConciseDateAndTime datetime={datetime} /> : (
										<Typography> -- </Typography>
									)
								}
							</>
							
						)
					}
				},
				
				{
					title: 'Expiry Date',
					getCell(certification_record) {
						
						const datetime = DateTime.fromISO(certification_record?.expiry_date as string)
						const days_diff = relativeDaysDiff(datetime);
						
						
						return (
							<>
								{
									(datetime.isValid) ? (
										<>
											<Typography
												sx={{
													background: (days_diff >= 60)
														? defaultAppTheme.palette.success.main
														: (days_diff <= 7 && days_diff > -150)
														? defaultAppTheme.palette.secondary.main
														: (days_diff > 7 && days_diff <= 30)
														? defaultAppTheme.palette.warning.main
														: (days_diff > 30 && days_diff < 60)
														? defaultAppTheme.palette.primary.main
														: defaultAppTheme.palette.grey['500'],
													padding: '4px',
													borderRadius: '4px',
													color: '#fff',
												}}
											>
												{relativeDatetimeText(datetime)}
											</Typography>
											
											<ConciseDateAndTime datetime={datetime} />
										</>
										) : (
										<Typography> -- </Typography>
									)
								}
							</>
						)
					}
				},
				
				{
					title: 'View Files',
					getCell(certification_record) {
						return (
							<ButtonToOpenDialog
								button_variant='text'
								dialog_title={`View Files`}
								button_content='View Files'
								dialog_content={
									<ShowCertificationRecordFiles
										certification_record={certification_record}
									setCloseDialog={setCloseDialog2}/>
								}
								closeDialog={closeDialog2}
								sx={{
									textTransform:'none',
									textDecoration: 'underline',
								}}
							/>
						)
					}
				},
				
				{
					title: 'Edit Record',
					getCell(certification_record) {
						return (
							<ButtonToOpenDialog
									button_variant='text'
									dialog_title={`Edit Record`}
									button_content='Edit Record'
									dialog_content={
										<CertificationRecordsProvider
											certification={certification}
											certification_record={certification_record}
										>
											<CreateEditCertificationRecord
												closeDialog={closeDialog2}  
												setCloseDialog={setCloseDialog2}
											/>
										</CertificationRecordsProvider>
									}
									closeDialog={closeDialog2}
									sx={{
										textTransform:'none',
										textDecoration: 'underline',
									}}
								/>
						)
					}
				}
			]}
		/>
	)
}


export default CertificationRecordsTable;
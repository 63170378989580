import daily_sensor1_data  from './data/AM02.BA1.DI-06__stats_by_DAY.json';
import daily_sensor2_data  from './data/GE1.AI-01__stats_by_DAY.json';
import daily_sensor3_data  from './data/GE1.AI-14__stats_by_DAY.json';
import daily_sensor4_data  from './data/GE2.AI-01__stats_by_DAY.json';
import daily_sensor5_data  from './data/GE2.AI-14__stats_by_DAY.json';
import daily_sensor6_data  from './data/MP1.AI-01__stats_by_DAY.json';
import daily_sensor7_data  from './data/MP1.AI-02__stats_by_DAY.json';
import daily_sensor8_data  from './data/MP1.AI-05__stats_by_DAY.json';
import daily_sensor9_data  from './data/MP2.AI-01__stats_by_DAY.json';
import daily_sensor10_data from './data/MP2.AI-02__stats_by_DAY.json';
import daily_sensor11_data from './data/MP2.AI-05__stats_by_DAY.json';
import daily_sensor12_data from './data/MP3.AI-01__stats_by_DAY.json';
import daily_sensor13_data from './data/MP3.AI-05__stats_by_DAY.json';
import daily_sensor14_data from './data/MP3.AI-02__stats_by_DAY.json';
import daily_sensor15_data from './data/MP4.AI-01__stats_by_DAY.json';
import daily_sensor16_data from './data/MP4.AI-02__stats_by_DAY.json';
import daily_sensor17_data from './data/MP4.AI-05__stats_by_DAY.json';

// import hourly_sensor1_data  from './data/AM02.BA1.DI-06__stats_by_HOUR.json';
// import hourly_sensor2_data  from './data/GE1.AI-01__stats_by_HOUR.json';
// import hourly_sensor3_data  from './data/GE1.AI-14__stats_by_HOUR.json';
// import hourly_sensor4_data  from './data/GE2.AI-01__stats_by_HOUR.json';
// import hourly_sensor5_data  from './data/GE2.AI-14__stats_by_HOUR.json';
// import hourly_sensor6_data  from './data/MP1.AI-01__stats_by_HOUR.json';
// import hourly_sensor7_data  from './data/MP1.AI-02__stats_by_HOUR.json';
// import hourly_sensor8_data  from './data/MP1.AI-05__stats_by_HOUR.json';
// import hourly_sensor9_data  from './data/MP2.AI-01__stats_by_HOUR.json';
// import hourly_sensor10_data from './data/MP2.AI-02__stats_by_HOUR.json';
// import hourly_sensor11_data from './data/MP2.AI-05__stats_by_HOUR.json';
// import hourly_sensor12_data from './data/MP3.AI-01__stats_by_HOUR.json';
// import hourly_sensor13_data from './data/MP3.AI-05__stats_by_HOUR.json';
// import hourly_sensor14_data from './data/MP3.AI-02__stats_by_HOUR.json';
// import hourly_sensor15_data from './data/MP4.AI-01__stats_by_HOUR.json';
// import hourly_sensor16_data from './data/MP4.AI-02__stats_by_HOUR.json';
// import hourly_sensor17_data from './data/MP4.AI-05__stats_by_HOUR.json';

import { doForObj, objFromArray } from 'utils';
import { selected_sensors } from './techsol_data';
import { TechhsolSensor } from './techsol_models';


interface JsonData
{
	// name: string;
	[key: string]: any,
}


interface JsonDataMap
{
	[key: string]: JsonData,
}


const daily_stats_by_sensor_position: JsonDataMap =
{
	'AM02.BA1.DI-06': daily_sensor1_data,
	'GE1.AI-14': daily_sensor2_data,
	'GE2.AI-01': daily_sensor3_data,
	'GE1.AI-01': daily_sensor4_data,
	'GE2.AI-14': daily_sensor5_data,
	'MP1.AI-01': daily_sensor6_data,
	'MP1.AI-02': daily_sensor7_data,
	'MP1.AI-05': daily_sensor8_data,
	'MP2.AI-01': daily_sensor9_data,
	'MP2.AI-02': daily_sensor10_data,
	'MP2.AI-05': daily_sensor11_data,
	'MP3.AI-01': daily_sensor12_data,
	'MP3.AI-02': daily_sensor13_data,
	'MP3.AI-05': daily_sensor14_data,
	'MP4.AI-01': daily_sensor15_data,
	'MP4.AI-02': daily_sensor16_data,
	'MP4.AI-05': daily_sensor17_data,
};

// const hourly_stats_by_sensor_position: JsonDataMap =
// {
// 	'AM02.BA1.DI-06': hourly_sensor1_data,
// 	'GE1.AI-14': hourly_sensor2_data,
// 	'GE2.AI-01': hourly_sensor3_data,
// 	'GE1.AI-01': hourly_sensor4_data,
// 	'GE2.AI-14': hourly_sensor5_data,
// 	'MP1.AI-01': hourly_sensor6_data,
// 	'MP1.AI-02': hourly_sensor7_data,
// 	'MP1.AI-05': hourly_sensor8_data,
// 	'MP2.AI-01': hourly_sensor9_data,
// 	'MP2.AI-02': hourly_sensor10_data,
// 	'MP2.AI-05': hourly_sensor11_data,
// 	'MP3.AI-01': hourly_sensor12_data,
// 	'MP3.AI-02': hourly_sensor13_data,
// 	'MP3.AI-05': hourly_sensor14_data,
// 	'MP4.AI-01': hourly_sensor15_data,
// 	'MP4.AI-02': hourly_sensor16_data,
// 	'MP4.AI-05': hourly_sensor17_data,
// };


let lookup = objFromArray(selected_sensors, 'signal_position') as {[key: string]: TechhsolSensor};


doForObj(lookup, (v: TechhsolSensor, k: string) => v.daily_stats = daily_stats_by_sensor_position[k]);

// doForObj(lookup, (v: TechhsolSensor, k: string) => v.hourly_stats = hourly_stats_by_sensor_position[k]);


export default lookup;
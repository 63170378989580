import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import { WorkOrderField } from 'pages/CreateWorkOrder/work_order_types';


export interface ReplaceProps
{
	field: WorkOrderField;
}


export const Replace = ({ field }: ReplaceProps) =>
{
	const {
		replace: { equipment },
	} = field;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '0.5rem',
				}}
			>
				Equipments:{' '}
				{equipment.map((equipment) => (
					<Box
						sx={{
							display: 'inline-block',
							backgroundColor: '#C85656',
							paddingBlock: '0.25rem',
							paddingInline: '0.5rem',
							borderRadius: '5px',
							color: 'white',
						}}
						key={equipment._id}
					>
						{equipment.name}
					</Box>
				))}
			</Box>
			<FormControl>
				<RadioGroup value=''>
					<FormControlLabel
						control={<Radio value='replaced' />}
						label='Replaced'
					/>
					<FormControlLabel
						control={<Radio value='ok' />}
						label='Ok'
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
};


export default Replace;
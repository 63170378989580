import { useState } from 'react';
import Box from '@mui/material/Box';

import { useStyles } from '../../styles/ManageUserTabTheme';
import InviteUsers from '../../users/InviteUsers';
import ManageRoles from './components/roles/ManageRoles';
import { Stack, Tab, Tabs } from '@mui/material';
import UsersList from './UsersList';
import { TabPanel } from '../settings_tablet_desktop/SettingsTabletDesktop';
import ManagePositions from './positions/ManagePositions';



const ManageUsersTab = () =>
{
	const classes = useStyles();
	
	const [ tabValue, setTabValue ] = useState<number>(0);
	
	
	
	const handleChange = (event: React.SyntheticEvent, newValue: number) =>
	{
		setTabValue(newValue);
	};
	
	
	return (
		<Box>
			<Tabs
				value={tabValue}
				onChange={handleChange}
			>
				<Tab label='Users' value={0} />
				<Tab label='Roles' value={1} />
			</Tabs>
			
			<TabPanel value={tabValue} index={0}>
				<Stack
					width='100%'
					direction='column'
					spacing={2}
					sx={{
						padding: '10px',
						maxWidth: '900px',
					}}
				>
					<Box
						component='form'
						sx={{ marginTop: '20px', width: '100%'}}
					>
						<InviteUsers />
					</Box>
					
					<Box
						className='container'
					>
						<UsersList />
					</Box>
				</Stack>
			</TabPanel>
			
			<TabPanel value={tabValue} index={1}>
				<Stack
					sx={{ width: '100%', padding: '10px' }}
					spacing={3}
				>
					<ManageRoles />
					
					<ManagePositions />
				</Stack>
			</TabPanel>
		</Box>
	)
}


export default ManageUsersTab;
import { StepperForm } from 'pages/CreateWorkOrder/work_order_types';
import { Control, useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, Divider, TextField } from '@mui/material';
import { Field } from './Field/Field';


export interface SectionProps
{
	control: Control<StepperForm>;
	index: number;
}


export const Section =
({
	control,
	index: sectionIndex
}
: SectionProps) =>
{
	const {
		fields: fieldsList,
		append,
		remove,
		move,
	} = useFieldArray({
		control,
		name: `sections.${sectionIndex}.fields` as `sections.0.fields`,
	});
	
	
	// useEffect(() => {
	// 	if (fieldsList.length === 0)
	// 	{
	// 		append({});
	// 	}
	// }, [append, fieldsList.length]);
	
	
	return (
		<Box
			sx={{
				backgroundColor: 'white',
				border: '1px solid #d8dbdf',
				padding: '1rem',
			}}
		>
			<Box mx={{ marginBottom: '1rem' }}>Section #{sectionIndex + 1}</Box>
			<Box
				sx={{
					marginLeft: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
				}}
			>
				<Box>
					<Controller
						control={control}
						name={`sections.${sectionIndex}.name` as const}
						defaultValue=''
						rules={{
							// required: true,
						}}
						render={({ field }) => {
							return (
								<TextField
									// required={true}
									label='Enter Section Name'
									value={field.value}
									onChange={field.onChange}
									variant='standard'
									fullWidth={true}
								/>
							);
						}}
					/>
				</Box>
				
				<Box>
					<Controller
						control={control}
						name={`sections.${sectionIndex}.description` as const}
						defaultValue=''
						render={({ field: { value, onChange } }) => {
							return (
								<TextField
									label='Section Details or Instructions'
									value={value}
									onChange={onChange}
									fullWidth={true}
									multiline
									rows={4}
								/>
							);
						}}
					/>
				</Box>
				
				{fieldsList.map((field, i) => (
					<Field
						key={field.id || i}
						control={control}
						fieldIndex={i}
						sectionIndex={sectionIndex}
						remove={remove}
						move={move}
					/>
				))}
				
				<Divider />
				
				<Button
					onClick={() => append({})}
					variant='text'
				>
					Add Field
				</Button>
				
			</Box>
		</Box>
	);
};


export default Section;
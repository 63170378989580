import {
	DateTime,
	saveToLocalStorage, deleteFromLocalStorage,
	doForObj, isLiteralObject,
	fuego,
} from 'stuff';

//import { default as _ } from 'lodash';

import { thingys2things } from '../classes/store_utils';


//import { thingys2things } from '../classes/store_utils';

//import debounce from 'lodash';


const LOG = false;


const reducer = (state, action) =>
{
	// Shallow copy
	state = Object.assign({}, state);
	
	
	if(isLiteralObject(action) && action.type)
	{
		if(Array.isArray(action.payload))
		{
			action.payload.forEach(payload_item => {
				state = handleAction(state, {type: action.type, payload: payload_item});
			})
		}
		else
		{
			return handleAction(state, action);
		}
		return state;
	}
	// TODO: Check for array of actions?
	// Handle a single action written in shorthand
	else
	{
		// There should only be one key
		// Ex: {UPSERT_THINGY: Thingy1} or {UPSERT_THINGYS: [Thingy1, Thingy2]}
		let actual_type    = Object.keys(action)[0];
		let actual_payload = Object.values(action)[0];
		
		//console.log('actual_payload', actual_payload);
		
		if(Array.isArray(actual_payload))
		{
			actual_payload.forEach(payload_item => {
				state = handleAction(state, {type: actual_type, payload: payload_item});
			})
		}
		else
		{
			return handleAction(state, {type: actual_type, payload: actual_payload});
		}
		return state;
	}
}








// const saveToLocal = (state) =>
// {
// 	let fresh_datetime = DateTime.local();
	
// 	console.log('=================== Delayed save to local storage');
	
// 	if(state.last_change < fresh_datetime)
// 	{
// 		// TODO: This causes remote updates to not be applied to state 50% of the time
// 		//saveToLocalStorage('things', thingys2things(state.thingys));
// 	}
// }




// This isn't working
// const debouncedSaveToLocalStorage = () => _.debounce(
// 	saveToLocal,
// 	300
// )

//console.log('============----------');
//console.log(_.debounce)


// _.debounce(function(){
// 	saveToLocal(20, 30);
// }, 300);








const handleAction = (state, action) =>
{
	// Shallow copy
	state = Object.assign({}, state);
	
	
	if(LOG) console.log('Handling', action);
	
	// TODO - Any special shorthand handling?
	
	
	
	let type    = action.type;
	let payload = action.payload;
	
	
	
	if(type === 'REMOTE_UPDATE')
	{
		if(Array.isArray(action.payload))
		{
			//console.log('Multiple updates');
			
			for(let thingy of action.payload)
			{
				//console.log('    ', thingy);
				
				//if(state.all[thingy._id])
				
				state.write(thingy);
				
				if(LOG) console.log(state)
			}
		}
		else
		{
			//console.log('Single update');
			
			state.write(action.payload);
		}
		
	}
	else if(type === 'WRITE')
	{
		state.write(action.payload);
		
		// Write to the cloud
		fuego.writeThingy(action.payload);
		
		if(LOG) console.log(state)
	}
	else if(type === 'DELETE')
	{
		let thingy = action.payload;
		let possible_preexisting = state.all[thingy._id];
		let collection = state.thingys[thingy.entity.plural];
		
		// If the thingy already exists, replace it (maintaining array order)
		if(possible_preexisting)
		{
			let index = collection.findIndex(x => x._id === thingy._id);
			
			collection.splice(index, 1);
		}
		else
		{
			console.warn('Deleting thingy that is not in state', thingy);
		}
		
		delete state.all[thingy._id];
	}
	else if(type === 'LOGIN')
	{
		state.auth = payload;
		state.user = payload;
		
		// TODO: Probably don't need the whole object - may need to refresh token later
		saveToLocalStorage('user', state.user.claims);
	}
	else if(type === 'LOGOUT')
	{
		delete state.auth;
		delete state.user;
		
		doForObj(state.thingys, collection_name => delete state.thingys[collection_name]);
		
		deleteFromLocalStorage('ceto_token');
		deleteFromLocalStorage('user');
		deleteFromLocalStorage('things');
		
		
		
		
		// TODO: Cancel fuego watchers?
		
		console.log(fuego);
		
		// TODO: Does this work as intended?
		Object.values(fuego?.connections_by_name)?.forEach(connection => {
			connection.unsub();
			connection.is_watching = false;
		})
	}
	
	
	
	if(type !== 'LOGOUT')
	{
		saveToLocalStorage('things', thingys2things(state.thingys));
	}
	
	
	//console.log(state);
	
	
	state.last_change = DateTime.local();
	
	
	return state;
}






export default reducer;
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { WorkOrderResponseForm } from 'types/forms';
import { Field } from './Field/Field';
import { SectionContainer, SectionHeader } from './styles';



export interface SectionProps
{
	index: number;
}


export const WorkOrderSection = ({ index: sectionIndex }: SectionProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	const fields = useWatch({
		control,
		name: `form_sections.${sectionIndex}.fields`,
	});
	
	
	return (
		<SectionContainer>
			
			<SectionHeader>
				<Controller
					control={control}
					name={`form_sections.${sectionIndex}.label`}
					render={({ field: { value } }) => <div>{value}</div>}
				/>
			</SectionHeader>
			
			{fields?.map((_, index) => (
				<Field
					key={index}
					sectionIndex={sectionIndex}
					fieldIndex={index}
				/>
			))}
			
		</SectionContainer>
	);
};


export default WorkOrderSection;
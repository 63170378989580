import { React, useState, NavLink } from 'stuff';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import CorIcon from 'components/icons/CorIcon';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';



const ForgotPasswordPage = () =>
{
	const auth = getAuth();
	// local state for managing inout fields
	const [ email, setEmail ] = useState('');
	
	const [ open, setOpen ] = useState<boolean>(false);
	
	
	// test login function
	const resetPassword = (email) => {
		
		sendPasswordResetEmail(auth, email)
			.then(() => {
				setOpen(true);
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.error('Failed to sign in', errorCode, errorMessage);
			})
	};
	
	
	
	
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) =>
	{
		if (reason === 'clickaway')
		{
			return;
		}
	
		setOpen(false);
	};
	
	
	
	
	
	return (
		<Box className='login_page'>
			
			<Box className='session'>
				
				<img
					src={'/images/logo_curved_border.png'}
					alt='CORSphere Logo'
					className='login_page_logo'
				/>
				
				
				<div className='company_header'>
					<Typography
						variant='h2'
						component='h2'
					>
						CORSphere
					</Typography>
					<Typography 
						variant='h3'
						component='h3'
					>
						Industrial Interconnectivity,
					</Typography>
					<Typography
						variant='h3'
						component='p'
					>
						made simple.
					</Typography>
				</div>
				
				
				<form
					autoComplete='off'
					onSubmit={(e) => {
						resetPassword(email);
						e.preventDefault();
					}}
				>
					
					<Typography
						variant='h2'
						component='h2'
					>
						Forgot Password
					</Typography>
					
					<TextField
						variant='outlined'
						label='Email'
						type='email'
						name='email'
						id='email'
						className='input_field'
						autoComplete='off'
						placeholder='Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						InputProps={{
							startAdornment:
							<InputAdornment position="start">
								<CorIcon of='email' className='login_page-icon'/>
							</InputAdornment>
						}}
					/>
					
					<Button type='submit' variant='contained'> Confirm </Button>
					
					<NavLink to='/login' className='discrete'>
						Return to Login
					</NavLink>
				</form>
				
				
				<img
					src={'/images/corsbot_final_blue_ver2.png'}
					alt='CORSBot'
					className='login_page_avatar'
				/>
				
				
				<div className='get-started'>
					<Typography
						variant='h2'
						component='h2'
					>
						Let's get started! 🚀
					</Typography>
				</div>
				
			</Box>
			
			
			<Snackbar 
				open={open}
				autoHideDuration={3000} 
				onClose={handleClose}
				className='alert'
			>
				<Alert
					onClose={handleClose} 
					severity="success" 
					sx={{
						width: '100%',
					}}
				>
					Please check your Email.
				</Alert>
			</Snackbar>
			
		</Box>
	)
}


export default ForgotPasswordPage;
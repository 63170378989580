import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StyledEngineProvider } from '@mui/material/styles';


// Enable to annoy developers into doing things right
// May cause double renders and logging
const STRICT_MODE = false;


// This may or may not wrap our App
const PossiblyStrict = ({ children }) =>
{
	if(STRICT_MODE)
		return <React.StrictMode>
			{children}
		</React.StrictMode>;
	else
		return <>{children}</>;
}


ReactDOM.render(
	<StyledEngineProvider injectFirst>
		<PossiblyStrict>
			<App />
		</PossiblyStrict>
	</StyledEngineProvider>
	,
	document.getElementById('root')
);


import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useSession } from 'app/providers/SessionProvider';
import { MultiSelectTable } from 'pages/CreateWorkOrder/GeneralDescription/Issues/MultiSelectTable/MultiSelectTable';
import { PartWithSelectedCount } from 'pages/CreateWorkOrder/work_order_types';
import CorIcon from 'components/icons/CorIcon';


export interface PartsTableProps
{
	selectedParts: PartWithSelectedCount[];
	onSelect: (parts: PartWithSelectedCount[]) => void;
	showCountDisplay: () => void;
}


export const PartsTable =
({
	onSelect,
	selectedParts,
	showCountDisplay,
}: PartsTableProps) =>
{
	const { parts } = useSession();
	
	
	if (parts.length === 0)
		return (
			<Box
				sx={{
					width: '100%',
					padding: '2rem',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	
	
	return (
		<MultiSelectTable
			options={parts.map((parts) => ({ ...parts, selected_count: 0 }))}
			onSelect={onSelect}
			rows={[
				{
					title: '',
					getCell: (_, isSelected) => (
						!isSelected ? (
							<CorIcon of='empty_circle' size='20px' />
						) : (
							<CorIcon of='circle_checked' size='20px' color='#74b70a' />
						)
					),
				},
				{
					title: 'Name',
					getCell: (part) => (
						<Box sx={{ fontWeight: 'normal' }}>{part.name}</Box>
						),
				},
				{
					title: (
						<Box sx={{ fontWeight: '700', color: '#3F8ED8' }}>
							Available Quantity
						</Box>
					),
					getCell: (part) => (
						<Box sx={{ color: '#3F8ED8' }}>{part.count}</Box>
					),
				},
				{
					title: 'Part#',
					getCell: (part) => (
						<Box sx={{ fontWeight: 'normal' }}>
							{part.erp_ref_id}
						</Box>
					),
				},
				{
					title: 'Manufacturer',
					getCell: (part) => (
						<Box sx={{ fontWeight: 'normal' }}>
							{part.manufacturer_name}
						</Box>
					),
				},
			]}
			tableDescription={
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<Button
						sx={{
							backgroundColor: '#186ab4',
							color: 'white',
							cursor: 'pointer',
							padding: '5px',
							borderRadius: '5px',
							'&:hover': {
								backgroundColor: '#186ab4',
							},
							textTransform: 'none',
						}}
						onClick={() => showCountDisplay()}
						endIcon={<CorIcon of='arrow_right' size='18px' />}
					>
						Set Quantity
					</Button>
				</Box>
			}
			skipFilterOnSelect={true}
			initialSelectedOptions={selectedParts}
		/>
	);
};


export default PartsTable;
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';

import { useStyles } from '../../styles/MobileHeaderTheme';
import { MobileHeaderProps } from '../../interfaces/settings_Interfaces';


const MobileHeader = ({ title, variantType }: MobileHeaderProps) =>
{
	const classes = useStyles();
	
	const navigate = useNavigate();
	
	
	return (
		<>
			<Box
				className={classes.MobileHeaderContainer}
			>
				<IconButton
					color='primary'
					onClick={() => navigate(-1)}
				>
					<NavigateBeforeIcon
						className={classes.IconSize}
					/>
				</IconButton>
				
				<Typography
					variant={variantType as any}
					color='#323338'
				>
					{ title }
				</Typography>
				
				
				{
					(title === 'Manage Tags')
					?
						<IconButton
							color='primary'
							onClick={() => navigate('/settings/tags/create')}
						>
							<AddIcon
								className={classes.IconSize}
							/>
						</IconButton>
					:
						<>
							{/*  Only exist to justify content symmetrically */}
							<Box
								className={classes.HiddenArrow}
							>
								<IconButton
									disabled
								>
									<AddIcon
										className={classes.IconSize}
									/>
								</IconButton>
							</Box>
						</>
				}
			</Box>
		</>
	)
}


export default MobileHeader;
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useRequisition } from '../../RequisitionDataProvider';
import UserAvatar from 'components/avatars/UserAvatar';
import RequisitionChargeToAccount from '../../RequisitionChargeToAccount';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';


const RequisitionCollabPrimaryUsers = () =>
{
	const form = useFormContext();
	
	const { requisition, users, updateChargeAccounts } = useRequisition();
	
	const created_by_user = users.filter(x => x._id === requisition?.created_by_user._id)[0];
	const account_to_charge_users = users.filter(x => requisition?.accounts_to_charge_ids?.includes(x._id));
	
	
	useEffect(() =>
	{
		form.setValue(
			'charge_accounts',
			account_to_charge_users
		)
	}, [requisition.accounts_to_charge_ids])
	
	
	
	return (
		<Grid
			item
			xs={12}
			sm={3}
		>
			<Stack
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='center'
			>
				<Typography
					variant='h6'
				>
					Primary Collaborators
				</Typography>
				
				<Stack
					direction='row'
					justifyContent='center'
					alignItems='center'
					sx={{
						margin:'10px',
					}}
				>
					<UserAvatar user={created_by_user} />
					
					<Stack
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						sx={{
							marginLeft: '5px',
						}}
					>
						<Typography
							variant='body1'
						>
							Requested by
						</Typography>
						<Typography
							variant='caption'
						>
							{ created_by_user?.display_name }
						</Typography>
					</Stack>
				</Stack>
				
				{
					account_to_charge_users.map(user => (
						<Stack
							direction='row'
							justifyContent='center'
							alignItems='center'
							sx={{
								margin:'10px',
							}}
							key={user._id}
						>
							<UserAvatar user={user} />
							
							<Stack
								direction='column'
								justifyContent='center'
								alignItems='flex-start'
								sx={{
									marginLeft: '5px',
								}}
							>
								<Typography
									variant='body1'
								>
									Accounts charged to
								</Typography>
								<Typography
									variant='caption'
								>
									{ user?.display_name }
								</Typography>
							</Stack>
						</Stack>
					))
				}
			</Stack>
			
			<Stack
				flexDirection='row'
				alignItems='center'
				justifyContent='flex-end'
			>
				<ButtonToOpenDialog
					button_variant='text'
					button_content='Edit'
					dialog_title={`Add or Remove Cost Centers`}
					dialog_dismiss_text='Discard'
					dialog_submit_text='Submit Changes'
					dialog_content={
						<RequisitionChargeToAccount />
					}
					sx={{
						textTransform:'none',
						textDecoration: 'underline',
					}}
					handleSubmit={updateChargeAccounts}
					dialogClassName='add_tags_dialog'
				/>
			</Stack>
		</Grid>
	)
};


export default RequisitionCollabPrimaryUsers;
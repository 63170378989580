import React from 'react'

import { useState } from 'react';
import { useTitle } from 'stuff';

import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import { useWOTCollabData } from './WOTCollabProvider';

import WOTDetailsTab from './WOTemplateDetails/WOTDetailsTab'
import WOTCollabSchedule from './WOTCollabSchedule';
import WOTCollabWorkSteps from './WOTCollabWorkSteps';


const tab_titles = ['Details', 'Schedule', 'Work Steps'];



const getTabNumberFromSearchParam = (tab: string | undefined) =>
{
	switch (tab)
	{
		case 'details':
			return 1;
		case 'schedule':
			return 2;
		case 'work_steps':
			return 3;
		default:
			return 1;
	}
};



const WOCollaborationTabs = () => 
{
	const { search } = useLocation();
	
	const { wo_template: workorder_template } = useWOTCollabData();
	
	
	const tab = getTabNumberFromSearchParam(
		new URLSearchParams(search).get('tab')
	);
	
	
	const [selected_tab, setSelectedTab] = useState(`${tab}`);
	
	
	useTitle(
		`Work Order Template #${workorder_template.work_order_template_num} - ${workorder_template.title} - ${
			tab_titles[parseInt(selected_tab) - 1]
		}`
	);
	
	
	
	const handleChange = (event: React.SyntheticEvent, newValue: string) =>
	{
		setSelectedTab(newValue);
		
		const url = new URL(document.location.href)
		
		switch(newValue)
		{
			case '1':
				url.searchParams.set('tab', 'details');
				break;
			case '2':
				url.searchParams.set('tab', 'schedule');
				break;
			case '3':
				url.searchParams.set('tab', 'work_steps');
				break;
			default:
				url.searchParams.set('tab', 'details');
				break;
		}
		
		window.history.pushState({}, document.title, url.toString());
	};
	
	
	
	return (
		<TabContext value={selected_tab}>
			
			<Box
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
				}}
				className='issue_collab_tabs'
			>
				<TabList
					onChange={handleChange}
					className='issue_collab_tabs_list'
				>
					<Tab label={tab_titles[0]} value={'1'} />
					<Tab label={tab_titles[1]} value={'2'} />
					<Tab label={tab_titles[2]} value={'3'} />
				</TabList>
			</Box>
			
			<TabPanel
				value='1'
				className='issue_collab_tab_panel'
			>
				<WOTDetailsTab />
			</TabPanel>
			
			<TabPanel
				value='2'
				className='issue_collab_tab_panel'
			>
				<WOTCollabSchedule />
			</TabPanel>
			
			<TabPanel
				value='3'
				className='issue_collab_tab_panel'
			>
				<WOTCollabWorkSteps />
			</TabPanel>
			
		</TabContext>
	)
};


export default WOCollaborationTabs;

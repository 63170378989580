import { DateTime } from 'luxon';
import { useCalendar } from './CalendarProvider';
import DatePickerWidget from 'app/gizmos/fields/datetimes/DatePickerWidget';


const CalendarDate = () =>
{
	const { selected_datetime, onDaySelected } = useCalendar();
	
	
	return <div
		className='calendar3_date'
	>
		<DatePickerWidget
			value={selected_datetime}
			onChange={(datetime: DateTime) => {
				if(datetime.isValid)
					onDaySelected(datetime)
			}}
		/>
	</div>
}


export default CalendarDate;
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';

import { Box, Typography, Stack, Grid } from '@mui/material';

import SortableTable from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/SortableTable';
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { WorkOrderTemplate } from 'models';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';

import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import { duration2text } from 'utils/datetime_utils_new';
import ConciseDateAndTime from 'app/gizmos/fields/datetimes/ConciseDateAndTime';
import { describeRecurrence } from 'models/recurrence/recurrence_helpers2';


const WorkOrderTemplatesTable =
({
	work_order_templates,
	title,
}: {
	work_order_templates: WorkOrderTemplate[],
	title?: string,
}
) =>
{
	const navigate = useNavigate();
	


	return(
		<Box>
			<Typography
				variant='h6'
			>
				{title}
			</Typography>
			
			<SortableTable
				options={work_order_templates}
				onSelect={(selectedOption) => {
					navigate(`/work-order-templates/${selectedOption._id}`)
				}}
				rows={[
					{
						title: 'WOT #',
						getCell(work_order_template)
						{
							return (
								<Box sx={{ marginInline: '0.5rem' }}>
									{work_order_template?.work_order_template_num || ''}
								</Box>
							);
						},
					},
					{
						title: 'Status',
						getCell(work_order_template)
						{
							const { status } = work_order_template;
							
							return (
								<Stack
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
								>
									<CorIcon
										of='active'
										size='24px'
										color={defaultAppTheme.palette.success.dark}
									/>
									
									<Typography
										variant='caption'
										color={defaultAppTheme.palette.success.dark}
										sx={{
											paddingLeft: '5px',
										}}
									>
										{ status.toUpperCase() || 'OPEN' }
									</Typography>
								</Stack>
							)
						},
					},
					{
						title: 'Title',
						getCell(work_order_template)
						{
							return <Typography
							variant='subtitle1'
							sx={{
								inlineSize: '250px',
								overflowWrap: 'break-word',
							}}
						>
							{ work_order_template?.title }
						</Typography>
						},
					},
					{
						title: 'Asset',
						getCell(work_order_template)
						{
							return (
								<Grid
									container
									width='100%'
									sx={{
										marginTop: '5px'
									}}
								>
									<RelatedAssetAndComponents component_ids={work_order_template.component_ids}
										division_id={work_order_template.division_id}
									/>
								</Grid>
							)
						},
					},
					{
						title: 'Schedule',
						getCell(work_order_template) {
							return (
								<Typography>
									{ describeRecurrence(work_order_template.recurrence) }
								</Typography>
							)
						}
					},
					{
						title: 'Created',
						getCell(work_order_template) {
							return (
								<ConciseDateAndTime
									datetime={work_order_template?.created_at}
								/>
							)
						}
					},
					{
						title: 'Last Automation',
						getCell(work_order_template) {
							
							if(!work_order_template?.recurrence?.last_automation_datetime)
							{
								return null;
							}
							
							
							// console.log(work_order_template?.recurrence)
							
							
							return (
								<Stack>
									<ConciseDateAndTime
										datetime={work_order_template?.recurrence?.last_automation_datetime}
									/>
									<Typography
										sx={{
											opacity: .4,
										}}
									>
										({
											duration2text(DateTime.fromISO(work_order_template?.recurrence?.last_automation_datetime as string).diffNow())
										})
									</Typography>
								</Stack>
							)
						}
					},
					{
						title: 'Next Automation',
						getCell(work_order_template) {
							
							if(!work_order_template?.recurrence?.next_automation_datetime)
							{
								return null;
							}
							
							
							// console.log(work_order_template?.recurrence)
							
							
							return (
								<Stack>
									<ConciseDateAndTime
										datetime={work_order_template?.recurrence?.next_automation_datetime}
									/>
									<Typography
										sx={{
											opacity: .4,
										}}
									>
										({
											duration2text(DateTime.fromISO(work_order_template?.recurrence?.next_automation_datetime as string).diffNow())
										})
									</Typography>
								</Stack>
							)
						}
					},
					{
						title: 'Count',
						getCell(work_order_template) {
							return (
								<Typography>
									{ work_order_template?.work_order_ids?.length }
								</Typography>
							)
						}
					},
				]}
			/>
		</Box>
	)
};


export default WorkOrderTemplatesTable;
import { Stack, Typography } from '@mui/material';
import { getNumberSuffix } from 'utils';


const DayOfMonth = ({ day_num }) =>
{
	return (
		<Stack
			direction='row'
		>
			{day_num - 1}
			<Typography
				sx={{
					opacity: .7,
					fontSize: '12px',
					alignSelf: 'start',
					marginTop: '-4px',
				}}
			>
				{getNumberSuffix(day_num - 1)}
			</Typography>
		</Stack>
	)
}


export default DayOfMonth;
import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const FieldContainer = styled(Box)(() => ({
	paddingTop: '1rem',
	paddingBottom: '3rem',
	borderTop: `1px solid #3F8ED8`,
	paddingInline: '1rem',
	display: `flex`,
	flexDirection: `column`,
	gap: `1rem`
}));

export const FieldDescription = styled(Box)(() => ({
	backgroundColor: `#efefef`,
	borderRadius: `5px`,
	paddingInline: `1rem`,
	paddingBlock: `0.5rem`
}));
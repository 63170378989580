import { Grid, Stack } from '@mui/material'
import CorsWorkOrdersSummary from './CorsWorkOrdersSummary'
import CorsIssuesSummary from './CorsIssuesSummary'
import CorsRequisitionsSummary from './CorsRequisitionsSummary'
import CorsActionsSummary from './CorsActionsSummary'
import CorsAnalyticsStateProvider from './CorsAnalyticsStateProvider'
import { useCorsAnalyticsLoader } from './CorsAnalyticsLoader'
import CorsAnalyticsSearchParameters from './CorsAnalyticsSearchParameters'
import CorsAssetCertificationsSummary from './CorsAssetCertificationsSummary'
import CorsUserCertificationsSummary from './CorsUserCertificationsSummary'



const CorsAnalyticsGridArea = () =>
{
	const { issues, work_orders, requisitions, actions, certifications } = useCorsAnalyticsLoader();
	
	
	return (
		<CorsAnalyticsStateProvider
			issues={issues}
			work_orders={work_orders}
			requisitions={requisitions}
			actions={actions}
			certifications={certifications}
		>
			<Stack
				width='100%'
				justifyContent='center'
				alignItems='center'
				gap={2}
			>
				<CorsAnalyticsSearchParameters />
				
				<Grid
					container
					width='100%'
					justifyContent='space-around'
					gap={2}
				>
					
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
					>
						<CorsWorkOrdersSummary />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
					>
						<CorsIssuesSummary />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
					>
						<CorsRequisitionsSummary />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
					>
						<CorsActionsSummary />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
					>
						<CorsAssetCertificationsSummary />
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={5}
					>
						<CorsUserCertificationsSummary />
					</Grid>
					
					
				</Grid>
			</Stack>
		</CorsAnalyticsStateProvider>
	)
}


export default CorsAnalyticsGridArea;
import { useState, ReactNode } from 'react';

import { Box, InputLabel, TextField, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, {
	tableCellClasses,
	TableCellProps,
} from '@mui/material/TableCell';


interface MultiSelectTableProps<T extends OptionBase>
{
	options: T[];
	rows: Row<T>[];
	onSelect: (options: T[]) => void;
	selectedOptionComponent?: SelectedOptionComponent<T>;
	initialSelectedOptions?: T[];
	tableDescription?: string | ReactNode;
	selectedOptionsLabel?: string;
	skipFilterOnSelect?: boolean;
}


type SelectedOptionComponent<T extends OptionBase> =
(
	option: T,
	removeSelection: (option: T) => void,
	selectedOptions: T[],
	index: number
) => ReactNode;


interface Row<T extends OptionBase>
{
	title: string | ReactNode;
	getCell: (option: T, isSelected: boolean) => string | ReactNode;
	align?: TableCellProps['align'];
}


interface OptionBase
{
	_id: string;
}


const getFilteredOptions = <T extends OptionBase>(
	options: T[],
	searchText: string
): T[] =>
{
	if (!searchText) return options;
	
	return options.filter((option) =>
		JSON.stringify(option).toLowerCase().includes(searchText)
	);
};


const OPTIONS_PER_PAGE = 6;


export const MultiSelectTable = <T extends OptionBase>({
	options,
	rows,
	selectedOptionComponent,
	onSelect,
	tableDescription,
	selectedOptionsLabel,
	skipFilterOnSelect,
	initialSelectedOptions,
}: MultiSelectTableProps<T>) =>
{
	const [searchText, setSearchText] = useState('');
	
	const filteredOptions = getFilteredOptions(options, searchText);
	
	const [selectedOptions, setSelectedOptions] = useState<T[]>(
		initialSelectedOptions ?? []
	);
	
	const [page, setPage] = useState(1);
	
	
	const startIndex = (page - 1) * OPTIONS_PER_PAGE;
	

	const onOptionSelect = (option: T) =>
	{
		if (selectedOptions.find((_option) => _option._id === option._id))
		{
			setSelectedOptions((state) =>
				state.filter((_option) => _option._id !== option._id)
			);
			return;
		}
		
		const newState = [...selectedOptions, option];
		setSelectedOptions(newState);
		!selectedOptionComponent && onSelect(newState);
	};
	
	const onOptionUnSelect = (option: T) =>
	{
		setSelectedOptions((state) =>
			state.filter((_option) => _option._id !== option._id)
		);
	};
	
	const isOptionSelected = (option: T) =>
		!!selectedOptions.find((_option) => _option._id === option._id);
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '1rem',
					width: '100%',
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
					}}
				>
					{tableDescription && (
						<InputLabel sx={{ width: '100%' }}>
							{tableDescription}
						</InputLabel>
					)}
					<TextField
						placeholder='Type to search'
						value={searchText}
						fullWidth={true}
						onChange={(e) => {
							setSearchText(e.target.value);
							setPage(1);
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<OptionsTable
					options={filteredOptions.slice(
						startIndex,
						startIndex + OPTIONS_PER_PAGE
					)}
					onOptionClick={onOptionSelect}
					isSelected={isOptionSelected}
					rows={rows}
					skipFilterOnSelect={skipFilterOnSelect}
				/>
				<Pagination
					page={page}
					count={Math.ceil(filteredOptions.length / OPTIONS_PER_PAGE)}
					onChange={(_e, page) => {
						setPage(page);
					}}
				/>
			</Box>
			{selectedOptions.length > 0 && selectedOptionComponent && (
				<Box
					sx={{
						padding: '2rem',
						borderLeft: '1px solid #d8dbdf',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{selectedOptionsLabel && (
							<Box sx={{ color: '#666666' }}>
								{selectedOptionsLabel}
							</Box>
						)}
						<Box
							sx={{
								backgroundColor: '#186ab4',
								color: 'white',
								cursor: 'pointer',
								padding: '1rem',
								borderRadius: '5px',
							}}
							onClick={() => onSelect(selectedOptions)}
						>
							Select
						</Box>
					</Box>
					<Box
						sx={{
							marginTop: '2rem',
						}}
					>
						<SelectedOptions
							selectedOptions={selectedOptions}
							removeSelection={onOptionUnSelect}
							selectedOptionComponent={selectedOptionComponent}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};


interface OptionsTableProps<T extends OptionBase>
{
	options: T[];
	isSelected: (option: T) => boolean;
	onOptionClick: (option: T) => void;
	rows: Row<T>[];
	skipFilterOnSelect?: MultiSelectTableProps<T>['skipFilterOnSelect'];
}


const OptionsTable = <T extends OptionBase>({
	options,
	onOptionClick,
	isSelected,
	rows,
	skipFilterOnSelect,
}: OptionsTableProps<T>) =>
{
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{rows.map((row, index) => {
							return (
								<StyledTableCell key={index}>
									{row.title}
								</StyledTableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{options
						.filter((option) =>
							skipFilterOnSelect ? true : !isSelected(option)
						)
						.map((option) => {
							return (
								<StyledTableRow
									key={option._id}
									onClick={() => onOptionClick(option)}
								>
									{rows.map((row) => (
										<StyledTableCell
											component='th'
											scope='row'
											align={row.align}
											key={option._id + row.title}
										>
											{row.getCell(
												option,
												isSelected(option)
											)}
										</StyledTableCell>
									))}
								</StyledTableRow>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};


interface SelectedOptionsProps<T extends OptionBase>
{
	selectedOptions: T[];
	removeSelection: (option: T) => void;
	selectedOptionComponent: SelectedOptionComponent<T>;
}


const SelectedOptions = <T extends OptionBase>({
	selectedOptions,
	removeSelection,
	selectedOptionComponent,
}: SelectedOptionsProps<T>) =>
{
	if (selectedOptions.length === 0)
		return null;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				border: `1px solid #d8dbdf`,
				borderRadius: `12px`,
				overflow: 'hidden',
			}}
		>
			{selectedOptions.map((option, index) =>
				selectedOptionComponent(
					option,
					removeSelection,
					selectedOptions,
					index
				)
			)}
		</Box>
	);
};


const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#d8dbdf',
		fontSize: 14,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));


const StyledTableRow = styled(TableRow)<{ isSelected?: boolean }>(
	({ isSelected }) => ({
		cursor: 'pointer',
		'&:nth-of-type(odd)': {
			backgroundColor: isSelected ? '#d8dbdf' : '',
		},
		backgroundColor: isSelected ? '#d8dbdf' : '',
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	})
);


import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { useNavigate } from 'react-router';
import CreateIssueStepper from './createIssueStepper/CreateIssueStepper';
import { CreateIssueStepperProps } from './interfaces';
import { useStyles } from './styles/theme';


const CreateIssueMobileView = ({onSubmit} : CreateIssueStepperProps) =>
{
	const classes = useStyles();
	
	const navigate = useNavigate();
	
	
	return (
		<Modal open={true} className={classes.CreateIssueBox} BackdropProps={{
			classes: {
				root: classes.CreateIssueModal
			}
		}}>
			<>
				
				<Box className={classes.CreateIssueDrawerHeaderBox}>
					
					<IconButton onClick={() => navigate(-1)}>
						<ChevronLeft />
					</IconButton>
					
					<Typography variant='h1'>
						Create New Issue
					</Typography>
					
					<Box>
						
					</Box>
					
				</Box>
				
				<Box>
					<CreateIssueStepper onSubmit={onSubmit} />
				</Box>
				
			</>
		</Modal>
	)
}


export default CreateIssueMobileView;
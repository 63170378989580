import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { SearchableSelectField } from 'models';
import Formlet from '../Formlet';


const SelectAndTextFormlet =
({
	name,
	label,
	help,
	options,
	getOptionLabel,
	sx,
	className,
	multiple = false,
	default_value,
	freeSolo = false,
} : SearchableSelectField) =>
{
	console.log('OPTIONS', options);
	
	return (
		<Formlet
			label={label}
			help={help}
		>
			<Controller
				name={name}
				render={({ field: { value, onChange }}) =>
				{
					console.log({value})
					
					return (
						<Autocomplete
							freeSolo = {freeSolo}
							options={options}
							getOptionLabel={getOptionLabel}
							value={value || default_value || []}
							multiple={multiple}
							disablePortal
							onChange={(e, newValue) => onChange(newValue)}
							renderInput={(params) => <TextField onChange={(e) => onChange(e.target.value)} {...params} label={label}/>}
							sx={{
								minWidth: '250px',
								...sx,
							}}
							className={className}
						/>
					)
					
				}}
			/>
		</Formlet>
	);
};


export default SelectAndTextFormlet;
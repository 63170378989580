import { CircularProgress, Stack } from '@mui/material';
import React from 'react'
import CostDistributionPieChart from './CostDistributionPieChart';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import CostAnalyticsTimeline from './CostAnalyticsTimeline';
import CostAnalyticsLoader, { useCostAnalyticsLoader } from './CostAnalyticsLoader';
import CostAnalyticsStateProvider from './CostAnalyticsStateProvider';
import { useAuthData } from 'stuff';
import { useCollection } from 'ember';
import { CostRecord } from 'models';


const CostAnalyticsDashboardMain = () =>
{
	const { claims } = useAuthData();
	
	const org_path = `organizations/${claims.organization_id}`;
	const cost_records_collection = useCollection(`${org_path}/cost_records`);
	
	
	if(cost_records_collection?.is_loading) return <CircularProgress />
	
	
	const cost_records = cost_records_collection?.data as CostRecord[] || [];
	
	return (
		<CostAnalyticsLoader>
			<CostAnalyticsStateProvider cost_records={cost_records}>
				<Stack
					justifyContent='center'
					alignItems='center'
					width='100%'
					sx={{
						padding: '10px'
					}}
				>
					<ParentSize>{({ width, height }) => <CostDistributionPieChart width={width} height={500} />}</ParentSize>
					
					<ParentSize>{({ width, height }) => <CostAnalyticsTimeline width={width} height={500} />}</ParentSize>
				</Stack>
			</CostAnalyticsStateProvider>
		</CostAnalyticsLoader>
	)
}


export default CostAnalyticsDashboardMain;
import { Controller } from 'react-hook-form';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { ActionProps } from 'pages/CreateWorkOrder/Sections/Section/Field/types';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


interface TextProps extends ActionProps {}


export const FillableText =
({
	control,
	sectionIndex: parentFieldIndex,
	fieldIndex: optionIndex,
	sections,
}: TextProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
			}}
		>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
				spacing={1}
				className='form_field_description'
			>
				<Typography
					variant='subtitle1'
					sx={{
						lineHeight: 0
					}}
				>
					{parentFieldIndex+1}.{optionIndex+1}
				</Typography>
				
				<Typography
					variant='subtitle2'
					className='form_field_description'
				>
					{sections[parentFieldIndex].fields[optionIndex].text.description}
				</Typography>
			</Stack>
			
			<Controller
				control={control}
				name={
					`sections.${parentFieldIndex}.fields.${optionIndex}.text.longText` as const
				}
				defaultValue=''
				render={({ field: { value, onChange } }) => {
					return (
						<TextField
							value={value}
							onChange={onChange}
							label='Please type your answer here'
							variant={'outlined'}
						/>
					);
				}}
			/>
		</Box>
	);
};


export default FillableText;
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useSession } from 'app/providers/SessionProvider';

import { Role } from 'models/users/user_interfaces';


interface AssignRoleProps
{
	control: Control<any, any>,
}


const AssignRoles =
({
	control
} : AssignRoleProps) =>
{
	const { roles } = useSession();
	
	const { replace } = useFieldArray({
		control,
		name: 'roles',
	});
	const selected_roles = useWatch({
		control,
		name: 'roles',
	});
	
	// Filters out the assigned roles from the roles collection
	const filtered_roles = roles.filter(role => !selected_roles?.map(x => x._id).includes(role._id));
	
	
	
	const updateSelectedRoles = (newValues:null | Role[]) =>
	{
		replace(newValues);
	};
	
	
	
	
	return (
		<Autocomplete
			multiple
			disablePortal
			options={filtered_roles}
			value={selected_roles}
			getOptionLabel={(option: Role) => option.name}
			onChange={(event, newValue: Role[]) => updateSelectedRoles(newValue)}
			renderTags={(value, getTagProps) =>
				value.map((option: Role, index) =>
				{
					return (
						<Chip
							variant='outlined'
							label={option.name}
							{...getTagProps({ index })}
						/>
					);
				})
			}
			renderInput={(params) => (
				<TextField
					{...params}
					variant='outlined'
					label='Assign to Roles'
					helperText='Select the roles responsible to complete the Work Orders'
				/>
			)}
			sx={{
				width: '50%'
			}}
		/>
	)
};


export default AssignRoles;
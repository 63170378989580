import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import ConciseDateAndTime from '../../fields/datetimes/ConciseDateAndTime';


// Appears as a sort of heading for a group of occurrences that will be generated at the same time
const ProjectionNote = ({ dt } : { dt?: DateTime }) =>
{
	let content : ReactNode;
	
	
	if(!dt)
	{
		content = <>
			Generating immediately
		</>
	}
	else
	{
		content = <>
			Generating on {<ConciseDateAndTime datetime={dt} />}
		</>
	}
	
	
	return (
		<Typography
			sx={{
				opacity: .55,
				marginLeft: -1,
				marginTop: 2,
			}}
		>
			{content}
		</Typography>
	)
}


export default ProjectionNote;
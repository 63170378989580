import CorIcon from 'components/icons/CorIcon';


export interface SearchOrClearIconProps
{
	word_entered: string;
	clearInput: () => void;
}


const SearchOrClearIcon =
({
	word_entered,
	clearInput,
}
: SearchOrClearIconProps) =>
{
	return (
		<div 
			className='search__icon'
		>
			{
				(word_entered.length === 0)
				?
					<CorIcon
						of='search'
						className=''
						size='1.2rem'
						color='#4C4C4C'
					/>
				:
					<span
						onClick={ clearInput }
					>
						<CorIcon
							of={ 'close' }
							className={''}
							size={ '1.2rem' }
							color={ '#4C4C4C' }
						/>
					</span>
			}
		</div>
	)
}


export default SearchOrClearIcon;
import diff from 'recursive-diff';
import cloneDeep from 'lodash/cloneDeep';
import { doForObj, isLiteralObject } from 'utils';

import reducer from 'store/reducers/reducer';




// Ex: {thingys: {cities: [{name: Halifax}] }} => {cities: [{name: Halifax}] }
const just_thingy_values = (state) =>
{
	return doForObj(
		state.thingys,
		(thingys, collection_name) =>
		{
			//console.log(
			//	'%c' + collection_name, 'color: orangered',
			//	thingys
			//);
			//
			//console.log(
			//	'%c' + collection_name, 'color: orangered',
			//	thingys.map(thingy => thingy.toThingo(state))
			//);
			
			return doForObj(thingys, x => x?.values || x);
		}
	)
}




// A wrapper for our actual reducer that provides fancy logging and analytics
const loggingReducer = (state, action) =>
{
	let start_time = performance.now();
	
	
	// Create a deep copy of the state for comparison later
	let old_state = cloneDeep(state);
	
	
	console.group(
		'%cHandling %c' + (
			action.type
			|| (Array.isArray(action) && 'MULTIPLE_ACTIONS')
			|| ((isLiteralObject(action) && !action.type &&
				(Object.keys(action)[0] + ': ' + action[Object.keys(action)[0]])))
			|| action
			|| 'UNKNOWN'
		),
		'color: blue; background: cyan; padding: 2px 0 2px 6px; border-radius: 5px 0 0 5px;',
		'color: #ddf; background: #007; padding: 2px 6px; border-radius: 0 5px 5px 0;',
	);
	
	
	// Log before state
	console.log(
		'%cAction',
		'color: #339; font-weight: bold; font-size: 14px; padding: 3px 6px; background: #0003; border-radius: 5px',
		action);
	
	// Log before state
	console.log(
		'%cState before',
		'color: #955; font-weight: bold; font-size: 14px; padding: 3px 6px; background: #0003; border-radius: 5px',
		old_state);
	
	
	// Call the actual reducer
	let new_state = reducer(state, action);
	
	
	// Clone the new state so that the logging will be accurate
	// (otherwise the logs will show more recent updates)
	let frozen_new_state = cloneDeep(new_state);
	
	
	// Log 'after' state
	console.log(
		'%cState after',
		'color: #955; font-weight: bold; font-size: 14px; padding: 3px 6px; background: #0003; border-radius: 5px',
		frozen_new_state);
	
	
	let mid_time = performance.now();
	
	
	logChanges(old_state, frozen_new_state);
	
	
	let end_time = performance.now();
	
	
	let total_time   = end_time - start_time;
	let diff_time    = end_time - mid_time;
	let diff_percent = Math.round(diff_time / total_time * 100);
	
	console.log(
		'%cTime (ms):    %c' + total_time +
		'%c    (diff: '+ diff_percent + '%)',
		'color: #fff7',
		null,
		'color: #fff4',
	);
	
	
	console.groupEnd();
	
	
	return new_state;
}


// DIFF TO FIND CHANGES
const logChanges = (old_state, new_state) =>
{
	let old_values = just_thingy_values(old_state);
	let new_values = just_thingy_values(new_state);
	
	//console.log(old_values, old_state)
	
	
	if(!old_values || !new_values)
	{
		console.warn('Unable to compare old and new values');
		console.warn(old_values, new_values);
		
		return;
	}
	
	
	// TODO: Detailed diff logging
	//let changes = diff.getDiff(old_state, new_state);
	let changes = diff.getDiff(
		old_values,
		new_values,
	);
	
	
	changes.forEach(x => console.log(
		'    %c' + x.op.toUpperCase() +
		'%c    ' + x.path.join(' > ') + '\n' +
		'        %c' + JSON.stringify(x.val),
		'color: #e00; background: #511; padding: 3px 6px; border-radius: 5px;',
		'color: cyan',
		'color: #888',
	));
}



export default loggingReducer;
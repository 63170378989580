import React, { useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import { WorkOrderTemplate } from 'models';
import WorkOrderTemplateCard from './WorkOrderTemplateCard';


const SearchableWorkOrderTemplatesCardList =
({
	work_order_templates,
} : {
	work_order_templates: WorkOrderTemplate[],
}) =>
{
	const CARDS_PER_PAGE = 5;
	
	const [ page, setPage ] = useState<number>(1);	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	
	console.log({
		work_order_templates,
	});
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
		>
			{
				work_order_templates
					?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE)
					.map((template) => (
						<WorkOrderTemplateCard
							key={template._id}
							work_order_template={template}
						/>
					))
			}
			
			<Pagination
				count={Math.ceil(work_order_templates.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px',
				}}
			/>
			
		</Stack>
	)
};


export default SearchableWorkOrderTemplatesCardList;
import { useCollectionConditionally } from "ember";
import { CertificationRecord, StorageFile } from "models";

import { where } from 'firebase/firestore';

import { useAuthData } from 'stuff';

import { Box, Typography, Stack, CircularProgress } from '@mui/material';


import { fullDateAndTimeFromISO } from 'utils/datetime_utils_old';
import ClickForDownloadDialog from 'app/widgets/dialogs/ClickForDownloadDialog';
import SortableTable from "../../issueCollaboration/issueDetails/issueTable/SortableTable";
import CorIcon from "components/icons/CorIcon";
import { defaultAppTheme } from "styles/mui_app_theme/defaultAppTheme";
import FilesTable from '../../issueCollaboration/issueDetails/FilesTable';



const ShowCertificationRecordFiles = 
({
	certification_record,
	setCloseDialog
}: {
	certification_record?: CertificationRecord,
	setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>
}) =>
{
	const { claims } = useAuthData();
	
	const org_path = `organizations/${claims.organization_id}`;
	
	const files_collection = useCollectionConditionally(
		(certification_record !== null),
		`${org_path}/files`,
		where('certification_record_id', '==', certification_record?._id)
	);
	
	
	if(files_collection?.is_loading)
	{
		return <CircularProgress />
	}
	
	
	const files = files_collection?.data as StorageFile[] || [];
	
	console.log('CERTIFICATION RECORD', certification_record)
	console.log('FILLESS', {files})
	
	
	return (
		<Box
			className='files_table_container'
		>
			<FilesTable files={files} />
		</Box>
	)
}




export default ShowCertificationRecordFiles;
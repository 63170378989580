import { ReactNode, createContext, useContext, useState, useEffect } from 'react';

import cuid from 'cuid';
import { Action, ActionStatus, ActionType, IssueStatus, StorageFile } from 'models';
import { useAuthData } from 'stuff';
import { useCollection, useCollectionConditionally, useUpdateDocProperty, useUploadFile, useWrite } from 'ember';
import { FormProvider, useForm } from 'react-hook-form';
import { Event, Role } from 'types';
import { User } from 'models/users/user_interfaces';
import { DateTime } from 'luxon';
import { useWatch } from 'react-hook-form';
import { where } from 'firebase/firestore';



export interface ActionData
{
	users: User[],
	roles: Role[],
	
	handleSubmit?: Function,
}

export interface ActionFormData
{
	_id: string,
	inquiryMode?: 'list' | 'string',
	inquiry: string,
	todos: {
		label: string;
	}[],
	action_type: ActionType,
	due_date?: string,
	files: File[],
	collaborators: User[],
	roles: Role[],
};


const DEFAULT_FORM_VALUES =
{
	_id: cuid(),
	inquiry: '',
	action_type: null,
	todos: [],
	roles: [],
	collaborators: [],
	files: [],
}


const ActionDataContext = createContext<ActionData>(null);


export const useAction = () =>
{
	return useContext(ActionDataContext)
};



const ActionsDataProvider =
({
	action_metadata,
	action,
	closeDialog,
	setCloseDialog,
	children,
} : {
	action_metadata: {
		issue_id?: string,
		requisition_id?: string,
	},
	action?: Action,
	closeDialog?: boolean
	setCloseDialog: React.Dispatch<React.SetStateAction<boolean>>,
	children?: ReactNode,
}) =>
{
	const { claims } = useAuthData();
	const uploadFile = useUploadFile();
	
	const write = useWrite();
	const updateDoc = useUpdateDocProperty();
	
	
	const current_user = {
		_id: claims.user_id,
		name: claims.name,
		email: claims.email,
	};
	
	
	const org_path = `organizations/${claims?.organization_id}`;
	
	const users_collection = useCollection(`${org_path}/users`);
	const roles_collection = useCollection(`${org_path}/roles`);
	
	
	const users = (users_collection?.data as User[]) || [];
	const roles = (roles_collection?.data as Role[]) || [];
	
	
	
	const form = useForm<ActionFormData>({
		defaultValues: {
			...DEFAULT_FORM_VALUES,
			...action,
		}
	});
	
	const control = form.control;
	
	
	const handleSubmit = () =>
	{
		console.log(form.getValues());
		
		const { _id, inquiry, action_type, collaborators, files, roles } = form.getValues();
		
		let date = DateTime.utc().toISO();
		
		const new_action: Action = {
			...action_metadata,
			_id: _id,
			inquiry: inquiry,
			action_type: action_type,
			assigned_to: collaborators?.map(x => x._id) || [],
			role_ids: roles?.map(x => x._id) || [],
			created_at: date,
			created_by_user: current_user,
			status: ActionStatus.OPEN,
		};
		
		
		
		let attached_files = [];
		
		if(files.length)
		{
			
			files?.forEach(file_to_upload =>
			{
				let file_id = cuid();
				
				
				let file : StorageFile =
				{
					...action_metadata,
					_id: file_id,
					name: file_to_upload.name,
					path: `organizations/${claims.organization_id}/files/${file_id}`,
					size: file_to_upload.size,
					type: file_to_upload.type,
					upload_date: date,
					action_id: _id,
					last_modified: DateTime.fromMillis(file_to_upload.lastModified).toISO(),
				}
				
				
				uploadFile(file, file_to_upload, `organizations/${claims.organization_id}/files`);
				
				
				attached_files.push(file);
			})
			
			
			let file_upload_event_text = 'Uploaded ' + files.length
				+ ' file' + ((files.length > 1) ? 's' : '');
			
			let file_upload_event : Event =
			{
				...action_metadata,
				_id: cuid(),
				name: file_upload_event_text,
				
				type: 'file',
				subtype: 'upload',
				
				datetime: date,
				summary:  file_upload_event_text,
				file_ids: attached_files?.map(x => x._id) || [],
				user_id: claims?.user_id,
			};
			
			write(`organizations/${claims.organization_id}/events`, file_upload_event);
		};
		
		
		
		let new_action_event_text = `${current_user.name} just created a new action item - ${inquiry}`
			
		let new_action_event : Event =
		{
			...action_metadata,
			_id: cuid(),
			name: new_action_event_text,
			
			type: 'action',
			subtype: 'create',
			
			datetime: date,
			summary:  new_action_event_text,
			action_id: _id,
			user_id: claims?.user_id,
		};
		
		
		
		if(action_metadata?.issue_id !== undefined)
		{
			updateDoc(`${org_path}/issues/${action_metadata.issue_id}`, 'status', IssueStatus.IN_PROGRESS)
		}
		
		
		
		write(`${org_path}/events`, new_action_event);
		write(`${org_path}/actions`, new_action);
		
		if(setCloseDialog) setCloseDialog(!closeDialog);
	};
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	const action_data: ActionData =
	{
		users: users,
		roles: roles,
		handleSubmit: handleSubmit,
	}
	
	
	
	return (
		<FormProvider {...form}>
			<ActionDataContext.Provider
				value={action_data}
			>
				<form
					onSubmit={handleSubmit}
				>
					{children}
				</form>
			</ActionDataContext.Provider>
		</FormProvider>
	)
}


export default ActionsDataProvider;
import Badge from 'components/badges/Badge';
import Avatar from '@mui/material/Avatar';
import { IssueLog } from '../../interfaces/content_data_interfaces';


export interface IssuedUserProps
{
	_issue_Headline: IssueLog;
};


const IssuedUser =
({
	_issue_Headline
}
: IssuedUserProps) =>
{
	
	return (
		<div>
			{
				(_issue_Headline !== undefined)
				&&
				<div
					className='issuedUser___wrapper'
				>
					<div>
						<Avatar
							alt={ _issue_Headline.headline.user.username }
							src={ _issue_Headline.headline.user.profilePic }
						/>
					</div>
					
					<div>
						{
							_issue_Headline.headline.user.username
						}
					</div>
					
					<div>
						<Badge
							title='Reporting New Issue'
							color='ocean'
							is_rectangle_shape = { true }
						/>
					</div>
				</div>
			}
		</div>
	)
}


export default IssuedUser;
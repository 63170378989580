import { useState } from 'stuff';
import { forwardRef, useImperativeHandle } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useStyles } from '../../../styles/ManageTagsTabTheme';
import { tagBgColors, tagTextColors } from 'app/dashboard/pages/settings/interfaces/ColorPaletteObj';
import { TagColorPickerProps, TagColorObject } from 'app/dashboard/pages/settings/interfaces/settings_Interfaces';


/*
	OnClick event for each background and text color will update the
	colors object using the setColors event handler as a props
*/
const TagColorPicker = forwardRef(({ colors, setColors, mobile }: TagColorPickerProps, ref) =>
{
	const classes = useStyles({});
	const [ selectedBgColor, setSelectedBgColor] = useState<string>('');
	const [ selectedTxtColor, setSelectedTxtColor] = useState<string>('');
	
	
	const bgColorPickHandler = ( bgColor: TagColorObject ) =>
	{
		setSelectedBgColor(bgColor.name)
		setColors({
			...colors,
			bgColor: bgColor
		})
	};
	
	
	const textColorPickHandler = ( textColor: TagColorObject ) =>
	{
		setSelectedTxtColor(textColor.name)
		setColors({
			...colors,
			textColor: textColor,
		})
	};
	
	
	useImperativeHandle(ref, () => ({
		resetColors(){
			setSelectedBgColor('');
			setSelectedTxtColor('');
		}
	}))
	
	
	return (
		<>
			
			<Box
				className={
					(mobile)
					? classes.TagColorsContainer__mobile
					: classes.TagColorsContainer
				}
			>
				
				<Typography
					className={classes.TagColorsTitle}
					variant='body1'
					color='#323338'
				>
					Color
				</Typography>
				
				<Box
					className={classes.TagColorsFlex}
				>
					{
						tagBgColors.map( (colorObj, idx) => (
							<Paper
								className={ (selectedBgColor ===  colorObj.name) ? classes.TagColorPaper__active : classes.TagColorPaper}
								sx={{ backgroundColor: colorObj.hex}}
								key={idx}
								onClick={ () => bgColorPickHandler( colorObj ) }
							/>
						) )
					}
				</Box>
				
			</Box>
			
			
			<Box
				className={
					(mobile)
					? classes.TagColorsContainer__mobile
					: classes.TagColorsContainer
				}
			>
				
				<Typography
					className={classes.TagColorsTitle}
					variant='body1'
					color='#323338'
				>
					Text color
				</Typography>
				
				<Box
					className={classes.TagColorsFlex}
				>
					{
						tagTextColors.map( (colorObj, idx) =>
							<Paper
								className={ (selectedTxtColor === colorObj.name) ? classes.TagColorPaper__active : classes.TagColorPaper}
								sx={{ backgroundColor: colorObj.hex}}
								key={idx}
								onClick={ () => textColorPickHandler( colorObj ) }
							/>
						)
					}
				</Box>
				
			</Box>
			
		</>
	)
})


export default TagColorPicker;
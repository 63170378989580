import cuid from 'cuid';

import { FirebaseApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, initializeAuth } from 'firebase/auth';
import { doc, setDoc, collection, getFirestore, query, updateDoc, arrayUnion, arrayRemove, deleteDoc, where, writeBatch, QueryConstraint } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';

import {
	FirebaseAppProvider,
	FirestoreProvider,
	AuthProvider, // possibly unnecessary for this?
	useFirebaseApp,
	useFirestore,
	useFirestoreDocData,
	useFirestoreCollectionData,
	useStorage,
	useStorageDownloadURL,
	ReactFireGlobals,
} from 'reactfire';

import { SuspenseSubject } from 'reactfire/dist/SuspenseSubject';

import { StorageFile } from 'models';

import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { chunkArray } from 'utils/array_utils';





// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface Doc extends DocDoesNotNeedId
{
	_id: string;
	[key: string]: any;
}


interface DocDoesNotNeedId
{
	[key: string]: any;
}





// Subscribe to a document for realtime updates with just one line!
// Ex: const doc = useDoc('foods', 'burrito');
//     let burrito = doc.data as Food;
export const useDoc = ( path: string, ...pathSegments: string[] ) =>
{
	const doc_ref = doc(useFirestore(), path, ...pathSegments);
	
	const { status, data } = useFirestoreDocData(doc_ref, {idField: '_id'});
	
	
	return {
		status,
		data,
		is_loading: (status === 'loading'),
	}
}






// export const useIssuesUserIsTaggedInViaRole = async ( path: string, ...constraints: any[] ) =>
// {
// 	let user_id = 'c123';
	
	
// 	// Get user doc
// 	const user_doc_ref = doc(useFirestore(), 'organizations/users/' + user_id);
	
// 	const { status: user_doc_status, data: user_data } = useFirestoreDocData(user_doc_ref, {idField: '_id'});
	
// 	if(user_doc_status === 'loading')
// 	{
// 		return {
// 			status: user_doc_status,
// 			user_data,
// 			is_loading: (user_doc_status === 'loading'),
// 		}
// 	}
	
	
// 	// Get roles assigned to user
// 	const roles = user_data.roles;
	
// 	const role_w_only_ids = roles.map(x => ({_id: x._id}));
	
// 	query(
// 		collection(useFirestore(), 'issues'),
// 		where('roles', 'array-contains', role_w_only_ids),
// 	);
	
	
// 	// const docsSnap = await getDocs(q);
	
// 	// docsSnap.forEach((doc) => {
// 	// 	console.log(doc.data());
// 	// });
	
	
	
// 	// ({ status, data } = useFirestoreCollectionData(
// 	// 	collection_query,
// 	// 	{
// 	// 		idField: '_id',
// 	// 	}));
	
	
// 	// return {
// 	// 	status,
// 	// 	data,
// 	// 	is_loading: (status === 'loading'),
// 	// }
// }






/*

${org}/roles
${org}/tags
${org}/role_tags
${org}/tag_roles


Upon logging in, client loads user doc, containing roles
Upon determining roles from user doc, client loads each relevant doc in role_tags
At this point, client has all tags involving the user

Query role_tags to determine which tags are relevant to this user based on all their roles
Query target collection (ex: ${org}/issues) to find docs referencing relevant tags



// Junior engineer
user1.roles =
[
	{_id: 'role1', name: 'Engineering Support'},
]

// Senior engineer
user2.roles =
[
	{_id: 'role1', name: 'Engineering Support'},
	{_id: 'role2', name: 'Engineering Manager'},
]

// Lead engineer
user3.roles =
[
	{_id: 'role2', name: 'Engineering Manager'},
	{_id: 'role4', name: 'Org admin'},
]

// Accountant
user4.roles =
[
	{_id: 'role3', name: 'Finance'},
	{_id: 'role4', name: 'Org admin'},
]


// Roles and tags docs define themselves - not who is assigned each or the relationships
${org}/roles =
[
	{_id: 'role1', name: 'Engineering Support'},
	{_id: 'role2', name: 'Engineering Manager'},
	{_id: 'role3', name: 'Finance'},
]

${org}/tags =
[
	{_id: 'tag1', name: 'Needs technical drawings'},
	{_id: 'tag2', name: 'Needs engineering approval'},
	{_id: 'tag3', name: 'Needs purchase order'},
	{_id: 'tag4', name: 'Needs new user account'},
]


// role_tags and tag_roles represent relationships and allow easy bidirectional lookups
role_tags.role1 = {tag1: true}
role_tags.role2 = {tag1: true, tag2: true}
role_tags.role3 = {tag3: true}
role_tags.role4 = {tag4: true}

tag_roles.tag1 = {role1: true, role2: true}
tag_roles.tag2 = {role2: true}
tag_roles.tag3 = {role3: true}
tag_roles.tag4 = {role4: true}


// Issues don't reference roles directly, just tags
issues/issue1.tags = [{_id: 'tag1', name: 'Needs technical drawings'}, {_id: 'tag3', name: 'Needs purchase order'}]
issues/issue2.tags = [{_id: 'tag1', name: 'Needs technical drawings'}]
issues/issue3.tags = [{_id: 'tag2', name: 'Needs engineering approval'}]
issues/issue4.tags = [{_id: 'tag3', name: 'Needs purchase order'}]


// Client must now use user.roles to look up relevant tags in role_tags
// Client then uses those tags to query ${org}/issues to find issue docs they're involved with


*/







// export const useDocsMatchingUserRole = async ( user: User, path: string, ...constraints: any[] ) =>
// {
// 	// Get roles assigned to user
// 	const roles = user.roles;
	
// 	// Strip out names in case the name was changed
// 	const role_w_only_ids = roles.map(x => ({_id: x._id}));
// 	const role_ids = roles.map(x => x._id);
	
	
	
// 	// Look up tags that reference our role
	
	
	
// 	// Query target collection to find docs that reference relevant tags
	
	
	
// 	// Ex: We want any issue that links to our role ID
// 	let q = query(
// 		collection(useFirestore(), path),
// 		where('roles', 'array-contains-any', role_w_only_ids),
// 	);
	
// 	let q2 = query(
// 		collection(useFirestore(), path),
// 		where('role_ids', 'array-contains-any', role_ids),
// 	);
	
	
// 	// Get all matching docs and extract their data
// 	let { status, data } = useFirestoreCollectionData(
// 		q,
// 		{
// 			idField: '_id',
// 		});
	
	
// 	return {
// 		status,
// 		data,
// 		is_loading: (status === 'loading'),
// 	}
// }










// Subscribe to a collection for realtime updates with just one line!
// DOCS: https://firebase.google.com/docs/reference/js/firestore_.queryconstraint
// Ex: const collection = useCollection('foods', where('in_stock', '==', 'true'));
//     const foods = collection.data as Food[];
export const useCollection = ( path: string, ...constraints: any[] ) =>
{
	const collection_ref = collection(useFirestore(), path);
	
	let collection_query;
	let status;
	let data;
	
	try
	{
		collection_query = query(
			collection_ref,
			//orderBy('name', 'asc'),
			...constraints
		);
		
		// console.log({
		// 	path,
		// 	collection_query,
		// })
	}
	catch(error)
	{
		// alert('Error occurred');
		
		console.error('Error occurred', error);
	}
	
	
	({ status, data } = useFirestoreCollectionData(
		collection_query,
		{
			idField: '_id',
		}));
	
	
	return {
		status,
		data,
		is_loading: (status === 'loading'),
	}
};



// Subscribe to a collection for realtime updates with just one line!
// DOCS: https://firebase.google.com/docs/reference/js/firestore_.queryconstraint
// Ex: const collection = useCollection('foods', where('in_stock', '==', 'true'));
//     const foods = collection.data as Food[];
export const useCollectionConditionally =
(
	condition: boolean,
	path: string,
	...constraints: QueryConstraint[]
) =>
{
	const collection_ref = collection(useFirestore(), path);
	
	let collection_query;
	let status;
	let data;
	
	try
	{
		if(condition)
		{
			collection_query = query(
				collection_ref,
				//orderBy('name', 'asc'),
				...constraints
			);
		}
		else
		{
			collection_query = query(
				collection_ref,
				where('_id', '==', 'foo')
			);
		}
		
		// console.log({
		// 	path,
		// 	collection_query,
		// })
		
	}
	catch(error)
	{
		// alert('Error occurred');
		
		console.error('Error occurred', error);
	}
	
	
	({ status, data } = useFirestoreCollectionData(
		collection_query,
		{
			idField: '_id',
		}));
	
	
	return {
		status,
		data,
		is_loading: (status === 'loading'),
	}
};





// Don't include doc ID in path (it will be extracted from data._id)
// Ex: const write = useWrite();
//     onClick={() => write('items', item)}
export const useWrite = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		collection_path: string,
		data: DocDoesNotNeedId,
		fn?: Function
	) =>
	{
		if(!data._id)
		{
			data._id = cuid();
		}
		
		
		const doc_ref = doc(firestore, collection_path, data._id);
		
		
		console.log(
			`Writing doc at ${collection_path}/${data._id}:`,
			data,
		);
		
		
		setDoc(doc_ref, data)
			.then(response => {
				if(fn) fn();
				console.log('Wrote document');
			})
			.catch(error => {
				console.error(error);
			})
	}
};





const forEachSeries = async (iterable, action) =>
{
	for (const x of iterable)
	{
		await action(x)
	}
}





const BATCH_SIZE = 500;


// Pass in as many items as you want to write them sequentially in batches
export const useWriteMultiple = () =>
{
	const writeBatch = useWriteBatch();
	
	
	
	return async (
		collection_path: string,
		docs: any[],
		fn?: Function
	) =>
	{
		const doc_batches: any[] = chunkArray(docs, BATCH_SIZE);
		
		
		await forEachSeries(doc_batches, (batch) => writeBatch(
			collection_path,
			batch,
			() =>
			{
				console.log('Wrote batch of docs to DB', batch)
			}
		))
			.then(response => {
				if(fn) fn();
				console.log('Successfully wrote all documents', docs);
			})
			.catch(error => {
				console.error(error);
			})
	}
	
}



// Only pass in <500 items (?)
export const useWriteBatch = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		collection_path: string,
		docs: any[],
		fn?: Function
	) =>
	{
		// Get a new write batch
		const batch = writeBatch(firestore);
		
		
		docs.forEach(x => {
			const doc_ref = doc(firestore, collection_path, x._id);
			
			batch.set(doc_ref, x);
		})
		
		
		// Commit the batch
		await batch.commit()
			.then(response => {
				if(fn) fn();
				console.log('Wrote documents', docs);
			})
			.catch(error => {
				console.error(error);
			})
	}
}






// Only pass in <500 items (?)
// export const useWriteBatch = () =>
// {
// 	const firestore = useFirestore();
	
	
// 	return async (
// 		collection_path: string,
// 		docs: any[],
// 		fn?: Function
// 	) =>
// 	{
// 		// Get a new write batch
// 		const batch = writeBatch(firestore);
		
		
// 		docs.forEach(x => {
// 			const doc_ref = doc(firestore, collection_path, x._id);
			
// 			batch.set(doc_ref, x);
// 		})
		
		
// 		// Commit the batch
// 		await batch.commit()
// 			.then(response => {
// 				if(fn) fn();
// 				console.log('Wrote documents', docs);
// 			})
// 			.catch(error => {
// 				console.error(error);
// 			})
// 	}
// }











// Don't include doc ID in path (it will be extracted from data._id)
// Ex: const update = useUpdate();
//     onClick={() => update('items', item)}
export const useUpdate = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		collection_path: string,
		data: Doc,
		fn?: Function
	) =>
	{
		const doc_ref = doc(firestore, collection_path, data._id);
		
		
		console.log(
			`Updating doc at ${collection_path}/${data._id}:`,
			data,
		);
		
		
		updateDoc(doc_ref, data)
			.then(response => {
				if(fn) fn();
				console.log('Updated document');
			})
			.catch(error => {
				console.error(error);
			})
	}
}


// Don't include doc ID in path (it will be extracted from data._id)
// Ex: const deleteDoc = useDelete();
//     onClick={() => deleteDoc('items', item)}
export const useDelete = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		collection_path: string,
		data: Doc,
		fn?: Function
	) =>
	{
		const doc_ref = doc(firestore, collection_path, data._id);
		
		
		console.log(
			`Deleting doc at ${collection_path}/${data._id}:`,
			data,
		);
		
		
		deleteDoc(doc_ref)
			.then(response => {
				if(fn) fn();
				console.log('Deleted document');
			})
			.catch(error => {
				console.error(error);
			})
	}
}


// This will overwrite a field specified by key with the given elements.
// Ex: const updateDoc = useUpdateDocProperty();
//     const handleClick = () => updateDoc('cities/halifax', 'citizens', 'Ben');
export const useUpdateDocProperty = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		path : string,
		key : string,
		new_elements : any
	) =>
	{
		const doc_ref = doc(firestore, path);
		
		
		await updateDoc(doc_ref, {
			[key]: new_elements
		});
	}
}



// Atomically add new elements to the array field specified by key.
// This will union the given elements with any array value that already exists
// on the server. Each specified element that doesn't already exist in the array
// will be added to the end. If the field being modified is not already an array
// it will be overwritten with an array containing exactly the specified elements.
// Ex: const arrayUnion = useDocArrayUnion();
//     const handleClick = () => arrayUnion('cities/halifax', 'citizens', 'Ben');
export const useDocArrayUnion = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		path : string,
		key : string,
		...new_elements : any[]
	) =>
	{
		const doc_ref = doc(firestore, path);
		
		
		await updateDoc(doc_ref, {
			[key]: arrayUnion(...new_elements)
		});
	}
}



// Atomically remove elements from the array field specified by key
// All instances of each element specified will be removed from the array.
// If the field being modified is not already an array it will be overwritten
// with an empty array.
// Ex: const arrayRemove = useDocArrayRemove();
//     const handleClick = () => arrayRemove('cities/halifax', 'citizens', 'Ben');
export const useDocArrayRemove = () =>
{
	const firestore = useFirestore();
	
	
	return async (
		path : string,
		key : string,
		...elements_to_remove : any[]
	) =>
	{
		const doc_ref = doc(firestore, path);
		
		
		await updateDoc(doc_ref, {
			[key]: arrayRemove(...elements_to_remove)
		});
	}
}



// Provide a path within the project's associated Cloud Storage bucket
// Ex: 'cats/newspaper.jpg' => 'https://firebasestorage.....jpg?alt=media&token=...'
export const useDownloadURL = (path : string) =>
{
	const storage = useStorage();
	
	const storage_ref = ref(storage, path);
	
	const { status, data } = useStorageDownloadURL(storage_ref);
	
	
	return {
		status,
		data,
		is_loading: (status === 'loading'),
	}
}



// Returns a function that will upload to storage, and save an associated document
// to the database
export const useUploadFile = () =>
{
	const write = useWrite();
	const update = useUpdate();
	
	const storage = useStorage();
	
	
	return (
		file_document : StorageFile,
		file_to_upload : File,
		path: string,
		fn?: Function,
	) =>
	{
		// Create a reference to 'mountains.jpg'
		const file_ref = ref(storage, file_document.path);
		
		
		// 'file' comes from the Blob or File API
		uploadBytes(file_ref, file_to_upload)
			.then((snapshot) => {
				console.log('Uploaded a blob or file!', file_document, file_to_upload);
				
				if(fn) fn();
				
				console.log('Updated document');
				
				// Update the StorageFile metadata in Firestore to confirm file fully uploaded
				update(path, {
					_id: file_document._id,
					upload_completed_datetime: DateTime.local().toISO(),
				});
			})
			.catch(error => {
				console.error(error);
			})
		
		write(path, file_document);
	}
}



// After logout, call to clear previous Reactfire session data from globals to avoid auth errors on login
export const clearFirestoreCache = () =>
{
	const preloadedObservables: Map<string, SuspenseSubject<any>> =
		(globalThis as any as ReactFireGlobals)._reactFirePreloadedObservables || new Map();
	
	
	let before = _.cloneDeep(preloadedObservables);
	
	
	let firestore_keys = Array.from(preloadedObservables.keys()).filter(
		(key) => key.includes('firestore')
	);
	
	firestore_keys.forEach(key => preloadedObservables.delete(key))
	
	
	let after = _.cloneDeep(preloadedObservables);
	
	
	console.log('clearFirestoreCache', { before, after, firestore_keys });
};



// Better than getAuth() as it gives us more control over things like persistence
export const getAppAuth = (app: FirebaseApp) =>
{
	return initializeAuth(app, {
		persistence: browserLocalPersistence,
	})
}



export {
	getAuth,
	getFirestore,
	
	FirebaseAppProvider,
	FirestoreProvider,
	AuthProvider,
	
	useFirebaseApp,
	useFirestore,
	useFirestoreDocData,
}
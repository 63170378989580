import { TreeNode } from './tree_models';


export const tree_root: TreeNode =
{
	label: 'Sensors',
	children: [
		{
			label: 'Port Engine',
			children: [
				{
					label: 'Oil Pressure',
					value: '54',
					units: 'psi',
				},
				{
					label: 'Oil Temp',
					value: '81',
					units: '°C',
				},
				{
					label: 'RPM',
					value: '497',
				},
				{
					label: 'Exhaust',
					children: [
						{
							label: 'O₂',
							value: '5.2',
							units: '%',
						},
						{
							label: 'CO',
							value: '70',
							units: 'ppm',
						},
						{
							label: 'CO₂',
							value: '13.5',
							units: '%',
						},
						{
							label: 'NO',
							value: '103',
							units: 'ppm',
						},
						{
							label: 'NO₂',
							value: '127',
							units: 'ppm',
						},
						{
							label: 'SO₂',
							value: '740',
							units: 'ppm',
						},
					],
				},
			],
		},
		{
			label: 'Intake Air',
			children:
			[
				{
					label: 'Ambient Temp',
					value: '15',
					units: '°C',
				},
				{
					label: 'O₂',
					value: '20.9',
					units: '%',
				},
				{
					label: 'CO₂',
					value: '0.05',
					units: '%',
				},
			],
		},
		{
			label: 'Stbd Engine',
			children: [
				{
					label: 'Oil Pressure',
					value: '52',
					units: 'psi',
				},
				{
					label: 'Oil Temp',
					value: '83',
					units: '°C',
				},
				{
					label: 'RPM',
					value: '508',
				},
				{
					label: 'Exhaust',
					children: [
						{
							label: 'O₂',
							value: '5.3',
							units: '%',
						},
						{
							label: 'CO',
							value: '73',
							units: 'ppm',
						},
						{
							label: 'CO₂',
							value: '13.4',
							units: '%',
						},
						{
							label: 'NO',
							value: '99',
							units: 'ppm',
						},
						{
							label: 'NO₂',
							value: '121',
							units: 'ppm',
						},
						{
							label: 'SO₂',
							value: '749',
							units: 'ppm',
						},
					],
				},
			],
		},
	],
};



import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from './ErrorFallback';


// Parent should specify fallback component and onError handler
const ErrorWrapper =
({
	fallback = ErrorFallback,
	onError,
	children,
} : {
	fallback?: any,
	onError?: any
	children: ReactNode,
}) =>
{
	return (
		<ErrorBoundary
			FallbackComponent={fallback}
			onError={onError}
		>
			{children}
		</ErrorBoundary>
	)
}


export default ErrorWrapper;
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useAuthData } from 'app/auth/AuthDataProvider';

import LoadingSpinner from 'components/loading/LoadingSpinner';

import SessionProvider from 'app/providers/SessionProvider';
import AuthPageRoutes from './AuthPageRoutes'
import Dashboard from 'app/dashboard/Dashboard';
import CorProvider from 'app/providers/CorProvider';
import ComponentLoader from 'app/providers/ComponentLoader';


// The highest-level component under App, which is all wiring
// Responsible for considering auth status and deciding which UI to display
const AppContent = () =>
{
	// Subscribe to status of authentication and details
	const { is_loading, user, claims, has_required_claims } = useAuthData();
	
	
	if(is_loading)
	{
		return <LoadingSpinner />;
	}
	
	if(!user && !claims)
	{
		return <AuthPageRoutes />;
	}
	
	if(!claims || !user)
	{
		return <LoadingSpinner />;
	}
	
	
	if(has_required_claims !== true)
	{
		return <LoadingSpinner />;
		// return <div>
		// 	<p>No organization claims available</p>
		// </div>
	}
	
	
	return (
		<Suspense
			fallback={<LoadingSpinner />}
		>
			<ErrorBoundary
				FallbackComponent={() =>
					<div></div>
					//<div>Might need to refresh (F5)</div>
				}
			>
				<SessionProvider>
					<ComponentLoader>
						<CorProvider>
							<Dashboard />
						</CorProvider>
					</ComponentLoader>
				</SessionProvider>
			</ErrorBoundary>
		</Suspense>
	)
}


export default AppContent;
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ActionProps } from '../../../types';

export interface DescriptionProps extends ActionProps {}


export const Description =
({
	control,
	sectionIndex,
	fieldIndex,
}: DescriptionProps) =>
{
	return (
		<Controller
			control={control}
			name={
				`sections.${sectionIndex}.fields.${fieldIndex}.reading.description` as const
			}
			defaultValue=''
			render={({ field: { value, onChange } }) => {
				return (
					<TextField
						value={value}
						onChange={onChange}
						label='Work-step Details'
						variant={'filled'}
					/>
				);
			}}
		/>
	);
};


export default Description;
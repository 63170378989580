import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Calendar from 'app/widgets/calendar/Calendar';
// import Calendar from 'app/widgets/dialogs/calendar/Calendar';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useMyDashboard } from './MyDashboardProvider';



const MyDashboardCalendar = () =>
{
	const { issues, actions, work_orders, requisitions } = useMyDashboard();
	
	
	return (
		<Box className='my_dashboard_calendar'>
			<Stack
				width='100%'
				justifyContent='flex-start'
				sx={{ padding: '10px' }}
			>
				<Typography
					variant='h5'
					sx={{
						background: defaultAppTheme.palette.primary.main,
						color: '#fff',
						padding: '10px',
						borderRadius: '4px',
						width: 'fit-content'
					}}
				>
					Your Calendar & Todos
				</Typography>
			</Stack>
			
			<Calendar
				issues={issues}
				actions={actions}
				work_orders={work_orders}
				requisitions={requisitions}
			/>
		</Box>
	)
};


export default MyDashboardCalendar;
import React, { useEffect, useState } from 'react';

import SearchBarOld from 'components/searchbar/SearchBarOld';
import { Pagination, Stack } from '@mui/material';
import AssetCard from './AssetCard';
import { Asset } from 'models';


const SearchableAssetList =
({
	assets,
	setSelectedAsset,
} : {
	assets: Asset[],
	setSelectedAsset: Function,
}) =>
{
	const CARDS_PER_PAGE = 10;
	
	const [ page, setPage ] = useState<number>(1);
	
	const [ filtered_assets, setFilteredAssets ] = useState<Asset[]>(assets);
	
	
	const handleChangePage =
	(
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) =>
	{
		console.log(page + ' to ' + newPage);
		
		setPage(newPage);
	};
	
	
	useEffect(() =>
	{
		setFilteredAssets(assets)
	}, [assets])
	
	
	console.log({filtered_assets});
	
	
	return (
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='flex-start'
			width='100%'
			sx={{
				padding: '20px'
			}}
		>
			<SearchBarOld
				items={assets}
				handleItems={setFilteredAssets}
				fields={['name']}
			/>
			{
				filtered_assets?.slice((page-1) * CARDS_PER_PAGE, (page-1) * CARDS_PER_PAGE + CARDS_PER_PAGE).map((asset: Asset) => (
					<AssetCard
						key={asset._id}
						asset={asset}
						setSelectedAsset={setSelectedAsset}
					/>
				))
			}
			<Pagination
				count={Math.ceil(assets.length / CARDS_PER_PAGE)}
				page={page}
				onChange={handleChangePage}
				sx={{
					marginTop: '10px'
				}}
			/>
			
		</Stack>
	)
};


export default SearchableAssetList;
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import './DocumentControl.css'


const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


const FileItem = ({ id, name, timestamp, fileUrl, size }) =>
{
	const fileDate = `${timestamp?.toDate().getDate()} ${monthNames[timestamp?.toDate().getMonth() + 1]} ${timestamp?.toDate().getFullYear()}`
	
	const getReadableFileSizeString = (fileSizeInBytes) =>
	{
		let i = -1;
		const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
		do {
			fileSizeInBytes = fileSizeInBytes / 1024;
			i++;
		} while (fileSizeInBytes > 1024);
		
		return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
	};
	
	
	return (
		<div className='fileItem'>
			<a href={fileUrl} target='_blank' download rel='noreferrer'>
				<div className='fileItem--left'>
					{/* <InsertDriveFileIcon color='secondary' /> */}
					<p>{name}</p>
				</div>
				<div className='fileItem--right'>
					<p>{fileDate}</p>
					<p>{getReadableFileSizeString(size)}</p>
				</div>
			</a>
		</div>
	)
}


export default FileItem;
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import RadioMUI from '@mui/material/Radio';

import { FormInputType, ResponseField } from 'types/forms';
import { WorkOrderResponseForm } from 'types/forms';


export interface YesNoNaProps
{
	field: ResponseField & {
		type: FormInputType.NoYesNa;
	};
	sectionIndex: number;
	fieldIndex: number;
}


export const YesNoNa = ({ field, sectionIndex, fieldIndex }: YesNoNaProps) =>
{
	const { control } = useFormContext<WorkOrderResponseForm>();
	
	
	console.log('value', field.response?.value);
	
	
	return (
		<Controller
			control={control}
			name={`form_sections.${sectionIndex}.fields.${fieldIndex}.response.value`}
			defaultValue={(field.response?.value) ?? ''}
			render={({ field: { value, onChange } }) => (
				<FormControl>
					<RadioGroup
						value={value}
						onChange={(e) => onChange(e.target.value)}
						defaultValue={value}
					>
						{
							field.value.map(({ value, label }) =>
								<FormControlLabel
									key={value}
									value={value}
									control={<RadioMUI />}
									label={label}
								/>
							)
						}
					</RadioGroup>
				</FormControl>
			)}
		/>
	);
};


export default YesNoNa;
import { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useToggle } from 'utils/hooks/useToggle';

import { Box, InputLabel, Stack } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { Issue } from 'types/issue_level_interfaces';

// import SelectedIssuesModal from './SelectedIssuesModal/SelectedIssuesModal';
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';

import SelectableIssuesTable from './SelectableIssuesTable';
import CorIcon from 'components/icons/CorIcon';
import { WorkOrderDescriptionFormData } from 'pages/CreateWorkOrder/work_order_types';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';


interface IssuesProps
{
	control: Control<any, any>;
}


export const LinkIssues = ({ control }: IssuesProps) =>
{
	const { replace } = useFieldArray({
		control,
		name: `issues`,
	});
	
	// const formSelectedIssues = useWatch({
	// 	control,
	// 	name: 'issues',
	// });
	
	const { isOpen, open, close } = useToggle();
	
	const [ selectedIssues, setSelectedIssues ] = useState<Issue[]>([]);
	
	const [ closeDialog, setCloseDialog ] = useState<boolean>(false);
	
	
	// // // TODO: What's this for?
	// useEffect(() =>
	// 	{
	// 		if(selectedIssues.length > 0)
	// 			return;
			
	// 		replace(formSelectedIssues);
	// 	},
	// 	[]
	// );
	
	
	const addSelectedIssues = () =>
	{
		replace(selectedIssues);
		
		setCloseDialog(!closeDialog);
	};
	
	
	const onIssueRemove = (issueId: string) =>
	{
		const newIssues = selectedIssues.filter(
			(_issue) => _issue._id !== issueId
		);
		setSelectedIssues(newIssues);
		replace(newIssues);
	};
	
	
	return (
		<Stack
			gap={1}
		>
			<InputLabel
				sx={{
					fontSize: '16px',
					fontWeight: '600',
					color: defaultAppTheme.palette.grey['700'],
				}}
			>
				Link any associated Issues
			</InputLabel>
			
			<ButtonToOpenDialog
				button_variant='outlined'
				dialog_title='Select Issues'
				button_content='Add Issue(s)'
				dialog_content={
					<SelectableIssuesTable
						selectedIssues={selectedIssues}
						setSelectedIssues={setSelectedIssues}
						addSelectedIssues={addSelectedIssues}
					/>
				}
				closeDialog={closeDialog}
				startIcon={<CorIcon of='issue' size='24px'/>}
			/>
			
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					border: '1px solid #d8dbdf',
					borderRadius: '5px',
					width: '100%',
					height: '150px',
					overflow: 'auto',
				}}
			>
				{selectedIssues.map((issue) => (
					<SelectedIssue
						issue={issue}
						onRemove={onIssueRemove}
						key={issue._id}
					/>
				))}
			</Box>
		</Stack>
	);
};


interface SelectedIssueProps
{
	issue: Issue;
	onRemove: (issueId: string) => void;
}


const SelectedIssue = ({ issue, onRemove }: SelectedIssueProps) =>
{
	return (
		<Box
			sx={{
				display: 'flex',
				gap: '2rem',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '1rem',
				borderRadius: '5px',
				border: '1px solid #d8dbdf',
				backgroundColor: '#d8dbdf',
				color: '#666666',
				boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.4)',
			}}
		>
			<Box>
				<Box
					sx={{
						fontWeight: 'semibold',
					}}
				>
					Issue #{issue.issue_num}
				</Box>
				<Box>{issue.name}</Box>
			</Box>
			<CloseIcon
				htmlColor='#666666'
				sx={{
					cursor: 'pointer',
				}}
				onClick={() => onRemove(issue._id)}
			/>
		</Box>
	);
};


export default LinkIssues;
import icon_set from './icon_set';


const MuiIcon = (props: { of: string, className?: string, onClick?: Function, fontSize?: string, color?: string, sx?: any } ) =>
{
	const { of, className, onClick, fontSize, color, sx } = props;
	
	let icon = icon_set[of] || icon_set['undefined'];
	
	let Icon = icon;
	
	
	
	return null;
	
	
	
	console.log(of, icon, typeof icon);
	
	
	// if(typeof icon.type === 'object')
	// {
		Icon = icon_set['unknown'];
	// }
	
	
	return (
		<div className='mui_icon'>
			<Icon 
				className={className}
				onClick={onClick}
				fontSize={fontSize}
				color={color}
				sx={sx}
			/>
		</div>
	)
}


export default MuiIcon;
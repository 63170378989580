import { Divider, Grid, Stack } from '@mui/material';
import InfoHighlight from 'app/dashboard/nav/notifications/InfoHighlight';
import { useWorkOrder } from './WorkOrderProvider';
import WorkOrderComponentsList from './workOrderSteps/workOrderDetails/WorkOrderComponentsList';
import WorkOrderPartsUsed from './workOrderSteps/workOrderDetails/WorkOrderPartsUsed';
import FilesTable from '../issueCollaboration/issueDetails/FilesTable';
import WorkOrderTagsList from './workOrderSteps/workOrderDetails/WorkOrderTagsList';
import WorkOrderCollaboratorsList from './workOrderSteps/workOrderDetails/WorkOrderCollaboratorsList';
import WorkOrderCosts from './workOrderSteps/workOrderDetails/WorkOrderCosts';
import AdditionalWorkOrderControls from './workOrderSteps/workOrderDetails/AdditionalWorkOrderControls';
import IssuesListResponsiveContent from '../issuesMain/IssuesListResponsiveContent';





const WorkOrderDetails = () =>
{
	const { work_order, files, tags, components, issues } = useWorkOrder();
	
	const { description, work_order_type } = work_order;
	
	
	return (
		<Grid
			container
			width={'100%'}
		>
			<Grid
				item
				className='cards_container'
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					spacing={1}
				>
					
					<InfoHighlight
						severity={'info'}
						message_context={`Work Order Type: ${work_order_type}`}
						message_main={description}
					/>
					
					<Grid
						container
						sx={{
							marginTop:'20px',
						}}
						width='100%'
					>
						<WorkOrderComponentsList
							components={components}
							work_order={work_order}
						/>
						
						<Grid
							item
						>
							<Divider orientation='vertical'/>
						</Grid>
						
						<WorkOrderPartsUsed work_order={work_order}/>
						
						<Grid
							item
						>
							<Divider
								orientation='vertical'
							/>
						</Grid>
						
						<WorkOrderTagsList tags={tags} work_order={work_order} />
					</Grid>
					
				</Stack>
			</Grid>
			
			<Grid
				item
				className='cards_container'
			>
				<WorkOrderCosts />
			</Grid>
			
			
			<Grid item width='100%'>
				
				<Grid container spacing={1}>
					
					<Grid item xs={12} sm={8} md={8} lg={8}>
						<FilesTable files={files} />
					</Grid>
					
					<Grid item xs={12} sm={4} md={4} lg={4}>
						<WorkOrderCollaboratorsList main_collaborator_title='Closed by user'/>
					</Grid>
					
				</Grid>
				
			</Grid>
			
			<Grid
				item
				width='100%'
			>
				<IssuesListResponsiveContent issues={issues} title={'Linked Issues'}/>
			</Grid>
			
			<Grid
				item
				className='cards_container'
			>
				<AdditionalWorkOrderControls />
			</Grid>
		</Grid>
	)
}


export default WorkOrderDetails;
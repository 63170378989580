import { Alert, AlertColor, Button, Snackbar } from '@mui/material';
import { createContext, useContext, useState } from 'react';
import { cuid, isLiteralObject } from 'stuff';
import { DateTime } from 'luxon';



export interface ToastFunctions
{
	showToast: Function,
	toastSuccess: Function,
	toastInfo: Function,
	toastWarning: Function,
	toastError: Function,
}



export interface Toast
{
	_id?: string,
	message: string,
	severity?: AlertColor,
	is_shown?: boolean,
	duration_shown?: number,
	datetime_hidden_in_ms?: number,
}



const ToastContext = createContext<ToastFunctions>(null);



// Gives access to showToast and convenience wrappers
export const useToast = () =>
{
	return useContext(ToastContext);
}



// Example of how to use this
export const ToastDemo = () =>
{
	const toast = useToast();
	
	
	return (
		<Button
			variant='contained'
			onClick={() => toast.toastSuccess('Test')}
		>
			Test
		</Button>
	)
}



// Allows any component to create a snackbar/toast notification that outlives the caller
// Useful for creation success notifications that occur while navigating
export const ToastProvider = ({ children }) =>
{
	// const [ show_snackbar, setShowSnackbar ] = useState<boolean>(true);
	
	
	const [ toasts, setToasts ] = useState<Toast[]>([]);
	
	
	const toastSuccess = ( toast_or_string?: Toast | string ) =>
	{
		let new_toast : Toast = mergeDefaultToast(
			toast_or_string,
			{
				message: 'Success',
				severity: 'success',
			}
		)
		
		writeToast(new_toast);
	}
	
	
	const toastInfo = ( toast_or_string?: Toast | string ) =>
	{
		let new_toast : Toast = mergeDefaultToast(
			toast_or_string,
			{
				message: 'Info',
				severity: 'info',
			}
		)
		
		writeToast(new_toast);
	}
	
	
	const toastWarning = ( toast_or_string?: Toast | string ) =>
	{
		let new_toast : Toast = mergeDefaultToast(
			toast_or_string,
			{
				message: 'Warning',
				severity: 'warning',
			}
		)
		
		writeToast(new_toast);
	}
	
	const toastError = ( toast_or_string?: Toast | string ) =>
	{
		let new_toast : Toast = mergeDefaultToast(
			toast_or_string,
			{
				message: 'Error',
				severity: 'error',
			}
		)
		
		writeToast(new_toast);
	}
	
	
	
	
	
	const writeToast = ( new_toast: Toast ) =>
	{
		// Clone the toasts array, add the new one, and overwrite in state
		let new_toasts = structuredClone(toasts);
		new_toasts.push(new_toast);
		setToasts(new_toasts)
		
		// console.log({
		// 	toast_or_string,
		// 	new_toast,
		// 	toasts,
		// 	new_toasts,
		// })
	}
	
	
	
	const mergeDefaultToast = ( toast_or_string?: Toast | string, obj?: Object ) =>
	{
		let new_toast : Toast =
		{
			_id: cuid(),
			message: 'Success',
			severity: 'success',
			duration_shown: 2000,
			is_shown: true,
			
			...obj
		};
		
		if(isLiteralObject(toast_or_string))
		{
			new_toast = Object.assign(
				new_toast,
				{...toast_or_string as Toast}
			)
		}
		else
		{
			if(toast_or_string)
			{
				new_toast = Object.assign(
					new_toast,
					{
						message: toast_or_string,
					}
				)
			}
		}
		
		return new_toast;
	}
	
	
	const showToast = ( toast_or_string?: Toast | string ) =>
	{
		let new_toast : Toast =
		{
			_id: cuid(),
			message: 'Success',
			severity: 'success',
			duration_shown: 2000,
			is_shown: true,
		};
		
		
		if(isLiteralObject(toast_or_string))
		{
			new_toast = Object.assign(
				new_toast,
				{...toast_or_string as Toast}
			)
		}
		else
		{
			if(toast_or_string)
			{
				new_toast = Object.assign(
					new_toast,
					{
						message: toast_or_string,
					}
				)
			}
		}
		
		let new_toasts = structuredClone(toasts);
		
		new_toasts.push(new_toast);
		
		setToasts(new_toasts)
		
		console.log({
			toast_or_string,
			new_toast,
			toasts,
			new_toasts,
		})
	}
	
	
	// Dismiss a specific toast
	const hideToast = (target_id: string) =>
	{
		let now_ms = DateTime.utc().toMillis();
		
		let new_toasts: Toast[] = structuredClone(toasts);
		
		// Any old toasts dismissed over 1 second ago should be removed
		new_toasts = new_toasts.filter(x =>
			(!x.datetime_hidden_in_ms)
			||
			((now_ms - x.datetime_hidden_in_ms) < 1000)
		);
		
		
		let target = new_toasts.find(x => x._id === target_id);
		
		if(target)
		{
			target.is_shown = false;
			target.datetime_hidden_in_ms = now_ms;
		}
		else
		{
			console.warn('Unable to find toast to remove', {
				target_id,
				toasts,
			});
		}
		
		setToasts(new_toasts);
	}
	
	
	return (
		<ToastContext.Provider
			value={{
				showToast,
				toastSuccess,
				toastInfo,
				toastWarning,
				toastError,
			}}
		>
			{children}
			
			{
				toasts.map( ( toast : Toast )=>
					<Snackbar
						open={toast?.is_shown}
						autoHideDuration={toast.duration_shown}
						onClose={() => hideToast(toast._id)}
						className='alert'
						key={toast._id}
					>
						<Alert
							severity={toast.severity || 'success'}
							onClose={() => hideToast(toast._id)}
							sx={{ width: '100%' }}
						>
							{toast.message}
						</Alert>
					</Snackbar>
				)
			}
			
		</ToastContext.Provider>
	)
}




// const ExampleButton = () => {
// 	const toast = useToast()
// 	return <button onClick={() => toast.success("hello!")}>
// 		push me
// 	</button>
// }



export default ToastProvider;
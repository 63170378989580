import { Button, Divider, Stack } from '@mui/material';
import { Action, ActionStatus } from 'models';


const ActionCollaborationControls = ({
	action,
	remaining_balance,
	handleActionUpdate,
	handleActionStatusUpdate,
	handleActionStatusCancelled,
} : {
	action: Action,
	remaining_balance: number,
	handleActionUpdate: any,
	handleActionStatusUpdate: any,
	handleActionStatusCancelled: any,
}) =>
{
	return (
		<Stack
			direction='column'
			justifyContent='center'
			alignItems='flex-end'
			width='100%'
			spacing={2}
		>
			<Button
				disabled={
					(action.status === ActionStatus.RESOLVED)
					|| (action.status === ActionStatus.CANCELLED)
				}
				variant='outlined'
				onClick={handleActionUpdate}
				sx={{
					textTransform: 'none'
				}}
			>
				Submit Response
			</Button>
			
			<Divider sx={{ width: '100%' }} />
			
			
			<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					width='100%'
			>
				<Button
					variant='outlined'
					onClick={handleActionStatusCancelled}
					color='error'
					disabled={
						(action.status === ActionStatus.RESOLVED)
						|| (action.status === ActionStatus.CANCELLED)
					}
					sx={{
						textTransform: 'none'
					}}
				>
					Cancel
				</Button>
				<Button
					variant='contained'
					onClick={handleActionStatusUpdate}
					color='success'
					disabled={
						(Number(action?.accounts_charged_amount?.quantity) > remaining_balance)
						|| (action.status === ActionStatus.RESOLVED)
						|| (action.status === ActionStatus.CANCELLED)
					}
					sx={{
						textTransform: 'none'
					}}
				>
					Resolve/Approve
				</Button>
			</Stack>
			
		</Stack>
	)
};


export default ActionCollaborationControls;
import { IconButton } from '@mui/material';
import CorIcon from 'components/icons/CorIcon';
import { useFormContext } from 'react-hook-form';


// A widget meant for use within a form gizmo, allowing you to easily remove an item from an object
const DeleteIconWidget =
({
	item_id,
	name,
	array,
	obj,
} : {
	item_id: string,
	name: string,
	// Can operate on either an array of an object
	array?: any[],
	obj?: {[key: string]: any},
}) =>
{
	const form_context = useFormContext();
	
	
	return (
		<IconButton
			color='secondary'
			aria-label='delete'
			component='label'
			onClick={() => {
				
				if(obj !== undefined)
				{
					let obj_without_item = structuredClone(obj);
					
					delete obj_without_item[item_id];
					
					form_context.setValue(name, obj_without_item);
				}
				else if(array !== undefined)
				{
					let array_without_item = array.filter(x => x?._id !== item_id);
					
					form_context.setValue(name, array_without_item);
				}
			}}
			sx={{
				alignSelf: 'center',
			}}
		>
			<CorIcon of='delete' size='24px' />
		</IconButton>
	)
}


export default DeleteIconWidget;
import ButtonToOpenDialog from 'app/widgets/dialogs/ButtonToOpenDialog';
import { User } from 'types';
import SearchableCollaboratorsList from './SearchableCollaboratorsList';
import CorIcon from 'components/icons/CorIcon';
import Formlet from 'app/gizmos/fields/Formlet';


const SelectCollaboratorsFormlet =
({
	closeDialog,
	selectedCollaborators,
	handleSelectedCollaborators,
	users_list,
	label,
	help,
	button_content,
}: {
	handleSelectedCollaborators: (value: any) => void,
	selectedCollaborators?: User[],
	closeDialog?: boolean,
	users_list?: User[],
	label?: string
	help?: string,
	button_content?: string,
}) =>
{
	return (
		<Formlet
			label={label}
			help={help}
		>
				<ButtonToOpenDialog
					button_variant='outlined'
					dialog_title={label || 'Select Collaborators'}
					button_content={button_content || 'Select Collaborators'}
					startIcon={<CorIcon of='alert_users' size='25px' />}
					dialog_content={
						<SearchableCollaboratorsList
							users_list={users_list}
							selectedCollaborators={selectedCollaborators}
							handleSelectedCollaborators={handleSelectedCollaborators}
						/>
					}
					closeDialog={closeDialog}
					dialog_submit_text='Done'
					handleSubmit={() => null}
					sx={{
						textTransform: 'none',
					}}
				/>
		</Formlet>
	)
};


export default SelectCollaboratorsFormlet;
import { Typography, Box, Button, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form';
import CreateIssueDesktopColumnRight from './createIssueStepper/components/CreateIssueDesktopColumnRight';
import CreateIssueDesktopColumnLeft from './createIssueStepper/components/CreateIssueDesktopColumnLeft';

import { useStyles } from './styles/theme'
import CorIcon from 'components/icons/CorIcon';
import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';
import { useNavigate } from 'react-router';


const CreateIssueDesktopView = () => 
{
	const classes = useStyles();

	const { formState: {errors}, getValues } = useFormContext();
	
	const navigate = useNavigate();
	
	
	const validatePage = () =>
	{
		const current_collaborators = getValues('collaborator_ids');
		
		return Boolean((errors?.name || errors?.severity || (current_collaborators.length > 0)))
	}
	
	
	return (
		<Box className={classes.BoxCol}>
			
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='flex-start'
			>
					<CorIcon
						of='back'
						size='40px'
						onClick={() => navigate(-1)}
						color= {defaultAppTheme?.palette?.primary.main}
						cursor='pointer'
					/>
					<Typography variant='h1'>
						Create New Issue
					</Typography>
			</Stack>
			
			<Box className={classes.BoxRow} >
				<CreateIssueDesktopColumnLeft />
				<CreateIssueDesktopColumnRight />
			</Box>
			
			<Box className={classes.CreateIssueRightJustifyRow}>
				<Typography variant='h4' color='#ff595e'>*</Typography>
				<Typography variant='h4'>mandatory fields.</Typography>
			</Box>
			
			<Box className={classes.BoxCol}>
				<Button
					size='small'
					type='submit'
					className={classes.DesktopSubmitButton}
					form='create-issue-form'
				>
					Done
				</Button>
			</Box>
		</Box>
	)
}


export default CreateIssueDesktopView;
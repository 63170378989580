import React, { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { Asset, System, ComponentTreeData } from 'models';
import SelectedComponentChip from './SelectedComponentChip';
import AssetComponentSelection from './AssetComponentSelection';

import { useDoc } from 'ember';
import { useAuthData } from 'stuff';
import { useSearchParams } from 'react-router-dom';


// selectEquipment
const ComponentSelection =
({
	selectEquipment,
	selected_components,
} : {
	selectEquipment?: (selected_components: ComponentTreeData[]) => void,
	selected_components?: ComponentTreeData[],
}) =>
{	
	const [ search_params, setSearchParams ] = useSearchParams();
	
	const asset_id = search_params?.get('asset') || 'None';
	const system_id = search_params?.get('system') || 'None';
	
	console.log(asset_id, system_id);
	
	
	const { claims } = useAuthData();
	
	const [ selectedAsset, setSelectedAsset ] = useState<Asset>();
	const [ selectedSystem, setSelectedSystem ] = useState<System>();
	const [ selectedComponents, setSelectedComponents ] = useState<ComponentTreeData[]>(selected_components || []);
	
	
	
	
	// If URL has a component_id, auto select that component
	const org_path = `organizations/${claims?.organization_id}`;
	
	const asset_doc = useDoc(`${org_path}/assets/${asset_id}`);
	
	// TODO: switch to primary groups instead of components
	const system_doc = useDoc(`${org_path}/components/${system_id}`);
	
	const asset = asset_doc?.data as Asset;
	const system = system_doc?.data as System;
	
	
	useEffect(() =>
	{
		if(!system_doc?.is_loading && !asset_doc?.is_loading)
		{
			setSelectedAsset(asset);
			setSelectedSystem(system);
		}
	}, [asset_doc.is_loading, system_doc.is_loading]);
	
	
	useEffect(() =>
	{
		if(selected_components?.length > 0)
		{
			setSearchParams({
				asset: selected_components[0]?.asset_id,
				system: selected_components[0]?.system_id,
				component: selected_components[0]?._id
			})
		}
	}, [])
	
	
	
	console.log({
		selectedAsset,
		selectedSystem,
		selectedComponents,
	});
	
	
	return (
		<Grid
			container
			direction='column'
			justifyContent='center'
			alignItems='center'
		>
			<Grid
				item
			>
				<Typography variant='h6'>
					Please select affected component(s)
				</Typography>
			</Grid>
			
			<Grid
				item
				width='100%'
			>
				<Grid
					container
					direction='row'
					justifyContent='flex-start'
					alignItems='center'
					className='selected_components_container'
				>
					{
						selectedComponents?.map(component=>
							<Grid
								key={component._id}
								item
								xs={12}
								sm={12}
								md={6}
								lg={4}
							>
								<SelectedComponentChip
									component={component}
									selectedComponents={selectedComponents}
									setSelectedComponents={setSelectedComponents}
								/>
							</Grid>
						)
					}
				</Grid>
			</Grid>
			
			<Grid
				item
				width='95%'
			>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					spacing={2}
				>
					<Button
						sx={{
							textTransform: 'none',
						}}
						onClick={() => setSelectedComponents([])}
					>
						Clear All
					</Button>
					
					<Button
						variant='contained'
						sx={{
							textTransform: 'none',
						}}
						onClick={() => selectEquipment(selectedComponents)}
					>
						Select
					</Button>
				</Stack>
			</Grid>
			
			<Grid
				item
				width='100%'
			>
				<AssetComponentSelection
					selectedComponents={selectedComponents}
					setSelectedComponents={setSelectedComponents}
					selectedSystem={selectedSystem}
					setSelectedSystem={setSelectedSystem}
					selectedAsset={selectedAsset}
					setSelectedAsset={setSelectedAsset}
				/>
			</Grid>
		</Grid>
	)
};


export default ComponentSelection;
import { Box } from '@mui/material';
import { User } from 'types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import SelectCollaboratorsFormlet from 'components/selectCollaborators/desktop/SelectCollaboratorsFormlet';
import SelectedCollaboratorsList from 'components/selectCollaborators/desktop/SelectedCollaboratorsList';



export interface CollaboratorsProps {
	users: User[]
}

const ActionCollaborators = ({ users }: CollaboratorsProps) =>
{
	const { control } = useFormContext()
	
	const { replace, remove, append } = useFieldArray({
		control,
		name: "collaborators"
	})
	const collaborators = useWatch({
		control,
		name: "collaborators"
	})
	
	
	const onChange = (newValues: User[]) => {
		replace(
			newValues.map((value) => {
				if (isUser(value)) return value;
			})
		);
	};
	
	
	const filterUsers = (users: User[]) =>
		users.filter(
			(user) =>
				!collaborators.find(
					(_collaborator) => _collaborator._id === user._id
				)
		);
	
	
	
	const selectUser = (user: User) =>
	{
		const collaborator_ids = collaborators.map(x => x._id);
		
		if(collaborator_ids.includes(user._id))
		{
			unSelectUser(user)
		}
		else
		{
			append(user)
		}
	}
	
	
	const unSelectUser = (user: User) =>
	{
		let index: number | undefined;
		
		collaborators.forEach((collaborator, idx) =>
		{
			if (collaborator._id === user._id)
			{
				index = idx;
			}
		});
		
		remove(index);
	};
	
	console.log({collaborators});
	
	
	
	return (
		<Box
			sx={{
				marginBlock: '2rem',
				width: `min(358px, 100%)`,
			}}
		>
			<Box sx={{ marginBottom: '1rem' }}>
				Assign Work
			</Box>
			
			<SelectCollaboratorsFormlet
				selectedCollaborators={collaborators}
				handleSelectedCollaborators={selectUser}
			/>
			
			<SelectedCollaboratorsList
				users={collaborators}
				handleChange={unSelectUser}
			/>
		</Box>
	);
};

export default ActionCollaborators;

function isUser(user: User | string): user is User {
	return (user as User)._id !== undefined;
}

import { useStorage, useStorageDownloadURL } from 'reactfire';
import { ref } from'firebase/storage';


import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';

import { AvatarImageProps } from './ImageProps';
import { text2hex } from 'utils/color_utils';



const AvatarImageFromStorage = (
{
	src,
	alt,
	...rest
}: AvatarImageProps ) =>
{
	const storage = useStorage();
	
	const { sx, cuid } = rest;
	
	const storage_ref = ref(storage, src);
	
	
	const { status, data: image_url } = useStorageDownloadURL(storage_ref);
	
	if(status === 'loading') return <CircularProgress />
	
	
	return <Avatar
		src={image_url}
		alt={alt}
		sx={{
			bgcolor: text2hex(cuid || ''), // pass in the CUID of the document for a unique background color
			...sx,
		}}
		{...rest}
	/>
};


export default AvatarImageFromStorage;
import { TimeUnit } from 'models';


// TODO: Review - ensure this is set for updated records
export enum ComponentRecordStatus
{
	UNDER_THRESHOLD = 'Under Threshold',
	OVER_THRESHOLD = 'Over Threshold',
	NORMAL = 'Normal',
	UNKNOWN = 'Unknown',
}


export enum ComponentRecordType
{
	RUNNING_HOURS = 'Running Hours',
	TEMPERATURE = 'Temperature',
	PRESSURE = 'Pressure',
	TIME_ELAPSED = 'Time Elapsed',
	COUNT = 'Count',
	
	HEIGHT = 'Height',
	LENGTH = 'Length',
	WIDTH = 'Width',
	DIAMETER = 'Diameter',
	DEPTH = 'Depth',
	
	AREA = 'Area',
	VOLUME = 'Volume',
	FLUID_LEVEL = 'Fluid Level',
	WEIGHT = 'Weight',
	FLOW_RATE = 'Flow Rate',
	FREQUENCY = 'Frequency',
	PERCENTAGE = 'Percentage',
	PITCH = 'Pitch',
	POWER = 'Power',
	VOLTAGE = 'Voltage',
	CURRENT = 'Current',
}


export enum DistanceUnits
{
	KM = 'km',
	NAUTICAL_MILES = 'NM',
}


export enum SpeedUnits
{
	KM_H = 'km/h',
	KNOTS = 'knots',
}


export enum PressureUnits
{
	// 1 atm = 101,325 Pa
	PASCALS = 'Pa',
	// 1 atm = 101.325 kPa
	KILOPASCALS = 'kPa',
	// 1 atm = 760 mmHg
	MM_MERCURY = 'mmHg',
	// 1 atm = 101.325 kPa
	ATMOSPHERE = 'atm',
	// 1 atm = 1.01325 bar
	BAR = 'bar',
	// 1 atm = 14.6959 psi
	PSI = 'psi',
}


export enum TemperatureUnits
{
	DEGREES_CELSIUS = '°C',
	DEGREES_FAHRENHEIT = '°F',
}


export enum TimeElapsedUnits
{
	HOURS = 'hr',
	MINUTES = 'min',
	SECONDS = 's',
	MILLISECONDS = 'ms',
}


export enum CountUnits
{
	COUNT_NUMBER = 'Count #'
}


export enum DimensionUnits
{
	INCHES = 'in',
	FEET = 'ft',
	METERS = 'm',
	CENTIMETERS = 'cm',
	MILLIMETERS = 'mm',
}


export enum AreaUnits
{
	INCHES_SQUARED = 'in²',
	FEET_SQUARED = 'ft²',
	METERS_SQUARED = 'm²',
	CENTIMETERS_SQUARED = 'cm²',
	MILLIMETERS_SQUARED = 'mm²',
}


export enum VolumeUnits
{
	INCHES_CUBED = 'in³',
	FEET_CUBED = 'ft³',
	METERS_CUBED = 'm³',
	CENTIMETERS_CUBED = 'cm³',
	MILLIMETERS_CUBED = 'mm³',
}

export enum FluidLevelUnits
{
	LITRES = 'L',
	MILLILITRES = 'mL',
	GALLONS = 'gal',
	FLUID_OUNCE = 'fl oz',
}


export enum WeightUnits
{
	MILLIGRAMS = 'mg',
	GRAMS = 'g',
	KILOGRAMS = 'kg',
	POUNDS = 'lb',
	OUNCES = 'oz',
	TONNES = 't',
}


export enum FlowRateUnits
{
	FEET_CUBED_PER_SECOND = 'ft³/s',
	METER_CUBED_PER_SECOND = 'm³/s',
}


export enum FrequencyUnits
{
	HERTZ = 'Hz',
	REVOLUTIONS_PER_MINUTE = 'RPM',
}


export enum PercentageUnits
{
	PERCENTAGE = '%',
}


export enum PitchUnits
{
	DEGREES = '°'
}


export enum CurrentUnits
{
	AMPERES = 'A',
	MILLIAMPERES = 'mA',
}


export enum VoltageUnits
{
	VOLT = 'V',
	MILLIVOLTS = 'mV',
}


export enum PowerUnits
{
	WATTS = 'W',
	KILLOWATTS = 'kW',
	HORSE_POWER = 'HP'
}







export const ComponentRecordUnits =
{
	...DistanceUnits,
	...SpeedUnits,
	...PressureUnits,
	...TemperatureUnits,
	...TimeUnit,
	...AreaUnits,
	...CountUnits,
	...CurrentUnits,
	...DimensionUnits,
	...VoltageUnits,
	...VolumeUnits,
	...FluidLevelUnits,
	...FlowRateUnits,
	...FrequencyUnits,
	...PercentageUnits,
	...TimeElapsedUnits,
	...PowerUnits,
	...PitchUnits,
	...WeightUnits
};


// export type ComponentRecordUnits = typeof ComponentRecordUnits;


export enum ThresholdActionType
{
	SEND_ALERT = 'Send Alert',
	CREATE_WORK_ORDER = 'Create Work Order',
}



import { useAuthData} from 'stuff';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useStyles } from '../../../../styles/ManageProfileTabTheme'


const ManageProfileItems = () =>
{
	const { claims } = useAuthData();
	
	const classes = useStyles();
	
	const navigate = useNavigate();
	
	
	return (
		<>
			<Box
				className={classes.UserProfileItemsContainer}
			>
				
				<Box
					className={classes.UserProfileItems}
					onClick={() => navigate('/settings/profile?edit=username')}
				>
					<Typography
						className={classes.UserProfile_Username__mobile}
						variant='h5'
					>
						Username
					</Typography>
					
					<Box
						className={classes.UserProfileItems__right}
					>
						<Typography
							className={classes.UserProfile_Username__mobile}
							variant='caption'
						>
							{
								claims?.name
							}
						</Typography>
						<ChevronRightIcon
							className={classes.UserProfileItems__rightIcon}
						/>
					</Box>
				</Box>
				
				<Box
					className={classes.UserProfileItems}
					onClick={() => navigate('/settings/profile?edit=email')}
				>
					<Typography
						className={classes.UserProfile_Username__mobile}
						variant='h5'
					>
						Email
					</Typography>
					
					<Box
						className={classes.UserProfileItems__right}
					>
						<Typography
							className={classes.UserProfile_Username__mobile}
							variant='caption'
						>
							{
								claims?.email
							}
						</Typography>
						<ChevronRightIcon
							className={classes.UserProfileItems__rightIcon}
						/>
					</Box>
				</Box>
				
				<Box
					className={classes.UserProfileItems}
				>
					<Typography
						className={classes.UserProfile_Username__mobile}
						variant='h5'
					>
						Change Password
					</Typography>
					
					<Box
						className={classes.UserProfileItems__right}
					>
						<ChevronRightIcon
							className={classes.UserProfileItems__rightIcon}
						/>
					</Box>
				</Box>
				
				<Divider/>
				
			</Box>
		</>
	)
}


export default ManageProfileItems;
import React from 'react'

import { StatickCalendarProps } from '../interface/CalendarProps';
import { AssetHistory } from '../interface/AssetHistoryInterface';

import { DateTime } from 'luxon';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import TextField from '@mui/material/TextField';
import CalendarPickerSkeleton from '@mui/lab/CalendarPickerSkeleton';

// import PickersDay from '@mui/lab/PickersDay';
// import Badge from '@mui/material/Badge';

import CalendarDateTitle from './CalendarDateTitle';


const StatickCalendar: React.FC< StatickCalendarProps > =
({
	selected_Date,
	asset_histories,
}) =>
{
	
	const [value, setValue] = React.useState<Date | null>( new Date() );
	const requestAbortController = React.useRef<AbortController | null>(null);
	const [isLoading, setIsLoading] = React.useState(false);
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [highlightedDays, setHighlightedDays] = React.useState< Array<number> >([]);
	
	
	const fetchHighlightedDays = React.useCallback((date: Date) => 
	{
		const current_dt: DateTime = DateTime.fromJSDate( date );
		
		const matched_assets: AssetHistory[] = asset_histories.filter(( asset ) => {
			const history_ordered_date: DateTime = DateTime.fromISO( asset.HistoryOrderDate );
			
			return ( history_ordered_date.year === current_dt.year ) && ( history_ordered_date.month === current_dt.month ); 
		})
		
		let temp_highlighted_days: Array<number> = [];
		matched_assets.map(( asset ) => {
			const history_ordered_date: DateTime = DateTime.fromISO( asset.HistoryOrderDate );
			return temp_highlighted_days.push( history_ordered_date.day );
		})
		
		setHighlightedDays(temp_highlighted_days);
		
		setIsLoading(false);
	}, [asset_histories]);
	
	
	React.useEffect(() => {
		fetchHighlightedDays( value );
		
		let abort_target = requestAbortController.current;
		
		// abort request on unmount
		return () => abort_target?.abort();
	}, [fetchHighlightedDays, value]);
	
	
	const updateSelectedDate = ( selected_date: Date ) =>
	{
		setValue( selected_date );
		selected_Date( DateTime.fromJSDate( selected_date ) );
	}
	
	
	const handleMonthChange = (date: Date) => 
	{
		if (requestAbortController.current)
		{
			// make sure that you are aborting useless requests
			// because it is possible to switch between months pretty quickly
			requestAbortController.current.abort();
		}
		setValue(new Date(date.getFullYear(), date.getMonth(), 1));
		setIsLoading(true);
		setHighlightedDays([]);
		fetchHighlightedDays(date);
	};
	
	
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<StaticDatePicker
				displayStaticWrapperAs="desktop"
				openTo="day"
				value={value}
				loading={isLoading}
				onChange={(newValue) => {
					updateSelectedDate(newValue);
				}}
				renderInput={(params) => <TextField {...params} />}
				onMonthChange={handleMonthChange}
				renderLoading={() => <CalendarPickerSkeleton />}
				renderDay={(day, _value, DayComponentProps) => {
					// const isSelected =
					// 	!DayComponentProps.outsideCurrentMonth &&
					// 	highlightedDays.indexOf(day.getDate()) >= 0;
					
					return (
						// <Badge
						//   key={day.toString()}
						//   overlap="circular"
						//   color="error"
						//   badgeContent={isSelected ? '' : undefined}
						//   variant={isSelected ? 'dot' : undefined}
						// >
						//   <PickersDay {...DayComponentProps} />
						// </Badge>
						<CalendarDateTitle
							date={day}
						/>
					);
				}}
			/>
	  </LocalizationProvider>
	)
}


export default StatickCalendar;
import { Chip, Grid, Stack, Typography } from '@mui/material';

import EditCostCenters from 'app/dashboard/pages/requisitions/EditCostCenters';
import { useWorkOrder } from '../../WorkOrderProvider';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';




const WorkOrderCostCenters = () =>
{
	const form = useFormContext();
	const { cost_centers, work_order, updateCostCenters } = useWorkOrder();
	
	const related_cost_centers = cost_centers.filter(x => work_order?.cost_center_ids?.includes(x._id));
	
	
	useEffect(() =>
	{
		form.setValue(
			'cost_centers',
			related_cost_centers
		)
	}, [work_order?.cost_center_ids]);
	
	
	return (
		<Stack>
			<Typography
				variant='h6'
			>
				Cost Centers
			</Typography>
			
			<Grid
				container
				gap={1}
			>
				{
					related_cost_centers.map(cost_center => (
						<Grid
							item
							key={cost_center?._id}
						>
							<Chip
								label={cost_center?.description}
							/>
						</Grid>
					))
				}
			</Grid>
			
			<EditCostCenters
				cost_centers={cost_centers}
				handleSubmit={updateCostCenters}
			/>
		</Stack>
	)
}


export default WorkOrderCostCenters;
import { createContext, ReactNode, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// import { useNavigate } from 'react-router';
import { cuid, useAuthData } from 'stuff';

import { useWrite } from 'ember';
import { DateTime } from 'luxon';

import { CostCenter, Periodicity } from 'models/costs';
import { useCollection } from 'ember';
import { Asset } from 'models';





export interface CostCenterForm
{
	centers: any[],
}



const DEFAULT_FORM_DATA: CostCenter =
{
	_id: cuid(),
	description: '',
	
	centers: [],
	components: [],
	
	allocated_budget: {
		currency: '',
		quantity: '',
	},
	periodicity: Periodicity.YEARLY,
};



// TODO: Default value?
const CostCenterFormContext = createContext<CostCenterForm | null>({
	centers: [],
});



export const useCreateCostCenter = () =>
{
	return useContext(CostCenterFormContext);
}



export const CostCenterFormProvider =
({
	children,
	cost_center,
	closeDialog,
	setCloseDialog,
} : {
	children?: ReactNode,
	cost_center?: CostCenter,
	closeDialog?: boolean,
	setCloseDialog?: any,
}) =>
{
	const { claims } = useAuthData();
	
	console.log('SHOW ME THE EDDITED COST CENTER', cost_center)
	
	const write = useWrite();
	
	const current_user = {
		_id: claims.user_id,
		name: claims.name,
		email: claims.email,
	};
	
	// const write = useWrite();
	
	
	// const uploadFile = useUploadFile();
	// const navigate = useNavigate();
	
	// const [ is_submitting, setIsSubmitting ] = useState(false);
	
	
	const org_path = `organizations/${claims.organization_id}`;
	const assets_collection = useCollection(`${org_path}/assets`);
	
	
	const centers = assets_collection?.data as Asset[];
	
	
	const form = useForm<CostCenter>({
		defaultValues: {
			...DEFAULT_FORM_DATA,
			...cost_center,
		},
	});
	
	
	console.log(form.getValues());
	
	
	
	
	const onSubmit = (data: any) =>
	{
		const datetime = DateTime.utc().toISO();
		
		console.log('FORM DATA', data);
		
		const cost_center_data = {
			...data,
			created_by_user: current_user,
			centers: data?.centers?.map(x => ({
				_id: x._id,
				name: x.name,
			})),
			created_at: datetime,
		}
		
		console.log('COST CENTER', cost_center_data)
		
		write(
			`organizations/${claims.organization_id}/cost_centers`,
			cost_center_data
		);
		
		setCloseDialog(!closeDialog);
	}
	
	
	const onSubmitError = (props: any) =>
	{
		console.log('Error submitting', props);
	}
	
	
	const handleSubmitForm = (e) =>
	{
		e.preventDefault()
		
		form.handleSubmit(
			onSubmit,
			onSubmitError
		)();
	};
	
	
	
	const value = {
		centers: centers || [],
	}
	
	
	console.log('centers', centers)
	
	
	return (
		<CostCenterFormContext.Provider value={value}>
			<FormProvider {...form}>
				<form
					onSubmit={handleSubmitForm}
					style={{
					}}
				>
					{children}
				</form>
			</FormProvider>
		</CostCenterFormContext.Provider>
	);
};


export default CostCenterFormProvider;
import Grid from '@mui/material/Grid';

import { Issue } from 'models';
import IssuesTable from '../issueCollaboration/issueDetails/issueTable/IssuesTable';
import SearchableIssuesCardList from './SearchableIssuesCardList';
import SearchAndSortStateProvider from 'components/searchAndSort/SearchAndSortStateProvider';
import IssuesListGridContent from './IssuesListGridContent';



const IssuesListResponsiveContent = ({
	issues,
	title,
} : {
	issues: Issue[],
	title?: string,
}) =>
{
	
	
	
	return (
		<SearchAndSortStateProvider
			issues={issues}
		>
			<IssuesListGridContent />
		</SearchAndSortStateProvider>
	)
};


export default IssuesListResponsiveContent;
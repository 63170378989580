
import { collection, onSnapshot } from 'firebase/firestore';

import fuego from './fuego';

import { snap2array } from './firebase_utils';
import { Thingy, resolveEntity } from 'store';


const UNSUB_DELAY = 4000;



// When a component first watches a collection, we will add a CollectionConnection
// to represent our app's observation. It contains a map of watchers to track which
// components are still subscribed. When components are unmounted, they should stop
// watching. After the final watcher stops watching, a delay will begin, after which
// we unsubscribe from the firestore collection.
class CollectionConnection
{
	constructor(collection_name, dispatch, id = null)
	{
		this.collection_name = collection_name;
		this.type = resolveEntity(collection_name).type;
		
		// Every subscribing component will provide a data handler function that
		// will be called with the results and snapshot every time we receive an update
		//this.handlers_by_id = {};
		
		
		// We'll keep a reference to the store's dispatch function so we can dispatch actions
		// to update the local state when the remote state changes
		this.dispatch = dispatch;
		
		
		// We'll maintain a map of component IDs that are subscribed to watch this collection
		this.watcher_ids = {};
		
		
		if(id)
		{
			this.addWatcher(id);
		}
	}
	
	
	
	// Subscribes to a Firestore collection, dispatching actions to our reducer
	// whenever we're notified of a remote update. Returns an unsubscribe function
	// that should be called after the last watcher is removed.
	subscribeAndReturnUnsubFn()
	{
		return onSnapshot(
			collection(fuego.db, this.collection_name)
			,
			(snap) =>
			{
				let items = snap2array(snap);
				
				//let thingys = items.map(x => new Thingy(this.collection_name, x));
				
				
				let upsertions = [];
				let deletions  = [];
				
				
				snap.docChanges().forEach((change) =>
				{
					let data = change.doc.data();
					
					let thingy = new Thingy(this.collection_name, data, {_id: change.doc.id});
					
					
					if(change.type === 'added')
					{
						//console.log('New', this.type, change, change.doc.data(), thingy);
						upsertions.push(thingy);
					}
					if(change.type === 'modified')
					{
						//console.log('Modified', this.type, change.doc.data(), thingy);
						upsertions.push(thingy);
					}
					if(change.type === 'removed')
					{
						//console.log('Removed', this.type, change.doc.data(), thingy);
						deletions.push(thingy);
					}
				})
				
				
				if(upsertions.length > 0)
				{
					this.dispatch({
						REMOTE_UPDATE: upsertions
					})
				}
				
				// TODO: Note that if we're not watching, we may not be notified of all deletions
				if(deletions.length > 0)
				{
					this.dispatch({
						DELETE: deletions
					})
				}
				
				//console.log('Current data: ', snap.size, items, snap);
				
				
				console.log(
					'🔥 %cRemote update for ' + this.collection_name,
					'color: #f66',
					items,
				);
				
				//this.dispatch({
				//	type: 'REMOTE_UPDATE',
				//	payload: thingys,
				//})
			}
		);
	}
	
	
	
	// Add a component as a watcher, tracking via a unique ID
	addWatcher(id)
	{
		// 
		if(Object.keys(this.watcher_ids).length === 0)
		{
			if(this.delayed_unsub)
			{
				clearTimeout(this.delayed_unsub);
				
				delete this.delayed_unsub;
				
				console.log('🔥 Cancelled delayed unsubscription from', this.collection_name);
			}
			
			this.unsub = this.subscribeAndReturnUnsubFn();
			
			this.is_watching = true;
		}
		
		
		this.watcher_ids[id] = id;
		
		console.log(
			'🔥 %cAdded watcher to ' + this.collection_name +
			' %c(' + id + ')',
			'color: #f66',
			'color: #844',
		);
	}
	
	
	
	// Remove the specified component fromm our watchers list
	// If The last watcher is removed, a delayed unsubscribe countdown starts
	removeWatcher(id)
	{
		delete this.watcher_ids[id];
		
		console.log(
			'🔥 %cRemoved watcher from ' + this.collection_name +
			' %c(' + id + ')',
			'color: #f66',
			'color: #844',
		);
		
		if(Object.keys(this.watcher_ids).length === 0)
		{
			this.delayed_unsub = setTimeout(
				() => {
					// TODO
					//this.is_watching = false;
					
					// Verify that the number of watchers is still zero
					if(Object.keys(this.watcher_ids).length === 0)
					{
						this.unsub();
			
						this.is_watching = false;
						
						console.log('        🔥 delayed unsub from', this.collection_name, this);
					}
					else
					{
						console.log('        🔥 delayed unsub - not unsubbing because there are watchers again', this);
					}
				}
				,
				UNSUB_DELAY
			)
		}
	}
}


export default CollectionConnection;
import { useState } from 'react';


// Update state to force render
export default function useForceUpdate()
{
	const [, setValue] = useState<number>(0);
	
	return () => setValue((value) => value + 1);
}


import { ScheduleFormData, TimeUnit } from 'pages/CreateWorkOrder/scheduling_types';
import { Control } from 'react-hook-form';
import BasedOn from '../BasedOn/BasedOn';
import RepeatsEvery from '../RepeatsEvery/RepeatsEvery';


interface IntervalProps
{
	control: Control<ScheduleFormData>,
	
	// Ex: years
	recurrence_unit: TimeUnit,
}


// TODO: Why is so much code copied and pasted rather than reused?
export const IntervalField =
({
	control,
	recurrence_unit,
}
: IntervalProps) =>
{
	return (
		<>
			<RepeatsEvery
				control={control}
				name={`recurrence.interval.${recurrence_unit}`}
				isRequired={true}
				unitLabel={recurrence_unit}
			/>
			<BasedOn
				control={control}
				name='recurrence.based_on'
				defaultValue={'effective_date'}
				values={[
					{
						value: 'effective_date',
						label: 'Effective Date',
					},
					{
						value: 'last_completed_date',
						label: 'Last Completed Date',
					},
				]}
			/>
		</>
	);
};


export default IntervalField;
import { DateTime, Duration, DurationLikeObject } from 'luxon';
import { capitalizeFirst } from 'utils';



// Ex: '2d 35m'
export const duration2text = (duration : Duration) =>
{
	if(!duration || !duration?.isValid)
	{
		return null;
	}
	
	let units = 'y M d h m'
	let units_arr = units.split(' ');
	
	// The numbers will actually be strings
	let num_of_each = duration.toFormat(units);
	let numbers_arr = num_of_each.split(' ');
	
	let result : string[] = [];
	
	numbers_arr.forEach((number_char : string, i : number) => {
		if(number_char !== '0')
		{
			result.push(number_char + units_arr[i]);
		}
	})
	
	if(result.length)
	{
		return result.join(' ');
	}
	else
	{
		return '<1m';
	}
}





// Ex: 2 => 'nd'
export const getNumberSuffix = (num: number) =>
{
	const th = 'th'
	const rd = 'rd'
	const nd = 'nd'
	const st = 'st'
	
	
	if(num === 11 || num === 12 || num === 13)
	{
		return th;
	}
	
	
	let last_digit = num.toString().slice(-1);
	
	
	switch (last_digit)
	{
		case '1': return st;
		
		case '2': return nd;
		
		case '3': return rd;
		
		default: return th;
	}
}



// Ex: 2 => '2nd'
export const ordinalNumber = (n: number) : string =>
{
	return n + getNumberSuffix(n);
}





// Ex: Today, Last week (1 day ago), Next week (in 11 days),
//     In 4 weeks (in 32 days)
export function relativeDatetimeText
(
	datetime: DateTime,
	relative_to = DateTime.local(),
)
{
	if(datetime === undefined)
	{
		return null;
	}
	
	if(datetime.hasSame(relative_to, 'day'))
	{
		return 'Today';
	}
	
	if(datetime.hasSame(relative_to.plus({days: 1}), 'day'))
	{
		return 'Tomorrow';
	}
	
	
	// console.log(
	// 	'Comparing',
	// 	datetime,
	// 	relativeDayTime,
	// )
	
	
	// Ex: Comparing earlier this week to today => -4.589890728967
	let num_days_from_now = datetime.diff(relative_to.startOf('day')).as('days');
	let text;
	
	
	// console.log(num_days_from_now)

	
	if(num_days_from_now < 0)
	{
		let days = -Math.floor(num_days_from_now);
		
		text = days + ' day'
			+ (days !== 1 ? 's' : '')
			+ ' ago'
	}
	else
	{
		let days = Math.floor(num_days_from_now);
		text = 'In ' + days + ' day'
				+ (days !== 1 ? 's' : '')
	}
	
	
	text = capitalizeFirst(text);
	
	
	// Ex: 2 weeks ago, This week, Next week, In 3 weeks
	let relative_to_today = datetime.toRelativeCalendar({unit: 'weeks'})
			.replace(' ago', '')
			.replace('in ', '');
	
	
	return text + ' (' + relative_to_today + ')';
}


export function relativeDaysDiff
(
	datetime: DateTime,
	relative_to = DateTime.local(),
)
{
	let num_days_from_now = datetime.diff(relative_to.startOf('day')).as('days');
	
	return num_days_from_now;
}







// Includes year if the datetime isn't in the same calendar year as the current local time
// Ex: 'Sun, Feb 6, 10:53' or 'Sun, Feb 6, 2022, 10:53'
export const conciseDateAndTime =
(
	datetime: DateTime | string,
	include_year = undefined,
	include_time = true,
)
: string | undefined =>
{
	if(typeof datetime == 'string')
	{
		datetime = DateTime.fromISO(datetime).toLocal();
	}
	
	
	if(!datetime.isValid)
	{
		return undefined;
	}
	
	let dt_text = (datetime.year !== DateTime.local().year)
		?
			datetime.toLocaleString({
				weekday: 'short',
				month: 'short',
				day: 'numeric',
				year:   (include_year || (include_year === undefined))
					? 'numeric'
					: undefined,
				hour:   (include_time) ? 'numeric' : undefined,
				minute: (include_time) ? '2-digit' : undefined,
				hourCycle: 'h23',
			})
		:
			datetime.toLocaleString({
				weekday: 'short',
				month: 'short',
				day: 'numeric',
				year:   (include_year)
					? 'numeric'
					: undefined,
				hour:   (include_time) ? 'numeric' : undefined,
				minute: (include_time) ? '2-digit' : undefined,
				hourCycle: 'h23',
			})
	
	return dt_text;
}



// Multiplies all object property values by provided multiplier
export const multiplyDuration = ( duration: DurationLikeObject, multiplier: number ) =>
{
	return Duration.fromObject(duration).mapUnits(x => x * multiplier)
}


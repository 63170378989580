import { OnDemandRecurrenceType } from 'models';
import { Controller } from 'react-hook-form';
import EnumToggleField from 'app/gizmos/fields/toggles/EnumToggleField';
import Formlet from '../fields/Formlet';


// Choose between Specific Days and Trigger
export const OnDemandRecurrenceTypeFormlet =
({
	label = 'On Demand Type',
	name = 'recurrence.on_demand_type',
} : {
	label?: string,
	name?: string,
}) =>
{
	return (
		<Formlet
			label={label}
			help='Schedule a list of specific dates, or only create work orders when triggered. Note: work order templates can be triggered from component records if desired, even without this being set to Trigger.'
		>
			<Controller
				name={name}
				render={({ field: { value, onChange } }) =>
					<EnumToggleField
						url_param_key='on_demand'
						enum_type={OnDemandRecurrenceType}
						value={value}
						onChange={onChange}
					/>
				}
			/>
		</Formlet>
	);
}


export default OnDemandRecurrenceTypeFormlet;
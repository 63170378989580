import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableBody,
	styled,
} from '@mui/material';

import TableCell, {
	tableCellClasses,
} from '@mui/material/TableCell';

import { defaultAppTheme } from 'styles/mui_app_theme/defaultAppTheme';

import { OptionBase } from 'models';
import { OptionsTableProps } from './table_interfaces';




// styled components
const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#d8dbdf',
		fontSize: 14,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)<{ isSelected?: boolean }>(
	({ isSelected }) => ({
		cursor: 'pointer',
		'&:nth-of-type(odd)': {
			backgroundColor: isSelected ? '#d8dbdf' : '',
		},
		backgroundColor: isSelected ? '#d8dbdf' : '',
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
		'&:hover': {
			backgroundColor: defaultAppTheme.palette.grey['100'],
		},
		padding: 2,
	})
);




// react component
const GeneralTable = <T extends OptionBase>({
	options,
	onOptionClick,
	rows,
}: OptionsTableProps<T>) =>
{
	return(
		<TableContainer
			component={Paper}
		>
			<Table>
				<TableHead>
					<TableRow>
						{
							rows.map((row) => {
								return (
									<StyledTableCell key={row.title}>
										{row.title}
									</StyledTableCell>
								);
							})
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{options
						.map((option) => {
							return (
								<StyledTableRow
									key={option._id}
									onClick={() => onOptionClick(option)}
								>
									{rows.map((row) => (
										<StyledTableCell
											key={option._id + row.title}
											component='th'
											scope='row'
											align={row.align}
											// sx={{ marginInline: '0.5rem' }}
										>
											{row.getCell(option)}
										</StyledTableCell>
									))}
								</StyledTableRow>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}


export default GeneralTable;
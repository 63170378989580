
import { Grid, Stack, Typography } from '@mui/material';
import { CurrentComponent } from 'models';
import RelatedAssetAndComponents from 'app/dashboard/pages/issueCollaboration/issueDetails/issueTable/RelatedAssetAndComponents';
import { WorkOrderNew } from 'models';


const WorkOrderComponentsList =
({
	components,
	work_order
}: {
	components: CurrentComponent[],
	work_order: WorkOrderNew,
}) =>
{
	return (
		<Grid
			item
			xs={12}
			sm={4}
		>
			<Stack
				direction='column'
				alignItems='flex-start'
				justifyContent='center'
				sx={{
					marginLeft:'10px',
					width: '100%',
				}}
			>
				<Typography
					variant='h6'
				>
					Equipment List
				</Typography>
				
				<RelatedAssetAndComponents component_ids={work_order.component_ids} division_id={work_order.division_id}/>
				
			</Stack>
		</Grid>
	)
}


export default WorkOrderComponentsList;
import { WorkOrderField } from 'pages/CreateWorkOrder/work_order_types';
import { Box, Radio } from '@mui/material';


export interface MultipleChoiceProps
{
	field: WorkOrderField;
}


export const MultipleChoice = ({ field }: MultipleChoiceProps) =>
{
	const {
		multiple_choice: { options },
	} = field;
	
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{options.map((option, key) => (
				<Box sx={{ display: 'flex', alignItems: 'center' }} key={key}>
					<Radio disabled={true} />
					<Box>{option.label}</Box>
				</Box>
			))}
		</Box>
	);
};


export default MultipleChoice;

// check badge title and return corresponding className



export const match_badgeBgcolor =
(
	color_: string | undefined,
	status_: string | undefined
) : string =>
{
	if ( color_ !== undefined )
	{
		const colorName: string = '--' + color_.toLowerCase();
		return colorName
	}
	else if ( status_ !== undefined )
	{
		return '--tag';
	}
	else 
	{
		return '';
	};
};



export const isRectangle =
(
	rectangleShape_: boolean | undefined
) =>
{
	if ( rectangleShape_ )
	{
		return '--rectangle'
	}
}



export const isFiltering =
(
	state_: boolean
) =>
{
	return state_ ? '--selected' : ''; 
}



import { useState } from 'react';
import { useURLStepNum } from 'utils/hooks';

import { StepperProps, steps } from './Stepper';



//  setActiveStep: StepperProps['setActiveStep'],



export const useStepper = ( handleSubmit: StepperProps['handleSubmit']) =>
{
	let { step, nextStep, prevStep } = useURLStepNum();
	
	const [ skipped, setSkipped ] = useState(new Set<number>());
	
	
	const isStepOptional = (step: number) =>
	{
		return false;
	};
	
	
	// TODO: How does skipping work?
	const isStepSkipped = (step: number) =>
	{
		return skipped.has(step);
	};
	
	
	// TODO: Clearer difference between next and finish/submit
	const handleNext = () =>
	{
		if (step + 1 > steps.length)
		{
			console.log('Submit?');
			
			handleSubmit();
			
			return;
		}
		
		
		let newSkipped = skipped;
		
		
		if (isStepSkipped(step))
		{
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(step);
		}
		
		
		nextStep();
		
		setSkipped(newSkipped);
	};
	
	
	const handleBack = () =>
	{
		if (step <= 1)
			return;
		
		prevStep();
	};
	
	
	const handleSkip = () =>
	{
		if (!isStepOptional(step))
		{
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error(`You can't skip a step that isn't optional.`);
		}
		
		nextStep();
		
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(step);
			return newSkipped;
		});
	};
	
	
	// const handleReset = () =>
	// {
	// 	//setActiveStep(0);
	// 	setSearchParams({ step: undefined });
	// };
	
	
	return {
		handleSkip,
		handleBack,
		handleNext,
		isStepOptional,
		isStepSkipped
	};
};
import { Stack, Typography } from '@mui/material';
import DatetimeFormlet from 'app/gizmos/fields/datetimes/DatetimeFormlet';
import NumberFormlet from 'app/gizmos/fields/text/NumberFormlet';
import { useFormContext } from 'react-hook-form';


const ReadingFormGizmo = () =>
{
	const form_context = useFormContext();
	
	let units = form_context?.watch('units');
	
	
	return (
		<Stack
			sx={{
				bgcolor: 'bg.form',
				padding: 2,
				borderRadius: 1,
			}}
		>
			<Stack>
				
				<Typography
					variant='h4'
					sx={{
						paddingBottom: 1,
					}}
				>
					New Reading
				</Typography>
				
				<NumberFormlet
					name='value'
					label='Value'
					units={units}
				/>
				
				<DatetimeFormlet
					name='recorded_at_datetime'
					label='Date/Time'
				/>
				
			</Stack>
		</Stack>
	)
}


export default ReadingFormGizmo;
import { Stack } from '@mui/material';
import SelectCollaboratorsFormlet from 'components/selectCollaborators/desktop/SelectCollaboratorsFormlet';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import SelectedCollaboratorsList from 'components/selectCollaborators/desktop/SelectedCollaboratorsList';
import { User } from 'models/users/user_interfaces';



const CreateRequisitionCollaborators = () =>
{
	const form = useFormContext();
	const { control } = form;
	
	
	
	const collaborators = useWatch({
		control,
		name: 'collaborators'
	});
	
	const { replace, remove, append } = useFieldArray({
		control,
		name: 'collaborators'
	});
	
	console.log('REGULAR COLLABORATORS', collaborators);
	
	
	const selectUser = (selected_account: User) =>
	{
		console.log(selected_account);
		const user_ids = collaborators?.map(x => x._id);
		
		if(user_ids.includes(selected_account._id))
		{
			unSelectUser(selected_account)
		}
		else
		{
			append(selected_account)
		}
	}
	
	
	const unSelectUser = (selected_account: User) =>
	{
		let index: number | undefined;
		
		collaborators.forEach((account, idx) =>
		{
			if (account._id === selected_account._id)
			{
				index = idx;
			}
		});
		
		remove(index);
	};
	
	
	
	return (
		<Stack>
			<SelectCollaboratorsFormlet
				handleSelectedCollaborators={selectUser}
				label='Please select other users you may need to collaborate with.'
				button_content='Select other Collaborators'
				selectedCollaborators={collaborators}
			/>
			
			<SelectedCollaboratorsList
				users={collaborators}
				handleChange={unSelectUser}
			/>
		</Stack>
	)
};


export default CreateRequisitionCollaborators;
import { Action } from 'models';
import { Event, PartsNeeded } from 'types';


export enum IssueStatus
{
	OPEN = 'Open',
	IN_PROGRESS = 'In Progress',
	ON_HOLD = 'On Hold',
	CLOSED = 'Closed',
	DEPENDS_ON_WORK_ORDER = 'Depends on Work Order',
	DEPENDS_ON_REQUISITION = 'Depends on Requisition',
}

export interface Issue
{
	_id: string,
	
	// Deprecated - use title instead
	name?: string,
	
	title?: string,
	
	description?: string,
	details?: string[],
	
	issue_num?: number,
	
	status?: IssueStatus,
	
	// Base 10?
	severity?: string,
	priority?: string,
	tag_ids?: string[],
	
	asset_id?: string,
	asset_name?: string,
	
	asset_ids?: string[],
	asset_names?: string[],
	
	system_ids?: string[],
	system_names?: string[],
	
	component_ids?: string[],
	component_names?: string[],
	
	parts_needed: PartsNeeded[],
	
	date_opened?: string,
	date_closed?: string,
	due_date?: string,
	
	issue_creator_id?: string,
	issue_creator_email?: string,
	issue_creator_name?: string,
	
	role_ids?: string[],
	collaborator_ids?: string[],
	document_ids?: string[],
	work_order_ids?: string[],
	activity_log_ids?: string[],
	
	// This could be a duplicate issue, or related to a previous issue
	// TODO: Relationship metadata
	issue_ids?: string[],
	
	// Possibly stitched in from other collections
	events?: Event[],
	actions?: Action[],
	
	
	// If this issue was triggered by a component record, we'll link to it
	component_record_id?: string,
}

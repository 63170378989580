import { React, useEffect, useState } from 'stuff';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useLocation } from 'react-router';
import IssuesListResponsiveContent from '../issuesMain/IssuesListResponsiveContent';
import ActionsListResponsiveContent from '../issueCollaboration/actions/ActionsListResponsiveContent';
import { useMyDashboard } from './MyDashboardProvider';
import { Badge, Typography } from '@mui/material';
import ResponsiveWorkOrdersList from 'pages/work_orders/work_orders/ResponsiveWorkOrdersList';
import { ActionStatus, IssueStatus, RequisitionStatus, WorkOrderStatus } from 'models';
import ResponsiveRequisitionsList from '../requisitionsAndParts/requisitionsList/ResponsiveRequisitionsList';


/*
	Using Material UI Tab component to render
	Manage Profile
	Mange Users
	Manage Tags
*/
interface TabPanelProps
{
	children?: React.ReactNode;
	index: number;
	value: number;
};



const getTabNumberFromSearchParam = (tab: string | undefined) =>
{
	switch (tab)
	{
		case 'issues':
			return 0;
		case 'actions':
			return 1;
		case 'work_orders':
			return 2;
		case 'requisitions':
			return 3;
		default:
			return 0;
	}
};






export function TabPanel(props: TabPanelProps)
{
	const { children, value, index, ...other } = props;
	
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
}



const MyDashboardTabs = () =>
{
	const { issues, actions, work_orders, requisitions } = useMyDashboard();
	const { search } = useLocation();
	
	const tab = getTabNumberFromSearchParam(
		new URLSearchParams(search).get('tab')
	);
	
	
	const [ tabValue, setTabValue ] = useState(tab);
	const [ issueNotification, setIssueNotification ] = useState<number>();
	const [ actionNotification, setActionNotification ] = useState<number>();
	const [ workOrderNotification, setWorkOrderNotification ] = useState<number>(0);
	const [ requisitionNotification, setRequisitionNotification ] = useState<number>(0);
	
	
	// console.log({search});
	
	
	const handleChange = (event: React.SyntheticEvent, newValue: number) =>
	{
		setTabValue(newValue);
		
		const url = new URL(document.location.href)
		
		switch(newValue)
		{
			case 0:
				url.searchParams.set('tab', 'issues');
				break;
			case 1:
				url.searchParams.set('tab', 'actions');
				break;
			case 2:
				url.searchParams.set('tab', 'work_orders');
				break;
			case 3:
				url.searchParams.set('tab', 'requisitions');
				break;
			default:
				url.searchParams.set('tab', 'issues');
				break;
		}
		
		window.history.pushState({}, document.title, url.toString());
	};
	
	
	let tab_items = [];
	
	tab_items =
	[
		{ label: 'Issues',         number: 0,     notifications: issueNotification },
		{ label: 'Actions',        number: 1,     notifications: actionNotification },
		{ label: 'Work Orders',    number: 2,     notifications: workOrderNotification },
		{ label: 'Requisitions',   number: 3,     notifications: requisitionNotification },
	]
	
	
	// Initializes Notification numbers on Tabs
	const outputBadgeNotificationNumber = () =>
	{
		if(issues.length > 0)
		{
			const open_issues = issues?.filter(x => x.status === IssueStatus.OPEN);
			
			setIssueNotification(open_issues.length);
		}
		
		if(actions.length > 0)
		{
			const open_actions = actions?.filter(x => x.status === ActionStatus.OPEN)
			
			setActionNotification(open_actions.length);
		}
		
		if(work_orders.length > 0)
		{
			const open_work_orders = work_orders.filter(x => x.status !== WorkOrderStatus.OPEN);
			
			setWorkOrderNotification(open_work_orders.length);
		}
		
		if(requisitions.length > 0)
		{
			const open_requisitions = requisitions.filter(x => x.status !== RequisitionStatus.STARTED);
			
			setRequisitionNotification(open_requisitions.length);
		}
	};
	
	useEffect(() =>
	{
		outputBadgeNotificationNumber()
	}, [issues, actions, work_orders, requisitions]);
	
	
	
	
	
	
	return (
		<Box
			sx={{ marginTop: '10px' }}
		>
			<Tabs
				value={ tabValue }
				onChange={handleChange}
				className='issue_collab_tabs_list'
			>
				{
					tab_items.map(( item, idx ) =>
						<Tab
							key={idx}
							label={
								<Badge
									badgeContent={item.notifications}
									color='error'
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'left'
									}}
								>
									<Typography
										variant='h5'
										sx={{padding: '8px'}}
									>
										{item.label}
									</Typography>
								</Badge>
							}
							value={item.number}
						/>
					)
				}
			</Tabs>
			
			<TabPanel value={tabValue} index={0}>
				<IssuesListResponsiveContent issues={issues} />
			</TabPanel>
			<TabPanel value={tabValue} index={1}>
				<ActionsListResponsiveContent actions={actions} />
			</TabPanel>
			<TabPanel value={tabValue} index={2}>
				<ResponsiveWorkOrdersList work_orders={work_orders} />
			</TabPanel>
			<TabPanel value={tabValue} index={3}>
				<ResponsiveRequisitionsList requisitions={requisitions} />
			</TabPanel>
		</Box>
	)
}


export default MyDashboardTabs;
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import RecurrenceFormSection from 'app/gizmos/recurrence/RecurrenceFormSection';
import useWorkOrderTemplate from 'pages/WorkOrderCollaboration/useWorkOrderTemplate';
import React from 'react'
import { useFormContext } from 'react-hook-form';
import { useWOTCollabData } from './WOTCollabProvider';

const WOTCollabSchedule = () =>
{
	const { updateRecurrence } = useWOTCollabData();
	
	return (
		<Stack
			padding={4}
			gap={2}
			className='container'
			sx={{
				marginBottom: '20px'
			}}
		>
			<Stack
				width='100%'
				direction='row'
				alignItems='center'
				justifyContent='space-between'
			>
				<Typography
					variant='h2'
				>
					Edit Work Schedule
				</Typography>
				
				<Button
					onClick={() => updateRecurrence()}
				>
					Save Changes
				</Button>
			</Stack>
			
			<Box
				sx={{
					margin: '5px',
					padding: '5px',
				}}
			>
				<RecurrenceFormSection />
			</Box>
		</Stack>
		
	)
};


export default WOTCollabSchedule;
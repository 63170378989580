import { Typography, Stack } from '@mui/material'
import { useComponent } from 'app/providers/ComponentLoader'
import CorIcon from 'components/icons/CorIcon'
import { CorComponent } from 'models'


// Summarizes info about the currently selected component
// Appears at the top of the sidebar in tree view and at the top of the component records page
const ComponentInfoCard =
({
	component,
} : {
	component?: CorComponent,
}) =>
{
	let { component: selected_component } = useComponent();
	
	
	selected_component = component || selected_component;
	
	
	return (
		<Stack
			direction='column'
			spacing={1.5}
			className='component_card'
			sx={{
				flexGrow: 1,
				bgcolor: 'bg.form',
			}}
		>
			<Typography
				variant='subtitle2'
			>
				{ selected_component?.asset_name }
			</Typography>
			
			<Stack
				direction='column'
				spacing={.5}
				className='component_card_info'
			>
				<Stack
					direction='row'
					alignItems='center'
					alignContent='center'
					spacing={1}
					className='component_card_info'
				>
					<Typography
						variant='caption'
					>
						{ selected_component?.system_name }
					</Typography>
					<CorIcon
						of='arrow_right'
						size= '15px'
					/>
				</Stack>
				
				
				<Stack
					direction='row'
					alignItems='center'
					alignContent='center'
					sx={{
						// Being overridden by something strange
						marginLeft: 1,
						paddingLeft: 1,
					}}
				>
					<Typography
						variant='body2'
					>
						{ selected_component?.name }
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	)
}


export default ComponentInfoCard;
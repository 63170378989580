import { useMemo } from 'react';

import {
	MapContainer,
	TileLayer,
	useMap,
} from 'react-leaflet';

import MinimapViewbox from './MinimapViewbox';

import { minimapClasses, determineMinimapZoom } from './minimap_utils';


const Minimap =
({
	position
} : {
	position?: string
}) =>
{
	const parent_map = useMap();
	
	
	// Memoize the minimap so it's not affected by position changes
	const minimap = useMemo(
		() => (
			<MapContainer
				style={{ height: 80, width: 120, cursor: 'crosshair' }}
				center={parent_map.getCenter()}
				zoom={determineMinimapZoom(parent_map)}
				dragging={false}
				doubleClickZoom={false}
				scrollWheelZoom={false}
				attributionControl={false}
				zoomControl={false}
			>
				
				<TileLayer
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>
				
				<MinimapViewbox
					parent_map={parent_map}
				/>
				
			</MapContainer>
		),
		[parent_map],
	);
	
	
	return (
		<div
			className={minimapClasses(position)}
		>
			<div
				className='leaflet-control leaflet-bar'
			>
				{minimap}
			</div>
		</div>
	)
}


export default Minimap;
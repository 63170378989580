// import { useFormContext } from 'react-hook-form';
// import { DateTime, Duration } from 'luxon';

import { Stack } from '@mui/material';

import RecurrenceFormSection from 'app/gizmos/recurrence/RecurrenceFormSection';

 
const WOTStep2 = () =>
{
	return (
		<Stack
			padding={3}
			gap={2}
		>
			<RecurrenceFormSection />
		</Stack>
	);
};


export default WOTStep2;
import { DateTime } from 'luxon';
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';


const DatePickerWidget =
({
	value,
	onChange,
	name,
} : {
	value: DateTime,
	onChange: Function,
	name?: string,
}) =>
{
	return <Stack
		className='date_picker'
		sx={{
			maxWidth: '160px',
		}}
	>
		<DatePicker
			label='Date'
			inputFormat='yyyy-MM-dd'
			value={value}
			onChange={(datetime: DateTime) => {
				if(datetime.isValid)
					onChange(datetime)
			}}
			renderInput={(params) =>
				<TextField
					sx={{
						bgcolor: 'field.default',
						
						'&:hover': {
							bgcolor: 'field.hover',
						},
						
						'& .Mui-focused': {
							bgcolor: 'field.focus',
						},
						
						
						'& .MuiInputLabel-root': {
							bgColor: 'field.default',
							outline: '4px solid field.default',
							padding: '0 2px',
							borderRadius: '2px',
							left: '2px',
						},
						
						'&:hover .MuiInputLabel-root': {
							bgcolor: 'field.hover',
							outline: '4px solid field.hover',
						},
						
						'& .MuiInputLabel-root.Mui-focused': {
							bgcolor: 'field.focus',
							outline: '4px solid field.focus',
						},
					}}
					{...params}
				/>
			}
		/>
	</Stack>
}


export default DatePickerWidget;
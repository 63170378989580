import { useNavigate } from "react-router";

import Box from "@mui/material/Box";

import { System } from "models";
import SortableTable from '../issueCollaboration/issueDetails/issueTable/SortableTable';
import SystemsGridRowCell from "./SystemsGridRowCell";


const SystemsGrid =
({
	systems,
	setSelectedSystem,
} : {
	systems: System[],
	setSelectedSystem?: Function,
}) =>
{
	const navigate = useNavigate();
	
	
	return (
		<Box className='systems_grid'>
			<SortableTable
				options={systems}
				onSelect={(selectedOption) => {
					console.log(selectedOption);
				}}
				rows={[
					{
						title: 'Name',
						getCell(system) {
							return (
								<SystemsGridRowCell
									cell_data={system}
									setSelectedSystem={setSelectedSystem}
								/>
							);
						},
					},
				]}
			/>
		</Box>
	)
};


export default SystemsGrid;
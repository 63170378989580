import Grid from '@mui/material/Grid';
import { WorkOrderNew } from 'models';
import SearchableWorkOrdersCardList from './SearchableWorkOrdersCardList';
import WorkOrdersTable from './WorkOrdersTable';
import SearchAndSortStateProvider from 'components/searchAndSort/SearchAndSortStateProvider';
import WorkOrdersSearch from './WorkOrdersSearch';
import WorkOrdersGridContainer from './WorkOrdersListGridContainer';


const ResponsiveWorkOrdersList =
({
	work_orders,
	title,
} : {
	work_orders: WorkOrderNew[],
	title?: string,
}) =>
{
	return (
		<SearchAndSortStateProvider
			work_orders={work_orders}
		>
			<WorkOrdersGridContainer />
		</SearchAndSortStateProvider>
	)
};


export default ResponsiveWorkOrdersList;